import React from "react";

import styles from "./style.module.scss";

type Props = {
  value: string;
  onChange: (a: string) => void;
};

const PanelTitleSearch: React.FunctionComponent<Props> = ({
  value,
  onChange,
}) => {
  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
      }}
    >
      <input
        placeholder="Search..."
        type="text"
        className={styles.filterSearch}
        value={value || ""}
        onChange={(evt) => {
          onChange(evt.currentTarget.value);
        }}
      />
    </form>
  );
};

export default PanelTitleSearch;
