import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { formatBytes, formatNumber } from "utils/format";
import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import SQL from "components/SQL";
import Tip from "components/Tip";

import {
  SchemaTableIndices,
  SchemaTableIndicesVariables,
  SchemaTableIndices_getSchemaTableIndices,
} from "./types/SchemaTableIndices";
import QUERY from "./Query.graphql";
import { useFeature } from "components/OrganizationFeatures";
import IssueSummaryBadge from "components/VacuumAdvisor/IssueSummaryBadge";

type Props = {
  databaseId: string;
  tableId: string;
};

const SchemaTableIndexes: React.FunctionComponent<Props> = ({
  databaseId,
  tableId,
}) => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  const { data, loading, error } = useQuery<
    SchemaTableIndices,
    SchemaTableIndicesVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const indexes = data.getSchemaTableIndices;

  const indexSizeBytes = indexes.reduce((size, idx) => size + idx.sizeBytes, 0);
  const indexWriteOverhead = indexes.reduce(
    (overhead, idx) =>
      idx.writeOverhead == null || overhead == null
        ? null
        : overhead + idx.writeOverhead,
    0,
  );

  return (
    <Panel title="Indexes">
      {hasIndexAdvisor && (
        <PanelTable horizontal borders className="mb-3">
          <tbody>
            <tr>
              <th>Total Index Size</th>
              <td>{formatBytes(indexSizeBytes)}</td>
              <th>
                Index Write Overhead{" "}
                <Tip content="Estimated cost of maintaining all indexes on this table, as a proportion of writes to the table itself. Specifically, for every byte written to the table, this many bytes total are written to update all its indexes (on average)." />
              </th>
              <td>
                {indexWriteOverhead == null
                  ? "n/a"
                  : formatNumber(indexWriteOverhead, 2)}
              </td>
            </tr>
          </tbody>
        </PanelTable>
      )}
      <PanelTable borders>
        <thead>
          <tr>
            <th>Name</th>
            <th>Definition</th>
            <th>Constraint</th>
            <th>Valid?</th>
            <th>First Seen</th>
            <th>Index Size</th>
            {hasIndexAdvisor && <th>Index Write Overhead</th>}
          </tr>
        </thead>
        <tbody>
          {indexes.map((index) => (
            <SchemaTableIndex
              key={index.id}
              index={index}
              databaseId={databaseId}
            />
          ))}
        </tbody>
      </PanelTable>
    </Panel>
  );
};

const SchemaTableIndex: React.FunctionComponent<{
  index: SchemaTableIndices_getSchemaTableIndices;
  databaseId: string;
}> = ({ index, databaseId }) => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  const { databaseIndex } = useRoutes();
  return (
    <tr key={index.id}>
      <td>
        <strong>
          <Link to={databaseIndex(databaseId, index.id)}>{index.name}</Link>
        </strong>
      </td>
      <td className="definition">
        <SQL inline sql={index.indexDefShort} />
      </td>
      <td className="definition">
        {index.constraintDef && <SQL inline sql={index.constraintDef} />}
      </td>
      <td>
        {index.valid ? (
          <>
            Yes <IssueSummaryBadge severity={undefined} />
          </>
        ) : (
          <>
            No <IssueSummaryBadge severity="critical" />
          </>
        )}
      </td>
      <td>{moment(index.createdAt * 1000).fromNow()}</td>
      <td>{(index.sizeBytes && formatBytes(index.sizeBytes)) || "n/a"}</td>
      {hasIndexAdvisor && (
        <td>
          {index.writeOverhead == null
            ? "n/a"
            : formatNumber(index.writeOverhead, 2)}
        </td>
      )}
    </tr>
  );
};

export default SchemaTableIndexes;
