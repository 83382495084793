import React from "react";
import { Link } from "react-router-dom";

type IssueDescriptionRef = {
  param: string;
  name: string;
  path?: string;
};

type IssueType = {
  checkGroupAndName: string;
  descriptionTemplate: string;
  descriptionReferences: IssueDescriptionRef[];
};

const IssueDescription: React.FunctionComponent<{ issue: IssueType }> = ({
  issue,
}) => {
  const templateParts = issue.descriptionTemplate.split(" ");
  const descriptionParts: React.ReactNode[] = [];
  templateParts.forEach((part, idx) => {
    const isLast = idx === templateParts.length - 1;

    let nextElem: React.ReactNode;
    if (part.startsWith(":")) {
      const ref = issue.descriptionReferences.find(
        (ref) => ref.param === part.substring(1),
      );
      if (ref?.path) {
        nextElem = (
          <Link key={ref.param} to={ref.path}>
            <strong>{ref.name}</strong>
          </Link>
        );
      } else if (ref) {
        nextElem = <strong key={ref.param}>{ref.name}</strong>;
      } else {
        nextElem = "?";
      }
    } else {
      nextElem = part;
    }

    descriptionParts.push(nextElem);

    if (!isLast) {
      descriptionParts.push(" ");
    }
  });
  return <>{descriptionParts}</>;
};

export default IssueDescription;
