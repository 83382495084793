import React from "react";

import styles from "./style.module.scss";

import { useSelectedNode } from "../Explain/WithNodeSelection";
import Node from "./Node";

import { AnnotatedPlan, Node as NodeType } from "types/explain";

type Props = {
  plan: AnnotatedPlan;
  databaseId: string;
};

const ExplainDetails: React.FunctionComponent<Props> = ({
  plan,
  databaseId,
}) => {
  return (
    <div className={styles.explainDetails}>
      <Explain plan={plan} databaseId={databaseId} />
    </div>
  );
};

type ExplainProps = {
  plan: AnnotatedPlan;
  databaseId: string;
};

const Explain: React.FunctionComponent<ExplainProps> = ({
  plan,
  databaseId,
}) => {
  const root = plan.plan[0].Plan;
  const annotations = plan.annotations;
  const [, setSelectedNode] = useSelectedNode();

  const handleNodeClick = (node: NodeType): void => {
    setSelectedNode(node.extra.id);
  };

  return (
    <Node
      node={root}
      databaseId={databaseId}
      annotations={annotations}
      onNodeClick={handleNodeClick}
    />
  );
};

export default ExplainDetails;
