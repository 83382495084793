import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import Loading from "components/Loading";
import Grid from "components/Grid";

import {
  SchemaFunctionList,
  SchemaFunctionListVariables,
  SchemaFunctionList_getSchemaFunctions,
  SchemaFunctionList_getSchemaFunctions_issues,
} from "./types/SchemaFunctionList";
import { useRoutes } from "utils/routes";
import QUERY from "./Query.graphql";
import LazyLoader from "components/LazyLoader";
import { toGraphQLSortDirection } from "components/Grid/util";

type SchemaFunctionType = Omit<
  SchemaFunctionList_getSchemaFunctions,
  "__typename" | "id" | "issues"
>;

type Props = {
  searchTerm: string | null;
  databaseId: string;
};

const NameAndIssuesCell: React.FunctionComponent<{
  databaseId: string;
  schemaFunction: SchemaFunctionList_getSchemaFunctions;
}> = ({ databaseId, schemaFunction }) => {
  const { databaseFunction } = useRoutes();
  return (
    <span>
      <Link to={databaseFunction(databaseId, schemaFunction.id)}>
        {schemaFunction.functionName}
      </Link>
      {schemaFunction.issues.map(
        (i: SchemaFunctionList_getSchemaFunctions_issues): React.ReactNode => (
          <Link
            to={databaseFunction(databaseId, schemaFunction.id)}
            className={`state-${i.severity}`}
            title={`${i.displayName || ""} ${i.description}`}
            key={i.id}
          >
            <i className="icon-exclamation-sign" />
          </Link>
        ),
      )}
    </span>
  );
};

const FETCH_BATCH_SIZE = 50;

const Table: React.FunctionComponent<Props> = (props) => {
  const [sortOpts, setSortOpts] = React.useState<{
    sortBy: keyof SchemaFunctionType;
    sortOrder: "asc" | "desc";
  }>({
    sortBy: "functionName",
    sortOrder: "desc",
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaFunctionList,
    SchemaFunctionListVariables
  >(QUERY, {
    variables: {
      offset: 0,
      limit: FETCH_BATCH_SIZE,
      filter: props.searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: toGraphQLSortDirection(sortOpts.sortOrder),
      databaseId: props.databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const schemaFunctions = data.getSchemaFunctions;

  const fetchMoreData = (loadedCount: number) => {
    return new Promise<boolean>((resolve) => {
      const offset = loadedCount;
      fetchMore({
        variables: { offset, limit: FETCH_BATCH_SIZE },
        updateQuery: (prev, { fetchMoreResult }): SchemaFunctionList => {
          const fetched = fetchMoreResult.getSchemaFunctions;
          resolve(fetched.length > 0);
          if (!prev || !prev.getSchemaFunctions) {
            return fetchMoreResult;
          }
          return {
            ...prev,
            getSchemaFunctions: [...prev.getSchemaFunctions, ...fetched],
          };
        },
      });
    });
  };

  const handleSort = (
    sortBy: keyof SchemaFunctionType,
    sortOrder: "asc" | "desc",
  ) => {
    setSortOpts({ sortBy, sortOrder });
  };

  return (
    <LazyLoader loadedCount={schemaFunctions.length} loadMore={fetchMoreData}>
      <Grid
        striped
        className="grid-cols-[120px_1fr_120px_160px]"
        data={schemaFunctions}
        columns={[
          { field: "schemaName", header: "Schema" },
          {
            field: "functionName",
            header: "Function Name",
            renderer: function SchemaFunctionNameCell({ rowData }) {
              return (
                <NameAndIssuesCell
                  databaseId={props.databaseId}
                  schemaFunction={rowData}
                />
              );
            },
          },
          { field: "language", header: "Language" },
          {
            field: "securityDefiner",
            header: "Security Definer",
            defaultSortOrder: "desc",
            renderer: function SecurityDefinerCell({ fieldData }) {
              return fieldData ? "Yes" : "No";
            },
          },
        ]}
        sortedBy={sortOpts.sortBy}
        sortedOrder={sortOpts.sortOrder}
        handleSort={handleSort}
      />
    </LazyLoader>
  );
};

export default Table;
