// Selected methods pulled from lodash-inflection to avoid bloating our bundle
// https://github.com/tuvistavie/lodash-inflection

/**
 * `titleize` capitalizes the first letter of each word in
 * the string `words`. It preserves the existing whitespace.
 */
export function titleize(words: string | any) {
  if (typeof words !== "string") {
    return words;
  }
  return words.replace(/\S+/g, (w) => w.charAt(0).toUpperCase() + w.slice(1));
}
