import React from "react";

import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import ContactSupportLink from "components/ContactSupportLink";

import { formatDateShort } from "utils/format";

import { BillingOverview_getOrganizationBilling_invoices } from "../Overview/types/BillingOverview";

type InvoiceType = Omit<
  BillingOverview_getOrganizationBilling_invoices,
  "__typename"
>;

const InvoiceList: React.FunctionComponent<{
  organizationCreatedAt: number;
  invoices: InvoiceType[];
}> = ({ organizationCreatedAt, invoices }) => {
  return (
    <Panel id="invoices" title="Invoices">
      {invoices.length > 0 ? (
        <PanelTable borders>
          <thead>
            <tr>
              <th>Date</th>
              <th>Reference</th>
              <th>Amount</th>
              <th>Status</th>
              <th className="w-1/2">Invoice PDF</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>
                  {invoice.invoicePdf ? (
                    <a href={invoice.invoicePdf} title="Download invoice PDF">
                      {formatDateShort(moment.unix(invoice.date))}
                    </a>
                  ) : (
                    formatDateShort(moment.unix(invoice.date))
                  )}
                </td>
                <td>{invoice.reference}</td>
                <td>{invoice.formattedTotal}</td>
                <td>{invoice.status}</td>
                <td>
                  {invoice.invoicePdf ? (
                    <a href={invoice.invoicePdf} title="Download invoice PDF">
                      <FontAwesomeIcon
                        className="pb-[1px] h-[15px]"
                        icon={faFilePdf}
                      />
                    </a>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </PanelTable>
      ) : (
        <PanelSection>
          <em>(none yet)</em>
        </PanelSection>
      )}
      {moment
        .unix(organizationCreatedAt)
        .isBefore(moment(new Date(2022, 1, 1))) ? (
        <PanelSection>
          For retrieving invoices before January 1st, 2022,{" "}
          <ContactSupportLink simpleStyle={true}>
            please contact support
          </ContactSupportLink>
          .
        </PanelSection>
      ) : null}
    </Panel>
  );
};

export default InvoiceList;
