import React from "react";
import { Link } from "react-router-dom";

import { IssueType } from "types/Issue";
import SQL from "components/SQL";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";
import CheckStatusBadge from "components/CheckStatusBadge";
import IssueDescription from "components/IssueDescription";

type Props = {
  issue: IssueType;
  serverId?: string;
  databaseId?: string;
  verbose?: boolean;
};

const SEVERITY_TO_CLASS_NAME = {
  critical: styles.issueCritical,
  warning: styles.issueWarning,
  info: styles.issueInfo,
};

const Issue: React.FunctionComponent<Props> = ({
  issue,
  serverId,
  databaseId,
  verbose,
}) => {
  const { id, queryText, severity, checkGroupAndName } = issue;

  const { databaseIssue, serverIssue } = useRoutes();
  const url = databaseId
    ? databaseIssue(databaseId, id, checkGroupAndName)
    : serverId
    ? serverIssue(serverId, id, checkGroupAndName)
    : "#";

  return (
    <li className={SEVERITY_TO_CLASS_NAME[severity]}>
      <Link to={url}>
        <div className={styles.wrapper}>
          <CheckStatusBadge status={severity} className={styles.issueBadge} />
          <div>
            <IssueDescription issue={issue} />
            {verbose && queryText && (
              <div className={styles.issueQuery}>
                <SQL inline sql={queryText} />
              </div>
            )}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default Issue;
