import React, { useRef, useState } from "react";

import {
  InteractionPoint,
  ScreenLocation,
  interactionPointsEqual,
} from "./util";

type Props = {
  width: number;
  height: number;
  mapLocationToInteractionPoint: (loc: ScreenLocation) => InteractionPoint;
  onHover?: (target: InteractionPoint | undefined) => void;
  onClick?: (target: InteractionPoint) => void;
  onRangeSelected?: (from: InteractionPoint, to: InteractionPoint) => void;
  children: (
    hover: InteractionPoint | undefined,
    rangeEndpoint: InteractionPoint | undefined,
  ) => React.ReactNode;
};

const MOUSE_LEAVE_GRACE_PERIOD_MS = 2000;

const Mouse: React.FunctionComponent<Props> = ({
  width,
  height,
  mapLocationToInteractionPoint,
  onHover,
  onClick,
  onRangeSelected,
  children,
}) => {
  const mouseLeaveTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const [hover, setHover] = useState<InteractionPoint | undefined>(undefined);
  const [rangeEndpoint, setRangeEndpoint] = useState<
    InteractionPoint | undefined
  >(undefined);

  const handleMouseMove = (e: React.MouseEvent<SVGGElement>) => {
    const currHover = mapLocationToInteractionPoint(getMouse(e));
    onHover && onHover(currHover);
    setHover(currHover);
  };

  // If there is a selection in progress when the cursor leaves the
  // mouse-handling area, we want to give a short grace period on triggering the
  // selection. We manage this through handleMouseLeave, handleMouseEnter, and
  // handleOutsideMouseUp. When the mouse leaves, we set a timeout to clear the
  // hover selection and install a document mouseUp listener, if the listener
  // fires, we apply a range selection as if the user had executed a mouseUp at
  // the last hover point. If the timeout fires, we clear the hover state and
  // the document listener.

  function handleOutsideMouseUp(): void {
    document.removeEventListener("mouseup", handleOutsideMouseUp);
    // if there is a pending timeout from leaving the mouse area while hovering,
    // clear it and apply the last range selection
    if (mouseLeaveTimeout.current) {
      clearTimeout(mouseLeaveTimeout.current);
      applyRangeSelection(hover);
    }
  }

  const handleMouseLeave = () => {
    if (!rangeEndpoint) {
      setHover(undefined);
      onHover && onHover(undefined);
      return;
    }

    document.addEventListener("mouseup", handleOutsideMouseUp);

    mouseLeaveTimeout.current = setTimeout(() => {
      document.removeEventListener("mouseup", handleOutsideMouseUp);
      setHover(undefined);
      setRangeEndpoint(undefined);
      onHover && onHover(undefined);
    }, MOUSE_LEAVE_GRACE_PERIOD_MS);
  };

  const handleMouseEnter = () => {
    if (mouseLeaveTimeout.current) {
      document.removeEventListener("mouseup", handleOutsideMouseUp);
      clearTimeout(mouseLeaveTimeout.current);
    }
  };

  function applyRangeSelection(point: InteractionPoint | undefined): void {
    if (!point || !rangeEndpoint || !onRangeSelected) {
      return;
    }
    // If a user has clicked and is dragging while hovering, the rangeEndpoint will
    // indicate where that click occurred. N.B.: that click may be before or after
    // the hover position, so we normalize the range.
    const [start, end] =
      rangeEndpoint.bottom.domain < point.bottom.domain
        ? [rangeEndpoint, point]
        : [point, rangeEndpoint];

    onRangeSelected(start, end);
    setRangeEndpoint(undefined);
  }

  const handleMouseUp =
    (onClick || onRangeSelected) &&
    ((e: React.MouseEvent<SVGGElement>) => {
      const mouse = getMouse(e);
      const clickPoint = mapLocationToInteractionPoint(mouse);
      if (
        !rangeEndpoint ||
        !onRangeSelected ||
        interactionPointsEqual(rangeEndpoint, clickPoint)
      ) {
        onClick && onClick(clickPoint);
        setRangeEndpoint(undefined);
        return;
      }

      applyRangeSelection(clickPoint);
    });

  const handleMouseDown =
    onRangeSelected &&
    ((e: React.MouseEvent<SVGGElement>) => {
      const clickSlice = mapLocationToInteractionPoint(getMouse(e));
      setRangeEndpoint(clickSlice);
    });

  return (
    <>
      <rect
        cursor={onClick ? "pointer" : onRangeSelected ? "crosshair" : undefined}
        width={width}
        height={height}
        fill="none"
        stroke="none"
        pointerEvents="all"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
      {children(hover, rangeEndpoint)}
    </>
  );
};

const getMouse = (e: React.MouseEvent<SVGGElement>): ScreenLocation => {
  const dims = e.currentTarget.getBoundingClientRect();
  return { x: e.clientX - dims.left, y: e.clientY - dims.top };
};

export default Mouse;
