import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCircle as faCircleSolid } from "@fortawesome/pro-solid-svg-icons";
import {
  PermissionKind,
  PERMISSION_FULL,
  PERMISSION_RESTRICTED,
  PERMISSION_NONE,
} from "utils/permissions";

const PermissionIcon: React.FunctionComponent<{
  permission: PermissionKind;
}> = ({ permission }) => {
  switch (permission) {
    case PERMISSION_FULL:
      return <FontAwesomeIcon icon={faCircleSolid} title="Full" />;
    case PERMISSION_RESTRICTED:
      return <FontAwesomeIcon icon={faDotCircle} title="Restricted" />;
    case PERMISSION_NONE:
      return <FontAwesomeIcon icon={faCircle} title="None" />;
  }
};

export default PermissionIcon;
