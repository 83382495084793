---
title: 'Step 4: Install the Collector'
install_track_title: Installation Guide (Aiven)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

## Downloading the collector container

Pull the Docker image like this:

```
docker pull quay.io/pganalyze/collector:stable
```

<Link className="btn btn-success" to="05_configure_the_collector_docker">
  Continue to Step 5: Configure the Collector
</Link>
