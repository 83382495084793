import React from "react";

import styles from "./style.module.scss";

type Props = {
  color: string;
};

const Swatch: React.FunctionComponent<Props> = ({ color }) => {
  return (
    <div
      className={styles.swatch}
      style={{
        backgroundColor: color,
      }}
    />
  );
};

export default Swatch;
