import React, { useEffect } from "react";

import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import * as Sentry from "@sentry/react";

import { useAppConfig } from "components/WithAppConfig";
import {
  useCurrentOrganization,
  useCurrentServer,
  useCurrentUser,
} from "components/WithCurrentOrganization";
import { useRouteTopLevelReference } from "utils/useRouteTopLevelReference";

const WithSentry = ({ children }: { children: React.ReactNode }) => {
  const { enterpriseEdition, sentryDsn, sentryTracesSampleRate } =
    useAppConfig();

  useEffect(() => {
    if (!enterpriseEdition && sentryDsn) {
      Sentry.init({
        dsn: sentryDsn,
        tracesSampleRate: sentryTracesSampleRate,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
            tracingOrigins: [/^\//, "/graphql"],
            shouldCreateSpanForRequest: (url) => {
              return (
                !url.match(/\/users\/auth\/?/) && !url.match(/\/users\/jwt\/?$/)
              );
            },
          }),
        ],
      });
    }
  }, [enterpriseEdition, sentryDsn, sentryTracesSampleRate]);

  const user = useCurrentUser();
  useEffect(() => {
    Sentry.setUser({ id: user.id, email: user.email });
  }, [user]);

  const { databaseId } = useRouteTopLevelReference();
  const server = useCurrentServer(true);
  const organization = useCurrentOrganization(true);
  useEffect(() => {
    Sentry.setTags({
      database_id: databaseId,
      server_id: server?.id,
      organization_id: organization?.id,
      organization_name: organization?.name,
    });
  }, [databaseId, server, organization]);

  return <>{children}</>;
};

export default WithSentry;
