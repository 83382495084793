import React from "react";

import Loading from "components/Loading";

import classNames from "classnames";

import styles from "./style.module.scss";

type Props = {
  className?: string;
  children: React.ReactNode;
};

const PanelTileGroup = ({ children, className }: Props) => {
  return (
    <div className={classNames(styles.panelTileGroup, className)}>
      {children}
    </div>
  );
};

type TileProps = {
  title: string;
  className?: string;
  loading?: boolean;
  children: React.ReactNode;
};

export const PanelTile = ({
  title,
  className,
  children,
  loading,
}: TileProps) => {
  return (
    <div className={classNames(styles.panelTile, className)}>
      <h4 className={styles.panelTileHeading}>{title}</h4>
      {loading ? <Loading small /> : children}
    </div>
  );
};

export const TileMainText = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="text-gray-900 text-3xl my-1 whitespace-nowrap min-w-fit">
      {children}
    </div>
  );
};

export const TileLine = ({ children }: { children: React.ReactNode }) => {
  return <div className="leading-loose">{children}</div>;
};

export default PanelTileGroup;
