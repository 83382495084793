import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import ModalContainer from "components/ModalContainer";
import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";

import UPDATE_MUTATION from "../EditParameterSets/Mutation.update.graphql";
import {
  UpdateExplainParameterSets,
  UpdateExplainParameterSetsVariables,
} from "../EditParameterSets/types/UpdateExplainParameterSets";
import {
  convertParamValue,
  convertToParamSetType,
  ParameterSetsType,
  ParamSetType,
  formatQueryTextWithParamValues,
  validateParamSet,
  ValueType,
  valueTypeValues,
} from "../ExplainWorkbook/util";
import ExpandableSQL from "components/ExpandableSQL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import Button from "components/Button";
import CopyToClipboard from "components/CopyToClipboard";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import { useParams } from "react-router-dom";

const EditParameterSetPanel = ({
  onDismiss,
  workbook,
  parameterSetId,
  refetchWorkbook,
}: {
  onDismiss: () => void;
  workbook: ExplainWorkbookType;
  parameterSetId: string;
  refetchWorkbook?: boolean;
}) => {
  const { databaseId } = useParams();
  const baselineQuery = workbook.baselineQuery;
  const [queryText, setQueryText] = useState(
    workbook.baselineQuery.queryTextWithAlias,
  );
  const [errorMessage, setErrorMessage] = useState("");
  const currAliasParamMap = workbook.aliasParamMapList.find(
    (ap) => ap.id === parameterSetId,
  );
  const [paramSet, setParamSet] = useState<ParamSetType>(
    convertToParamSetType(currAliasParamMap.parameters),
  );

  const [updateExplainParameterSets] = useMutation<
    UpdateExplainParameterSets,
    UpdateExplainParameterSetsVariables
  >(UPDATE_MUTATION);

  const handleUpdateParamSet = () => {
    const validateError = validateParamSet(
      paramSet,
      Object.keys(baselineQuery.paramRefAliasMap).length,
    );
    if (validateError) {
      setErrorMessage(validateError);
      return;
    }
    const newSet = Object.entries(paramSet).reduce((accum, [ref, set]) => {
      accum[ref] = {
        value: convertParamValue(set),
        type: set.type,
      };
      return accum;
    }, {} as ParamSetType);
    const parameterSets: ParameterSetsType = {};
    parameterSets[parameterSetId] = newSet;
    const refetchOpts = refetchWorkbook
      ? {
          refetchQueries: [
            {
              query: WORKBOOK_DETAIL_QUERY,
              variables: {
                workbookId: workbook.id,
                databaseId,
              },
            },
          ],
          awaitRefetchQueries: true,
        }
      : undefined;
    updateExplainParameterSets({
      variables: {
        workbookId: workbook.id,
        parameterSets: parameterSets,
        updateTypes: true,
      },
      ...refetchOpts,
      onCompleted: () => {
        setErrorMessage("");
        onDismiss();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  // TODO: lots of same functions exist in CreateParameterSetPanel, combine them
  const handlePreviewParameters = () => {
    const preview = formatQueryTextWithParamValues(
      workbook.baselineQuery.queryTextWithAlias,
      paramSet,
    );
    setQueryText(preview);
  };

  const handleParamValueChange = (alias: string, value: string) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    if (value === "" && !prevParam?.type && !prevParam?.isNull) {
      // remove from the set when the value becomes an empty string without type or flagged as null
      delete newSet[alias];
      setParamSet(newSet);
      return;
    }
    newSet[alias] = {
      value: value,
      type: prevParam?.type,
      isNull: prevParam?.isNull,
    };
    setParamSet(newSet);
  };
  const handleParamNullChange = (alias: string, isNull: boolean) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    // when null is checked but there is an existing value, empty it
    newSet[alias] = {
      value: isNull ? undefined : prevParam?.value,
      type: prevParam?.type,
      isNull: isNull,
    };
    setParamSet(newSet);
  };
  const handleParamTypeChange = (alias: string, type: string) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    if (type === "" && prevParam?.type != null) {
      // reset type (to fallback to auto detect)
      newSet[alias] = {
        value: prevParam?.value,
        type: null,
        isNull: prevParam?.isNull,
      };
      setParamSet(newSet);
      return;
    }
    // When type null is selected, update the value to null
    newSet[alias] = {
      value: prevParam?.value,
      type: type as ValueType,
      isNull: prevParam?.isNull,
    };
    setParamSet(newSet);
  };
  const inputFields = Object.entries(baselineQuery.paramRefAliasMap).flatMap(
    ([ref, alias]) => {
      const aliasStr = alias as string;
      return [
        <div key={`label-${ref}`}>{aliasStr}</div>,
        <input
          key={`input-${ref}`}
          className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5 disabled:bg-[#eee]"
          type="text"
          value={paramSet[aliasStr]?.value ?? ""}
          disabled={paramSet[aliasStr]?.isNull}
          onChange={(e) => handleParamValueChange(aliasStr, e.target.value)}
        />,
        <input
          key={`null-${ref}`}
          type="checkbox"
          checked={paramSet[aliasStr]?.isNull}
          onChange={(e) => handleParamNullChange(aliasStr, e.target.checked)}
        />,
        <select
          key={`type-${ref}`}
          value={paramSet[aliasStr]?.type ?? ""}
          onChange={(e) => handleParamTypeChange(aliasStr, e.target.value)}
          className="bg-inherit"
        >
          <option value="">auto detect</option>
          {valueTypeValues.map((typeName) => {
            return (
              <option key={typeName} value={typeName}>
                {typeName}
              </option>
            );
          })}
        </select>,
      ];
    },
  );

  return (
    <ModalContainer
      title={`Edit parameter set${
        refetchWorkbook ? ` (${currAliasParamMap.name})` : ""
      }`}
      layout="centered"
      onClose={onDismiss}
    >
      <div className="relative">
        <div className="rounded-md bg-[#f7fafc] border border-[#E8E8EE] p-4 mb-4 grid gap-2 text-[#606060]">
          <ExpandableSQL sql={queryText} />
        </div>
        <CopyToClipboard
          className="absolute top-1 right-2 text-[12px] text-[#999]"
          label="copy"
          content={queryText}
        />
      </div>
      <div>
        <div className="grid grid-cols-[min-content_1fr_40px_200px] gap-2 mb-2 items-center font-medium">
          <div>Name</div>
          <div>Value</div>
          <div>NULL</div>
          <div>Param Type</div>
          {Object.values(inputFields)}
        </div>
        <Button bare onClick={handlePreviewParameters}>
          <FontAwesomeIcon icon={faMagnifyingGlass} /> Preview Parameters
        </Button>
      </div>
      <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
      <div className="text-right mt-4">
        <button className="btn !px-10" onClick={onDismiss}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={handleUpdateParamSet}>
          Update Parameter Set
        </button>
      </div>
    </ModalContainer>
  );
};

export default EditParameterSetPanel;
