import React from "react";

import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import ExplainPanel from "components/ExplainPanel";

import QUERY from "./Query.graphql";
import {
  QueryExplain as QueryExplainType,
  QueryExplainVariables,
} from "./types/QueryExplain";
import PLAN_QUERY from "./Query.plan.graphql";
import {
  QueryPlan as QueryPlanType,
  QueryPlanVariables,
} from "./types/QueryPlan";
import QueryExplainError, {
  QueryExplainLoadError,
} from "components/QueryExplainError";
import { ExplainPlanType } from "components/Explain/util";

const QueryExplain = ({
  explainId,
  queryId,
  databaseId,
  blockSize,
}: {
  explainId: string;
  queryId: string;
  databaseId: string;
  blockSize: number;
}) => {
  // explainId either comes as humanId of QueryExplain, or "planid-originalplanid"
  const originalPlanId = explainId.split("planid-")[1];
  return originalPlanId ? (
    <QueryExplainWithPlan
      originalPlanId={originalPlanId}
      queryId={queryId}
      databaseId={databaseId}
      blockSize={blockSize}
    />
  ) : (
    <QueryExplainWithExplain
      explainId={explainId}
      databaseId={databaseId}
      blockSize={blockSize}
    />
  );
};

const QueryExplainWithExplain = ({
  explainId,
  databaseId,
  blockSize,
}: {
  explainId: string;
  databaseId: string;
  blockSize: number;
}) => {
  const { error, loading, data } = useQuery<
    QueryExplainType,
    QueryExplainVariables
  >(QUERY, {
    variables: {
      explainId,
      databaseId,
    },
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className="m-5">
        <QueryExplainLoadError databaseId={databaseId} />
      </div>
    );
  }

  const explain = data.getQueryExplain;

  if (!explain) {
    return <Loading error />;
  }

  if (explain.error) {
    return (
      <div className="m-5">
        <QueryExplainError
          error={explain.error}
          source={explain.source}
          queryText={explain.querySample?.queryText}
        />
      </div>
    );
  }

  return (
    <ExplainPanel
      explain={explain}
      databaseId={databaseId}
      blockSize={blockSize}
    />
  );
};

const QueryExplainWithPlan = ({
  originalPlanId,
  queryId,
  databaseId,
  blockSize,
}: {
  originalPlanId: string;
  queryId: string;
  databaseId: string;
  blockSize: number;
}) => {
  const { error, loading, data } = useQuery<QueryPlanType, QueryPlanVariables>(
    PLAN_QUERY,
    {
      variables: {
        originalPlanId,
        queryId,
        databaseId,
      },
    },
  );
  if (
    loading ||
    error ||
    !data.getQueryPlan ||
    !data.getQueryPlan.annotatedJson
  ) {
    return <Loading error={!!error} />;
  }
  const plan = data.getQueryPlan;
  const explain = {
    id: plan.id,
    seenAt: plan.planCapturedTime,
    querySample: {
      queryText: data.getQueryDetails.normalizedQuery,
      occurredAt: null,
      runtimeMs: null,
    },
    query: data.getQueryDetails,
    format: null,
    fingerprint: plan.planFingerprint,
    outputText: plan.explainPlan,
    annotatedJson: plan.annotatedJson,
    totalCost: plan.totalCost,
    totalSharedBlksRead: null,
    totalBlkReadTime: null,
  } as ExplainPlanType;
  return (
    <ExplainPanel
      explain={explain}
      databaseId={databaseId}
      blockSize={blockSize}
    />
  );
};

export default QueryExplain;
