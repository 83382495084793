import { useQuery } from "@apollo/client";

import {
  UseLoadServerIfNeeded,
  UseLoadServerIfNeededVariables,
} from "./types/UseLoadServerIfNeeded";
import QUERY from "./Query.graphql";

export const useLoadServerIfNeeded = (
  serverId: string | null | undefined,
  databaseId: string | null | undefined,
) => {
  const { data, loading, error } = useQuery<
    UseLoadServerIfNeeded,
    UseLoadServerIfNeededVariables
  >(QUERY, {
    variables: { databaseId },
    skip: !!serverId || !databaseId,
  });
  if (loading || error) {
    return { serverId: null, serverIdLoaded: false };
  }
  if (serverId) {
    return { serverId, serverIdLoaded: true };
  }
  if (!databaseId) {
    return { serverId: null, serverIdLoaded: true };
  }
  const fetchedServerId = data?.getServerDetails?.humanId;
  if (!fetchedServerId) {
    return { serverId: null, serverIdLoaded: false };
  }

  return {
    serverId: fetchedServerId,
    serverIdLoaded: true,
  };
};
