import imgLogExplainOverview from './log-explain-overview.png'
import imgLogExplainDetails from './log-explain-details.png'

export const ImgLogExplainOverview = () => <img src={imgLogExplainOverview} alt="Screenshot of EXPLAIN list in pganalyze" />

export const ImgLogExplainDetails = () => <img src={imgLogExplainDetails} alt="Screenshot of EXPLAIN plan in pganalyze" />

Assuming the collector is running in the background, after a few minutes you should then see EXPLAIN plans show up in the pganalyze EXPLAIN plan list:

<ImgLogExplainOverview />

When you select one of the query executions in this list, you will see details of the corresponding plan:

<ImgLogExplainDetails />

From now on, you will now get EXPLAIN plans automatically in the pganalyze UI, for all your slow queries.

[Learn more about how pganalyze works with EXPLAIN data.](https://pganalyze.com/docs/explain)
