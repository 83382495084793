import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import classNames from "classnames";

import LogLineContent from "../LogLineContent";
import LogLineDetails from "../LogLineDetails";
import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

import {
  LogLinesVariables,
  LogLines as LogLinesType,
  LogLines_getLogLines,
} from "./types/LogLines";
import Loading from "components/Loading";
import { useLocation } from "react-router-dom";
import { useDateRange } from "components/WithDateRange";

type Props = {
  hideIfEmpty?: boolean;
  focusedOccurredAt?: string | null | undefined;
  focusLineHandler: (line: number | null | undefined) => void;
  onReady?: () => void;
} & LogLinesVariables;

const LogLines: React.FunctionComponent<Props> = ({
  databaseId,
  serverId,
  classification,
  occurredBefore,
  occurredAfter,
  backendPid,
  postgresRoleId,
  queryFingerprint,
  onReady,
  ...rest
}) => {
  // if occurredBefore is set, fetch logs to that time
  // if cleared, but a line is focused, use last set occurredBefore
  // fall back to "to" timestamp from useDateRange
  const focusedId = useFocusedLogOccurredAt();
  const [{ from, to }] = useDateRange();
  const [derivedOccurredBefore, setDerivedOccurredBefore] =
    useState<number>(occurredBefore);
  useEffect(() => {
    if (focusedId && !occurredBefore) {
      return;
    }
    setDerivedOccurredBefore(occurredBefore);
  }, [focusedId, occurredBefore]);

  const { data, loading, error } = useQuery<LogLinesType, LogLinesVariables>(
    QUERY,
    {
      variables: {
        databaseId,
        serverId,
        classification,
        occurredBefore: derivedOccurredBefore || to.unix(),
        occurredAfter: occurredAfter || from.unix(),
        backendPid,
        postgresRoleId,
        queryFingerprint,
      },
    },
  );
  useEffect(() => {
    if (
      !onReady ||
      loading ||
      (!data && !error) ||
      data?.getLogLines?.length === 0
    ) {
      return;
    }
    onReady();
  }, [data, loading, error, onReady]);

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <LogLinesDisplay
      data={data}
      databaseId={databaseId}
      serverId={serverId}
      {...rest}
    />
  );
};

type DisplayProps = Props & {
  data: LogLinesType;
};

const LogLinesDisplay: React.FunctionComponent<DisplayProps> = ({
  data,
  hideIfEmpty,
  focusedOccurredAt: propsFocusedOccurredAt,
  focusLineHandler,
  serverId,
  databaseId,
}) => {
  const routeFocusedOccurredAt = useFocusedLogOccurredAt();
  const focusedOccurredAt = propsFocusedOccurredAt || routeFocusedOccurredAt;
  const logLines = data.getLogLines;

  if (logLines.length === 0 && hideIfEmpty) {
    return null;
  }

  return (
    <div>
      <ul className={styles.logContainer}>
        {logLines.length > 0 &&
          logLines.map((logLine) => (
            <LogLineItem
              key={logLine.occurredAt}
              focused={logLine.occurredAt === focusedOccurredAt}
              focusLineHandler={focusLineHandler}
              logLine={logLine}
              serverId={serverId}
              databaseId={databaseId}
            />
          ))}
        {logLines.length === 0 && "No recent log entries"}
      </ul>
    </div>
  );
};

const LogLineItem: React.FunctionComponent<{
  logLine: LogLines_getLogLines;
  focused: boolean;
  focusLineHandler: (line: number | null | undefined) => void;
  serverId: string;
  databaseId: string;
}> = ({ focused, focusLineHandler, logLine, serverId, databaseId }) => {
  return (
    <li key={logLine.occurredAt} className={styles.logLine}>
      <span
        className={
          (focused && styles.logLineFocusFocused) || styles.logLineFocus
        }
      >
        <a
          href=""
          onClick={(evt) => {
            evt.preventDefault();
            focusLineHandler(focused ? null : logLine.occurredAt);
          }}
        >
          <span
            className={classNames(
              "fa",
              focused ? "fa-times-circle" : "fa-plus-circle",
            )}
          />
        </a>
      </span>
      {focused ? (
        <LogLineDetails
          logLine={logLine}
          serverId={serverId}
          databaseId={databaseId}
        />
      ) : (
        <LogLineContent
          logLine={logLine}
          serverId={serverId}
          databaseId={databaseId}
        />
      )}
    </li>
  );
};

const useFocusedLogOccurredAt = () => {
  const loc = useLocation();
  const id = loc.hash.slice(1);
  // t= prefix is used for general time navigation, otherwise we assume an occurred_at timestamp as ID
  return id.startsWith("t=") ? undefined : Number(id);
};

export default LogLines;
