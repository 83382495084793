import React, { useState } from "react";
import { treemap as d3Treemap, hierarchy, HierarchyNode } from "d3-hierarchy";
import { scaleOrdinal } from "d3-scale";
import { formatBytes } from "utils/format";

import { colors, isDark } from "utils/colors";

import styles from "./style.module.scss";
import NoData from "components/Graph/NoData";
import { useDimensions } from "utils/hooks";
import Popover from "components/Popover";
import { Link } from "react-router-dom";

export type TreeMapDataType = {
  displayName?: string;
  name: string;
  link: string;
  size: number;
};

type NodeType = {
  annotation?: React.ReactNode;
  displayName?: string;
  size: number;
  name: string;
  link?: string;
  last?: boolean;
  children?: NodeType[];
};

type LeafType = {
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  data: NodeType;
};

type Props = {
  className?: string;
  data: Array<TreeMapDataType>;
};

const TreeMapImpl: React.FC<Props & { width: number; height: number }> = ({
  data,
  className,
  width,
}) => {
  const totalSize = data.reduce((totSize, elem) => totSize + elem.size, 0);
  const treeData: NodeType[] = [];
  const others: NodeType[] = [];

  const [hover, setHover] = useState(null);

  data.forEach(({ displayName, name, link, size }: TreeMapDataType) => {
    if (size < totalSize / 100.0) {
      // Smaller 1% => "Other"
      others.push({ displayName, name, link, size });
    } else {
      treeData.push({
        annotation: (
          <>
            <strong>Size:</strong> {formatBytes(size)}
          </>
        ),
        displayName,
        size,
        name,
        link,
      });
    }
  });

  if (others.length > 0) {
    const othersHtml = others
      .sort((a: TreeMapDataType, b: TreeMapDataType): number => b.size - a.size)
      .slice(0, 10)
      .map(({ displayName, name, size }: TreeMapDataType): React.ReactNode => {
        return (
          <li key={"table:" + name}>
            {displayName || name} ({formatBytes(size)})
          </li>
        );
      });

    if (others.length > 10) {
      othersHtml.push(<li key="more">... ({others.length - 10} more)</li>);
    }

    const othersSize = others.reduce((totSize, node) => totSize + node.size, 0);
    treeData.push({
      name: `${others.length} Others (${formatBytes(othersSize)})`,
      last: true,
      size: othersSize,
      annotation: <ul className={styles.treemapOthersList}>{othersHtml}</ul>,
    });
  }

  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = width / 5 - margin.top - margin.bottom;

  const treemap = d3Treemap<NodeType>()
    .size([innerWidth, innerHeight])
    .round(true);

  const genericRoot = hierarchy<NodeType>({
    name: "",
    children: treeData,
    size: null,
  })
    .sort((a: HierarchyNode<NodeType>, b: HierarchyNode<NodeType>): number => {
      if (a.data.last) {
        return b.data.size;
      }
      if (b.data.last) {
        return -a.data.size;
      }
      return b.data.size - a.data.size;
    })
    .sum((d: NodeType): number => d.size);

  const root = treemap(genericRoot);

  const color = scaleOrdinal(colors).domain(
    root.leaves().map((d: { data: NodeType }): string => d.data.name),
  );

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    const name = e.currentTarget.dataset["name"];
    const item = root.leaves().find((item) => item.data.name === name);
    setHover({
      item: item,
      elem: e.currentTarget,
    });
  };
  const handleMouseLeave = () => {
    setHover(null);
  };

  return (
    <div className={className} onMouseLeave={handleMouseLeave}>
      <Popover
        title={hover && <b>{hover.item.data.name}</b>}
        content={hover && hover.item.data.annotation}
        anchor={hover && hover.elem}
      />
      <div
        style={{
          position: "relative",
          width,
          height: innerHeight + margin.top + margin.bottom,
          top: margin.top,
          left: margin.left,
        }}
      >
        {root.leaves().map((leaf: LeafType) => {
          const left = leaf.x0;
          const top = leaf.y0;
          const width = Math.max(0, leaf.x1 - leaf.x0 + 1);
          const height = Math.max(0, leaf.y1 - leaf.y0 + 1);

          const background = color(leaf.data.name);
          const textColor = isDark(background) ? "white" : "black";

          const node = (
            <div
              key={leaf.data.name}
              data-name={leaf.data.name}
              className={styles.node}
              style={{ top, left, width, height, background, color: textColor }}
              onMouseEnter={handleMouseEnter}
            >
              {leaf.data.displayName || leaf.data.name}
            </div>
          );
          return leaf.data.link ? (
            <Link key={leaf.data.name} to={leaf.data.link}>
              {node}
            </Link>
          ) : (
            node
          );
        })}
      </div>{" "}
    </div>
  );
};

const TreeMap: React.FunctionComponent<Props> = ({ data, ...rest }) => {
  const [ref, dims] = useDimensions();
  if (data.length === 0) {
    return (
      <div className={styles.noDataWrapper}>
        <div className={styles.noData}>
          <NoData />
        </div>
      </div>
    );
  }
  return (
    <div ref={ref}>
      {dims && (
        <TreeMapImpl
          width={dims.width}
          height={dims.height}
          data={data}
          {...rest}
        />
      )}
    </div>
  );
};

export default TreeMap;
