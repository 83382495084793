import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTitleSearch from "components/PanelTitleSearch";
import PageContent from "components/PageContent";
import SettingsNav from "components/SettingsNav";
import { useRoutes } from "utils/routes";

import {
  ServerSettingsList as ServerSettingsListType,
  ServerSettingsListVariables,
  ServerSettingsList_getServers as ServerType,
} from "./types/ServerSettingsList";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import { StatusDotIcon } from "components/Icons";
import Grid from "components/Grid";
import { makeFilter } from "utils/filter";

type RowDataType = ServerType & {
  collectorVersion: string;
};

const ServerSettingsList: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [showInactive, setShowInactive] = useState(false);
  const { data, loading, error } = useQuery<
    ServerSettingsListType,
    ServerSettingsListVariables
  >(QUERY, { variables: { organizationSlug } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const titleSearch = (
    <>
      <div className={styles.showInactiveControl}>
        <input
          type="checkbox"
          checked={showInactive}
          id="show_inactive"
          onChange={(evt) => setShowInactive(evt.target.checked)}
        />
        <label htmlFor="show_inactive">
          Show servers with no recent statistics data
        </label>
      </div>
      <PanelTitleSearch
        value={searchTerm}
        onChange={(newTerm: string) => {
          setSearchTerm(newTerm);
        }}
      />
    </>
  );

  const servers = data.getServers.map((s: ServerType): RowDataType => {
    return {
      ...s,
      collectorVersion: s.lastSnapshot?.collectorVersion,
    };
  });

  const gridData = servers
    .filter(makeFilter(searchTerm, "name"))
    .filter((s) => showInactive || s.hasRecentSnapshot);

  return (
    <PageContent
      title="Settings"
      pageCategory="servers"
      pageName="edit"
      featureNav={<SettingsNav />}
    >
      <Panel title="All Servers" secondaryTitle={titleSearch}>
        <PanelSection>Select a server to view settings:</PanelSection>
        <Grid
          className="grid-cols-[minmax(30%,1fr),repeat(3,20%)]"
          striped
          defaultSortBy="name"
          data={gridData}
          columns={[
            {
              field: "name",
              header: "Server",
              renderer: ServerNameCell,
            },
            {
              field: "displaySystemType",
              header: "System type",
            },
            {
              field: "collectorVersion",
              header: "Collector Version",
            },
            {
              field: "hasRecentSnapshot",
              header: "Has Recent Snapshot?",
              renderer: function HasRecentSnapshotCell({ fieldData }) {
                return fieldData ? (
                  <>
                    <StatusDotIcon
                      status="okay"
                      title="Recently received statistics data"
                      fixedWidth
                    />
                    Yes
                  </>
                ) : (
                  <>
                    <StatusDotIcon status="no data" fixedWidth />
                    No
                  </>
                );
              },
            },
          ]}
        />
      </Panel>
    </PageContent>
  );
};

const ServerNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { serverEdit } = useRoutes();
  return (
    <span>
      <Link to={serverEdit(rowData.humanId)}>{rowData.name}</Link>
    </span>
  );
};

export default ServerSettingsList;
