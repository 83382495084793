import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useRoutes } from "utils/routes";
import CheckStatusBadge from "components/CheckStatusBadge";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import SQL from "components/SQL";

import {
  ResolvedIssueList as ResolvedIssueListType,
  ResolvedIssueListVariables,
  ResolvedIssueList_getIssues as IssueType,
} from "./types/ResolvedIssueList";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import Grid from "components/Grid";
import { makeFilter } from "utils/filter";

type Props = {
  organizationSlug?: string;
  serverId?: string;
  databaseId?: string;
  checkGroupAndName: string;
  startTs?: number;
  endTs?: number;
};

const ISSUE_SEVERITY_TO_ORDER = {
  critical: 3,
  warning: 2,
  info: 1,
  okay: 0,
};

const ResolvedIssueList: React.FunctionComponent<Props> = ({
  organizationSlug,
  serverId,
  databaseId,
  checkGroupAndName,
  startTs,
  endTs,
}) => {
  const { server, database, serverIssue, databaseIssue } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");
  const now = useMemo(() => moment(), []);
  const { data, loading, error } = useQuery<
    ResolvedIssueListType,
    ResolvedIssueListVariables
  >(QUERY, {
    variables: {
      organizationSlug,
      serverId,
      databaseId,
      checkGroupsAndNames: [checkGroupAndName],
      state: "resolved",
      startTs: startTs || now.clone().subtract(30, "d").unix(),
      endTs: endTs || now.unix(),
    },
  });
  if (loading || error || !data) {
    return <Loading error={!!error} />;
  }

  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(newTerm: string) => {
        setSearchTerm(newTerm);
      }}
    />
  );
  const issueUrl = (issue: IssueType): string => {
    return issue.database
      ? databaseIssue(issue.database.id, issue.id, checkGroupAndName)
      : serverIssue(issue.server.humanId, issue.id, checkGroupAndName);
  };

  const issues = data.getIssues
    .map((i) => ({
      serverName: i.server.name,
      databaseId: i.database?.id ?? "?",
      databaseName: i.database?.datname,
      severityOrder: ISSUE_SEVERITY_TO_ORDER[i.severity],
      triggeredAt: i.createdAt,
      resolvedAt: i.updatedAt,
      duration: i.updatedAt - i.createdAt,
      ...i,
    }))
    .filter(
      makeFilter(
        searchTerm,
        "severity",
        "serverName",
        "databaseName",
        "description",
        "queryText",
      ),
    );

  return (
    <Panel title="Resolved In Last 30 Days" secondaryTitle={titleSearch}>
      <Grid
        className="grid-cols-[minmax(3%,96px),repeat(2,minmax(5%,120px)),minmax(20%,1fr),minmax(10%,220px),minmax(5%,100px),minmax(10%,220px),]"
        striped
        data={issues}
        defaultSortBy="resolvedAt"
        columns={[
          {
            field: "severityOrder",
            header: "Severity",
            renderer: function SeverityCell({ rowData }) {
              return <CheckStatusBadge status={rowData.severity} />;
            },
          },
          {
            field: "serverName",
            header: "Server",
            renderer: function ServerCell({ rowData, fieldData }) {
              return (
                <Link to={server(rowData.server.humanId)}>{fieldData}</Link>
              );
            },
          },
          {
            field: "databaseName",
            header: "Database",
            nullValue: "n/a",
            renderer: function DatabaseCell({ rowData, fieldData }) {
              return <Link to={database(rowData.databaseId)}>{fieldData}</Link>;
            },
          },
          {
            field: "description",
            header: "Description",
            nullValue: "n/a",
            renderer: function DescriptionCell({ rowData, fieldData }) {
              const { queryText } = rowData;
              return (
                <Link to={issueUrl(rowData)}>
                  {fieldData}
                  {queryText && (
                    <div className={styles.issueQuery}>
                      <SQL inline sql={queryText} />
                    </div>
                  )}
                </Link>
              );
            },
          },
          {
            field: "triggeredAt",
            header: "First Triggered At",
            nullValue: "n/a",
            defaultSortOrder: "desc",
            renderer: function TriggeredAtCell({ fieldData }) {
              return moment.unix(fieldData).format("ll LTS z");
            },
          },
          {
            field: "duration",
            header: "Duration",
            nullValue: "n/a",
            defaultSortOrder: "desc",
            renderer: function DurationCell({ fieldData }) {
              return moment.duration(fieldData * 1000).humanize();
            },
          },
          {
            field: "resolvedAt",
            header: "Resolved At",
            nullValue: "n/a",
            defaultSortOrder: "desc",
            renderer: function ResolvedAtCell({ fieldData }) {
              return moment.unix(fieldData).format("ll LTS z");
            },
          },
        ]}
      />
    </Panel>
  );
};

export default ResolvedIssueList;
