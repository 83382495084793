import React from "react";
import { useDateRange } from "components/WithDateRange";
import DateRangeSelector from "components/DateRangeSelector";

import { formatTimestampShorter } from "utils/format";
import pluralize from "pluralize";
import moment, { Moment } from "moment-timezone";

import styles from "./style.module.scss";

const formatAvailableInterval = (earliest: Moment): string => {
  if (earliest.isSameOrAfter(moment().subtract(25, "hours"))) {
    const hours = Math.round(moment().diff(earliest, "hours", true));
    return hours + " " + pluralize("hour", hours);
  } else {
    const days = Math.round(moment().diff(earliest, "days", true));
    return days + " " + pluralize("day", days);
  }
};

type Props = {
  feature?: string;
};

const DateRangeBar: React.FunctionComponent<Props> = ({ feature }) => {
  const [{ from, to, label, earliest, unsyncable }, setRange] = useDateRange();

  const tip = `Data available since ${formatTimestampShorter(earliest)}`;
  const unsyncNotice = unsyncable && (
    <div title={tip} className={styles.tip}>
      {feature || "this"} data only available for last{" "}
      {formatAvailableInterval(earliest)}
    </div>
  );

  const adjustedEarliest = moment.min(earliest, from);

  return (
    <div className={styles.container}>
      {unsyncNotice}
      <DateRangeSelector
        from={from}
        to={to}
        label={label}
        earliest={adjustedEarliest}
        onChange={setRange}
      />
    </div>
  );
};

export default DateRangeBar;
