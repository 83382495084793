import React from "react";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";

import { formatBytes } from "utils/format";

import { AreaSeries, LineSeries } from "components/Graph/Series";
import { Data } from "components/Graph/util";
import { useDateRange } from "components/WithDateRange";

import { Memory as MemoryType, MemoryVariables } from "./types/Memory";
import QUERY from "./Query.graphql";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";

type Props = {
  serverId: string;
};

const Memory: React.FunctionComponent<Props> = ({ serverId }) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<MemoryType, MemoryVariables>(
    QUERY,
    {
      variables: {
        serverId,
        startTs: newStartTs.unix(),
        endTs: newEndTs.unix(),
      },
    },
  );

  const noData = !loading && !error && data.getSystemStats == null;

  const secondaryTitle = (
    <span>
      <strong>Note:</strong> Process Memory includes Postgres{" "}
      <code>shared_buffers</code>
    </span>
  );

  return (
    <Panel title="Memory" secondaryTitle={secondaryTitle}>
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: formatBytes,
            },
          }}
          series={[
            {
              key: "memoryTotal",
              type: LineSeries,
              label: "Total Memory",
            },
            {
              key: "memoryProcess",
              type: AreaSeries,
              label: "Process Memory",
            },
            {
              key: "memoryPageCache",
              type: AreaSeries,
              label: "OS Page Cache",
            },
            { key: "memoryBuffers", type: AreaSeries, label: "OS Buffers" },
            {
              key: "memoryFree",
              type: AreaSeries,
              label: "Free Memory",
            },
            { key: "memorySwap", type: AreaSeries, label: "Swap In Use" },
          ]}
        />
      </GraphSection>
    </Panel>
  );
};

export default Memory;
