import React from "react";

import { Link } from "react-router-dom";

export type StepType = [title: string, path: string];

const DocSteps: React.FunctionComponent<{
  steps: StepType[];
  step: string;
}> = ({ steps, step }) => {
  if (!steps || !step) {
    return null;
  }

  const currStepNum = parseInt(step);
  if (currStepNum < 1) {
    return null;
  }
  return (
    <ul className="install-step-nav">
      {steps.map((step, idx) => {
        const [label, path] = step;
        const idx1based = idx + 1;
        return (
          <Step
            key={idx}
            label={label}
            href={"../" + path}
            number={idx1based}
            active={currStepNum === idx1based}
            disabled={idx1based > currStepNum}
          />
        );
      })}
    </ul>
  );
};

const Step: React.FunctionComponent<{
  number: number;
  href: string;
  label: string;
  active: boolean;
  disabled: boolean;
}> = ({ number, href, label, active, disabled }) => {
  return (
    <>
      <li className={disabled ? "disabled" : active ? "active" : undefined}>
        {disabled ? (
          <div className="step-number">{number}</div>
        ) : (
          <Link to={href}>
            <div className="step-number">{number}</div> {label}
          </Link>
        )}
      </li>
      {disabled && " "}
    </>
  );
};

export default DocSteps;
