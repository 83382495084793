import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import sortBy from "lodash/sortBy";

import Form from "components/Form";
import FormSelectField from "components/FormSelectField";
import FormSubmit from "components/FormSubmit";
import FormTextField from "components/FormTextField";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";

import {
  MemberNew as MemberNewType,
  MemberNewVariables,
  MemberNew_getOrganizationRoles as OrganizationRoleType,
} from "./types/MemberNew";
import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

const MemberNew: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const { organizationMembers } = useRoutes();
  const { data, loading, error } = useQuery<MemberNewType, MemberNewVariables>(
    QUERY,
    {
      variables: { organizationSlug },
    },
  );

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const roles = sortBy(
    data.getOrganizationRoles,
    (k: OrganizationRoleType): number => k.updatedAt,
  );

  return (
    <PageContent
      title={`Organization "${data.getOrganizationDetails.name}"`}
      pageCategory="organization-members"
      pageName="new"
    >
      <Panel title="Invite Organization Member">
        <Form action={organizationMembers(organizationSlug)}>
          <FormTextField
            type="email"
            name="membership[invite_email]"
            required={true}
            placeholder="user@example.com"
            autoFocus={true}
            label="E-Mail"
          />
          <FormSelectField
            name="membership[role_ids][]"
            required={true}
            multiple={true}
            label="Assigned Roles"
            options={roles.map(
              (r: OrganizationRoleType): Array<string> => [r.id, r.name],
            )}
            helpText="Select one or multiple roles to apply to this member. Each role may contain multiple permissions, check the Roles page for details."
          />
          <FormSubmit title="Send Invite" />
        </Form>
      </Panel>
    </PageContent>
  );
};

export default MemberNew;
