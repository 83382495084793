import { Datum } from "components/Graph/util";
import {
  VacuumSimulator_getVacuumSimulatorInput_tableStats as SchemaTableStatsType,
  VacuumSimulator_getVacuumSimulatorInput as VacuumSimulatorInputType,
} from "./types/VacuumSimulator";

export type AutovacuumCount = {
  total: number[];
  deadRows: number[];
  inserts: number[];
  freezing: number[];
};

type SimulationData = {
  totalAutovacuumCount: AutovacuumCount;
  deadRowsVacuumed: Datum[];
  deadRowsThresholdData: Datum[];
  frozenxidAgeVacuumed: Datum[];
  freezeAgeThresholdData: Datum[];
  minmxidAgeVacuumed: Datum[];
  minmxidAgeThresholdData: Datum[];
  insertRowsVacuumed: Datum[];
  insertRowsThresholdData: Datum[];
  avoidableGrowthRows: Datum[];
  reusableRows: Datum[];
};

type TableVacuumSettingsType = {
  autovacuumThreshold: number;
  scaleFactor: number;
  freezeMaxAge: number;
  mxidFreezeMaxAge: number;
  freezeMinAge: number;
  mxidFreezeMinAge: number;
  vacuumFreezeTableAge: number;
  vacuumMultixactFreezeTableAge: number;
  insertThreshold: number;
  insertScaleFactor: number;
};

export const simulateVacuum = (
  tableStats: SchemaTableStatsType,
  simulatorInput: VacuumSimulatorInputType,
  tableVacuumSettings: TableVacuumSettingsType,
  ignoreInitialReusableRows: boolean,
): SimulationData => {
  // See app/services/vacuum_insight_service.rb for the original implementation
  let currTotalRow = tableStats.liveTuples[0][1];
  let currFrozenxidAge = tableStats.frozenxidAge[0][1];
  let currMinmxidAge = tableStats.minmxidAge[0][1];

  let currDeadRows = tableStats.deadTuples[0][1];
  let currInsertRows = tableStats.insertsSinceVacuum[0][1];
  let currReusableRows = ignoreInitialReusableRows
    ? 0
    : Math.max(simulatorInput.initialBloatRows - currDeadRows, 0);
  const runInsertsVacuum = (tableVacuumSettings.insertThreshold ?? -1) !== -1;

  let avoidableRowGrowth = 0;
  // reusable rows: dead rows already vacuumed, can be reused for new rows
  // reusable dead rows:
  //   dead rows not yet vacuumed, could have been reusable rows if vacuumed early
  //   reset this with vacuum, also reduce this when dead rows are counted toward to
  //   avoidable growth
  let reusableDeadRows = currDeadRows;
  const simulationData: SimulationData = {
    totalAutovacuumCount: {
      total: [],
      freezing: [],
      deadRows: [],
      inserts: [],
    },
    deadRowsVacuumed: [],
    deadRowsThresholdData: [],
    frozenxidAgeVacuumed: [],
    freezeAgeThresholdData: [],
    minmxidAgeVacuumed: [],
    minmxidAgeThresholdData: [],
    insertRowsVacuumed: [],
    insertRowsThresholdData: [],
    avoidableGrowthRows: [],
    reusableRows: [],
  };

  // tableStats is a bit different shape from the backend version
  // use liveTuples as the base
  tableStats.liveTuples.forEach((dat, i) => {
    const inserts = tableStats.inserts[i][1];
    const updates = tableStats.updates[i][1];
    const deletes = tableStats.deletes[i][1];
    const newRows = inserts + updates;
    let deadRows = deletes + updates;
    const collectedAt = dat[0];
    // Fill null data in case no data (TODO: improve no data case)
    if (dat[1] == null) {
      simulationData.deadRowsVacuumed.push([collectedAt, null]);
      simulationData.deadRowsThresholdData.push([collectedAt, null]);
      simulationData.frozenxidAgeVacuumed.push([collectedAt, null]);
      simulationData.minmxidAgeThresholdData.push([collectedAt, null]);
      simulationData.minmxidAgeVacuumed.push([collectedAt, null]);
      simulationData.freezeAgeThresholdData.push([collectedAt, null]);
      simulationData.insertRowsVacuumed.push([collectedAt, null]);
      simulationData.insertRowsThresholdData.push([collectedAt, null]);
      simulationData.avoidableGrowthRows.push([collectedAt, null]);
      simulationData.reusableRows.push([collectedAt, null]);
      return;
    }

    if (i !== 0) {
      const collectDiffInSec = collectedAt - tableStats.liveTuples[i - 1][0];
      // Usually, deadRows (dead tuples) is the sum of deletes and updates.
      // However, especially with hot updates, dead tuples won't go up as much,
      // since heap pruning operations during hot updates will reclaim some tuples.
      // To perform a better simulation, use the diff of deadTuples when available.
      const currDeadTuples = tableStats.deadTuples[i][1];
      const prevDeadTuples = tableStats.deadTuples[i - 1][1];
      if (currDeadTuples - prevDeadTuples > 0) {
        deadRows = currDeadTuples - prevDeadTuples;
      }

      currDeadRows += deadRows;
      currInsertRows += inserts;
      currTotalRow = tableStats.liveTuples[i][1];
      currFrozenxidAge += simulatorInput.xactPerSec * collectDiffInSec;
      currMinmxidAge += simulatorInput.multixactPerSec * collectDiffInSec;
      reusableDeadRows += deadRows;
    }

    if (newRows <= currReusableRows) {
      // new rows are smaller than reusable rows, simply subtract new rows from reusable rows
      currReusableRows -= newRows;
    } else {
      // calculate how many rows are exceeding reusable rows
      const needToGrowRows = newRows - currReusableRows;
      currReusableRows = 0;
      if (needToGrowRows <= reusableDeadRows) {
        // need to grow less rows than reusable dead rows
        // these rows could have been used reusable rows if dead rows are vacuumed
        avoidableRowGrowth += needToGrowRows;
        reusableDeadRows -= needToGrowRows;
      } else {
        // count all reusable dead rows towards to avoidable row growth and set
        // reusable dead rows to zero
        avoidableRowGrowth += reusableDeadRows;
        reusableDeadRows = 0;
      }
    }

    const deadRowsThreshold =
      tableVacuumSettings.autovacuumThreshold +
      currTotalRow * tableVacuumSettings.scaleFactor;
    const insertThreshold =
      tableVacuumSettings.insertThreshold +
      currTotalRow * tableVacuumSettings.insertScaleFactor;

    const needFreezeVacuum =
      currFrozenxidAge > tableVacuumSettings.freezeMaxAge ||
      currMinmxidAge > tableVacuumSettings.mxidFreezeMaxAge;
    const aggressiveVacuum =
      currFrozenxidAge > tableVacuumSettings.vacuumFreezeTableAge ||
      currMinmxidAge > tableVacuumSettings.vacuumMultixactFreezeTableAge;
    const needDeadRowsVacuum = currDeadRows >= deadRowsThreshold;
    const needInsertVacuum =
      runInsertsVacuum && currInsertRows >= insertThreshold;

    simulationData.deadRowsVacuumed.push([collectedAt, currDeadRows]);
    simulationData.deadRowsThresholdData.push([collectedAt, deadRowsThreshold]);
    simulationData.frozenxidAgeVacuumed.push([collectedAt, currFrozenxidAge]);
    simulationData.freezeAgeThresholdData.push([
      collectedAt,
      tableVacuumSettings.freezeMaxAge,
    ]);
    simulationData.minmxidAgeVacuumed.push([collectedAt, currMinmxidAge]);
    simulationData.minmxidAgeThresholdData.push([
      collectedAt,
      tableVacuumSettings.mxidFreezeMaxAge,
    ]);
    simulationData.insertRowsVacuumed.push([collectedAt, currInsertRows]);
    simulationData.insertRowsThresholdData.push([collectedAt, insertThreshold]);
    simulationData.avoidableGrowthRows.push([collectedAt, avoidableRowGrowth]);
    simulationData.reusableRows.push([collectedAt, currReusableRows]);
    if (!(needFreezeVacuum || needDeadRowsVacuum || needInsertVacuum)) {
      return;
    }

    simulationData.totalAutovacuumCount.total.push(collectedAt);
    if (needFreezeVacuum) {
      simulationData.totalAutovacuumCount.freezing.push(collectedAt);
    }
    if (needDeadRowsVacuum) {
      simulationData.totalAutovacuumCount.deadRows.push(collectedAt);
    }
    if (needInsertVacuum) {
      simulationData.totalAutovacuumCount.inserts.push(collectedAt);
    }

    currInsertRows = 0;
    // TODO: Potentially perform freezing for non-aggressive vacuum if applicable
    if (aggressiveVacuum) {
      // Only reset up to min age
      currFrozenxidAge = Math.min(
        currFrozenxidAge,
        tableVacuumSettings.freezeMinAge,
      );
      currMinmxidAge = Math.min(
        currMinmxidAge,
        tableVacuumSettings.mxidFreezeMinAge,
      );
    }
    currReusableRows += currDeadRows;
    currDeadRows = 0;
    reusableDeadRows = 0;
  });

  return simulationData;
};
