// See https://blog.jcoglan.com/2017/02/17/the-myers-diff-algorithm-part-3/

type DiffableLine<T> = {
  n: number;
  content: T;
};

export type Diffable<T> = DiffableLine<T>[];

type DiffTrace = number[][];

function shortestEdit<T>(
  a: Diffable<T>,
  b: Diffable<T>,
  matcher: (a: T, b: T) => boolean,
): DiffTrace {
  const aLen = a.length;
  const bLen = b.length;
  const max = aLen + bLen;
  const offset = max;

  const edits = Array.from<number>({ length: 2 * max + 1 });
  edits[1 + offset] = 0;

  const trace = [];

  for (let d = 0; d <= max; d++) {
    trace.push(edits.slice());

    for (let k = -d; k <= d; k += 2) {
      let x: number, y: number;

      if (
        k === -d ||
        (k != d && edits[k - 1 + offset] < edits[k + 1 + offset])
      ) {
        x = edits[k + 1 + offset];
      } else {
        x = edits[k - 1 + offset] + 1;
      }

      y = x - k;

      while (x < aLen && y < bLen && matcher(a[x].content, b[y].content)) {
        x++, y++;
      }

      edits[k + offset] = x;

      if (x >= aLen && y >= bLen) {
        return trace;
      }
    }
  }
  throw new Error("unreachable");
}

type DiffEdit<T> =
  | {
      kind: "insert";
      b: DiffableLine<T>;
    }
  | {
      kind: "delete";
      a: DiffableLine<T>;
    }
  | {
      kind: "equal";
      a: DiffableLine<T>;
      b: DiffableLine<T>;
    };

type Diff<T> = DiffEdit<T>[];

export function diff<T>(
  a: Diffable<T>,
  b: Diffable<T>,
  matcher: (a: T, b: T) => boolean = (a, b) => a == b,
): Diff<T> {
  const diff: Diff<T> = [];

  function recordEdit(prevX: number, prevY: number, x: number, y: number) {
    const aLine = a[prevX];
    const bLine = b[prevY];

    if (x == prevX) {
      diff.unshift({ kind: "insert", b: bLine });
    } else if (y == prevY) {
      diff.unshift({ kind: "delete", a: aLine });
    } else {
      diff.unshift({ kind: "equal", a: aLine, b: bLine });
    }
  }

  const aLen = a.length;
  const bLen = b.length;
  const max = aLen + bLen;
  const offset = max;

  let x = a.length;
  let y = b.length;

  const trace = shortestEdit(a, b, matcher);
  trace
    .map((item, idx) => [item, idx] as const)
    .reverse()
    .forEach(([edits, d]) => {
      const k = x - y;
      let prevK;

      if (
        k == -d ||
        (k != d && edits[k - 1 + offset] < edits[k + 1 + offset])
      ) {
        prevK = k + 1;
      } else {
        prevK = k - 1;
      }

      const prevX = edits[prevK + offset];
      const prevY = prevX - prevK;

      while (x > prevX && y > prevY) {
        recordEdit(x - 1, y - 1, x, y);
        x = x - 1;
        y = y - 1;
      }

      if (d > 0) {
        recordEdit(prevX, prevY, x, y);
      }

      x = prevX;
      y = prevY;
    });

  return diff;
}

export function toDiffable(text: string): Diffable<string> {
  return text.split("\n").map((line, idx) => {
    return {
      n: idx,
      content: line,
    };
  });
}
