import { SortOrder } from "components/Grid/util";
import { SortDirection } from "types/graphql-global-types";

const sortDirToSortOrder = {
  [SortDirection.ASC]: "asc" as const,
  [SortDirection.DESC]: "desc" as const,
};

const sortOrderToSortDir = {
  asc: SortDirection.ASC,
  desc: SortDirection.DESC,
};

export function toSortOrder(dir: SortDirection): SortOrder {
  return sortDirToSortOrder[dir];
}

export function toSortDir(order: SortOrder): SortDirection {
  return sortOrderToSortDir[order];
}
