import React from "react";
import Panel from "components/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExternalLinkAlt,
} from "@fortawesome/pro-regular-svg-icons";

import styles from "./style.module.scss";
import { useAppConfig } from "components/WithAppConfig";
import PanelSection from "components/PanelSection";
import LogInsightsTeaser from "./LogInsightsTeaser";
import { useRoutes } from "utils/routes";
import TrackedLink from "components/TrackedLink";
import OnboardingProgressPanels from "components/OnboardingProgressPanels";
import { useFeature } from "components/OrganizationFeatures";
import { useCurrentOrganization } from "components/WithCurrentOrganization";

type Props = {
  systemType: string;
  serverId?: string;
};

const BlankSlate: React.FunctionComponent<Props> = ({
  systemType,
  serverId,
}) => {
  const { enterpriseEdition } = useAppConfig();
  const hasLogsFeature = useFeature("logs");
  const { slug: organizationSlug, onboardingProgress } =
    useCurrentOrganization();
  const { integratedCollector } = onboardingProgress;

  if (enterpriseEdition && systemType != "amazon_rds") {
    return <EnterpriseNoLogInsightsBlankSlate />;
  }
  if (!hasLogsFeature) {
    return (
      <UpsellBlankSlate
        integratedCollector={integratedCollector}
        organizationSlug={organizationSlug}
      />
    );
  }

  return (
    <>
      <Panel title="We're getting everything ready" className={styles.panel}>
        <PanelSection>
          <p>
            pganalyze is now collecting statistics from your system. Basic
            features like query performance overview are enabled now and will be
            ready for you in 30 minutes.
          </p>
          {!integratedCollector && (
            <p>We will notify you by e-mail when it's ready.</p>
          )}
        </PanelSection>
      </Panel>
      <OnboardingProgressPanels
        organizationSlug={organizationSlug}
        currentStep="collector_waiting"
        systemType={systemType}
        serverId={serverId}
      />
    </>
  );
};

const UpsellBlankSlate: React.FunctionComponent<{
  organizationSlug: string;
  integratedCollector: boolean;
}> = ({ organizationSlug, integratedCollector }) => {
  const { organizationSubscription } = useRoutes();

  return (
    <>
      <Panel title="We're getting everything ready" className={styles.panel}>
        <PanelSection>
          <ul className={styles.setupChecklist}>
            <li>
              <span>
                <FontAwesomeIcon icon={faCheckCircle} /> Setup complete
              </span>
            </li>
          </ul>

          <p>
            pganalyze is now collecting statistics from your system. Basic
            features like query performance overview are enabled now and will be
            ready for you in 30 minutes.
          </p>
          {integratedCollector && (
            <p>We will notify you by e-mail when it's ready.</p>
          )}
        </PanelSection>
      </Panel>
      <Panel title="Upgrade for Log Insights and More" className={styles.panel}>
        <PanelSection className={styles.panelSection}>
          <div className={styles.logInsightsWrapper}>
            <LogInsightsTeaser />
          </div>
          <p>
            The base feature set is now enabled, but you can get even more out
            of pganalyze by upgrading to enable powerful features like{" "}
            <TrackedLink
              href="https://pganalyze.com/log-insights"
              target="_blank"
              title="Learn more"
              eventCategory="Feature Page Link"
              eventLabel="Log Insights"
            >
              Log Insights{" "}
              <FontAwesomeIcon size="sm" icon={faExternalLinkAlt} />
            </TrackedLink>{" "}
            and{" "}
            <TrackedLink
              href="https://pganalyze.com/docs/explain"
              target="_blank"
              title="Learn more"
              eventCategory="Docs Link"
              eventLabel="EXPLAIN Insights"
            >
              Automated EXPLAIN{" "}
              <FontAwesomeIcon size="sm" icon={faExternalLinkAlt} />
            </TrackedLink>
            .
          </p>
          <p>
            <TrackedLink
              href={organizationSubscription(organizationSlug)}
              className="btn btn-success"
              eventCategory="In-App Link"
              eventLabel="Subscription Plans"
            >
              Review Plans
            </TrackedLink>
          </p>
        </PanelSection>
      </Panel>
    </>
  );
};

const EnterpriseNoLogInsightsBlankSlate: React.FunctionComponent = () => {
  return (
    <Panel title="We're getting everything ready" className={styles.panel}>
      <PanelSection className={styles.panelSection}>
        <ul className={styles.setupChecklist}>
          <li>
            <span>
              <FontAwesomeIcon icon={faCheckCircle} /> Setup complete
            </span>
          </li>
        </ul>

        <p>
          pganalyze is now collecting statistics from your system. Basic
          features like query performance overview are enabled now and will be
          ready for you in 30 minutes.
        </p>
      </PanelSection>
    </Panel>
  );
};

export default BlankSlate;
