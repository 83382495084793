---
title: 'Step 3: Install the Collector'
install_track_title: Installation Guide (Azure Database for PostgreSQL)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import InstallChoice from '../../components/InstallChoice'
import styles from '../../style.module.scss'

import imgLogoUbuntu from '../../images/logo_ubuntu.png'
import imgLogoRedhat from '../../images/logo_redhat.svg'
import imgLogoDocker from '../../images/logo_docker.svg'

export const SelectCollectorPlatform = () => {
  return (
    <InstallChoice
      docsRoot={false}
      items={[
        {
          link: "03_install_the_collector_deb",
          img: imgLogoUbuntu,
          providerName: "Ubuntu & Debian VM",
        },
        {
          link: "03_install_the_collector_yum",
          img: imgLogoRedhat,
          providerName: "RHEL, CentOS & Fedora VM",
        },
        {
          link: "03_install_the_collector_docker",
          img: imgLogoDocker,
          providerName: "Docker",
        },
      ]}
    />
  )
}

To continue, we need to know where you'd like to install the pganalyze collector.

The collector runs on a virtual machine or container and connects to your Azure Database for PostgreSQL server:

<SelectCollectorPlatform />
