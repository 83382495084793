import React from "react";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";

const LoadingErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <Sentry.ErrorBoundary fallback={<Loading error />}>
    {children}
  </Sentry.ErrorBoundary>
);

export default LoadingErrorBoundary;
