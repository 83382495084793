import React from "react";

import {
  GetVacuumAdvisorData_getIssues as IssueType,
  GetVacuumAdvisorData_getVacuumInsightStatus as InsightStatusType,
} from "../types/GetVacuumAdvisorData";

import { PostgresSettingType } from "components/ServerPostgresSettings";
import PostgresSettingsPanel from "components/PostgresSettingsPanel";
import { sortBy } from "lodash";
import SingletonInsightPanel from "../SingletonInsightPanel";
import FreezingStatsGraph from "components/VacuumAdvisor/FreezingStats/FreezingStatsGraph";
import XactPerMinGraph from "../FreezingStats/XactPerMinGraph";
import FreezingStatsGrid from "../FreezingStats/FreezingStatsGrid";

const VacuumAdvisorFreezing: React.FunctionComponent<{
  serverId: string;
  serverName: string;
  serverCreatedAt: moment.Moment;
  databaseId?: string;
  issues: IssueType[];
  settings: PostgresSettingType[];
  insightStatus: { [key: string]: InsightStatusType };
}> = ({
  serverId,
  serverName,
  serverCreatedAt,
  databaseId,
  issues,
  settings,
  insightStatus,
}) => {
  const txidWraparoundIssue = issues.find(
    (issue) => issue.checkGroupAndName === "vacuum/txid_wraparound",
  );
  const mxidWraparoundIssue = issues.find(
    (issue) => issue.checkGroupAndName === "vacuum/mxid_wraparound",
  );

  const freezingSettings = sortBy(
    settings.filter((ps) => {
      return [
        "autovacuum_freeze_max_age",
        "autovacuum_multixact_freeze_max_age",
        "autovacuum_vacuum_insert_scale_factor",
        "autovacuum_vacuum_insert_threshold",
      ].includes(ps.name);
    }),
    (ps) => ps.name,
  );
  return (
    <>
      <SingletonInsightPanel
        title="Approaching Transaction ID Wraparound"
        checkGroupAndName="vacuum/txid_wraparound"
        serverName={serverName}
        serverCreatedAt={serverCreatedAt}
        issue={txidWraparoundIssue}
        serverId={serverId}
        insightStatus={insightStatus["vacuum/txid_wraparound"]}
        summary={
          <>
            Detects when a server is consuming a large portion of the
            Transaction ID space and in danger of triggering transaction ID
            wraparound prevention mechanisms.
          </>
        }
        details={
          <FreezingStatsGraph
            serverId={serverId}
            databaseId={databaseId}
            type="txid"
          />
        }
      />
      <SingletonInsightPanel
        title="Approaching Multixact ID Wraparound"
        checkGroupAndName="vacuum/mxid_wraparound"
        serverName={serverName}
        serverCreatedAt={serverCreatedAt}
        issue={mxidWraparoundIssue}
        serverId={serverId}
        insightStatus={insightStatus["vacuum/mxid_wraparound"]}
        summary={
          <>
            Detects when a server is consuming a large portion of the Multixact
            ID space and in danger of triggering multixact ID wraparound
            prevention mechanisms.
          </>
        }
        details={
          <FreezingStatsGraph
            serverId={serverId}
            databaseId={databaseId}
            type="mxid"
          />
        }
      />
      <PostgresSettingsPanel
        title="Freezing-Related Config Settings"
        serverId={serverId}
        settings={freezingSettings}
      />
      <XactPerMinGraph serverId={serverId} />
      <FreezingStatsGrid serverId={serverId} databaseId={databaseId} />
    </>
  );
};

export default VacuumAdvisorFreezing;
