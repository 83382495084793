import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import PageIssueList from "components/PageIssueList";
import SQL from "components/SQL";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";

import {
  SchemaFunctionDetails as SchemaFunctionDetailsType,
  SchemaFunctionDetailsVariables,
} from "./types/SchemaFunctionDetails";
import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

const SchemaFunctionDetails: React.FunctionComponent = () => {
  const { databaseId, schemaFunctionId } = useParams();
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(null, databaseId);
  const { data, loading, error } = useQuery<
    SchemaFunctionDetailsType,
    SchemaFunctionDetailsVariables
  >(QUERY, {
    variables: {
      databaseId,
      schemaFunctionId,
    },
  });
  if (loading || error || !serverIdLoaded) {
    return <Loading error={!!error} />;
  }
  const {
    id,
    functionName,
    source,
    language,
    args,
    result,
    config,
    securityDefiner,
  } = data.getSchemaFunctionDetails;
  return (
    <PageContent
      title={`Function: ${functionName}(...)`}
      pageCategory="schema-functions"
      pageName="show"
    >
      <PageIssueList
        serverId={serverId}
        referentId={id}
        referentType="SchemaFunction"
      />
      <Panel title={`${functionName}(...)`}>
        <PanelSection>
          {language === "sql" && <SQL sql={source.replace(/^\n/, "")} />}
          {language !== "sql" && (
            <pre className="noborder">{source.replace(/^\n/, "")}</pre>
          )}
        </PanelSection>
        <PanelTable horizontal={true} className={styles.table}>
          <tbody>
            <tr>
              <th>Language</th>
              <td>
                <pre className="noborder">{language}</pre>
              </td>
            </tr>
            <tr>
              <th>Arguments</th>
              <td>
                <pre className="noborder">{args}</pre>
              </td>
            </tr>
            <tr>
              <th>Result</th>
              <td>
                <pre className="noborder">{result}</pre>
              </td>
            </tr>
            <tr>
              <th>Config</th>
              <td>
                <pre className="noborder">{config.join("; ")}</pre>
              </td>
            </tr>
            <tr>
              <th>Security Definer</th>
              <td>{(securityDefiner && "Yes") || "No"}</td>
            </tr>
          </tbody>
        </PanelTable>
      </Panel>
    </PageContent>
  );
};

export default SchemaFunctionDetails;
