import React, { useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { formatNumber } from "utils/format";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import Popover from "components/Popover";
import { useRoutes } from "utils/routes";

import {
  OrganizationOverviewServerTable_getSummaryStatsByServer,
  OrganizationOverviewServerTable_getSummaryStatsByServer_server_tags,
  OrganizationOverviewServerTable,
  OrganizationOverviewServerTableVariables,
} from "./types/OrganizationOverviewServerTable";
import QUERY from "./Query.graphql";
import { ServerTagsTable } from "components/ServerDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/pro-solid-svg-icons";
import { StatusDotIcon } from "components/Icons";
import Tip from "components/Tip";
import Grid from "components/Grid";
import Badge from "components/Badge";

type ServerStatusType = "okay" | "critical" | "no data";

type RowDataType = {
  serverId: string;
  serverName: string;
  tags: OrganizationOverviewServerTable_getSummaryStatsByServer_server_tags[];
  databaseCount: number;
  slowQueries: number;
  unusedIndexes: number;
  warnings: number;
  criticals: number;
  status: ServerStatusType;
  isPrimary: boolean | null;
};

type Props = {
  organizationSlug: string;
};

const Table: React.FunctionComponent<Props> = ({ organizationSlug }) => {
  const popoverPortal = useRef();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [showInactive, setShowInactive] = useState(false);
  const { data, loading, error } = useQuery<
    OrganizationOverviewServerTable,
    OrganizationOverviewServerTableVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const title = (
    <div>
      All Servers{" "}
      <Tip content="Servers that haven't sent data for more than 35 days will be automatically deleted" />
    </div>
  );
  const titleSearch = (
    <>
      <div className="flex">
        <input
          type="checkbox"
          checked={showInactive}
          id="show_inactive"
          onChange={(evt) => setShowInactive(evt.target.checked)}
        />
        <label htmlFor="show_inactive">
          Show servers with no recent statistics data
        </label>
      </div>
      <PanelTitleSearch
        value={searchTerm}
        onChange={(newTerm: string) => {
          setSearchTerm(newTerm);
        }}
      />
    </>
  );

  let servers: Array<RowDataType> = data.getSummaryStatsByServer.map(
    (
      summary: OrganizationOverviewServerTable_getSummaryStatsByServer,
    ): RowDataType => {
      return {
        serverId: summary.server.humanId,
        serverName: summary.server.name,
        status:
          (summary.server.hasRecentSnapshot &&
            ((summary.server.hasCriticalIssues && "critical") || "okay")) ||
          "no data",
        tags: summary.server.tags,
        databaseCount: summary.databaseCount,
        slowQueries: summary.queriesSlownessCount,
        unusedIndexes: summary.unusedIndexesCount,
        warnings: summary.warningsCount,
        criticals: summary.criticalsCount,
        isPrimary: summary.server.latestStats?.isPrimary,
      };
    },
  );

  if (searchTerm) {
    servers = servers.filter((di: RowDataType): boolean => {
      const replica_status =
        di.isPrimary != null ? (di.isPrimary ? "primary" : "replica") : "";
      const match =
        di.serverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        replica_status.includes(searchTerm.toLowerCase());
      return match;
    });
  }

  if (!showInactive) {
    servers = servers.filter((s) => s.status != "no data");
  }

  return (
    <Panel title={title} secondaryTitle={titleSearch}>
      <div ref={popoverPortal} />
      <Grid
        className="grid-cols-[20px,minmax(30%,1fr),repeat(3,minmax(10%,140px)),repeat(2,minmax(10%,120px))]"
        striped
        data={servers}
        defaultSortBy="serverName"
        columns={[
          {
            field: "status",
            header: "",
            renderer: function StatusCell({ fieldData }) {
              return <StatusDotIcon status={fieldData} />;
            },
          },
          {
            field: "serverName",
            header: "Server",
            renderer: function ServernameCell({ rowData }) {
              return (
                <ServerNameCell rowData={rowData} portal={popoverPortal} />
              );
            },
          },
          {
            field: "databaseCount",
            header: "# of Databases",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: function DBCountCell({ fieldData }) {
              return formatNumber(fieldData);
            },
          },
          {
            field: "slowQueries",
            header: "Slow Queries",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: function SlowQueryCountCell({ fieldData }) {
              return formatNumber(fieldData);
            },
          },
          {
            field: "unusedIndexes",
            header: "Unused Indexes",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: function UnusedIndexCountCell({ fieldData }) {
              return formatNumber(fieldData);
            },
          },
          {
            field: "warnings",
            header: "Warnings",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: function WarningCountCell({ fieldData }) {
              return formatNumber(fieldData);
            },
          },
          {
            field: "criticals",
            header: "Criticals",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: function CriticalCountCell({ fieldData }) {
              return formatNumber(fieldData);
            },
          },
        ]}
      />
    </Panel>
  );
};

const ServerNameCell: React.FunctionComponent<{
  rowData: RowDataType;
  portal?: React.RefObject<HTMLDivElement>;
}> = ({ rowData, portal }) => {
  const { server } = useRoutes();
  const { tags, serverId } = rowData;
  return (
    <span>
      <Link to={server(rowData.serverId)}>{rowData.serverName}</Link>
      {rowData.isPrimary != null && (
        <Badge className="ml-2">
          {rowData.isPrimary ? "Primary" : "Replica"}
        </Badge>
      )}
      {tags.length > 0 && (
        <Popover
          placement="bottom"
          wrapperClassName="ml-2 text-[12px]"
          content={<ServerTagsTable tags={tags} />}
          portal={portal?.current}
        >
          <Link to={server(serverId)}>
            <FontAwesomeIcon icon={faTags} />
          </Link>
        </Popover>
      )}
    </span>
  );
};

export default Table;
