import React from "react";

export interface QueryTagType {
  id: string;
  key: React.ReactNode | string;
  value: React.ReactNode | string | null;
}

const QueryTags: React.FunctionComponent<{
  tags: QueryTagType[];
  additionalNode?: React.ReactNode;
}> = ({ tags, additionalNode }) => {
  if (tags.length == 0) {
    return null;
  }

  return (
    <div>
      {tags.map((tag) => (
        <div
          className="mr-2 mt-[5px] inline-block rounded border text-[12px] bg-white border-[#ddd]"
          key={tag.id}
        >
          <span className="px-[6px] py-[2px] inline-block">
            {tag.key}&#8203;
          </span>
          <span className="px-[6px] py-[2px] inline-block whitespace-pre-line border-l">
            {tag.value}&#8203;
          </span>
        </div>
      ))}
      {additionalNode}
    </div>
  );
};

export default QueryTags;
