import React from "react";
import { useQuery } from "@apollo/client";
import { useParams, Link } from "react-router-dom";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";

import {
  PostgresSettings as PostgresSettingListType,
  PostgresSettingsVariables as PostgresSettingListVariables,
  PostgresSettings_getPostgresSettings as PostgresSettingType,
} from "./types/PostgresSettings";
import QUERY from "./Query.graphql";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";

type GroupedSettingsType = {
  settings: Array<PostgresSettingType>;
  group: string;
};

const PostgresSettingList: React.FunctionComponent = () => {
  const { serverId } = useParams();

  const { data, loading, error } = useQuery<
    PostgresSettingListType,
    PostgresSettingListVariables
  >(QUERY, {
    variables: {
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const groupedSettings: Array<GroupedSettingsType> = sortBy(
    Object.entries(
      groupBy(
        data.getPostgresSettings,
        (s: PostgresSettingType): string => s.group || "Other Settings",
      ),
    ).map(
      ([group, settings]): GroupedSettingsType => ({
        group,
        settings,
      }),
    ),
    // To avoid Amazon config group being on the top of the list, treat them as a lower case
    // (all other special/extension config group will start with a lower case)
    (c: GroupedSettingsType): string =>
      c.group.startsWith("Amazon") ? c.group.toLowerCase() : c.group,
  );

  return (
    <PageContent
      title="Config Settings"
      pageCategory="postgres-settings"
      pageName="index"
    >
      {groupedSettings.map((c) => (
        <PostgresSettingGroup
          key={c.group}
          group={c.group}
          unsortedSettings={c.settings}
          serverId={serverId}
        />
      ))}
    </PageContent>
  );
};

const PostgresSettingGroup: React.FunctionComponent<{
  serverId: string;
  group: string;
  unsortedSettings: PostgresSettingType[];
}> = ({ group, unsortedSettings, serverId }) => {
  const { serverConfigSetting } = useRoutes();
  const settings = sortBy(
    unsortedSettings.filter(
      (s: PostgresSettingType): boolean => s.name != "rds.extensions",
    ),
    (s: PostgresSettingType): string => s.name,
  );

  return (
    <Panel title={group} key={group}>
      <PanelTable borders={true}>
        <thead>
          <tr>
            <th>Setting</th>
            <th>Current Value</th>
            <th>Default Value</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {settings.map(
            (setting: PostgresSettingType): React.ReactNode => (
              <tr
                key={setting.name}
                className={
                  (setting.resetValuePretty != setting.bootValuePretty &&
                    styles.changed) ||
                  null
                }
              >
                <td className={styles.setting}>
                  <Link to={serverConfigSetting(serverId, setting.name)}>
                    {setting.name}
                  </Link>
                </td>
                <td className={styles.resetValue}>
                  <span>{setting.resetValuePretty}</span>
                </td>
                <td className={styles.bootValue}>
                  <span>{setting.bootValuePretty}</span>
                </td>
                <td className={styles.source}>
                  <span>{setting.source}</span>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </PanelTable>
    </Panel>
  );
};

export default PostgresSettingList;
