import React from "react";

import { useQuery } from "@apollo/client";

import GraphSection from "components/Graph/GraphSection";

import { Data, Datum } from "components/Graph/util";

import SERVER_XMIN_HORIZON_QUERY from "./Query.graphql";

import {
  ServerXminHorizonVariables,
  ServerXminHorizon as ServerXminHorizonType,
} from "./types/ServerXminHorizon";
import { ThresholdSeries } from "components/Graph/Series";
import Loading from "components/Loading";
import { labelXminHeldBackBy, labelXminHeldBackByShort } from "utils/vacuum";
import moment from "moment";
import DateRangeGraph from "components/Graph/DateRangeGraph";

const XminHorizonGraph: React.FunctionComponent<{
  serverId: string;
  startTs: number;
  endTs: number;
  thresholdHours?: number;
}> = ({ serverId, startTs, endTs, thresholdHours }) => {
  const { data, loading, error } = useQuery<
    ServerXminHorizonType,
    ServerXminHorizonVariables
  >(SERVER_XMIN_HORIZON_QUERY, {
    variables: { serverId, startTs, endTs },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const noData = !loading && !error && data.getServerXminHorizon == null;

  const graphData: Data = {};
  if (!noData) {
    // Graph data is coming in seconds
    // Convert this to hours will allow the graph y-axis align to the threshold nicely
    function toDatum(val: number[]): Datum {
      return [val[0], val[1] && val[1] / 60 / 60];
    }
    graphData["backend"] = data.getServerXminHorizon.backend?.map(toDatum);
    graphData["replicationSlot"] =
      data.getServerXminHorizon.replicationSlot?.map(toDatum);
    graphData["replicationSlotCatalog"] =
      data.getServerXminHorizon.replicationSlotCatalog?.map(toDatum);
    graphData["preparedXact"] =
      data.getServerXminHorizon.preparedXact?.map(toDatum);
    graphData["standby"] = data.getServerXminHorizon.standby?.map(toDatum);
    if (thresholdHours) {
      // Usually backend is not null, so use that data to populate threshold graph
      graphData["threshold"] = data.getServerXminHorizon.backend?.map((val) => {
        return [val[0], thresholdHours];
      }) as Datum[];
    }
  }
  const yAxisFormatter = (y: number): string => {
    // With moment.duration, 0 value will show up as "a few seconds"
    // To avoid confusion, make it explicitly 0
    return y === 0 ? "0" : moment.duration(y, "hours").humanize({ h: 49 });
  };

  return (
    <GraphSection noData={noData} loading={loading} error={error}>
      <DateRangeGraph
        data={graphData}
        axes={{
          left: {
            format: yAxisFormatter,
          },
        }}
        series={[
          {
            key: "backend",
            label: labelXminHeldBackBy("backend"),
            tipLabel: labelXminHeldBackByShort("backend"),
          },
          {
            key: "replicationSlot",
            label: labelXminHeldBackBy("replication_slot"),
            tipLabel: labelXminHeldBackByShort("replication_slot"),
          },
          {
            key: "replicationSlotCatalog",
            label: labelXminHeldBackBy("replication_slot_catalog"),
            tipLabel: labelXminHeldBackByShort("replication_slot_catalog"),
          },
          {
            key: "preparedXact",
            label: labelXminHeldBackBy("prepared_xact"),
            tipLabel: labelXminHeldBackByShort("prepared_xact"),
          },
          {
            key: "standby",
            label: labelXminHeldBackBy("standby"),
            tipLabel: labelXminHeldBackByShort("standby"),
          },
          {
            type: ThresholdSeries,
            key: "threshold",
            label: "Alert threshold",
            tipLabel: "Threshold",
          },
        ]}
      />
    </GraphSection>
  );
};

export default XminHorizonGraph;
