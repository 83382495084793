import React from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import {
  ViewPermission,
  ModifyPermission,
  BillingPermission,
  APIPermission,
  ManagePermission,
} from "components/DocsSnippet";
import Form from "components/Form";
import FormSubmit from "components/FormSubmit";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import PermissionIcon from "components/PermissionIcon";
import { useRoutes } from "utils/routes";

import {
  RoleDetails as RoleDetailsType,
  RoleDetailsVariables,
  RoleDetails_getOrganizationRoleDetails_validMemberships as MembershipType,
  RoleDetails_getOrganizationRoleDetails_permissions as PermissionType,
} from "./types/RoleDetails";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import { getPermissionKind } from "utils/permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

const RoleDetails: React.FunctionComponent = () => {
  const { slug: organizationSlug, id: organizationRoleId } = useParams();
  const { organizationRole, organizationRoleEdit } = useRoutes();
  const { data, loading, error } = useQuery<
    RoleDetailsType,
    RoleDetailsVariables
  >(QUERY, {
    variables: { organizationRoleId },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const {
    id,
    name,
    validMemberships,
    permissions,
    permittedToEditRole,
    permittedToRemoveRole,
  } = data.getOrganizationRoleDetails;
  return (
    <PageContent
      title={`Role "${name}"`}
      pageCategory="organization-roles"
      pageName="show"
    >
      <Panel title="Assigned Members">
        <PanelTable>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {validMemberships.map((m) => (
              <RoleMember
                key={m.id}
                member={m}
                organizationSlug={organizationSlug}
              />
            ))}
          </tbody>
        </PanelTable>
      </Panel>
      <Panel title="Permissions">
        <PanelTable>
          <thead>
            <tr>
              <th>Scope</th>
              <th className={styles.permission}>
                View <ViewPermission />
              </th>
              <th className={styles.permission}>
                Modify <ModifyPermission />
              </th>
              <th className={styles.permission}>
                Billing <BillingPermission />
              </th>
              <th className={styles.permission}>
                API <APIPermission />
              </th>
              <th className={styles.permission}>
                Manage <ManagePermission />
              </th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((p) => (
              <RolePermission key={p.id} permission={p} />
            ))}
          </tbody>
        </PanelTable>
        <PanelSection>
          {permittedToEditRole && (
            <Link
              to={organizationRoleEdit(organizationSlug, id)}
              className="btn btn-success"
            >
              Edit Permissions
            </Link>
          )}
          {!permittedToEditRole && (
            <span>
              You are not allowed to edit this role - you do not have the{" "}
              <strong>Manage</strong> permission on this organization.
            </span>
          )}
        </PanelSection>
      </Panel>
      <Panel title="Deletion">
        {validMemberships.length != 0 && (
          <PanelSection>
            This role is currently assigned to members. If you want to delete
            this role you will need to remove the assignments first.
          </PanelSection>
        )}
        {validMemberships.length == 0 && permittedToRemoveRole && (
          <Form action={organizationRole(organizationSlug, id)} method="delete">
            {validMemberships.length == 0 && (
              <div>
                <p>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-2"
                  />
                  Deleting this role will remove it from your organization and
                  you will need to re-create it if you want to assign it to
                  users.
                </p>
                <FormSubmit danger={true} title="Delete Role" />
              </div>
            )}
          </Form>
        )}
        {validMemberships.length == 0 && !permittedToRemoveRole && (
          <PanelSection>
            You are not allowed to delete this role - you do not have the{" "}
            <strong>Manage</strong> permission on this organization.
          </PanelSection>
        )}
      </Panel>
    </PageContent>
  );
};

const RoleMember: React.FunctionComponent<{
  organizationSlug: string;
  member: MembershipType;
}> = ({ member, organizationSlug }) => {
  const { organizationMember } = useRoutes();
  const email = (member.accepted && member.user.email) || member.inviteEmail;

  return (
    <tr key={member.id}>
      <td>
        {member.accepted && member.user.avatarUrl && (
          <img
            src={member.user.avatarUrl}
            style={{ width: 30, height: 30 }}
            className={styles.memberImage}
          />
        )}
      </td>
      <td>
        <Link to={organizationMember(organizationSlug, member.id)}>
          {(member.accepted && member.user.fullname) || "(invited)"}
        </Link>
      </td>
      <td>{email}</td>
    </tr>
  );
};
const RolePermission: React.FunctionComponent<{
  permission: PermissionType;
}> = ({ permission }) => {
  const {
    id,
    database,
    server,
    view,
    modify,
    billing,
    api,
    manage,
    restricted,
  } = permission;

  return (
    <tr key={id}>
      <td>
        {(database && "Database: " + database.displayName) ||
          (server && "Server: " + server.name) ||
          "All Databases/Servers"}
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={getPermissionKind(view, restricted)} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={getPermissionKind(modify, restricted)} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={getPermissionKind(billing, restricted)} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={getPermissionKind(api, restricted)} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={getPermissionKind(manage, restricted)} />
      </td>
    </tr>
  );
};

export default RoleDetails;
