import React, {
  MouseEvent,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";

import { useQuery } from "@apollo/client";
import { useFormState } from "react-use-form-state";

import HelpOverlay, {
  SupportFormFields,
  TicketCategory,
} from "components/HelpOverlay";
import { useAppConfig } from "components/WithAppConfig";
import {
  useCurrentServer,
  useCurrentUser,
} from "components/WithCurrentOrganization";

import styles from "./style.module.scss";

import QUERY from "./Query.graphql";
import {
  GetServerHelpInfo,
  GetServerHelpInfoVariables,
} from "./types/GetServerHelpInfo";

type HelpFormState = {
  open: boolean;
  subject: string | undefined;
};

const HelpOverlaySupportedContext = React.createContext<boolean>(false);
const HelpContext = React.createContext<HelpFormState>({
  open: false,
  subject: "",
});
const HelpSetOpenContext = React.createContext<
  (newOpen: boolean, newSubject?: string) => void
>(() => {
  /* no op */
});

export const WithHelpOpenState = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const appConfig = useAppConfig();

  const [openState, setOpenState] = useState<HelpFormState>({
    open: false,
    subject: "",
  });

  const setHelpOpen = useCallback((open: boolean, subject = "") => {
    setOpenState({ open, subject: open ? subject : "" });
  }, []);

  const { demo } = useCurrentUser();
  const helpOverlaySupported = !demo && !appConfig.enterpriseEdition;

  return (
    <HelpOverlaySupportedContext.Provider value={helpOverlaySupported}>
      <HelpSetOpenContext.Provider value={setHelpOpen}>
        <HelpContext.Provider value={openState}>
          {children}
        </HelpContext.Provider>
      </HelpSetOpenContext.Provider>
    </HelpOverlaySupportedContext.Provider>
  );
};

export const useHelpOverlaySupported = () => {
  return useContext(HelpOverlaySupportedContext);
};

export const useHelpSetOpen = (): ((
  newOpen: boolean,
  newSubject?: string,
) => void) => {
  return useContext(HelpSetOpenContext);
};

export const useHelpIsOpen = (): boolean => {
  return useContext(HelpContext).open;
};

const Help: React.FunctionComponent = () => {
  const [category, setCategory] = useState<TicketCategory | null>(null);
  const [formState, formInputs] = useFormState<SupportFormFields>();
  const overlaySupported = useHelpOverlaySupported();
  const setOpen = useHelpSetOpen();
  const { open: helpOpen, subject: helpSubject } = useContext(HelpContext);
  const userInputSubject = formState.values.ticketSubject;
  useEffect(() => {
    if (helpOpen && helpSubject && userInputSubject === "") {
      formState.setField("ticketSubject", helpSubject);
    }
  }, [helpOpen, helpSubject, userInputSubject, formState]);

  const server = useCurrentServer(true);
  // N.B.: we treat this data as optional (to avoid errors in the form when there are problems
  // loading the data we only use to ask for more specific context), so we ignore loading and
  // error values here.
  const { data: serverData } = useQuery<
    GetServerHelpInfo,
    GetServerHelpInfoVariables
  >(QUERY, {
    variables: {
      // N.B.: server *can* be null here, but if it's present,
      // it definitely has a humanId, hence the assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      serverId: server?.humanId!,
    },
    skip: server == null,
  });

  const systemType = serverData?.getServerDetails.systemType;
  const configFromEnv =
    serverData?.getServerDetails.collectorInfo?.configFromEnv;
  const monitoringUser =
    serverData?.getServerDetails.collectorInfo?.monitoringUser;
  const collectorVersion =
    serverData?.getServerDetails.lastSnapshot?.collectorVersion;

  const onClose = () => {
    setOpen(false);
  };

  if (helpOpen) {
    return (
      <HelpOverlay
        category={category}
        setCategory={setCategory}
        formState={formState}
        formInputs={formInputs}
        serverId={server?.humanId}
        collectorVersion={collectorVersion}
        systemType={systemType}
        configFromEnv={configFromEnv}
        monitoringUser={monitoringUser}
        onClose={onClose}
      />
    );
  }

  let href;
  let handleClick;
  if (overlaySupported) {
    href = "";
    handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();
      setOpen(true);
    };
  } else {
    href = "mailto:support@pganalyze.com";
  }

  return (
    <a href={href} className={styles.helpBubble} onClick={handleClick}>
      Get Help
    </a>
  );
};

export default Help;
