import React from "react";
import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";

import { titleize } from "utils/inflection";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import PageIssueList from "components/PageIssueList";

import {
  PostgresSettingDetails,
  PostgresSettingDetailsVariables,
  PostgresSettingDetails_getSchemaTableOptionsForSetting,
} from "./types/PostgresSettingDetails";

import QUERY from "./Query.graphql";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";

interface RowOpts {
  databaseId: string;
  settingName: string;
  v: PostgresSettingDetails_getSchemaTableOptionsForSetting;
}

const SchemaTableOptionRow: React.FunctionComponent<RowOpts> = ({
  databaseId,
  settingName,
  v,
}) => {
  const { databaseTable, databaseTableVacuum } = useRoutes();
  const tablePath = settingName.startsWith("autovacuum_")
    ? databaseTableVacuum(databaseId, v.table.id)
    : databaseTable(databaseId, v.table.id);

  let value = v.value;
  if (value == "true") {
    value = "on";
  }
  if (value == "false") {
    value = "off";
  }
  return (
    <tr key={v.id}>
      <td>{v.table.schemaName}</td>
      <td>
        <Link to={tablePath}>{v.table.tableName}</Link>
      </td>
      <td>{value}</td>
    </tr>
  );
};

const PostgresSettingDetailsComponent: React.FunctionComponent = () => {
  const { serverId, settingName } = useParams();
  const { data, loading, error } = useQuery<
    PostgresSettingDetails,
    PostgresSettingDetailsVariables
  >(QUERY, { variables: { serverId, settingName } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const {
    id,
    name,
    resetValuePrettyNoDelimiter,
    source,
    sourceFile,
    sourceLine,
    docSnippetHtml,
  } = data.getPostgresSettingDetails;
  const tableOptions = data.getSchemaTableOptionsForSetting;

  const val = /^\s|\s$/.test(resetValuePrettyNoDelimiter)
    ? `'${resetValuePrettyNoDelimiter}'`
    : resetValuePrettyNoDelimiter;
  const formattedSetting = `${name} = ${val}`;

  return (
    <PageContent
      title={`Config Setting: ${name}`}
      pageCategory="postgres-settings"
      pageName="show"
    >
      <PageIssueList
        serverId={serverId}
        referentId={id}
        referentType="PostgresSetting"
      />
      <Panel
        title={
          (sourceFile && sourceLine && `${sourceFile}:${sourceLine}`) ||
          `${titleize(source)} Config`
        }
      >
        <PanelSection>
          <code>{formattedSetting}</code>
        </PanelSection>
      </Panel>
      <Panel title="PostgreSQL Documentation">
        <PanelSection>
          {docSnippetHtml && (
            /* eslint-disable react/no-danger */
            <div
              dangerouslySetInnerHTML={{ __html: docSnippetHtml }}
              className={styles.snippet}
            />
            /* eslint-enable react/no-danger */
          )}
          {!docSnippetHtml && "Not available"}
        </PanelSection>
      </Panel>
      {tableOptions.length > 0 && (
        <Panel title="Customized Per-Table Options">
          <PanelTable borders={true}>
            <thead>
              <tr>
                <th>Schema Name</th>
                <th>Table Name</th>
                <th style={{ width: "60%" }}>Configuration Value</th>
              </tr>
            </thead>
            <tbody>
              {tableOptions.map(
                (
                  v: PostgresSettingDetails_getSchemaTableOptionsForSetting,
                ): React.ReactNode => (
                  <SchemaTableOptionRow
                    databaseId={v.table.databaseId}
                    settingName={data.getPostgresSettingDetails.name}
                    v={v}
                    key={v.id}
                  />
                ),
              )}
            </tbody>
          </PanelTable>
        </Panel>
      )}
    </PageContent>
  );
};

export default PostgresSettingDetailsComponent;
