import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import {
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import styles from "./style.module.scss";

const LogProcessingDisabledPanel: React.FunctionComponent<{
  disabledReasons: string;
}> = ({ disabledReasons }) => {
  const reasons = disabledReasons.split("; ");
  return (
    <Panel
      title={
        <span className={styles.logProcessingDisabledTitle}>
          <FontAwesomeIcon icon={faExclamationCircle} /> Unsupported log
          configuration
        </span>
      }
    >
      <PanelSection>
        <p>
          The Postgres logging configuration on this server is not supported by
          pganalyze. Log Insights and Automated EXPLAIN will not process new log
          data until the logging settings are adjusted:
        </p>
        <ul className={styles.logProcessingDisabledReasons}>
          {reasons.map((reason) => (
            <li key={reason}>
              <FontAwesomeIcon icon={faTimesCircle} /> <strong>Error:</strong>{" "}
              {reason}
            </li>
          ))}
        </ul>
        <p>
          Changes can be made by editing the Postgres configuration file or
          using the <code>ALTER SYSTEM</code>
          command (learn more{" "}
          <a
            href={
              "https://www.postgresql.org/docs/current/config-setting.html#CONFIG-SETTING-CONFIGURATION-FILE"
            }
            title="Learn more"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
          ). After settings are updated, log processing will resume after a few
          minutes.
        </p>
      </PanelSection>
    </Panel>
  );
};

export default LogProcessingDisabledPanel;
