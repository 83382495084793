import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import flowRight from "lodash/flowRight";

import Panel from "components/Panel";
import PageContent from "components/PageContent";
import PanelSection from "components/PanelSection";
import Form from "components/Form";
import FormSubmit from "components/FormSubmit";
import withLoading from "decorators/withLoading";

import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

type Props = {
  data: {
    getCurrentUserDetails: {
      permittedToCreateOrganization: boolean;
    };
  };
};

const NewOrganization: React.FunctionComponent<Props> = ({ data }) => {
  const { organizations } = useRoutes();
  const permittedToCreate =
    data.getCurrentUserDetails.permittedToCreateOrganization;

  return (
    <PageContent
      title="New Organization"
      pageCategory="organization"
      pageName="new"
    >
      <Panel title="New Organization">
        {permittedToCreate && (
          <Form action={organizations()}>
            <div className="form-group">
              <label htmlFor="organization_name">Organization Name</label>
              <div className="row">
                <div className="col-sm-5">
                  <input
                    type="text"
                    name="organization[name]"
                    id="organization_name"
                    defaultValue=""
                    placeholder="My Organization"
                    className="form-control"
                    autoFocus={true}
                  />
                </div>
                <div className="col-sm-7">
                  <div className="help-block">
                    Choose a descriptive name (anything that you like)
                  </div>
                </div>
              </div>
            </div>
            <FormSubmit title="Create Organization" />
          </Form>
        )}
        {!permittedToCreate && (
          <PanelSection>
            You are not allowed to create new organizations. Ask an
            administrator to create one for you, or invite you to an existing
            one.
          </PanelSection>
        )}
      </Panel>
    </PageContent>
  );
};

export default flowRight(graphql(QUERY), withLoading)(NewOrganization);
