import Panel from "components/Panel";
import React, { useState } from "react";

import QUERY from "../ExplainWorkbook/Query.graphql";
import {
  ExplainWorkbookDetails,
  ExplainWorkbookDetailsVariables,
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import Grid, { NumberCell } from "components/Grid";
import { faBolt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandableSQL from "components/ExpandableSQL";
import Identicon from "components/Identicon";
import { Link, useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import { ExplainWorkbookFeatureNav, IOReadPopover } from "../ExplainWorkbook";
import EditParameterSets, { missingValueAliases } from "../EditParameterSets";
import RunExplain from "../RunExplain";
import ExplainVariantSidebar from "../ExplainVariantSidebar";
import { formatMs } from "utils/format";

// This is not really tab, but for which page to show
type ExplainVariantTabType = "run";

const ExplainVariant = ({ tab }: { tab?: ExplainVariantTabType }) => {
  const { databaseId, workbookId, variantId } = useParams();
  const { databaseWorkbookVariantResult, databaseWorkbookVariantRun } =
    useRoutes();

  const { loading, error, data } = useQuery<
    ExplainWorkbookDetails,
    ExplainWorkbookDetailsVariables
  >(QUERY, {
    variables: { workbookId },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const workbook = data.getExplainWorkbookDetails;
  const explainQuery =
    workbook.baselineQuery.id === variantId
      ? workbook.baselineQuery
      : workbook.explainQueries.find((val) => val.id === variantId);

  const featureNav = (
    <ExplainWorkbookFeatureNav
      workbookId={workbookId}
      databaseId={databaseId}
    />
  );

  // When there is zero result uploaded, or with "run" path, show Run EXPLAIN
  if (explainQuery.explainResults.length === 0 || tab === "run") {
    // Check if this variant's aliases contain missing value aliases
    // (can't run EXPLAIN until missing value aliases are all set)
    const missingAliases = Array.from(
      new Set(
        workbook.parameterSets.flatMap((ps) =>
          missingValueAliases(workbook, ps.id).map((v) => `$${v}`),
        ),
      ),
    );
    if (
      missingAliases.some((alias) =>
        Object.values(explainQuery.paramRefAliasMap).includes(alias),
      )
    ) {
      // Missing aliases so it can't show Run EXPLAIN yet, show edit params page
      return (
        <EditParameterSets workbook={workbook} explainQuery={explainQuery} />
      );
    } else {
      return <RunExplain workbook={workbook} explainQuery={explainQuery} />;
    }
  }

  let fastestRuntime = Infinity;
  const variantData = workbook.parameterSets.map((ps) => {
    const result = explainQuery.explainResults.find(
      (er) => er.parameterSetId === ps.id,
    );
    fastestRuntime = Math.min(fastestRuntime, result?.runtimeMs ?? Infinity);
    return {
      resultId: result?.id,
      setId: ps.id,
      paramSetName: ps.name,
      planFingerprint: result?.planFingerprint,
      totalCost: result?.totalCost,
      runtimeMs: result?.runtimeMs,
      totalBlkReadTime: result?.totalBlkReadTime,
    };
  });

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      title={workbook.name}
      pageCategory="explains"
      pageName="workbooks"
      layout="leftSidebar"
      featureNav={featureNav}
    >
      <>
        <ExplainQueryPanel workbook={workbook} explainQuery={explainQuery} />
        <Panel title="Query Plans">
          <Grid
            className="grid-cols-[28%_repeat(4,18%)]"
            data={variantData}
            columns={[
              {
                field: "setId",
                header: "Variant",
                renderer: () => explainQuery.name,
              },
              {
                field: "planFingerprint",
                header: "Plan",
                renderer: function PlanFingerprintCell({ fieldData, rowData }) {
                  if (rowData.resultId == null) {
                    return (
                      <Link
                        to={databaseWorkbookVariantRun(
                          databaseId,
                          workbook.id,
                          explainQuery.id,
                        )}
                      >
                        Run EXPLAIN
                      </Link>
                    );
                  }
                  return (
                    <Link
                      to={databaseWorkbookVariantResult(
                        databaseId,
                        workbook.id,
                        explainQuery.id,
                        rowData.resultId,
                      )}
                    >
                      <Identicon identity={fieldData} />
                      <span title={fieldData}>{fieldData.substring(0, 7)}</span>
                    </Link>
                  );
                },
              },
              {
                field: "paramSetName",
                header: "Parameter Set",
              },
              {
                field: "totalCost",
                header: "Est. Cost",
                renderer: NumberCell,
                style: "number",
                nullValue: "-",
              },
              {
                field: "runtimeMs",
                header: "Runtime",
                renderer: function RuntimeMsCell({ fieldData, rowData }) {
                  if (fieldData !== fastestRuntime) {
                    return (
                      <>
                        {rowData.totalBlkReadTime > 0 && (
                          <IOReadPopover
                            totalBlkReadTime={rowData.totalBlkReadTime}
                          />
                        )}
                        {formatMs(fieldData)}
                      </>
                    );
                  }
                  return (
                    <span className={"text-[#2B5827]"}>
                      {rowData.totalBlkReadTime > 0 && (
                        <IOReadPopover
                          totalBlkReadTime={rowData.totalBlkReadTime}
                        />
                      )}
                      <FontAwesomeIcon
                        icon={faBolt}
                        title="Fastest"
                        className="mr-[2px]"
                      />
                      {formatMs(fieldData)}
                    </span>
                  );
                },
                style: "number",
                nullValue: "-",
              },
            ]}
          />
        </Panel>
      </>
      {/* sidebar */}
      <ExplainVariantSidebar workbook={workbook} explainQuery={explainQuery} />
    </PageContent>
  );
};

export const ExplainQueryPanel = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
}) => {
  const [selectedParameterSetId, setSelectedParameterSetId] = useState("");
  const handleParameterSetSelected = (selected: string) => {
    setSelectedParameterSetId(selected);
  };
  const query = selectedParameterSetId
    ? explainQuery.queryTextWithParameters.find(
        (val) => val.parameterSetId === selectedParameterSetId,
      ).queryWithParameters
    : explainQuery.queryTextWithAlias;

  return (
    <div className="rounded-md bg-[#f7fafc] border border-[#E8E8EE] p-4 mb-4 grid gap-2 text-[#606060]">
      <div className="flex">
        <div className="flex-grow text-[18px] leading-6">
          {explainQuery.name}
        </div>
        <div>
          <select
            value={selectedParameterSetId}
            onChange={(e) => handleParameterSetSelected(e.target.value)}
            className="bg-inherit"
          >
            <option value="">With parameter aliases</option>
            {workbook.parameterSets.map((set) => {
              return (
                <option key={set.id} value={set.id}>
                  With {set.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div>
        <ExpandableSQL sql={query} />
      </div>
    </div>
  );
};

export default ExplainVariant;
