import { useCurrentPage } from "components/PageContent";
import React, { useEffect, useRef } from "react";

type Props = Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  "onClick" | "ref"
> & {
  eventCategory: string;
  eventAction?: string;
  eventLabel: string;
};

const TrackedLink: React.FunctionComponent<Props> = (props) => {
  const { eventCategory, eventAction, eventLabel, ...rest } = props;

  const { pageCategory, pageName } = useCurrentPage();

  const ref = useRef();
  useEffect(() => {
    if (window.analytics) {
      window.analytics.trackLink(ref.current, eventAction || "click", {
        category: eventCategory,
        label: eventLabel,
        pageCategory,
        pageName,
      });
    }
  }, [eventCategory, eventAction, eventLabel, pageCategory, pageName]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    let redirect = true;
    if (
      e.button !== 0 ||
      e.altKey ||
      e.ctrlKey ||
      e.metaKey ||
      e.shiftKey ||
      e.defaultPrevented
    ) {
      redirect = false;
    }
    if (window.gtag) {
      window.gtag("event", eventAction || "click", {
        event_category: eventCategory,
        event_label: eventLabel,
        event_callback: () => {
          if (redirect) {
            document.location.href = props.href;
          }
        },
      });
    } else {
      if (redirect) {
        document.location.href = props.href;
      }
    }

    return false;
  };

  return <a {...rest} ref={ref} onClick={handleClick} />;
};

export default TrackedLink;
