import React, { useState } from "react";

import { useParams } from "react-router-dom";

import Panel from "components/Panel";
import { useDateRange } from "components/WithDateRange";
import FilterSearch from "components/FilterSearch";
import QueryOverviewGraph from "components/QueryOverviewGraph";

import { useUserPreferences } from "utils/hooks";
import { UserPreferences } from "utils/preferences";

import QueryTable from "./QueryTable";
import styles from "./style.module.scss";

const QueryList: React.FunctionComponent = () => {
  const { databaseId } = useParams();
  const [qlPrefs, setQlPrefs] = useUserPreferences("queryList");
  const mergeQlPrefs = (newValues: Partial<UserPreferences["queryList"]>) => {
    setQlPrefs({ ...qlPrefs, ...newValues });
  };
  const setShowRead = (showRead: boolean) => {
    mergeQlPrefs({ showRead });
  };
  const setShowWrite = (showWrite: boolean) => {
    mergeQlPrefs({ showWrite });
  };
  const setShowOther = (showOther: boolean) => {
    mergeQlPrefs({ showOther });
  };
  const setDoCompare = (doCompare: boolean) => {
    mergeQlPrefs({ doCompare });
  };
  const qlDefaults: UserPreferences["queryList"] = {
    showRead: true,
    showWrite: true,
    showOther: true,
    doCompare: false,
  };
  const { showRead, showWrite, showOther, doCompare } = {
    ...qlDefaults,
    ...qlPrefs,
  };

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [range] = useDateRange();
  const { from, to } = range;

  const statementTypes: string[] = [];
  if (showRead) {
    statementTypes.push("read");
  }
  if (showWrite) {
    statementTypes.push("write");
  }
  if (showOther) {
    statementTypes.push("other");
  }

  return (
    <Panel title="Database Queries">
      <QueryOverviewGraph databaseId={databaseId} />
      <form
        className={styles.queryFilter}
        onSubmit={(evt) => evt.preventDefault()}
      >
        <div className={styles.toggleBox}>
          <input
            type="checkbox"
            checked={showRead}
            name="filter[statement_type][read]"
            id="filter_statement_type_read"
            onChange={(evt) => setShowRead(evt.target.checked)}
          />
          <label htmlFor="filter_statement_type_read"> SELECT</label>

          <input
            type="checkbox"
            checked={showWrite}
            id="filter_statement_type_write"
            onChange={(evt) => setShowWrite(evt.target.checked)}
          />
          <label htmlFor="filter_statement_type_write">
            {" "}
            INSERT, UPDATE, DELETE
          </label>

          <input
            type="checkbox"
            checked={showOther}
            id="filter_statement_type_other"
            onChange={(evt) => setShowOther(evt.target.checked)}
          />
          <label htmlFor="filter_statement_type_other"> DDL &amp; other</label>
        </div>
        <div className={styles.spacer} />
        <div className={styles.toggleBox}>
          <input
            type="checkbox"
            checked={doCompare}
            id="include_comparison"
            onChange={(evt) => setDoCompare(evt.target.checked)}
          />
          <label htmlFor="include_comparison"> Compare to 7 days ago</label>
        </div>
        <FilterSearch
          name="filter[search]"
          initialValue={searchTerm}
          onChange={setSearchTerm}
          debounceMs={300}
        />
      </form>
      {from && to && (
        <QueryTable
          searchTerm={searchTerm}
          databaseId={databaseId}
          compare={doCompare}
          startTs={from.unix()}
          endTs={to.unix()}
          statementTypes={statementTypes}
        />
      )}
    </Panel>
  );
};

export default QueryList;
