import React from "react";

import Button from "components/Button";
import { SlackLogo } from "components/Icons";
import { useAppConfig } from "components/WithAppConfig";

import styles from "./style.module.scss";
import { csrfTag } from "utils/csrf";

const ConnectToSlack: React.FunctionComponent = () => {
  const config = useAppConfig();

  const available = config?.slackIntegrationAvailable;
  if (!available) {
    return (
      <button disabled className="btn btn-secondary disabled">
        <SlackLogo /> Not Available
      </button>
    );
  }

  return (
    <form action="/users/auth/slack" method="post">
      {csrfTag()}
      <Button type="submit" className={styles.connectBtn}>
        <SlackLogo /> Connect to Slack
      </Button>
    </form>
  );
};

export default ConnectToSlack;
