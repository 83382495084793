import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import PageIssueList from "components/PageIssueList";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import SchemaTableColumns from "components/SchemaTableColumns";
import SchemaTableStats from "components/SchemaTableStats";
import SchemaTableIndexes from "components/SchemaTableIndexes";
import SQL from "components/SQL";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";

import {
  SchemaViewDetails as SchemaViewDetailsType,
  SchemaViewDetailsVariables,
} from "./types/SchemaViewDetails";
import QUERY from "./Query.graphql";

import DateRangeBar from "components/DateRangeBar";
import { useRoutes } from "utils/routes";

type Props = {
  tab: string;
};

const SchemaViewDetails: React.FunctionComponent<Props> = ({ tab }) => {
  const { databaseId, viewId } = useParams();
  const { databaseView, databaseViewColumns, databaseViewIndexes } =
    useRoutes();
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(null, databaseId);
  const { data, loading, error } = useQuery<
    SchemaViewDetailsType,
    SchemaViewDetailsVariables
  >(QUERY, {
    variables: {
      databaseId,
      viewId,
    },
  });
  if (loading || error || !serverIdLoaded) {
    return <Loading error={!!error} />;
  }
  const {
    id,
    schemaName,
    viewName,
    viewDefinition,
    fillfactor,
    materializedView,
    lastStats,
  } = data.getSchemaViewDetails;

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseView(databaseId, id)}>
        {!materializedView && "View Definition"}
        {materializedView && "View Definition & Statistics"}
      </PageNavLink>
      <PageNavLink to={databaseViewColumns(databaseId, id)}>
        Columns
      </PageNavLink>
      {materializedView && (
        <PageNavLink to={databaseViewIndexes(databaseId, id)}>
          Indexes
        </PageNavLink>
      )}
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title={`${
        (materializedView && "Materialized View") || "View"
      }: ${schemaName}.${viewName}`}
      pageControls={materializedView && <DateRangeBar />}
      pageCategory="schema-views"
      pageName="show"
      pageTab={tab}
      pageProps={{ materializedView }}
      featureNav={featureNav}
    >
      <PageIssueList
        serverId={serverId}
        referentId={id}
        referentType="SchemaView"
      />
      {tab == "overview" && (
        <Panel title="View Definition">
          <PanelSection>
            <SQL sql={viewDefinition} />
          </PanelSection>
        </Panel>
      )}
      {tab == "overview" && materializedView && (
        <SchemaTableStats
          databaseId={databaseId}
          tableId={id}
          stats={lastStats}
          fillfactor={fillfactor}
          materializedView={true}
        />
      )}
      {tab == "columns" && (
        <SchemaTableColumns databaseId={databaseId} tableId={id} />
      )}
      {tab == "indexes" && (
        <SchemaTableIndexes databaseId={databaseId} tableId={id} />
      )}
    </PageContent>
  );
};

export default SchemaViewDetails;
