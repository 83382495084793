import React, { ComponentProps, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";

import Dropdown from "../Dropdown";

import styles from "./style.module.scss";
import { makeFilter } from "utils/filter";
import classNames from "classnames";

const FILTER_THRESHOLD = 5;

export function MenuList<T extends { id: string }>({
  trigger,
  prompt,
  noMatch,
  items,
  filterKeys,
  renderer: ItemRenderer,
  children: extraItems,
}: {
  trigger: ComponentProps<typeof Dropdown>["trigger"];
  prompt: string;
  noMatch?: string;
  items: T[];
  filterKeys: (keyof T)[];
  renderer: React.ComponentType<{ item: T }>;
  children?: React.ReactNode;
}) {
  const [filter, setFilter] = useState("");
  const doFilter = items.length > FILTER_THRESHOLD;
  const menuItems = doFilter
    ? items.filter(makeFilter(filter, ...filterKeys))
    : items;

  function resetFilter() {
    setFilter("");
  }

  const listClass = classNames(
    "mb-0 relative list-none",
    doFilter && "max-h-[9.6rem] overflow-y-scroll",
  );

  return (
    <Dropdown
      trigger={trigger}
      className={styles.dropdown}
      onClose={resetFilter}
    >
      {({ setClosed }) => {
        return (
          <div className={styles.selectMenuList}>
            {doFilter && (
              <ListFilter
                prompt={prompt}
                filter={filter}
                setFilter={setFilter}
              />
            )}
            {(doFilter || menuItems.length > 0) && (
              <ul className={listClass}>
                {doFilter && menuItems.length === 0 ? (
                  <li>
                    <div
                      className={classNames(
                        styles.selectMenuListItem,
                        styles.noMatches,
                      )}
                    >
                      <span>&mdash; {noMatch ?? "no matches"} &mdash;</span>
                    </div>
                  </li>
                ) : (
                  menuItems.map((item) => (
                    <li key={item.id} onClick={setClosed}>
                      <ItemRenderer item={item} />
                    </li>
                  ))
                )}
              </ul>
            )}
            {extraItems}
          </div>
        );
      }}
    </Dropdown>
  );
}

const ListFilter = ({
  prompt,
  filter,
  setFilter,
}: {
  prompt: string;
  filter: string;
  setFilter: (v: string) => void;
}) => {
  // We want to focus the input field when the menu first opens; i.e.,
  // (currently) when this component is first rendered. We want to avoid the
  // autofocus behavior on touch devices, since it's probably more convenient to
  // scroll through the recent item list. We don't bother to subscribe to events
  // for the query, because this is not likely to change in the course of a
  // single run of our application on a specific device.
  const focusInput = window.matchMedia("(pointer: fine)");
  return (
    <div className="flex leading-5 text-base mt-2 mb-1 pb-2 border-b border-[#ccc]">
      <FontAwesomeIcon
        className="flex-none text-sm text-[#ccc] mr-1.5"
        icon={faMagnifyingGlass}
      />
      <input
        autoFocus={focusInput.matches}
        className="flex-grow flex-shrink min-w-0"
        placeholder={prompt}
        type="text"
        value={filter}
        onChange={(e) => setFilter(e.currentTarget.value)}
      />
    </div>
  );
};

export default MenuList;
