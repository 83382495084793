import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import classNames from "classnames";

import { useAsyncActionFlash } from "components/WithFlashes";

import MUTATION from "./Mutation.graphql";

import {
  UpdateOrganizationDefaultSamlRole,
  UpdateOrganizationDefaultSamlRoleVariables,
} from "./types/UpdateOrganizationDefaultSamlRole";
import Select from "components/Select";

type OrganizationRole = { id: string; name: string };

const UpdateRoleSaml: React.FunctionComponent<{
  organizationSlug: string;
  disabled: boolean;
  defaultRole: OrganizationRole;
  roles: OrganizationRole[];
}> = ({ organizationSlug, disabled, defaultRole, roles }) => {
  const [selectedRole, setSelectedRole] = useState<OrganizationRole | null>(
    defaultRole,
  );
  useEffect(() => {
    setSelectedRole(defaultRole);
  }, [defaultRole]);
  const [updateRole, { called, loading, error }] = useMutation<
    UpdateOrganizationDefaultSamlRole,
    UpdateOrganizationDefaultSamlRoleVariables
  >(MUTATION);

  useAsyncActionFlash({
    called,
    loading,
    error: error?.message,
    success: "Default role for SAML updated",
  });

  if (disabled) {
    return null;
  }
  const updateDisabled =
    selectedRole == null || selectedRole?.id === defaultRole?.id;

  const handleRoleSelected = (selected: OrganizationRole | null) => {
    setSelectedRole(selected);
  };

  const handleUpdateButtonClick = () => {
    updateRole({
      variables: {
        organizationSlug,
        organizationRoleId: selectedRole.id,
      },
    });
  };

  return (
    <>
      <button
        disabled={updateDisabled}
        onClick={handleUpdateButtonClick}
        className={classNames("btn btn-primary")}
      >
        Update default role
      </button>
      <span>
        Update a default role to be used for new members{" "}
        <Select
          placeholder="— select a role —"
          items={roles}
          itemToString={(item) => item.name}
          value={selectedRole}
          onChange={handleRoleSelected}
        />
        .
      </span>
    </>
  );
};

export default UpdateRoleSaml;
