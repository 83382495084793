import React, { useState } from "react";
import { Link } from "react-router-dom";

import { formatPercent } from "utils/format";
import { useRoutes } from "utils/routes";
import PanelSection from "components/PanelSection";

import { ServerQueryOverview_getQueryOverviewStatsByDatabase as SummaryType } from "../types/ServerQueryOverview";
import styles from "./style.module.scss";
import { makeFilter } from "utils/filter";
import Grid, { MsCell, NumberCell } from "components/Grid";

type RowDataType = {
  databaseId: string | null | undefined;
  databaseName: string | null | undefined;
} & SummaryType;

type Props = {
  data: SummaryType[];
};

const ServerQueryOverviewTable: React.FunctionComponent<Props> = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const tableData = data.map((inputData: SummaryType): RowDataType => {
    return {
      ...inputData,
      databaseId: (inputData.database && inputData.database.id) || null,
      databaseName: (inputData.database && inputData.database.datname) || null,
    };
  });

  const gridData = tableData.filter(makeFilter(searchTerm, "databaseName"));

  return (
    <>
      <PanelSection>
        <strong>Select a database to show detailed query statistics:</strong>
        <input
          placeholder="Search..."
          type="text"
          name="filter[search]"
          className={styles.filterSearch}
          onChange={(evt) => setSearchTerm(evt.currentTarget.value)}
        />
      </PanelSection>
      <Grid
        className="grid-cols-[minmax(30%,1fr),repeat(4,minmax(12%,120px))]"
        striped
        defaultSortBy="queriesPerSecond"
        data={gridData}
        columns={[
          {
            field: "databaseName",
            header: "Database",
            renderer: DatabaseNameCell,
          },
          {
            field: "avgTime",
            header: "Avg Time (ms)",
            style: "number",
            defaultSortOrder: "desc",
            renderer: MsCell,
          },
          {
            field: "queriesPerSecond",
            header: "Queries / Second",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: NumberCell,
          },
          {
            field: "pctOfTotalIo",
            header: "% of All I/O",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: function PctOfTotalIoCell({ fieldData }) {
              return formatPercent(fieldData / 100.0);
            },
          },
          {
            field: "pctOfTotal",
            header: "% of All Runtime",
            style: "number",
            defaultSortOrder: "desc",
            renderer: function PctOfTotalCell({ fieldData }) {
              return formatPercent(fieldData / 100.0);
            },
          },
        ]}
      />
    </>
  );
};

const DatabaseNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { databaseQueries } = useRoutes();
  return (
    <span>
      {rowData.database && !rowData.database.hidden && (
        <Link to={databaseQueries(rowData.database.id)}>
          {rowData.database.datname}
        </Link>
      )}
      {rowData.database && rowData.database.hidden && rowData.database.datname}
    </span>
  );
};

export default ServerQueryOverviewTable;
