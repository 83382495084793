import React from "react";
import { useParams } from "react-router-dom";

import { useRoutes } from "utils/routes";
import { useQuery } from "@apollo/client";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Form from "components/Form";
import FormSubmit from "components/FormSubmit";
import SettingsNav from "components/SettingsNav";
import Loading from "components/Loading";

import UsageStats from "./UsageStats";
import {
  OrganizationSettings as OrganizationSettingsType,
  OrganizationSettingsVariables,
} from "./types/OrganizationSettings";

import QUERY from "./Query.graphql";

const OrganizationSettings: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const { organization } = useRoutes();

  const { data, loading, error } = useQuery<
    OrganizationSettingsType,
    OrganizationSettingsVariables
  >(QUERY, {
    variables: { organizationSlug },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { name, permittedToEditOrganization } = data.getOrganizationDetails;

  return (
    <PageContent
      title="Settings"
      pageCategory="organization"
      pageName="edit"
      featureNav={<SettingsNav />}
    >
      <UsageStats organizationSlug={organizationSlug} />
      <Panel title="Edit Organization">
        {permittedToEditOrganization && (
          <Form action={organization(organizationSlug)} method="patch">
            <div className="form-group">
              <label htmlFor="organization_name">Organization Name</label>
              <div className="row">
                <div className="col-sm-5">
                  <input
                    type="text"
                    name="organization[name]"
                    id="organization_name"
                    defaultValue={name}
                    className="form-control"
                    required={true}
                  />
                </div>
                <div className="col-sm-7">
                  <div className="help-block">
                    Choose a descriptive name (anything that you like)
                  </div>
                </div>
              </div>
            </div>
            <FormSubmit title="Update Organization" />
          </Form>
        )}
        {!permittedToEditOrganization && (
          <PanelSection>
            <p>You are not allowed to edit this organization.</p>
          </PanelSection>
        )}
      </Panel>
    </PageContent>
  );
};

export default OrganizationSettings;
