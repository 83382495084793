export function isMissingQueryText(queryText: string): boolean {
  return (
    queryText === "<query text unavailable>" ||
    queryText === "<unidentified queryid>" ||
    /^<unidentified queryid (\d+)>$/.test(queryText)
  );
}

export function normalizeMissingQueryText(queryText: string): string {
  if (isMissingQueryText(queryText)) {
    return "<query text unavailable>";
  }

  return queryText;
}
