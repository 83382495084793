import React, { useCallback, useState } from "react";

import classNames from "classnames";
import moment from "moment-timezone";

import {
  formatNumber,
  formatBytes,
  formatMs,
  formatPercent,
  formatTimestampShort,
} from "utils/format";

import {
  ExplainComparison as ExplainComparisonType,
  ExplainComparison_getQueryExplains as ExplainSummary,
  ExplainComparisonVariables,
} from "./types/ExplainComparison";
import {
  ExplainComparisonWorkbook as ExplainComparisonWorkbookType,
  ExplainComparisonWorkbookVariables,
  ExplainComparisonWorkbook_getExplainWorkbookDetails as ExplainComparisonWorkbookDetailsType,
} from "./types/ExplainComparisonWorkbook";

import { AnnotatedPlan } from "types/explain";
import ExplainFingerprint from "components/ExplainFingerprint";
import PanelTable from "components/PanelTable";

import QUERY from "./Query.graphql";
import QUERY_WORKBOOK from "./Query.workbook.graphql";

import styles from "./style.module.scss";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import { ExplainPlanType } from "components/Explain/util";
import {
  ExplainCostMetric,
  useCostMetric,
  useSetCostMetric,
  WithExplainCostMetric,
} from "components/WithExplainCostMetric";
import ExplainDiff from "components/ExplainDiff";
import { useFeature } from "components/OrganizationFeatures";
import {
  ComparablePlanType,
  useSetCurrentComparePlan,
  useSetCurrentPlan,
} from "components/WithExplainPlan";
import { useRouteSearchState } from "utils/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import Grid, { MsCell } from "components/Grid";
import ModalContainer from "components/ModalContainer";
import { mru } from "utils/array";

const ExplainComparison: React.FunctionComponent<{
  databaseId: string;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
  blockSize: number;
}> = ({ databaseId, blockSize, explain, plan }) => {
  const hasExplainCompareFeature = useFeature("explainCompare");
  if (hasExplainCompareFeature) {
    // TODO: Rework the data fetching to only load the annotated JSON for the plans being compared
    return (
      <ExplainComparisonQueryPage
        databaseId={databaseId}
        queryId={explain.query.id}
        blockSize={blockSize}
      />
    );
  } else {
    return (
      <ExplainComparisonOld
        databaseId={databaseId}
        blockSize={blockSize}
        explain={explain}
        plan={plan}
      />
    );
  }
};

function getParameterSetName(
  parameterSetId: string,
  workbookDetails: ExplainComparisonWorkbookDetailsType,
): string {
  return (
    workbookDetails.parameterSets.find((set) => set.id === parameterSetId)
      ?.name ?? parameterSetId
  );
}

const ExplainComparisonQueryPage: React.FunctionComponent<{
  databaseId: string;
  blockSize: number;
  queryId: string;
}> = ({ databaseId, blockSize, queryId }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonType,
    ExplainComparisonVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const comparablePlans = data.getQueryExplains.map((e) => ({
    id: e.id,
    seenAt: e.seenAt,
    fingerprint: e.fingerprint,
    label: formatTimestampShort(moment.unix(e.seenAt)),
    runtime: e.querySample.runtimeMs,
    ioMs: e.totalBlkReadTime,
    ioBytes: e.totalSharedBlksRead * blockSize,
    totCost: e.totalCost,
    plan: JSON.parse(e.annotatedJson),
  }));
  return <ExplainComparisonImpl comparablePlans={comparablePlans} />;
};

const ExplainComparisonImpl: React.FunctionComponent<{
  comparablePlans: ComparablePlanType[];
}> = ({ comparablePlans }) => {
  const [open, setOpen] = useState(false);

  const { planA, planB } = useComparisonPlans(comparablePlans);

  function toggleOpen() {
    setOpen((open) => !open);
  }

  return (
    <div>
      <WithExplainCostMetric>
        <PlanSummaryTable planA={planA} planB={planB} />
        <div className="flex gap-4 justify-between items-center my-4">
          <ExplainSelectCostMetric />
          <button className="btn btn-primary" onClick={toggleOpen}>
            <FontAwesomeIcon icon={faEdit} /> Select Plans
          </button>
        </div>
        {open && (
          <SelectPlansToCompareModal
            toggleOpen={toggleOpen}
            comparablePlans={comparablePlans}
          />
        )}
        {planA && planB && (
          <ExplainDiff planA={planA.plan} planB={planB.plan} />
        )}
      </WithExplainCostMetric>
    </div>
  );
};

function useComparisonPlans(comparablePlans: ComparablePlanType[]) {
  const [plans, setPlans] = useRouteSearchState({
    key: "planCompare",
    decode: decodePlanComparison,
    encode: encodePlanComparison,
  });

  const setPlanA = useSetCurrentPlan();
  const setPlanB = useSetCurrentComparePlan();

  const [planAId, planBId] = plans ?? [null, null];

  const planA = comparablePlans.find((p) => p.id === planAId);
  const planB = comparablePlans.find((p) => p.id === planBId);

  const setComparisonPlans = useCallback(
    (planA: ComparablePlanType, planB: ComparablePlanType) => {
      setPlanA(planA);
      setPlanB(planB);
      setPlans([planA.id, planB.id]);
    },
    [setPlanA, setPlanB, setPlans],
  );

  return {
    planA,
    planB,
    setComparisonPlans,
  };
}

function encodePlanComparison(plans: [planA: string, planB: string]) {
  return plans.join("-vs-");
}

function decodePlanComparison(comparison: string) {
  return comparison.split("-vs-") as [planA: string, planB: string];
}

function SelectPlansToCompareModal({
  comparablePlans,
  toggleOpen,
}: {
  comparablePlans: ComparablePlanType[];
  toggleOpen: () => void;
}) {
  const { planA, planB, setComparisonPlans } =
    useComparisonPlans(comparablePlans);
  const initialCandidates = [] as string[];
  if (planA) {
    initialCandidates.push(planA.id);
  }
  if (planB) {
    initialCandidates.push(planB.id);
  }
  const [compareCandidates, setCompareCandidates] =
    useState<string[]>(initialCandidates);

  function addCompareCanditate(candidateId: string) {
    const newCandidates = mru(candidateId, compareCandidates, 2);
    setCompareCandidates(newCandidates);
  }

  function clearCompareCanditate(candidateId: string) {
    const newCandidates = compareCandidates.filter((id) => id !== candidateId);
    setCompareCandidates(newCandidates);
  }

  const candidateA =
    compareCandidates.length > 0 &&
    comparablePlans.find((p) => p.id === compareCandidates[0]);
  const candidateB =
    compareCandidates.length > 1 &&
    comparablePlans.find((p) => p.id === compareCandidates[1]);

  return (
    <ModalContainer
      title="Select plans to compare"
      layout="centered"
      onClose={toggleOpen}
    >
      <Grid
        pageSize={10}
        data={comparablePlans}
        columns={[
          {
            field: "id",
            header: "",
            disableSort: true,
            width: "34px",
            renderer: ({ fieldData }) => {
              function handleToggleChecked(
                e: React.ChangeEvent<HTMLInputElement>,
              ) {
                if (e.currentTarget.checked) {
                  addCompareCanditate(fieldData);
                } else {
                  clearCompareCanditate(fieldData);
                }
              }
              const checked = compareCandidates.includes(fieldData);
              return (
                <input
                  type="checkbox"
                  className="cursor-pointer !mt-0.5"
                  checked={checked}
                  onChange={handleToggleChecked}
                />
              );
            },
          },
          {
            field: "fingerprint",
            width: "120px",
            renderer: ({ fieldData }) => {
              return <ExplainFingerprint fingerprint={fieldData} />;
            },
          },
          {
            width: "1fr",
            field: "label",
          },
          {
            field: "runtime",
            style: "number",
            nullValue: "n/a",
            renderer: MsCell,
            width: "minmax(10%,180px)",
          },
          {
            field: "ioMs",
            header: "I/O Read Time",
            style: "number",
            nullValue: "n/a",
            renderer: MsCell,
            width: "minmax(10%,180px)",
          },
        ]}
      />
      <div className="mt-4">
        <PlanSummaryTable planA={candidateA} planB={candidateB} />
      </div>
      <button
        disabled={compareCandidates.length < 2}
        className="mt-4 btn btn-success"
        onClick={() => {
          if (compareCandidates.length < 2) {
            return;
          }
          setComparisonPlans(candidateA, candidateB);
          toggleOpen();
        }}
      >
        Compare Plans
      </button>
    </ModalContainer>
  );
}

function PlanSummaryTable({
  planA,
  planB,
}: {
  planA: ComparablePlanType;
  planB: ComparablePlanType;
}) {
  return (
    <table className="w-full border-spacing-1 border-separate table-fixed">
      <thead>
        <tr>
          <th className="w-16"></th>
          <th className="w-32">Fingerprint</th>
          <th className="w-full">Description</th>
          <th className="w-32 text-right">Runtime</th>
          <th className="w-32 text-right">I/O Read Time</th>
        </tr>
      </thead>
      <tbody>
        <PlanSummaryRow label="Plan A" plan={planA} />
        <PlanSummaryRow label="Plan B" plan={planB} />
      </tbody>
    </table>
  );
}

function PlanSummaryRow({
  label,
  plan,
}: {
  label: string;
  plan: ComparablePlanType;
}) {
  return (
    <tr>
      <th className="whitespace-nowrap h-6" scope="row">
        {label}
      </th>
      <td>{plan ? <ExplainFingerprint explain={plan} /> : "n/a"}</td>
      <td>{plan?.label ?? "n/a"}</td>
      <td className="text-right">
        {plan?.runtime != null ? formatMs(plan.runtime) : "n/a"}
      </td>
      <td className="text-right">
        {plan?.ioMs != null ? formatMs(plan.ioMs) : "n/a"}
      </td>
    </tr>
  );
}

function ExplainSelectCostMetric() {
  const costMetric = useCostMetric();
  const setCostMetric = useSetCostMetric();
  function handleMetricChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setCostMetric(evt.currentTarget.value as ExplainCostMetric);
  }

  return (
    <div className="flex gap-2 items-baseline justify-end">
      <b>Cost Metric:</b>
      <label>
        <input
          className="!mr-1"
          type="radio"
          value="Est. Cost"
          checked={costMetric == "Est. Cost"}
          onChange={handleMetricChange}
        />
        Est. Total Cost (Self)
      </label>
      <label>
        <input
          className="!mr-1"
          type="radio"
          value="Runtime"
          checked={costMetric == "Runtime"}
          onChange={handleMetricChange}
        />
        Runtime (Self)
      </label>
      <label>
        <input
          className="!mr-1"
          type="radio"
          value="I/O Time"
          checked={costMetric == "I/O Time"}
          onChange={handleMetricChange}
        />
        I/O Read Time (Self)
      </label>
      <label>
        <input
          className="!mr-1"
          type="radio"
          value="Rows"
          checked={costMetric == "Rows"}
          onChange={handleMetricChange}
        />
        Rows
      </label>
    </div>
  );
}

export const ExplainComparisonWorkbook: React.FunctionComponent<{
  databaseId: string;
  blockSize: number;
  workbookId: string;
}> = ({ workbookId, blockSize }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonWorkbookType,
    ExplainComparisonWorkbookVariables
  >(QUERY_WORKBOOK, {
    variables: {
      workbookId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const comparablePlans: ComparablePlanType[] = [];
  [data.getExplainWorkbookDetails.baselineQuery]
    .concat(data.getExplainWorkbookDetails.explainQueries)
    .forEach((explainQuery) =>
      explainQuery.explainResults.forEach((e) => {
        const label = `${explainQuery.name} - ${getParameterSetName(
          e.parameterSetId,
          data.getExplainWorkbookDetails,
        )}`;
        comparablePlans.push({
          id: e.id,
          label,
          seenAt: e.createdAt,
          fingerprint: e.planFingerprint,
          runtime: e.runtimeMs,
          ioMs: e.totalBlkReadTime,
          ioBytes: e.totalSharedBlksRead * blockSize,
          totCost: e.totalCost,
          plan: JSON.parse(e.annotatedJson),
        });
      }),
    );

  return <ExplainComparisonImpl comparablePlans={comparablePlans} />;
};

const ExplainComparisonOld: React.FunctionComponent<{
  databaseId: string;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
  blockSize: number;
}> = ({ databaseId, blockSize, explain, plan }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonType,
    ExplainComparisonVariables
  >(QUERY, {
    variables: {
      queryId: explain.query.id,
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const explains = data.getQueryExplains;
  const explainsWithQuerySample = explains.filter((e) => e.querySample);
  return (
    <ExplainComparisonView
      ioBlocks={explain.totalSharedBlksRead}
      ioMs={explain.totalBlkReadTime}
      fingerprint={explain.fingerprint}
      plan={plan}
      current={explain}
      explains={explainsWithQuerySample}
      runtime={explain.querySample.runtimeMs}
      blockSize={blockSize}
      seenAt={explain.seenAt}
    />
  );
};

type Props = {
  fingerprint: string;
  runtime: number;
  ioBlocks: number;
  blockSize: number;
  ioMs: number;
  plan: AnnotatedPlan;
  explains: ExplainSummary[];
  seenAt: number;
  current: ExplainPlanType;
};

const ExplainComparisonView: React.FunctionComponent<Props> = ({
  plan,
  fingerprint,
  runtime,
  ioBlocks,
  blockSize,
  ioMs,
  explains,
  seenAt,
  current,
}) => {
  const root = plan.plan[0].Plan;
  const totCost =
    "Total Cost" in root ? formatNumber(root["Total Cost"]) : "N/A";

  // TODO
  //  * if this execution is not in the plans list, don't show comparisons
  //  * if this execution is the only plan with *this* fingerprint, don't show comparisons with--well basically with itself
  //  * if there is only one other plan with same fingerprint, don't show min/max
  //  * if there is only one other plan with *different* fingerprint, don't show min/max

  const thisPlanExplains = explains.filter(
    (e) => e.fingerprint === fingerprint,
  );
  const thisPlanSummary = getExplainsStats(thisPlanExplains);

  const otherPlanExplains = explains.filter(
    (e) => e.fingerprint !== fingerprint,
  );
  const otherPlanSummary = getExplainsStats(otherPlanExplains);

  const hasAnyOtherPlans = explains.length > 0;
  return (
    <PanelTable borders={true} className={styles.explainComparison}>
      {hasAnyOtherPlans && (
        <thead>
          <tr>
            <th />
            <th>This Execution</th>
            {thisPlanExplains.length > 0 && (
              <th colSpan={2}>
                This Plan{" · "}
                <ExplainFingerprint explain={current} />
              </th>
            )}
            {otherPlanExplains.length > 0 && <th colSpan={2}>Other Plans</th>}
          </tr>
          <tr>
            <th />
            <th>{formatTimestampShort(moment.unix(seenAt))}</th>
            {thisPlanExplains.length > 0 && (
              <>
                <th>min</th>
                <th>max</th>
              </>
            )}
            {otherPlanExplains.length > 0 && (
              <>
                <th>min</th>
                <th>max</th>
              </>
            )}
          </tr>
        </thead>
      )}
      <tbody>
        <tr>
          <th scope="row">Total Est. Cost</th>
          <td>{totCost}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>{formatNumber(thisPlanSummary.totCost.min)}</td>
              <td>{formatNumber(thisPlanSummary.totCost.max)}</td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>{formatNumber(otherPlanSummary.totCost.min)}</td>
              <td>{formatNumber(otherPlanSummary.totCost.max)}</td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">Runtime</th>
          <td>{formatMs(runtime)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>{formatMs(thisPlanSummary.runtime.min)}</td>
              <td>{formatMs(thisPlanSummary.runtime.max)}</td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>{formatMs(otherPlanSummary.runtime.min)}</td>
              <td>{formatMs(otherPlanSummary.runtime.max)}</td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">Read From Disk</th>
          <td>{formatBlocksBytes(ioBlocks, blockSize)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>
                {formatBlocksBytes(thisPlanSummary.blksRead.min, blockSize)}
              </td>
              <td>
                {formatBlocksBytes(thisPlanSummary.blksRead.max, blockSize)}
              </td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>
                {formatBlocksBytes(otherPlanSummary.blksRead.min, blockSize)}
              </td>
              <td>
                {formatBlocksBytes(otherPlanSummary.blksRead.max, blockSize)}
              </td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">I/O Read Time</th>
          <td>{formatIOReadTime(ioMs, ioMs / runtime)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>
                {formatIOReadTime(
                  thisPlanSummary.blkReadTime.min,
                  thisPlanSummary.blkReadTimeFract.min,
                )}
              </td>
              <td>
                {formatIOReadTime(
                  thisPlanSummary.blkReadTime.max,
                  thisPlanSummary.blkReadTimeFract.max,
                )}
              </td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>
                {formatIOReadTime(
                  otherPlanSummary.blkReadTime.min,
                  otherPlanSummary.blkReadTimeFract.min,
                )}
              </td>
              <td>
                {formatIOReadTime(
                  otherPlanSummary.blkReadTime.max,
                  otherPlanSummary.blkReadTimeFract.max,
                )}
              </td>
            </>
          )}
        </tr>
      </tbody>
    </PanelTable>
  );
};

type Range = {
  min: number;
  max: number;
};

type ExplainStats = {
  totCost: Range;
  runtime: Range;
  blksRead: Range;
  blkReadTime: Range;
  blkReadTimeFract: Range;
};

const getExplainsStats = (explains: ExplainSummary[]): ExplainStats => {
  const totCosts = explains.map((e) => e.totalCost);
  const runtimes = explains.map((e) => e.querySample.runtimeMs);
  const blksRead = explains.map((e) => e.totalSharedBlksRead);

  type BlkTimeReduceState = {
    min: number;
    max: number;
    minRuntime: number;
    maxRuntime: number;
  };
  const blkReadTime = explains.reduce<BlkTimeReduceState>(
    (state, curr) => {
      const result = { ...state };
      if (curr.totalBlkReadTime > state.max) {
        Object.assign(result, {
          max: curr.totalBlkReadTime,
          maxRuntime: curr.querySample.runtimeMs,
        });
      }
      if (curr.totalBlkReadTime < state.min) {
        Object.assign(result, {
          min: curr.totalBlkReadTime,
          minRuntime: curr.querySample.runtimeMs,
        });
      }
      return result;
    },
    { min: Infinity, max: -1, minRuntime: Infinity, maxRuntime: -1 },
  );

  const min = (values: number[]): number => {
    return Math.min.apply(null, values);
  };
  const max = (values: number[]): number => {
    return Math.max.apply(null, values);
  };

  return {
    totCost: {
      min: min(totCosts),
      max: max(totCosts),
    },
    runtime: {
      min: min(runtimes),
      max: max(runtimes),
    },
    blksRead: {
      min: min(blksRead),
      max: max(blksRead),
    },
    blkReadTime: {
      min: blkReadTime.min,
      max: blkReadTime.max,
    },
    blkReadTimeFract: {
      min: blkReadTime.min / blkReadTime.minRuntime,
      max: blkReadTime.max / blkReadTime.maxRuntime,
    },
  };
};

const formatBlocksBytes = (blocks: number, blockSize: number): string => {
  return `${formatBytes(blocks * blockSize)} · ${formatNumber(blocks)} blocks`;
};

const formatIOReadTime = (
  ioMs: number,
  ioFract: number,
): React.ReactElement => {
  if (!ioMs) {
    return <>-</>;
  }

  return (
    <>
      <span>{formatMs(ioMs)}</span>
      <span>
        {" "}
        ·{" "}
        <span
          className={classNames({
            [styles.redHighlight]: ioFract > 0.5,
          })}
        >
          {formatPercent(ioFract)}
        </span>
      </span>
    </>
  );
};

export default ExplainComparison;
