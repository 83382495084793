import React from "react";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import PageContent from "components/PageContent";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import DateRangeBar from "components/DateRangeBar";
import { WithDateRange } from "components/WithDateRange";

import VacuumAdvisorOverview from "./VacuumAdvisorOverview";
import VacuumTimeline from "./VacuumTimeline";
import VacuumHistory from "./VacuumHistory";
import VacuumRunning from "./VacuumRunning";
import VacuumServerKPITiles from "./VacuumServerKPITiles";

import { retention } from "utils/limits";
import { useRoutes } from "utils/routes";

import QUERY from "./Query.graphql";
import {
  GetVacuumAdvisorData,
  GetVacuumAdvisorDataVariables,
  GetVacuumAdvisorData_getIssues as IssueType,
  GetVacuumAdvisorData_getIssueCounts as IssueCountType,
  GetVacuumAdvisorData_getPostgresSettings as SettingType,
  GetVacuumAdvisorData_getServerDetails_databasesWithoutColumnStats as DatabaseWithoutColumnStatsType,
  GetVacuumAdvisorData_getVacuumInsightStatus as InsightStatusType,
} from "./types/GetVacuumAdvisorData";
import Loading from "components/Loading";
import VacuumAdvisorPerformance from "./VacuumAdvisorPerformance";
import VacuumAdvisorBloat from "./VacuumAdvisorBloat";
import VacuumAdvisorFreezing from "./VacuumAdvisorFreezing";
import { isCategory } from "./util";
import { useCurrentServer } from "components/WithCurrentOrganization";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { InfoIcon } from "components/Icons";
import moment from "moment";

const VacuumAdvisor: React.FunctionComponent<{
  tab: VacuumAdvisorTabType;
}> = ({ tab }) => {
  const server = useCurrentServer();
  const serverId = server.humanId;
  const { databaseId } = useParams();
  const {
    serverVacuums,
    serverVacuumsActivity,
    serverVacuumsBloat,
    serverVacuumsPerformance,
    serverVacuumsFreezing,
    databaseVacuumsFreezing,
    databaseVacuumsBloat,
    databaseVacuumsActivity,
  } = useRoutes();

  const { data, loading, error } = useQuery<
    GetVacuumAdvisorData,
    GetVacuumAdvisorDataVariables
  >(QUERY, {
    variables: {
      serverId,
      databaseId,
    },
  });

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink to={serverVacuums(serverId)}>Overview</PageNavLink>
      <PageNavLink
        to={
          databaseId
            ? databaseVacuumsBloat(databaseId)
            : serverVacuumsBloat(serverId)
        }
      >
        Bloat
      </PageNavLink>
      <PageNavLink
        to={
          databaseId
            ? databaseVacuumsFreezing(databaseId)
            : serverVacuumsFreezing(serverId)
        }
      >
        Freezing
      </PageNavLink>
      <PageNavLink to={serverVacuumsPerformance(serverId)}>
        Performance
      </PageNavLink>
      <PageNavLink
        to={
          databaseId
            ? databaseVacuumsActivity(databaseId)
            : serverVacuumsActivity(serverId)
        }
      >
        Activity
      </PageNavLink>
    </PageSecondaryNavigation>
  );

  const pageControls = ["freezing", "activity", "bloat"].includes(tab) ? (
    <DateRangeBar feature="VACUUM Activity" />
  ) : null;

  const { blockSize } = data?.getServerDetails ?? {};
  const issues = data?.getIssues;
  const issueCounts = data?.getIssueCounts;
  const settings = data?.getPostgresSettings;
  const serverName = data?.getServerDetails.name;
  const serverCreatedAt = moment.unix(data?.getServerDetails.createdAt);
  const insightStatus = data?.getVacuumInsightStatus.reduce(
    (m, o) => ((m[o.checkGroupAndName] = o), m),
    {},
  );

  const isPrimary = data?.getLatestPostgresServerStats?.isPrimary ?? true;
  const dbsWithoutColStats = data?.getServerDetails.databasesWithoutColumnStats;
  const showKPITiles =
    isPrimary && ["overview", "performance", "bloat", "freezing"].includes(tab);

  return (
    <WithDateRange initialHorizon={retention.default}>
      <PageContent
        title="VACUUM Advisor"
        pageControls={pageControls}
        pageCategory="vacuums"
        pageName="index"
        featureNav={featureNav}
        pageTab={tab}
      >
        {showKPITiles && (
          <VacuumServerKPITiles
            loading={!!(loading || error)}
            tab={tab}
            serverDetails={data?.getServerDetails}
            settings={settings}
          />
        )}
        {loading || error ? (
          <Loading error={!!error} />
        ) : !isPrimary ? (
          <NoVacuumAdvisorOnReplicasPanel />
        ) : (
          <VacuumAdvisorContent
            tab={tab}
            serverId={serverId}
            serverCreatedAt={serverCreatedAt}
            serverName={serverName}
            blockSize={blockSize}
            databaseId={databaseId}
            databasesWithoutColumnStats={dbsWithoutColStats}
            issues={issues}
            issueCounts={issueCounts}
            settings={settings}
            insightStatus={insightStatus}
          />
        )}
      </PageContent>
    </WithDateRange>
  );
};

const NoVacuumAdvisorOnReplicasPanel: React.FunctionComponent = () => {
  return (
    <Panel title="Not available on replicas">
      <PanelSection>
        <InfoIcon className="mr-2" />
        Since VACUUM activity only occurs on primaries, VACUUM Advisor is not
        available on replicas. Please check the primary for this replica
        instead.
      </PanelSection>
    </Panel>
  );
};

export type VacuumAdvisorTabType =
  | "overview"
  | "activity"
  | "performance"
  | "bloat"
  | "freezing";

const VacuumAdvisorContent: React.FunctionComponent<{
  tab: VacuumAdvisorTabType;
  serverId: string;
  serverCreatedAt: moment.Moment;
  serverName: string;
  blockSize: number;
  databaseId?: string;
  databasesWithoutColumnStats: DatabaseWithoutColumnStatsType;
  issueCounts: IssueCountType[];
  issues: IssueType[];
  settings: SettingType[];
  insightStatus: { [key: string]: InsightStatusType };
}> = ({
  tab,
  serverId,
  serverCreatedAt,
  serverName,
  blockSize,
  databaseId,
  databasesWithoutColumnStats,
  issueCounts,
  issues,
  settings,
  insightStatus,
}) => {
  const currentIssues = getCurrentIssues(issues, tab);
  return (
    <>
      {tab === "overview" && (
        <VacuumAdvisorOverview
          serverId={serverId}
          issues={issues}
          issueCounts={issueCounts}
        />
      )}
      {tab === "activity" && (
        <div>
          <VacuumTimeline serverId={serverId} databaseId={databaseId} />
          <VacuumRunning serverId={serverId} databaseId={databaseId} />
          <VacuumHistory serverId={serverId} databaseId={databaseId} />
        </div>
      )}
      {tab === "performance" && (
        <VacuumAdvisorPerformance
          serverId={serverId}
          serverCreatedAt={serverCreatedAt}
          serverName={serverName}
          blockSize={blockSize}
          issues={currentIssues}
          settings={settings}
          insightStatus={insightStatus}
        />
      )}
      {tab === "bloat" && (
        <VacuumAdvisorBloat
          serverId={serverId}
          serverCreatedAt={serverCreatedAt}
          serverName={serverName}
          databaseId={databaseId}
          databasesWithoutColumnStats={databasesWithoutColumnStats}
          issues={currentIssues}
          settings={settings}
          insightStatus={insightStatus}
        />
      )}
      {tab === "freezing" && (
        <VacuumAdvisorFreezing
          serverId={serverId}
          serverCreatedAt={serverCreatedAt}
          serverName={serverName}
          databaseId={databaseId}
          issues={currentIssues}
          settings={settings}
          insightStatus={insightStatus}
        />
      )}
    </>
  );
};

function getCurrentIssues(
  allIssues: IssueType[] | undefined,
  tab: VacuumAdvisorTabType,
): IssueType[] | undefined {
  if (!allIssues) {
    return undefined;
  }

  switch (tab) {
    case "overview":
      return allIssues;
    case "performance":
    case "bloat":
    case "freezing":
      return allIssues.filter(isCategory(tab));
    default:
      return [];
  }
}

export default VacuumAdvisor;
