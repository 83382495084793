---
title: 'Step 2: Enable pg_stat_statements'
install_track_title: Installation Guide (Self-Managed)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

## Install official PostgreSQL contrib package

On your database server, make sure that the extensions package is installed:

```
sudo yum install postgresql-contrib
```

## Enable pg_stat_statements

Run the following SQL statement as a superuser, to enable the `pg_stat_statements` extension:

```
ALTER SYSTEM SET shared_preload_libraries = 'pg_stat_statements';
```

## Restart the PostgreSQL daemon

If you have not used pg_stat_statements before, you will need a full Postgres restart to enable it for the first time:

```
sudo systemctl restart postgresql
```

## Verify that pg_stat_statements returns data

As a superuser, run the following statements:

```
CREATE EXTENSION IF NOT EXISTS pg_stat_statements;
SELECT calls, query FROM pg_stat_statements LIMIT 1;
```

If you have configured your database correctly, this will return a result like this:

```
 calls | query
-------+-------
     8 | SELECT * FROM t WHERE field = ?
(1 row)
```

If you get an error you might not have restarted the server. If you get stuck feel free to reach out to us, we're happy to help.

Next we continue by installing the pganalyze collector:

<Link className="btn btn-success" to="03_install_the_collector_yum">
  Proceed to Step 3: Install the collector
</Link>
