import React from "react";

import styles from "./style.module.scss";

type Props = {
  name: string;
  avatarUrl: string;
};

const SIZE = 20;

const Avatar: React.FunctionComponent<Props> = ({ name, avatarUrl }) => {
  const handleError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = "//www.gravatar.com/avatar/?d=mp&s=" + SIZE;
    e.currentTarget.onerror = undefined;
  };

  return (
    <span>
      <img
        src={avatarUrl}
        onError={handleError}
        style={{ width: SIZE, height: SIZE }}
        className={styles.avatarImg}
      />
      <span>{name}</span>
    </span>
  );
};

export default Avatar;
