import React, { useState } from "react";

import classNames from "classnames";

import styles from "./style.module.scss";
import { useDebounced } from "utils/hooks";

type Props = {
  name?: string;
  className?: string;
  initialValue: string;
  debounceMs?: number;
  placeholder?: string;
  onChange: (newValue: string) => void;
};

const FilterSearch: React.FunctionComponent<Props> = ({
  name,
  className,
  initialValue = "",
  debounceMs = 0,
  placeholder,
  onChange,
}) => {
  const [value, setValue] = useState(initialValue);
  const debouncedOnChange = useDebounced(onChange, debounceMs);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setValue(newValue);
    debouncedOnChange(newValue);
  };
  return (
    <input
      placeholder={placeholder ?? "Search..."}
      type="text"
      name={name}
      value={value}
      className={classNames(styles.filterSearch, className)}
      onChange={handleInputChange}
    />
  );
};

export default FilterSearch;
