import React from "react";

import {
  formatNumber,
  formatBytes,
  formatMs,
  formatTimestampShortest,
} from "utils/format";

import moment from "moment";
import ExplainFingerprint from "components/ExplainFingerprint";
import { ComparablePlanType } from "components/WithExplainPlan";

type Props = {
  planA: ComparablePlanType;
  planB: ComparablePlanType;
};

const ExplainCompareSummary: React.FunctionComponent<Props> = ({
  planA,
  planB,
}) => {
  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td></td>
            <td className="text-center pb-2">Plan A</td>
            <td className="text-center pb-2">Plan B</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Seen At
            </th>
            <td className="text-right">
              {planA.seenAt != null
                ? formatTimestampShortest(moment.unix(planA.seenAt))
                : "-"}
            </td>
            <td className="text-right">
              {planB.seenAt != null
                ? formatTimestampShortest(moment.unix(planB.seenAt))
                : "-"}
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Plan Fingerprint
            </th>
            <td className="text-right">
              <ExplainFingerprint fingerprint={planA.fingerprint} />
            </td>
            <td className="text-right">
              <ExplainFingerprint fingerprint={planB.fingerprint} />
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Runtime
            </th>
            <td className="text-right">
              {planA.runtime != null ? formatMs(planA.runtime) : "-"}
            </td>
            <td className="text-right">
              {planB.runtime != null ? formatMs(planB.runtime) : "-"}
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              I/O Read Time
            </th>
            <td className="text-right">
              {planA.ioMs != null ? formatMs(planA.ioMs) : "-"}
            </td>
            <td className="text-right">
              {planB.ioMs != null ? formatMs(planB.ioMs) : "-"}
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Read From Disk
            </th>
            <td className="text-right">
              {planA.ioBytes != null ? formatBytes(planA.ioBytes) : "-"}
            </td>
            <td className="text-right">
              {planB.ioBytes != null ? formatBytes(planB.ioBytes) : "-"}
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Total Est. Cost
            </th>
            <td className="text-right">
              {planA.totCost != null ? formatNumber(planA.totCost) : "N/A"}
            </td>
            <td className="text-right">
              {planB.totCost != null ? formatNumber(planB.totCost) : "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExplainCompareSummary;
