import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import pluralize from "pluralize";

import { Node as NodeType, Annotation } from "types/explain";

import Badge from "components/Badge";
import PanelTable from "components/PanelTable";

import { formatNumber, formatMsAdaptive } from "utils/format";

import { useSelectedNode } from "../../Explain/WithNodeSelection";
import IndexInfo from "../../Explain/IndexInfo";
import RelationInfo from "../../Explain/RelationInfo";
import NodeHeading from "../NodeHeading";

import styles from "./style.module.scss";
import { faRepeat } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CTEInfo from "../CTEInfo";
import { useExplainDisplayOptions } from "components/ExplainPanel/WithExplainDisplayOptions";

type Props = {
  node: NodeType;
  databaseId: string;
  annotations: Annotation[];
  onNodeClick: (node: NodeType) => void;
  parent?: NodeType;
};

const Node: React.FunctionComponent<Props> = ({
  node,
  databaseId,
  annotations,
  onNodeClick,
  parent,
}) => {
  const { Plans, ...rest } = node;
  const [selectedNode] = useSelectedNode();
  const displayOpts = useExplainDisplayOptions();
  const isSelected = node === selectedNode;

  const nodeRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (isSelected && nodeRef.current) {
      nodeRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isSelected]);

  const handleNodeClick = () => {
    onNodeClick(node);
  };

  const nodeAnnotations = annotations.filter((a) => a.node === node.extra.id);

  const isNLInner =
    parent &&
    parent["Node Type"] == "Nested Loop" &&
    rest["Parent Relationship"] == "Inner";
  const workerCount = "Workers" in rest ? rest["Workers"].length : 0;
  const showExecutionsHint = (rest["Actual Loops"] ?? 0) > 1;
  const showExecutionsBlurb =
    showExecutionsHint || (isNLInner && rest["Actual Loops"] != null);

  const showActualTime =
    displayOpts.showActualTime && rest["Actual Total Time"] != null;
  const showIOTime =
    displayOpts.showIOTime &&
    rest["I/O Read Time"] != null &&
    rest["I/O Write Time"] != null;
  const showCost = displayOpts.showCost;
  const showRows = displayOpts.showActualRows;
  return (
    <div className={styles.nodeWrapper} ref={nodeRef}>
      <div
        className={classNames(styles.nodeCommon, styles.node, {
          [styles.nodeSelected]: isSelected,
        })}
      >
        <NodeHeading
          node={node}
          className={classNames({ [styles.selectedNodeHeading]: isSelected })}
          onNodeClick={handleNodeClick}
        >
          <NodeAnnotations annotations={nodeAnnotations} />
        </NodeHeading>
        <div className={styles.nodeContent}>
          <NodeSection>
            <CTEInfo node={node} />
            <RelationInfo node={node} databaseId={databaseId} />
            <IndexInfo node={node} databaseId={databaseId} />
          </NodeSection>
          <NodeSection>
            {showExecutionsBlurb && (
              <div className={styles.executionsBlurb}>
                <FontAwesomeIcon icon={faRepeat} /> Executed{" "}
                {pluralize("time", rest["Actual Loops"], true)}
                {workerCount > 0 && ` using ${workerCount} workers`}:
              </div>
            )}
            <PanelTable>
              {showExecutionsHint && (
                <thead>
                  <tr>
                    <th>Metric</th>
                    <th>Total</th>
                    <th>Average</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {showActualTime && (
                  <tr>
                    <td>Actual Time:</td>
                    {showExecutionsHint && rest["Workers"] && <td>-</td>}
                    {showExecutionsHint && !rest["Workers"] && (
                      <td>
                        {formatMsAdaptive(
                          rest["Actual Total Time"] * rest["Actual Loops"],
                        )}
                      </td>
                    )}
                    <td>{formatMsAdaptive(rest["Actual Total Time"])}</td>
                  </tr>
                )}
                {showIOTime && (
                  <tr>
                    <td>I/O Time:</td>
                    <td>
                      {formatMsAdaptive(
                        rest["I/O Read Time"] + rest["I/O Write Time"],
                      )}
                    </td>
                    {showExecutionsHint && rest["Workers"] && <td>-</td>}
                    {showExecutionsHint && !rest["Workers"] && (
                      <td>
                        {formatMsAdaptive(
                          (rest["I/O Read Time"] + rest["I/O Write Time"]) /
                            rest["Actual Loops"],
                        )}
                      </td>
                    )}
                  </tr>
                )}
                {showCost && (
                  <tr>
                    <td>Est. Cost:</td>
                    {showExecutionsHint && <td>-</td>}
                    <td>{formatNumber(rest["Total Cost"])}</td>
                  </tr>
                )}
                {showRows &&
                  (rest["Actual Rows"] != null ? (
                    <tr>
                      <td>Actual Rows:</td>

                      {["BitmapOr", "BitmapAnd"].includes(rest["Node Type"]) ? (
                        <>
                          {showExecutionsHint && <td>Unknown</td>}
                          <td>Unknown</td>
                        </>
                      ) : (
                        <>
                          {showExecutionsHint && (
                            <td>
                              {formatNumber(
                                rest["Actual Rows"] * rest["Actual Loops"],
                              )}
                            </td>
                          )}
                          <td>
                            {formatNumber(rest["Actual Rows"])}{" "}
                            <span className={styles.nodeSecondaryText}>
                              · est. {formatNumber(rest["Plan Rows"])}
                            </span>
                          </td>
                        </>
                      )}
                    </tr>
                  ) : (
                    <tr>
                      <td>Est. Rows:</td>
                      {showExecutionsHint && <td>-</td>}
                      <td>{formatNumber(rest["Plan Rows"])}</td>
                    </tr>
                  ))}
              </tbody>
            </PanelTable>
          </NodeSection>
        </div>
      </div>
      <NodeChildren>
        {Plans &&
          Plans.map((p: NodeType, index: number) => {
            return (
              <NodeChild key={index} lastNode={index === Plans.length - 1}>
                <Node
                  node={p}
                  databaseId={databaseId}
                  annotations={annotations}
                  onNodeClick={onNodeClick}
                  parent={rest}
                />
              </NodeChild>
            );
          })}
      </NodeChildren>
    </div>
  );
};

const NodeAnnotations: React.FunctionComponent<{
  annotations: Annotation[];
}> = ({ annotations }) => {
  if (annotations.length === 0) {
    return null;
  }
  return (
    <div className={styles.annotations}>
      {annotations.map((a, index) => {
        return <Badge key={index}>{a.summary}</Badge>;
      })}
    </div>
  );
};

const NodeSection = ({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={classNames(className, styles.nodeSection)}>{children}</div>
  );
};

const NodeChild = ({
  children,
  lastNode,
}: {
  lastNode: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div className={styles.nodeChild}>
      <div>
        <div
          className={classNames(styles.nodeChildLine, {
            [styles.nodeLastChildLine]: lastNode,
          })}
        />
        {lastNode ? null : <div className={styles.nodeChildContinuationLine} />}
      </div>
      <div>{children}</div>
    </div>
  );
};

const NodeChildren = ({ children }: { children: React.ReactNode }) => {
  const [expanded, expand] = useState(true);
  const toggleExpand = () => expand((expanded) => !expanded);

  if (!React.Children.count(children)) {
    return null;
  }

  return (
    <div className={styles.nodeChildren}>
      <div className={styles.nodeChildrenLine} />
      <span
        className={styles.nodeChildrenToggle}
        title={`${expanded ? "collapse" : "expand"} children`}
        onClick={toggleExpand}
      >
        {expanded ? "−" : "+"}
      </span>
      {expanded && children}
    </div>
  );
};

export default Node;
