import React, { useState } from "react";

import Tip from "components/Tip";

type Props = {
  systemType: string;
  server?: {
    systemType: string;
    dbName: string | null;
    dbUsername: string | null;
    dbHost: string | null;
    dbPort: number | null;
    dbSslmode: string | null;
    dbUseIamAuth: boolean | null;
    awsRegion: string | null;
    awsDbInstanceId: string | null;
    awsDbClusterId: string | null;
    awsAssumeRole: string | null;
    azureDbServerName: string | null;
    azureSubscriptionId: string | null;
    azureEventhubNamespace: string | null;
    azureEventhubName: string | null;
    azureAdClientId: string | null;
    gcpCloudsqlInstanceId: string | null;
    gcpAlloydbClusterId: string | null;
    gcpAlloydbInstanceId: string | null;
    gcpProjectId: string | null;
    gcpPubsubSubscription: string | null;
    enableLogs: boolean | null;
    enableActivity: boolean | null;
    filterLogSecret: string | null;
    filterQuerySample: string | null;
    filterQueryText: string | null;
  };
};

export const ServerLocalCollectorSettings: React.FunctionComponent<Props> = ({
  systemType,
  server,
}) => {
  const [showFilterSettings, setShowFilterSettings] = useState(false);

  return (
    <div className="row mb-[10px]">
      <input
        type="hidden"
        name="server[system_type]"
        value={server?.systemType ?? systemType}
      />
      <div className="col-sm-6">
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_name">
            Database Name
          </label>
          <input
            className="form-control"
            type="text"
            name="server[db_name]"
            id="server_db_name"
            placeholder="mydb"
            defaultValue={server?.dbName}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_username">
            Database Username
          </label>
          <input
            className="form-control"
            type="text"
            name="server[db_username]"
            id="server_db_username"
            placeholder="someuser"
            defaultValue={server?.dbUsername}
          />
        </div>
      </div>
      <div className="col-sm-12 mt-[10px] mb-[10px]">
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_password">
            Database Password
          </label>
          <div className="row">
            <div className="col-sm-6">
              <input
                className="form-control"
                type="password"
                name="server[db_password]"
                id="server_db_password"
              />
            </div>
            {server && (
              <div className="col-sm-6">
                <div className="help-block">
                  Leave empty to keep current password
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_host">
            Hostname
          </label>
          <input
            className="form-control"
            type="text"
            name="server[db_host]"
            id="server_db_host"
            placeholder="my-database.example.com"
            defaultValue={server?.dbHost}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_port">
            PostgreSQL Port
          </label>
          <input
            className="form-control"
            type="text"
            name="server[db_port]"
            id="server_db_port"
            placeholder="5432"
            defaultValue={server ? String(server.dbPort) : "5432"}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_sslmode">
            PostgreSQL SSL Mode
          </label>
          <select
            className="form-control"
            id="server_db_sslmode"
            name="server[db_sslmode]"
            defaultValue={server?.dbSslmode}
          >
            <option value="prefer">Prefer</option>
            <option value="require">Require</option>
            <option value="aws_verify_full">
              Require &amp; Verify Full (Amazon RDS &amp; Amazon Aurora)
            </option>
            <option value="disable">Disable</option>
          </select>
        </div>
        {systemType === "amazon_rds" && (
          <>
            <div className="form-group">
              <label className="control-label" htmlFor="server_aws_region">
                AWS Region (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[aws_region]"
                id="server_aws_region"
                defaultValue={server?.awsRegion}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_aws_db_instance_id"
              >
                AWS DB Instance ID (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[aws_db_instance_id]"
                id="server_aws_db_instance_id"
                defaultValue={server?.awsDbInstanceId}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_aws_db_cluster_id"
              >
                AWS DB Cluster ID (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[aws_db_cluster_id]"
                id="server_aws_db_cluster_id"
                defaultValue={server?.awsDbClusterId}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="server_aws_assume_role">
                AWS Assume Role ARN (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[aws_assume_role]"
                id="server_aws_assume_role"
                defaultValue={server?.awsAssumeRole}
              />
            </div>
            <div>
              <input type="hidden" name="server[db_use_iam_auth]" value="0" />
              <input
                type="checkbox"
                name="server[db_use_iam_auth]"
                id="server[db_use_iam_auth]"
                value="1"
                defaultChecked={server ? server.dbUseIamAuth : false}
                style={{ margin: 0 }}
              />
              <label
                htmlFor="server[db_use_iam_auth]"
                className="ml-[3px] inline"
              >
                Use IAM authentication{" "}
                <Tip
                  content={`To use this setting, leave password empty and make sure IAM authentication is enabled in AWS, the pganalyze IAM policy includes the "rds-db:connect" privilege for the pganalyze user, and the user is granted the "rds_iam" role in Postgres.`}
                />
              </label>
            </div>
          </>
        )}
        {systemType === "azure_database" && (
          <>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_azure_db_server_name"
              >
                Server name (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[azure_db_server_name]"
                id="server_azure_db_server_name"
                defaultValue={server?.azureDbServerName}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_azure_subscription_id"
              >
                Azure Subscription ID
              </label>
              <input
                className="form-control"
                type="text"
                name="server[azure_subscription_id]"
                id="server_azure_subscription_id"
                defaultValue={server?.azureSubscriptionId}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_azure_eventhub_namespace"
              >
                Event Hub namespace (for Log Insights)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[azure_eventhub_namespace]"
                id="server_azure_eventhub_namespace"
                defaultValue={server?.azureEventhubNamespace}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_azure_eventhub_name"
              >
                Event Hub name (for Log Insights)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[azure_eventhub_name]"
                id="server_azure_eventhub_name"
                defaultValue={server?.azureEventhubName}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_azure_ad_client_id"
              >
                Managed Identity Client ID (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[azure_ad_client_id]"
                id="server_azure_ad_client_id"
                defaultValue={server?.azureAdClientId}
              />
            </div>
          </>
        )}
        {systemType === "google_cloudsql" && (
          <>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_gcp_cloudsql_instance_id"
              >
                Cloud SQL instance ID
              </label>
              <input
                className="form-control"
                type="text"
                name="server[gcp_cloudsql_instance_id]"
                id="server_gcp_cloudsql_instance_id"
                defaultValue={server?.gcpCloudsqlInstanceId}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_gcp_alloydb_cluster_id"
              >
                AlloyDB cluster ID
              </label>
              <input
                className="form-control"
                type="text"
                name="server[gcp_alloydb_cluster_id]"
                id="server_gcp_alloydb_cluster_id"
                defaultValue={server?.gcpAlloydbClusterId}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_gcp_alloydb_instance_id"
              >
                AlloyDB instance ID
              </label>
              <input
                className="form-control"
                type="text"
                name="server[gcp_alloydb_instance_id]"
                id="server_gcp_alloydb_instance_id"
                defaultValue={server?.gcpAlloydbInstanceId}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="server_gcp_project_id">
                GCP Project ID
              </label>
              <input
                className="form-control"
                type="text"
                name="server[gcp_project_id]"
                id="server_gcp_project_id"
                defaultValue={server?.gcpProjectId}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_gcp_pubsub_subscription"
              >
                Pub/Sub subscription (for Log Insights)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[gcp_pubsub_subscription]"
                id="server_gcp_pubsub_subscription"
                defaultValue={server?.gcpPubsubSubscription}
              />
            </div>
          </>
        )}
      </div>
      <div className="col-sm-12 mt-2">
        {!server && (
          <div>
            <input type="hidden" name="collect_all_databases" value="0" />
            <input
              type="checkbox"
              name="collect_all_databases"
              id="collect_all_databases"
              value="1"
              defaultChecked={true}
              style={{ margin: 0 }}
            />
            <label htmlFor="collect_all_databases" className="ml-[3px] inline">
              Collect statistics from all databases on this server
            </label>
          </div>
        )}
        <div>
          <input type="hidden" name="server[enable_activity]" value="0" />
          <input
            type="checkbox"
            name="server[enable_activity]"
            id="server[enable_activity]"
            value="1"
            defaultChecked={server ? server.enableActivity : true}
            style={{ margin: 0 }}
          />
          <label htmlFor="server[enable_activity]" className="ml-[3px] inline">
            Enable Activity Sampling
          </label>
        </div>
        <div>
          <input type="hidden" name="server[enable_logs]" value="0" />
          <input
            type="checkbox"
            name="server[enable_logs]"
            id="server[enable_logs]"
            value="1"
            defaultChecked={server ? server.enableLogs : false}
            style={{ margin: 0 }}
          />
          <label htmlFor="server[enable_logs]" className="ml-[3px] inline">
            Enable Log Insights
          </label>
          {systemType === "self_managed" && (
            <div className="pl-[17px]">
              Self-managed servers need to{" "}
              <a
                href="https://pganalyze.com/docs/install/troubleshooting/pg_read_file_helper"
                target="_blank"
              >
                create a helper function in the monitored database
              </a>{" "}
              for log access with the integrated collector
            </div>
          )}
          {systemType == "amazon_rds" && (
            <div className="pl-[17px]">
              Requires setup of{" "}
              <a
                href="https://pganalyze.com/docs/install/amazon_rds/03_install_collector_ec2"
                target="_blank"
              >
                IAM Policy
              </a>{" "}
              to allow log download
            </div>
          )}
          {systemType == "azure_database" && (
            <div className="pl-[17px]">
              Requires setup of{" "}
              <a
                href="https://pganalyze.com/docs/log-insights/setup/azure-database/01_set_up_managed_identity"
                target="_blank"
              >
                Azure Event Hub
              </a>{" "}
              for forwarding logs from the database instance
            </div>
          )}
          {systemType == "google_cloudsql" && (
            <div className="pl-[17px]">
              Requires setup of{" "}
              <a
                href="https://pganalyze.com/docs/log-insights/setup/google-cloud-sql/01_create_topic_and_subscriber"
                target="_blank"
              >
                Cloud Pub/Sub
              </a>{" "}
              for forwarding logs from the database instance
            </div>
          )}
        </div>
        {showFilterSettings ? (
          <>
            <div className="font-bold text-[#555] mt-[5px]">
              PII Filtering Settings (
              <a
                href="https://pganalyze.com/docs/collector/settings#pii-filtering-settings"
                target="_blank"
              >
                learn more
              </a>
              )
            </div>
            <div className="col-sm-6 mb-2">
              <div className="form-group">
                <fieldset>
                  <label className="control-label">Filter Log Secret</label>
                  <div>
                    <input
                      type="checkbox"
                      id="server_filter_log_secret__credential"
                      name="server[filter_log_secret][]"
                      value="credential"
                      defaultChecked={
                        server?.filterLogSecret?.includes("credential") ?? true
                      }
                    />
                    <label
                      className="ml-[3px] inline"
                      htmlFor="server_filter_log_secret__credential"
                    >
                      Credential
                    </label>
                  </div>
                  <div>
                    {" "}
                    <input
                      type="checkbox"
                      id="server_filter_log_secret__parsing_error"
                      name="server[filter_log_secret][]"
                      value="parsing_error"
                      defaultChecked={
                        server?.filterLogSecret?.includes("parsing_error") ??
                        true
                      }
                    />
                    <label
                      className="ml-[3px] inline"
                      htmlFor="server_filter_log_secret__parsing_error"
                    >
                      Parsing Error
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="server_filter_log_secret__statement_text"
                      name="server[filter_log_secret][]"
                      value="statement_text"
                      defaultChecked={server?.filterLogSecret?.includes(
                        "statement_text",
                      )}
                    />
                    <label
                      className="ml-[3px] inline"
                      htmlFor="server_filter_log_secret__statement_text"
                    >
                      Statement Text
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="server_filter_log_secret__statement_parameter"
                      name="server[filter_log_secret][]"
                      value="statement_parameter"
                      defaultChecked={server?.filterLogSecret?.includes(
                        "statement_parameter",
                      )}
                    />
                    <label
                      className="ml-[3px] inline"
                      htmlFor="server_filter_log_secret__statement_parameter"
                    >
                      Statement Parameter
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="server_filter_log_secret__table_data"
                      name="server[filter_log_secret][]"
                      value="table_data"
                      defaultChecked={server?.filterLogSecret?.includes(
                        "table_data",
                      )}
                    />
                    <label
                      className="ml-[3px] inline"
                      htmlFor="server_filter_log_secret__table_data"
                    >
                      Table Data
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="server_filter_log_secret__ops"
                      name="server[filter_log_secret][]"
                      value="ops"
                      defaultChecked={server?.filterLogSecret?.includes("ops")}
                    />
                    <label
                      className="ml-[3px] inline"
                      htmlFor="server_filter_log_secret__ops"
                    >
                      Ops
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="server_filter_log_secret__unidentified"
                      name="server[filter_log_secret][]"
                      value="unidentified"
                      defaultChecked={
                        server?.filterLogSecret?.includes("unidentified") ??
                        true
                      }
                    />
                    <label
                      className="ml-[3px] inline"
                      htmlFor="server_filter_log_secret__unidentified"
                    >
                      Unidentified
                    </label>
                  </div>
                </fieldset>
              </div>
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="server_filter_query_sample"
                >
                  Filter Query Samples
                </label>
                <select
                  className="form-control"
                  id="server_filter_query_sample"
                  name="server[filter_query_sample]"
                  defaultValue={server?.filterQuerySample ?? "none"}
                >
                  <option value="none">none</option>
                  <option value="normalize">normalize</option>
                  <option value="all">all</option>
                </select>
              </div>
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="server_filter_query_text"
                >
                  Filter Query Text
                </label>
                <select
                  className="form-control"
                  id="server_filter_query_text"
                  name="server[filter_query_text]"
                  defaultValue={server?.filterQueryText ?? "unparsable"}
                >
                  <option value="none">none</option>
                  <option value="unparsable">unparsable</option>
                </select>
              </div>
            </div>
          </>
        ) : (
          <div className="mt-[5px]">
            <a
              href=""
              onClick={(evt) => {
                evt.preventDefault();
                setShowFilterSettings(true);
              }}
              className="block"
            >
              Show PII Filtering Settings
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServerLocalCollectorSettings;
