import React from "react";
import { useCycle } from "utils/hooks";

const LogInsightsTeaser: React.FunctionComponent = () => {
  const lines = [
    {
      text: "LOG:  connection authorized: user=myuser database=mydb SSL enabled (protocol=TLSv1.2, cipher=ECDHE-RSA-AES256-GCM-SHA384, compression=off)",
      timestamp: "2017-11-17 16:42:23 UTC",
      classification: "C21",
    },
    {
      text: `ERROR:  null value in column "reference_id" violates not-null constraint`,
      timestamp: "2017-11-17 16:41:21 UTC",
      classification: "V102",
    },
    {
      text: "DETAIL:  Failing row contains (null, secretvalue).",
      textFiltered: "DETAIL:  Failing row contains (XXXXXXXXXXXXXXXXX).",
      timestamp: "2017-11-17 16:41:21 UTC",
      classification: "V102",
    },
    {
      text: "STATEMENT: INSERT INTO secrets (reference_id, secret) VALUES (null, 'secretvalue')",
      textFiltered:
        "STATEMENT: XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      timestamp: "2017-11-17 16:41:21 UTC",
      classification: "V102",
    },
    {
      text: "LOG:  automatic vacuum of table “mydb.public.mytable”: index scans: 1",
      timestamp: "2017-11-17 16:00:41 UTC",
      classification: "A65",
    },
    {
      text: "    pages: 0 removed, 15092 remain, 0 skipped due to pins, 10999 skipped frozen",
    },
    {
      text: "    tuples: 17675 removed, 300160 remain, 0 are dead but not yet removable, oldest xmin: 1033269669",
    },
    {
      text: "    buffer usage: 58297 hits, 337478 misses, 10646 dirtied",
    },
    {
      text: "    avg read rate: 3.938 MB/s, avg write rate: 0.124 MB/s",
    },
    {
      text: "    system usage: CPU: user: 1.22 s, system: 2.45 s, elapsed: 669.58 s",
    },
    {
      text: "LOG:  duration: 2334.085 ms  plan:",
      timestamp: "2017-11-17 16:00:03 UTC",
      classification: "T84",
    },
    {
      text: "    {",
    },
    {
      text: `          "Query Text": "SELECT reference_id FROM table WHERE secret = 'verysecret';",`,
      textFiltered: `          Query Text": "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",`,
    },
    {
      text: `          "Plan": {`,
    },
    {
      text: `            "Node Type": "Index Scan",`,
    },
  ];
  const step = useCycle(lines.length, 400);

  return (
    <div
      style={{
        padding: 10,
        background: "#262626",
        color: "white",
        fontFamily: "Monaco, monospace",
        fontSize: "12px",
        overflow: "hidden",
        transform: "scale(0.7) translate(-140px, -50px)",
        marginBottom: "-70px",
      }}
    >
      {lines.map((line, idx) => (
        <LogLine
          {...line}
          processed={idx < step}
          highlight={idx === step}
          key={idx}
        />
      ))}
    </div>
  );
};

type Props = {
  text: string;
  textFiltered?: string;
  timestamp?: string;
  classification?: string;
  processed: boolean;
  highlight: boolean;
};

const LogLine: React.FunctionComponent<Props> = ({
  text,
  textFiltered,
  timestamp,
  classification,
  processed,
  highlight,
}) => (
  <pre
    style={{
      margin: 0,
      padding: 0,
      border: "none",
      lineHeight: "16px",
      background: "transparent",
      color: "inherit",
      position: "relative",
      whiteSpace: "pre",
    }}
  >
    {highlight && (
      <div
        style={{
          position: "absolute",
          left: "-10px",
          right: "-10px",
          top: 0,
          bottom: 0,
          height: "16px",
          opacity: 0.5,
          background: "white",
        }}
      ></div>
    )}
    {timestamp && timestamp + " "}
    {classification && (
      <span
        style={{
          visibility: (processed && "visible") || "hidden",
          color: "#ba94da",
        }}
      >
        {classification}{" "}
      </span>
    )}
    {(processed && textFiltered) || text}
  </pre>
);

export default LogInsightsTeaser;
