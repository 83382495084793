import React from "react";
import { useQuery } from "@apollo/client";
import { useParams, Link } from "react-router-dom";
import groupBy from "lodash/groupBy";
import moment from "moment-timezone";

import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import {
  SnapshotList as SnapshotListType,
  SnapshotListVariables,
  SnapshotList_getSnapshots,
} from "./types/SnapshotList";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

const SnapshotList: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const { serverSnapshot } = useRoutes();
  const { data, loading, error } = useQuery<
    SnapshotListType,
    SnapshotListVariables
  >(QUERY, {
    variables: {
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const snapshots = groupBy(
    data.getSnapshots,
    (s: SnapshotList_getSnapshots): string => s.submitter,
  );

  return (
    <PageContent
      title="Last 10 Full Statistics Snapshots"
      pageCategory="snapshots"
      pageName="index"
    >
      <Panel title="Snapshot List">
        <PanelSection>
          {Object.entries(snapshots).map(
            ([submitter, submitterSnapshots]): React.ReactNode => (
              <div key={submitter}>
                <h4>{submitter}</h4>
                <ul>
                  {submitterSnapshots.map(
                    (snapshot: SnapshotList_getSnapshots): React.ReactNode => (
                      <li key={snapshot.id} className={styles.snapshot}>
                        {!snapshot.hasError && (
                          <Link to={serverSnapshot(serverId, snapshot.id)}>
                            {moment
                              .unix(snapshot.collectedAt)
                              .format("MMM DD hh:mm:ssa zz")}
                          </Link>
                        )}
                        {snapshot.hasError && (
                          <span>
                            <strong>
                              <Link to={serverSnapshot(serverId, snapshot.id)}>
                                {moment
                                  .unix(snapshot.collectedAt)
                                  .format("MMM DD hh:mm:ssa zz")}
                              </Link>
                            </strong>
                            <br />
                            Snapshot #{snapshot.id} could not be processed.
                          </span>
                        )}
                      </li>
                    ),
                  )}
                </ul>
              </div>
            ),
          )}
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

export default SnapshotList;
