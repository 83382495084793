import React from "react";

import { Node } from "types/explain";

import styles from "./style.module.scss";
import { useSelectedNode } from "../../Explain/WithNodeSelection";

const CTEInfo: React.FunctionComponent<{
  node: Node;
}> = ({ node }) => {
  const isCTE = node["CTE Name"] != null;
  const isCTESubplan = (node["Subplan Name"] as string)?.startsWith("CTE ");
  const [, setSelectedNode] = useSelectedNode();
  const onClick = () => setSelectedNode(node["CTE Name"]);

  if (!isCTE && !isCTESubplan) {
    return null;
  }

  const cteName = node["CTE Name"];
  const cteSubplanName = String(node["Subplan Name"]);
  return (
    <div className={styles.cteInfo}>
      {isCTESubplan && <span title={cteSubplanName}>{cteSubplanName}</span>}
      {isCTE && (
        <a title={cteName} onClick={onClick}>
          {cteName}
        </a>
      )}
    </div>
  );
};

export default CTEInfo;
