import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePrevious } from "utils/hooks";

export type FlashLevel = "success" | "notice" | "alert" | "error";

type FlashOpts = {
  persist?: boolean;
} & (
  | {
      dismissable: true;
      id: string;
    }
  | {
      dismissable?: false;
      id?: string;
    }
);

type FlashBase = {
  level: FlashLevel;
};

type ServerFlash = FlashBase & {
  escapedHtmlMsg: string;
};

type ClientFlash = FlashBase & {
  msg: React.ReactNode;
};

export type Flash = (ServerFlash | ClientFlash) & FlashOpts;

type SetFlash = (
  level: FlashLevel,
  msg: React.ReactNode,
  opts?: FlashOpts,
) => void;
type NewFlashes = Flash[] | ((existing: Flash[]) => Flash[]);
type SetFlashes = (newFlashes: NewFlashes) => void;
type SetNextFlashLocation = (nextFlashLocation: string) => void;

const FlashesContext = React.createContext<Flash[]>([]);
const SetFlashesContext = React.createContext<SetFlashes | undefined>(
  undefined,
);
const SetNextFlashLocation = React.createContext<
  SetNextFlashLocation | undefined
>(undefined);

const WithFlashes = ({
  initialFlashes,
  children,
}: {
  initialFlashes: Flash[];
  children: React.ReactNode;
}) => {
  const [flashes, setFlashes] = useState<Flash[]>(initialFlashes);
  const [nextFlashLocation, setNextFlashLocation] = useState<string>(null);

  // Flashes flagged to persist are never removed unless the caller
  // uses the callback form of the setter and explicitly removes them
  const doSetFlashes = useCallback(
    (newFlashes: NewFlashes): void => {
      if (typeof newFlashes === "function") {
        setFlashes(newFlashes);
      } else {
        setFlashes((currFlashes: Flash[]) => {
          return currFlashes.filter((f) => f.persist).concat(newFlashes);
        });
      }
    },
    [setFlashes],
  );

  // For now, always clear flashes when navigating away
  const location = useLocation();
  const lastLocation = usePrevious(location);
  useEffect(() => {
    if (nextFlashLocation == location.pathname) {
      setNextFlashLocation(undefined);
    } else if (!!lastLocation && location !== lastLocation) {
      doSetFlashes([]);
    }
  }, [location, lastLocation, nextFlashLocation, doSetFlashes]);

  return (
    <SetFlashesContext.Provider value={doSetFlashes}>
      <FlashesContext.Provider value={flashes}>
        <SetNextFlashLocation.Provider value={setNextFlashLocation}>
          {children}
        </SetNextFlashLocation.Provider>
      </FlashesContext.Provider>
    </SetFlashesContext.Provider>
  );
};

export function useFlashes(): Flash[] {
  return useContext(FlashesContext);
}

export function useSetFlashes(): SetFlashes {
  const setter = useContext(SetFlashesContext);
  if (!setter) {
    throw new Error("must have ancestor WithFlashes component");
  }
  return setter;
}

export function useSetFlash(): SetFlash {
  const setFlashes = useContext(SetFlashesContext);
  return useCallback<SetFlash>(
    (level, msg, opts) => {
      if (!setFlashes) {
        throw new Error("must have ancestor WithFlashes component");
      }

      setFlashes([{ level, msg, ...opts }]);
    },
    [setFlashes],
  );
}

export function useClearFlashes(): () => void {
  const setFlashes = useContext(SetFlashesContext);
  return useCallback(() => {
    if (!setFlashes) {
      throw new Error("must have ancestor WithFlashes component");
    }

    setFlashes([]);
  }, [setFlashes]);
}

type AsyncActionFlashArgs = {
  called: boolean;
  loading: boolean;
  error?: React.ReactNode;
  success?: React.ReactNode;
  successRedirect?: string;
};

export function useAsyncActionFlash({
  called,
  loading,
  error,
  success,
  successRedirect,
}: AsyncActionFlashArgs): void {
  const setFlash = useSetFlash();
  const clearFlashes = useClearFlashes();
  const setNextFlashLocation = useContext(SetNextFlashLocation);
  const navigate = useNavigate();
  useEffect(() => {
    if (!called) {
      return;
    }
    if (loading) {
      clearFlashes();
      return;
    }
    if (error) {
      setFlash("error", error);
      return;
    }
    if (success) {
      setFlash("success", success);
    }
    if (successRedirect) {
      setNextFlashLocation(successRedirect);
      navigate(successRedirect);
    }
  }, [
    setFlash,
    clearFlashes,
    setNextFlashLocation,
    navigate,
    called,
    loading,
    error,
    success,
    successRedirect,
  ]);
}

export default WithFlashes;
