import React from "react";

import { Link, useLocation } from "react-router-dom";
import { ScanType, getLocator } from "../util";
import classNames from "classnames";
import { ScanIcon } from "../Icons";

const ScanReference: React.FunctionComponent<{
  scan: ScanType;
  className?: string;
  children?: React.ReactNode;
}> = ({ className, scan: scan, children }) => {
  const loc = useLocation();
  const { label, combinedExpression } = scan;
  const fullDescription = `Table scan with filters ${combinedExpression}`;
  const urlId = getLocator(scan);
  const path = loc.pathname + loc.search + `#${urlId}`;

  return (
    <Link
      to={path}
      title={fullDescription}
      className={classNames("inline-flex items-start gap-1", className)}
    >
      <ScanIcon label={label} />
      {children ?? (
        <span className="font-mono overflow-hidden text-ellipsis">
          {combinedExpression}
        </span>
      )}
    </Link>
  );
};

export default ScanReference;
