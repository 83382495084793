import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import SettingsNav from "components/SettingsNav";
import { useRoutes } from "utils/routes";

import DebugInfo from "./DebugInfo";
import EditForm from "./EditForm";
import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

import {
  ServerSettings as ServerSettingsType,
  ServerSettingsVariables,
  ServerSettings_getDatabases as FullDatabaseType,
} from "./types/ServerSettings";
import Loading from "components/Loading";
import TestLocalCollector from "./TestLocalCollector";

const ServerSettings: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const { data, loading, error } = useQuery<
    ServerSettingsType,
    ServerSettingsVariables
  >(QUERY, {
    variables: { serverId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const server = data.getServerDetails;
  const {
    permittedToEdit,
    permittedToRemove,
    usesLocalCollector,
    humanId,
    name,
  } = server;
  const databases = data.getDatabases;
  const organizationPlanName =
    server.organization.planInfo.subscriptionPlanName;

  const hiddenDatabases = data.hiddenDatabases;

  return (
    <PageContent
      title="Settings"
      pageCategory="servers"
      pageName="edit"
      featureNav={<SettingsNav />}
    >
      <Panel title={`Server ${name}`}>
        <PanelTable className={styles.databaseList}>
          <thead>
            <tr>
              <th>Database</th>
              <th># Queries</th>
              <th># Tables</th>
              <th>Connection Limit</th>
              <th>Owner</th>
              <th>Encoding</th>
              <th>Collation</th>
            </tr>
          </thead>
          <tbody>
            {databases.map((d) => (
              <Database key={d.id} database={d} serverId={server.humanId} />
            ))}
          </tbody>
        </PanelTable>
      </Panel>
      <EditForm
        organizationPlanName={organizationPlanName}
        permittedToEditServer={permittedToEdit}
        server={server}
      />
      {usesLocalCollector && <TestLocalCollector serverId={serverId} />}
      <DebugInfo hiddenDatabases={hiddenDatabases} server={server} />
      {permittedToRemove && (
        <Panel title="Remove Server">
          <PanelSection>
            <a
              data-confirm="Do you really want to delete all statistics for this server?"
              rel="nofollow"
              data-method="delete"
              href={`/servers/${humanId}`}
            >
              Remove server and associated databases from pganalyze
            </a>
          </PanelSection>
        </Panel>
      )}
    </PageContent>
  );
};

const Database: React.FunctionComponent<{
  database: FullDatabaseType;
  serverId: string;
}> = ({ database, serverId }) => {
  const {
    id,
    datname,
    queryCount,
    tableCount,
    connectionLimit,
    ownerPostgresRole,
    encoding,
    collate,
  } = database;
  const { database: databaseRoute, serverRole } = useRoutes();

  return (
    <tr key={id}>
      <td>
        <Link to={databaseRoute(id)}>{datname || "Database #" + id}</Link>
      </td>
      <td>{(queryCount > 1000 && "1000+") || queryCount}</td>
      <td>{(tableCount > 100 && "100+") || tableCount}</td>
      <td>{connectionLimit || "n/a"}</td>
      <td>
        {ownerPostgresRole && (
          <Link to={serverRole(serverId, ownerPostgresRole.id)}>
            {ownerPostgresRole.name}
          </Link>
        )}
      </td>
      <td>{encoding}</td>
      <td>{collate}</td>
    </tr>
  );
};

export default ServerSettings;
