import React from "react";

import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { SortDirection } from "types/graphql-global-types";

import { formatBytes } from "utils/format";
import Loading from "components/Loading";

import {
  SchemaViewList,
  SchemaViewListVariables,
  SchemaViewList_getSchemaViews,
  SchemaViewList_getSchemaViews_issues,
} from "./types/SchemaViewList";
import { useRoutes } from "utils/routes";
import QUERY from "./Query.graphql";
import LazyLoader from "components/LazyLoader";
import Grid from "components/Grid";
import { SortOrder } from "components/Grid/util";
import { toSortDir, toSortOrder } from "utils/graphql";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  searchTerm: string | null;
  databaseId: string;
}

const FETCH_BATCH_SIZE = 50;

const Table: React.FunctionComponent<Props> = ({ searchTerm, databaseId }) => {
  const [sortOpts, setSortOpts] = React.useState<SortOptsType>({
    sortBy: "viewName",
    sortDirection: SortDirection.ASC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaViewList,
    SchemaViewListVariables
  >(QUERY, {
    variables: {
      offset: 0,
      limit: FETCH_BATCH_SIZE,
      filter: searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const schemaViews = data.getSchemaViews;

  const fetchMoreData = (loadedCount: number) => {
    return new Promise<boolean>((resolve) => {
      const offset = loadedCount;
      fetchMore({
        variables: { offset, limit: FETCH_BATCH_SIZE },
        updateQuery: (prev, { fetchMoreResult }): SchemaViewList => {
          const fetched = fetchMoreResult.getSchemaViews;
          resolve(fetched.length > 0);
          if (!prev || !prev.getSchemaViews) {
            return fetchMoreResult;
          }
          return {
            ...prev,
            getSchemaViews: [...prev.getSchemaViews, ...fetched],
          };
        },
      });
    });
  };

  const sortedOrder = toSortOrder(sortOpts.sortDirection);
  const sortedBy = sortOpts.sortBy as keyof SchemaViewList_getSchemaViews;

  function handleSort(
    sortBy: keyof SchemaViewList_getSchemaViews,
    sortOrder: SortOrder,
  ) {
    setSortOpts({
      sortBy,
      sortDirection: toSortDir(sortOrder),
    });
  }

  return (
    <LazyLoader loadedCount={schemaViews.length} loadMore={fetchMoreData}>
      <Grid
        className="grid-cols-[120px_200px_1fr_100px_120px]"
        striped
        data={schemaViews}
        columns={[
          {
            field: "schemaName",
            header: "Schema",
          },
          {
            field: "viewName",
            header: "view Name",
            renderer: function ViewNameCell({ rowData }) {
              return (
                <NameAndIssuesCell
                  databaseId={databaseId}
                  schemaView={rowData}
                />
              );
            },
          },
          {
            field: "viewDefinition",
            header: "Definition",
            style: "query",
            renderer: function ViewDefinitionCell({ fieldData }) {
              return fieldData.trim();
            },
          },
          {
            field: "materializedView",
            header: "Materialized",
            defaultSortOrder: "desc",
            renderer: function IsMaterializedViewCell({ fieldData }) {
              return fieldData ? "Yes" : "No";
            },
          },
          {
            field: "sizeBytes",
            header: "Data Size",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: function DataSizeCell({ fieldData }) {
              return formatBytes(fieldData);
            },
          },
        ]}
        sortedBy={sortedBy}
        sortedOrder={sortedOrder}
        handleSort={handleSort}
      />
    </LazyLoader>
  );
};

const NameAndIssuesCell: React.FunctionComponent<{
  databaseId: string;
  schemaView: SchemaViewList_getSchemaViews;
}> = ({ databaseId, schemaView }) => {
  const { databaseView } = useRoutes();
  return (
    <span>
      <Link to={databaseView(databaseId, schemaView.id)}>
        {schemaView.viewName}
      </Link>
      {schemaView.issues.map(
        (i: SchemaViewList_getSchemaViews_issues): React.ReactNode => (
          <Link
            to={databaseView(databaseId, schemaView.id)}
            className={`state-${i.severity}`}
            title={`${i.displayName || ""} ${i.description}`}
            key={i.id}
          >
            <i className="icon-exclamation-sign" />
          </Link>
        ),
      )}
    </span>
  );
};

export default Table;
