import React from "react";

import moment from "moment-timezone";

import { formatTimestampShort } from "utils/format";

import Avatar from "components/Avatar";
import CheckStatusBadge from "components/CheckStatusBadge";
import {
  IconAcknowledged,
  IconDetected,
  IconDowngraded,
  IconEscalated,
  IconNotified,
  IconOpen,
  IconResolved,
} from "../Icons";

import {
  IssueDetail_getIssue_activity as IssueActivity,
  IssueDetail_getIssue_activity_user as UserType,
} from "../types/IssueDetail";

import styles from "./style.module.scss";
import { ActivityKind } from "types/graphql-global-types";

const Activity: React.FunctionComponent<{
  activity: IssueActivity[];
}> = ({ activity }) => {
  const activityItems = activity
    .map((event, idx) => {
      let description: React.ReactNode;
      let Icon: React.ComponentType;

      if (event.kind === ActivityKind.notified) {
        Icon = IconNotified;
        description = event.description;
      } else {
        const descriptionParts: React.ReactNode[] = [];
        const ActivityBadge = () => (
          <CheckStatusBadge
            className={styles.issueActivityBadge}
            status={event.severity}
          />
        );

        if (event.kind === ActivityKind.triggered) {
          Icon = IconDetected;
          descriptionParts.push("detected as", <ActivityBadge />);
        } else if (event.kind === ActivityKind.acknowledged) {
          Icon = IconAcknowledged;
          descriptionParts.push("acknowledged");
          if (event.user) {
            descriptionParts.push(" by ", <ActivityUser user={event.user} />);
          }
        } else if (event.kind === ActivityKind.resolved) {
          Icon = IconResolved;
          descriptionParts.push("resolved");
        } else if (event.kind === ActivityKind.reopened) {
          Icon = IconOpen;
          descriptionParts.push("re-opened");
          if (event.user) {
            descriptionParts.push(" by ", <ActivityUser user={event.user} />);
          }
        } else if (event.kind === ActivityKind.escalated) {
          Icon = IconEscalated;
          descriptionParts.push("escalated to", <ActivityBadge />);
        } else if (event.kind === ActivityKind.downgraded) {
          Icon = IconDowngraded;
          descriptionParts.push("downgraded to", <ActivityBadge />);
        } else {
          // hide unknown activity
          // TODO: show notify_failed
          return null;
        }

        // add keys to keep react linting happy
        description = descriptionParts.map((part, i) => (
          <React.Fragment key={i}>{part}</React.Fragment>
        ));
      }

      return (
        <div key={idx}>
          <Icon />{" "}
          <span className={styles.issueActivityTimestamp}>
            {formatTimestampShort(moment.unix(event.occurredAt))}
          </span>
          : {description}
        </div>
      );
    })
    .filter((activityItem) => activityItem != null)
    .reverse();

  return <div className={styles.issueActivity}>{activityItems}</div>;
};

const ActivityUser: React.FunctionComponent<{ user: UserType | null }> = ({
  user,
}) => {
  return <Avatar name={user.fullname} avatarUrl={user.avatarUrl} />;
};

export default Activity;
