import React, { useState } from "react";
import { Link } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import flowRight from "lodash/flowRight";

import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import withLoading from "decorators/withLoading";

import QUERY from "./Query.graphql";

import { PostgresRoleList as PostgresRoleListType } from "./types/PostgresRoleList";
import { useRoutes } from "utils/routes";
import Grid from "components/Grid";
import { makeFilter } from "utils/filter";

type Props = {
  data: PostgresRoleListType;
  serverId: string;
};

const PostgresRoleList: React.FunctionComponent<Props> = ({
  data,
  serverId,
}) => {
  const { serverRole } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");

  const postgresRoles = data.getPostgresRoles.filter(
    makeFilter(searchTerm, "name"),
  );

  const titleSearch = (
    <PanelTitleSearch value={searchTerm} onChange={setSearchTerm} />
  );

  return (
    <Panel title="All Roles" secondaryTitle={titleSearch}>
      <Grid
        className="grid-cols-[1fr_100px_100px]"
        striped
        data={postgresRoles}
        columns={[
          {
            field: "name",
            header: "Name",
            renderer: function NameCell({ rowData }) {
              return (
                <Link to={serverRole(serverId, rowData.id)}>
                  {rowData.name}
                </Link>
              );
            },
          },
          {
            field: "login",
            header: "Login",
            defaultSortOrder: "desc",
            nullValue: "?",
            renderer: YesNoCell,
          },
          {
            field: "superUser",
            header: "Super User",
            defaultSortOrder: "desc",
            nullValue: "?",
            renderer: YesNoCell,
          },
        ]}
        defaultSortBy="name"
      />
    </Panel>
  );
};

function YesNoCell({ fieldData }: { fieldData: boolean }) {
  return fieldData ? "Yes" : "No";
}

export default flowRight(graphql(QUERY), withLoading)(PostgresRoleList);
