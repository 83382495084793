import React from "react";

const InsightDescription = ({
  children,
  docsPath,
}: {
  docsPath: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="mx-[10px] my-2">
      {children}{" "}
      <a target="_blank" href={"https://pganalyze.com" + docsPath}>
        Learn more
      </a>
    </div>
  );
};

export default InsightDescription;
