import React from "react";

import classNames from "classnames";

import styles from "./style.module.scss";

type Props = {
  label: string;
  className?: string;
  children: React.ReactNode;
};

const PanelItem = ({ label, children, className }: Props) => {
  return (
    <div className={classNames(styles.panelItem, className)}>
      <span className={styles.panelItemLabel}>{label}</span>
      <div>{children}</div>
    </div>
  );
};

export default PanelItem;
