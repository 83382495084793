import React from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { scaleTime } from "d3-scale";

import styles from "./style.module.scss";
import DateRangeGraph from "components/Graph/DateRangeGraph";
import { Datum, valueNonZero, yValue } from "components/Graph/util";
import {
  AreaSeries,
  LineSeries,
  ThresholdAreaSeries,
  ThresholdSeries,
} from "components/Graph/Series";
import { useRoutes } from "utils/routes";

import { phaseLabel } from "components/VacuumDetailsGraph/util";
import { useDateRange } from "components/WithDateRange";
import moment from "moment-timezone";

import {
  VacuumTableStats_getSchemaTableVacuumInfo_vacuumRuns as VacuumRunType,
  VacuumTableStats_getSchemaTableVacuumInfo_vacuumRuns_phases as VacuumPhaseType,
  VacuumTableStats_getSchemaTableStats as SchemaTableStatsType,
} from "../VacuumTableStats/types/VacuumTableStats";

type Props = {
  serverId: string;
  vacuumRuns: Array<VacuumRunType>;
  tableStats: SchemaTableStatsType;
  autovacuumVacuumThreshold: number;
  autovacuumVacuumScaleFactor: number;
  autovacuumFreezeMaxAge: number;
  autovacuumMultixactFreezeMaxAge: number;
  autovacuumVacuumInsertThreshold: number | null;
  autovacuumVacuumInsertScaleFactor: number | null;
  showToast: boolean;
};

const VacuumGraph: React.FunctionComponent<Props> = ({
  serverId,
  tableStats,
  autovacuumVacuumThreshold,
  autovacuumVacuumScaleFactor,
  autovacuumFreezeMaxAge,
  autovacuumMultixactFreezeMaxAge,
  autovacuumVacuumInsertThreshold,
  autovacuumVacuumInsertScaleFactor,
  vacuumRuns,
  showToast,
}) => {
  vacuumRuns = vacuumRuns.filter((run) => showToast || !run.toast);

  return (
    <div>
      <DeadRowsGraph
        stats={tableStats}
        autovacuumVacuumThreshold={autovacuumVacuumThreshold}
        autovacuumVacuumScaleFactor={autovacuumVacuumScaleFactor}
      />
      <XactAgeGraph
        frozenxidAge={(tableStats?.frozenxidAge ?? []) as Datum[]}
        autovacuumFreezeMaxAge={autovacuumFreezeMaxAge}
      />
      <MultiXactAgeGraph
        minmxidAge={(tableStats?.minmxidAge ?? []) as Datum[]}
        autovacuumMultixactFreezeMaxAge={autovacuumMultixactFreezeMaxAge}
      />
      <InsertsGraph
        stats={tableStats}
        autovacuumVacuumInsertThreshold={autovacuumVacuumInsertThreshold}
        autovacuumVacuumInsertScaleFactor={autovacuumVacuumInsertScaleFactor}
      />
      <VacuumPhases serverId={serverId} vacuumRuns={vacuumRuns} />
    </div>
  );
};

const VacuumPhases: React.FunctionComponent<{
  serverId: string;
  vacuumRuns: Array<VacuumRunType>;
}> = ({ serverId, vacuumRuns }) => {
  const [{ from, to }] = useDateRange();
  const { serverVacuum } = useRoutes();

  const phaseClass = (d: VacuumPhaseType): string => {
    switch (d.phase) {
      case 1: // SCAN_HEAP
        return styles.scanHeapPhase;
      case 2: // VACUUM_INDEX
        return styles.vacuumIndexPhase;
      case 3: // VACUUM_HEAP
        return styles.vacuumHeapPhase;
      case 4: // INDEX_CLEANUP
        return styles.vacuumIndexCleanupPhase;
      default:
        return styles.defaultPhase;
    }
  };

  const scale = scaleTime().domain([from, to]).range([0, 100]).clamp(true);

  return (
    <div className={styles.vacuumPhaseBar}>
      <div>
        {vacuumRuns.map((vacuumRun) => {
          const vacuumStart = moment(vacuumRun.vacuumStart * 1000).isAfter(from)
            ? scale(vacuumRun.vacuumStart * 1000)
            : null;
          const vacuumEnd =
            vacuumRun.vacuumEnd &&
            moment(vacuumRun.vacuumEnd * 1000).isBefore(to)
              ? scale(vacuumRun.vacuumEnd * 1000)
              : null;
          return (
            <React.Fragment key={vacuumRun.id}>
              {vacuumRun.phases.map((phase) => {
                const phaseStart = scale(phase.startDate * 1000);
                const phaseEnd = scale(phase.endDate * 1000);
                const phaseWidth = Math.max(1, phaseEnd - phaseStart);
                const label = phaseLabel(phase.phase);
                return (
                  <Link
                    to={serverVacuum(serverId, vacuumRun.identity)}
                    key={phase.startDate}
                  >
                    <div
                      className={classNames(styles.phase, phaseClass(phase))}
                      style={{
                        left: `${phaseStart}%`,
                        width: `${phaseWidth}%`,
                      }}
                      title={label}
                    >
                      {label}
                    </div>
                  </Link>
                );
              })}
              {vacuumStart && (
                <div
                  className={styles.vacuumStart}
                  style={{ left: `${vacuumStart}%` }}
                />
              )}
              {vacuumEnd && (
                <div
                  className={styles.vacuumEnd}
                  style={{ left: `${vacuumEnd}%` }}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export const DeadRowsGraph: React.FunctionComponent<{
  stats: SchemaTableStatsType;
  autovacuumVacuumThreshold: number;
  autovacuumVacuumScaleFactor: number;
  altAutovacuumVacuumThreshold?: number;
  altAutovacuumVacuumScaleFactor?: number;
}> = ({
  stats,
  autovacuumVacuumThreshold,
  autovacuumVacuumScaleFactor,
  altAutovacuumVacuumThreshold,
  altAutovacuumVacuumScaleFactor,
}) => {
  const calcVacuumThreshold = (liveTuples: number): number =>
    autovacuumVacuumThreshold + autovacuumVacuumScaleFactor * liveTuples;

  const calcAltVacuumThreshold = (liveTuples: number): number =>
    altAutovacuumVacuumThreshold + altAutovacuumVacuumScaleFactor * liveTuples;
  const showAltThreshold =
    altAutovacuumVacuumThreshold && altAutovacuumVacuumScaleFactor;

  const liveTuples = stats?.liveTuples ?? [];
  const deadTuples = stats?.deadTuples ?? [];

  const deadTuplesUnder: Datum[] = [];
  const deadTuplesOver: Datum[] = [];
  const vacuumThreshold: Datum[] = [];
  const altVacuumThreshold: Datum[] = [];

  deadTuples.forEach((val, i) => {
    const collectedAt = val[0];
    const deadTuple = val[1];
    const liveTuple = liveTuples[i]?.[1];
    if (deadTuple == null || liveTuple == null) {
      deadTuplesUnder.push([collectedAt, null]);
      deadTuplesOver.push([collectedAt, null]);
      vacuumThreshold.push([collectedAt, null]);
      return;
    }
    const tupleThreshold = calcVacuumThreshold(liveTuple);
    deadTuplesUnder.push([collectedAt, Math.min(deadTuple, tupleThreshold)]);
    deadTuplesOver.push([collectedAt, Math.max(0, deadTuple - tupleThreshold)]);
    vacuumThreshold.push([collectedAt, tupleThreshold]);
    if (showAltThreshold) {
      const altTupleThreshold = calcAltVacuumThreshold(liveTuple);
      altVacuumThreshold.push([collectedAt, altTupleThreshold]);
    }
  });

  const data = {
    deadTuplesUnder,
    deadTuplesOver,
    vacuumThreshold,
    altVacuumThreshold,
  };
  const thresholdAt = (i: number) => yValue(data["vacuumThreshold"][i]);
  const series = [
    {
      type: ThresholdAreaSeries,
      opts: {
        mode: "under",
      },
      key: "deadTuplesUnder",
      label: "Dead Rows Under Threshold",
      color: "#efefef",
      tipLabel: "Under Threshold",
    },
    {
      type: ThresholdAreaSeries,
      opts: {
        mode: "over",
        thresholdAt,
      },
      key: "deadTuplesOver",
      label: "Dead Rows Over Threshold",
      color: "#efcccc",
      tipLabel: "Over Threshold",
    },
    {
      type: LineSeries,
      key: "vacuumThreshold",
      label: "Threshold (Rows required to start autovacuum)",
      tipLabel: "Threshold",
    },
  ];
  if (showAltThreshold) {
    series.push({
      type: LineSeries,
      key: "altVacuumThreshold",
      label: "Alternative Threshold",
      tipLabel: "Alt. Threshold",
    });
  }

  return (
    <div>
      <DateRangeGraph
        axes={{
          left: {
            format: "count",
          },
        }}
        series={series}
        data={data}
      />
    </div>
  );
};

export const XactAgeGraph: React.FunctionComponent<{
  frozenxidAge: Datum[];
  autovacuumFreezeMaxAge: number;
}> = ({ frozenxidAge, autovacuumFreezeMaxAge }) => {
  // Only draw age graphs when there is _some_ age data (don't draw if all null or 0)
  const hasFrozenxidAgeData = frozenxidAge?.some((val): boolean => {
    return valueNonZero(val[1]);
  });

  if (!hasFrozenxidAgeData) {
    return null;
  }

  const frozenxidThreshold = frozenxidAge.map((val): Datum => {
    return [val[0], val[1] && autovacuumFreezeMaxAge];
  });

  const frozenxidAgeData = {
    frozenxidAge,
    frozenxidThreshold,
  };

  return (
    <DateRangeGraph
      axes={{
        left: {
          format: "count",
        },
      }}
      series={[
        {
          type: AreaSeries,
          key: "frozenxidAge",
          label: "Oldest Unfrozen XID Age",
          color: "#efefef",
        },
        {
          type: ThresholdSeries,
          key: "frozenxidThreshold",
          label: "Threshold (Age to trigger anti-wraparound autovacuum)",
          tipLabel: "Threshold",
        },
      ]}
      data={frozenxidAgeData}
    />
  );
};

export const MultiXactAgeGraph: React.FunctionComponent<{
  minmxidAge: Datum[];
  autovacuumMultixactFreezeMaxAge: number;
}> = ({ minmxidAge, autovacuumMultixactFreezeMaxAge }) => {
  // Only draw age graphs when there is _some_ age data (don't draw if all null or 0)
  const hasMinmxidAgeData = minmxidAge?.some((val): boolean => {
    return valueNonZero(val[1]);
  });
  if (!hasMinmxidAgeData) {
    return null;
  }

  const minmxidThreshold = minmxidAge.map((val): Datum => {
    return [val[0], val[1] && autovacuumMultixactFreezeMaxAge];
  });

  const minmxidAgeData = {
    minmxidAge,
    minmxidThreshold,
  };

  return (
    <DateRangeGraph
      axes={{
        left: {
          format: "count",
        },
      }}
      series={[
        {
          type: AreaSeries,
          key: "minmxidAge",
          label: "Oldest Unfrozen Multixact ID Age",
          color: "#efefef",
        },
        {
          type: ThresholdSeries,
          key: "minmxidThreshold",
          label: "Threshold (Age to trigger anti-wraparound autovacuum)",
          tipLabel: "Threshold",
        },
      ]}
      data={minmxidAgeData}
    />
  );
};

export const InsertsGraph: React.FunctionComponent<{
  stats: SchemaTableStatsType;
  autovacuumVacuumInsertThreshold: number | null;
  autovacuumVacuumInsertScaleFactor: number | null;
}> = ({
  stats,
  autovacuumVacuumInsertThreshold,
  autovacuumVacuumInsertScaleFactor,
}) => {
  // Inserts VACUUM is only available since Postgres 13 (don't draw if no threshold)
  // Inserts VACUUM is disabled if threshold is set to -1
  if (
    autovacuumVacuumInsertThreshold == null ||
    autovacuumVacuumInsertThreshold === -1
  ) {
    return null;
  }
  // Only draw age graphs when there is _some_ age data (don't draw if all null or 0)
  const hasInsertsData = stats?.insertsSinceVacuum?.some((val): boolean => {
    return valueNonZero(val[1]);
  });
  if (!hasInsertsData) {
    return null;
  }
  const inserts = stats.insertsSinceVacuum as Datum[];

  const calcInsertThreshold = (liveTuples: number): number =>
    autovacuumVacuumInsertThreshold +
    autovacuumVacuumInsertScaleFactor * liveTuples;

  const insertsThreshold = inserts.map((val, idx): Datum => {
    return [
      val[0],
      val[1] == null ? null : calcInsertThreshold(stats.liveTuples[idx][1]),
    ];
  });

  const insertsData = {
    inserts,
    insertsThreshold,
  };
  return (
    <DateRangeGraph
      axes={{
        left: {
          format: "count",
        },
      }}
      series={[
        {
          type: AreaSeries,
          key: "inserts",
          label: "Inserted Rows",
          color: "#efefef",
        },
        {
          type: ThresholdSeries,
          key: "insertsThreshold",
          label: "Threshold (Rows required to start autovacuum)",
          tipLabel: "Threshold",
        },
      ]}
      data={insertsData}
    />
  );
};

export default VacuumGraph;
