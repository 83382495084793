import React from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

type Props = {
  className?: string;
  children: React.ReactNode;
  horizontal?: boolean;
  borders?: boolean;
  equalWidth?: boolean;
};

const PanelTable: React.FunctionComponent<Props> = ({
  className,
  horizontal,
  borders,
  equalWidth,
  children,
}) => {
  let tableClass;
  if (equalWidth && horizontal) {
    tableClass =
      borders || borders === null
        ? styles.equalWidthBorderHorizontalTable
        : styles.equalWidthSimpleHorizontalTable;
  } else if (horizontal) {
    tableClass =
      borders || borders === null
        ? styles.borderHorizontalTable
        : styles.simpleHorizontalTable;
  } else {
    tableClass = borders ? styles.borderTable : styles.simpleTable;
  }

  return (
    <table className={classNames(className, tableClass)}>{children}</table>
  );
};

export default PanelTable;
