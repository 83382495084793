import React from "react";

type Props = {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  defaultValue?: string;
};

class FormTextField extends React.Component<Props> {
  render() {
    const {
      name,
      label,
      type,
      required,
      placeholder,
      autoFocus,
      defaultValue,
    } = this.props;
    const id = name.replace("[", "_").replace("]", "");

    return (
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <div className="row">
          <div className="col-sm-6">
            <input
              type={type || "text"}
              name={name}
              id={id}
              required={required}
              placeholder={placeholder}
              autoFocus={autoFocus}
              defaultValue={defaultValue}
              className="form-control"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FormTextField;
