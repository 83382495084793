import { useSelectedNode } from "components/Explain/WithNodeSelection";
import React from "react";

const Source = () => {
  const [node] = useSelectedNode();

  if (!node) {
    return (
      <div>
        Please select a plan node to view the original EXPLAIN output for that
        step of query execution.
      </div>
    );
  }

  const { extra: _ignored, Plans: _alsoIgnored, ...source } = node;
  const formattedSource = JSON.stringify(source, null, 2);

  return (
    <div>
      <pre className="whitespace-pre-wrap">{formattedSource}</pre>
    </div>
  );
};

export default Source;
