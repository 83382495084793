import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useRoutes } from "utils/routes";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import { checkTitle } from "../../../../../docs/util/checks";

import styles from "./style.module.scss";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";

type Props = {
  databaseId: string;
  missingIndexCheckDisabled: boolean;
  indexUnusedCheckDisabled: boolean;
};

const ChecksDisabledPanels: React.FunctionComponent<Props> = ({
  databaseId,
  missingIndexCheckDisabled,
  indexUnusedCheckDisabled,
}) => {
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(null, databaseId);
  if (!serverIdLoaded) {
    return null;
  }

  return (
    <>
      <CheckDisabledPanel
        serverId={serverId}
        checkGroupAndName="index_advisor/indexing_engine"
        disabled={missingIndexCheckDisabled}
      />
      <CheckDisabledPanel
        serverId={serverId}
        checkGroupAndName="schema/index_unused"
        disabled={indexUnusedCheckDisabled}
      />
    </>
  );
};

const CheckDisabledPanel: React.FunctionComponent<{
  serverId: string;
  checkGroupAndName: string;
  disabled: boolean;
}> = ({ serverId, checkGroupAndName, disabled }) => {
  const { serverCheckConfigure } = useRoutes();
  const [checkGroup, checkName] = checkGroupAndName.split("/");
  const title = checkTitle(checkGroup, checkName);

  if (!disabled) {
    return null;
  }

  const description = getCheckDisabledDescription(checkGroupAndName);

  return (
    <Panel
      title={
        <span>
          <FontAwesomeIcon
            className={styles.checkDisabledIcon}
            icon={faTimesCircle}
          />{" "}
          {title} Check Disabled
        </span>
      }
    >
      <PanelSection>
        <p>{description}</p>
        <p>
          <Link to={serverCheckConfigure(serverId, checkGroupAndName)}>
            Enable {title} check
          </Link>
        </p>
      </PanelSection>
    </Panel>
  );
};

function getCheckDisabledDescription(checkGroupAndName: string): string {
  switch (checkGroupAndName) {
    case "index_advisor/indexing_engine":
      return "Index Advisor is not currently checking for missing indexes on this server.";
    case "schema/index_unused":
      return "Index Advisor is not currently checking for unused indexes on this server.";
    default:
      return null;
  }
}

export default ChecksDisabledPanels;
