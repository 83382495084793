import React from "react";

import styles from "./style.module.scss";
import classNames from "classnames";

export type Props = {
  title: React.ReactNode;
  secondaryTitle?: React.ReactNode;
  secondaryTitleClassName?: string;
  collapsed?: boolean;
  /** if title appears inside of, rather than on top of, a panel  */
  inner?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
};

const PanelTitle: React.FunctionComponent<Props> = ({
  title,
  secondaryTitle,
  secondaryTitleClassName,
  collapsed,
  inner,
  onFocus,
}) => {
  // The secondary title wrapper itself cannot receive focus, but if any
  // focusable elements are included in the title (e.g., a box to filter panel
  // contents), we can propagate the focus information and allow a collapsed
  // Panel to expand.
  return (
    <h2
      className={classNames(
        styles.title,
        inner ? styles.innerTitle : styles.topTitle,
        collapsed && styles.collapsedTitle,
      )}
    >
      {title}
      {secondaryTitle && (
        <small
          onFocus={onFocus}
          className={secondaryTitleClassName || styles.secondaryTitle}
        >
          {secondaryTitle}
        </small>
      )}
    </h2>
  );
};

export default PanelTitle;
