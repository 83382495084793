import React from "react";
import { useParams, Navigate } from "react-router-dom";

import OnboardingFlow from "components/OnboardingFlow";
import PageContent from "components/PageContent";
import { useCurrentOrganization } from "components/WithCurrentOrganization";
import { useRoutes } from "utils/routes";

const OrganizationSetup: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const { serverSetup } = useRoutes();
  const { name, onboardingProgress } = useCurrentOrganization();
  if (onboardingProgress.lastIntegratedServer) {
    return (
      <Navigate
        to={serverSetup(onboardingProgress.lastIntegratedServer.humanId)}
        replace
      />
    );
  }

  return (
    <PageContent
      title={<span>Get Started</span>}
      windowTitle={`Organization: ${name}`}
      pageCategory="organization"
      pageName="setup"
    >
      <OnboardingFlow
        organizationSlug={organizationSlug}
        currentStep="collector_setup"
      />
    </PageContent>
  );
};

export default OrganizationSetup;
