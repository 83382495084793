import React from "react";
import { useParams } from "react-router-dom";
import { format } from "d3-format";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { formatBytes, formatNumber } from "utils/format";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import PageIssueList from "components/PageIssueList";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import PanelSection from "components/PanelSection";
import SQL from "components/SQL";
import Tip from "components/Tip";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";

import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

import {
  SchemaIndexDetails as SchemaIndexDetailsType,
  SchemaIndexDetailsVariables,
} from "./types/SchemaIndexDetails";
import DateRangeBar from "components/DateRangeBar";
import { Data } from "components/Graph/util";
import { AreaSeries } from "components/Graph/Series";
import { useDateRange } from "components/WithDateRange";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";
import { useRoutes } from "utils/routes";
import { useFeature } from "components/OrganizationFeatures";
import SchemaTableQueries from "components/SchemaTableQueries";

const SchemaIndexDetails: React.FunctionComponent = () => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  const { databaseId, schemaIndexId } = useParams();
  const { databaseTable, databaseIndex } = useRoutes();
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(null, databaseId);

  const { data, loading, error } = useQuery<
    SchemaIndexDetailsType,
    SchemaIndexDetailsVariables
  >(QUERY, {
    variables: {
      databaseId,
      schemaIndexId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });
  if (loading || error || !serverIdLoaded) {
    return <Loading error={!!error} />;
  }

  const {
    id,
    schemaName,
    tableId,
    tableName,
    name,
    fillfactor,
    indexDef,
    constraintDef,
    sizeBytes,
    similarIndices,
    metadata,
    lastUsedAt,
  } = data.getSchemaIndexDetails;

  const noStatsData = data.getSchemaIndexStats == null;

  const secondaryTitle = (
    <div style={{ marginRight: "5px" }}>
      Table:&nbsp;
      <strong>
        <Link to={databaseTable(databaseId, tableId)}>
          {schemaName}.{tableName}
        </Link>
      </strong>
    </div>
  );

  const rightValues = [
    ["Fillfactor", fillfactor],
    ["Last Used", lastUsedAt],
  ];
  const [topRight, bottomRight] = rightValues.filter(([_, v]) => v !== null);

  return (
    <PageContent
      title={`Index: ${name}`}
      secondaryTitle={secondaryTitle}
      pageControls={<DateRangeBar />}
      pageCategory="schema-indices"
      pageName="show"
    >
      <Panel title="Index Definition">
        <PanelSection>
          <SQL inline sql={indexDef} />
        </PanelSection>
      </Panel>
      <PageIssueList
        serverId={serverId}
        referentId={id}
        referentType="SchemaIndex"
      />
      <Panel title="Index Statistics">
        {(sizeBytes && (
          <PanelTable horizontal={true} borders={true}>
            <tbody>
              <tr>
                <th>Index Size</th>
                <td>{formatBytes(sizeBytes)}</td>
                {topRight && (
                  <>
                    <th>{topRight[0]}</th>
                    <td>{topRight[1]}</td>
                  </>
                )}
              </tr>
              {hasIndexAdvisor && metadata && (
                <tr>
                  <th>
                    Index Write Overhead{" "}
                    <Tip content="Estimated cost of maintaining this index, as a proportion of writes to its table. Specifically, for every byte written to the table, this many bytes are written to this index (on average)." />
                  </th>
                  <td>{formatNumber(metadata.writeOverhead, 2)}</td>
                  {(topRight || bottomRight) && (
                    <>
                      <th>{bottomRight && bottomRight[0]}</th>
                      <td>{bottomRight && bottomRight[1]}</td>
                    </>
                  )}
                </tr>
              )}
            </tbody>
          </PanelTable>
        )) ||
          null}
        <div className={styles.graphs}>
          <GraphSection
            noData={noStatsData}
            className={styles.indexGraphSection}
          >
            <DateRangeGraph
              data={data.getSchemaIndexStats as unknown as Data}
              axes={{ left: { format: formatBytes } }}
              series={[
                {
                  key: "indexSize",
                  type: AreaSeries,
                  label: "Index Size",
                },
              ]}
            />
          </GraphSection>
          <GraphSection
            noData={noStatsData}
            className={styles.indexGraphSection}
          >
            <DateRangeGraph
              data={data.getSchemaIndexStats as unknown as Data}
              axes={{
                left: {
                  format: "count",
                  tipFormat: (y: number): string =>
                    format(",.2f")(y) + " scans / min",
                },
              }}
              series={[{ key: "indexScans", label: "Index Scans" }]}
            />
          </GraphSection>
          <GraphSection
            noData={noStatsData}
            className={styles.indexGraphSection}
          >
            <DateRangeGraph
              data={data.getSchemaIndexStats as unknown as Data}
              axes={{
                left: { format: "pct from ratio" },
              }}
              series={[
                {
                  key: "blocksFromCache",
                  type: AreaSeries,
                  label: "% From Buffer Cache",
                },
                {
                  key: "blocksFromDisk",
                  type: AreaSeries,
                  label: "% From Disk",
                },
              ]}
            />
          </GraphSection>
        </div>
      </Panel>
      {constraintDef && (
        <Panel title="Constraint">
          <PanelSection>
            <pre className="noborder">{constraintDef}</pre>
          </PanelSection>
        </Panel>
      )}
      {similarIndices.length !== 0 && (
        <Panel title="Similar indexes on this table">
          <PanelTable borders={true}>
            <thead>
              <tr>
                <th>Index Name</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              {similarIndices.map(
                (index): React.ReactNode => (
                  <tr key={index.id}>
                    <td>
                      <Link to={databaseIndex(databaseId, index.id)}>
                        {index.name}
                      </Link>
                    </td>
                    <td className="definition">{index.indexDefShort}</td>
                  </tr>
                ),
              )}
            </tbody>
          </PanelTable>
        </Panel>
      )}
      <SchemaTableQueries
        databaseId={databaseId}
        tableId={tableId}
        indexId={id}
      />
    </PageContent>
  );
};

export default SchemaIndexDetails;
