import React, { useState, useContext } from "react";

export type ExplainCostMetric = "Est. Cost" | "Runtime" | "I/O Time" | "Rows";

const ExplainCostMetricContext =
  React.createContext<ExplainCostMetric>("Runtime");
const ExplainSetCostMetricContext = React.createContext<
  (metric: ExplainCostMetric) => void
>(() => {
  /* do nothing */
});

export const WithExplainCostMetric = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [metric, setMetric] = useState<ExplainCostMetric>("Runtime");
  return (
    <ExplainSetCostMetricContext.Provider value={setMetric}>
      <ExplainCostMetricContext.Provider value={metric}>
        {children}
      </ExplainCostMetricContext.Provider>
    </ExplainSetCostMetricContext.Provider>
  );
};

export function useSetCostMetric() {
  return useContext(ExplainSetCostMetricContext);
}

export function useCostMetric() {
  return useContext(ExplainCostMetricContext);
}
