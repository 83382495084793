import React from "react";
import Loading from "components/Loading";

type Props = {
  data: {
    error: any | null | undefined;
    loading: boolean;
  };
  children: React.ReactNode;
};

const withLoading = (
  Component: React.ComponentType<Props>,
): ((a: Props) => React.ReactNode) => {
  const wrapped = (props: Props): React.ReactNode => {
    const { error, loading } = props.data;

    if (loading || error) {
      return <Loading error={!!error} />;
    }

    return <Component {...props} />;
  };
  wrapped.displayName = `withLoading(${
    Component.displayName || Component.name || "Component"
  })`;
  return wrapped;
};

export default withLoading;
