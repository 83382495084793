import React from "react";

import { ServerSettings_getServerDetails as FullServerType } from "../types/ServerSettings";
import Form from "components/Form";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";
import ServerLocalCollectorSettings from "components/ServerLocalCollectorSettings";

type Props = {
  server: FullServerType;
  organizationPlanName: string;
  permittedToEditServer: boolean;
};

const EditForm: React.FunctionComponent<Props> = ({
  server,
  organizationPlanName,
  permittedToEditServer,
}) => {
  const { server: serverRoute } = useRoutes();
  if (!permittedToEditServer) {
    return (
      <Panel title="Server Settings">
        <PanelSection>
          You are not allowed to edit this server - you need the{" "}
          <strong>Modify</strong> permission on the server.
        </PanelSection>
      </Panel>
    );
  }

  const {
    humanId,
    name,
    retentionInDays,
    statementResetFrequency,
    statementTimeoutMs,
    statementTimeoutMsQueryText,
    usesLocalCollector,
  } = server;

  return (
    <Panel title="Server Settings">
      <Form action={serverRoute(humanId)} method="patch">
        <div className="form-group">
          <label className="control-label" htmlFor="server_name">
            Identifier
          </label>
          <div className="row">
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                defaultValue={name}
                name="server[name]"
                id="server_name"
              />
            </div>
            <div className="col-sm-6">
              <div className="help-block">
                Choose a descriptive name of your choice
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label">Statistics Retention Time</label>
          <div className="row">
            <div className="col-sm-6">
              <input
                className="form-control"
                disabled={true}
                type="text"
                defaultValue={`${retentionInDays} days`}
              />
            </div>
            <div className="col-sm-6">
              <div className="help-block">{organizationPlanName} plan</div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label
            className="control-label"
            htmlFor="server_collector_features_statement_reset_frequency"
          >
            Collector: Query Statistics Reset Frequency
          </label>
          <div className="row">
            <div className="col-sm-6">
              <select
                className="form-control"
                defaultValue={String(statementResetFrequency)}
                name="server[collector_features][statement_reset_frequency]"
                id="server_collector_features_statement_reset_frequency"
              >
                <option value="">None - reset disabled</option>
                <option value="36">Every 6 hours</option>
                <option value="72">Every 12 hours</option>
                <option value="144">Every 24 hours</option>
              </select>
            </div>
            <div className="col-sm-6">
              <div className="help-block">
                Frequency of{" "}
                <a href="https://www.postgresql.org/docs/current/static/pgstatstatements.html#idm46046811141760">
                  pg_stat_statements_reset()
                </a>
                . We recommend only enabling this based on guidance from
                pganalyze support.
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label
            className="control-label"
            htmlFor="server_collector_features_statement_timeout_ms"
          >
            Collector: Statement Timeout
          </label>
          <div className="row">
            <div className="col-sm-6">
              <select
                className="form-control"
                defaultValue={String(statementTimeoutMs)}
                name="server[collector_features][statement_timeout_ms]"
                id="server_collector_features_statement_timeout_ms"
              >
                <option value="30000">30s</option>
                <option value="60000">60s</option>
                <option value="300000">300s</option>
              </select>
            </div>
            <div className="col-sm-6">
              <div className="help-block">
                Timeout for all statements the collector runs, except query text
                statements.
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label
            className="control-label"
            htmlFor="server_collector_features_statement_timeout_ms_query_text"
          >
            Collector: Query Text Timeout
          </label>
          <div className="row">
            <div className="col-sm-6">
              <select
                className="form-control"
                defaultValue={String(statementTimeoutMsQueryText)}
                name="server[collector_features][statement_timeout_ms_query_text]"
                id="server_collector_features_statement_timeout_ms_query_text"
              >
                <option value="120000">120s</option>
                <option value="300000">300s</option>
                <option value="600000">600s</option>
              </select>
            </div>
            <div className="col-sm-6">
              <div className="help-block">
                Timeout for statements that retrieve query text from
                pg_stat_statements.
              </div>
            </div>
          </div>
        </div>
        {usesLocalCollector && (
          <>
            <hr />
            <ServerLocalCollectorSettings
              server={server}
              systemType={server.systemType}
            />
          </>
        )}
        <div className="form-group">
          <input
            type="submit"
            name="commit"
            value="Save Settings"
            className="btn btn-success"
            data-disable-with="Save Settings"
          />
        </div>
      </Form>
    </Panel>
  );
};

export default EditForm;
