import React, { useEffect, useState } from "react";

import classNames from "classnames";
import Downshift, { ControllerStateAndHelpers } from "downshift";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";

import styles from "./style.module.scss";

type Props<T> = {
  items: T[];
  itemToString: (item: T | null) => string;
  onChange: (selected: T | null) => void;
  placeholder: string;
  value: T | null;
  label?: string;
};

function Select<T>({
  items,
  itemToString,
  onChange,
  placeholder,
  value,
  label,
}: Props<T>): React.ReactElement {
  function itemToStringInternal(item: T | null): string {
    if (item === null) {
      return "";
    }
    return itemToString(item);
  }

  return (
    <div className={styles.outerWrapper}>
      <Downshift
        onChange={onChange}
        selectedItem={value}
        itemToString={itemToStringInternal}
      >
        {({ getRootProps, ...otherDownshiftProps }) => {
          return (
            <SelectContent
              {...getRootProps({ refKey: "innerRef" })}
              downshiftProps={otherDownshiftProps}
              items={items}
              placeholder={placeholder}
              label={label}
              itemToString={itemToString}
            />
          );
        }}
      </Downshift>
    </div>
  );
}

function SelectContent<T>({
  innerRef,
  items,
  itemToString,
  label,
  placeholder,
  downshiftProps: {
    getLabelProps,
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    clearSelection,
    selectedItem,
    inputValue,
    highlightedIndex,
    selectItem,
  },
}: {
  downshiftProps: ControllerStateAndHelpers<T>;
  innerRef: React.RefObject<HTMLDivElement>;
} & Omit<Props<T>, "onChange" | "value">): React.ReactElement {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [prevSelection, setPrevSelection] = useState<T | null>(null);
  useEffect(() => {
    if (selectedItem) {
      setPrevSelection(selectedItem);
    }
  }, [selectedItem]);
  useEffect(() => {
    if (inputValue) {
      setPrevSelection(null);
    }
  }, [inputValue]);

  function filterItems(input: string): T[] {
    const inputLower = input.toLowerCase();
    return items.filter((item) => {
      return itemToString(item).toLowerCase().includes(inputLower);
    });
  }

  function handleInputKeyUp(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === "Enter") {
      const filtered = filterItems(inputValue);
      if (filtered.length > 0) {
        selectItem(filtered[0]);
      }
    }
  }

  function handleInputFocus(): void {
    setShowPlaceholder(false);
    clearSelection();
  }

  function handleInputBlur(): void {
    setShowPlaceholder(true);
    if (!selectedItem && !!prevSelection) {
      selectItem(prevSelection);
    }
  }

  function handleClearClick(): void {
    setPrevSelection(null);
    clearSelection();
  }

  return (
    <div ref={innerRef} className={styles.innerWrapper}>
      {label && <div {...getLabelProps()}>{label}</div>}
      <div className={styles.inputWrapper}>
        <input
          {...getInputProps({
            placeholder: showPlaceholder ? placeholder : "",
            onKeyUp: handleInputKeyUp,
            onFocus: handleInputFocus,
            onBlur: handleInputBlur,
          })}
          className={classNames(styles.input, isOpen && styles.inputOpen)}
        />
        {selectedItem ? (
          <button
            className={styles.inputButton}
            onClick={handleClearClick}
            aria-label="clear selection"
          >
            <ClearIcon />
          </button>
        ) : (
          <button className={styles.inputButton} {...getToggleButtonProps()}>
            <CaretIcon isOpen={isOpen} />
          </button>
        )}
      </div>
      <div className={styles.menuWrapper}>
        <ul
          className={classNames(styles.menu, !isOpen && styles.menuClosed)}
          {...getMenuProps({ open: isOpen })}
        >
          {isOpen
            ? filterItems(inputValue).map((item, index) => (
                <li
                  key={index}
                  className={classNames(
                    styles.menuItem,
                    highlightedIndex === index && styles.menuItemActive,
                    selectedItem === item && styles.menuItemSelected,
                  )}
                  {...getItemProps({ item, index })}
                >
                  {itemToString(item)}
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  );
}

const CaretIcon: React.FunctionComponent<{ isOpen: boolean }> = ({
  isOpen,
}) => {
  return <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />;
};

const ClearIcon: React.FunctionComponent = () => (
  <FontAwesomeIcon icon={faTimes} />
);

export default Select;
