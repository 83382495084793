import {
  CollectorReleaseCache,
  convertCollectorReleaseCache,
  StoredCollectorReleaseCache,
} from "./collector";
import {
  UserPreferences,
  StoredUserPreferences,
  convertPreferences,
} from "./preferences";

export type LocalStorageSchema = {
  preferences: {
    current: UserPreferences;
    known: StoredUserPreferences;
  };
  collectorReleaseCache: {
    current: CollectorReleaseCache;
    known: StoredCollectorReleaseCache;
  };
};

export function getConverter<K extends keyof LocalStorageSchema>(
  section: K,
): (known: LocalStorageSchema[K]["known"]) => LocalStorageSchema[K]["current"] {
  switch (section) {
    case "preferences":
      return convertPreferences;
    case "collectorReleaseCache":
      return convertCollectorReleaseCache;
    default:
      throw new Error("unknown localStorage section");
  }
}

export function getLatestVersion(section: keyof LocalStorageSchema): number {
  switch (section) {
    case "preferences":
      return 1;
    case "collectorReleaseCache":
      return 1;
    default:
      throw new Error("unknown localStorage section");
  }
}
