import React from "react";

import {
  IndexSelectionResultType,
  findByLocator,
  isIndexType,
  isScanType,
} from "../util";

import { IndexIcon, ScanIcon, SeqScanIcon } from "../Icons";
import IndexDetails from "../IndexDetails";
import SeqScanDetails from "../SeqScanDetails";
import ScanDetails from "../ScanDetails";
import ModalContainer from "components/ModalContainer";

const Details: React.FunctionComponent<{
  databaseId: string;
  result: IndexSelectionResultType;
  locator: string;
  onClose: () => void;
}> = ({ databaseId, result, locator, onClose }) => {
  let title: React.ReactNode;
  let content: React.ReactNode;
  const selection = findByLocator(locator, result);
  if (!selection) {
    return null;
  } else if (selection === "seq scan") {
    title = (
      <>
        <SeqScanIcon className="mr-1" />
        Sequential Scan
      </>
    );
    content = <SeqScanDetails result={result} />;
  } else if (isScanType(selection)) {
    title = (
      <>
        <ScanIcon label={selection.label} className="mr-1" />
        Scan
      </>
    );
    content = (
      <ScanDetails databaseId={databaseId} result={result} scan={selection} />
    );
  } else if (isIndexType(selection)) {
    title = (
      <>
        <IndexIcon
          label={selection.label}
          className="mr-1"
          selected={selection.selected}
          existing={selection.existing}
        />
        Index
      </>
    );
    content = (
      <IndexDetails databaseId={databaseId} result={result} index={selection} />
    );
  }

  return (
    <ModalContainer title={title} onClose={onClose}>
      {content}
    </ModalContainer>
  );
};

export default Details;
