---
title: 'Step 1: Guided Setup'
install_track_title: Installation Guide (Self-Managed)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import CollectorInstallInstructions from '../../components/CollectorInstallInstructions'

To begin guided setup, you'll need to install the collector, which sends statistics information
and query activity to pganalyze.

Note that guided setup is **only supported for Ubuntu 20.04 or newer or Debian 11 or newer.** If you are
running an older version, or using a RHEL/CentOS/Amazon Linux system, please follow the <Link to="01_create_monitoring_user">manual setup instructions</Link>.

You will need an API key for the install. <PublicOnly>It can be found in the pganalyze Settings page for your organization.</PublicOnly><InAppOnly>It is included in the command below.</InAppOnly>

To install the collector and start guided setup, run the following command:

<CollectorInstallInstructions apiKey={props.apiKey} guided />

You'll be shown additional instructions, and prompted to confirm any changes to your system.
<PublicOnly>
  Once finished, check the <a href='https://app.pganalyze.com'>pganalyze app</a> to continue.
</PublicOnly>
<InAppOnly>
  Once finished, check back here to continue. <br />
</InAppOnly>
