import React, { useState } from "react";
import classNames from "classnames";

import Form from "../Form";
import FormErrors from "../FormErrors";
import GoogleSignIn from "../GoogleSignIn";

import { useUnauthenticatedRoutes } from "utils/routes";

import styles from "./style.module.scss";

type Props = {
  invite: {
    email: string;
    organizationName: string;
    invitedByFullname: string;
  };
  googleAuthorizePath: string;
  googleClientId: string | null | undefined;
  enterpriseSamlSignInUrl: string | null | undefined;
};

const Login: React.FunctionComponent<Props> = ({
  invite,
  googleClientId,
  googleAuthorizePath,
  enterpriseSamlSignInUrl,
}) => {
  const { userSignUp, userSignIn, userPasswordReset } =
    useUnauthenticatedRoutes();
  const [googleErrors, setGoogleErrors] = useState<string[]>([]);
  const [enterpriseSsoOverride, setEnterpriseSsoOverride] = useState(false);

  if (enterpriseSamlSignInUrl && !enterpriseSsoOverride) {
    return (
      <>
        <div className="header-notice">
          <a
            onClick={(evt) => {
              evt.preventDefault();
              setEnterpriseSsoOverride(true);
            }}
            href="#"
            className="linkbar-email-link"
          >
            Sign In with Fallback Authentication
          </a>
        </div>
        <div className="flex-container">
          <div className="form-container">
            <h1>Sign In</h1>
            <p>Your organization has configured Single Sign-On:</p>
            <a
              href={enterpriseSamlSignInUrl}
              className={classNames("btn", "btn-success", styles.ssoLink)}
            >
              Continue with Single Sign-On
            </a>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="header-notice">
        {enterpriseSamlSignInUrl ? (
          <p>
            <a
              onClick={(evt) => {
                evt.preventDefault();
                setEnterpriseSsoOverride(false);
              }}
              href="#"
              className="linkbar-email-link"
            >
              Sign In with Single Sign-On
            </a>
          </p>
        ) : null}
        Not signed up yet? <a href={userSignUp()}>Sign up now.</a>
      </div>
      <div className="flex-container">
        <div className="form-container">
          <Form
            id="new_user"
            action={userSignIn()}
            method="post"
            insideSection={true}
          >
            <h1>Sign In</h1>
            {!invite && <p>Access your existing pganalyze account:</p>}
            {invite && (
              <p>
                You're invited to the organization "{invite.organizationName}"
                by {invite.invitedByFullname}.
                <br />
                <br />
                Please sign in to accept the invitation.
              </p>
            )}
            <input
              className="form-control"
              type="email"
              name="user[email]"
              id="user_email"
              autoFocus={true}
              required={true}
              placeholder="Email"
            />
            <input
              className="form-control"
              type="password"
              name="user[password]"
              id="user_password"
              placeholder="Password"
            />
            <div className="checkbox">
              <label>
                <input name="user[remember_me]" type="hidden" value="0" />
                <input
                  type="checkbox"
                  value="1"
                  defaultChecked
                  name="user[remember_me]"
                  id="user_remember_me"
                />
                Remember me
              </label>
            </div>
            <input
              type="submit"
              name="commit"
              value="Sign in"
              className="btn btn-success"
              data-disable-with="Sign in"
            />
          </Form>
        </div>
        <div className="linkbar">
          <ul>
            <li className="active">
              <a href="#" className="linkbar-email-link">
                Sign In with Email
              </a>
            </li>
            {googleClientId && (
              <li>
                <GoogleSignIn
                  simple={true}
                  authorizePath={googleAuthorizePath}
                  googleClientId={googleClientId}
                  className="linkbar-google-link"
                  errorHandler={setGoogleErrors}
                />
                <FormErrors errors={googleErrors} />
              </li>
            )}
          </ul>
          <a
            href={userPasswordReset()}
            style={{ marginTop: "30px", fontSize: "12px" }}
          >
            Forgot your password?
          </a>
        </div>
      </div>
    </>
  );
};

export default Login;
