import React from "react";
import moment from "moment-timezone";
import pluralize from "pluralize";

import { formatDateShort } from "utils/format";

import { BillingPlanInfoFragment_planInfo } from "./types/BillingPlanInfoFragment";

import styles from "./style.module.scss";

const PlanInfo: React.FunctionComponent<{
  planInfo: BillingPlanInfoFragment_planInfo;
  allowPlanChange: boolean;
  changePlanElement?: React.ReactNode;
}> = ({ planInfo, allowPlanChange, changePlanElement }) => {
  return (
    <>
      <div className={styles.planInfo}>
        {planInfo.subscriptionCancelationPending ? (
          <>
            <p>
              <strong>Your subscription has been canceled.</strong>
            </p>
            <p>
              You can still use the{" "}
              <strong>{planInfo.subscriptionPlanName}</strong> plan until{" "}
              {formatDateShort(
                moment.unix(planInfo.subscriptionCancelationEffectiveAt),
              )}
              .
              {allowPlanChange ? (
                <> You can reactivate your subscription below:</>
              ) : null}
            </p>
          </>
        ) : planInfo.activeSubscription ? (
          <>
            Your organization is currently on the{" "}
            <strong>{planInfo.subscriptionPlanName}</strong> plan.
            {changePlanElement}
          </>
        ) : planInfo.isTrial && planInfo.subscriptionPlanId != "lite" ? (
          <>
            Your organization is trialing the Scale plan. Your trial has{" "}
            <strong>
              {planInfo.trialEndsAt
                ? pluralize(
                    "day",
                    moment(planInfo.trialEndsAt * 1000).diff(moment(), "days"),
                    true,
                  )
                : "0 days"}
            </strong>{" "}
            remaining.
          </>
        ) : planInfo.subscriptionPlanId == "lite" &&
          !planInfo.supportsLitePlan ? (
          <>
            <p>Your trial has ended. Please upgrade to a paid plan.</p>
            <p>
              Want to try pganalyze again?{" "}
              <a href="https://pganalyze.com/contact">
                Contact us to renew your trial.
              </a>
            </p>
          </>
        ) : (
          <>
            Your organization is currently on the{" "}
            <strong>{planInfo.subscriptionPlanName}</strong> plan.
          </>
        )}
      </div>
    </>
  );
};

export default PlanInfo;
