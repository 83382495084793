const HashStateKeyArray = [
  "threshold",
  "scale_factor",
  "freeze_max_age",
  "mxid_freeze_max_age",
  "insert_threshold",
  "insert_scale_factor",
] as const;
type HashStateKeyType = (typeof HashStateKeyArray)[number];
export type VacuumSimulatorHashState = {
  key: HashStateKeyType;
  value: number;
};

export const vacuumSimulatorHashStateEncoder = (
  value: VacuumSimulatorHashState[],
): string => {
  return value
    .map((val) => {
      return `${val.key}=${val.value}`;
    })
    .join(",");
};

export const vacuumSimulatorHashStateDecoder = (
  str: string,
): VacuumSimulatorHashState[] => {
  return str
    .split(",")
    .map((part) => {
      const [k, v] = part.split("=", 2);
      if (
        k &&
        v &&
        HashStateKeyArray.some((v) => v == k) &&
        !isNaN(parseInt(v))
      ) {
        return { key: k as HashStateKeyType, value: Number(v) };
      }
      return null;
    })
    .filter((v) => v != null);
};
