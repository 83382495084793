import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import DatabaseSummaryStatsTable from "components/DatabaseSummaryStatsTable";
import Loading from "components/Loading";
import IssueList from "components/IssueList";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";

import BlankSlate from "./BlankSlate";
import SystemStats from "./SystemStats";

import {
  ServerDashboard as ServerDashboardType,
  ServerDashboardVariables,
  ServerDashboard_getIssues,
  ServerDashboard_getSystemDetails,
  ServerDashboard_getServerDetails_tags,
} from "./types/ServerDashboard";
import QUERY from "./Query.graphql";

const ServerDashboard: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const { data, loading, error } = useQuery<
    ServerDashboardType,
    ServerDashboardVariables
  >(QUERY, {
    variables: {
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const server = data.getServerDetails;
  const blankSlate = server.lastSnapshotAt === null;

  return (
    <PageContent
      title="Dashboard"
      windowTitle={`Dashboard: ${server.name}`}
      pageCategory="server"
      pageName={"show"}
      pageProps={{ blankSlate }}
    >
      {blankSlate ? (
        <BlankSlate systemType={server.systemType} serverId={server.humanId} />
      ) : (
        <DashboardContent
          tags={server.tags}
          serverIssues={data.getIssues}
          systemDetails={data.getSystemDetails}
          serverId={serverId}
        />
      )}
    </PageContent>
  );
};

const DashboardContent: React.FunctionComponent<{
  tags: ServerDashboard_getServerDetails_tags[];
  serverIssues: ServerDashboard_getIssues[];
  systemDetails: ServerDashboard_getSystemDetails;
  serverId: string;
}> = ({ tags, serverIssues, systemDetails, serverId }) => {
  return (
    <>
      <SystemStats systemDetails={systemDetails} />
      {systemDetails.info?.systemType == "amazon_rds" && (
        <ServerTags tags={tags} />
      )}
      <ServerIssues serverIssues={serverIssues} serverId={serverId} />
      <DatabaseSummaryStatsTable serverId={serverId} />
    </>
  );
};

const ServerIssues: React.FunctionComponent<{
  serverIssues: ServerDashboard_getIssues[];
  serverId: string;
}> = ({ serverIssues, serverId }) => {
  return (
    <Panel title="Server-wide Issues">
      <IssueList serverId={serverId} issues={serverIssues} verbose />
      {serverIssues.length === 0 && (
        <PanelSection>No server-wide issues found.</PanelSection>
      )}
    </Panel>
  );
};

const ServerTags: React.FunctionComponent<{
  tags: ServerDashboard_getServerDetails_tags[];
}> = ({ tags }) => {
  const title = (
    <>
      Server Tags
      <span className="flex-grow" />
    </>
  );

  return (
    <Panel title={title} className="overflow-hidden">
      {tags.length == 0 ? (
        <PanelSection>No server tags found.</PanelSection>
      ) : (
        <ServerTagsTable tags={tags} />
      )}
    </Panel>
  );
};

export const ServerTagsTable: React.FunctionComponent<{
  tags: ServerDashboard_getServerDetails_tags[];
}> = ({ tags }) => {
  return (
    <div className="overflow-hidden">
      <PanelTable borders>
        <thead>
          <tr>
            <th>Key</th>
            <th className="wh-3/5">Value</th>
          </tr>
        </thead>
        <tbody>
          {tags.map(({ key, value }) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </PanelTable>
    </div>
  );
};

export default ServerDashboard;
