import React from "react";

import styles from "./style.module.scss";

export const NoData: React.FunctionComponent = () => {
  return (
    <div className={styles.noData}>
      <div>no data available</div>
    </div>
  );
};

export default NoData;
