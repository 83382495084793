import React from "react";

import classNames from "classnames";

import styles from "./style.module.scss";
import ContactSupportLink from "components/ContactSupportLink";
import { SpinnerIcon } from "components/Icons";

interface Props {
  error?: boolean;
  small?: boolean;
  className?: string;
}

const Loading: React.FunctionComponent<Props> = ({
  error,
  small,
  className,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        !small && styles.containerBig,
        className,
      )}
    >
      {!error && <SpinnerIcon className="text-[20px]" />}
      {error && (
        <>
          <div className={styles.loading}>Loading...</div>
          <div className={styles.error}>
            Internal Error: Please{" "}
            <ContactSupportLink>contact support</ContactSupportLink>
          </div>
        </>
      )}
    </div>
  );
};

export default Loading;
