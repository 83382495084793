---
title: 'Step 4: Adjust Postgres log_line_prefix'
install_track_title: 'Log Insights: Setup (Azure Database for PostgreSQL)'
backlink_href: /docs/log-insights/setup
backlink_title: 'Log Insights: Setup'
---

import imgLogLinePrefix from './log-line-prefix.png'

export const ImgLogLinePrefix = () => <img src={imgLogLinePrefix} alt="Screenshot of changing log_line_prefix setting in Azure Portal" />

By default Azure uses `%t-%c-` as the `log_line_prefix` setting. Whilst we support this setting,
we recommend you utilize the following `log_line_prefix` parameter value instead:

```
log_line_prefix = '%m [%p] %q[user=%u,db=%d,app=%a] '
```

To set the `log_line_prefix`, copy the above value inside the single quotes. Note there is a trailing space at the end
of the parameter value, which is required.

Then go to the "Server parameters" section of your database instance to change the setting:

<ImgLogLinePrefix />

The correct setting for `log_line_prefix` ensures that the collector can associate each log line
to the correct session, database and username.

<Link className="btn btn-success" to="05_configure_collector">
  Continue to Step 5: Configure collector
</Link>
