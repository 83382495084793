import React from "react";

import { Node, Annotation } from "types/explain";

import RelationInfo from "../../Explain/RelationInfo";
import IndexInfo from "../../Explain/IndexInfo";

import Overview from "./Overview";
import IOAndBuffers from "./IOAndBuffers";
import Output from "./Output";

import NodeTypeIcon from "../../Explain/NodeTypeIcon";
import { useSelectedNode } from "../../Explain/WithNodeSelection";

type Props = {
  databaseId: string;
  blockSize: number;
  annotations: Annotation[];
};

const NodeDetails = ({ databaseId, blockSize, annotations }: Props) => {
  const [node] = useSelectedNode();

  if (!node) {
    return (
      <div>
        Please select a plan node to view some detailed information about that
        step of query execution.
      </div>
    );
  }

  const nodeAnnotations = annotations.filter((a) => a.node === node?.extra.id);

  const hasRelation = "Relation Name" in node;
  const hasIndex = "Index Name" in node;
  const hasEitherRelOrIdx = hasRelation || hasIndex;

  return (
    <div>
      <Heading node={node} />
      {hasEitherRelOrIdx && <Subheading node={node} databaseId={databaseId} />}
      <Overview annotations={nodeAnnotations} node={node} />
      <h4 className="text-[#606060]">I/O & Buffers</h4>
      <IOAndBuffers node={node} blockSize={blockSize} />
      <hr />
      <h4 className="text-[#606060]">Output Columns</h4>
      <Output node={node} />
    </div>
  );
};

const Heading = ({ node }: { node: Node }) => {
  const nodeType = node["Node Type"];
  const nodeTypeDetails = node["Scan Direction"] || node["Operation"];
  const nodeLabel = node["Parallel Aware"] ? "Parallel " + nodeType : nodeType;
  return (
    <div title={nodeType}>
      <h4 className="mt-0 text-lg mb-0 text-[#606060]">
        <NodeTypeIcon nodeType={nodeType} />
        &nbsp; {nodeLabel}
        {nodeTypeDetails && (
          <span className="ml-0.5"> ({nodeTypeDetails})</span>
        )}
      </h4>
    </div>
  );
};

const Subheading = ({
  node,
  databaseId,
}: {
  node: Node;
  databaseId: string;
}) => {
  return (
    <div className="ml-3">
      <RelationInfo node={node} databaseId={databaseId} wrap />
      <IndexInfo node={node} databaseId={databaseId} wrap />
    </div>
  );
};

export default NodeDetails;
