import React from "react";
import { useQuery } from "@apollo/client";
import { Navigate, useParams } from "react-router-dom";

import DatabaseSummaryStatsTable from "components/DatabaseSummaryStatsTable";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";

import {
  OrganizationOverview as OrganizationOverviewType,
  OrganizationOverviewVariables,
} from "./types/OrganizationOverview";
import QUERY from "./Query.graphql";

import ServerTable from "./ServerTable";

type Props = {
  tab?: string;
};

const OrganizationOverview: React.FunctionComponent<Props> = ({ tab }) => {
  const { slug: organizationSlug } = useParams();
  const { organizationSetup, organizationDatabases, organizationServers } =
    useRoutes();
  const { data, loading, error } = useQuery<
    OrganizationOverviewType,
    OrganizationOverviewVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { name, visibleServerCount, permittedToCreateServers } =
    data.getOrganizationDetails;

  if (visibleServerCount === 0 && permittedToCreateServers) {
    return <Navigate to={organizationSetup(organizationSlug)} replace />;
  }

  if (!tab) {
    return <Navigate to={organizationServers(organizationSlug)} replace />;
  }

  const featureNav =
    visibleServerCount > 0 ? (
      <PageSecondaryNavigation>
        <PageNavLink to={organizationServers(organizationSlug)}>
          All Servers
        </PageNavLink>
        <PageNavLink to={organizationDatabases(organizationSlug)}>
          All Databases
        </PageNavLink>
      </PageSecondaryNavigation>
    ) : null;

  return (
    <PageContent
      title={
        <span>
          Organization <strong>{name}</strong>
        </span>
      }
      featureNav={featureNav}
      windowTitle={`Organization: ${name}`}
      pageCategory="organization"
      pageName="show"
      pageTab={tab}
    >
      {tab == "servers" &&
        (visibleServerCount > 0 || permittedToCreateServers) && (
          <ServerTable organizationSlug={organizationSlug} />
        )}
      {tab == "databases" &&
        (visibleServerCount > 0 || permittedToCreateServers) && (
          <DatabaseSummaryStatsTable organizationSlug={organizationSlug} />
        )}
      {visibleServerCount === 0 && !permittedToCreateServers && (
        <Panel title="Restricted Permissions">
          <PanelSection>
            <p>
              You don't have access to any servers and are not allowed to create
              new ones.
            </p>
            <p>
              <strong>Hint:</strong> Ask your administrator to grant access, or
              explore the menu on the left for other organization actions, e.g.
              Billing.
            </p>
          </PanelSection>
        </Panel>
      )}
    </PageContent>
  );
};

export default OrganizationOverview;
