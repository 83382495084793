import React from "react";

import { Node } from "types/explain";

import styles from "./style.module.scss";

const Output: React.FunctionComponent<{ node: Node }> = ({ node }) => {
  const output = node["Output"];
  if (!output) {
    return <div>Output columns not available for this node</div>;
  }
  return (
    <div className={styles.output}>
      <ul>
        {output.map((col: string, idx: number) => (
          <li key={idx}>{col}</li>
        ))}
      </ul>
    </div>
  );
};

export default Output;
