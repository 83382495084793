import imgManagedIdentityVMAssignment from '../../images/azure_managed_identity_vm_assignment.png'
export const ImgManagedIdentityVMAssignment = () => <img src={imgManagedIdentityVMAssignment} alt="Screenshot of Virtual Machine Identity configuration in Azure Portal" />

### Set up Managed Identity

To start, we need to [create a new user-assigned Managed Identity](https://portal.azure.com/#create/Microsoft.ManagedIdentity) through the Azure Portal.

After the Managed Identity is created, assign it to your virtual machine by going to "Security", "Identity", "User assigned" and clicking "Add user assigned managed identity":

<ImgManagedIdentityVMAssignment />

Now the pganalyze collector running inside the virtual machine will be able to call Azure REST APIs using the Managed Identity.
