import React from "react";
import NodeTypeIcon from "../../Explain/NodeTypeIcon";

import classNames from "classnames";

import { Node } from "types/explain";

import styles from "./style.module.scss";

const NodeHeading = ({
  node,
  className,
  onNodeClick,
  children,
}: {
  node: Node;
  className?: string;
  onNodeClick?: () => void;
  children?: React.ReactNode;
}) => {
  const nodeType = node["Node Type"];
  const nodeTypeDetails = node["Scan Direction"] || node["Operation"];
  const nodeLabel = node["Parallel Aware"] ? "Parallel " + nodeType : nodeType;
  return (
    <div
      className={classNames(styles.heading, className)}
      data-nodeheading
      title={nodeType}
      onClick={onNodeClick}
      style={{ cursor: onNodeClick ? "pointer" : "default" }}
    >
      <div className="flex">
        <span className={styles.title}>
          <NodeTypeIcon nodeType={nodeType} />
          &nbsp; {nodeLabel}
        </span>
        {nodeTypeDetails && (
          <span className="ml-0.5"> ({nodeTypeDetails})</span>
        )}
        <div className={styles.childWrapper}>{children}</div>
        <span className={styles.nodeId}>{node.extra.id}</span>
      </div>
    </div>
  );
};

export default NodeHeading;
