---
title: 'Step 2: Install the Collector'
install_track_title: Installation Guide (Google Cloud SQL and AlloyDB)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import CollectorInstallInstructions from '../../components/CollectorInstallInstructions'

In this step we'll install the collector which sends statistics information to pganalyze.

<CollectorInstallInstructions apiKey={props.apiKey} />

<Link className="btn btn-success" to="03_configure_the_collector_package">
  Continue to Step 3: Configure the Collector
</Link>
