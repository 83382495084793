import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { StripeElementStyle } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { useAsyncActionFlash } from "components/WithFlashes";
import { useRoutes } from "utils/routes";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import {
  UpdateCreditCard as UpdateCreditCardType,
  UpdateCreditCardVariables,
} from "./types/UpdateCreditCard";
import MUTATION from "./Mutation.graphql";
import styles from "./style.module.scss";

const stripeElementStyle: StripeElementStyle = {
  base: {
    fontFamily: '"Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif',
    "::placeholder": {
      color: "#aeaeae",
    },
  },
};

export const CreditCardForm: React.FunctionComponent<{
  cardHolderName: string;
  setCardHolderName: (val: string) => void;
}> = ({ cardHolderName, setCardHolderName }) => {
  return (
    <>
      <div className="mb-4">
        <label className="control-label">Name on card</label>
        <input
          className="card-holder form-control"
          type="text"
          value={cardHolderName}
          onChange={(e) => setCardHolderName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="control-label" htmlFor="card-number">
          Card number
        </label>
        <CardNumberElement
          className={styles.stripeFormControl}
          options={{ style: stripeElementStyle }}
        />
      </div>
      <div className="flex gap-6 mb-4">
        <div className="flex-grow">
          <label className="control-label">Expiry date</label>
          <CardExpiryElement
            className={styles.stripeFormControl}
            options={{ style: stripeElementStyle }}
          />
        </div>
        <div className="flex-grow max-w-[8rem]">
          <label className="control-label" htmlFor="card-cvc">
            CVC
          </label>
          <CardCvcElement
            className={styles.stripeFormControl}
            options={{ style: stripeElementStyle }}
          />
        </div>
      </div>
    </>
  );
};

const UpdateCreditCard: React.FunctionComponent<{
  organizationSlug: string;
}> = ({ organizationSlug }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const { organizationSubscription } = useRoutes();

  const [
    updateBillingCard,
    { called: mutationCalled, loading: mutationLoading, error: mutationError },
  ] = useMutation<UpdateCreditCardType, UpdateCreditCardVariables>(MUTATION);
  useAsyncActionFlash({
    called: mutationCalled,
    loading: mutationLoading,
    error: mutationError?.message,
    success:
      "Updated your billing details, this will take effect with your next charge.",
    successRedirect: organizationSubscription(organizationSlug),
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }
    if (!window.stripeTestPaymentMethod && (!stripe || !elements)) {
      return;
    }

    if (window.stripeTestPaymentMethod) {
      updateBillingCard({
        variables: {
          organizationSlug,
          paymentMethodId: window.stripeTestPaymentMethod,
        },
      });
      return;
    }

    setError(null);
    setLoading(true);

    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
        billing_details: {
          name: cardHolderName,
        },
      })
      .then(({ paymentMethod, error }) => {
        setLoading(false);
        if (error) {
          setError(error.message);
        } else {
          updateBillingCard({
            variables: { organizationSlug, paymentMethodId: paymentMethod.id },
          });
        }
      });
  };

  return (
    <Panel title="Update Credit Card">
      <PanelSection className="w-[42%] min-w-min">
        <form onSubmit={handleSubmit}>
          {error && (
            <p
              className="payment-errors alert-danger"
              style={{ display: "inline-block" }}
            >
              {error}
            </p>
          )}
          <CreditCardForm
            cardHolderName={cardHolderName}
            setCardHolderName={setCardHolderName}
          />
          <input
            type="submit"
            name="commit"
            value={
              loading || mutationLoading
                ? "Updating credit card..."
                : "Update credit card"
            }
            disabled={loading || mutationLoading}
            className="btn btn-success"
          ></input>
        </form>
      </PanelSection>
    </Panel>
  );
};

export default UpdateCreditCard;
