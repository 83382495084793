import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import AlertPolicyList from "components/AlertPolicyList";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import { CHECK_INFO } from "components/CheckList";

import {
  AlertSummary as AlertSummaryType,
  AlertSummaryVariables,
  AlertSummary_getChecks,
  AlertSummary_getOrganizationDetails_slackIntegration as SlackIntegration,
  AlertSummary_getOrganizationDetails_pagerdutyIntegration as PagerDutyIntegration,
} from "./types/AlertSummary";
import {
  ServerAlertSummary,
  ServerAlertSummaryVariables,
} from "./types/ServerAlertSummary";

import { useRoutes } from "utils/routes";
import PanelItems from "components/PanelItems";
import PanelItem from "components/PanelItem";
import PanelSection from "components/PanelSection";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import { checkMaxSeverity } from "../../../../../docs/util/checks";
import ConnectToSlack from "components/ConnectToSlack";
import { PagerDutyLogo, SlackLogo } from "components/Icons";
import ConnectToPagerDuty from "components/ConnectToPagerDuty";

import styles from "./style.module.scss";

import QUERY from "./Query.graphql";
import SERVER_QUERY from "./Query.server.graphql";
import { useCurrentOrganization } from "components/WithCurrentOrganization";
import Grid, { GridColumn } from "components/Grid";

type Check = AlertSummary_getChecks & {
  checkTitle: string;
  checkTitleDisplay: React.ReactNode;
  emailAlert: string;
  slackAlert: string;
  pagerdutyAlert: string;
};

type Props = {
  serverId?: string;
};

const AlertSummary: React.FunctionComponent<Props> = ({ serverId }) => {
  const { slug: organizationSlug } = useCurrentOrganization();
  const [searchTerm, setSearchTerm] = useState("");
  const {
    serverCheckConfigure,
    organizationCheckConfigure,
    organizationAlertPolicy,
  } = useRoutes();
  const { data, loading, error } = useQuery<
    AlertSummaryType,
    AlertSummaryVariables
  >(QUERY, {
    variables: { organizationSlug, serverId },
  });
  const {
    data: serverData,
    loading: serverLoading,
    error: serverError,
  } = useQuery<ServerAlertSummary, ServerAlertSummaryVariables>(SERVER_QUERY, {
    variables: { serverId },
    skip: !serverId,
  });

  if (loading || error || serverLoading || serverError) {
    return <Loading error={!!error} />;
  }

  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(newTerm: string) => {
        setSearchTerm(newTerm);
      }}
    />
  );
  const membership = data.getOrganizationMembershipCurrentUser;

  const { slackIntegration, pagerdutyIntegration } =
    data.getOrganizationDetails;

  const {
    slack: slackNotificationConfig,
    pagerduty: pagerdutyNotificationConfig,
  } = serverData?.getServerDetails.notificationConfig ?? {};

  const checks: Check[] = data.getChecks
    .map((check) => {
      const checkShorthand = check.groupName + "/" + check.checkName;
      const policySetting = membership.alertPolicy.settings.find(
        (s) => s.checkName === checkShorthand,
      );

      const emailAlert = policySetting
        ? policySetting.immediate
          ? "Yes"
          : "No"
        : "n/a";
      const slackAlert = !!slackIntegration
        ? formatPartiallyEnabledCode(check.notifySlack)
        : "n/a";

      const checkGoesCritical =
        checkMaxSeverity(check.groupName, check.checkName) === "critical";
      const pagerdutyAlert =
        checkGoesCritical && !!pagerdutyIntegration
          ? formatPartiallyEnabledCode(check.notifyPagerduty)
          : "n/a";
      const enabled = formatPartiallyEnabledCode(check.enabled);

      return {
        ...check,
        checkTitle: CHECK_INFO[checkShorthand]?.title,
        checkTitleDisplay: CHECK_INFO[checkShorthand]?.titleDisplay,
        emailAlert,
        slackAlert,
        pagerdutyAlert,
        enabled,
      };
    })
    .filter((c) => {
      return (
        searchTerm === "" || c.checkTitle.toLowerCase().includes(searchTerm)
      );
    });

  const columns: GridColumn<
    (typeof checks)[number],
    keyof (typeof checks)[number]
  >[] = [
    {
      field: "checkTitle",
      header: "Check",
      renderer: function CheckTitleCell({ rowData }) {
        return (
          <Link
            to={
              serverId
                ? serverCheckConfigure(
                    serverId,
                    rowData.groupName,
                    rowData.checkName,
                  )
                : organizationCheckConfigure(
                    organizationSlug,
                    rowData.groupName,
                    rowData.checkName,
                  )
            }
          >
            {rowData.checkTitleDisplay}
          </Link>
        );
      },
    },
    { field: "enabled", header: "Check Enabled?" },
  ];
  if (slackIntegration) {
    columns.push({
      field: "slackAlert",
      header: "Slack Alert?",
      renderer: AlertEnabledRenderer,
    });
  }
  if (pagerdutyIntegration) {
    columns.push({
      field: "pagerdutyAlert",
      header: "PagerDuty Alert?",
      renderer: AlertEnabledRenderer,
    });
  }
  columns.push({
    field: "emailAlert",
    header: "Email Alert?",
    renderer: AlertEnabledRenderer,
  });

  const gridClassName =
    slackIntegration && pagerdutyIntegration
      ? "grid grid-cols-[minmax(200px,2fr)_repeat(4,1fr)]"
      : slackIntegration || pagerdutyIntegration
      ? "grid grid-cols-[minmax(250px,2fr)_repeat(3,1fr)]"
      : "grid grid-cols-[minmax(300px,3fr)_repeat(2,1fr)]";

  return (
    <>
      <Panel title="All Checks" secondaryTitle={titleSearch}>
        <PanelSection>
          <PanelItems>
            {slackIntegration && (
              <PanelItem
                className={styles.integrationPanelItem}
                label="Slack alerts"
              >
                <SlackIntegrationStatus
                  integration={slackIntegration}
                  serverId={serverId}
                  channelName={slackNotificationConfig?.channelName}
                />
              </PanelItem>
            )}
            {pagerdutyIntegration && (
              <PanelItem
                className={styles.integrationPanelItem}
                label="PagerDuty alerts"
              >
                <PagerDutyIntegrationStatus
                  integration={pagerdutyIntegration}
                  serverId={serverId}
                  serviceName={pagerdutyNotificationConfig?.serviceName}
                  organizationSlug={organizationSlug}
                />
              </PanelItem>
            )}
            <PanelItem
              className={styles.integrationPanelItem}
              label="E-mail alerts"
            >
              According to your current Alert Policy (
              <Link
                to={organizationAlertPolicy(
                  organizationSlug,
                  membership.alertPolicy.id,
                )}
              >
                <strong>{membership.alertPolicy.policyName}</strong>
              </Link>
              ).
            </PanelItem>
          </PanelItems>
          <AlertConfig
            organizationSlug={organizationSlug}
            membershipId={membership.id}
            serverId={serverId}
            hasIntegrations={!!slackIntegration || !!pagerdutyIntegration}
          />
        </PanelSection>
        <Grid
          className={gridClassName}
          data={checks}
          defaultSortBy="checkTitle"
          striped
          columns={columns}
        />
      </Panel>
      <AlertPolicyList organizationSlug={organizationSlug} />
    </>
  );
};

function formatPartiallyEnabledCode(code: string): string {
  if (code === "enabled_for_all") {
    return "Yes";
  } else if (code === "disabled_for_all") {
    return "No";
  } else if (code === "partially_enabled") {
    return "Partially enabled";
  } else {
    return "Unknown";
  }
}

const AlertEnabledRenderer: React.FunctionComponent<{
  fieldData: string;
}> = ({ fieldData }) => {
  if (fieldData === "n/a") {
    return <span className="text-[#999]">{fieldData}</span>;
  }

  return <>{fieldData}</>;
};

const IconConfigure = () => <FontAwesomeIcon icon={faCog} />;

const SlackIntegrationStatus: React.FunctionComponent<{
  integration: SlackIntegration | undefined;
  serverId: string | null;
  channelName: string | undefined;
}> = ({ integration, serverId, channelName }) => {
  if (!integration) {
    return <ConnectToSlack />;
  }
  const configInfo = channelName ? (
    <>
      Connected to <strong>#{channelName}</strong>
    </>
  ) : serverId ? (
    "Connected, but no channel set up"
  ) : (
    "Connected"
  );

  return (
    <div>
      <SlackLogo /> {configInfo}
    </div>
  );
};

const PagerDutyIntegrationStatus: React.FunctionComponent<{
  organizationSlug: string;
  integration: PagerDutyIntegration | undefined;
  serverId: string | null;
  serviceName: string | undefined;
}> = ({ organizationSlug, integration, serverId, serviceName }) => {
  if (!integration) {
    return <ConnectToPagerDuty organizationSlug={organizationSlug} />;
  }

  const configInfo = serviceName ? (
    <>
      Connected to <strong>{serviceName}</strong>
    </>
  ) : serverId ? (
    "Connected, but no service set up"
  ) : (
    "Connected"
  );

  return (
    <div>
      <PagerDutyLogo /> {configInfo}
    </div>
  );
};

const AlertConfig: React.FunctionComponent<{
  organizationSlug: string;
  serverId: string;
  membershipId: string;
  hasIntegrations: boolean;
}> = ({ organizationSlug, membershipId, serverId, hasIntegrations }) => {
  const { organizationMember, serverAlertConfig, organizationIntegrations } =
    useRoutes();
  return (
    <div>
      <IconConfigure />{" "}
      <Link to={organizationIntegrations(organizationSlug)}>
        Manage alerting integrations
      </Link>{" "}
      {hasIntegrations && (
        <>
          |{" "}
          {serverId ? (
            <Link to={serverAlertConfig(serverId)}>
              Configure alerts for this server
            </Link>
          ) : (
            <>Select a server to configure integration alerts</>
          )}{" "}
        </>
      )}
      |{" "}
      <Link to={organizationMember(organizationSlug, membershipId)}>
        Change your e-mail alert policy
      </Link>
    </div>
  );
};

export default AlertSummary;
