import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import moment from "moment-timezone";

import ConnectionGraph from "components/ConnectionGraph";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import {
  ServerConnections as ServerConnectionsType,
  ServerConnectionsVariables,
} from "./types/ServerConnections";
import QUERY from "./Query.graphql";
import ConnectionsPerDatabase from "./ConnectionsPerDatabase";
import { retention } from "utils/limits";
import { WithDateRange } from "components/WithDateRange";
import DateRangeBar from "components/DateRangeBar";

const ServerConnections: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const [selectedTimestamp, setSelectedTimestamp] = useState(null);
  const { data, loading, error } = useQuery<
    ServerConnectionsType,
    ServerConnectionsVariables
  >(QUERY, {
    variables: {
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { lastSnapshotAt, name } = data.getServerDetails;
  const timestamp = selectedTimestamp || lastSnapshotAt;

  const handleClick = (timestamp: Date): void => {
    setSelectedTimestamp(moment(timestamp).unix());
  };

  if (!timestamp) {
    return (
      <PageContent
        title="Connections"
        pageCategory="server"
        pageName="connections"
      >
        <Panel title="History">
          <PanelSection>
            No data available. If you added this server recently you may need to
            wait a bit.
          </PanelSection>
        </Panel>
      </PageContent>
    );
  }

  return (
    <WithDateRange initialHorizon={retention.connections}>
      <PageContent
        title="Connections"
        pageControls={<DateRangeBar feature="Connections" />}
        windowTitle={`Connections: ${name}`}
        pageCategory="server"
        pageName="connections"
      >
        <Panel title="History">
          <ConnectionGraph serverId={serverId} onClick={handleClick} />
        </Panel>
        <ConnectionsPerDatabase serverId={serverId} timestamp={timestamp} />
      </PageContent>
    </WithDateRange>
  );
};

export default ServerConnections;
