import React from "react";

import UsageBar from "components/UsageBar";

const ResourceUsageBar: React.FunctionComponent<{
  usage: number;
  limit: number;
  label: string;
  formatUsage?: (value: number) => string;
  formatLimit?: (value: number) => string;
  description: string;
}> = ({
  usage,
  limit,
  label,
  formatUsage = String,
  formatLimit = String,
  description,
}) => {
  return (
    <div>
      <div className="flex justify-between">
        <span>{label}</span>
        <span>
          <strong>{formatUsage(usage)}</strong> of {formatLimit(limit)}{" "}
          {description}
        </span>
      </div>
      <UsageBar usage={usage} limit={limit} />
    </div>
  );
};

export default ResourceUsageBar;
