import React from "react";
import sortBy from "lodash/sortBy";

import { notNullOrUndefined } from "utils/array";

import {
  Data,
  StackedData,
  SeriesInfo,
  Scale,
  defined,
  valueDefined,
  yValue,
  ScreenLocation,
  InteractionPoint,
  xValue,
  findDataNearXY,
} from "./util";
import Tooltip from "./Tooltip";
import Mouse from "./Mouse";
import { StackedHoverMarker, HoverMarker } from "./Series";
import UndefinedRegions from "./UndefinedRegions";

import styles from "./style.module.scss";
import Swatch from "./Swatch";

type Props = {
  width: number;
  height: number;
  data: Data;
  xScale: Scale;
  stackedData: StackedData;
  onClick?: (target: InteractionPoint) => void;
  onRangeSelected?: (start: InteractionPoint, end: InteractionPoint) => void;
  series: SeriesInfo[];
  small?: boolean;
};

const Foreground: React.FunctionComponent<Props> = ({
  series,
  data,
  stackedData,
  xScale,
  width,
  height,
  onClick,
  onRangeSelected,
  small = false,
}) => {
  const seriesData = new Map(
    series.filter((s) => !s.disabled).map((s) => [s, data[s.key]]),
  );

  const findInteractionPoint = (loc: ScreenLocation): InteractionPoint => {
    const nearbyData = findDataNearXY(seriesData, loc, xScale);
    const nearbyXVals = nearbyData.map((d) => {
      const domainVal = xValue(d.datum);
      const rangeVal = xScale(domainVal);
      return [domainVal, rangeVal];
    });
    const [nearestXDomain, nearestXRange] = sortBy(
      nearbyXVals,
      ([_domainVal, rangeVal]) => Math.abs(rangeVal - loc.x),
    )[0];

    return {
      bottom: {
        domain: nearestXDomain,
        range: nearestXRange,
      },
      nearby: nearbyData,
    };
  };

  const clipPathId = "seriesClipPath";
  return (
    <>
      <g pointerEvents="none">
        <clipPath id={clipPathId}>
          {/*
            N.B.: we tweak the clip path slightly to allow series to show
            values right at the top and to avoid spilling over axes
          */}
          <rect x={1} y={-1} width={width - 1} height={height + 1} />
        </clipPath>
        <g clipPath={`url(#${clipPathId})`}>
          <UndefinedRegions
            width={width}
            height={height}
            xScale={xScale}
            data={data}
          />
          {series.map((s) => {
            const SeriesComponent = s.type as React.ComponentType<unknown>;

            const props = {
              fill: s.fill,
              stroke: s.stroke,
              data: s.type.stacked ? stackedData[s.key] : data[s.key],
              xScale: xScale,
              yScale: s.scale,
              className: s.className,
              opts: s.opts,
            };
            return <SeriesComponent key={s.key} {...props} />;
          })}
        </g>
      </g>
      {!small && (
        <Mouse
          mapLocationToInteractionPoint={findInteractionPoint}
          onRangeSelected={onRangeSelected}
          onClick={onClick}
          width={width}
          height={height}
        >
          {(hover, rangeEndpoint) => {
            const tipLines =
              hover === undefined
                ? []
                : hover.nearby
                    .map((hoverRef) => {
                      const datum = hoverRef.datum;
                      const s = series.find((s) => s.key === hoverRef.series);
                      if (!s) {
                        return null;
                      }
                      const format = (v: number): string =>
                        s.tipFormat ? s.tipFormat(v) : String(v);
                      const val = yValue(datum);
                      const tipValue =
                        valueDefined(val) && typeof val === "number"
                          ? format(val)
                          : "N/A";
                      return (
                        <div className={styles.tooltipItem} key={s.key}>
                          <Swatch color={s.fill} />
                          <span className={styles.tooltipItemLabel}>
                            {s.tipLabel}
                          </span>
                          :{" "}
                          <span className={styles.tooltipItemValue}>
                            {tipValue}
                          </span>
                        </div>
                      );
                    })
                    .filter(notNullOrUndefined);

            return (
              <g pointerEvents="none">
                <Tooltip
                  tipLines={tipLines}
                  hover={hover}
                  rangeEndpoint={rangeEndpoint}
                  width={width}
                  height={height}
                />
                {hover &&
                  hover.nearby.map((hoverRef) => {
                    // we render hover markers after the tooltip because we don't
                    // want the tip or the tip line to obscure it
                    const s = series.find((s) => s.key === hoverRef.series);

                    if (!s || !defined(hoverRef.datum)) {
                      return null;
                    }
                    const props = {
                      xScale: xScale,
                      yScale: s.scale,
                      hover: hoverRef,
                      tooltipX: hover.bottom.range,
                    };
                    if (s.type.stacked) {
                      return (
                        <StackedHoverMarker
                          key={s.key}
                          {...props}
                          data={stackedData[s.key]}
                        />
                      );
                    } else {
                      return <HoverMarker key={s.key} {...props} />;
                    }
                  })}
              </g>
            );
          }}
        </Mouse>
      )}
    </>
  );
};

export default Foreground;
