import React from "react";

import CheckpointStarting from "./CheckpointStarting";
import TempFileCreated from "./TempFileCreated";
import { useDateRange } from "components/WithDateRange";
import moment from "moment-timezone";

type Props = {
  serverId: string;
  databaseId: string;
  classification: string;
  highlightedTime: moment.Moment;
};

const LogAnalysis: React.FunctionComponent<Props> = ({
  serverId,
  databaseId,
  classification,
  highlightedTime,
}) => {
  const [{ from, to }] = useDateRange();
  switch (classification) {
    case "W40":
      return (
        <CheckpointStarting
          serverId={serverId}
          databaseId={databaseId}
          startTs={from.unix()}
          endTs={to.unix()}
          highlightedTime={highlightedTime}
        />
      );
    case "S7":
      return (
        <TempFileCreated
          serverId={serverId}
          databaseId={databaseId}
          startTs={from.unix()}
          endTs={to.unix()}
          highlightedTime={highlightedTime}
        />
      );
  }

  return null;
};

export default React.memo(LogAnalysis);
