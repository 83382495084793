import React from "react";
import { useQuery } from "@apollo/client";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

import imgLogoAws from "../../../../docs/images/logo_aws.svg";
import imgLogoAzure from "../../../../docs/images/logo_azure.svg";
import imgLogoGcp from "../../../../docs/images/logo_google_cloud.svg";
import imgLogoPgsql from "../../../../docs/images/logo_postgres.svg";

import Form from "components/Form";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { ServerLocalCollectorSettings } from "components/ServerLocalCollectorSettings";
import { useAppConfig } from "components/WithAppConfig";
import { useRoutes } from "utils/routes";
import {
  ServerLocalCollectorNew as ServerLocalCollectorNewType,
  ServerLocalCollectorNewVariables,
} from "./types/ServerLocalCollectorNew";

import QUERY from "./Query.graphql";

import classNames from "classnames";
import { valueOrFirst } from "utils/array";

const ServerLocalCollectorNew: React.FunctionComponent = () => {
  const { organizationServers, organizationServersInstall } = useRoutes();
  const { slug: organizationSlug } = useParams();
  const { enterpriseEdition } = useAppConfig();
  const loc = useLocation();
  const qs = queryString.parse(loc.search);
  const systemType = valueOrFirst(qs.system_type);

  const { data } = useQuery<
    ServerLocalCollectorNewType,
    ServerLocalCollectorNewVariables
  >(QUERY, {
    variables: { organizationSlug },
  });
  const apiKey = data?.getApiKeys?.[0]?.token;

  if (!enterpriseEdition) {
    return (
      <Navigate to={organizationServersInstall(organizationSlug)} replace />
    );
  }

  return (
    <PageContent title="Add Server" pageCategory="servers" pageName="new">
      <div style={{ maxWidth: "1000px", fontSize: "16px" }}>
        <Panel title="Add Server">
          {!systemType ? (
            <PanelSection>
              <p>
                Please choose where you would like to run the pganalyze
                collector, which connects to your database server to collect
                metrics and logs.
              </p>
              <p>
                <strong>
                  Collector inside pganalyze Enterprise Server container
                </strong>
                <br />
                The easiest way to get started. This method requires that you
                run a single pganalyze Enterprise Server container, not multiple
                separate containers. This works best when monitoring a small
                environment on AWS, Azure or Google Cloud. This method does not
                support integrating Log Insights / Automated EXPLAIN for other
                providers.
              </p>
              <p>
                This is a pull-based method, where Postgres connections are made
                from the central installation going to your database
                environment.
              </p>
              <div className="mt-4 grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                <SystemTypeOption
                  systemType="amazon_rds"
                  img={imgLogoAws}
                  providerName="Amazon Web Services"
                  description="RDS and Aurora"
                />
                <SystemTypeOption
                  systemType="azure_database"
                  img={imgLogoAzure}
                  providerName="Microsoft Azure"
                  description="Flexible Server and Comsos DB"
                />
                <SystemTypeOption
                  systemType="google_cloudsql"
                  img={imgLogoGcp}
                  providerName="Google Cloud"
                  description="Cloud SQL and AlloyDB"
                />
                <SystemTypeOption
                  systemType="self_managed"
                  img={imgLogoPgsql}
                  providerName="Self-Managed"
                  description="VM, Container, On-Premise"
                />
              </div>
              <p className="!mt-6">
                <strong>Separate collector installation</strong>
                <br />
                For installations that have a more complex network setup,
                require customized collector settings, or are monitoring other
                managed database providers.
              </p>
              <p>
                This is a push-based method that submits data to the central
                installation using a collector API key. This method supports
                scaled out pganalyze Enterprise Server installations with
                multiple separate containers running.
              </p>
              <div className="mt-4 flex justify-left">
                <SystemTypeOption
                  className="max-w-xs"
                  systemType="external_collector"
                  img={imgLogoPgsql}
                  providerName="Any Supported Provider"
                  description="Full config options, scale out installations"
                />
              </div>
            </PanelSection>
          ) : systemType != "external_collector" ? (
            <>
              <PanelSection>
                <p>
                  <strong>
                    Collector inside pganalyze Enterprise Server container
                  </strong>{" "}
                  <Link to="">(change)</Link>
                </p>
                <p>
                  Please specify the connection information for the Postgres
                  server you'd like to monitor. The collector inside the
                  pganalyze Enterprise Server container will connect to your
                  database server using the Postgres protocol to fetch data.
                </p>
                <p>
                  You'll need to either set up a{" "}
                  <strong>
                    <a href="https://pganalyze.com/docs/install/amazon_rds/02_create_monitoring_user">
                      restricted monitoring user
                    </a>
                  </strong>{" "}
                  or specify superuser credentials.
                </p>
              </PanelSection>
              <Form
                action={organizationServers(organizationSlug)}
                method="post"
                className="text-[14px]"
              >
                <ServerLocalCollectorSettings systemType={systemType} />
                <div className="form-group">
                  <input
                    type="submit"
                    name="commit"
                    value="Add Server"
                    className="btn btn-success"
                    data-disable-with="Add Server"
                  />
                </div>
              </Form>
            </>
          ) : (
            <PanelSection>
              <p>
                <strong>Separate collector installation</strong>{" "}
                <Link to="">(change)</Link>
              </p>
              <p>
                Continue by following the cloud-based{" "}
                <a href="https://pganalyze.com/docs/install">
                  collector installation instructions
                </a>{" "}
                to install the collector either on your database server directly
                (for self-managed servers) or on a container/VM close to your
                database server.
              </p>
              <p>
                When configuring the collector, specify the following
                configuration settings in the collector config file:
              </p>
              <pre>
                {"[pganalyze]\napi_base_url = http://" +
                  window.location.hostname +
                  ":" +
                  (window.location.port || 80) +
                  "\napi_key = " +
                  (apiKey || "REPLACE_ME_REPLACE_ME")}
              </pre>
              <p>
                When running the collector in a container these settings are
                instead called <code>PGA_API_KEY</code> and{" "}
                <code>PGA_API_BASEURL</code>.
              </p>
              <p>
                You can find the collector API key on the Settings page. The API
                base URL needs to be reachable from the collector, and is the
                internal address of your pganalyze Enterprise Server
                installation.
              </p>
              <p>
                <a href="https://pganalyze.com/docs/collector/settings">
                  See details on all collector configuration settings.
                </a>
              </p>
              <p>
                <strong>Network connectivity requirements</strong>
                <br />
                The collector requires outbound network access to the pganalyze
                API (as specified by the <code>api_base_url</code>).
                Additionally it also requires connectivity to your database
                server.
              </p>
              <p>
                There are no connections initiated by the central pganalyze
                Enterprise Server container going into your database environment
                when using the separate collector installation, and no inbound
                ports need to be opened.
              </p>
            </PanelSection>
          )}
        </Panel>
      </div>
    </PageContent>
  );
};

function SystemTypeOption({
  className,
  systemType,
  providerName,
  description,
  img,
}: {
  className?: string;
  systemType: string;
  providerName: string;
  description: string;
  img: string;
}) {
  return (
    <Link
      className={classNames(
        className,
        "flex flex-col text-sm rounded-md border border-[#e8e8e8] bg-[#f8f8f8] text-inherit hover:text-inherit hover:border-[#e0e0e0] hover:bg-[#f0f0f0] items-start p-3 gap-3",
      )}
      to={`?system_type=${systemType}`}
    >
      <div className="flex items-center text-base font-semibold leading-5 h-[44px]  p-0 gap-3 ">
        <img
          className="w-[44px] h-auto m-0 p-0 border-none"
          src={img}
          alt={`${providerName} logo`}
        />
        {providerName}
      </div>
      <div className="flex leading-5 align-middle items-center">
        {description}
      </div>
    </Link>
  );
}

export default ServerLocalCollectorNew;
