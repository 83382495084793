import React, { useRef } from "react";

import CodeBlock from "../../../../docs/components/CodeBlock";
import CopyToClipboard from "components/CopyToClipboard";

import styles from "./style.module.scss";

const CopyCodeBlock = ({
  children,
  style,
}: {
  // ignored for now in this implementation but still accepted
  language?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>();
  // We pass a callback here since if the children re-render and the text
  // content changes (such as when we generate passwords), the parent
  // may not rerender and we would be stuck with stale content. By only
  // getting the content when it's requested, we can avoid that.
  const copyContent = () => ref.current?.textContent;
  return (
    <div className={styles.copyBlock}>
      <div ref={ref}>
        <CodeBlock style={style}>{children}</CodeBlock>
      </div>
      <CopyToClipboard
        content={copyContent}
        label="copy"
        className={styles.copyIcon}
      />
    </div>
  );
};

export default CopyCodeBlock;
