import React from "react";
import { Link } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import flowRight from "lodash/flowRight";
import groupBy from "lodash/groupBy";
import maxBy from "lodash/maxBy";
import meanBy from "lodash/meanBy";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import toPairs from "lodash/toPairs";
import moment, { Moment } from "moment-timezone";
import Color from "color";

import { formatBytes } from "utils/format";
import PanelSection from "components/PanelSection";
import SQL from "components/SQL";
import withLoading from "decorators/withLoading";

import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

import { TempFileCreated as TempFileCreatedType } from "./types/TempFileCreated";
import TimeBucketBar from "components/TimeBucketBar";
import { useRoutes } from "utils/routes";

type QueryType = {
  id: string;
  databaseId: string;
  truncatedQuery: string;
};

type LogStatsType = {
  count: number;
  timebucket: number;
  query: QueryType | null | undefined;
  avgSize: number;
};

type Props = {
  data: TempFileCreatedType;
  databaseId: string;
  serverId: string;
  startTs: number;
  endTs: number;
  highlightedTime: moment.Moment | null | undefined;
};

const TempFileCreated: React.FunctionComponent<Props> = ({
  data,
  serverId,
  startTs,
  endTs,
  highlightedTime,
}) => {
  const { serverConfigSetting } = useRoutes();
  const timeMin = startTs;
  const timeMax = endTs;

  const logStats = data.getLogAnalysisTempFileCreated.buckets;

  let logStatsByClassification = toPairs(
    groupBy(logStats, (l): string => (l.query && l.query.id) || "0"),
  );
  logStatsByClassification = sortBy(
    logStatsByClassification,
    ([, stats]): number => {
      return -sumBy(stats, (g): number => g.count);
    },
  );

  return (
    <PanelSection>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>
              <strong>Queries for Temp File Created:</strong>
            </td>
          </tr>
          {logStatsByClassification.map(([queryId, stats]) => (
            <QueryLogStats
              key={queryId}
              {...{ stats, queryId, timeMax, timeMin, highlightedTime }}
            />
          ))}
          <tr>
            <td className={styles.configSettingsTitle}>
              <strong>Related Config Parameters:</strong>
            </td>
          </tr>
          <tr>
            <td className={styles.configSetting}>
              <Link to={serverConfigSetting(serverId, "work_mem")}>
                work_mem
              </Link>{" "}
              = {data.workMemSetting?.value ?? "n/a"}
            </td>
          </tr>
        </tbody>
      </table>
    </PanelSection>
  );
};

const QueryLogStats: React.FunctionComponent<{
  stats: LogStatsType[];
  queryId: string;
  timeMin: number;
  timeMax: number;
  highlightedTime: moment.Moment;
}> = ({ stats, queryId, timeMin, timeMax, highlightedTime }) => {
  const { databaseQuery } = useRoutes();
  const maxCount = maxBy(stats, (g: LogStatsType): number => g.count).count;
  const totalCount = sumBy(stats, (g: LogStatsType): number => g.count);
  const avgSize = meanBy(stats, (g: LogStatsType): number => g.avgSize);
  const query = stats[0].query;

  const buckets = stats
    .filter((group) => group.timebucket >= timeMin)
    .map((group) => {
      const start = moment.unix(group.timebucket);
      return {
        start,
        value: group.count,
      };
    });
  const representativeGroup = stats[0];
  const TimeBucketTip: React.FunctionComponent<{
    start: Moment;
    end: Moment;
    value: number;
  }> = ({ start, end, value }) => (
    <div className={styles.statsPopover}>
      <strong>{value}</strong> &times;{" "}
      {(representativeGroup.query && (
        <span>Query #{representativeGroup.query.id}</span>
      )) ||
        "Unknown Query"}
      <div className={styles.groupInfoTime}>
        {start.format("MMM DD hh:mma")}
        &mdash;
        {end.format("hh:mma zz")}
      </div>
    </div>
  );

  const from = moment.unix(timeMin);
  const to = moment.unix(timeMax);
  const bucketSize = moment.duration(
    to.diff(from, "days", true) <= 3 ? 10 : 60,
    "minutes",
  );
  return (
    <>
      <tr key={queryId + "_title"}>
        <td className={styles.classificationTitle}>
          {totalCount} &times;{" "}
          {(query && (
            <Link to={databaseQuery(query.databaseId, query.id)}>
              Query #{query.id}
            </Link>
          )) ||
            "Unknown Query"}
        </td>
      </tr>
      <tr key={queryId}>
        <td>
          <div className={styles.classificationGroups}>
            <TimeBucketBar
              buckets={buckets}
              bucketSize={bucketSize}
              maxValue={maxCount}
              color={Color("#FF7F50")}
              from={from}
              to={to}
              highlight={highlightedTime}
              tip={TimeBucketTip}
            />
          </div>
          <div className={styles.classificationDetails}>
            <strong>Avg. Temp File Size</strong>: {formatBytes(avgSize)},{" "}
            <strong>Query:</strong>{" "}
            {(query && <SQL sql={query.truncatedQuery} inline />) || "Unknown"}
          </div>
        </td>
      </tr>
    </>
  );
};

export default flowRight(graphql(QUERY), withLoading)(TempFileCreated);
