import React from "react";

import { Link } from "react-router-dom";
import moment from "moment";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";
import { formatDateMonthDay } from "utils/format";

type Props = {
  organizationSlug: string;
  planName: string;
  showPlanComparisonAsUpgrade: boolean;
  planMarketingLines: string[];
  upgradeMarketingLines: string[];
  subscriptionCancelationEffectiveAt: number;
};

const PlanSummaryPanel: React.FunctionComponent<Props> = ({
  organizationSlug,
  planName,
  showPlanComparisonAsUpgrade,
  planMarketingLines,
  upgradeMarketingLines,
  subscriptionCancelationEffectiveAt,
}) => {
  const { organizationSubscriptionComparePlans } = useRoutes();
  return (
    <Panel
      title="Current Plan"
      secondaryTitle={
        <Link to={organizationSubscriptionComparePlans(organizationSlug)}>
          Compare all plans
        </Link>
      }
    >
      {subscriptionCancelationEffectiveAt && (
        <PanelSection>
          Your subscription ends{" "}
          {formatDateMonthDay(moment.unix(subscriptionCancelationEffectiveAt))}.{" "}
          <Link to={organizationSubscriptionComparePlans(organizationSlug)}>
            Restart your subscription.
          </Link>
        </PanelSection>
      )}
      <PanelSection>
        <div className="text-gray-900 text-xl mb-2 font-medium">{planName}</div>
        <div className="grid grid-flow-col auto-cols-fr">
          <div>
            {upgradeMarketingLines.length > 0 && (
              <div className="text-gray-900 font-semibold">Included</div>
            )}
            <ul>
              {planMarketingLines.map((line, i) => (
                <li key={i}>{line}</li>
              ))}
            </ul>
          </div>
          {upgradeMarketingLines.length > 0 && (
            <div>
              <div className="text-gray-900 font-semibold">Not included</div>
              <ul>
                {upgradeMarketingLines.map((line, i) => (
                  <li key={i}>{line}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </PanelSection>
      <PanelSection>
        {showPlanComparisonAsUpgrade ? (
          <Link to={organizationSubscriptionComparePlans(organizationSlug)}>
            <button className="btn btn-success">Upgrade</button>
          </Link>
        ) : (
          <Link to={organizationSubscriptionComparePlans(organizationSlug)}>
            Change plan
          </Link>
        )}
      </PanelSection>
    </Panel>
  );
};

export default PlanSummaryPanel;
