import React from "react";

import IssueList from "components/IssueList";

import {
  FollowerIssueList as FollowerIssueListType,
  FollowerIssueListVariables,
} from "./types/FollowerIssueList";
import QUERY from "./Query.graphql";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";

type Props = {
  serverId: string;
  referentId: string;
};

const FollowerIssueList: React.FunctionComponent<Props> = ({
  serverId,
  referentId,
}) => {
  const { data, loading, error } = useQuery<
    FollowerIssueListType,
    FollowerIssueListVariables
  >(QUERY, {
    variables: {
      serverId,
      referentId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return <IssueList serverId={serverId} issues={data.getIssues} />;
};

export default FollowerIssueList;
