import React, { useEffect, useRef, useState } from "react";

/**
 * The LazyLoader aids in lazy-loading large collections. It works by placing a
 * sentinel element after its children, and watching for that element to come
 * into view. Once it does, the LazyLoader invokes the loadMore callback. Once
 * more data is loaded, a new sentinel is set up and the process repeats until
 * the callback returns false, indicating no more data.
 */
const LazyLoader: React.FunctionComponent<{
  /** the number of rows that have been loaded from this collection */
  loadedCount: number;
  /** callback to load more data: called with currently loaded row count, and
   * should return whether more data is available to load */
  loadMore: (loadedCount: number) => Promise<boolean>;
  children: React.ReactNode;
}> = ({ loadedCount, loadMore, children }) => {
  const sentinelRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    const currSentinel = sentinelRef.current;
    let observer: IntersectionObserver;
    if (hasMore && currSentinel) {
      observer = new IntersectionObserver(async (entries) => {
        // We always expect just a single entry
        const entry = entries[0];
        if (entry.isIntersecting) {
          const result = await loadMore(loadedCount);
          if (result !== hasMore) {
            setHasMore(result);
          }
        }
      });
      observer.observe(currSentinel);
    }

    return () => {
      if (!observer || !currSentinel) {
        return;
      }
      observer.unobserve(currSentinel);
    };
  }, [hasMore, loadMore, loadedCount]);

  return (
    <div className="relative">
      {children}
      <div ref={sentinelRef} key={String(loadedCount)} className="absolute " />
    </div>
  );
};

export default LazyLoader;
