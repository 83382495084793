import React, { useContext } from "react";

import { useQuery } from "@apollo/client";
import Loading from "components/Loading";

import QUERY from "./Query.graphql";

import { WithAppConfig as WithAppConfigType } from "./types/WithAppConfig";

export type AppConfigType = Omit<
  WithAppConfigType["getAppConfig"],
  "__typename" | "id"
>;

export const AppConfigContext = React.createContext<AppConfigType | undefined>(
  undefined,
);

const WithAppConfig = ({ children }: { children: React.ReactNode }) => {
  const { loading, data, error } = useQuery<WithAppConfigType, never>(QUERY);

  if (loading || error || !data) {
    return <Loading error={!!error} />;
  }

  return (
    <AppConfigContext.Provider value={data.getAppConfig}>
      {children}
    </AppConfigContext.Provider>
  );
};

export function useAppConfig(): AppConfigType {
  const config = useContext(AppConfigContext);
  if (!config) {
    throw new Error("must have ancestor WithAppConfig component");
  }
  return config;
}

export default WithAppConfig;
