import React from "react";

import classNames from "classnames";

import { DialogOverlay, DialogContent } from "@reach/dialog";

export type ModalLayout = "right sidebar" | "centered";

export const ModalContent = ({
  children,
  className,
  layout,
}: {
  className?: string;
  children: React.ReactNode;
  layout?: ModalLayout;
}) => {
  const sharedClasses =
    "absolute top-0 right-0 left-0 bg-white p-0 m-0 shadow-md w-auto";
  const baseClasses =
    layout === "right sidebar"
      ? "min-h-screen md:ml-[300px]"
      : layout === "centered"
      ? "md:mx-[200px] my-[100px]"
      : undefined;

  const cn = classNames(sharedClasses, baseClasses, className);
  return <DialogContent className={cn}>{children}</DialogContent>;
};

export const ModalOverlay = ({
  children,
  className,
  onDismiss,
}: {
  className?: string;
  onDismiss: () => void;
  children: React.ReactNode;
}) => {
  const cn = classNames(
    "fixed top-0 right-0 bottom-0 left-0 overflow-scroll isolate z-10",
    className,
  );
  return (
    <DialogOverlay isOpen className={cn} onDismiss={onDismiss}>
      {children}
    </DialogOverlay>
  );
};
