export function makeFilter<T>(
  searchTerm: string,
  ...keys: (keyof T)[]
): (object: T) => boolean {
  return (object: T) => {
    if (searchTerm === "") {
      return true;
    }
    const lcTerm = searchTerm.toLowerCase();
    return keys.some(
      (k) => object[k]?.toString().toLowerCase().includes(lcTerm),
    );
  };
}
