import React from "react";

import { Annotation } from "types/explain";

import styles from "./style.module.scss";
import { Link } from "react-router-dom";

type Props = { annotations: Annotation[] };

const ExplainInsights: React.FunctionComponent<Props> = ({ annotations }) => {
  const topLevelInsights: string[] = [];
  const perNodeInsights = annotations.reduce<Map<number, string[]>>(
    (state, ann: Annotation) => {
      const nodeId = ann.node;
      if (!nodeId) {
        topLevelInsights.push(ann.detail);
        return state;
      }
      if (!state.has(nodeId)) {
        state.set(nodeId, []);
      }
      state.get(nodeId).push(ann.detail);
      return state;
    },
    new Map<number, string[]>(),
  );

  const allInsights: React.ReactElement[] = topLevelInsights
    .map((insight) => <>{insight}</>)
    .concat(
      Array.from(perNodeInsights).map(([nodeId, annotations]) => {
        return (
          <Link key={nodeId} to={"#node-" + nodeId}>
            <span className={styles.insightsNodeId}>{nodeId}</span>{" "}
            {joinMaybeWithAnd(annotations)}
          </Link>
        );
      }),
    );

  const insightsContent =
    allInsights.length > 0 ? (
      <ul>
        {allInsights.map((insight, idx) => (
          <li key={idx}>{insight}</li>
        ))}
      </ul>
    ) : (
      <div>No automatic insights available.</div>
    );

  return (
    <div className={styles.insights}>
      <h4 className="text-[#606060] ">Insights ({allInsights.length})</h4>
      <ul>{insightsContent}</ul>
    </div>
  );
};

const joinMaybeWithAnd = (items: string[]): string => {
  if (items.length === 0) {
    return null;
  } else if (items.length === 1) {
    return items[0];
  } else if (items.length === 2) {
    return items.join(" and ");
  } else {
    return (
      items.slice(0, items.length - 1).join(", ") +
      ", and " +
      items[items.length - 1]
    );
  }
};

export default ExplainInsights;
