import React from "react";

import {
  formatBytes,
  formatDurationPrecise,
  formatNumber,
  formatPercent,
} from "utils/format";

import PanelTileGroup, { PanelTile } from "components/PanelTileGroup";
import Tip from "components/Tip";
import { VacuumAdvisorTabType } from "..";
import {
  GetVacuumAdvisorData_getServerDetails as ServerDetailsType,
  GetVacuumAdvisorData_getPostgresSettings as SettingType,
} from "../types/GetVacuumAdvisorData";

const VacuumServerKPITiles: React.FunctionComponent<{
  loading: boolean;
  tab: VacuumAdvisorTabType;
  serverDetails: ServerDetailsType;
  settings: SettingType[];
}> = ({ loading, tab, serverDetails, settings }) => {
  const {
    xactPerSecSummary,
    totalBloat,
    txidUtilizationSummary,
    autovacuumAvgWorkerCount,
    oldestXminAssignedAgo,
    vacuumCount,
    integratedLogInsights,
  } = serverDetails ?? {};
  const {
    estimatedTableBytes,
    idealTableBytes,
    numTotalTables,
    numTablesWithStats,
  } = totalBloat ?? {};
  const {
    autovacuumCount,
    antiWraparoundAutovacuumCount,
    totalVacuumRunsCount,
    manualVacuumRunsCount,
    skippedCount,
  } = vacuumCount ?? {};
  const autovacuumMaxWorker = settings?.find(
    (ps) => ps.name === "autovacuum_max_workers",
  )?.resetValue;
  const logAutovacuumMinDuration = settings?.find(
    (ps) => ps.name === "log_autovacuum_min_duration",
  )?.resetValue;
  const autovacuumFreezeMaxAge = settings?.find(
    (ps) => ps.name === "autovacuum_freeze_max_age",
  )?.resetValue;
  const vacuumFreezeMinAge = settings?.find(
    (ps) => ps.name === "vacuum_freeze_min_age",
  )?.resetValue;
  const txidWraparoundInterval = calculateVacuumInterval(
    autovacuumFreezeMaxAge,
    vacuumFreezeMinAge,
    xactPerSecSummary,
  );
  return (
    <PanelTileGroup className="mb-5">
      {tab === "overview" && (
        <>
          <KPIAvgAutovacuumWorkers
            loading={loading}
            autovacuumAvgWorker={autovacuumAvgWorkerCount}
            autovacuumMaxWorker={autovacuumMaxWorker}
          />
          <KPIAutovacuumCount
            loading={loading}
            totalVacuumRunsCount={totalVacuumRunsCount}
            manualVacuumRunsCount={manualVacuumRunsCount}
            autovacuumCount={autovacuumCount}
            integratedLogInsights={integratedLogInsights}
            logAutovacuumMinDuration={logAutovacuumMinDuration}
          />
          <KPIAntiWraparoundAutovacuumCount
            loading={loading}
            autovacuumCount={autovacuumCount}
            antiWraparoundAutovacuumCount={antiWraparoundAutovacuumCount}
            integratedLogInsights={integratedLogInsights}
            logAutovacuumMinDuration={logAutovacuumMinDuration}
          />
        </>
      )}
      {tab === "bloat" && (
        <>
          <KPITableBloat
            loading={loading}
            estimatedBytes={estimatedTableBytes}
            idealBytes={idealTableBytes}
            numTotalTables={numTotalTables}
            numTablesWithStats={numTablesWithStats}
          />
          <KPITableCount loading={loading} numTotalTables={numTotalTables} />
          <KPIXminAssignedAge
            loading={loading}
            oldestXminAssignedAgo={oldestXminAssignedAgo}
          />
        </>
      )}
      {tab === "freezing" && (
        <>
          <KPIAntiWraparoundAutovacuumFrequency
            loading={loading}
            txidWraparoundInterval={txidWraparoundInterval}
          />
          <KPIXidUsageRate
            loading={loading}
            xactPerSecSummary={xactPerSecSummary}
          />
          <KPITXIDUtilization
            loading={loading}
            txidUtilizationSummary={txidUtilizationSummary}
          />
        </>
      )}
      {tab === "performance" && (
        <>
          <KPIAvgAutovacuumWorkers
            loading={loading}
            autovacuumAvgWorker={autovacuumAvgWorkerCount}
            autovacuumMaxWorker={autovacuumMaxWorker}
          />
          <KPIAutovacuumCount
            loading={loading}
            totalVacuumRunsCount={totalVacuumRunsCount}
            manualVacuumRunsCount={manualVacuumRunsCount}
            autovacuumCount={autovacuumCount}
            integratedLogInsights={integratedLogInsights}
            logAutovacuumMinDuration={logAutovacuumMinDuration}
          />
          <KPISkippedVacuumCount
            loading={loading}
            count={skippedCount}
            integratedLogInsights={integratedLogInsights}
          />
        </>
      )}
    </PanelTileGroup>
  );
};

const KPITableBloat: React.FunctionComponent<{
  loading: boolean;
  estimatedBytes: number;
  idealBytes: number;
  numTotalTables: number;
  numTablesWithStats: number;
}> = ({
  loading,
  estimatedBytes,
  idealBytes,
  numTotalTables,
  numTablesWithStats,
}) => {
  const bloatBytes = estimatedBytes - idealBytes;
  const bloatRatio = idealBytes === 0 ? 0 : bloatBytes / idealBytes;
  const hasBloatData = numTablesWithStats > 0 && bloatBytes >= 0;
  const bloatLabel = hasBloatData ? formatBytes(bloatBytes) : "—";
  return (
    <PanelTile
      className="min-h-[140px]"
      title="Est. Table Bloat"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl mr-2">{bloatLabel}</span>
        {hasBloatData && formatPercent(bloatRatio, 0)}
      </div>
      <div className="mt-2 whitespace-nowrap">
        {hasBloatData ? (
          <>
            based on {formatNumber(numTablesWithStats)} of{" "}
            {formatNumber(numTotalTables)} tables
          </>
        ) : (
          <>no table bloat data found</>
        )}
      </div>
    </PanelTile>
  );
};

const KPITableCount: React.FunctionComponent<{
  loading: boolean;
  numTotalTables: number;
}> = ({ loading, numTotalTables }) => {
  const tableCountLabel =
    numTotalTables == undefined ? "—" : formatNumber(numTotalTables);
  return (
    <PanelTile className="min-h-[140px]" title="Total Tables" loading={loading}>
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{tableCountLabel}</span>
      </div>
    </PanelTile>
  );
};

const KPIXidUsageRate: React.FunctionComponent<{
  loading: boolean;
  xactPerSecSummary: number;
}> = ({ loading, xactPerSecSummary }) => {
  // per-minute is likely to be a more useful rate for most of our users,
  // especially considering this only counts write transactions
  const xidsPerMinute =
    typeof xactPerSecSummary === "number" ? xactPerSecSummary * 60 : undefined;
  const xidRateLabel =
    xidsPerMinute == undefined ? "—" : formatNumber(xidsPerMinute);
  return (
    <PanelTile
      className="min-h-[140px]"
      title="Transaction ID Allocation"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{xidRateLabel}</span>
      </div>
      <div className="mt-2 whitespace-nowrap">
        per minute{" "}
        <Tip content="Rate of transaction ID allocation for this server. Transaction IDs must be allocated for each write transaction in the system. Higher allocation rates generally mean more freezing will be required." />
      </div>
    </PanelTile>
  );
};

const KPIAntiWraparoundAutovacuumFrequency: React.FunctionComponent<{
  loading: boolean;
  txidWraparoundInterval: string;
}> = ({ loading, txidWraparoundInterval }) => {
  return (
    <PanelTile
      className="min-h-[140px]"
      title="Anti-Wraparound Vacuum Frequency"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{txidWraparoundInterval ?? "-"}</span>
      </div>
      <div className="mt-2 whitespace-nowrap">
        for each table{" "}
        <Tip content="How often an anti-wraparound autovacuum needs to be run at minimum for each table on this server, based on (autovacuum_freeze_max_age - vacuum_freeze_min_age) divided by the average assigned transaction IDs per minute." />
      </div>
    </PanelTile>
  );
};

const KPITXIDUtilization: React.FunctionComponent<{
  loading: boolean;
  txidUtilizationSummary: number;
}> = ({ loading, txidUtilizationSummary }) => {
  const pctLabel =
    txidUtilizationSummary == undefined
      ? "—"
      : formatNumber(txidUtilizationSummary, 1);
  return (
    <PanelTile
      className="min-h-[140px]"
      title="Transaction ID Utilization"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{pctLabel} %</span>
      </div>
    </PanelTile>
  );
};

const KPIXminAssignedAge: React.FunctionComponent<{
  loading: boolean;
  oldestXminAssignedAgo: number;
}> = ({ loading, oldestXminAssignedAgo }) => {
  const ageLabel =
    oldestXminAssignedAgo == undefined
      ? "—"
      : formatNumber(oldestXminAssignedAgo / 60 / 60, 1);
  return (
    <PanelTile
      className="min-h-[140px]"
      title="Xmin Horizon Assigned"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{ageLabel}</span>
      </div>
      <div className="mt-2 whitespace-nowrap">
        hours ago{" "}
        <Tip content="How long ago the xmin horizon was assigned, indicating up to which point VACUUM can clean up dead rows. It is advisable to keep this number low to avoid additional overhead due to blocked VACUUMs that can't perform any cleanup." />
      </div>
    </PanelTile>
  );
};

const KPIAvgAutovacuumWorkers: React.FunctionComponent<{
  loading: boolean;
  autovacuumAvgWorker: number;
  autovacuumMaxWorker: string;
}> = ({ loading, autovacuumAvgWorker, autovacuumMaxWorker }) => {
  const avgWorker =
    autovacuumAvgWorker == undefined
      ? "—"
      : formatNumber(autovacuumAvgWorker, 2);
  return (
    <PanelTile
      className="min-h-[140px]"
      title="Avg. Autovacuum Workers"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{avgWorker}</span>
      </div>
      <div className="mt-2 whitespace-nowrap">
        out of {autovacuumMaxWorker} max workers{" "}
        <Tip content="The average number of autovacuum workers running concurrently in the last 24 hours. If this value is close to the max workers count, your autovacuum may be capacity constrained and you could have a vacuum backlog. Raising autovacuum_max_workers or adjusting cost settings is advised." />
      </div>
    </PanelTile>
  );
};

const KPIAutovacuumCount: React.FunctionComponent<{
  loading: boolean;
  totalVacuumRunsCount: number;
  manualVacuumRunsCount: number;
  autovacuumCount: number;
  integratedLogInsights: boolean;
  logAutovacuumMinDuration: string;
}> = ({
  loading,
  totalVacuumRunsCount,
  manualVacuumRunsCount,
  autovacuumCount,
  integratedLogInsights,
  logAutovacuumMinDuration,
}) => {
  // Only use autovacuumCount when it has accurate data
  const useAutovacuumCount =
    integratedLogInsights && logAutovacuumMinDuration === "0";

  const autovacuumCountFromVacuumRuns =
    totalVacuumRunsCount - manualVacuumRunsCount;
  const countLabel = formatNumber(
    useAutovacuumCount ? autovacuumCount : autovacuumCountFromVacuumRuns,
  );
  return (
    <PanelTile
      className="min-h-[140px]"
      title="Total Autovacuum Count"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{countLabel}</span>
      </div>
      <div className="mt-2 whitespace-nowrap">
        in the last 24 hours{" "}
        {!useAutovacuumCount && (
          <Tip content="Estimated count based on samples of vacuums actively running (collected every 10 seconds). Value may exclude very short autovacuums. Set log_autovacuum_min_duration to 0 to get accurate counts." />
        )}
      </div>
    </PanelTile>
  );
};

const KPIAntiWraparoundAutovacuumCount: React.FunctionComponent<{
  loading: boolean;
  autovacuumCount: number;
  antiWraparoundAutovacuumCount: number;
  integratedLogInsights: boolean;
  logAutovacuumMinDuration: string;
}> = ({
  loading,
  autovacuumCount,
  antiWraparoundAutovacuumCount,
  integratedLogInsights,
  logAutovacuumMinDuration,
}) => {
  const noDataAvailable =
    !integratedLogInsights || logAutovacuumMinDuration === "-1";
  const countLabel = noDataAvailable
    ? "-"
    : formatNumber(antiWraparoundAutovacuumCount);
  const tip = antiWraparoundCountTip(
    integratedLogInsights,
    logAutovacuumMinDuration,
  );

  return (
    <PanelTile
      className="min-h-[140px]"
      title="Total Anti-Wraparound Autovacuums"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{countLabel}</span>
        {!noDataAvailable && ` out of ${formatNumber(autovacuumCount)}`}
      </div>
      <div className="mt-2 whitespace-nowrap">in the last 24 hours {tip}</div>
    </PanelTile>
  );
};

const KPISkippedVacuumCount: React.FunctionComponent<{
  loading: boolean;
  count: number;
  integratedLogInsights: boolean;
}> = ({ loading, count, integratedLogInsights }) => {
  const countLabel = integratedLogInsights ? formatNumber(count) : "-";
  return (
    <PanelTile
      className="min-h-[140px]"
      title="Skipped Autovacuums"
      loading={loading}
    >
      <div className="mt-3 whitespace-nowrap">
        <span className="text-2xl">{countLabel}</span>
      </div>
      <div className="mt-2 whitespace-nowrap">
        skipped due to locks in the last 24 hours{" "}
        {integratedLogInsights ? (
          <Tip content="Regular autovacuums are skipped when another connection holds a conflicting lock or acquires a conflicting lock while the vacuum is running. This excludes anti-wraparound autovacuums, which will always acquire and hold necessary locks instead of skipping." />
        ) : (
          <Tip content="Log Insights is not enabled, no data available." />
        )}
      </div>
    </PanelTile>
  );
};

function antiWraparoundCountTip(
  integratedLogInsights: boolean,
  logAutovacuumMinDuration: string,
): React.ReactNode {
  const noLogInsightsTip = "Log Insights is not enabled, no data available.";
  const noLogAutovacuumMinDurationTip =
    "log_autovacuum_min_duration is set to -1, no data available.";
  const nonZeroLogAutovacuumMinDurationTip =
    "Only shows autovacuums that exceed log_autovacuum_min_duration. Data is likely inaccurate for very fast autovacuums. Set log_autovacuum_min_duration to 0 for best accuracy.";
  let tip = <></>;
  if (integratedLogInsights) {
    if (logAutovacuumMinDuration === "-1") {
      tip = <Tip content={noLogAutovacuumMinDurationTip} />;
    } else if (logAutovacuumMinDuration !== "0") {
      tip = <Tip content={nonZeroLogAutovacuumMinDurationTip} />;
    }
  } else {
    tip = <Tip content={noLogInsightsTip} />;
  }
  return tip;
}

// Similar function exists in FreezingStats/XactPerSecGraph/index.tsx
function calculateVacuumInterval(
  maxAge: string,
  minAge: string,
  tps: number,
): string | null {
  if (tps == null || tps === 0) {
    return null;
  }
  const minAgeVal = parseInt(minAge, 10);
  const maxAgeVal = parseInt(maxAge, 10);
  if (isNaN(minAgeVal) || isNaN(maxAgeVal)) {
    return null;
  }
  const intervalInMs = ((maxAgeVal - minAgeVal) / tps) * 1000;

  return formatDurationPrecise(intervalInMs);
}

export default VacuumServerKPITiles;
