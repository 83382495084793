You can enable `auto_explain` for your Crunchy Bridge cluster by running (as Postgres superuser):

```
ALTER SYSTEM SET session_preload_libraries = 'auto_explain';
SELECT pg_reload_conf();
```

Subsequent Postgres sessions will then be configured with those settings and
will automatically log the EXPLAIN plan for any qualifying statements to your Postgres logs.

Before proceeding to the next step, start a new Postgres session to load the
`auto_explain` module.
