import PageContent from "components/PageContent";
import Panel from "components/Panel";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import QUERY from "./Query.graphql";
import CREATE_MUTATION from "./Mutation.create.graphql";
import DELETE_MUTATION from "./Mutation.delete.graphql";
import WORKBOOK_MUTATION from "../ExplainVariantSidebar/Mutation.workbook.graphql";
import WORKBOOK_LIST_QUERY from "../Query.graphql";
import {
  QuerySamplesForWorkbook,
  QuerySamplesForWorkbookVariables,
} from "./types/QuerySamplesForWorkbook";
import { formatTimestampShort } from "utils/format";
import moment from "moment";
import Grid, { MsCell } from "components/Grid";
import SQL from "components/SQL";
import Identicon from "components/Identicon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/pro-regular-svg-icons";
import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";
import ExpandableSQL from "components/ExpandableSQL";
import FilterSearch from "components/FilterSearch";
import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import Loading from "components/Loading";
import {
  CreateExplainParameterSets,
  CreateExplainParameterSetsVariables,
} from "./types/CreateExplainParameterSets";
import {
  DeleteExplainParameterSets,
  DeleteExplainParameterSetsVariables,
} from "./types/DeleteExplainParameterSets";
import {
  aliasParamMapToString,
  jsonParametersToString,
} from "../ExplainWorkbook/util";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import CreateParameterSetPanel from "../CreateParameterSetPanel";
import EditParameterSetPanel from "../EditParameterSetPanel";
import {
  DeleteExplainWorkbook,
  DeleteExplainWorkbookVariables,
} from "../ExplainVariantSidebar/types/DeleteExplainWorkbook";
import Button from "components/Button";

const SelectParameterSets = ({
  workbook,
}: {
  workbook: ExplainWorkbookType;
}) => {
  const { databaseId } = useParams();
  const [sampleIds, setSampleIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showCreateParameterSetPanel, setShowCreateParameterSetPanel] =
    useState(false);
  const [parameterSetIdForEdit, setParameterSetIdForEdit] = useState(null);
  const {
    databaseQueryExplain,
    databaseWorkbooks,
    databaseWorkbookVariantRun,
  } = useRoutes();
  const navigate = useNavigate();
  const baselineQuery = workbook.baselineQuery;

  const [createExplainParameterSets] = useMutation<
    CreateExplainParameterSets,
    CreateExplainParameterSetsVariables
  >(CREATE_MUTATION);
  const [deleteExplainParameterSets] = useMutation<
    DeleteExplainParameterSets,
    DeleteExplainParameterSetsVariables
  >(DELETE_MUTATION);
  const [deleteExplainWorkbook] = useMutation<
    DeleteExplainWorkbook,
    DeleteExplainWorkbookVariables
  >(WORKBOOK_MUTATION);

  const { loading, error, data } = useQuery<
    QuerySamplesForWorkbook,
    QuerySamplesForWorkbookVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryFingerprint: baselineQuery.queryFingerprint,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const querySamples = data.getQuerySamples.filter(
    (sample) => sample.jsonParameters !== null,
  );
  const filteredData = querySamples.filter((sample) =>
    jsonParametersToString(sample.jsonParameters).includes(searchTerm),
  );
  const secondaryTitle = (
    <FilterSearch
      initialValue={searchTerm}
      onChange={setSearchTerm}
      placeholder="Search parameters..."
    />
  );

  function handleCreateDismiss() {
    setShowCreateParameterSetPanel(false);
  }

  function handleEditDismiss() {
    setParameterSetIdForEdit(null);
  }

  const handleRunExplain = () => {
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        querySampleIds: sampleIds,
        finalize: true,
      },
      refetchQueries: [
        {
          query: WORKBOOK_DETAIL_QUERY,
          variables: { workbookId: workbook.id, databaseId },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate(
          databaseWorkbookVariantRun(
            workbook.databaseId,
            workbook.id,
            baselineQuery.id,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleDeleteWorkbook = () => {
    deleteExplainWorkbook({
      variables: { workbookId: workbook.id },
      refetchQueries: [
        {
          query: WORKBOOK_LIST_QUERY,
          variables: {
            databaseId: workbook.databaseId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate(databaseWorkbooks(databaseId));
      },
    });
  };

  const queryWithNoParams =
    Object.keys(baselineQuery.paramRefAliasMap).length === 0;
  const selectedParameterSetsCount =
    workbook.parameterSets.length + Object.keys(sampleIds).length;
  const defaultExpandCustomParams =
    // there are custom params
    workbook.parameterSets.length > 0 ||
    // there are parameters but there is no query samples
    (Object.keys(baselineQuery.paramRefAliasMap).length > 0 &&
      querySamples.length === 0) ||
    queryWithNoParams;

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      title={workbook.name}
      pageCategory="explains"
      pageName="workbooks"
      layout="sidebar"
    >
      {/* main content */}
      <div className="rounded-md bg-[#f7fafc] border border-[#E8E8EE] p-4 mb-4 grid gap-2 text-[#606060]">
        <ExpandableSQL sql={baselineQuery.queryTextWithAlias} />
      </div>
      {querySamples.length > 0 && (
        <Panel
          title="Select Parameters from Query Samples"
          secondaryTitle={secondaryTitle}
        >
          <Grid
            className="grid-cols-[1fr_140px_140px]"
            data={filteredData}
            pageSize={5}
            defaultSortBy={"runtimeMs"}
            columns={[
              {
                field: "jsonParameters",
                header: "Query Sample",
                renderer: function ParametersCell({ rowData, fieldData }) {
                  const jsonParams = jsonParametersToString(fieldData);
                  return (
                    <div className="flex gap-2">
                      <div>
                        <input
                          type="checkbox"
                          id="sample_params"
                          checked={!!sampleIds.includes(rowData.id)}
                          onChange={(evt) => {
                            if (evt.target.checked) {
                              if (sampleIds.length > 4) {
                                setErrorMessage(
                                  "Only up to 5 samples can be selected",
                                );
                                return;
                              }
                              setSampleIds([...sampleIds, rowData.id]);
                            } else {
                              setSampleIds(
                                sampleIds.filter((id) => id !== rowData.id),
                              );
                            }
                          }}
                        />
                      </div>
                      <div title={jsonParams}>
                        <SQL className="!whitespace-nowrap" sql={jsonParams} />
                        <div className="text-[12px] text-[#606060]">
                          {formatTimestampShort(
                            moment.unix(rowData.occurredAt),
                          )}
                        </div>
                      </div>
                    </div>
                  );
                },
                disableSort: true,
              },
              {
                field: "explain",
                header: "Plan Fingerprint",
                renderer: function PlanFingerprintCell({ fieldData, rowData }) {
                  if (!fieldData) {
                    return "";
                  }
                  const fingerprint = fieldData.fingerprint;
                  return (
                    <Link
                      to={databaseQueryExplain(
                        databaseId,
                        baselineQuery.query.id,
                        rowData.explain.humanId,
                      )}
                    >
                      <Identicon identity={fingerprint} />
                      <span title={fingerprint}>
                        {fingerprint.substring(0, 7)}
                      </span>
                    </Link>
                  );
                },
              },
              {
                field: "runtimeMs",
                header: "Runtime",
                renderer: MsCell,
                defaultSortOrder: "desc",
              },
            ]}
          />
        </Panel>
      )}
      <Panel
        title="Custom Parameters"
        expandable
        defaultExpanded={defaultExpandCustomParams}
      >
        {queryWithNoParams ? (
          <PanelSection>No parameters with the query</PanelSection>
        ) : (
          <>
            <Grid
              className="grid-cols-[1fr_100px]"
              data={workbook.aliasParamMapList}
              noRowsText="No custom parameters"
              columns={[
                {
                  field: "parameters",
                  header: "Custom Parameters",
                  renderer: function ParametersCell({ fieldData }) {
                    return (
                      <div className="flex gap-2">
                        <div>
                          <input type="checkbox" checked disabled />
                        </div>
                        <SQL sql={aliasParamMapToString(fieldData)} />
                      </div>
                    );
                  },
                  className: "whitespace-normal",
                },
                {
                  field: "id",
                  header: "",
                  renderer: function DeleteParamCell({ fieldData }) {
                    return (
                      <>
                        <FontAwesomeIcon
                          icon={faEdit}
                          title="Edit"
                          className="text-[#337AB7] mr-3 cursor-pointer"
                          onClick={() => setParameterSetIdForEdit(fieldData)}
                        />
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          title="Delete"
                          className="text-[#CA1515] mr-1 cursor-pointer"
                          onClick={() => {
                            deleteExplainParameterSets({
                              variables: {
                                workbookId: workbook.id,
                                parameterSetId: fieldData,
                              },
                              onCompleted: () => {
                                setErrorMessage("");
                              },
                              onError: (error) => {
                                setErrorMessage(error.message);
                              },
                            });
                          }}
                        />
                      </>
                    );
                  },
                  className: "text-right",
                },
              ]}
            />
            <PanelSection>
              <button
                className="btn btn-primary"
                onClick={() => setShowCreateParameterSetPanel(true)}
              >
                Add Parameter Set
              </button>
            </PanelSection>
          </>
        )}
      </Panel>
      {errorMessage && <div className="text-[#FF0000]">{errorMessage}</div>}
      <div className="grid grid-cols-2 items-center gap-1">
        <div>{selectedParameterSetsCount} parameter sets selected</div>
        <div className="justify-self-end">
          <Button
            bare
            onClick={handleDeleteWorkbook}
            className="mr-4 !text-[#d43f3a] font-medium"
          >
            Cancel creation
          </Button>
          <button
            className="btn btn-success !px-4"
            disabled={!queryWithNoParams && selectedParameterSetsCount === 0}
            onClick={handleRunExplain}
          >
            Run EXPLAIN...
          </button>
        </div>
      </div>
      {showCreateParameterSetPanel && (
        <CreateParameterSetPanel
          onDismiss={handleCreateDismiss}
          workbook={workbook}
        />
      )}
      {parameterSetIdForEdit && (
        <EditParameterSetPanel
          onDismiss={handleEditDismiss}
          workbook={workbook}
          parameterSetId={parameterSetIdForEdit}
        />
      )}
      {/* sidebar */}
      <ParameterSetsSidebar />
    </PageContent>
  );
};

export const ParameterSetsSidebar = () => {
  return (
    <div className="w-[320px]">
      <h4 className="leading-7 mt-0">Parameter Sets</h4>
      <p>
        Parameter sets refer to the use of placeholders in SQL queries, allowing
        for the dynamic substitution of values at execution time to enhance
        security and performance. This technique helps prevent SQL injection
        attacks and improves query efficiency by reusing the execution plan with
        different parameter values.
      </p>
      <h4 className="leading-7">Query Samples</h4>
      <p>
        Query samples in PostgreSQL refer to examples or instances of SQL
        queries that are used for testing, optimization, or benchmarking
        purposes. These samples help in analyzing query performance, identifying
        potential bottlenecks, and ensuring that the database can handle various
        types of queries efficiently.
      </p>
    </div>
  );
};

export default SelectParameterSets;
