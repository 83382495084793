export function getIndexStructure(indexDef: string): string {
  return indexDef.replace(/.*USING/, "");
}

export function getIndexDdl(
  tableName: string,
  schemaName: string | undefined,
  indexDef: string,
): string {
  let fqn = "";
  if (schemaName) {
    fqn = quoteIdent(schemaName) + ".";
  }
  fqn += quoteIdent(tableName);

  return `CREATE INDEX CONCURRENTLY ON ${fqn} USING ${indexDef};`;
}

export function quoteIdent(ident: string, force = false): string {
  const skipQuote = !force && /^[a-z][a-z0-9_]+$/.test(ident);
  if (skipQuote) {
    return ident;
  }
  return `"${ident.replace(/"/, '\\"')}"`;
}
