import React from "react";

import className from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";

import PanelTileGroup, { PanelTile } from "components/PanelTileGroup";

import {
  formatBytes,
  formatNumber,
  formatPercent,
  formatTimeAgo,
} from "utils/format";

import styles from "./style.module.scss";
import Popover from "components/Popover";
import pluralize from "pluralize";
import Tip from "components/Tip";
import { InfoIcon } from "components/Icons";
import moment from "moment";

type Props = {
  loading: boolean;
  totalIndexWriteOverhead: DatabaseIndexWriteOverhead;
  totalDataSize: number;
  totalDataSize7dAgo: number;
  totalIndexSize: number;
};

type DatabaseIndexWriteOverhead = {
  tableWritesPerMinute: number;
  totalWriteOverhead: number;
  tablesWithMissingStats: number;
  tablesWithMissingIndexMetadata: number;
  tablesWithDefaultExprIndexOverhead: number;
  updatedAt: number;
};

const KPITiles: React.FunctionComponent<Props> = ({
  loading,
  totalIndexWriteOverhead,
  totalDataSize,
  totalDataSize7dAgo,
  totalIndexSize,
}) => {
  return (
    <PanelTileGroup className={styles.kpiTiles}>
      <KPIDataSize
        loading={loading}
        totalDataSize={totalDataSize}
        totalDataSize7dAgo={totalDataSize7dAgo}
      />
      <KPIIndexSize loading={loading} totalIndexSize={totalIndexSize} />
      <KPITableWrites
        loading={loading}
        writeCount={totalIndexWriteOverhead?.tableWritesPerMinute}
      />
      <KPIIndexWriteOverhead
        loading={loading}
        indexWriteOverhead={totalIndexWriteOverhead}
      />
    </PanelTileGroup>
  );
};

const KPIIndexWriteOverhead: React.FunctionComponent<{
  loading: boolean;
  indexWriteOverhead: DatabaseIndexWriteOverhead;
}> = ({ loading, indexWriteOverhead }) => {
  const overheadFactor = indexWriteOverhead?.totalWriteOverhead;
  const hasIncompleteEstimate =
    indexWriteOverhead &&
    (indexWriteOverhead.tablesWithDefaultExprIndexOverhead > 0 ||
      indexWriteOverhead.tablesWithMissingIndexMetadata > 0 ||
      indexWriteOverhead.tablesWithMissingStats > 0);
  const tablesMissingOverhead =
    (indexWriteOverhead?.tablesWithMissingIndexMetadata ?? 0) +
    (indexWriteOverhead?.tablesWithMissingStats ?? 0);
  const tablesWithDefaultExprtIndexOverhead =
    indexWriteOverhead?.tablesWithDefaultExprIndexOverhead;

  const currStr =
    overheadFactor == null ? "n/a" : formatNumber(overheadFactor, 2);
  return (
    <PanelTile
      className={styles.kpiTile}
      title="Avg. Index Write Overhead"
      loading={loading}
    >
      <div className={styles.kpiValueLine}>
        <span className={styles.kpiValue}>{currStr}</span>
      </div>
      <div className={styles.kpiDetailLine}>
        index bytes per table byte{" "}
        <Popover
          content={
            <>
              <p>
                Estimated cost of maintaining indexes in your database, as a
                proportion of writes to tables. Specifically, for every byte
                written to a table in this database, this many bytes are written
                to its indexes. This is a weighted average across all tables,
                weighted by average table writes per minute.
              </p>
              {hasIncompleteEstimate && (
                <p>
                  <strong>This estimate was based on incomplete data</strong>:{" "}
                  {tablesMissingOverhead > 0 && (
                    <>
                      Could not calculate index write overhead for{" "}
                      {pluralize("table", tablesMissingOverhead, true)}.
                    </>
                  )}
                  {tablesWithDefaultExprtIndexOverhead > 0 && (
                    <>
                      {" "}
                      A rough heuristic for expression indexes was used for
                      estimating overhead on{" "}
                      {pluralize(
                        "table",
                        tablesWithDefaultExprtIndexOverhead,
                        true,
                      )}
                      .
                    </>
                  )}
                </p>
              )}
            </>
          }
        >
          <InfoIcon />
        </Popover>
      </div>
    </PanelTile>
  );
};

const KPITableWrites: React.FunctionComponent<{
  loading: boolean;
  writeCount: number;
}> = ({ loading, writeCount }) => {
  const count = writeCount == null ? "n/a" : formatNumber(writeCount);
  return (
    <PanelTile
      className={styles.kpiTile}
      title="Table Writes"
      loading={loading}
    >
      <div className={styles.kpiValueLine}>
        <span className={styles.kpiValue}>{count}</span>
      </div>
      <div className={styles.kpiDetailLine}>per minute</div>
    </PanelTile>
  );
};

const KPIDataSize: React.FunctionComponent<{
  loading: boolean;
  totalDataSize: number;
  totalDataSize7dAgo: number;
}> = ({ loading, totalDataSize, totalDataSize7dAgo }) => {
  const delta = totalDataSize - totalDataSize7dAgo;
  const good = delta < 0;

  const currStr = formatBytes(totalDataSize);
  const deltaStr = formatBytes(Math.abs(delta));

  return (
    <PanelTile
      className={styles.kpiTile}
      title="Total Data Size"
      loading={loading}
    >
      <div className={styles.kpiValueLine}>
        <span className={styles.kpiValue}>{currStr}</span>
      </div>
      <div className={styles.kpiDetailLine}>
        <KPIDelta delta={deltaStr} increase={delta > 0} good={good} />
      </div>
    </PanelTile>
  );
};

const KPIIndexSize: React.FunctionComponent<{
  loading: boolean;
  totalIndexSize: number;
}> = ({ loading, totalIndexSize }) => {
  const currStr = formatBytes(totalIndexSize);

  return (
    <PanelTile
      className={styles.kpiTile}
      title="Total Index Size"
      loading={loading}
    >
      <div className={styles.kpiValueLine}>
        <span className={styles.kpiValue}>{currStr}</span>
      </div>
    </PanelTile>
  );
};

const KPIDelta: React.FunctionComponent<{
  delta: string;
  increase: boolean;
  good: boolean;
}> = ({ delta, increase, good }) => {
  return (
    <span
      className={className(
        styles.kpiDelta,
        good ? styles.kpiDeltaGood : styles.kpiDeltaBad,
      )}
    >
      <FontAwesomeIcon icon={increase ? faCaretUp : faCaretDown} /> {delta}
    </span>
  );
};

export const StatusKPITiles: React.FunctionComponent<{
  loading: boolean;
  analyzedCount: number;
  processedTables: number;
  totalTables: number;
  lastProcessed: number;
  analyzedQueriesPercentage: number;
}> = ({
  loading,
  analyzedCount,
  processedTables,
  totalTables,
  lastProcessed,
  analyzedQueriesPercentage,
}) => {
  return (
    <PanelTileGroup className={styles.kpiTiles}>
      <KPIQueriesAnalyzed loading={loading} analyzedCount={analyzedCount} />
      <KPITablesProcessed
        loading={loading}
        processedTables={processedTables}
        totalTables={totalTables}
        lastProcessed={lastProcessed}
      />
      <KPIPercentageAnalyzed
        loading={loading}
        analyzedQueriesPercentage={analyzedQueriesPercentage}
      />
    </PanelTileGroup>
  );
};

const KPIQueriesAnalyzed: React.FunctionComponent<{
  loading: boolean;
  analyzedCount: number;
}> = ({ loading, analyzedCount }) => {
  const count = analyzedCount == null ? "n/a" : formatNumber(analyzedCount);
  return (
    <PanelTile
      className={styles.kpiTile}
      title="Queries Analyzed"
      loading={loading}
    >
      <div className={styles.kpiValueLine}>
        <span className={styles.kpiValue}>{count}</span>
      </div>
      <div className={styles.kpiDetailLine}>Excluding DDL queries</div>
    </PanelTile>
  );
};

const KPITablesProcessed: React.FunctionComponent<{
  loading: boolean;
  processedTables: number;
  totalTables: number;
  lastProcessed: number;
}> = ({ loading, processedTables, totalTables, lastProcessed }) => {
  const stats =
    processedTables == null ? (
      "n/a"
    ) : (
      <>
        {formatNumber(processedTables)} / {formatNumber(totalTables)}
      </>
    );
  return (
    <PanelTile
      className={styles.kpiTile}
      title="Tables Processed"
      loading={loading}
    >
      <div className={styles.kpiValueLine}>
        <span className={styles.kpiValue}>{stats}</span>
      </div>
      <div className={styles.kpiDetailLine}>
        {lastProcessed
          ? `Last processed ${formatTimeAgo(moment.unix(lastProcessed))} `
          : "No processed tables "}
        <Tip content="The number of tables Index Advisor processed to find insights of Missing Index or Unused Index using the Indexing Engine. Processes once a day." />
      </div>
    </PanelTile>
  );
};

const KPIPercentageAnalyzed: React.FunctionComponent<{
  loading: boolean;
  analyzedQueriesPercentage: number;
}> = ({ loading, analyzedQueriesPercentage }) => {
  return (
    <PanelTile
      className={styles.kpiTile}
      title="% of All Query Runtime Analyzed"
      loading={loading}
    >
      <div className={styles.kpiValueLine}>
        <span className={styles.kpiValue}>
          {formatPercent(analyzedQueriesPercentage / 100)}
        </span>
      </div>
      <div className={styles.kpiDetailLine}>
        In the last 7 days{" "}
        <Tip content="The percentage of queries that Index Advisor was able to analyze without errors for purpose of finding missing indexes. This is looks back at the queries that have run in the last 7 days." />
      </div>
    </PanelTile>
  );
};

export default KPITiles;
