---
title: 'Step 4: Configure the Collector'
install_track_title: Installation Guide (Azure Database for PostgreSQL)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import PublicLastStepLogInsightsLink from '../_public_last_step_log_insights_link.mdx'

export const APIKeyInstructions = ({ apiKey }) => {
  return apiKey ? (
    <React.Fragment>
      The <code>api_key</code> for the current organization is{" "}
      <code>{apiKey}</code>
    </React.Fragment>
  ) : (
    <React.Fragment>
      The <code>api_key</code> can be found in the pganalyze Settings page for
      your organization, under the API keys tab
    </React.Fragment>
  );
};

export const ConfigSample = ({ apiKey }) => {
  return (
    <CodeBlock>
      {`[pganalyze]
api_key = ${apiKey ?? 'your_pga_organization_api_key'}\n
[server1]
db_host = your_db.postgres.database.azure.com
db_name = your_database_name
db_username = your_monitoring_user
db_password = your_monitoring_user_password
azure_subscription_id = your_subscription_id
azure_db_server_name = your_db`}
    </CodeBlock>
  )
}

## Configuring the collector

The collector configuration file lives in `/etc/pganalyze-collector.conf`, and looks like this:

<ConfigSample apiKey={props.apiKey} />

Fill in the values step-by-step:

1. <APIKeyInstructions apiKey={props.apiKey} />
2. The `db_host` is the IP address of your Azure Database server
3. The `db_name` is the database on the Azure Database server you want to monitor
4. The `db_username` and `db_password` should be the credentials of the monitoring user we created in Step 1
5. The `azure_subscription_id` is the subscription ID of your Azure Database server
6. (optional) The `azure_db_server_name` sets your Azure Database server name - this is automatically inferred from `db_host` and typically not needed.
If you're using Azure Cosmos DB for PostgreSQL Cluster, this is required and set the cluster name.

If the VM has multiple Managed Identities assigned, specify the client ID of the specific Managed Identity created in Step 3
using the `azure_ad_client_id` setting.

## Testing the new configuration

Run the following to make sure the configuration works:

```
sudo pganalyze-collector --test
```

<PublicLastStepLogInsightsLink />
