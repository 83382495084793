import React from "react";

import classNames from "classnames";

import CopyToClipboard, {
  Props as CopyToClipboardProps,
} from "components/CopyToClipboard";

import styles from "./style.module.scss";

type Props = Pick<CopyToClipboardProps, "content" | "className">;

const CopyToClipboardIcon: React.FunctionComponent<Props> = ({
  content,
  className,
}) => {
  return (
    <CopyToClipboard
      className={classNames(styles.icon, className)}
      content={content}
      label=""
      title="Copy to clipboard"
      fixedWidth
    />
  );
};

export default CopyToClipboardIcon;
