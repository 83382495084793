export const mru = <T>(newItem: T, items: T[], count: number): T[] => {
  return [newItem]
    .concat(items.filter((item) => item !== newItem))
    .slice(0, count);
};

// https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
export const notNullOrUndefined = <TValue>(
  value: TValue | null | undefined,
): value is TValue => {
  return value !== null && value !== undefined;
};

export function valueOrFirst<T>(input: T | T[] | undefined): T | undefined {
  return Array.isArray(input) ? input[0] : input;
}
