import React from "react";

import { Link } from "react-router-dom";
import classNames from "classnames";

import { SortDirection } from "types/graphql-global-types";
import { formatMs, formatPercent } from "utils/format";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";
import LazyLoader from "components/LazyLoader";
import Grid from "components/Grid";
import { GridColumn, SortOrder } from "components/Grid/util";
import { toSortDir, toSortOrder } from "utils/graphql";

interface QueryType {
  id: string;
  postgresRole: null | {
    id: string;
    name: string;
  };
  postgresRoleName?: null | string;
  truncatedQuery: string;
  normalizedQuery: string;
  avgTime: number;
  avgTimeDelta?: number;
  callsPerMinute: number;
  callsPerMinuteDelta?: number;
  bufferHitRatio: number;
  pctOfTotal: number;
  pctOfTotalIo?: number;
}

export type QueryStatsTableSortBy = keyof Omit<QueryType, "postgresRole">;

interface SortOptsType {
  sortBy: QueryStatsTableSortBy;
  sortDirection: SortDirection;
}

interface Props {
  databaseId: string;
  serverId: string;
  queries: QueryType[];
  searchTerm: null | string;
  compare: boolean;
  showingTable?: boolean;
  setSortOpts: (opts: SortOptsType) => void;
  sortOpts: SortOptsType;
  fetchMoreData: (loadedCount: number) => Promise<boolean>;
}

// Need to turn this into a data loading for the query statistics, similar to
// SchemaTableList - however this is more complicated because this table is
// used twice, once for the query overview, and once for the per-table queries
const QueryStatsTable: React.FunctionComponent<Props> = ({
  databaseId,
  serverId,
  compare,
  queries,
  showingTable,
  sortOpts,
  setSortOpts,
  fetchMoreData,
}) => {
  const { databaseQuery, serverRole } = useRoutes();

  const queriesWithRoles: Array<QueryType> = queries.map(
    (q: QueryType): QueryType => {
      return {
        postgresRoleName: q.postgresRole?.name ?? null,
        ...q,
      };
    },
  );

  function handleSort(sortBy: QueryStatsTableSortBy, sortOrder: SortOrder) {
    setSortOpts({
      sortBy,
      sortDirection: toSortDir(sortOrder),
    });
  }

  const sortedOrder = toSortOrder(sortOpts.sortDirection);
  const sortedBy = sortOpts.sortBy as keyof QueryType;
  const columns: GridColumn<QueryType, keyof QueryType>[] = [
    {
      field: "normalizedQuery",
      header: "Query",
      style: "query",
      renderer: function QueryCell({ fieldData, rowData }) {
        const href = databaseQuery(databaseId, rowData.id);
        return (
          <Link to={href} title={fieldData}>
            {rowData.truncatedQuery}
          </Link>
        );
      },
    },
    {
      field: "postgresRoleName",
      header: "Role",
      nullValue: "n/a",
      renderer: function RoleCell({ fieldData, rowData }) {
        return (
          <Link to={serverRole(serverId, rowData.postgresRole.id)}>
            {fieldData}
          </Link>
        );
      },
    },
    {
      field: "avgTime",
      header: "Avg Time (ms)",
      style: "number",
      defaultSortOrder: "desc",
      renderer: function AvgTimeCell({ fieldData }) {
        return formatMs(fieldData);
      },
    },
  ];

  if (compare) {
    columns.push({
      field: "avgTimeDelta",
      header: "7 Day Trend",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: AvgTimeTrendCell,
    });
  }

  columns.push({
    field: "callsPerMinute",
    header: "Calls / min",
    style: "number",
    defaultSortOrder: "desc",
    renderer: function CallsPerMinCell({ fieldData }) {
      return fieldData.toFixed(2);
    },
  });

  if (compare) {
    columns.push({
      field: "callsPerMinuteDelta",
      header: "7 Day Trend",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: CallsTrendCell,
    });
  }

  if (!showingTable) {
    columns.push({
      field: "pctOfTotalIo",
      header: "% of All I/O",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function PctOfTotalIoCell({ fieldData }) {
        return formatPercent(fieldData / 100.0);
      },
    });
  }

  columns.push({
    field: "pctOfTotal",
    header: "% of All Runtime",
    style: "number",
    defaultSortOrder: "desc",
    renderer: function PctOfTotalCell({ fieldData }) {
      return formatPercent(fieldData / 100.0);
    },
  });

  const gridClass =
    compare && showingTable
      ? "grid-cols-[1fr_minmax(10%,120px)_repeat(5,10%)]"
      : compare
      ? "grid-cols-[1fr_minmax(10%,120px)_repeat(6,10%)]"
      : showingTable
      ? "grid-cols-[4fr_minmax(10%,120px)_repeat(3,12%)]"
      : "grid-cols-[4fr_minmax(10%,120px)_repeat(4,12%)]";

  return (
    <LazyLoader loadedCount={queriesWithRoles.length} loadMore={fetchMoreData}>
      <Grid
        className={gridClass}
        striped
        data={queriesWithRoles}
        columns={columns}
        handleSort={handleSort}
        sortedBy={sortedBy}
        sortedOrder={sortedOrder}
        noRowsText="No queries matched"
      />
    </LazyLoader>
  );
};

function AvgTimeTrendCell({
  fieldData,
  rowData,
}: {
  fieldData: number;
  rowData: { avgTime: number };
}) {
  const delta = fieldData;
  const { avgTime: value } = rowData;

  const prevValue = value - delta;
  const pctDelta = (value - prevValue) / prevValue;
  if (delta === 0) {
    return "(no change)";
  }
  const sign = delta > 0 ? "+" : "";
  const pctChange = sign + formatPercent(pctDelta);
  const absChange = sign + formatMs(delta);
  return (
    <span
      title={pctChange}
      className={classNames(
        delta < 0 && styles.deltaDown,
        delta > 0 && styles.deltaUp,
      )}
    >
      {absChange}
    </span>
  );
}

function CallsTrendCell({
  rowData,
  fieldData,
}: {
  rowData: {
    callsPerMinute: number;
  };
  fieldData: number;
}) {
  const { callsPerMinute } = rowData;

  const delta = fieldData;
  if (delta === 0) {
    return "(no change)";
  }

  const value = callsPerMinute;

  const prevValue = value - delta;
  const pctDelta = (value - prevValue) / prevValue;

  // We only need to add the positive sign; the negative sign is part of the
  // formatted number
  const sign = delta > 0 ? "+" : "";
  const pctChange = sign + formatPercent(pctDelta);
  const absChange = sign + delta.toFixed(2);
  return (
    <span
      title={pctChange}
      className={classNames(
        delta < 0 && styles.deltaDown,
        delta > 0 && styles.deltaUp,
      )}
    >
      {absChange}
    </span>
  );
}

export default QueryStatsTable;
