import React from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

const CreditCardInfo: React.FunctionComponent<{
  organizationSlug: string;
  subscriptionCardType: string;
  subscriptionCardLast4: string;
  subscriptionCardExpMonth: number;
  subscriptionCardExpYear: number;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}> = ({
  organizationSlug,
  subscriptionCardType,
  subscriptionCardLast4,
  subscriptionCardExpMonth,
  subscriptionCardExpYear,
  onClick,
}) => {
  const { organizationSubscriptionUpdateCreditCard } = useRoutes();

  return (
    <div>
      <h3 className="twh text-base">
        Credit card
        <span
          style={{ fontWeight: "normal", fontSize: "90%", marginLeft: ".2em" }}
        >
          {onClick ? (
            <a href="" onClick={onClick}>
              (change credit card)
            </a>
          ) : (
            <Link
              to={organizationSubscriptionUpdateCreditCard(organizationSlug)}
            >
              (change credit card)
            </Link>
          )}
        </span>
      </h3>
      {subscriptionCardType ? (
        <div>
          {`${subscriptionCardType} ending in ****${subscriptionCardLast4} (expires ${subscriptionCardExpMonth}/${subscriptionCardExpYear})`}
        </div>
      ) : (
        "No card on file"
      )}
    </div>
  );
};

export default CreditCardInfo;
