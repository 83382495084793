import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import DocsSnippet from "components/DocsSnippet";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import { CHECK_OPTIONS } from "components/CheckConfig";

import {
  CheckConfigList as CheckConfigListType,
  CheckConfigList_getCheckConfigs,
  CheckConfigListVariables,
} from "./types/CheckConfigList";
import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";
import Grid, { GridColumn } from "components/Grid";

type Props = {
  organizationSlug: string;
  checkGroupAndName: string;
};

type CheckConfigType = CheckConfigList_getCheckConfigs & Record<string, string>;

const CheckConfigList: React.FunctionComponent<Props> = ({
  organizationSlug,
  checkGroupAndName,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { serverCheckConfigure } = useRoutes();
  const { data, loading, error } = useQuery<
    CheckConfigListType,
    CheckConfigListVariables
  >(QUERY, {
    variables: {
      organizationSlug,
      checkGroupAndName: checkGroupAndName,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const titleSearch = (
    <PanelTitleSearch value={searchTerm} onChange={setSearchTerm} />
  );

  const [groupName, checkName] = checkGroupAndName.split("/", 2);
  const options =
    (CHECK_OPTIONS[groupName] && CHECK_OPTIONS[groupName][checkName]) || [];

  const checkConfigs: CheckConfigType[] = data.getCheckConfigs
    .map((c) => ({
      serverName: c.server.name,
      ...JSON.parse(c.settingsJson),
      ...c,
    }))
    .filter((c) => {
      return (
        searchTerm === "" || c.serverName.toLowerCase().includes(searchTerm)
      );
    });

  const baseColumns: GridColumn<
    (typeof checkConfigs)[number],
    keyof (typeof checkConfigs)[number]
  >[] = [
    {
      field: "serverName",
      header: "Server",
      renderer: function ServerCell({ fieldData, rowData }) {
        return (
          <Link
            to={serverCheckConfigure(rowData.server.humanId, checkGroupAndName)}
          >
            {fieldData}
          </Link>
        );
      },
    },
    {
      field: "enabled",
      header: "Check Enabled?",
      renderer: function CheckEnabledCell({ fieldData }) {
        return fieldData ? "Yes" : "No";
      },
    },
  ];

  const columns = options.reduce<typeof baseColumns>((currCols, currOpt) => {
    return currCols.concat({
      field: currOpt.key,
      header: (
        <>
          {currOpt.title}{" "}
          <DocsSnippet title={currOpt.title} content={currOpt.help} />
        </>
      ),
    });
  }, baseColumns);

  const gridStyle = {
    gridTemplateColumns:
      options.length > 0
        ? `1fr 150px repeat(${options.length},250px)`
        : "1fr 150px",
  };
  return (
    <Panel title="Select Server To Configure" secondaryTitle={titleSearch}>
      <Grid
        className="grid"
        style={gridStyle}
        data={checkConfigs}
        defaultSortBy="serverName"
        columns={columns}
        striped
      />
    </Panel>
  );
};

export default CheckConfigList;
