import React from "react";

import { useQuery } from "@apollo/client";
import moment from "moment";
import Loading from "components/Loading";
import IndexSelectionResults from "components/IndexSelectionResults";
import { transformIndexSelectionResult } from "components/SchemaTableIndexAdvisor/util";

import {
  IssueDetail_getIssue as IssueType,
  IssueDetail_getIssue_references_referent_SchemaTable as IssueReferenceTable,
} from "../types/IssueDetail";

import QUERY from "./Query.graphql";
import {
  IndexingEngineIssue as IndexingEngineIssueType,
  IndexingEngineIssueVariables,
} from "./types/IndexingEngineIssue";

const IndexAdvisorIndexingEngine: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const detail = issue.detailsJson && JSON.parse(issue.detailsJson);
  const runId = detail.indexing_engine_run;
  // Work around https://github.com/pganalyze/pganalyze/issues/3674 by
  // hiding deletion suggestions unless using a custom config (presets
  // never include consolidation).
  const useConsolidation = detail.index_advisor_settings_name == "custom";

  const table = issue.references.find((ref) => ref.kind === "SchemaTable")
    .referent as IssueReferenceTable;

  const { data, loading, error } = useQuery<
    IndexingEngineIssueType,
    IndexingEngineIssueVariables
  >(QUERY, {
    variables: { runId, databaseId: issue.databaseId, tableId: table.id },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const result = transformIndexSelectionResult(
    data,
    moment.unix(data.getSchemaTableIndexSelection.runAt),
  );

  return (
    <IndexSelectionResults
      databaseId={issue.databaseId}
      result={result}
      useConsolidation={useConsolidation}
    />
  );
};

export default IndexAdvisorIndexingEngine;
