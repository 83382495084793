import { useDateRange } from "components/WithDateRange";
import { AutovacuumCount } from "../simulateVacuum";
import classNames from "classnames";
import { DeadRowsIcon, FreezingIcon, InsertsIcon } from "components/Icons";
import Tip from "components/Tip";
import { scaleTime } from "d3";
import React from "react";

type FocusType = "total" | "deadRows" | "freezing" | "inserts";

const VacuumCountGraph: React.FunctionComponent<{
  autovacuumCount: AutovacuumCount;
  title: string;
  focus?: FocusType;
}> = ({ autovacuumCount, title, focus }) => {
  const [{ from, to }] = useDateRange();
  const scale = scaleTime().domain([from, to]).range([0, 100]).clamp(true);

  const bgColor = (type: FocusType) => {
    if (type === "total") {
      return "bg-slate-400";
    }
    return focus === type ? "bg-[#337ab7] z-40" : "bg-slate-400";
  };
  const iconColor = (type: FocusType) => {
    return focus === type ? "text-[#337ab7]" : "text-slate-400";
  };
  const fontStyle = (type: FocusType) => {
    return focus === type ? "font-semibold text-[#337ab7]" : undefined;
  };
  const iconStyle = "ml-[-6px] bg-white align-bottom mb-[-7px]";
  const iconZIndex = (type: FocusType) => {
    return focus === type ? "z-50" : 0;
  };

  const autovacuumText = (
    <>
      {autovacuumCount.total.length} autovacuum
      {autovacuumCount.total.length !== 1 && "s"}{" "}
      {focus === "total" ? (
        <Tip content="The current autovacuum count can be much bigger than the simulation count with frequently autovacuumed tables, as the simulation can only perform autovacuum every 10 minutes." />
      ) : (
        <>
          (triggered by{" "}
          <span className={fontStyle("deadRows")}>
            <DeadRowsIcon
              className={classNames("text-[14px]", fontStyle("deadRows"))}
            />{" "}
            dead rows: {autovacuumCount.deadRows.length}
          </span>
          ,{" "}
          <span className={fontStyle("freezing")}>
            <FreezingIcon
              className={classNames("text-[14px]", fontStyle("freezing"))}
            />{" "}
            freeze age: {autovacuumCount.freezing.length}
          </span>
          ,{" "}
          <span className={fontStyle("inserts")}>
            <InsertsIcon
              className={classNames("text-[14px]", fontStyle("inserts"))}
            />{" "}
            inserts: {autovacuumCount.inserts.length}
          </span>
          ){" "}
          {autovacuumCount.deadRows.length +
            autovacuumCount.freezing.length +
            autovacuumCount.inserts.length >
            autovacuumCount.total.length && (
            <Tip content="The total autovacuum count is less than the sum of each type of autovacuums, because two or three autovacuums are triggering autovacuum at the same timing." />
          )}
        </>
      )}
    </>
  );

  return (
    <div>
      <div className="ml-[65px] mr-[25px] mb-[10px] border-b border-black">
        <div className="w-full h-8 leading-8 relative">
          <div className="absolute text-[12px]" style={{ left: "-65px" }}>
            {title}
          </div>
          {focus == "total" &&
            autovacuumCount.total.map((triggeredAt) => {
              const start = scale(triggeredAt * 1000);
              return (
                <React.Fragment key={`t-${triggeredAt}`}>
                  <div
                    className={`w-[3px] absolute h-full ${bgColor("total")}`}
                    style={{ left: `${start}%` }}
                    title="total"
                  />
                </React.Fragment>
              );
            })}
          {autovacuumCount.deadRows?.map((triggeredAt) => {
            const start = scale(triggeredAt * 1000);
            return (
              <React.Fragment key={`d-${triggeredAt}`}>
                <div
                  className={`w-[3px] absolute h-full ${bgColor("deadRows")}`}
                  style={{ left: `${start}%` }}
                  title="dead rows"
                />
                <div
                  className={`absolute ${iconZIndex("deadRows")}`}
                  style={{ left: `${start}%` }}
                >
                  <DeadRowsIcon
                    className={classNames(iconStyle, iconColor("deadRows"))}
                  />
                </div>
              </React.Fragment>
            );
          })}
          {autovacuumCount.freezing?.map((triggeredAt) => {
            const start = scale(triggeredAt * 1000);
            return (
              <React.Fragment key={`f-${triggeredAt}`}>
                <div
                  className={`w-[3px] absolute h-full ${bgColor("freezing")}`}
                  style={{ left: `${start}%` }}
                  title="freeze age"
                />
                <div
                  className={`absolute ${iconZIndex("freezing")}`}
                  style={{ left: `${start}%` }}
                >
                  <FreezingIcon
                    className={classNames(iconStyle, iconColor("freezing"))}
                  />
                </div>
              </React.Fragment>
            );
          })}
          {autovacuumCount.inserts?.map((triggeredAt) => {
            const start = scale(triggeredAt * 1000);
            return (
              <React.Fragment key={`i-${triggeredAt}`}>
                <div
                  className={`w-[3px] absolute h-full ${bgColor("inserts")}`}
                  style={{ left: `${start}%` }}
                  title="inserts"
                />
                <div
                  className={`absolute ${iconZIndex("inserts")}`}
                  style={{ left: `${start}%` }}
                >
                  <InsertsIcon
                    className={classNames(iconStyle, iconColor("inserts"))}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="text-center">{autovacuumText}</div>
    </div>
  );
};

export default VacuumCountGraph;
