import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

import { useCurrentOrganization } from "components/WithCurrentOrganization";
import { Flash, useSetFlashes } from "components/WithFlashes";

const WarnIfPastDue: React.FunctionComponent = () => {
  const { organizationSubscription } = useRoutes();
  const currentOrganization = useCurrentOrganization(true);
  const setFlashes = useSetFlashes();

  const { slug, planInfo, pastDue } = currentOrganization ?? {};
  const billingOverviewPath = organizationSubscription(slug);
  const skipFlash = !slug || !planInfo?.activeSubscription || !pastDue;

  useEffect(() => {
    const flash: Flash = {
      level: "alert",
      msg: (
        <span>
          Your organization's last payment did not go through. Please{" "}
          <Link to={billingOverviewPath}>update your payment method</Link>.
        </span>
      ),
      persist: true,
    };
    if (!skipFlash) {
      setFlashes((currFlashes) => currFlashes.concat(flash));
    }
    return () => {
      setFlashes((currFlashes) => currFlashes.filter((f) => f != flash));
    };
  }, [setFlashes, skipFlash, billingOverviewPath]);
  return null;
};

export default WarnIfPastDue;
