import { APIKeyInstructions } from './04_configure_the_collector_ec2.mdx'

export const MultiECSConfigFile = ({ apiKey }) => {
  return (
    <CodeBlock>
      {`[pganalyze]
api_key = ${apiKey ?? 'your_pga_organization_api_key'}\n
[instance1]
db_host = instance-id1.account-id.us-east-1.rds.amazonaws.com
db_name = your_database_name
db_username = your_monitoring_user
db_password = your_monitoring_user_password
db_sslrootcert = rds-ca-global
db_sslmode = verify-full\n
[instance2]
db_host = instance-id2.account-id.us-east-1.rds.amazonaws.com
db_name = your_database_name
db_username = your_monitoring_user
db_password = your_monitoring_user_password
db_sslrootcert = rds-ca-global
db_sslmode = verify-full`}
    </CodeBlock>
  )
}

To start, create an `INI` collector config file and save it as `pganalyze_collector.conf`:

<MultiECSConfigFile apiKey={props.apiKey} />

**Fill in the values step-by-step:**

1. <APIKeyInstructions apiKey={props.apiKey} />
2. The `db_host` is the hostname / endpoint of your RDS instance (for Amazon Aurora you can use the cluster endpoint in many cases, see for details below)
3. The `db_name` is the database name on the Postgres instance you want to monitor
4. The `db_username` and `db_password` should be the monitoring user we created in <Link to='02_create_monitoring_user'>Step 2</Link>
5. The `db_sslrootcert` and `db_sslmode` is the recommended SSL connection configuration that you can usually keep as specified above

Next, create a SSM secret for storing above file:

```bash
aws ssm put-parameter --name /pganalyze/CONFIG_CONTENTS --type SecureString \
  --value file://pganalyze_collector.conf
```

Now, save the following ECS task definition to a file, for example `pganalyze_task.json`:

```json
{
  "family": "pganalyze-fargate",
  "requiresCompatibilities": [
    "FARGATE"
  ],
  "executionRoleArn": "arn:aws:iam::YOUR_ACCOUNT_ID:role/pganalyzeTaskRole",
  "taskRoleArn": "arn:aws:iam::YOUR_ACCOUNT_ID:role/pganalyzeTaskRole",
  "networkMode": "awsvpc",
  "memory": "512",
  "cpu": "256",
  "containerDefinitions": [
    {
      "name": "pganalyze",
      "image": "quay.io/pganalyze/collector:stable",
      "essential": true,
      "secrets": [
        {"name": "CONFIG_CONTENTS", "valueFrom": "/pganalyze/CONFIG_CONTENTS"},
      ],
      "logConfiguration": {
        "logDriver": "awslogs",
        "options": {
          "awslogs-group": "/ecs/pganalyze",
          "awslogs-region": "us-east-1",
          "awslogs-stream-prefix": "pganalyze"
        }
      },
      "user": 1000,
      "readonlyRootFilesystem": false,
      "mountPoints": []
    }
  ]
}
```

Make sure to adjust `YOUR_ACCOUNT_ID` to be your AWS account ID (in the `executionRoleArn` field).
