import React, { useEffect, useRef, useState } from "react";

import Form from "../Form";
import FormErrors from "../FormErrors";
import TimezoneSelect from "../TimezoneSelect";
import GoogleSignIn from "../GoogleSignIn";

import styles from "./style.module.scss";
import { useUnauthenticatedRoutes } from "utils/routes";

type Props = {
  invite: {
    email: string;
    organizationName: string;
    invitedByFullname: string;
  };
  errors: string[];
  enterpriseEdition: boolean;
  googleAuthorizePath: string;
  googleClientId: string | null | undefined;
};

const Signup: React.FunctionComponent<Props> = ({
  invite,
  errors: initialErrors,
  enterpriseEdition,
  googleClientId,
  googleAuthorizePath,
}) => {
  const [tab, setTab] = useState("signup_password");
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "viewed", {
        event_category: "Signup Form",
        non_interaction: true,
      });
    }
  }, []);

  return (
    <>
      <HeaderNotice />
      <div className="flex-container">
        <div className="form-container">
          <div>
            {!invite && !enterpriseEdition && (
              <h1>Sign up for a free 14-day trial</h1>
            )}
            {(invite || enterpriseEdition) && <h1>Sign up</h1>}
            {invite && (
              <p>
                You're invited to the organization "{invite.organizationName}"
                by {invite.invitedByFullname}.
                <br />
                <br />
                Please sign up to accept the invitation.
              </p>
            )}
            {!enterpriseEdition && !invite && (
              <p>
                Your trial account comes with the complete
                <br />
                pganalyze feature set. No credit-card required.
              </p>
            )}
          </div>
          {tab == "signup_password" && (
            <SignupPassword
              invite={invite}
              enterpriseEdition={enterpriseEdition}
              errors={errors}
            />
          )}
          {tab == "signup_google" && (
            <SignupGoogle
              invite={invite}
              enterpriseEdition={enterpriseEdition}
              errors={errors}
              setErrors={setErrors}
              googleAuthorizePath={googleAuthorizePath}
              googleClientId={googleClientId}
            />
          )}
        </div>
        <div className="linkbar">
          <ul>
            <li
              className={tab == "signup_password" ? "active" : ""}
              onClick={(evt) => {
                evt.preventDefault();
                setTab("signup_password");
              }}
            >
              <a href="#" className="linkbar-email-link">
                Sign Up with Email
              </a>
            </li>
            {googleClientId && (
              <li
                className={tab == "signup_google" ? "active" : ""}
                onClick={(evt) => {
                  evt.preventDefault();
                  setTab("signup_google");
                }}
              >
                <a href="#" className="linkbar-google-link">
                  Sign Up with Google
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

const SignupGoogle: React.FunctionComponent<
  Props & { setErrors: (value: string[]) => void }
> = ({
  errors,
  setErrors,
  invite,
  enterpriseEdition,
  googleAuthorizePath,
  googleClientId,
}) => {
  const formRef = useRef<HTMLFormElement>();
  return (
    <form ref={formRef} id="new_user">
      <FormErrors errors={errors} modelName="user" />
      {!enterpriseEdition && <OrganizationName invite={invite} />}
      <Email invite={invite} />
      <Timezone />
      <div>
        <GoogleSignIn
          authorizePath={googleAuthorizePath}
          googleClientId={googleClientId || ""}
          buttonText={
            (invite ? "Accept Invitation and " : "") + "Sign Up with Google"
          }
          errorHandler={(errors: string[]) => {
            setErrors(errors);
          }}
          formValidateHandler={(): boolean => {
            if (formRef.current?.reportValidity) {
              return formRef.current.reportValidity();
            } else if (formRef.current) {
              return formRef.current.checkValidity();
            } else {
              return true;
            }
          }}
          formDataHandler={(): FormData =>
            (formRef.current && new FormData(formRef.current)) || new FormData()
          }
          formSuccessHandler={(location: string): void => {
            if (window.gtag) {
              window.gtag("event", "submitted", {
                event_category: "Signup Form",
                event_label: "Google Auth",
              });
              window.gtag("event", "conversion", {
                send_to: "AW-792224255/eZy8CK2XnPgBEP_D4fkC",
                event_callback: () => {
                  document.location.href = location;
                },
              });
            } else {
              document.location.href = location;
            }
          }}
        />
      </div>
      <TermsNotice enterpriseEdition={enterpriseEdition} />
    </form>
  );
};

const HeaderNotice: React.FunctionComponent = () => {
  const { userSignIn } = useUnauthenticatedRoutes();
  return (
    <div className="header-notice">
      Already signed up? <a href={userSignIn()}>Log in now.</a>
    </div>
  );
};

const Timezone: React.FunctionComponent = () => {
  return <TimezoneSelect id="user_timezone" name="user[timezone]" />;
};

const TermsNotice: React.FunctionComponent<
  Pick<Props, "enterpriseEdition">
> = ({ enterpriseEdition }) => {
  if (enterpriseEdition) {
    return null;
  }

  return (
    <p className={styles.termsNotice}>
      By signing up you agree to the{" "}
      <a href="https://pganalyze.com/terms">Terms of Service</a> and{" "}
      <a href="https://pganalyze.com/privacy">Privacy Policy</a>.
    </p>
  );
};

const Email: React.FunctionComponent<Pick<Props, "invite">> = ({ invite }) => {
  return (
    <input
      className="form-control"
      required
      id="user_email"
      type="email"
      name="user[email]"
      defaultValue={invite && invite.email}
      readOnly={invite && !!invite.email}
      placeholder="Email"
    />
  );
};

const OrganizationName: React.FunctionComponent<Pick<Props, "invite">> = ({
  invite,
}) => {
  if (invite) {
    return null;
  }
  return (
    <input
      autoFocus={true}
      className="form-control"
      required
      id="user_organization_name"
      type="text"
      name="user[organization_name]"
      placeholder="Organization / Company"
    />
  );
};

const SignupPassword: React.FunctionComponent<
  Pick<Props, "enterpriseEdition" | "invite"> & { errors: string[] }
> = ({ errors, enterpriseEdition, invite }) => {
  const { users } = useUnauthenticatedRoutes();
  return (
    <Form
      id="new_user"
      action={users()}
      method="post"
      eventCategory="Signup Form"
      eventLabel="Password Auth"
      eventConversion="AW-792224255/eZy8CK2XnPgBEP_D4fkC"
      insideSection={true}
    >
      <FormErrors errors={errors} modelName="user" />
      {!enterpriseEdition && <OrganizationName invite={invite} />}
      <input
        className="form-control"
        required
        id="user_fullname"
        type="text"
        name="user[fullname]"
        placeholder="Full Name"
      />
      <Email invite={invite} />
      <input
        className="form-control"
        required
        id="user_password"
        type="password"
        name="user[password]"
        placeholder="Password"
      />
      <input
        className="form-control"
        required
        id="user_password_confirmation"
        type="password"
        name="user[password_confirmation]"
        placeholder="Password confirmation"
      />
      <Timezone />
      <input
        type="submit"
        name="commit"
        value={
          invite
            ? "Accept Invitation and Create Account"
            : `Create Account and Start Your Free Trial`
        }
        className="btn btn-success"
      />

      <TermsNotice enterpriseEdition={enterpriseEdition} />
    </Form>
  );
};

export default Signup;
