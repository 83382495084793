import moment, { Moment } from "moment-timezone";

export class PredefinedPeriod {
  constructor(
    readonly key: string,
    readonly label: string,
    readonly duration: number,
    readonly units: moment.unitOfTime.DurationAs,
  ) {}

  get range(): [Moment, Moment] {
    const to = moment();
    return [to.clone().subtract(this.duration, this.units), to];
  }

  extendsBefore(ts: Moment): boolean {
    const from = this.range[0];
    return from.isBefore(ts);
  }
}

export const periods: PredefinedPeriod[] = [
  new PredefinedPeriod("1h", "Last hour", 1, "hour"),
  new PredefinedPeriod("3h", "Last 3 hours", 3, "hours"),
  new PredefinedPeriod("6h", "Last 6 hours", 6, "hours"),
  new PredefinedPeriod("12h", "Last 12 hours", 12, "hours"),
  new PredefinedPeriod("24h", "Last 24 hours", 24, "hours"),
  new PredefinedPeriod("2d", "Last 2 days", 2, "days"),
  new PredefinedPeriod("7d", "Last 7 days", 7, "days"),
  new PredefinedPeriod("10d", "Last 10 days", 10, "days"),
  new PredefinedPeriod("14d", "Last 14 days", 14, "days"),
  new PredefinedPeriod("30d", "Last 30 days", 30, "days"),
];

export const findByLabel = (label: string): PredefinedPeriod | undefined => {
  return periods.find((p) => p.label === label);
};

export const findByKey = (key: string): PredefinedPeriod | undefined => {
  return periods.find((p) => p.key === key);
};
