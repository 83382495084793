import React from "react";
import { VacuumData, PhaseInfo, phaseStyle, phaseLabel } from "./util";
import { Scale, SeriesConfig } from "../Graph/util";
import { translateX } from "utils/svg";

import Graph from "../Graph";
import { formatBytes } from "utils/format";

type Props = {
  data: VacuumData;
  blockSize: number;
  small?: boolean;
};

const VacuumDetailsGraph: React.FunctionComponent<Props> = ({
  blockSize,
  data,
  small = false,
}) => {
  const baseSeries: SeriesConfig[] = [
    {
      key: "heapBlksScanned",
      label: "Heap Bytes Scanned",
      color: "#4682b4",
    },
    {
      key: "heapBlksVacuumed",
      label: "Heap Bytes Vacuumed",
      color: "#609060",
    },
  ];
  const series = small
    ? baseSeries
    : baseSeries.concat({
        key: "numDeadTuples",
        label: "Dead Rows",
        color: "#999999",
        yAxis: "right",
      });
  return (
    <Graph
      epochTimeUnit="millisecond"
      data={data.seriesData}
      axes={{
        left: {
          format: (n) => formatBytes(n * blockSize),
        },
      }}
      series={series}
      small={small}
      backdrop={({ width, height, xScale }) => (
        <PhaseBackdrop
          width={width}
          height={height}
          xScale={xScale}
          phases={data.phases}
          small={small}
        />
      )}
    />
  );
};

const PhaseBackdrop: React.FunctionComponent<{
  width: number;
  height: number;
  xScale: Scale;
  phases: PhaseInfo[];
  small: boolean;
}> = ({ height, xScale, phases, small }) => {
  return (
    <>
      {phases.map((p, i) => {
        const phaseStart = xScale(p.startDate);
        const phaseWidth = xScale(p.endDate) - phaseStart;
        const phaseClass = phaseStyle(p.phase);
        const label = phaseLabel(p.phase);
        const labelY = 15;
        const phaseId = `phase${i}`;
        return (
          <g
            key={p.startDate}
            transform={translateX(phaseStart)}
            className={phaseClass}
            pointerEvents="none"
          >
            {!small && (
              <clipPath id={phaseId}>
                <rect x={0} y={0} width={phaseWidth} height={height} />
              </clipPath>
            )}
            <rect width={phaseWidth} height={height} />
            {!small && (
              <g clipPath={`url(#${phaseId})`}>
                <text
                  x={phaseWidth / 2}
                  y={labelY}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {label}
                </text>
              </g>
            )}
          </g>
        );
      })}
    </>
  );
};

export default VacuumDetailsGraph;
