import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import flowRight from "lodash/flowRight";

import PanelSection from "components/PanelSection";
import withLoading from "decorators/withLoading";

import { QuerySearchResults as ResultsType } from "./types/QuerySearchResults";

import QUERY from "./Query.graphql";
import ExplainTable from "components/ExplainTable";

type Props = {
  data: ResultsType;
  databaseId: string;
};

const Results: React.FunctionComponent<Props> = ({ data, databaseId }) => {
  if (data.getQueryTagsets.length == 0) {
    return <PanelSection>No results found</PanelSection>;
  }

  const explains = data.getQueryTagsets
    .filter((queryTagset) => {
      return queryTagset.querySample && queryTagset.querySample.explain;
    })
    .map((queryTagset) => {
      return {
        ...queryTagset.querySample.explain,
        querySample: {
          occurredAt: queryTagset.querySample.occurredAt,
          runtimeMs: queryTagset.querySample.runtimeMs,
          queryText: queryTagset.querySample.queryText,
        },
        query: { id: queryTagset.query.id },
      };
    });

  return (
    <ExplainTable
      explains={explains}
      databaseId={databaseId}
      blockSize={parseInt(data.blockSize.value, 10)}
    />
  );
};

export default flowRight(graphql(QUERY), withLoading)(Results);
