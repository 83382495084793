import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import Loading from "components/Loading";
import LogLines from "components/LogLines";

import {
  PostgresRoleDetails as PostgresRoleDetailsType,
  PostgresRoleDetailsVariables,
} from "./types/PostgresRoleDetails";
import QUERY from "./Query.graphql";

import { useFeature } from "components/OrganizationFeatures";
import { useDateRange } from "components/WithDateRange";

const PostgresRoleDetails: React.FunctionComponent = () => {
  const { serverId, id: roleId } = useParams();
  const logs = useFeature("logs");
  const [{ from, to }] = useDateRange();
  const { data, loading, error } = useQuery<
    PostgresRoleDetailsType,
    PostgresRoleDetailsVariables
  >(QUERY, {
    variables: {
      serverId,
      roleId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const {
    id,
    inherit,
    login,
    name,
    createDb,
    createRole,
    superUser,
    replication,
    passwordValidUntil,
    connectionLimit,
    config,
  } = data.getPostgresRoleDetails;
  const server = data.getServerDetails;

  return (
    <PageContent
      title={
        <span>
          Postgres Role: <strong>{name}</strong> on{" "}
          <strong>{server.name}</strong>
        </span>
      }
      windowTitle={`Postgres Role: ${name} on ${server.name}`}
      pageCategory="postgres-roles"
      pageName="show"
    >
      <Panel title="Role Settings">
        <PanelTable horizontal={true} borders={true}>
          <tbody>
            <tr>
              <th>Inherit</th>
              <td>{(inherit && "Yes") || "No"}</td>
              <th>Login</th>
              <td>{(login && "Yes") || "No"}</td>
            </tr>
            <tr>
              <th>Create DB</th>
              <td>{(createDb && "Yes") || "No"}</td>
              <th>Create Role</th>
              <td>{(createRole && "Yes") || "No"}</td>
            </tr>
            <tr>
              <th>Super User</th>
              <td>{(superUser && "Yes") || "No"}</td>
              <th>Replication</th>
              <td>{(replication && "Yes") || "No"}</td>
            </tr>
            <tr>
              <th>Password Valid Until</th>
              <td>{passwordValidUntil || "Infinity"}</td>
              <th>Connection Limit</th>
              <td>{connectionLimit}</td>
            </tr>
            <tr>
              <th>Config</th>
              <td colSpan={3}>{config && config.join(", ")}</td>
            </tr>
          </tbody>
        </PanelTable>
      </Panel>
      {logs && (
        <LogLines
          serverId={serverId}
          postgresRoleId={id}
          occurredBefore={to.unix()}
          occurredAfter={from.unix()}
        />
      )}
    </PageContent>
  );
};

export default PostgresRoleDetails;
