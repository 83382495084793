import React from "react";
import classNames from "classnames";

import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const IssueSummaryBadge: React.FunctionComponent<{
  severity: string | undefined;
  className?: string;
}> = ({ severity, className }) => {
  let style: string;
  let icon: IconDefinition;
  if (severity === "info") {
    style = "text-[#2C6682]";
    icon = faInfoCircle;
  } else if (severity === "warning") {
    style = "text-yellow-600";
    icon = faExclamationTriangle;
  } else if (severity === "critical") {
    style = "text-[#C2400C]";
    icon = faExclamationTriangle;
  } else if (severity === undefined) {
    style = "text-[#43962A]";
    icon = faCheckCircle;
  } else {
    // should not happen
    return null;
  }

  return (
    <FontAwesomeIcon icon={icon} className={classNames(style, className)} />
  );
};

export default IssueSummaryBadge;
