import { getIndexStructure } from "utils/ddl";
import type { IndexSelection as IndexSelectionInputType } from "./types/IndexSelection";
import {
  ExecutionCostType,
  IndexSelectionResultType,
  IndexType,
  ScanCostType,
  ScanType,
  StatisticsType,
} from "components/IndexSelectionResults/util";
import { Moment } from "moment";

// From index_advisor_config_service.rb
export const WRITE_OPTIMIZED_DEFAULT_SETTINGS = {
  Method: "CP",
  Options: {
    Goals: [
      {
        Name: "Minimize Maximum Weighted Cost",
        Tolerance: 10,
      },
      {
        Name: "Minimize Update Overhead",
        Tolerance: 0,
      },
      {
        Name: "Minimize Index Write Overhead",
        Tolerance: 0,
      },
    ],
    Rules: [
      {
        Name: "Maximum Per-Scan Cost Tolerance",
        Value: 100,
      },
    ],
  },
};

export const READ_OPTIMIZED_DEFAULT_SETTINGS = {
  Method: "CP",
  Options: {
    Goals: [
      {
        Name: "Minimize Update Overhead",
        Tolerance: 0,
      },
      {
        Name: "Minimize Index Write Overhead",
        Tolerance: 0,
      },
    ],
    Rules: [
      {
        Name: "Maximum Per-Scan Cost Tolerance",
        Value: 5,
      },
    ],
  },
};

export const BALANCED_DEFAULT_SETTINGS = {
  Method: "CP",
  Options: {
    Goals: [
      {
        Name: "Minimize Update Overhead",
        Tolerance: 0,
      },
      {
        Name: "Minimize Index Write Overhead",
        Tolerance: 0,
      },
    ],
    Rules: [
      {
        Name: "Maximum Per-Scan Cost Tolerance",
        Value: 10,
      },
    ],
  },
};

// raw result structure (input to transformation)
type IndexSelectionResult = {
  output: OutputType;
};

type SelectionOutput = {
  Goals: {
    Name: string;
    Value: number;
  }[];
  "Existing Indexes": SelectionIndexType[];
  "Possible Indexes": SelectionIndexType[];
  Statistics: {
    Status: string;
    Time: number;
    "Total Time": number;
    Coverage: {
      Total: number;
      "Existing Indexes": number;
      "Possible Indexes": number;
      Uncovered: number;
    };
    Cost: {
      Total: number;
      Maximum: number;
    };
    "Weighted Cost": {
      Total: number;
      Maximum: number;
    };
    "Indexes Used": {
      Total: number;
      "Existing Indexes": number;
      "Possible Indexes": number;
    };
    "Index Write Overhead": {
      Total: number;
      "Existing Indexes": number;
      "Possible Indexes": number;
    };
    "Update Overhead": number;
  };
};

type SelectionIndexType = {
  Index: IndexInformation;
  "Index Write Overhead": number;
};

type UpdateInfo = {
  Columns: string[];
  Overhead: number;
};

type CostingOutput = {
  "Existing Indexes": CostingIndexType[];
  "Possible Indexes": CostingIndexType[];
  Updates: UpdateInfo[];
  Scans: ScanOutput[];
};

type OutputType = {
  Costing: CostingOutput;
  Selection: SelectionOutput;
};

type ScanOutput = {
  "Scan ID": string;
  "Sequential Scan Cost": number;
  "Existing Index Costs": {
    "Index OID": number;
    Cost: number | null;
  }[];
  "Possible Index Costs": {
    "Index OID": number;
    Cost: number | null;
  }[];
};

type IndexInformation = {
  "Index OID": number;
  Name: string;
  Type: string;
  Hypothetical: boolean;
  "Size Bytes": number;
  Tuples: number;
  "Tree Height": number;
  Columns: string[];
  Definition: string;
};

type CostingIndexType = {
  Index: IndexInformation;
  "Index Write Overhead": number;
};

export function transformIndexSelectionResult(
  result: IndexSelectionInputType,
  runAt: Moment,
): IndexSelectionResultType {
  const selectionResult: IndexSelectionResult =
    result.getSchemaTableIndexSelection.data;
  const scanLabelsById = selectionResult.output.Costing.Scans.reduce(
    (map, scan, i) => {
      map.set(scan["Scan ID"], `S${i + 1}`);
      return map;
    },
    new Map(),
  );

  const scanInfosById = result.getSchemaTableIndexSelection.scans.reduce<{
    [scanId: string]: ScanType;
  }>((map, scan) => {
    map[scan.id] = {
      id: scan.id,
      label: scanLabelsById.get(scan.id),
      whereExpression: scan.whereExpression,
      joinExpression: scan.joinExpression,
      combinedExpression: scan.combinedExpression,
      scansPerMin: scan.avgCallsPerMinute,
      executionCosts: [],
      bestExistingCost: undefined as ExecutionCostType,
      bestSelectedCost: undefined as ExecutionCostType,
      parameterizedScanExpected: scan.parameterizedScanExpected,
      queries: scan.queries,
    };
    return map;
  }, {});

  const scansById: { [scanId: string]: ScanType } = {};
  selectionResult.output.Costing.Scans.forEach((scan) => {
    const scanId = scan["Scan ID"];
    const scanInfo = scanInfosById[scanId];
    const seqScanCost = scan["Sequential Scan Cost"];
    if (seqScanCost != null) {
      scanInfo.executionCosts.push({
        indexId: null,
        cost: Math.round(seqScanCost),
      });
    }

    scan["Existing Index Costs"]
      .concat(scan["Possible Index Costs"])
      .forEach((idx) => {
        const idxCost = idx.Cost;
        if (idxCost == null) {
          return;
        }

        const scanInfo = scanInfosById[scanId];
        scanInfo.executionCosts.push({
          indexId: String(idx["Index OID"]),
          cost: Math.round(idxCost),
        });
      });

    scansById[scanId] = scanInfo;
  });

  const scanCostsByIdxId = selectionResult.output.Costing.Scans.reduce<
    Map<string, ScanCostType[]>
  >((map, scan) => {
    const scanId = scan["Scan ID"];
    const allIdxCosts = scan["Existing Index Costs"].concat(
      scan["Possible Index Costs"],
    );
    allIdxCosts.forEach((idxCost) => {
      const idxOid = String(idxCost["Index OID"]);
      const cost = idxCost.Cost;
      if (cost != null) {
        let idxCostList = map.get(idxOid);
        if (!idxCostList) {
          idxCostList = [];
          map.set(idxOid, idxCostList);
        }

        idxCostList.push({
          scanId,
          cost: Math.round(cost),
        });
      }
    });
    return map;
  }, new Map());

  const indexConstraintsByName = result.getSchemaTableIndices.reduce(
    (map, idx) => {
      if (/^primary key/i.test(idx.constraintDef)) {
        map[idx.name] = "primary key";
      } else if (/^unique$/i.test(idx.constraintDef)) {
        map[idx.name] = "unique";
      }
      return map;
    },
    {},
  );
  const indexIdsByName = result.getSchemaTableIndices.reduce((map, idx) => {
    map[idx.name] = idx.id;
    return map;
  }, {});

  const indexesById = {};
  const selectedIndexOids = selectionResult.output.Selection["Existing Indexes"]
    .concat(selectionResult.output.Selection["Possible Indexes"])
    .reduce((set, idx) => {
      if (idx["Selected"]) {
        set.add(String(idx["Index OID"]));
      }
      return set;
    }, new Set());

  selectionResult.output.Costing["Existing Indexes"].forEach((curr) => {
    const idxOid = String(curr.Index["Index OID"]);
    const label = `I${Object.entries(indexesById).length + 1}`;
    const scans = scanCostsByIdxId.get(idxOid) ?? [];
    indexesById[idxOid] = {
      id: idxOid,
      pganalyzeId: indexIdsByName[curr.Index.Name] ?? null,
      existing: true,
      selected: selectedIndexOids.has(idxOid),
      label,
      name: curr.Index.Name,
      ddl: curr.Index.Definition,
      structure: getIndexStructure(curr.Index.Definition),
      constraint: indexConstraintsByName[curr.Index.Name] ?? null,
      scans,
      scanCount: scans.length,
      writeOverhead: curr["Index Write Overhead"],
    };
  });
  selectionResult.output.Costing["Possible Indexes"].forEach((curr) => {
    const idxOid = String(curr.Index["Index OID"]);
    const label = `I${Object.entries(indexesById).length + 1}`;
    const scans = scanCostsByIdxId.get(idxOid) ?? [];
    indexesById[idxOid] = {
      id: idxOid,
      existing: false,
      selected: selectedIndexOids.has(idxOid),
      label,
      name: curr.Index.Name,
      ddl: stripIndexNameFromDef(curr.Index.Definition),
      structure: getIndexStructure(curr.Index.Definition),
      constraint: null,
      scans,
      scanCount: scans.length,
      writeOverhead: curr["Index Write Overhead"],
    };
  });

  Object.values(scansById).forEach((scan) => {
    scan.bestExistingCost = getBestExistingCost(scan, indexesById);
    scan.bestSelectedCost = getBestSelectedCost(scan, indexesById);
  });

  const rawStats = selectionResult.output.Selection.Statistics;
  const rawCoverage = rawStats.Coverage;
  const rawIdxsUsed = rawStats["Indexes Used"];
  const rawIwo = rawStats["Index Write Overhead"];
  const statistics: StatisticsType = {
    status: rawStats.Status,
    model_build_time: rawStats.Time["Build"],
    model_solve_time: rawStats.Time["Solve"],
    total_time: rawStats["Total Time"],
    coverage: {
      total: rawCoverage.Total,
      existing_indexes: rawCoverage["Existing Indexes"],
      possible_indexes: rawCoverage["Possible Indexes"],
      uncovered: rawCoverage["Uncovered"],
    },
    cost: {
      total: Math.round(rawStats.Cost.Total),
      maximum: Math.round(rawStats.Cost.Maximum),
    },
    weighted_cost: {
      total: rawStats["Weighted Cost"].Total,
      maximum: rawStats["Weighted Cost"].Maximum,
    },
    indexes_used: {
      total: rawIdxsUsed.Total,
      existing_indexes: rawIdxsUsed["Existing Indexes"],
      possible_indexes: rawIdxsUsed["Possible Indexes"],
    },
    index_write_overhead: {
      total: rawIwo.Total,
      existing_indexes: rawIwo["Existing Indexes"],
      possible_indexes: rawIwo["Possible Indexes"],
    },
    update_overhead: rawStats["Update Overhead"],
    existing_update_overhead: calculateExistingUpdateOverhead(selectionResult),
  };

  const goals = selectionResult.output.Selection.Goals.map((g) => {
    return {
      name: g.Name,
      value: g.Value,
    };
  });

  return {
    runAt,
    output: {
      goals,
      statistics,
    },
    scans: scansById,
    indexes: indexesById,
  };
}

function calculateExistingUpdateOverhead(result: IndexSelectionResult): number {
  return result.output.Costing.Updates.reduce(
    (overhead: number, update: UpdateInfo) => {
      const isIndexedUpdate = result.output.Costing["Existing Indexes"].some(
        (idx) => {
          return idx.Index.Columns.some((col) => update.Columns.includes(col));
        },
      );
      if (isIndexedUpdate) {
        return overhead + update.Overhead;
      }
      return overhead;
    },
    0,
  );
}

/**
 * Best possible cost for this scan among the possible indexes that were
 * selected (or a sequential scan or an existing index scan).
 */
function getBestSelectedCost(
  scan: ScanType,
  indexes: { [idxId: string]: IndexType },
): ExecutionCostType {
  const selectedCosts = scan.executionCosts.filter((cost) => {
    return cost.indexId == null || indexes[cost.indexId].selected;
  });
  return selectedCosts.reduce((best, curr) => {
    return curr.cost < best.cost ? curr : best;
  });
}

/**
 * Current cost for this scan, using an existing index or a sequential scan.
 */
function getBestExistingCost(
  scan: ScanType,
  indexes: { [idxId: string]: IndexType },
): ExecutionCostType {
  const existingCosts = scan.executionCosts.filter((cost) => {
    return cost.indexId == null || indexes[cost.indexId].existing;
  });
  return existingCosts.reduce((best, curr) => {
    return curr.cost < best.cost ? curr : best;
  });
}

function stripIndexNameFromDef(idxDef: string): string {
  return idxDef.replace(/CONCURRENTLY .* ON /i, "CONCURRENTLY ON ");
}

export type IndexSelectionPreset =
  | "read_optimized"
  | "balanced"
  | "write_optimized"
  | "ignored"
  | "default";

export function getPresetByName(presetName: string): IndexSelectionPreset {
  switch (presetName) {
    case "Read-optimized":
      return "read_optimized";
    case "Balanced":
      return "balanced";
    case "Write-optimized":
      return "write_optimized";
    case "Ignored":
      return "ignored";
    case "Default":
      return "default";
    default:
      return undefined;
  }
}

export function getNameByPreset(preset: string): string {
  switch (preset) {
    case "read_optimized":
      return "Read-optimized";
    case "balanced":
      return "Balanced";
    case "write_optimized":
      return "Write-optimized";
    case "ignored":
      return "Ignored";
    case "custom":
      return "Custom Configuration";
    case "default":
      return "Default";
    default:
      return undefined;
  }
}
