import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { formatBytes, formatNumber } from "utils/format";
import { useRoutes } from "utils/routes";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Loading from "components/Loading";
import Grid from "components/Grid";

import QUERY from "./Query.graphql";

import {
  QueryDetailIndexAdvisorInfo,
  QueryDetailIndexAdvisorInfoVariables,
} from "./types/QueryDetailIndexAdvisorInfo";
import { QueryDetailIndexAdvisorIssues_getIssues as IssueType } from "../types/QueryDetailIndexAdvisorIssues";

import styles from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleInfo,
  faExclamationCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ParameterizedScanInfo } from "components/IndexSelectionResults/ScanDetails";

type Props = {
  databaseId: string;
  queryId: string;
  issues: IssueType[];
};

const QueryDetailsIndexAdvisorV2: React.FunctionComponent<Props> = ({
  databaseId,
  queryId,
  issues,
}) => {
  const { data, loading, error } = useQuery<
    QueryDetailIndexAdvisorInfo,
    QueryDetailIndexAdvisorInfoVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryId,
    },
  });
  const [includePartitions, setIncludePartitions] = useState(false);

  const secondaryTitle = (
    <label className="!px-0">
      <input
        type="checkbox"
        checked={includePartitions}
        id="include_partitions"
        onChange={(e) => setIncludePartitions(e.target.checked)}
      />{" "}
      Show table partitions
    </label>
  );

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <Panel title="Scans" secondaryTitle={secondaryTitle}>
      <ScanGrid
        databaseId={databaseId}
        scans={data.getSchemaTableScansForQuery}
        includePartitions={includePartitions}
        issues={issues}
      />
    </Panel>
  );
};

type ScanRowData = {
  databaseId: string;
  scanId: string;
  fullTableName: string;
  table: {
    id: string;
    schemaName: string;
    tableName: string;
  };
  tableSize: number | null;
  scanMethod: string;
  totalCost: number;
  whereExpression: string;
  joinExpression: string;
  suggestedIndexIssueId: string | null;
  suggestedIndexDescription: string | null;
  parameterizedScanExpected: boolean;
};

const TableScanCell: React.FunctionComponent<{
  rowData: ScanRowData;
}> = ({ rowData }) => {
  const { databaseTable, databaseTableIndexSelection, databaseIssue } =
    useRoutes();
  const [scanMethodIcon, scanMethodClassname] = getScanMethodInfo(
    rowData.scanMethod,
  );
  return (
    <div className="m-1">
      <Link to={databaseTable(rowData.databaseId, rowData.table.id)}>
        {rowData.table.schemaName}.{rowData.table.tableName}
      </Link>
      <div className="ml-2 mb-1 grid grid-cols-[120px_minmax(0,1fr)] gap-1 mt-1.5">
        <div className="text-[#777777] font-medium">WHERE clause</div>
        <div className="font-mono whitespace-normal">
          {rowData.whereExpression || "-"}
        </div>
        <div className="text-[#777777] font-medium">JOIN clause</div>
        <div>
          {rowData.joinExpression ? (
            <div>
              <div className="font-mono whitespace-normal mb-1">
                {rowData.joinExpression}
              </div>
              <ParameterizedScanInfo
                parameterizedScanExpected={rowData.parameterizedScanExpected}
              />
            </div>
          ) : (
            "-"
          )}
        </div>
        <div className="text-[#777777] font-medium">Scan method</div>
        <div className="whitespace-normal">
          <FontAwesomeIcon
            className={scanMethodClassname}
            icon={scanMethodIcon}
          />{" "}
          {rowData.scanMethod}
        </div>

        <div className="text-[#777777] font-medium">Index Advisor</div>
        <div className="truncate">
          {rowData.suggestedIndexIssueId ? (
            <Link
              to={
                databaseIssue(
                  rowData.databaseId,
                  rowData.suggestedIndexIssueId,
                  "index_advisor/indexing_engine",
                ) +
                "#S-" +
                rowData.scanId
              }
            >
              <FontAwesomeIcon className="inline-block" icon={faCircleInfo} />{" "}
              Create new index on {rowData.suggestedIndexDescription}
            </Link>
          ) : (
            <Link
              to={databaseTableIndexSelection(
                rowData.databaseId,
                rowData.table.id,
              )}
            >
              <FontAwesomeIcon
                className="text-[#43962A] inline-block"
                icon={faCheckCircle}
              />{" "}
              No recommended changes
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const ScanGrid: React.FunctionComponent<{
  databaseId: string;
  scans: QueryDetailIndexAdvisorInfo["getSchemaTableScansForQuery"];
  includePartitions: boolean;
  issues: IssueType[];
}> = ({ databaseId, scans, includePartitions, issues }) => {
  if (scans.length === 0) {
    // TODO: this may indicate an error in Index Advisor analysis, but right now we can't distinguish
    // that from the case of a query not scanning any tables: e.g., SELECT * FROM udf()
    return <PanelSection>No scans found in this query.</PanelSection>;
  }
  const scanData = scans
    .filter((scan) => {
      return includePartitions || !scan.table.parentTableId;
    })
    .map((scan) => {
      return {
        databaseId,
        scanId: scan.id,
        fullTableName: scan.table.schemaName + "." + scan.table.tableName,
        table: scan.table,
        tableSize: scan.table.lastStats?.dataSizeBytes,
        scanMethod: scan.genericPlanScanMethod,
        totalCost: scan.genericPlanTotalCost,
        whereExpression: scan.whereExpression,
        joinExpression: scan.joinExpression,
        suggestedIndexIssueId: !!scan.suggestedIndex
          ? issues.find(
              (issue) =>
                issue.checkGroupAndName == "index_advisor/indexing_engine" &&
                String(JSON.parse(issue.groupingKey)["table"]) == scan.table.id,
            )?.id
          : null,
        suggestedIndexDescription: scan.suggestedIndex,
        parameterizedScanExpected: scan.parameterizedScanExpected,
      };
    });

  return (
    <Grid
      className={styles.scanGrid}
      data={scanData}
      defaultSortBy="totalCost"
      columns={[
        {
          field: "fullTableName",
          header: "Table and Scan Details",
          renderer: TableScanCell,
        },
        {
          field: "totalCost",
          header: "Cost",
          style: "number",
          defaultSortOrder: "desc",
          renderer: ({ fieldData }) => formatNumber(fieldData, 2),
        },
        {
          field: "tableSize",
          header: "Table Size",
          style: "number",
          nullValue: "n/a",
          defaultSortOrder: "desc",
          renderer: function TableSizeCell({ fieldData }) {
            return formatBytes(fieldData);
          },
        },
      ]}
    />
  );
};

function getScanMethodInfo(
  fieldData: string,
): [icon: IconProp, className: string] {
  if (["Seq Scan", "Parallel Seq Scan"].includes(fieldData)) {
    return [faExclamationCircle, "text-[#C22426]"];
  } else if (["Append", "Merge Append"].includes(fieldData)) {
    return [faQuestionCircle, "text-[#777777]"];
  } else {
    // Index Scan, Index Only Scan, Bitmap Heap Scan, Tid Scan
    return [faCheckCircle, "text-[#43962A]"];
  }
}

export default QueryDetailsIndexAdvisorV2;
