import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { formatNumber } from "utils/format";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import { useRoutes } from "utils/routes";

import {
  DatabaseSummaryStatsTable_getSummaryStatsByDatabase,
  DatabaseSummaryStatsTable,
  DatabaseSummaryStatsTableVariables,
} from "./types/DatabaseSummaryStatsTable";
import QUERY from "./Query.graphql";
import { StatusDotIcon } from "components/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import Grid, { GridColumn } from "components/Grid";

type RowDataType = {
  serverId: string;
  serverName: string;
  status: string;
  databaseId: string;
  databaseName: string;
  slowQueries: number;
  unusedIndexes: number;
  warnings: number;
  criticals: number;
};

type Props = {
  organizationSlug?: string;
  serverId?: string;
};

const Table: React.FunctionComponent<Props> = ({
  organizationSlug,
  serverId,
}) => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [showInactive, setShowInactive] = useState(false);
  const { data, loading, error } = useQuery<
    DatabaseSummaryStatsTable,
    DatabaseSummaryStatsTableVariables
  >(QUERY, {
    variables: {
      organizationSlug,
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  let databases: Array<RowDataType> = data.getSummaryStatsByDatabase.map(
    (
      summary: DatabaseSummaryStatsTable_getSummaryStatsByDatabase,
    ): RowDataType => {
      return {
        serverId: summary.database.server.humanId,
        serverName: summary.database.server.name,
        databaseId: summary.database.id,
        databaseName: summary.database.datname,
        status: summary.database.server.hasRecentSnapshot
          ? summary.database.server.hasCriticalIssues
            ? "critical"
            : "okay"
          : "no data",
        slowQueries: summary.queriesSlownessCount,
        unusedIndexes: summary.unusedIndexesCount,
        warnings: summary.warningsCount,
        criticals: summary.criticalsCount,
      };
    },
  );

  if (searchTerm) {
    databases = databases.filter(
      (di: RowDataType): boolean =>
        di.serverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        di.databaseName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  if (!serverId && !showInactive) {
    databases = databases.filter((d) => d.status != "no data");
  }

  const titleSearch = (
    <>
      {serverId ? null : (
        <div className="flex">
          <input
            type="checkbox"
            checked={showInactive}
            id="show_inactive"
            onChange={(evt) => setShowInactive(evt.target.checked)}
          />
          <label htmlFor="show_inactive">
            Show servers with no recent statistics data
          </label>
        </div>
      )}
      <PanelTitleSearch
        value={searchTerm}
        onChange={(newTerm: string) => {
          setSearchTerm(newTerm);
        }}
      />
    </>
  );

  const columns: GridColumn<
    (typeof databases)[number],
    keyof (typeof databases)[number]
  >[] = [
    {
      field: "databaseName",
      header: "Database",
      renderer: DatabaseNameCell,
    },
    {
      field: "slowQueries",
      header: "Slow Queries",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: NumericCell,
    },
    {
      field: "unusedIndexes",
      header: "Unused Indexes",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: NumericCell,
    },
    {
      field: "warnings",
      header: "Warnings",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: NumericCell,
    },
    {
      field: "criticals",
      header: "Criticals",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: NumericCell,
    },
  ];

  let gridClassName =
    "grid grid-cols-[minmax(150px,1fr)_repeat(4,minmax(15%,110px))]";
  if (!serverId) {
    gridClassName =
      "grid grid-cols-[24px_repeat(2,minmax(150px,1fr))_repeat(4,minmax(10%,110px))]";
    columns.unshift({
      field: "serverName",
      header: "Server",
      renderer: ServerNameCell,
    });
    columns.unshift({
      field: "status",
      header: "",
      renderer: function StatusCell({ fieldData }) {
        return <StatusDotIcon status={fieldData} />;
      },
    });
  }

  return (
    <Panel title="All Databases" secondaryTitle={titleSearch}>
      <Grid
        className={gridClassName}
        data={databases}
        defaultSortBy={serverId ? "databaseName" : "serverName"}
        striped
        columns={columns}
      />
    </Panel>
  );
};

const ServerNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { server } = useRoutes();
  return (
    <span>
      <Link to={server(rowData.serverId)}>{rowData.serverName}</Link>
    </span>
  );
};

const DatabaseNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { database } = useRoutes();
  return (
    <span>
      <Link to={database(rowData.databaseId)}>{rowData.databaseName}</Link>
      {rowData.status != "no data" && rowData.criticals > 0 ? (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-[#ff0000] ml-[5px]"
        />
      ) : null}
    </span>
  );
};

const NumericCell: React.FunctionComponent<{ fieldData: number }> = ({
  fieldData,
}) => {
  return <>{formatNumber(fieldData)}</>;
};

export default Table;
