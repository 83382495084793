import React from "react";

import Panel from "components/Panel";

import styles from "./style.module.scss";

const DocsPanel = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div className={styles.container}>
      <Panel title={title}>
        <div className={styles.content}>{children}</div>
      </Panel>
    </div>
  );
};

export default DocsPanel;
