import React from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import Panel from "components/Panel";
import ResourceUsageBar from "components/ResourceUsageBar";
import PanelSection from "components/PanelSection";
import ContactSupportLink from "components/ContactSupportLink";
import { useRoutes } from "utils/routes";

const ServerUsagePanel: React.FunctionComponent<{
  organizationSlug: string;
  usage: number;
  limit: number;
  extraServersAction: "omit" | "adjust" | "upgrade" | "contact";
  subscriptionPlanId: string;
}> = ({
  organizationSlug,
  usage,
  limit,
  extraServersAction,
  subscriptionPlanId,
}) => {
  if (limit == null) {
    return null;
  }

  const overLimit = usage > limit;

  return (
    <Panel
      title={
        overLimit ? (
          <span>
            <FontAwesomeIcon
              className="text-sm text-[#C22426]"
              icon={faExclamationTriangle}
            />{" "}
            Billable Servers
          </span>
        ) : (
          "Billable Servers"
        )
      }
      secondaryTitle={
        <a href="https://pganalyze.com/docs/accounts/billing" target="_blank">
          What is a billable server?
        </a>
      }
    >
      <PanelSection>
        <div className="max-w-xl">
          <ResourceUsageBar
            label="Billable servers"
            usage={usage}
            limit={limit}
            description="servers included"
          />
        </div>
      </PanelSection>
      {extraServersAction !== "omit" && (
        <PanelSection>
          <ExtraServersAction
            action={extraServersAction}
            organizationSlug={organizationSlug}
            subscriptionPlanId={subscriptionPlanId}
          />
        </PanelSection>
      )}
    </Panel>
  );
};

const ExtraServersAction: React.FunctionComponent<{
  action: "adjust" | "upgrade" | "contact";
  organizationSlug: string;
  subscriptionPlanId: string;
}> = ({ action, organizationSlug, subscriptionPlanId }) => {
  const {
    organizationSubscriptionComparePlans,
    organizationSubscriptionActivate,
  } = useRoutes();
  if (action === "adjust") {
    return (
      <Link
        to={organizationSubscriptionActivate(
          organizationSlug,
          subscriptionPlanId,
        )}
      >
        Adjust server limit
      </Link>
    );
  } else if (action === "upgrade") {
    return (
      <Link to={organizationSubscriptionComparePlans(organizationSlug)}>
        Upgrade your plan to add additional servers
      </Link>
    );
  } else if (action === "contact") {
    return (
      <ContactSupportLink simpleStyle>
        Contact us to adjust server limit
      </ContactSupportLink>
    );
  } else {
    return null;
  }
};

export default ServerUsagePanel;
