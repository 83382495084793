import React from "react";
import pluralize from "pluralize";

import styles from "./style.module.scss";

type Props = {
  errors: Array<string>;
  modelName?: string;
};

class FormErrors extends React.Component<Props> {
  render() {
    const { errors, modelName } = this.props;

    if (errors.length == 0) {
      return null;
    }

    return (
      <div className={styles.errors}>
        {modelName && (
          <strong>
            {pluralize("error", errors.length, true)} prohibited this{" "}
            {modelName} from being saved:
          </strong>
        )}
        <ul>
          {errors.map(
            (e: string, i: number): React.ReactNode => (
              <li key={i}>{e}</li>
            ),
          )}
        </ul>
      </div>
    );
  }
}

export default FormErrors;
