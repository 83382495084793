import { VacuumTimeline_getVacuumRuns_vacuumRuns as VacuumRunType } from "components/VacuumAdvisor/VacuumTimeline/types/VacuumTimeline";
import sortBy from "lodash/sortBy";
import { Moment } from "moment-timezone";

export type VacuumActivity = {
  lanes: {
    [laneId: string]: VacuumEntry[];
  };
  tableVacuumFrequencyRanks: {
    [name: string]: number;
  };
};

export type VacuumEntry = {
  databaseId: string;
  startTs: number;
  endTs: number | undefined;
  adjustedStartTs: number;
  adjustedEndTs: number;
  laneId: string;
  laneName: string;
  tableId: string;
  tableName: string;
  vacuumIdentity: string;
};

export const adaptVacuumRunData = (
  runs: VacuumRunType[],
  start: Moment,
  end: Moment,
): VacuumActivity => {
  const startTs = start.valueOf() / 1000;
  const endTs = end.valueOf() / 1000;
  const assignLane = laneAssigner();
  const tableFrequencies = {};
  const lanes = sortBy(runs, (run) => run.vacuumStart)
    .filter((r) => {
      return (
        (r.vacuumEnd == null || r.vacuumEnd > startTs) && r.vacuumStart < endTs
      );
    })
    .reduce<VacuumActivity["lanes"]>((lanes, run) => {
      const [laneId, laneName] = assignLane(run);
      if (!(laneId in lanes)) {
        lanes[laneId] = [];
      }
      const tableId = run.schemaTable?.id ?? "0";
      const tableName = run.schemaTable?.tableName ?? "unknown table";
      if (tableFrequencies[tableId]) {
        tableFrequencies[tableId] += 1;
      } else {
        tableFrequencies[tableId] = 1;
      }
      lanes[laneId].push({
        databaseId: run.databaseId,
        startTs: run.vacuumStart * 1000,
        endTs: run.vacuumEnd ? run.vacuumEnd * 1000 : undefined,
        adjustedStartTs: Math.max(run.vacuumStart, startTs) * 1000,
        adjustedEndTs:
          (run.vacuumEnd ? Math.min(run.vacuumEnd, endTs) : endTs) * 1000,
        laneId: laneId,
        laneName: laneName,
        tableId: tableId,
        tableName: tableName,
        vacuumIdentity: run.identity,
      });
      return lanes;
    }, {});
  const tableVacuumFrequencyRanks = sortBy(
    Object.entries(tableFrequencies),
    ([_table, vacuumCount]) => -vacuumCount,
  )
    .map(([t, _vacuumCount]) => t)
    .reduce((freqs, t, i) => {
      // N.B.: the ranks are zero-indexed
      const rank = i;
      freqs[t] = rank;
      return freqs;
    }, {});
  return {
    lanes,
    tableVacuumFrequencyRanks,
  };
};

const laneAssigner = () => {
  const oldestAutovacByLane: { [laneId: string]: VacuumRunType } = {};
  let nextAutovac = 0;
  return (run: VacuumRunType): [string, string] => {
    if (run.autovacuum) {
      const laneEntry = Object.entries(oldestAutovacByLane).find(
        ([_laneId, prevRun]) => {
          // Allow up to 19 second overlap for vacuums, since we can't detect
          // the end correctly within that range
          return (
            prevRun.vacuumEnd != null &&
            prevRun.vacuumEnd - 19 <= run.vacuumStart
          );
        },
      );
      const laneId = laneEntry ? laneEntry[0] : "autovacuum #" + ++nextAutovac;
      oldestAutovacByLane[laneId] = run;
      return [laneId, laneId];
    }

    // Use VACUUM's pid for laneId number
    const laneId = "vacuum #" + Number(run.backendIdentity.slice(-7));
    const laneName = (run.postgresRole && run.postgresRole.name) || "unknown";
    return [laneId, laneName];
  };
};
