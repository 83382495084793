import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import sortBy from "lodash/sortBy";

import { formatNumber, formatBytes } from "utils/format";
import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";

import {
  ServerSchemaOverview as ServerSchemaOverviewType,
  ServerSchemaOverviewVariables,
  ServerSchemaOverview_getDatabaseStats as DatabaseStats,
} from "./types/ServerSchemaOverview";
import QUERY from "./Query.graphql";

import Grid from "components/Grid";
import { makeFilter } from "utils/filter";
import PanelTitleSearch from "components/PanelTitleSearch";

const ServerSchemaOverview: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { data, loading, error } = useQuery<
    ServerSchemaOverviewType,
    ServerSchemaOverviewVariables
  >(QUERY, {
    variables: {
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const titleSearch = (
    <PanelTitleSearch value={searchTerm} onChange={setSearchTerm} />
  );

  return (
    <PageContent
      title="Schema Statistics"
      pageCategory="schema"
      pageName="index_server"
    >
      <Panel title="Databases" secondaryTitle={titleSearch}>
        <Table data={data} searchTerm={searchTerm} />
      </Panel>
    </PageContent>
  );
};

type RowDataType = {
  databaseId: string | null | undefined;
  databaseName: string | null | undefined;
} & DatabaseStats;

type TableProps = {
  data: ServerSchemaOverviewType;
  searchTerm: string;
};

const Table: React.FunctionComponent<TableProps> = ({ data, searchTerm }) => {
  let tableData = data.getDatabaseStats.map(
    (inputData: DatabaseStats): RowDataType => {
      return {
        ...inputData,
        databaseId: inputData.database?.id ?? null,
        databaseName: inputData.database?.datname ?? null,
      };
    },
  );

  tableData = tableData.filter(makeFilter(searchTerm, "databaseName"));
  tableData = sortBy(tableData, (b: RowDataType): number => -b.totalSize);

  return (
    <Grid
      className="grid-cols-[1fr_160px_repeat(4,minmax(10%,120px))]"
      striped
      data={tableData}
      defaultSortBy="totalSize"
      columns={[
        {
          field: "databaseName",
          header: "Database",
          renderer: DatabaseNameCell,
        },
        {
          field: "tableViewCount",
          header: "Table + View Count",
          style: "number",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: function TableViewCountCell({ fieldData }) {
            return formatNumber(fieldData);
          },
        },
        {
          field: "dataSize",
          header: "Data Size",
          style: "number",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: BytesCell,
        },
        {
          field: "toastSize",
          header: "TOAST Size",
          style: "number",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: BytesCell,
        },
        {
          field: "indexSize",
          header: "Index Size",
          style: "number",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: BytesCell,
        },
        {
          field: "totalSize",
          header: "Total Size",
          style: "number",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: BytesCell,
        },
      ]}
    />
  );
};

const BytesCell: React.FunctionComponent<{ fieldData: number }> = ({
  fieldData,
}) => {
  return <>{formatBytes(fieldData)}</>;
};

const DatabaseNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { databaseTables } = useRoutes();
  return (
    <span>
      {rowData.database && !rowData.database.hidden && (
        <Link to={databaseTables(rowData.database.id)}>
          {rowData.database.datname}
        </Link>
      )}
      {rowData.database && rowData.database.hidden && rowData.database.datname}
    </span>
  );
};

export default ServerSchemaOverview;
