import React from "react";

import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import toPairs from "lodash/toPairs";
import moment, { Moment } from "moment-timezone";
import classNames from "classnames";
import { Link } from "react-router-dom";

import PanelSection from "components/PanelSection";

import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

import directory from "../../../../docs/directory.json";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";

import {
  LogStats_getLogStats_stats,
  LogStats as LogStatsType,
  LogStatsVariables,
  LogStats_getLogQuotaUsage,
} from "./types/LogStats";
import { useDateRange } from "components/WithDateRange";
import TimeBucketBar from "components/TimeBucketBar";
import ShowFlash from "components/ShowFlash";

import Color from "color";
import { useRoutes } from "utils/routes";
import { formatBytes } from "utils/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/pro-regular-svg-icons";

type LogStatsValue = Omit<LogStats_getLogStats_stats, "__typename">;

type LogClassificationStatsType = [string, LogStatsValue[]];
type LogClassificationStatsCategoryType = [
  string,
  LogClassificationStatsType[],
];

type Props = {
  serverId: string;
  databaseId?: string;
  selectedClassification: string | null | undefined;
  highlightedTime: moment.Moment | null | undefined;
};

const LogStats: React.FunctionComponent<Props> = ({
  databaseId,
  serverId,
  ...rest
}) => {
  const [{ from, to }] = useDateRange();
  const { data, loading, error } = useQuery<LogStatsType, LogStatsVariables>(
    QUERY,
    {
      variables: {
        databaseId,
        serverId,
        startTs: from.unix(),
        endTs: to.unix(),
      },
    },
  );
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const timeMin = from.unix();
  const timeMax = to.unix();

  return (
    <>
      <LogQuotaWarning quotaInfo={data.getLogQuotaUsage} />
      <LogStatsDisplay
        databaseId={databaseId}
        serverId={serverId}
        data={data}
        timeMin={timeMin}
        timeMax={timeMax}
        {...rest}
      />
    </>
  );
};

type DisplayProps = Props & {
  data: LogStatsType;
  timeMin: number;
  timeMax: number;
};

const LogStatsDisplay: React.FunctionComponent<DisplayProps> = ({
  databaseId,
  serverId,
  data,
  timeMin,
  timeMax,
  selectedClassification,
  highlightedTime,
}) => {
  const logStats = data.getLogStats.stats;
  if (!logStats || logStats.length === 0) {
    return (
      <PanelSection>
        <strong>Error:</strong> No logs were received
        {databaseId === null ? "" : " for this database"} in the selected
        timeframe.
      </PanelSection>
    );
  }

  if (selectedClassification) {
    return (
      <ClassificationDetails
        classification={selectedClassification}
        logStats={logStats}
        serverId={serverId}
        databaseId={databaseId}
        highlightedTime={highlightedTime}
        timeMax={timeMax}
        timeMin={timeMin}
      />
    );
  }

  const classificationDetailsKeys = Object.keys(directory.logClassifications);
  const categoriesKeys = Object.keys(directory.logCategories);
  let logStatsByClassification: LogClassificationStatsType[] = toPairs(
    groupBy(logStats, (l: LogStatsValue): string => l.classification),
  );

  logStatsByClassification = sortBy(
    logStatsByClassification,
    ([classification]): number => {
      const idx = classificationDetailsKeys.indexOf(classification);
      return (idx === -1 && Number.MAX_VALUE) || idx;
    },
  );

  let classificationStatsByCategory: LogClassificationStatsCategoryType[] =
    toPairs(
      groupBy(
        logStatsByClassification,
        (l: LogClassificationStatsType): string =>
          directory.logClassifications[l[0]].category,
      ),
    );

  classificationStatsByCategory = sortBy(
    classificationStatsByCategory,
    ([category]): number => {
      const idx = categoriesKeys.indexOf(category);
      return (idx === -1 && Number.MAX_VALUE) || idx;
    },
  );

  return (
    <div className={styles.stats}>
      <table>
        <tbody>
          {classificationStatsByCategory.map(
            ([category, classificationAndStats]): React.ReactNode => [
              <tr key={category}>
                <td />
                <td className={styles.categoryTitle}>
                  {directory.logCategories[category]}
                </td>
              </tr>,
              classificationAndStats.map(
                ([classification, stats], index: number): React.ReactNode => (
                  <ClassificationLogStats
                    key={classification}
                    stats={stats}
                    classification={classification}
                    firstClassification={index === 0}
                    databaseId={databaseId}
                    serverId={serverId}
                    highlightedTime={highlightedTime}
                    timeMax={timeMax}
                    timeMin={timeMin}
                  />
                ),
              ),
            ],
          )}
        </tbody>
      </table>
    </div>
  );
};

type ClassificationLogStatsProps = {
  stats: LogStatsValue[];
  classification: string;
  firstClassification: boolean;
  databaseId: string;
  serverId: string;
  timeMax: number;
  timeMin: number;
  highlightedTime: Moment;
};

const ClassificationLogStats: React.FunctionComponent<
  ClassificationLogStatsProps
> = ({
  stats,
  classification,
  firstClassification,
  databaseId,
  serverId,
  timeMax,
  timeMin,
  highlightedTime,
}) => {
  const { databaseLogs, serverLogs } = useRoutes();
  const maxCount = Math.max(
    ...stats.map((g: LogStatsValue): number => g.count),
  );
  const details = directory.logClassifications[classification];

  let classificationPath = "";
  if (databaseId) {
    classificationPath = databaseLogs(databaseId, classification);
  } else if (serverId) {
    classificationPath = serverLogs(serverId, classification);
  }

  return (
    <tr
      key={classification}
      className={classNames(
        styles.classification,
        firstClassification && styles.firstClassification,
      )}
    >
      <td className={styles.classificationTitle}>
        <Link to={classificationPath}>
          <span
            title="Click for more details"
            className={styles.classificationTitleText}
          >
            <FontAwesomeIcon
              icon={faSearchPlus}
              className="text-[#777] hover:text-[#333]"
            />{" "}
            {classification}: {details.title}
          </span>
        </Link>
      </td>
      <td className={styles.classificationGroups}>
        <Stats
          serverId={serverId}
          databaseId={databaseId}
          stats={stats}
          maxCount={maxCount}
          highlightedTime={highlightedTime}
          timeMax={timeMax}
          timeMin={timeMin}
          link
        />
      </td>
    </tr>
  );
};

type StatsProps = {
  stats: LogStatsValue[];
  maxCount: number;
  highlightedTime: Moment;
  timeMax: number;
  timeMin: number;
  serverId: string;
  databaseId: string;
  link?: boolean;
};

const Stats: React.FunctionComponent<StatsProps> = ({
  stats,
  maxCount,
  highlightedTime,
  timeMax,
  timeMin,
  serverId,
  databaseId,
  link = false,
}) => {
  const { databaseLogs, serverLogs } = useRoutes();
  if (stats.length === 0) {
    return null;
  }
  const from = moment.unix(timeMin);
  const to = moment.unix(timeMax);
  const bucketSize = moment.duration(
    to.diff(from, "hours", true) <= 3
      ? 1
      : to.diff(from, "days", true) <= 3
      ? 10
      : 60,
    "minutes",
  );

  const linkable = link && (databaseId != null || serverId != null);
  const buckets = stats
    .filter((group) => group.timebucket >= timeMin)
    .map((group) => {
      const start = moment.unix(group.timebucket);
      const end = start.clone().add(bucketSize);
      const url =
        linkable &&
        (databaseId
          ? databaseLogs(databaseId, group.classification)
          : serverLogs(serverId, group.classification)) + `#t=${end.unix()}`;
      return {
        start,
        value: group.count,
        url,
      };
    });
  const representativeGroup = stats[0];
  const details =
    directory.logClassifications[representativeGroup.classification];
  const color = details && details.color && Color(details.color);

  const TimeBucketTip: React.FunctionComponent<{
    start: Moment;
    end: Moment;
    value: number;
  }> = ({ start, end, value }) => {
    return (
      <div className={styles.statsPopover}>
        <strong>{value}</strong> &times;{" "}
        {(details &&
          `${representativeGroup.classification}: ${details.title}`) ||
          representativeGroup.classification ||
          "?"}
        <div className={styles.groupInfoTime}>
          {start.format("MMM DD hh:mma")}
          &mdash;
          {end.format("hh:mma zz")}
        </div>
      </div>
    );
  };
  return (
    <TimeBucketBar
      from={from}
      to={to}
      highlight={highlightedTime}
      buckets={buckets}
      bucketSize={bucketSize}
      maxValue={maxCount}
      color={color}
      tip={TimeBucketTip}
    />
  );
};

type ClassificationDetailsProps = {
  classification: string;
  logStats: LogStatsValue[];
  highlightedTime: Moment;
  timeMax: number;
  timeMin: number;
  serverId: string;
  databaseId: string;
};

const ClassificationDetails: React.FunctionComponent<
  ClassificationDetailsProps
> = ({
  classification,
  logStats,
  highlightedTime,
  timeMax,
  timeMin,
  serverId,
  databaseId,
}) => {
  const stats = logStats.filter(
    (l: LogStatsValue): boolean => l.classification == classification,
  );
  const maxCount = Math.max(
    ...stats.map((g: LogStatsValue): number => g.count),
  );

  return (
    <PanelSection>
      <table className={styles.detailsStats}>
        <tbody>
          <tr>
            <td className={styles.classificationGroups}>
              <Stats
                serverId={serverId}
                databaseId={databaseId}
                stats={stats}
                maxCount={maxCount}
                highlightedTime={highlightedTime}
                timeMax={timeMax}
                timeMin={timeMin}
                link={false}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </PanelSection>
  );
};

const LogQuotaWarning: React.FunctionComponent<{
  quotaInfo: LogStats_getLogQuotaUsage;
}> = ({ quotaInfo }) => {
  const { bytesOverQuota } = quotaInfo;
  if (bytesOverQuota === 0) {
    return null;
  }
  return (
    <ShowFlash
      level="alert"
      msg={
        <>
          This server exceeded its log rate limit by{" "}
          {formatBytes(bytesOverQuota)} in the selected time period; some log
          lines were dropped.{" "}
          <a
            target="_blank"
            rel="noopener"
            href="https://pganalyze.com/docs/log-insights/limits"
          >
            Learn more
          </a>
          .
        </>
      }
    />
  );
};

export default React.memo(LogStats);
