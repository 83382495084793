import React, { useState } from "react";

import Container from "./Container";

import { LogLinesVariables } from "./Container/types/LogLines";

type Props = {
  hideIfEmpty?: boolean;
  focusedLine?: number | null;
  focusLineHandler?: (line: number | null) => void;
  onReady?: () => void;
} & LogLinesVariables;

const LogLines: React.FunctionComponent<Props> = ({
  focusedLine,
  focusLineHandler,
  ...rest
}) => {
  const [localFocusedLine, setLocalFocusedLine] = useState(focusedLine);

  const handleLineFocus = (occurredAt: number | null) => {
    if (focusLineHandler) {
      focusLineHandler(occurredAt);
    } else {
      setLocalFocusedLine(occurredAt);
    }
  };

  return (
    <Container
      {...{
        ...rest,
        ...{
          focusedLine: focusedLine || localFocusedLine,
          focusLineHandler: handleLineFocus,
        },
      }}
    />
  );
};

export default LogLines;
