import React from "react";

import { OrganizationFeatures } from "components/OrganizationFeatures";
import { useCurrentServer } from "components/WithCurrentOrganization";
import Help from "components/Help";
import LoadingErrorBoundary from "components/LoadingErrorBoundary";
import OutdatedCollectorNotice from "components/OutdatedCollectorNotice";
import PageSideNav from "components/PageSideNav";
import StatusNotice from "components/StatusNotice";
import SyncMetricsHorizon from "components/SyncMetricsHorizon";
import WarnIfPastDue from "components/WarnIfPastDue";
import WarnIfServerOverage from "components/WarnIfServerOverage";

import styles from "./style.module.scss";

type Props = {
  content: React.ReactNode;
};

const PageLayout: React.FunctionComponent<Props> = ({ content }) => {
  const {
    humanId: serverId,
    lastSnapshot,
    hasRecentSnapshot,
  } = useCurrentServer(true) || {};

  return (
    <OrganizationFeatures>
      <div className={styles.page}>
        <Help />
        <SyncMetricsHorizon />
        <WarnIfPastDue />
        <WarnIfServerOverage />
        <StatusNotice />
        <OutdatedCollectorNotice
          serverId={serverId}
          hasRecentSnapshot={hasRecentSnapshot}
          collectorVersion={lastSnapshot?.collectorVersion}
        />
        <PageSideNav />
        <div className={styles.content}>
          <LoadingErrorBoundary>{content}</LoadingErrorBoundary>
        </div>
      </div>
    </OrganizationFeatures>
  );
};

export default PageLayout;
