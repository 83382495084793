import React from "react";
import { format } from "d3-format";
import { useQuery } from "@apollo/client";

import {
  formatBytes,
  formatNumber,
  formatBytesTrend,
  formatBytesTrendDetail,
} from "utils/format";
import {
  SchemaTableSummaryStatsType,
  SchemaTablePartialSummaryStatsType,
} from "types/SchemaTable";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import Badge from "components/Badge";

import styles from "./style.module.scss";
import { AreaSeries, LineSeries } from "components/Graph/Series";
import { Data } from "components/Graph/util";
import { useDateRange } from "components/WithDateRange";

import {
  SchemaTableStats as SchemaTableStatsType,
  SchemaTableStatsVariables,
} from "./types/SchemaTableStats";
import QUERY from "./Query.graphql";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";

type Props = {
  databaseId: string;
  fillfactor: number | null | undefined;
  stats: SchemaTableSummaryStatsType;
  tableId: string;
  partitionedBy?: string;
  stats7dAgo?: SchemaTablePartialSummaryStatsType;
  materializedView?: boolean | null | undefined;
};

const SchemaTableStats: React.FunctionComponent<Props> = ({
  databaseId,
  tableId,
  partitionedBy,
  fillfactor,
  materializedView,
  stats,
  stats7dAgo,
}) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<
    SchemaTableStatsType,
    SchemaTableStatsVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });

  const noData = !loading && !error && data.getSchemaTableStats == null;

  let sizeTrend: React.ReactNode = "";
  if (stats && stats7dAgo) {
    const { dataSizeBytes: sizeBytes, snapshotAt } = stats;
    const { dataSizeBytes: sizeBytes7dAgo, snapshotAt: snapshotAt7dAgo } =
      stats7dAgo;
    if (sizeBytes7dAgo !== null && sizeBytes7dAgo !== 0 && sizeBytes !== null) {
      const trendContent = formatBytesTrend(sizeBytes, sizeBytes7dAgo);
      const trendTooltip = formatBytesTrendDetail(
        sizeBytes,
        sizeBytes7dAgo,
        snapshotAt,
        snapshotAt7dAgo,
      );

      sizeTrend = <Badge title={trendTooltip}>{trendContent}</Badge>;
    }
  }

  return (
    <Panel
      title={`${
        (materializedView && "Materialized View") || "Table"
      } Statistics`}
    >
      {stats && (
        <PanelTable horizontal={true} borders={true}>
          <tbody>
            <tr>
              <th>Data + TOAST Size</th>
              <td>
                {formatBytes(stats.dataSizeBytes)}
                {sizeTrend}
              </td>
              <th>Estimated Visible Rows</th>
              <td>{formatNumber(stats.liveTuples)}</td>
            </tr>
            <tr>
              <th>Size of Indexes</th>
              <td>{formatBytes(stats.indexSizeBytes)}</td>
              <th>Estimated Dead Rows</th>
              <td>{formatNumber(stats.deadTuples)}</td>
            </tr>
            <tr>
              <th>Total Size</th>
              <td>{formatBytes(stats.dataSizeBytes + stats.indexSizeBytes)}</td>
              {fillfactor && <th>Fillfactor</th>}
              {fillfactor && <td>{fillfactor}</td>}
            </tr>
            {partitionedBy && (
              <tr>
                <th>Partitioned By</th>
                <td colSpan={3}>{partitionedBy}</td>
              </tr>
            )}
          </tbody>
        </PanelTable>
      )}
      {!stats && (
        <PanelSection>
          <p>
            <strong>Error:</strong> This{" "}
            {(materializedView && "materialized view") || "table"} has limited
            statistics available.
          </p>
          <p>
            This sometimes happens after collector restarts, if this persists
            please contact support.
          </p>
        </PanelSection>
      )}
      <div className={styles.graphs}>
        <GraphSection
          noData={noData}
          className={styles.tableGraphSection}
          loading={loading}
          error={error}
        >
          <DateRangeGraph
            data={data?.getSchemaTableStats as unknown as Data}
            axes={{ left: { format: formatBytes } }}
            series={[
              {
                key: "tableSize",
                type: AreaSeries,
                label: "Data Size",
              },
              {
                key: "toastSize",
                type: AreaSeries,
                label: "TOAST Size",
              },
              {
                key: "indexSize",
                type: AreaSeries,
                label: "Index Size",
              },
              {
                key: "totalSize",
                type: LineSeries,
                label: "Total Size",
              },
            ]}
          />
        </GraphSection>
        <GraphSection
          noData={noData}
          className={styles.tableGraphSection}
          loading={loading}
          error={error}
        >
          <DateRangeGraph
            data={data?.getSchemaTableStats as unknown as Data}
            axes={{
              left: {
                format: "count",
                tipFormat: (y: number): string =>
                  format(",.2f")(y) + " rows / min",
              },
            }}
            series={[
              { key: "inserts", label: "Inserted" },
              { key: "updates", label: "Updated" },
              { key: "hotUpdates", label: "HOT Updated" },
              { key: "deletes", label: "Deleted" },
            ]}
          />
        </GraphSection>
      </div>
    </Panel>
  );
};

export default SchemaTableStats;
