import React from "react";

import classNames from "classnames";

import { useDimensions } from "utils/hooks";
import Mouse from "components/Graph/Mouse";
import {
  ScreenLocation,
  InteractionPoint,
  formatTimeAdaptive,
} from "components/Graph/util";
import { scaleTime } from "d3";
import { useDateRange } from "components/WithDateRange";
import Axis from "components/Graph/Axis";
import { translateX, translateY } from "utils/svg";
import moment, { Moment } from "moment-timezone";
import { toStarting10m } from "utils/date";
import { formatTimeShort } from "utils/format";

import styles from "./style.module.scss";

type Props = {
  selectedTime: Moment | undefined;
  labelPosition?: "top" | "left";
  onTimeSelected: (time: Moment) => void;
};

const LogLinesTimeSelector: React.FunctionComponent<Props> = (props) => {
  const labelPos = props.labelPosition || "left";
  return (
    <div
      key={labelPos}
      className={classNames(styles.outerWrapper, {
        [styles.labelLeft]: labelPos === "left",
      })}
    >
      <div className={styles.title}>Jump to time:</div>
      <div className={styles.innerWrapper}>
        <LogLinesTimeSelectorImpl {...props} />
      </div>
    </div>
  );
};

const LogLinesTimeSelectorImpl: React.FunctionComponent<Props> = ({
  selectedTime,
  onTimeSelected,
}) => {
  const [ref, dims] = useDimensions<SVGElement>();

  const height = dims ? dims.height : 0;
  const width = dims ? dims.width : 0;

  const axisMargin = 18;
  const markerHeight = 10;
  const axisHeight = height - markerHeight;
  const axisWidth = width - 2 * axisMargin;

  const [{ from, to }] = useDateRange();

  const scale = scaleTime().domain([from, to]).range([0, axisWidth]);
  const width10min = scale(from.clone().add(10, "minutes").valueOf());
  const mapLocation = (loc: ScreenLocation): InteractionPoint => {
    const date = scale.invert(loc.x);
    const bucketStartMs = toStarting10m(moment(date)).valueOf();
    const bucketX = scale(bucketStartMs);
    return {
      bottom: {
        domain: bucketStartMs,
        range: bucketX,
      },
      nearby: [],
    };
  };
  const handleBucketClick = (point: InteractionPoint): void => {
    onTimeSelected(moment(point.bottom.domain));
  };

  return (
    <svg ref={ref} width="100%" height="100%" preserveAspectRatio="none">
      {dims && (
        <g pointerEvents="none" transform={translateX(axisMargin)}>
          <Mouse
            width={axisWidth}
            height={height}
            onClick={handleBucketClick}
            mapLocationToInteractionPoint={mapLocation}
          >
            {(hover) => {
              if (!hover && !selectedTime) {
                return null;
              }
              // N.B.: this represents the 10m bucket ending at this position,
              // so we draw left of this
              const markerX = hover ? hover.bottom.range : scale(selectedTime);
              const markerFill = hover ? "#72e2f6" : "#aeaeae";
              const markerStroke = hover ? "#1282a6" : "#797979";
              const atRightEdge = markerX / axisWidth > 0.85;
              const textXOffset = atRightEdge ? -width10min - 4 : 4;
              const textAnchor = atRightEdge ? "end" : "start ";
              return (
                <g transform={translateX(markerX)} pointerEvents="none">
                  <rect
                    x={-width10min}
                    y={0}
                    height={markerHeight}
                    width={width10min}
                    fill={markerFill}
                  />
                  <line
                    x1={-1}
                    x2={-1}
                    y1={0}
                    y2={markerHeight}
                    strokeWidth={2}
                    stroke={markerStroke}
                  />
                  {hover && (
                    <text
                      x={textXOffset}
                      y={0}
                      fontSize={10}
                      dominantBaseline="hanging"
                      textAnchor={textAnchor}
                      strokeWidth={0.2}
                      stroke="black"
                    >
                      {formatTimeShort(moment(hover.bottom.domain))}
                    </text>
                  )}
                </g>
              );
            }}
          </Mouse>
          <g pointerEvents="none" transform={translateY(markerHeight)}>
            <Axis
              placement="bottom"
              scale={scale}
              width={axisWidth}
              height={axisHeight}
              tickFormat={formatTimeAdaptive}
            />
          </g>
        </g>
      )}
    </svg>
  );
};

export default LogLinesTimeSelector;
