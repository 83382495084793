import React from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { formatBytes } from "utils/format";
import Loading from "components/Loading";
import Panel from "components/Panel";

import {
  VacuumRunning as VacuumRunningType,
  VacuumRunningVariables,
  VacuumRunning_getVacuumRuns_vacuumRuns,
} from "./types/VacuumRunning";
import VacuumDetailsGraph from "components/VacuumDetailsGraph";
import {
  adaptVacuumDetailData,
  phaseLabel,
} from "components/VacuumDetailsGraph/util";
import { useRoutes } from "utils/routes";
import QUERY from "./Query.graphql";
import Grid, { GridColumn } from "components/Grid";

type VacuumRunType = VacuumRunning_getVacuumRuns_vacuumRuns & {
  dbName: string;
  postgresRoleName?: string;
  schemaName?: string;
  tableName?: string;
  maxBytesReadPerSec: number | null;
  maxBytesWrittenPerSec: number | null;
};

function ReadSpeedCell({ fieldData }: { fieldData: number }) {
  return formatBytes(fieldData) + "/s";
}

const VacuumGraph: React.FunctionComponent<{
  vacuumRun: VacuumRunType;
}> = ({ vacuumRun }) => {
  const { details } = vacuumRun;
  return (
    <div style={{ alignSelf: "center", height: "25px" }}>
      <VacuumDetailsGraph
        blockSize={details.heapBlksTotalBytes / details.heapBlksTotal}
        data={adaptVacuumDetailData(details.stats, details.heapBlksTotal)}
        small
      />
    </div>
  );
};

const VacuumRunning: React.FunctionComponent<{
  serverId: string;
  databaseId?: string;
}> = ({ serverId, databaseId }) => {
  const { serverVacuum, serverRole } = useRoutes();
  const { data, loading, error } = useQuery<
    VacuumRunningType,
    VacuumRunningVariables
  >(QUERY, { variables: { serverId, databaseId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const vacuumRuns = data.getVacuumRuns.vacuumRuns.map((v) => {
    return {
      dbName: v.databaseName,
      postgresRoleName: v.postgresRole?.name,
      schemaName: v.schemaTable?.schemaName ?? "unknown schema",
      tableName: v.schemaTable?.tableName ?? "unknown table",
      progress: (v.vacuumEnd ?? moment().unix()) - v.vacuumStart,
      currentPhase: phaseLabel(
        (v.details.stats[v.details.stats.length - 1] || [])[1],
      ),
      runningSince: moment
        .duration(
          moment
            .unix(data.getVacuumRuns.lastCollectedAt)
            .diff(moment.unix(v.vacuumStart)),
        )
        .humanize(),
      maxBytesReadPerSec:
        (v.details.costDelayMs !== 0 &&
          (1000 / v.details.costDelayMs) *
            (v.details.costLimit / v.details.costPageMiss) *
            v.details.blockSize) ||
        null,
      maxBytesWrittenPerSec:
        (v.details.costDelayMs !== 0 &&
          (1000 / v.details.costDelayMs) *
            (v.details.costLimit / v.details.costPageDirty) *
            v.details.blockSize) ||
        null,
      ...v,
    };
  });

  const columns: GridColumn<
    (typeof vacuumRuns)[number],
    keyof (typeof vacuumRuns)[number]
  >[] = [
    {
      field: "schemaName",
      header: "Schema",
    },
    {
      field: "tableName",
      header: "Table",
      renderer: function TableCell({ rowData, fieldData }) {
        return (
          <span>
            <Link to={serverVacuum(serverId, rowData.identity)}>
              {fieldData || "(unknown)"}
            </Link>
            {rowData.toast && " (TOAST)"}
          </span>
        );
      },
    },
    {
      field: "backendPid",
      header: "PID",
    },
    {
      field: "progress",
      header: "Progress",
      renderer: function ProgressCell({ rowData }) {
        return <VacuumGraph vacuumRun={rowData} />;
      },
    },
    {
      field: "currentPhase",
      header: "Current Phase",
    },
    {
      field: "runningSince",
      header: "Running Since",
    },
    {
      field: "maxBytesReadPerSec",
      header: "Max Read Speed",
      nullValue: "-",
      renderer: ReadSpeedCell,
    },
    {
      field: "maxBytesWrittenPerSec",
      header: "Max Write Speed",
      nullValue: "-",
      renderer: ReadSpeedCell,
    },
    {
      field: "postgresRoleName",
      header: "Role / Autovacuum",
      renderer: function PostgresRoleCell({ rowData, fieldData }) {
        if (fieldData && rowData.postgresRole) {
          return (
            <Link to={serverRole(serverId, rowData.postgresRole.id)}>
              {fieldData}
            </Link>
          );
        } else if (rowData.autovacuum) {
          return "autovacuum";
        } else {
          return "-";
        }
      },
    },
  ];

  if (!databaseId) {
    columns.unshift({
      field: "dbName",
      header: "Database",
    });
  }

  const gridClassname = databaseId
    ? "grid-cols-[minmax(7%,min(7%,100px)),minmax(10%,1fr),minmax(5%,min(5%,80px)),minmax(10%,1fr),repeat(5,minmax(10%,120px))]"
    : "grid-cols-[minmax(7%,min(7%,100px)),minmax(7%,min(7%,100px)),minmax(10%,1fr),minmax(5%,min(5%,80px)),minmax(10%,1fr),repeat(5,minmax(10%,120px))]";

  return (
    <Panel
      title="Currently Running"
      secondaryTitle={moment
        .unix(data.getVacuumRuns.lastCollectedAt)
        .format("ll LTS z")}
    >
      <Grid
        className={gridClassname}
        striped
        defaultSortBy="vacuumEnd"
        data={vacuumRuns}
        columns={columns}
      />
    </Panel>
  );
};

export default VacuumRunning;
