import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";
import PageContent from "components/PageContent";
import Form from "components/Form";
import FormSubmit from "components/FormSubmit";
import Loading from "components/Loading";
import RoleForm from "components/RoleForm";

import { RoleNew as RoleNewType, RoleNewVariables } from "./types/RoleNew";
import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

const RoleNew: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const { organizationRoles } = useRoutes();
  const { data, loading, error } = useQuery<RoleNewType, RoleNewVariables>(
    QUERY,
    {
      variables: { organizationSlug },
    },
  );
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { name } = data.getOrganizationDetails;
  return (
    <PageContent
      title={`Organization "${name}"`}
      pageCategory="organization-roles"
      pageName="new"
    >
      <Panel title="Create Role">
        <Form action={organizationRoles(organizationSlug)}>
          <RoleForm organizationSlug={organizationSlug} />
          <FormSubmit title="Create Role" />
        </Form>
      </Panel>
    </PageContent>
  );
};

export default RoleNew;
