import React from "react";

import pluralize from "pluralize";

import OnboardingProgressIndicator from "components/OnboardingProgressIndicator";

import { useRoutes } from "utils/routes";

import styles from "./style.module.scss";
import TrackedLink from "components/TrackedLink";
import { useCurrentOrganization } from "components/WithCurrentOrganization";

const PageOnboardingNav: React.FunctionComponent = () => {
  const organization = useCurrentOrganization(true);
  const { serverSetup, organizationSetup, organizationSubscription } =
    useRoutes();

  if (!organization || organization.planInfo.activeSubscription) {
    return null;
  }

  const daysRemaining =
    organization.planInfo.trialEndsAt == null
      ? 0
      : (organization.planInfo.trialEndsAt * 1000 - Date.now()) /
        (24 * 60 * 60 * 1000);

  const stepsRemaining = organization.onboardingProgress.integratedExplain
    ? 0
    : organization.onboardingProgress.integratedLogInsights
    ? 1
    : organization.onboardingProgress.integratedCollector
    ? 2
    : 3;
  const linkTarget =
    daysRemaining <= 0 || stepsRemaining === 0
      ? organizationSubscription(organization.slug)
      : organization.onboardingProgress.lastIntegratedServer
      ? serverSetup(
          organization.onboardingProgress.lastIntegratedServer.humanId,
        )
      : organizationSetup(organization.slug);

  const timeRemainingBlurb =
    stepsRemaining === 0
      ? null
      : daysRemaining > 1
      ? pluralize("day", Math.floor(daysRemaining), true) +
        " remaining in your trial"
      : daysRemaining > 0
      ? "Less than 24h left in your trial"
      : "";

  return (
    <div className={styles.wrapper}>
      <TrackedLink
        href={linkTarget}
        eventCategory="Setup Link"
        eventLabel={stepsRemaining === 0 ? "Upgrade to paid plan" : "Setup"}
      >
        <OnboardingProgressIndicator
          stepsRemaining={stepsRemaining}
          trialExpired={daysRemaining <= 0}
        />
      </TrackedLink>
      {timeRemainingBlurb && (
        <p className={styles.timeRemainingBlurb}>{timeRemainingBlurb}</p>
      )}
    </div>
  );
};

export default PageOnboardingNav;
