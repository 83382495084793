import React, { useMemo, useState } from "react";

import Panel from "components/Panel";
import Grid, { NumberCell } from "components/Grid";

import { formatNumber } from "utils/format";
import { IndexSelectionResultType } from "../util";

import ScanReference from "../ScanReference";
import IndexReference from "../IndexReference";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";

const SeqScanDetails: React.FunctionComponent<{
  result: IndexSelectionResultType;
}> = ({ result }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const scanData = useMemo(() => {
    const seqScanCosts = Object.values(result.scans).map((scan) => {
      const seqScanCost = scan.executionCosts.find((c) => c.indexId == null);
      return {
        ...scan,
        seqScanCost: seqScanCost.cost,
      };
    });

    return seqScanCosts.map((scan) => {
      const estCurrCost = scan.bestExistingCost;
      const estNewCost = scan.bestSelectedCost;
      const indexChanged = estCurrCost.indexId !== estNewCost.indexId;
      return {
        ...scan,
        cost: scan.seqScanCost,
        combinedClauses: scan.combinedExpression,
        indexChanged,
        estCurrCost: estCurrCost.cost,
        currIndex: estCurrCost.indexId,
        estNewCost: estNewCost.cost,
        newIndex: estNewCost.indexId,
      };
    });
  }, [result]);
  const filteredScanData = scanData.filter(
    makeFilter(searchTerm, "combinedClauses", "label", "currIndex", "newIndex"),
  );
  const noRowsText =
    scanData.length === 0
      ? "No scans found to be using this index"
      : "No matching scans found";

  return (
    <Panel
      title="Scans"
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <Grid
        className="grid-cols-[1fr_10%_10%_10%_40px_10%_40px]"
        noRowsText={noRowsText}
        striped
        defaultSortBy="combinedClauses"
        data={filteredScanData}
        columns={[
          {
            field: "combinedClauses",
            header: "Scan Expression",
            renderer: function ScanDetailsCell({ rowData }) {
              return <ScanReference className="w-full" scan={rowData} />;
            },
          },
          {
            field: "scansPerMin",
            header: "Est. Scans/min",
            style: "number",
            renderer: NumberCell.precision(2),
          },
          {
            field: "cost",
            header: "Seq Scan Cost",
            style: "number",
            renderer: NumberCell,
          },
          {
            field: "estCurrCost",
            header: "Est. Cost",
            style: "number",
            nullValue: "n/a",
            renderer: NumberCell,
          },
          {
            field: "currIndex",
            header: "",
            disableSort: true,
            title: ({ fieldData }) => {
              if (!fieldData) {
                return "Sequential Scan";
              }

              return result.indexes[fieldData].structure;
            },
            renderer: function IndexCell({ fieldData }) {
              return (
                <IndexReference
                  terse
                  className="w-full"
                  index={result.indexes[fieldData]}
                />
              );
            },
          },
          {
            field: "estNewCost",
            header: "Est. New Cost",
            style: "number",
            nullValue: "n/a",
            renderer: function EstNewCostCell({ rowData, fieldData }) {
              if (!rowData.indexChanged) {
                return "no change";
              }
              return formatNumber(fieldData);
            },
          },
          {
            field: "newIndex",
            header: "",
            disableSort: true,
            title: ({ rowData, fieldData }) => {
              if (!fieldData) {
                return "Sequential Scan";
              }

              if (!rowData.indexChanged) {
                return undefined;
              }

              return result.indexes[fieldData].structure;
            },
            renderer: function IndexCell({ rowData, fieldData }) {
              if (!rowData.indexChanged) {
                return null;
              }

              return (
                <IndexReference
                  terse
                  className="w-full"
                  index={result.indexes[fieldData]}
                />
              );
            },
          },
        ]}
      />
    </Panel>
  );
};

export default SeqScanDetails;
