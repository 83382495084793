import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import { useUserPreferences } from "utils/hooks";

import { PageHeaderNav_servers as ServerType } from "./types/PageHeaderNav";

import styles from "./style.module.scss";
import { useRelatedRoute, useRoutes } from "utils/routes";
import { mru } from "utils/array";
import { CaretIcon, StatusDotIcon } from "components/Icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import Badge from "components/Badge";
import MenuList from "./MenuList";

type Props = {
  servers: Array<ServerType>;
  currentServerId?: string;
  organizationSlug: string;
};

const ServerSelect: React.FunctionComponent<Props> = ({
  servers,
  currentServerId,
  organizationSlug,
}) => {
  const [serverSelect, setServerSelect] = useUserPreferences("serverSelect");
  const recentServerIds = useMemo(() => {
    return (serverSelect.recentServerIds || {})[organizationSlug] || [];
  }, [serverSelect.recentServerIds, organizationSlug]);
  const { relatedOrganizationRoute } = useRelatedRoute();

  useEffect(() => {
    if (currentServerId && recentServerIds[0] != currentServerId) {
      setServerSelect({
        ...serverSelect,
        recentServerIds: {
          ...serverSelect.recentServerIds,
          [organizationSlug]: mru(currentServerId, recentServerIds, 5),
        },
      });
    }
  }, [
    setServerSelect,
    serverSelect,
    organizationSlug,
    recentServerIds,
    currentServerId,
  ]);

  const sortedServers = servers.slice().sort((a, b) => {
    if (
      recentServerIds.includes(a.humanId) &&
      recentServerIds.includes(b.humanId)
    ) {
      return (
        recentServerIds.indexOf(a.humanId) - recentServerIds.indexOf(b.humanId)
      );
    }
    if (recentServerIds.includes(a.humanId)) {
      return -1;
    }
    if (recentServerIds.includes(b.humanId)) {
      return 1;
    }
    if (a.hasRecentSnapshot && !b.hasRecentSnapshot) {
      return -1;
    }
    if (!a.hasRecentSnapshot && b.hasRecentSnapshot) {
      return 1;
    }
    return 0;
  });

  const currentServer = servers.find(
    (s: ServerType): boolean => s.humanId === currentServerId,
  );

  const trigger = ({
    open,
    toggleOpen,
  }: {
    open: boolean;
    toggleOpen: () => void;
  }) => (
    <button onClick={toggleOpen}>
      <small>Server</small>
      <div className={styles.selectMenuCurrent}>
        {currentServer ? (
          <>
            <ServerItemContent server={currentServer} />
            <Link
              to={relatedOrganizationRoute(organizationSlug)}
              className={styles.closeLink}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Link>
          </>
        ) : (
          <>
            <StatusDotIcon status="no data" title="" fixedWidth />
            <span className="ml-[4px]">(no server)</span>
          </>
        )}
        <CaretIcon direction={open ? "up" : "down"} headerNav />
      </div>
    </button>
  );

  return (
    <div className={styles.selectMenu} data-select-menu="server">
      <MenuList
        trigger={trigger}
        prompt="Search servers..."
        noMatch="no servers match"
        items={sortedServers}
        filterKeys={["name"]}
        renderer={ServerItem}
      >
        <AddServer organizationSlug={organizationSlug} />
      </MenuList>
    </div>
  );
};

const AddServer: React.FunctionComponent<{ organizationSlug: string }> = ({
  organizationSlug,
}) => {
  const { organizationServersNew } = useRoutes();
  return (
    <div
      key="new"
      className="mt-1 py-1 -mb-1 border-t border-[#ccc] first:border-t-0 first:pt-0 first:mt-0"
    >
      <Link
        className={styles.selectMenuListItem}
        to={organizationServersNew(organizationSlug)}
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="text-[10px] mr-[2px] hover:text-[#000] hover:font-medium active:text-[#000] active:font-medium"
          fixedWidth
        />
        <span className="ml-[4px] hover:text-[#000] hover:font-medium active:text-[#000] active:font-medium">
          Add Server
        </span>
      </Link>
    </div>
  );
};

const ServerItem: React.FunctionComponent<{ item: ServerType }> = ({
  item: item,
}) => {
  const { relatedServerRoute } = useRelatedRoute();
  return (
    <Link
      className={styles.selectMenuListItem}
      to={relatedServerRoute(item.humanId)}
    >
      <ServerItemContent server={item} />
    </Link>
  );
};

const ServerItemContent: React.FunctionComponent<{ server: ServerType }> = ({
  server,
}) => {
  const status = server.hasRecentSnapshot
    ? server.hasCriticalIssues
      ? "critical"
      : "okay"
    : "no data";
  return (
    <>
      <StatusDotIcon status={status} fixedWidth />
      <span key="name" className="ml-[4px]">
        {server.name}
      </span>
      {server.latestStats?.isPrimary != null && (
        <Badge className="ml-[4px]">
          {server.latestStats.isPrimary ? "Primary" : "Replica"}
        </Badge>
      )}
    </>
  );
};

export default ServerSelect;
