import React from "react";

import { Link } from "react-router-dom";
import { useRoutes } from "utils/routes";

import { useFeature } from "components/OrganizationFeatures";

import CopyCodeBlock from "components/CopyCodeBlock";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import IssueReferencesPanel from "../IssueReferencesPanel";
import Activity from "../Activity";

import { WithSmartAnchor } from "../../../../../docs/components/SmartAnchor";
import { WithCodeBlock } from "../../../../../docs/components/CodeBlock";
import { featureUrl, IssueGuidanceUrls } from "../../../../../docs/util/checks";

import {
  CheckGuidance,
  CheckTrigger,
} from "../../../../../docs/components/CheckDocumentation";

import {
  IssueDetail_getIssue as IssueType,
  IssueDetail_getIssue_checkConfig,
  IssueDetail_getIssue_references_referent_SchemaIndex as IssueReferenceIndex,
  IssueDetail_getIssue_references_referent_SchemaTable as IssueReferenceTable,
} from "../types/IssueDetail";

import styles from "./style.module.scss";
import InsufficientVacuumFrequencyReferencesPanel from "../InsufficientVacuumFrequencyReferencesPanel";
import TableAutovacuumSettings from "../TableAutovacuumSettings";
import IndexAdvisorIndexingEngine from "../IndexAdvisorIndexingEngine";

const IssueDetailBody: React.FunctionComponent<{
  issue: IssueType;
  checkGroup: string;
  checkName: string;
}> = ({ issue, checkGroup, checkName }) => {
  if (checkName === "indexing_engine") {
    return (
      <>
        <IndexAdvisorIndexingEngine issue={issue} />
        <CheckConfigurationPanel
          serverId={issue.server.humanId}
          checkGroup={checkGroup}
          checkName={checkName}
          config={issue.checkConfig}
        />
        <Panel title="Activity">
          <PanelSection>
            <Activity activity={issue.activity} />
          </PanelSection>
        </Panel>
      </>
    );
  } else if (checkName === "insufficient_vacuum_frequency") {
    const table = issue.references[0].referent as IssueReferenceTable;
    return (
      <>
        <InsufficientVacuumFrequencyReferencesPanel issue={issue} />
        <div className={styles.extraPanels}>
          <div className={styles.extraPanelsColumn}>
            <TableAutovacuumSettings
              serverId={issue.server.id}
              databaseId={issue.databaseId}
              tableId={table.id}
              tableName={table.tableName}
            />
          </div>
          <div className={styles.extraPanelsColumn}>
            <CheckConfigurationPanel
              serverId={issue.server.humanId}
              checkGroup={checkGroup}
              checkName={checkName}
              config={issue.checkConfig}
            />
            <Panel title="Activity">
              <PanelSection>
                <Activity activity={issue.activity} />
              </PanelSection>
            </Panel>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <IssueReferencesPanel
        issue={issue}
        checkGroup={checkGroup}
        checkName={checkName}
      />
      <div className={styles.extraPanels}>
        <div className={styles.extraPanelsColumn}>
          <GuidancePanel
            checkGroup={checkGroup}
            checkName={checkName}
            issue={issue}
          />
        </div>
        <div className={styles.extraPanelsColumn}>
          <CheckConfigurationPanel
            serverId={issue.server.humanId}
            checkGroup={checkGroup}
            checkName={checkName}
            config={issue.checkConfig}
          />
          <Panel title="Activity">
            <PanelSection>
              <Activity activity={issue.activity} />
            </PanelSection>
          </Panel>
        </div>
      </div>
    </>
  );
};

const CheckConfigurationPanel: React.FunctionComponent<{
  serverId: string;
  checkGroup: string;
  checkName: string;
  config: IssueDetail_getIssue_checkConfig;
}> = ({ serverId, checkGroup, checkName, config }) => {
  const parsedConfig = {
    enabled: config.enabled,
    settings: JSON.parse(config.settingsJson),
  };
  const { serverCheckConfigure } = useRoutes();
  return (
    <Panel title="Check Configuration" className={styles.checkConfigPanel}>
      <PanelSection>
        <CheckTrigger
          checkGroup={checkGroup}
          checkName={checkName}
          config={parsedConfig}
        />
        <Link to={serverCheckConfigure(serverId, checkGroup, checkName)}>
          Configure this check
        </Link>
      </PanelSection>
    </Panel>
  );
};

const GuidancePanel: React.FunctionComponent<{
  checkGroup: string;
  checkName: string;
  issue: IssueType;
}> = ({ checkGroup, checkName, issue }) => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  const { databaseId } = issue;
  const serverId = issue.server.humanId;

  const firstReference = issue.references?.[0];
  const firstReferenceUrl = firstReference?.url;
  const indexRecommendationUrl =
    firstReference?.kind === "Query" &&
    featureUrl(
      firstReferenceUrl,
      hasIndexAdvisor ? "indexadvisor" : "indexcheck",
    );

  const tableId =
    (firstReference?.referent as IssueReferenceIndex)?.tableId ??
    (firstReference?.referent as IssueReferenceTable)?.id;

  const SettingLink: React.FunctionComponent<{ setting: string }> = ({
    setting,
  }) => {
    return (
      <Link to={routes.serverConfigSetting(serverId, setting)}>{setting}</Link>
    );
  };

  const routes = useRoutes();
  const urls: IssueGuidanceUrls = {
    firstReferenceUrl,
    SettingLink,
    queriesUrl: databaseId
      ? routes.databaseQueries(databaseId)
      : routes.serverQueries(serverId),
    indexRecommendationUrl,
    serverSystemUrl: routes.serverSystem(serverId),
    serverVacuumsUrl: routes.serverVacuums(serverId),
    tableVacuumsUrl:
      databaseId && tableId
        ? routes.databaseTableVacuum(databaseId, tableId)
        : "",
    backendsUrl: databaseId
      ? routes.databaseBackends(databaseId)
      : routes.serverConnections(serverId),
    databaseWaitEventsUrl: databaseId
      ? routes.databaseWaitEvents(databaseId)
      : "",
    databaseTableUrl:
      databaseId && tableId ? routes.databaseTable(databaseId, tableId) : "",
    serverLogInsightsUrl: routes.serverLogs(serverId),
    serverSchemaUrl: routes.serverSchema(serverId),
    serverReplicationUrl: routes.serverReplication(serverId),
    serverVacuumFreezingUrl: routes.serverVacuumsFreezing(serverId),
    featureUrl,
  };
  return (
    <Panel title="Guidance" className={styles.guidancePanel}>
      <PanelSection>
        <WithSmartAnchor linkComponent={Link} linkRelative={false}>
          <WithCodeBlock component={CopyCodeBlock}>
            <CheckGuidance
              checkGroup={checkGroup}
              checkName={checkName}
              urls={urls}
              issue={issue}
            />
          </WithCodeBlock>
        </WithSmartAnchor>
      </PanelSection>
    </Panel>
  );
};

export default IssueDetailBody;
