import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";

import QUERY from "./Query.graphql";
import Grid from "components/Grid";
import {
  SchemaTableExtendedStats as SchemaTableExtendedStatsType,
  SchemaTableExtendedStatsVariables,
} from "./types/SchemaTableExtendedStats";
import Callout from "components/Callout";

const ExtendedStatsPanel: React.FunctionComponent<{
  databaseId: string;
  tableId: string;
}> = ({ databaseId, tableId }) => {
  const { data, loading, error } = useQuery<
    SchemaTableExtendedStatsType,
    SchemaTableExtendedStatsVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data.getSchemaTableExtendedStats.length == 0) {
    return null;
  }
  const noStatsData = !data.getSchemaTableExtendedStats.every(
    (s) => s.hasStatisticsData,
  );

  return (
    <>
      {noStatsData && (
        <Callout className="mb-3">
          Your database appears to be missing extended stats monitoring helper
          functions, so the statistics data of extended statistics are not
          available. Please review the relevant{" "}
          <a
            target="_blank"
            href="https://pganalyze.com/docs/install/troubleshooting/ext_stats_helper"
          >
            troubleshooting documentation
          </a>
          .
        </Callout>
      )}
      <Panel title="Extended Statistics">
        <Grid
          className="grid-cols-[2fr_1fr_2fr_1fr_1fr]"
          data={data.getSchemaTableExtendedStats}
          columns={[
            {
              field: "id",
              header: "Name",
              title: true,
            },
            {
              field: "kind",
              header: "Kind",
              title: true,
            },
            {
              field: "columns",
              header: "Columns",
              title: true,
            },
            {
              field: "expressions",
              header: "Expressions",
              title: true,
            },
            {
              field: "hasStatisticsData",
              header: "Has Statistics Data?",
              renderer: ({ fieldData }) => (fieldData ? "Yes" : "No"),
              tip: (
                <>
                  Whether the statistics data of this extended statistics object
                  is available in pganalyze or not. The monitoring helper
                  function to collect this data might be missing when not
                  available. The data is important to improve Index Advisor
                  recommendations.
                </>
              ),
            },
          ]}
        />
      </Panel>
    </>
  );
};

export default ExtendedStatsPanel;
