import React from "react";
import moment from "moment-timezone";

import styles from "./style.module.scss";
import { InfoIcon, StatusDotIcon } from "components/Icons";
import Popover from "components/Popover";

type Props = {
  lastReceivedSnapshotAt: number | null | undefined;
  lastTestSnapshotAt: number | null | undefined;
  duplicateSnapshotReceivedAt: number | null | undefined;
};

const SnapshotStatus = ({
  lastReceivedSnapshotAt,
  lastTestSnapshotAt,
  duplicateSnapshotReceivedAt,
}: Props) => {
  if (!lastReceivedSnapshotAt && !lastTestSnapshotAt) {
    return (
      <span>
        <StatusDotIcon status="no data" />
        <span>No Snapshots received</span>
      </span>
    );
  }

  const receivedTs = moment.unix(
    lastReceivedSnapshotAt || lastTestSnapshotAt || 0,
  );
  const testTs = moment.unix(lastTestSnapshotAt || 0);
  const dupTs = moment.unix(duplicateSnapshotReceivedAt || 0);
  // Test snapshots can incorrectly cause duplicateSnapshotReceivedAt to be set.
  // If it's set by a test snapshot, the diff is close to or equal to zero.
  // If it's set by a normal snapshot, the diff can be up to 8 minutes.
  // Checking if the diff is under 10 minutes to include a buffer.
  const dupIsCloseToTest =
    lastTestSnapshotAt &&
    duplicateSnapshotReceivedAt &&
    Math.abs(testTs.diff(dupTs, "minutes")) < 10;
  const dupReceivedRecently =
    moment().diff(dupTs, "minutes") <= 30 && !dupIsCloseToTest;
  const noRecentSnapshot = moment().diff(receivedTs, "hours") > 1;

  const status = noRecentSnapshot || dupReceivedRecently ? "warning" : "okay";
  const statusTitle = dupReceivedRecently
    ? "Duplicate snapshot received"
    : noRecentSnapshot
    ? "Received last snapshot more than an hour ago"
    : "Recently received statistics data";

  return (
    <>
      <div>
        <span>
          {receivedTs.fromNow()}
          <span className={styles.snapshotTimestamp}>
            {" "}
            · {receivedTs.format("YYYY-MM-DD HH:mm:ss ZZ")}
          </span>
          {!lastReceivedSnapshotAt && (
            <span className={styles.snapshotTimestamp}> · Test Snapshot</span>
          )}
        </span>
      </div>
      <div>
        <StatusDotIcon status={status} title={statusTitle} /> {statusTitle}{" "}
        {dupReceivedRecently && (
          <Popover
            content={
              <>
                Check if there are two collectors running at the same time to
                monitor this sever. Contact us if you are not running two
                collectors but seeing this message. (last received at{" "}
                {dupTs.format("YYYY-MM-DD HH:mm:ss ZZ")})
              </>
            }
          >
            <InfoIcon />
          </Popover>
        )}
      </div>
    </>
  );
};

export default SnapshotStatus;
