import React from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

const BillingDetailsInfo: React.FunctionComponent<{
  organizationSlug: string;
  billingAddressWithName: string;
  billingVatId: string;
  billingEmail: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}> = ({
  organizationSlug,
  billingAddressWithName,
  billingVatId,
  billingEmail,
  onClick,
}) => {
  const { organizationSubscriptionUpdateBillingDetails } = useRoutes();

  return (
    <>
      <h3 className="twh text-base mb-1">
        Billing details
        <span
          style={{
            fontWeight: "normal",
            fontSize: "90%",
            marginLeft: ".2em",
          }}
        >
          {onClick ? (
            <a href="" onClick={onClick}>
              (change billing details)
            </a>
          ) : (
            <Link
              to={organizationSubscriptionUpdateBillingDetails(
                organizationSlug,
              )}
            >
              (change billing details)
            </Link>
          )}
        </span>
      </h3>
      <strong>Address:</strong>
      <br />
      <pre
        style={{
          display: "inline-block",
          border: "none",
          padding: 0,
          margin: 0,
          background: "transparent",
          font: "inherit",
          lineHeight: "1.25em",
        }}
      >
        {billingAddressWithName}
      </pre>
      {billingVatId ? (
        <div style={{ marginTop: ".2em" }}>
          <strong>VAT ID / Tax ID:</strong> {billingVatId}
        </div>
      ) : null}
      {billingEmail ? (
        <div style={{ marginTop: ".2em" }}>
          <strong>Billing Email:</strong> {billingEmail}
        </div>
      ) : null}
    </>
  );
};

export default BillingDetailsInfo;
