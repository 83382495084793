import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import classNames from "classnames";

import ConnectToPagerDuty from "components/ConnectToPagerDuty";
import { useAsyncActionFlash } from "components/WithFlashes";

import {
  TestPagerDutyIntegration,
  TestPagerDutyIntegrationVariables,
} from "./types/TestPagerDutyIntegration";

import MUTATION from "./Mutation.graphql";
import Select from "components/Select";

type IntegrationStatusType = "enabled" | "not enabled" | "auth invalid";
type PagerDutyService = { id: string; name: string };

const ConnectTestPagerDuty: React.FunctionComponent<{
  organizationSlug: string;
  services: PagerDutyService[];
  status: IntegrationStatusType;
}> = ({ organizationSlug, services, status }) => {
  const [selectedService, setSelectedService] =
    useState<PagerDutyService | null>(null);
  const [testIntegration, { called, loading, error }] = useMutation<
    TestPagerDutyIntegration,
    TestPagerDutyIntegrationVariables
  >(MUTATION);

  useAsyncActionFlash({
    called,
    loading,
    error: error?.message,
    success: "Alert sent",
  });

  if (status === "not enabled") {
    return (
      <>
        <ConnectToPagerDuty organizationSlug={organizationSlug} />
        <span>
          Connect to PagerDuty to enable triggering incidents for critical
          database issues found by pganalyze.
        </span>
      </>
    );
  }

  const handleServiceSelected = (selected: PagerDutyService | null) => {
    setSelectedService(selected);
  };

  const testDisabled = selectedService === null || status === "auth invalid";
  const testDescription =
    status === "auth invalid" ? (
      <>
        Reset the integration and re-authenticate with PagerDuty to test
        alerting.
      </>
    ) : (
      <>
        Send a test alert to service{" "}
        <Select
          placeholder="— select a service —"
          items={services}
          itemToString={(item) => item.name}
          value={selectedService}
          onChange={handleServiceSelected}
        />
        .
      </>
    );
  const disabledHint =
    selectedService === null && status === "enabled"
      ? "select a channel to send a test alert"
      : undefined;

  const handleTestButtonClick = () => {
    if (testDisabled) {
      return;
    }
    testIntegration({
      variables: {
        organizationSlug,
        serviceId: selectedService.id,
      },
    });
  };
  return (
    <>
      <button
        disabled={testDisabled}
        title={disabledHint}
        onClick={handleTestButtonClick}
        className={classNames("btn btn-primary", testDisabled && "disabled")}
      >
        Test integration
      </button>
      <span>{testDescription}</span>
    </>
  );
};

export default ConnectTestPagerDuty;
