import React from "react";

import { Link } from "react-router-dom";

import { Node } from "types/explain";

import { useRoutes } from "utils/routes";

const IndexInfo: React.FunctionComponent<{
  node: Node;
  databaseId: string;
  wrap?: boolean;
}> = ({ node, databaseId, wrap }) => {
  const indName = node["Index Name"];
  if (!indName) {
    return null;
  }
  const title = `using ${indName}`;
  return (
    <div
      className={
        wrap ? undefined : "overflow-hidden whitespace-nowrap text-ellipsis"
      }
      title={wrap ? undefined : title}
    >
      using{" "}
      <LinkIndex databaseId={databaseId} node={node}>
        <span className="text-[#2f9c37]">{indName}</span>
      </LinkIndex>
    </div>
  );
};

const LinkIndex: React.FunctionComponent<{
  databaseId: string;
  node: Node;
  children: React.ReactNode;
}> = ({ databaseId, node, children }) => {
  const { databaseIndex } = useRoutes();
  const indexId = node.extra.index_id;
  if (!indexId) {
    return children as React.ReactElement;
  }
  return <Link to={databaseIndex(databaseId, indexId)}>{children}</Link>;
};

export default IndexInfo;
