import React from "react";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";

import { useRoutes } from "utils/routes";

type Props = {
  databaseId: string;
};

const SchemaNav: React.FunctionComponent<Props> = ({ databaseId }) => {
  const { databaseTables, databaseViews, databaseIndexes, databaseFunctions } =
    useRoutes();
  return (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseTables(databaseId)}>Tables</PageNavLink>
      <PageNavLink to={databaseIndexes(databaseId)}>Indexes</PageNavLink>
      <PageNavLink to={databaseViews(databaseId)}>Views</PageNavLink>
      <PageNavLink to={databaseFunctions(databaseId)}>
        Stored Procedures & Functions
      </PageNavLink>
    </PageSecondaryNavigation>
  );
};

export default SchemaNav;
