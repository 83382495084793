import React, { useCallback } from "react";

import { useRoutes } from "utils/routes";
import { Link } from "react-router-dom";

import Poller from "components/Poller";

import {
  CheckExplainIntegration,
  CheckExplainIntegrationVariables,
  CheckExplainIntegration_getExplains as Explain,
} from "./types/CheckExplainIntegration";

import QUERY from "./Query.graphql";

const POLL_INTERVAL_MS = 5000; // 5 seconds
const POLL_TIMEOUT_MS = 600_000; // 10 minutes

const AutomatedExplainIntegrationStatus: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { databaseQueryExplain } = useRoutes();
  const [explain, setExplain] = React.useState<Explain | null>(null);
  const handleResult = useCallback(
    (result: CheckExplainIntegration) => {
      const firstExplain = result.getExplains?.[0];
      if (firstExplain) {
        setExplain(firstExplain);
      }
    },
    [setExplain],
  );

  if (!explain) {
    return (
      <>
        <hr />
        <Poller<CheckExplainIntegration, CheckExplainIntegrationVariables>
          query={QUERY}
          queryOpts={{
            variables: { serverId },
            pollInterval: POLL_INTERVAL_MS,
          }}
          onResult={handleResult}
          pollingMessage="Waiting for an EXPLAIN plan (this should take a few minutes after you've performed the test command)..."
          timeoutMessage="Your first EXPLAIN plan is taking longer than expected."
          timeout={POLL_TIMEOUT_MS}
        />
      </>
    );
  }

  return (
    <>
      <hr />
      <p>
        <strong>Success - received your first explain plan!</strong>
      </p>
      <p>Your Automated EXPLAIN setup is complete. View the plan now:</p>
      <Link
        to={databaseQueryExplain(
          explain.database.id,
          explain.query.id,
          explain.humanId,
        )}
        className="btn btn-success"
      >
        View EXPLAIN plan
      </Link>
    </>
  );
};

export default AutomatedExplainIntegrationStatus;
