import React, { useContext, useEffect, useState } from "react";

const SkipLegacyStylesContext = React.createContext(false);
const SetSkipLegacyStylesContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => false);

const WithLegacyStyles = ({ children }: { children: React.ReactNode }) => {
  const [skipLegacyStyles, setSkipLegacyStyles] = useState(false);

  const isDev = import.meta.env?.DEV;
  const legacyStylesElementSelector = isDev
    ? 'style[data-vite-dev-id$="index_legacy.scss"]'
    : 'link[rel=stylesheet][href^="/vite/assets/styles_legacy-"]';

  useEffect(() => {
    // In order to disable legacy styles, we set the `media` tag to never be
    // applicable (this is simpler than trying to remove and re-add the
    // element). This allows fine-grained toggling of legacy styles (implemented
    // in PageContent, so that it's effectively page-level).
    if (skipLegacyStyles) {
      document
        .querySelector(legacyStylesElementSelector)
        ?.setAttribute("media", "not all");
    } else {
      document
        .querySelector(legacyStylesElementSelector)
        ?.removeAttribute("media");
    }
  }, [skipLegacyStyles, legacyStylesElementSelector]);

  return (
    <SetSkipLegacyStylesContext.Provider value={setSkipLegacyStyles}>
      <SkipLegacyStylesContext.Provider value={skipLegacyStyles}>
        {children}
      </SkipLegacyStylesContext.Provider>
    </SetSkipLegacyStylesContext.Provider>
  );
};

export function useSkipLegacyStyles() {
  return useContext(SkipLegacyStylesContext);
}

export function useSetSkipLegacyStyles() {
  return useContext(SetSkipLegacyStylesContext);
}

export default WithLegacyStyles;
