import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import PageIssueList from "components/PageIssueList";
import SchemaTableColumns from "components/SchemaTableColumns";
import SchemaTableConstraints from "components/SchemaTableConstraints";
import SchemaTableListTable from "components/SchemaTableList/Table";
import SchemaTableIndexes from "components/SchemaTableIndexes";
import SchemaTableStats from "components/SchemaTableStats";
import SchemaTableQueries from "components/SchemaTableQueries";
import VacuumTableStats from "components/VacuumTableStats";
import SchemaTableIndexAdvisor from "components/SchemaTableIndexAdvisor";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";

import {
  SchemaTableDetails as SchemaTableDetailsType,
  SchemaTableDetailsVariables,
} from "./types/SchemaTableDetails";
import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

import DateRangeBar from "components/DateRangeBar";
import { useRoutes } from "utils/routes";
import VacuumSimulator from "components/VacuumSimulator";

type Props = {
  tab: string;
};

const SchemaTableDetails: React.FunctionComponent<Props> = ({ tab }) => {
  const { databaseId, tableId } = useParams();
  const {
    databaseTable,
    databaseTablePartitions,
    databaseTableQueries,
    databaseTableColumns,
    databaseTableIndexes,
    databaseTableIndexSelection,
    databaseTableConstraints,
    databaseTableVacuum,
    databaseTableVacuumSimulator,
  } = useRoutes();
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(null, databaseId);
  const { data, loading, error } = useQuery<
    SchemaTableDetailsType,
    SchemaTableDetailsVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
    },
  });
  if (loading || error || !serverIdLoaded) {
    return <Loading error={!!error} />;
  }
  const {
    id,
    schemaName,
    tableName,
    fillfactor,
    parentTable,
    partitionBoundary,
    partitionedBy,
    lastStats,
    stats7dAgo,
  } = data.getSchemaTableDetails;

  const secondaryTitle = parentTable ? (
    <div>
      Parent table:&nbsp;
      <strong>
        <Link to={databaseTable(databaseId, parentTable.id)}>
          {parentTable.schemaName}.{parentTable.tableName}
        </Link>
      </strong>
      {partitionBoundary && (
        <span>
          &nbsp;· Partition boundary:&nbsp;
          <span className={styles.partitionBoundary}>{partitionBoundary}</span>
        </span>
      )}
    </div>
  ) : null;

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseTable(databaseId, id)}>Statistics</PageNavLink>
      <PageNavLink to={databaseTablePartitions(databaseId, id)}>
        Partitions
      </PageNavLink>
      <PageNavLink to={databaseTableQueries(databaseId, id)}>
        Queries
      </PageNavLink>
      <PageNavLink to={databaseTableColumns(databaseId, id)}>
        Columns
      </PageNavLink>
      <PageNavLink to={databaseTableIndexes(databaseId, id)}>
        Indexes
      </PageNavLink>
      <PageNavLink to={databaseTableIndexSelection(databaseId, id)}>
        Index Advisor
      </PageNavLink>
      <PageNavLink to={databaseTableConstraints(databaseId, id)}>
        Constraints
      </PageNavLink>
      <PageNavLink to={databaseTableVacuum(databaseId, id)}>
        VACUUM/ANALYZE Activity
      </PageNavLink>
      <PageNavLink to={databaseTableVacuumSimulator(databaseId, id)}>
        VACUUM Simulator
      </PageNavLink>
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title={`Table: ${schemaName}.${tableName}`}
      secondaryTitle={secondaryTitle}
      pageControls={tab !== "index_advisor" && <DateRangeBar />}
      pageCategory="schema-tables"
      pageName="show"
      pageTab={tab}
      featureNav={featureNav}
    >
      {tab != "vacuum_simulator" && tab != "index_advisor" && (
        <PageIssueList
          serverId={serverId}
          referentId={id}
          referentType="SchemaTable"
        />
      )}
      {tab == "overview" && (
        <SchemaTableStats
          databaseId={databaseId}
          tableId={id}
          partitionedBy={partitionedBy}
          stats={lastStats}
          stats7dAgo={stats7dAgo}
          fillfactor={fillfactor}
        />
      )}
      {tab == "partitions" && (
        <SchemaTableListTable databaseId={databaseId} parentTableId={id} />
      )}
      {tab == "queries" && (
        <SchemaTableQueries databaseId={databaseId} tableId={id} />
      )}
      {tab == "columns" && (
        <SchemaTableColumns
          databaseId={databaseId}
          tableId={id}
          liveTuples={lastStats?.liveTuples}
        />
      )}
      {tab == "indexes" && (
        <SchemaTableIndexes databaseId={databaseId} tableId={id} />
      )}
      {tab == "constraints" && (
        <SchemaTableConstraints databaseId={databaseId} tableId={id} />
      )}
      {tab == "vacuum" && (
        <VacuumTableStats databaseId={databaseId} tableId={id} />
      )}
      {tab == "index_advisor" && (
        <SchemaTableIndexAdvisor
          databaseId={databaseId}
          tableId={id}
          schemaName={schemaName}
          tableName={tableName}
        />
      )}
      {tab == "vacuum_simulator" && (
        <VacuumSimulator databaseId={databaseId} tableId={id} />
      )}
    </PageContent>
  );
};

export default SchemaTableDetails;
