import React from "react";

import { useParams } from "react-router-dom";

import PageContent from "components/PageContent";
import DateRangeBar from "components/DateRangeBar";
import { useRoutes } from "utils/routes";
import { useFeature } from "components/OrganizationFeatures";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import QueryList from "./QueryList";
import QuerySearch from "./QuerySearch";
import ExplainList from "components/ExplainList";

type QueryPerformanceTabType = "overview" | "search" | "explains";

const QueryPerformance: React.FunctionComponent<{
  tab: QueryPerformanceTabType;
}> = ({ tab }) => {
  const { databaseId } = useParams();
  const hasSearchFeature = useFeature("querySearch");
  const hasExplainUpload = useFeature("explainUpload");
  const { databaseQueries, databaseQueriesSearch, databaseQueriesExplains } =
    useRoutes();

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseQueries(databaseId)}>Overview</PageNavLink>
      {hasSearchFeature && (
        <PageNavLink to={databaseQueriesSearch(databaseId)}>Search</PageNavLink>
      )}
      {hasExplainUpload && (
        <PageNavLink to={databaseQueriesExplains(databaseId)}>
          EXPLAIN Plans
        </PageNavLink>
      )}
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title="Query Performance"
      pageControls={<DateRangeBar />}
      pageCategory="queries"
      pageName="index"
      featureNav={(hasSearchFeature || hasExplainUpload) && featureNav}
    >
      <QueryPerformanceTab tab={tab} />
    </PageContent>
  );
};

const QueryPerformanceTab: React.FunctionComponent<{
  tab: QueryPerformanceTabType;
}> = ({ tab }) => {
  switch (tab) {
    case "overview":
      return <QueryList />;
    case "search":
      return <QuerySearch />;
    case "explains":
      return <ExplainList />;
  }
};

export default QueryPerformance;
