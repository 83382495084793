import React from "react";

import { useLocation } from "react-router-dom";
import classNames from "classnames";

import type { Node, AnnotatedPlan } from "../../types/explain";

import { useCostMetric } from "../WithExplainCostMetric";
import { diff } from "../../utils/diff";
import { findPlanIndexes, toDiffablePlan } from "../../utils/explain";
import { formatNumber, formatMs } from "../../utils/format";
import { useSelectedNode } from "components/Explain/WithNodeSelection";
import Button from "components/Button";
import Callout from "components/Callout";

export const ExplainDiff = ({
  planA,
  planB,
}: {
  planA: AnnotatedPlan;
  planB: AnnotatedPlan;
}) => {
  const { hash } = useLocation();
  const usingComparisonPlan = hash.includes("compare");
  const [selectedNode, setSelected] = useSelectedNode();
  const selectedId = selectedNode?.extra.id;

  const planAIndexes = findPlanIndexes(planA.plan);
  const planBIndexes = findPlanIndexes(planB.plan);
  const allIndexes = Array.from(
    new Set(Array.from(planAIndexes).concat(Array.from(planBIndexes))),
  ).sort();
  const planDiff = diff(
    toDiffablePlan(planA.plan, allIndexes),
    toDiffablePlan(planB.plan, allIndexes),
    (a, b) => {
      return (
        a.line.trimStart() === b.line.trimStart() &&
        a.node["Index Name"] === b.node["Index Name"] &&
        a.node["Relation Name"] === b.node["Relation Name"] &&
        a.node.Relation === b.node.Relation
      );
    },
  );
  const hasDiff = planDiff.some((val) => val.kind != "equal");

  const metric = useCostMetric();

  return (
    <div className="px-4 pb-4">
      {!hasDiff && (
        <Callout className="mb-4">
          Both plans have an identical structure
        </Callout>
      )}
      <table className="w-full table-fixed">
        <tbody className="whitespace-pre font-mono">
          <tr>
            <th className="overflow-hidden text-ellipsis w-1/3">Plan A</th>
            <th className="overflow-hidden text-ellipsis w-1/3">Plan B</th>
            <th className="overflow-hidden text-ellipsis text-right w-1/6">
              Plan A: {metric}
            </th>
            <th className="overflow-hidden text-ellipsis text-right w-1/6">
              Plan B: {metric}
            </th>
          </tr>
          {planDiff.map((edit, idx) => {
            const aSelected =
              !usingComparisonPlan &&
              edit.kind != "insert" &&
              edit.a.content.node.extra.id === selectedId;
            const bSelected =
              usingComparisonPlan &&
              edit.kind != "delete" &&
              edit.b.content.node.extra.id === selectedId;
            switch (edit.kind) {
              case "insert":
                return (
                  <tr className="hover:bg-slate-100" key={idx}>
                    <td></td>
                    <td
                      className={classNames(
                        "overflow-hidden bg-green-300 border-l-4",
                        bSelected ? "border-[#337ab7]" : "border-green-300",
                      )}
                    >
                      <Button
                        bare
                        className="w-full text-left"
                        onClick={() =>
                          setSelected(
                            edit.b.content.node.extra.id,
                            "comparison",
                          )
                        }
                      >
                        {edit.b.content.line}
                        <sup>{edit.b.content.footnote}</sup>
                      </Button>
                    </td>
                    <td className="text-right"></td>
                    <td className="text-right">
                      <CostFormatted node={edit.b.content.node} />
                    </td>
                  </tr>
                );
              case "delete":
                return (
                  <tr className="hover:bg-slate-100" key={idx}>
                    <td
                      className={classNames(
                        "overflow-hidden bg-red-300 border-l-4",
                        aSelected ? "border-[#337ab7]" : "border-red-300",
                      )}
                    >
                      <Button
                        bare
                        className="w-full text-left"
                        onClick={() =>
                          setSelected(edit.a.content.node.extra.id, "main")
                        }
                      >
                        {edit.a.content.line}
                        <sup>{edit.a.content.footnote}</sup>
                      </Button>
                    </td>
                    <td></td>
                    <td className="text-right">
                      <CostFormatted node={edit.a.content.node} />
                    </td>
                    <td className="text-right"></td>
                  </tr>
                );
              case "equal":
                return (
                  <tr className="hover:bg-slate-100" key={idx}>
                    <td
                      className={classNames(
                        "overflow-hidden border-l-4",
                        aSelected ? "border-[#337ab7]" : "border-transparent",
                      )}
                    >
                      <Button
                        bare
                        className="w-full text-left"
                        onClick={() =>
                          setSelected(edit.a.content.node.extra.id, "main")
                        }
                      >
                        {edit.a.content.line}
                        <sup>{edit.a.content.footnote}</sup>
                      </Button>
                    </td>
                    <td
                      className={classNames(
                        "overflow-hidden border-l-4",
                        bSelected ? "border-[#337ab7]" : "border-transparent",
                      )}
                    >
                      <Button
                        bare
                        className="w-full text-left"
                        onClick={() =>
                          setSelected(
                            edit.b.content.node.extra.id,
                            "comparison",
                          )
                        }
                      >
                        {edit.b.content.line}
                        <sup>{edit.b.content.footnote}</sup>
                      </Button>
                    </td>
                    <td className="text-right">
                      <CostFormatted node={edit.a.content.node} />
                    </td>
                    <td className="text-right">
                      <CostFormatted node={edit.b.content.node} />
                    </td>
                  </tr>
                );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

const CostFormatted = ({ node }: { node: Node }) => {
  const metric = useCostMetric();

  if (metric === "Est. Cost") {
    const cost = node.extra.self["Total Cost"];
    return cost != null ? formatNumber(cost, 2) : "-";
  } else if (metric === "Runtime") {
    const cost = node.extra.self["Actual Total Time"];
    return cost != null ? formatMs(cost) : "-";
  } else if (metric === "I/O Time") {
    const cost = node.extra.self["I/O Read Time"];
    return cost != null ? formatMs(cost) : "-";
  } else if (metric == "Rows") {
    const cost = node["Actual Rows"];
    return cost != null ? formatNumber(cost) : "-";
  } else {
    throw new Error("unsupported metric");
  }
};

export default ExplainDiff;
