import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons";

import { useRouteTopLevelReference } from "utils/useRouteTopLevelReference";
import { usePrevious } from "utils/hooks";
import { useRoutes } from "utils/routes";

import PageOnboardingNav from "components/PageOnboardingNav";
import PageUserNav from "components/PageUserNav";
import {
  useCurrentOrganization,
  useCurrentServer,
} from "components/WithCurrentOrganization";

import NavItems from "../PageSideNav/NavItems";
import OrganizationSelect from "../PageSideNav/OrganizationSelect";

import styles from "./style.module.scss";

const PageSideNav = () => {
  const { root } = useRoutes();
  const [navOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    setNavOpen((navState) => !navState);
  };
  const location = useLocation();
  const lastLocation = usePrevious(location);
  useEffect(() => {
    if (location !== lastLocation) {
      setNavOpen(false);
    }
  }, [location, lastLocation]);

  const { humanId: serverId } = useCurrentServer(true) || {};
  const { databaseId } = useRouteTopLevelReference();

  const currentOrganization = useCurrentOrganization(true);
  const organizationSlug = currentOrganization?.slug;

  return (
    <div className={styles.logoAndNav}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <h1>
            <Link to={root()}>
              <span>pganalyze</span>
            </Link>
          </h1>
        </div>
        <a
          href=""
          className={styles.navOpenBtn}
          onClick={(evt) => {
            evt.preventDefault();
            toggleNav();
          }}
        >
          <div className="p-[20px] h-[60px]">
            <FontAwesomeIcon
              icon={navOpen ? faTimes : faBars}
              className="text-white text-[20px]"
            />
          </div>
        </a>
      </div>
      <div className={navOpen ? styles.navOpen : styles.navClosed}>
        <ul className={styles.menu}>
          <OrganizationSelect />
          <NavItems
            organizationSlug={organizationSlug}
            serverId={serverId}
            databaseId={databaseId}
          />
        </ul>
        <div>
          <PageOnboardingNav />
          <PageUserNav className={styles.userNav} />
        </div>
      </div>
    </div>
  );
};

export default PageSideNav;
