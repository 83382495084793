export function getOnboardingWaitMinutes(checkGroupAndName: string): number {
  switch (checkGroupAndName) {
    case "vacuum/inefficient_index_phase":
    case "vacuum/insufficient_vacuum_frequency":
      return 24 * 60;
    case "vacuum/xmin_horizon":
    case "vacuum/txid_wraparound":
    case "vacuum/mxid_wraparound":
      return 30;
    default:
      throw new Error(`unknown check: ${checkGroupAndName}`);
  }
}
