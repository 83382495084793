---
title: 'Step 2: Configure VPC Peering'
install_track_title: 'Log Insights: Setup (Aiven)'
backlink_href: /docs/log-insights/setup
backlink_title: 'Log Insights: Setup'
---

import imgAwsEditRouteTable from './aws-edit-route-table.png'

export const ImgAwsEditRouteTable = () => <img alt="Editing AWS VPC route table" src={imgAwsEditRouteTable} />

Once you have moved your database into a VPC, follow the [Aiven instructions to
set up VPC
peering](https://help.aiven.io/en/articles/778836-using-virtual-private-cloud-vpc-peering)
between your Aiven database VPC and your collector VPC. The details will vary depending
on the Service Cloud Provider you are using with Aiven.

If peering to a VPC on AWS, be sure to also edit the route table for your
collector VPC to add the destination IP range for your VPC and your Peering
connection target:

<ImgAwsEditRouteTable />

<Link className="btn btn-success" to="03_configure_collector">
  Continue to Step 3: Configure collector
</Link>
