import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";
import { useFeature } from "components/OrganizationFeatures";
import TreeMap, { TreeMapDataType } from "components/TreeMap";
import Loading from "components/Loading";

import {
  TableTreeMap as TableTreeMapType,
  TableTreeMapVariables,
} from "./types/TableTreeMap";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

type Props = {
  databaseId: string;
  organizationSlug: string;
};

const TableTreeMap: React.FunctionComponent<Props> = ({
  databaseId,
  organizationSlug,
}) => {
  const { organizationSubscription, databaseTable } = useRoutes();
  const hasSchemaStatsFeature = useFeature("schemaStats");
  const { error, loading, data } = useQuery<
    TableTreeMapType,
    TableTreeMapVariables
  >(QUERY, {
    variables: {
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const schemaTables = data.getSchemaTables;

  if (!hasSchemaStatsFeature) {
    return (
      <div className={styles.schemaStatsUnavailable}>
        <p>
          <strong>Schema Statistics</strong> are not included in your current
          plan.
          <br />
          <Link to={organizationSubscription(organizationSlug)}>
            Learn more about our Production plans.
          </Link>
        </p>
      </div>
    );
  }

  const treeMapData = schemaTables.map(
    ({ id, schemaName, tableName, sizeBytes }): TreeMapDataType => ({
      displayName: tableName,
      name: `${schemaName}.${tableName}`,
      link: databaseTable(databaseId, id),
      size: sizeBytes || 0,
    }),
  );

  return <TreeMap data={treeMapData} />;
};

export default TableTreeMap;
