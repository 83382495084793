import { ScaleLinear, ScaleTime } from "d3-scale";
import { stack, stackOrderNone, stackOffsetNone } from "d3-shape";

import moment, { Moment } from "moment-timezone";
import { formatTimestampLong } from "utils/format";
import { TickFormat, TickFormatFn } from "./Axis";
import { LineSeries } from "./Series";

export type ScreenLocation = {
  x: number;
  y: number;
};

export type Datum = [number, number | undefined | null];
export type Range = [number, number];
export type StackedDatum = [number, Range | undefined | null];

export type AnyDatum = Datum | StackedDatum;

export type Data = {
  [key: string]: Datum[];
};

export type DatumRef = {
  series: string;
  index: number;
  datum: Datum;
};

type InteractionValue = {
  domain: number;
  range: number;
};

export type InteractionPoint = {
  bottom: InteractionValue;
  left?: InteractionValue;
  right?: InteractionValue;
  nearby: DatumRef[];
};

export type StackedData = {
  [key: string]: StackedDatum[];
};

export type Layout = {
  x: number;
  y: number;
  size: {
    width: number;
    height: number;
  };
};

export type Scale = ScaleLinear<number, number> | ScaleTime<number, number>;

type BaseSeriesProps<T> = {
  xScale: Scale;
  yScale: Scale;
  fill: string;
  stroke: string;
  className: string;
  opts?: T;
};

export type SeriesProps<T = unknown> = BaseSeriesProps<T> & {
  data: Datum[];
};

export type StackedSeriesProps<T = unknown> = BaseSeriesProps<T> & {
  data: StackedDatum[];
};

export type SeriesType = React.ComponentType<
  SeriesProps | StackedSeriesProps
> & {
  stacked?: boolean;
  xPadding?: number;
};

export type SeriesConfig = {
  yAxis?: "left" | "right"; // xAxis is always bottom, default yAxis is left
  key: string; // id in old format--which array in data object to use
  type?: SeriesType; // default line
  label: string;
  tipLabel?: string; // label for tooltip, if different
  color?: string;
  className?: string;
  defaultDisabled?: boolean;
  opts?: any;
};

export const applySeriesDefaults = (config: SeriesConfig): SeriesConfig => {
  return {
    type: LineSeries,
    yAxis: "left",
    tipLabel: config.label,
    defaultDisabled: false,
    ...config,
  };
};

export type SeriesInfo = Omit<
  Required<SeriesConfig>,
  "yAxis" | "defaultDisabled" | "color" | "className"
> & {
  disabled: boolean;
  scale: Scale;
  tipFormat?: (value: number) => string;
  stroke: string;
  fill: string;
  className?: string; // Repeated here due to https://github.com/microsoft/TypeScript/issues/28339
};

export type AxisConfig = {
  format?: TickFormat;
  tipFormat?: TickFormat;
};

// Internally, our AxisConfig can take a TickFormat (i.e., a function or a
// built-in format), but we don't want to expose that in the component's public
// interface, since if we're using a built-in format for the tipFormat function,
// it should always be the same one as what we're using for the format function
// itself (and in these cases, tipFormat can be left empty to default to the
// format prop).
//
// Allow specifying only a custom format function for tipFormat, and not a
// built-in format.
export type UserAxisConfig = {
  format?: TickFormat;
  tipFormat?: TickFormatFn;
};

export const applyAxisDefaults = (config: AxisConfig): AxisConfig => {
  return {
    tipFormat: config.format,
    ...config,
  };
};

export const xValue = (d: AnyDatum): number => d[0];
export const yValue = (d: Datum): number | undefined | null => d[1];
export const yBottom = (d: AnyDatum): number | undefined | null =>
  Array.isArray(d[1]) ? d[1][0] : d[1];
export const yTop = (d: AnyDatum): number | undefined | null =>
  Array.isArray(d[1]) ? d[1][1] : d[1];

// this lets us filter null/undefined from an Array and have TypeScript
// recognize that Array elements will no longer be null; e.g.
//   const foo: (T | null)[] = [...];
//   const bar: T[] = foo.filter(isPresent);
export const isPresent = <T>(value: T | null | undefined): value is T =>
  value != null;
export const valueDefined = (v: number | undefined | null): boolean =>
  v !== undefined && v !== null && !isNaN(v);
export const defined = (d: AnyDatum): boolean => !!d && valueDefined(yTop(d));
export const valueNonZero = (v: number | undefined | null): boolean =>
  valueDefined(v) && v !== 0;

export const isStacked = (s: SeriesConfig): boolean => !!s.type?.stacked;
export const isLeftAxis = (s: SeriesConfig): boolean =>
  !s.yAxis || s.yAxis === "left";
export const isRightAxis = (s: SeriesConfig): boolean => s.yAxis === "right";

// Adjust data epoch timestamps from seconds (since 1970) to milliseconds
export const adjustTimestamps: (data: Data) => Data = (data) => {
  return Object.entries(data).reduce((adjusted, [key, seriesData]): Data => {
    adjusted[key] = seriesData.map((datum) => [
      xValue(datum) * 1000,
      yValue(datum),
    ]);
    return adjusted;
  }, {});
};

// Like d3's extent, but for a number of parallel series
export const extent: (
  data: AnyDatum[][],
  accessor: (d: AnyDatum) => number | undefined | null,
) => Range = (data, accessor) => {
  return data.reduce(
    ([min, max], values) => {
      if (!values) {
        return [min, max];
      }
      return [
        values.reduce((currMin, datum) => {
          const val = accessor(datum);
          return valueDefined(val) && typeof val === "number"
            ? Math.min(currMin, val)
            : currMin;
        }, min),
        values.reduce((currMax, datum) => {
          const val = accessor(datum);
          return valueDefined(val) && typeof val === "number"
            ? Math.max(currMax, val)
            : currMax;
        }, max),
      ];
    },
    [Infinity, -Infinity],
  );
};

export const interactionPointsEqual = (
  p1: InteractionPoint | undefined,
  p2: InteractionPoint | undefined,
): boolean => {
  if (p1 === undefined && p2 === undefined) {
    return true;
  }
  if (p1 === undefined || p2 === undefined) {
    return false;
  }

  if (
    !p1 !== !p2 ||
    !!p1.bottom != !!p2.bottom ||
    !!p1.left != !!p2.left ||
    !!p1.right != !!p2.right
  ) {
    return false;
  }
  if (!p1 && !p2) {
    return true;
  }

  return (
    p1.bottom.domain === p2.bottom.domain &&
    p1.left?.domain === p2.left?.domain &&
    p1.right?.domain === p2.right?.domain
  );
};

export const findDataNearXY = (
  seriesData: Map<SeriesInfo, Datum[]>,
  loc: ScreenLocation,
  xScale: Scale,
): DatumRef[] => {
  return Array.from(seriesData)
    .map(([series, data]) => {
      let minXDistance = Infinity;
      let minYDistance = Infinity;
      let min: Datum | undefined;
      let minIdx = -1;
      data.forEach((d, i) => {
        if (!defined(d)) {
          return;
        }
        const [x, y] = d;
        const xDistance = Math.abs(loc.x - xScale(x));
        const yDistance = Math.abs(loc.y - series.scale(y!));
        if (
          xDistance < minXDistance ||
          (xDistance === minXDistance && yDistance < minYDistance)
        ) {
          minXDistance = xDistance;
          minYDistance = yDistance;
          min = d;
          minIdx = i;
        }
      });
      return min ? { series: series.key, index: minIdx, datum: min } : null;
    })
    .filter(isPresent);
};

export const prepareStacked = (
  data: Data,
  stackedSeries: SeriesConfig[],
): StackedData | undefined => {
  if (stackedSeries.length <= 0) {
    return undefined;
  }
  // d3's built-in stacking assumes data shaped a certain way (namely, all stacked
  // series at a particular point shares an index); we could stack ourselves instead,
  // but massaging the data to make d3 happy allows us to take advantage of its other
  // stacking features like stream graphs eventually if we want
  const stackKeys = stackedSeries.map((s) => s.key);
  // N.B. we assume series have identical timestamps, and we ignore
  // data points after the shortest series ends if any
  const shortest = Object.entries(data)
    .filter(([key]) => stackKeys.includes(key))
    .map(([, value]) => value)
    .reduce<Datum[] | undefined>((shortest, curr) => {
      return !shortest || curr.length < shortest.length ? curr : shortest;
    }, undefined);
  if (shortest === undefined) {
    return undefined;
  }
  const stackable = shortest.map((datum, i) => {
    return [
      xValue(datum),
      stackKeys.reduce<{ [key: string]: number | null | undefined }>(
        (valMap, key) => {
          valMap[key] = yValue(data[key][i]);
          return valMap;
        },
        {},
      ),
    ];
  });

  const stacker = stack()
    .keys(stackKeys)
    .value((d, key) => d[1][key])
    .order(stackOrderNone)
    .offset(stackOffsetNone);
  // TODO: could not figure out the type issues here
  const stacked = stacker(stackable as any);
  const stackedData = stackKeys.reduce<StackedData>((currData, key, i) => {
    currData[key] = data[key].map<StackedDatum>((d, j) => {
      const isDefinedHere = defined(data[key][j]);
      // unfortunately d3's stacking turns null into 0, so we need to compensate
      // by checking ourselves
      return [
        xValue(d),
        isDefinedHere
          ? // Ignore the data property thats also present in the SeriesPoint type
            (stacked[i][j] as unknown as [number, number])
          : undefined,
      ];
    });
    return currData;
  }, {});

  return stackedData;
};

export const formatTime = (value: number | Date) => {
  return formatTimestampLong(moment(value));
};

const hasTimeComponent = (
  m: Moment,
  unit: moment.unitOfTime.DurationAs,
): boolean => {
  return m.clone().startOf(unit).isBefore(m);
};

// Adapted from d3's multiFormat example https://github.com/d3/d3-time-format#d3-time-format
export const formatTimeAdaptive = (d: Date | number) => {
  const m = moment(typeof d === "number" ? new Date(d) : d);
  return hasTimeComponent(m, "second")
    ? m.format(".SSS")
    : hasTimeComponent(m, "minute")
    ? m.format(":ss")
    : hasTimeComponent(m, "hour")
    ? m.format("hh:mm")
    : hasTimeComponent(m, "day")
    ? m.format("hh A")
    : hasTimeComponent(m, "month")
    ? hasTimeComponent(m, "week")
      ? m.format("ddd DD")
      : m.format("MMM DD")
    : hasTimeComponent(m, "year")
    ? m.format("MMMM")
    : m.format("YYYY");
};
