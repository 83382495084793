import React from "react";

import classNames from "classnames";

import styles from "./style.module.scss";

export type Impact = 1 | 2 | 3 | 4 | 5;

const ImpactRenderer: React.FunctionComponent<{
  impact: Impact | undefined;
}> = ({ impact }) => {
  return (
    <div className="flex">
      {Array(5)
        .fill(undefined)
        .map((_, i) => {
          return (
            <div
              key={i}
              className={classNames(
                "rounded-[2px] m-[2px] w-[5px] h-[18px]",
                // N.B.: when impact is undefined, i < impact is always false;
                // all segments are inactive
                i < impact ? styles.impactActive : styles.impactInactive,
              )}
            />
          );
        })}
    </div>
  );
};

export default ImpactRenderer;
