import React, { useMemo, useState } from "react";

import PanelTable from "components/PanelTable";
import Panel from "components/Panel";
import Grid, { GridColumn, NumberCell } from "components/Grid";
import CopyToClipboard from "components/CopyToClipboard";
import SQL from "components/SQL";

import { formatNumber, formatPercent } from "utils/format";
import { IndexType, IndexSelectionResultType } from "../util";

import ScanReference from "../ScanReference";
import IndexReference from "../IndexReference";
import { makeFilter } from "utils/filter";
import FilterSearch from "components/FilterSearch";
import Tip from "components/Tip";
import { useRoutes } from "utils/routes";
import { Link } from "react-router-dom";

const IndexDetails: React.FunctionComponent<{
  result: IndexSelectionResultType;
  databaseId: string;
  index: IndexType;
}> = ({ result, index, databaseId }) => {
  return (
    <div>
      <IndexInfoPanel index={index} databaseId={databaseId} />
      <IndexScansPanel result={result} index={index} />
      <IndexQueriesPanel
        result={result}
        databaseId={databaseId}
        index={index}
      />
    </div>
  );
};

const IndexInfoPanel: React.FunctionComponent<{
  index: IndexType;
  databaseId: string;
}> = ({ index, databaseId }) => {
  const { databaseIndex } = useRoutes();

  const ddl = index.ddl + ";";
  const status =
    index.existing && index.selected
      ? "Existing index to keep"
      : index.existing
      ? "Existing index to drop"
      : index.selected
      ? "New index to add"
      : "Other considered index";

  const name = index.existing ? (
    <Link to={databaseIndex(databaseId, index.pganalyzeId)}>{index.name}</Link>
  ) : (
    <>&mdash;</>
  );

  return (
    <Panel title="Index Details">
      <PanelTable horizontal borders>
        <tbody>
          <tr>
            <th>Name</th>
            <td className="font-mono">{name}</td>
            <th>Structure</th>
            <td className="font-mono">{index.structure}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{status}</td>
            <th>Index Write Overhead</th>
            <td>{formatNumber(index.writeOverhead, 2)}</td>
          </tr>
        </tbody>
      </PanelTable>
      <div className="p-2 bg-[#f5f5f8]">
        <CopyToClipboard
          className="block mt-1 float-right"
          label="Copy command"
          content={ddl}
        />
        <SQL inline className="text-base" sql={ddl} />
      </div>
    </Panel>
  );
};

const IndexScansPanel: React.FunctionComponent<{
  result: IndexSelectionResultType;
  index: IndexType;
}> = ({ result, index }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const scanData = useMemo(() => {
    return index.scans.map((scanCost) => {
      const scan = result.scans[scanCost.scanId];
      const estCurrCost = scan.bestExistingCost;
      const estNewCost = scan.bestSelectedCost;
      const indexChanged = estCurrCost.indexId !== estNewCost.indexId;
      return {
        ...scan,
        cost: scanCost.cost,
        combinedClauses: scan.combinedExpression,
        indexChanged,
        estCurrCost: estCurrCost.cost,
        currIndex: estCurrCost.indexId,
        estNewCost: estNewCost.cost,
        newIndex: estNewCost.indexId,
      };
    });
  }, [index.scans, result]);
  // If there are no scans that are expected to use this index, show all scans
  // by default. There's little point in forcing users to click "show all" in
  // this situation.
  const thisIndexScanCount = scanData.filter(
    (scan) => scan.newIndex === index.id,
  ).length;
  const [showAll, setShowAll] = useState(thisIndexScanCount === 0);

  const filteredScanData = scanData
    .filter(
      makeFilter(
        searchTerm,
        "combinedClauses",
        "label",
        "currIndex",
        "newIndex",
      ),
    )
    .filter((scan) => {
      return showAll || scan.newIndex === index.id;
    });
  const noRowsText =
    scanData.length === 0
      ? `No scans found to ${showAll ? "be using" : "prefer"} this index`
      : "No matching scans found";

  const columns: GridColumn<
    (typeof filteredScanData)[number],
    keyof (typeof filteredScanData)[number]
  >[] = [
    {
      field: "combinedClauses",
      header: "Scan Expression",
      renderer: function ScanDetailsCell({ rowData }) {
        return <ScanReference className="w-full" scan={rowData} />;
      },
    },
    {
      field: "scansPerMin",
      header: "Est. Scans/min",
      style: "number",
      renderer: NumberCell.precision(2),
    },
    {
      field: "estCurrCost",
      header: "Est. Cost",
      style: "number",
      nullValue: "n/a",
      renderer: NumberCell,
    },
    {
      field: "currIndex",
      header: "",
      disableSort: true,
      title: ({ fieldData }) => {
        if (!fieldData) {
          return "Sequential Scan";
        }

        return result.indexes[fieldData].structure;
      },
      renderer: function IndexCell({ fieldData }) {
        return (
          <IndexReference
            terse
            className="w-full"
            index={result.indexes[fieldData]}
          />
        );
      },
    },
    {
      field: "estNewCost",
      header: "Est. New Cost",
      style: "number",
      nullValue: "n/a",
      renderer: function EstNewCostCell({ rowData, fieldData }) {
        if (!rowData.indexChanged) {
          return "no change";
        }
        return formatNumber(fieldData);
      },
    },
    {
      field: "newIndex",
      header: "",
      disableSort: true,
      title: ({ rowData, fieldData }) => {
        if (!fieldData) {
          return "Sequential Scan";
        }
        if (!rowData.indexChanged) {
          return undefined;
        }

        return result.indexes[fieldData].structure;
      },
      renderer: function IndexCell({ rowData, fieldData }) {
        if (!rowData.indexChanged) {
          return null;
        }
        return (
          <IndexReference
            terse
            className="w-full"
            index={result.indexes[fieldData]}
          />
        );
      },
    },
  ];

  if (showAll) {
    columns.splice(2, 0, {
      field: "cost",
      header: "This Index Cost",
      style: "number",
      renderer: NumberCell,
    });
  }
  const gridClassName = showAll
    ? "grid-cols-[1fr_10%_10%_10%_40px_10%_40px]"
    : "grid-cols-[1fr_10%_10%_40px_10%_40px]";
  return (
    <Panel
      title="Scans"
      secondaryTitle={
        <>
          <div>
            <label>
              <input
                type="checkbox"
                className="!mr-1"
                checked={showAll}
                onChange={(evt) => setShowAll(evt.target.checked)}
              />
              Show all scans{" "}
              <Tip
                content={
                  <>
                    If checked, shows all scans that could potentially use this
                    index, even if this index is not the best option in the
                    current index selection recommendation.
                    <br />
                    If unchecked, only shows scans that are expected to use this
                    index (i.e., for which this index offers the lowest cost).
                  </>
                }
              />
            </label>
          </div>
          <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
        </>
      }
    >
      <Grid
        className={gridClassName}
        noRowsText={noRowsText}
        striped
        defaultSortBy="cost"
        data={filteredScanData}
        columns={columns}
      />
    </Panel>
  );
};

const IndexQueriesPanel: React.FunctionComponent<{
  databaseId: string;
  result: IndexSelectionResultType;
  index: IndexType;
}> = ({ databaseId, result, index }) => {
  const { databaseQuery } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");
  const queryData = useMemo(() => {
    return index.scans.flatMap((scanCost) => {
      const scan = result.scans[scanCost.scanId];
      const estCurrCost = scan.bestExistingCost;
      const estNewCost = scan.bestSelectedCost;
      const indexChanged = estCurrCost.indexId !== estNewCost.indexId;
      return scan.queries.map((query) => {
        return {
          scan,
          scanLabel: scan.label,
          queryId: query.id,
          normalizedQuery: query.normalizedQuery,
          truncatedQuery: query.truncatedQuery,
          callsPerMinute: query.callsPerMinute,
          pctOfTotal: query.pctOfTotal,
          avgTime: query.avgTime,
          cost: scanCost.cost,
          indexChanged,
          estCurrCost: estCurrCost.cost,
          estNewCost: estNewCost.cost,
          newIndex: estNewCost.indexId,
        };
      });
    });
  }, [index.scans, result]);
  // If there are no scans that are expected to use this index, show all scans
  // by default. There's little point in forcing users to click "show all" in
  // this situation.
  const thisIndexScanCount = queryData.filter(
    (query) => query.newIndex === index.id,
  ).length;
  const [showAll, setShowAll] = useState(thisIndexScanCount === 0);

  const filteredQueryData = queryData
    .filter(
      makeFilter(searchTerm, "normalizedQuery", "truncatedQuery", "scanLabel"),
    )
    .filter((query) => {
      return showAll || query.newIndex === index.id;
    });
  const noRowsText =
    queryData.length === 0
      ? `No queries found to ${showAll ? "be using" : "prefer"} this index`
      : "No matching queries found";

  const columns: GridColumn<
    (typeof filteredQueryData)[number],
    keyof (typeof filteredQueryData)[number]
  >[] = [
    {
      field: "truncatedQuery",
      header: "Query",
      style: "query",
      renderer: function QueryTextCell({ fieldData, rowData }) {
        const url = databaseQuery(databaseId, rowData.queryId);
        return (
          <Link title={rowData.normalizedQuery} to={url}>
            {fieldData}
          </Link>
        );
      },
    },
    {
      field: "scanLabel",
      header: "Scan Cost Change",
      disableSort: true,
      title: ({ rowData }) => {
        return rowData.scan.combinedExpression;
      },
      renderer: function IndexCell({ rowData }) {
        return (
          <ScanReference scan={rowData.scan}>
            {" "}
            {rowData.estCurrCost != rowData.estNewCost ? (
              <>
                {formatNumber(rowData.estCurrCost)}
                {" → "}
                {formatNumber(rowData.estNewCost)}
              </>
            ) : (
              "no change"
            )}
          </ScanReference>
        );
      },
    },
    {
      field: "avgTime",
      header: "Avg. Time (ms)",
      style: "number",
      defaultSortOrder: "desc",
      renderer: NumberCell.precision(2),
    },
    {
      field: "callsPerMinute",
      header: "Calls / Min",
      style: "number",
      defaultSortOrder: "desc",
      renderer: NumberCell.precision(3),
    },
    {
      field: "pctOfTotal",
      header: "% All Runtime",
      style: "number",
      defaultSortOrder: "desc",
      renderer: function PcfOfTotalCell({ fieldData }) {
        return formatPercent(fieldData / 100, 2);
      },
    },
  ];
  const gridClassName = "grid-cols-[1fr_15%_10%_10%_12%]";

  return (
    <Panel
      title="Affected Queries"
      secondaryTitle={
        <>
          <div>
            <label>
              <input
                type="checkbox"
                className="!mr-1"
                checked={showAll}
                onChange={(evt) => setShowAll(evt.target.checked)}
              />
              Show all queries{" "}
              <Tip
                content={
                  <>
                    If checked, shows all queries that could potentially use
                    this index, even if this index is not the best option in the
                    current index selection recommendation.
                    <br />
                    If unchecked, only shows queries that are expected to use
                    this index (i.e., for which this index offers the lowest
                    cost).
                  </>
                }
              />
            </label>
          </div>
          <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
        </>
      }
    >
      <Grid
        className={gridClassName}
        noRowsText={noRowsText}
        striped
        defaultSortBy="pctOfTotal"
        data={filteredQueryData}
        columns={columns}
      />
    </Panel>
  );
};

export default IndexDetails;
