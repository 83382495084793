import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import TreeMap from "components/TreeMap";

import {
  SchemaTableListTreeMap,
  SchemaTableListTreeMapVariables,
  SchemaTableListTreeMap_getSchemaTables,
} from "./types/SchemaTableListTreeMap";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

interface TreeMapDataType {
  displayName?: string;
  name: string;
  link: string;
  size: number;
}

interface Props {
  databaseId: string;
}

const TableTreeMap: React.FunctionComponent<Props> = ({ databaseId }) => {
  const { data, loading, error } = useQuery<
    SchemaTableListTreeMap,
    SchemaTableListTreeMapVariables
  >(QUERY, { variables: { databaseId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const treeMapData: Array<TreeMapDataType> = data.getSchemaTables.map(
    ({
      id,
      schemaName,
      tableName,
      sizeBytes,
    }: SchemaTableListTreeMap_getSchemaTables): TreeMapDataType => ({
      displayName: tableName,
      name: `${schemaName}.${tableName}`,
      link: `/databases/${databaseId}/tables/${id}`,
      size: sizeBytes || 0,
    }),
  );

  return <TreeMap className={styles.treemap} data={treeMapData} />;
};

export default TableTreeMap;
