import React from "react";

import pluralize from "pluralize";

import RadialStepCounter from "components/RadialStepCounter";

import styles from "./style.module.scss";

const OnboardingProgressIndicator: React.FunctionComponent<{
  stepsRemaining: number;
  trialExpired: boolean;
}> = ({ stepsRemaining, trialExpired }) => {
  let title, subtitle;
  if (trialExpired) {
    title = "Trial expired";
    subtitle = "Upgrade or renew trial";
  } else if (stepsRemaining === 0) {
    title = "Setup completed";
    subtitle = "Upgrade to paid plan";
  } else {
    title = "Set up pganalyze";
    subtitle = pluralize("remaining task", stepsRemaining, true);
  }

  return (
    <div className={styles.wrapper}>
      <RadialStepCounter
        className={styles.stepCounter}
        size={48}
        steps={3}
        completedSteps={3 - stepsRemaining}
      />
      <div className={styles.label}>
        <div className={styles.setupTitle}>{title}</div>
        <div className={styles.setupSubtitle}>{subtitle}</div>
      </div>
    </div>
  );
};

export default OnboardingProgressIndicator;
