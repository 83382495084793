import React from "react";

import Popover from "components/Popover";

import { InfoIcon } from "components/Icons";

const Tip: React.FunctionComponent<{
  content: React.ReactNode;
  className?: string;
}> = ({ content, className }) => {
  return (
    <Popover content={content}>
      <InfoIcon className={className} />
    </Popover>
  );
};

export default Tip;
