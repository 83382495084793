import React from "react";

import moment, { Moment } from "moment";
import { times } from "lodash";
import Button from "components/Button";

const DayPicker = ({
  earliest,
  latest,
  ...rest
}: {
  earliest?: Moment;
  latest?: Moment;
  rangeStart?: Moment;
  rangeEnd?: Moment;
  prospectiveRangeStart?: Moment;
  prospectiveRangeEnd?: Moment;
  onDayEnter: (day: Moment) => void;
  onDayLeave: (day: Moment) => void;
  onDayClick: (day: Moment) => void;
}) => {
  const thisMonth = latest;
  const prevMonth = latest.clone().subtract(1, "month");
  return (
    <div className="py-2 px-3 border-t flex gap-6">
      <CalendarMonth
        month={prevMonth}
        earliest={earliest}
        latest={latest}
        {...rest}
      />
      <CalendarMonth
        month={thisMonth}
        earliest={earliest}
        latest={latest}
        {...rest}
      />
    </div>
  );
};

const SUNDAY = 0;
const SATURDAY = 6;

const CalendarMonth = ({
  month,
  earliest,
  latest,
  rangeStart,
  rangeEnd,
  prospectiveRangeStart,
  prospectiveRangeEnd,
  onDayEnter,
  onDayLeave,
  onDayClick,
}: {
  month: Moment;
  earliest?: Moment;
  latest?: Moment;
  rangeStart?: Moment;
  rangeEnd?: Moment;
  prospectiveRangeStart?: Moment;
  prospectiveRangeEnd?: Moment;
  onDayEnter: (day: Moment) => void;
  onDayLeave: (day: Moment) => void;
  onDayClick: (day: Moment) => void;
}) => {
  const firstOfMonth = month.clone().startOf("month");
  const firstWeekday = firstOfMonth.day();
  const beforeFirstFiller =
    firstWeekday === SUNDAY ? null : (
      <div style={{ gridColumn: `1 / span ${firstWeekday}` }} />
    );

  const lastOfMonth = month.clone().endOf("month");
  const lastWeekday = lastOfMonth.day();
  const afterLastFiller =
    lastWeekday === SATURDAY ? null : (
      <div style={{ gridColumn: `span ${SATURDAY - lastWeekday}` }} />
    );
  const totDays = firstOfMonth.daysInMonth();
  const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const label = month.format("MMMM YYYY");

  return (
    <div className="w-56">
      <div className="text-base ml-1.5 mb-3">{label}</div>
      <div className="grid grid-cols-7 justify-items-stretch items-stretch">
        {days.map((d) => {
          return (
            <div
              key={d}
              className="w-[30px] h-6 text-center text-xs text-[#8b9898]"
            >
              {d}
            </div>
          );
        })}
        {beforeFirstFiller}
        {times(totDays, (n) => {
          const dayOfMonth = n + 1;
          const dayDate = month.clone().date(dayOfMonth).startOf("day");
          const context = {
            earliest,
            latest,
            rangeStart,
            rangeEnd,
            prospectiveRangeStart,
            prospectiveRangeEnd,
          };
          const kind = getDayKind(dayDate, context);
          return (
            <CalendarDay
              key={dayOfMonth}
              day={dayDate}
              kind={kind}
              onDayEnter={onDayEnter}
              onDayLeave={onDayLeave}
              onDayClick={onDayClick}
            />
          );
        })}
        {afterLastFiller}
      </div>
    </div>
  );
};

type CalendarDayKind =
  | "default"
  | "disabled" // outside of the window of available data
  | "selection full" // one-day selection
  | "selection start" // beginning of multi-day selection
  | "selection middle" // middle of multi-day selection
  | "selection end" // end of multi-day selection
  | "prospective selection full" // one day prospective selection
  | "prospective selection start" // beginning of multi-day prospective selection
  | "prospective selection middle" // middleof multi-day prospective selection
  | "prospective selection end"; // end of multi-day prospective selection

type CalendarContext = {
  earliest: Moment;
  latest: Moment;
  rangeStart?: Moment;
  rangeEnd?: Moment;
  prospectiveRangeStart?: Moment;
  prospectiveRangeEnd?: Moment;
};

function getDayKind(day: Moment, context: CalendarContext): CalendarDayKind {
  if (
    day.isBefore(context.earliest, "day") ||
    day.isAfter(context.latest, "day")
  ) {
    return "disabled";
  }
  if (context.prospectiveRangeStart && context.prospectiveRangeEnd) {
    if (
      day.isSameOrAfter(context.prospectiveRangeStart, "day") &&
      day.isSameOrBefore(context.prospectiveRangeEnd, "day")
    ) {
      if (
        context.prospectiveRangeStart.isSame(context.prospectiveRangeEnd, "day")
      ) {
        // if we have a one-day prospective selection but there's already an existing selection,
        // we want to match styling (corner rounding) to the existing selection
        if (
          !context.rangeStart ||
          !context.rangeEnd ||
          day.isBefore(context.rangeStart) ||
          day.isAfter(context.rangeEnd) ||
          context.rangeStart.isSame(context.rangeEnd, "day")
        ) {
          return "prospective selection full";
        } else if (
          day.isAfter(context.rangeStart, "day") &&
          context.rangeStart.isBefore(context.rangeEnd, "day")
        ) {
          return "prospective selection middle";
        } else if (day.isSame(context.rangeStart, "day")) {
          return "prospective selection start";
        } else if (day.isSame(context.rangeEnd, "day")) {
          return "prospective selection end";
        }
      } else if (day.isSame(context.prospectiveRangeStart, "day")) {
        return "prospective selection start";
      } else if (day.isSame(context.prospectiveRangeEnd, "day")) {
        return "prospective selection end";
      } else {
        return "prospective selection middle";
      }
    }
  }
  if (context.rangeStart && context.rangeEnd) {
    if (
      day.isSameOrAfter(context.rangeStart, "day") &&
      day.isSameOrBefore(context.rangeEnd, "day")
    ) {
      if (context.rangeStart.isSame(context.rangeEnd, "day")) {
        return "selection full";
      } else if (day.isSame(context.rangeStart, "day")) {
        return "selection start";
      } else if (day.isSame(context.rangeEnd, "day")) {
        return "selection end";
      } else {
        return "selection middle";
      }
    }
  }
  return "default";
}

const CalendarDay = ({
  day,
  kind,
  onDayEnter,
  onDayLeave,
  onDayClick,
}: {
  day: Moment;
  kind: CalendarDayKind;
  onDayEnter: (day: Moment) => void;
  onDayLeave: (day: Moment) => void;
  onDayClick: (day: Moment) => void;
}) => {
  const dayOfMonth = day.date();
  if (kind === "disabled") {
    return (
      <div className="text-[#dce0e0] min-w-[30px] h-[34px] flex justify-center items-center">
        {dayOfMonth}
      </div>
    );
  }
  const isToday = day.isSame(moment(), "day");

  const classNames: string[] = ["min-w-[30px]", "h-[34px]"];
  if (isToday) {
    classNames.push("!font-bold !text-[#d0021b]");
  }
  if (kind.startsWith("prospective selection")) {
    classNames.push("!bg-[#409bb8]", "!text-[#d0e7ed]");
  } else if (kind.startsWith("selection")) {
    classNames.push("!bg-[#d0e7ed]", "!text-[#1282a6]");
  }
  if (kind.endsWith("full")) {
    classNames.push("rounded-lg");
  } else if (kind.endsWith("start")) {
    classNames.push("rounded-l-md");
  } else if (kind.endsWith("end")) {
    classNames.push("rounded-r-md");
  }

  function handleMouseEnter() {
    onDayEnter(day);
  }
  function handleMouseLeave() {
    onDayLeave(day);
  }
  function handleClick() {
    onDayClick(day);
  }

  return (
    <Button
      bare
      className={classNames.join(" ")}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {dayOfMonth}
    </Button>
  );
};

export default DayPicker;
