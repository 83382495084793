---
title: 'Step 2: Enable pg_stat_statements'
install_track_title: Installation Guide (Self-Managed)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import InstallChoice from '../../components/InstallChoice'
import styles from '../../style.module.scss'

import imgLogoUbuntu from '../../images/logo_ubuntu.png'
import imgLogoRedhat from '../../images/logo_redhat.svg'
import imgLogoDocker from '../../images/logo_docker.svg'
import imgLogoAL2 from '../../images/aws-ec2.svg'

export const SelectCollectorPlatform = () => {
  return (
    <InstallChoice
      docsRoot={false}
      items={[
        {
          link: "02_enable_pg_stat_statements_deb",
          img: imgLogoUbuntu,
          providerName: "Ubuntu & Debian",
        },
        {
          link: "02_enable_pg_stat_statements_yum",
          img: imgLogoRedhat,
          providerName: "RHEL, CentOS & Fedora",
        },
        {
          link: "02_enable_pg_stat_statements_yum",
          img: imgLogoAL2,
          providerName: "Amazon Linux 2",
        },
        {
          link: "02_enable_pg_stat_statements_docker",
          img: imgLogoDocker,
          providerName: "Docker",
        },
      ]}
    />
  )
}

In this step we setup [pg_stat_statements](http://www.postgresql.org/docs/current/static/pgstatstatements.html), which is used by pganalyze to collect Postgres query statistics.

To continue, we need to know where your collector is running:

<SelectCollectorPlatform />

<br />
If your Postgres runs somewhere else, <a href="mailto:support@pganalyze.com">
  let us know
</a> & we're happy to help.
