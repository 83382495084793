import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import pluralize from "pluralize";

import { useRoutes } from "utils/routes";

import { useCurrentOrganization } from "components/WithCurrentOrganization";
import { Flash, useSetFlashes } from "components/WithFlashes";

const WarnIfServerOverage: React.FunctionComponent = () => {
  const { organizationSubscription } = useRoutes();
  const currentOrganization = useCurrentOrganization(true);
  const setFlashes = useSetFlashes();

  const { slug, serverLimit, serverCountForBilling, skipServerOverageWarning } =
    currentOrganization ?? {};
  const billingOverviewPath = organizationSubscription(slug);
  const skipFlash =
    !slug ||
    serverLimit == null ||
    skipServerOverageWarning ||
    !currentOrganization?.planInfo.activeSubscription;

  useEffect(() => {
    const overageFlash: Flash = {
      level: "alert",
      msg: (
        <span>
          Your subscription includes {pluralize("server", serverLimit, true)}{" "}
          total, but {pluralize("server", serverCountForBilling, true)} have
          submitted statistics in the last 24h. Please{" "}
          <Link to={billingOverviewPath}>adjust your subscription</Link>.
        </span>
      ),
      persist: true,
    };
    if (!skipFlash && serverCountForBilling > serverLimit) {
      setFlashes((currFlashes) => currFlashes.concat(overageFlash));
    }
    return () => {
      setFlashes((currFlashes) => currFlashes.filter((f) => f != overageFlash));
    };
  }, [
    setFlashes,
    skipFlash,
    serverLimit,
    serverCountForBilling,
    billingOverviewPath,
  ]);
  return null;
};

export default WarnIfServerOverage;
