import React, { useState } from "react";

import Panel from "components/Panel";
import { StatusKPITiles } from "../KPITiles";
import { useQuery } from "@apollo/client";
import {
  GetIndexAdvisorStatus,
  GetIndexAdvisorStatusVariables,
  GetIndexAdvisorStatus_getIndexAdvisorStatus_queriesWithErrors,
  GetIndexAdvisorStatus_getIndexAdvisorStatus_tableStats,
} from "./types/GetIndexAdvisorStatus";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import QUERY from "./Query.graphql";
import Loading from "components/Loading";
import Grid, { NoDataGridContainer } from "components/Grid";
import { formatPercent, formatTimestampLong } from "utils/format";
import { useRoutes } from "utils/routes";
import { Link } from "react-router-dom";
import FilterSearch from "components/FilterSearch";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeFilter } from "utils/filter";

const Status: React.FunctionComponent<{
  databaseId: string;
}> = ({ databaseId }) => {
  const { data, loading, error } = useQuery<
    GetIndexAdvisorStatus,
    GetIndexAdvisorStatusVariables
  >(QUERY, {
    variables: {
      databaseId,
    },
  });
  const stats = data?.getIndexAdvisorStatus;
  return (
    <>
      <StatusKPITiles
        loading={loading || !!error}
        analyzedCount={stats?.analyzedCount}
        processedTables={stats?.processedTables}
        totalTables={stats?.totalTables}
        lastProcessed={stats?.lastProcessed}
        analyzedQueriesPercentage={stats?.analyzedQueriesPercentage}
      />
      <TablesProcessedGrid
        loading={loading}
        error={error}
        data={stats?.tableStats}
        databaseId={databaseId}
      />
      <QueriesWithErrorsGrid
        loading={loading}
        error={error}
        data={stats?.queriesWithErrors}
        databaseId={databaseId}
      />
    </>
  );
};

const QueriesWithErrorsGrid: React.FunctionComponent<{
  loading: boolean;
  error: Error;
  data: GetIndexAdvisorStatus_getIndexAdvisorStatus_queriesWithErrors[];
  databaseId: string;
}> = ({ loading, error, data, databaseId }) => {
  const { databaseQuery } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");
  if (loading || error) {
    return (
      <Panel title="Queries With Errors">
        <Loading error={!!error} />
      </Panel>
    );
  }
  if (data.length === 0) {
    return (
      <Panel title="Queries With Errors">
        <NoDataGridContainer>No errors found</NoDataGridContainer>
      </Panel>
    );
  }

  const filteredData = data.filter((dat) => {
    // Not using makeFilter as it needs to access a nested value
    if (searchTerm === "") {
      return true;
    }
    return (
      dat["error"].toString().toLowerCase().includes(searchTerm) ||
      dat["tableNames"].toString().toLowerCase().includes(searchTerm) ||
      dat["postgresRole"]["name"].toString().toLowerCase().includes(searchTerm)
    );
  });
  return (
    <Panel
      title={`Queries With Errors (${filteredData.length})`}
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <Grid
        className="grid-cols-[1fr_120px_400px_150px]"
        data={filteredData}
        defaultSortBy="pctOfTotal"
        columns={[
          {
            field: "truncatedQuery",
            header: "Query",
            className: "font-[monospace]",
            renderer: function QueryCell({ fieldData, rowData }) {
              return (
                <Link
                  to={databaseQuery(databaseId, rowData.id.toString())}
                  title={fieldData}
                >
                  {fieldData}
                </Link>
              );
            },
          },
          {
            field: "postgresRole",
            header: "Role",
            nullValue: "-",
            renderer: function RoleCell({ fieldData }) {
              return (
                <div
                  className="text-ellipsis overflow-hidden"
                  title={fieldData.name}
                >
                  {fieldData.name}
                </div>
              );
            },
          },
          {
            field: "error",
            header: "Error",
            renderer: function ErrorCell({ fieldData }) {
              return <span title={fieldData}>{fieldData}</span>;
            },
          },
          {
            field: "pctOfTotal",
            header: "% of all runtime",
            style: "number",
            renderer: ({ fieldData }) => formatPercent(fieldData / 100),
            defaultSortOrder: "desc",
          },
        ]}
        pageSize={10}
      />
    </Panel>
  );
};

const TablesProcessedGrid: React.FunctionComponent<{
  loading: boolean;
  error: Error;
  data: GetIndexAdvisorStatus_getIndexAdvisorStatus_tableStats[];
  databaseId: string;
}> = ({ loading, error, data, databaseId }) => {
  const { databaseTableIndexes } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");
  if (loading || error) {
    return (
      <Panel title="Index Advisor Status per Table">
        <Loading error={!!error} />
      </Panel>
    );
  }

  const filteredData = data.filter(makeFilter(searchTerm, "tableName"));
  return (
    <Panel
      title={`Index Advisor Status per Table (${filteredData.length})`}
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <Grid
        className="grid-cols-[1fr_160px_160px_180px]"
        data={filteredData}
        defaultSortBy="insightsCount"
        columns={[
          {
            field: "tableName",
            header: "Table",
            renderer: function TableNameCell({ fieldData, rowData }) {
              return (
                <Link
                  to={databaseTableIndexes(databaseId, rowData.id.toString())}
                >
                  {fieldData}
                </Link>
              );
            },
          },
          {
            field: "scansCount",
            header: "Scans on table",
            style: "number",
            nullValue: "n/a",
            defaultSortOrder: "desc",
            tip: "The number of distinct scans from the query workload on this table.",
          },
          {
            field: "insightsCount",
            header: "Insights",
            style: "number",
            nullValue: "n/a",
            defaultSortOrder: "desc",
            tip: "Total insights count of the table. This includes both Missing Index and Unused Index insights.",
          },
          {
            field: "lastRun",
            header: "Last Run",
            renderer: function LastRunCell({ fieldData, rowData }) {
              return (
                <>
                  {fieldData
                    ? formatTimestampLong(moment(fieldData * 1000))
                    : "n/a"}
                  {rowData.failed && (
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      title="Last run failed"
                      className="ml-2"
                    />
                  )}
                </>
              );
            },
            tip: "The last time the Indexing Engine processed this table to find insights.",
          },
        ]}
        pageSize={10}
      />
    </Panel>
  );
};

export default Status;
