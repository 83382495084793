---
title: 'Step 1: Check for auto_explain'
install_track_title: 'Automated EXPLAIN: Setup (Crunchy Bridge)'
backlink_href: /docs/explain/setup
backlink_title: 'Automated EXPLAIN: Setup'
---

import Step01InAppCB from './_01_in_app.mdx'
import Step01PublicCB from './_01_public.mdx'

The recommended Automated EXPLAIN configuration for Crunchy Bridge is to use
the Postgres `auto_explain` contrib module. This ensures accurate and detailed
plan information, including runtime information (as gathered by EXPLAIN ANALYZE). With our
recommended configuration (presented in a later step), performance overhead is minimal.

<PublicOnly>
   <Step01PublicCB />
</PublicOnly>
<InAppOnly>
   <Step01InAppCB autoExplainEnabled={props.autoExplainEnabled} />
</InAppOnly>
