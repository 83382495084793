import { useEffect, useState } from "react";

import moment, { Moment } from "moment";
import { useLocalStorage, useUnmounted } from "./hooks";

export const COLLECTOR_CHANGELOG_URL =
  "https://github.com/pganalyze/collector/blob/main/CHANGELOG.md#changelog";

export const COLLECTOR_UPGRADE_URL =
  "https://pganalyze.com/docs/collector/upgrading";

const LATEST_RELEASE_URL =
  "https://api.github.com/repos/pganalyze/collector/releases/latest";

function getReleaseInfoURL(collectorTag: string): string {
  return `https://api.github.com/repos/pganalyze/collector/releases/tags/${collectorTag}`;
}

export type CollectorReleaseInfo = {
  publishedAt: Moment;
  tagName: string;
};

export type CollectorFreshness =
  | "latest"
  | "recent" /* less than six months old */
  | "outdated"
  | "very outdated";

export type CollectorFreshnessInfo = {
  latestRelease: CollectorReleaseInfo;
  usedRelease: CollectorReleaseInfo;
  freshness: CollectorFreshness;
};

export function useCollectorInfo(
  usedVersion: string | undefined,
): CollectorFreshnessInfo | undefined {
  const [cachedInfo, setCachedInfo] = useLocalStorage("collectorReleaseCache");
  const usedTagName = `v${usedVersion}`;
  const cachedUsedInfo = cachedInfo?.collectorInfo.used.find(
    (rel) => rel.tagName === usedTagName,
  );
  const cachedLatestInfo = cachedInfo?.collectorInfo.latest;
  const isCachedLatestValid =
    cachedLatestInfo &&
    moment
      .unix(cachedLatestInfo.cachedAt)
      .isAfter(moment().subtract(24, "hours"));

  const freshLatestInfo = useCollectorReleaseInfo("latest", {
    skip: isCachedLatestValid,
  });
  const freshUsedInfo = useCollectorReleaseInfo(usedTagName, {
    skip: !!cachedUsedInfo,
  });

  const usedRelease = cachedUsedInfo
    ? parseCachedVersionInfo(cachedUsedInfo)
    : freshUsedInfo;
  const latestRelease =
    cachedLatestInfo && isCachedLatestValid
      ? parseCachedVersionInfo(cachedLatestInfo)
      : freshLatestInfo;

  const freshness =
    usedRelease && latestRelease
      ? getFreshness(latestRelease, usedRelease)
      : undefined;

  useEffect(() => {
    if (isCachedLatestValid || !freshLatestInfo) {
      return;
    }
    if (cachedUsedInfo || !freshUsedInfo) {
      return;
    }

    const updatedUsedList =
      cachedInfo?.collectorInfo.used.filter((info) => {
        // prune old release info on access: this won't change, but we don't
        // need to take up localStorage space indefinitely
        return (
          info.tagName === usedTagName ||
          moment.unix(info.cachedAt).isAfter(moment().subtract(6, "months"))
        );
      }) ?? [];

    if (!cachedUsedInfo && usedRelease) {
      updatedUsedList.push({
        cachedAt: moment().unix(),
        publishedAt: usedRelease.publishedAt.unix(),
        tagName: usedTagName,
      });
    }
    setCachedInfo({
      ...cachedInfo,
      collectorInfo: {
        latest: isCachedLatestValid
          ? cachedLatestInfo
          : {
              tagName: freshLatestInfo.tagName,
              publishedAt: freshLatestInfo.publishedAt.unix(),
              cachedAt: moment().unix(),
            },
        used: updatedUsedList,
      },
    });
  }, [
    setCachedInfo,
    cachedInfo,
    cachedLatestInfo,
    cachedUsedInfo,
    freshLatestInfo,
    freshUsedInfo,
    isCachedLatestValid,
    usedRelease,
    usedTagName,
  ]);

  if (!usedRelease || !latestRelease || !freshness) {
    return undefined;
  }

  return {
    usedRelease,
    latestRelease,
    freshness,
  };
}

function getFreshness(
  latestRelease: CollectorReleaseInfo,
  usedRelease: CollectorReleaseInfo,
): CollectorFreshness {
  if (latestRelease.tagName === usedRelease.tagName) {
    return "latest";
  }
  if (usedRelease.publishedAt.isAfter(moment().subtract(6, "months"))) {
    return "recent";
  }
  const [major, minor] = usedRelease.tagName
    .substring(1)
    .split(".")
    .map((v) => parseInt(v, 10));
  if (major === 0 && minor < 40) {
    return "very outdated";
  }

  return "outdated";
}

function parseCachedVersionInfo(
  info: CollectorCachedReleaseInfo,
): CollectorReleaseInfo {
  return {
    publishedAt: moment.unix(info.publishedAt),
    tagName: info.tagName,
  };
}

export function useCollectorReleaseInfo(
  tagName: string,
  opts?: { skip?: boolean },
): CollectorReleaseInfo | null {
  const skip = opts?.skip;
  const [info, setInfo] = useState<CollectorReleaseInfo | null>(null);
  const unmounted = useUnmounted();
  const url =
    tagName === "latest" ? LATEST_RELEASE_URL : getReleaseInfoURL(tagName);
  useEffect(() => {
    if (skip) {
      return;
    }
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        if (unmounted.current || !result.tag_name) {
          return;
        }
        setInfo({
          publishedAt: moment(result.published_at),
          tagName: result.tag_name,
        });
      })
      .catch((err) => {
        // this is not critical information so we can just log the error
        console.error("could not fetch collector release info:", err);
      });
  }, [unmounted, skip, url]);
  if (skip) {
    return null;
  }
  return info;
}

type CollectorCachedReleaseInfo = {
  publishedAt: number;
  tagName: string;
  cachedAt: number;
};

type CollectorReleaseCacheV1 = {
  version: 1;
  collectorInfo: {
    latest: CollectorCachedReleaseInfo;
    used: CollectorCachedReleaseInfo[];
  };
};

// Discriminated union of all versions
export type StoredCollectorReleaseCache = CollectorReleaseCacheV1;

// Always the latest value
export type LatestCollectorReleaseCache = CollectorReleaseCacheV1;

// The exposed type hides the version; that's internal to encoding/decoding
export type CollectorReleaseCache = Omit<
  LatestCollectorReleaseCache,
  "version"
>;

// Take whatever's stored and convert to latest version. We
// can age out old versions if we're confident they are no
// longer in use.
export const convertCollectorReleaseCache = (
  prefs: StoredCollectorReleaseCache,
): CollectorReleaseCache => {
  const { version, ...rest } = prefs;
  switch (version) {
    case 1:
      return rest;
  }
};
