import React from "react";
import { useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";

import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import ExplainWorkbookList from "components/ExplainWorkbookList";
import ServerExplainWorkbookList from "components/ServerExplainWorkbookList";
import PageContent from "components/PageContent";

type QueryTuningTabType = "overview" | "workbook";

const QueryTuning: React.FunctionComponent<{ tab: QueryTuningTabType }> = ({
  tab,
}) => {
  const { databaseId, serverId } = useParams();
  const { databaseWorkbooks, serverWorkbooks } = useRoutes();

  const featureNav = databaseId ? (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseWorkbooks(databaseId)}>Workbooks</PageNavLink>
    </PageSecondaryNavigation>
  ) : (
    <PageSecondaryNavigation>
      <PageNavLink to={serverWorkbooks(serverId)}>Workbooks</PageNavLink>
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title="Query Tuning"
      pageCategory="query-tuning"
      pageName="index"
      featureNav={featureNav}
    >
      <QueryTuningContent tab={tab} databaseId={databaseId} />
    </PageContent>
  );
};

const QueryTuningContent: React.FunctionComponent<{
  tab: QueryTuningTabType;
  databaseId: string;
}> = ({ tab, databaseId }) => {
  if (tab !== "workbook") {
    return null;
  }
  return databaseId ? <ExplainWorkbookList /> : <ServerExplainWorkbookList />;
};

export default QueryTuning;
