import React, { useEffect } from "react";

import { useAppConfig } from "components/WithAppConfig";
import { useCurrentOrganization } from "components/WithCurrentOrganization";

type Props = {
  pageCategory?: string | null | undefined;
  pageName?: string | null | undefined;
  pageProps?: unknown | null | undefined;
  pageTab?: string;
};

const PageAnalytics: React.FunctionComponent<Props> = ({
  pageCategory,
  pageName,
  pageProps,
  pageTab,
}) => {
  const currentOrganization = useCurrentOrganization(true);
  const { enterpriseEdition } = useAppConfig();

  useEffect(() => {
    if (enterpriseEdition || !currentOrganization || !window.analytics) {
      return;
    }

    const {
      name,
      createdAt,
      slug: organizationSlug,
      planInfo,
      onboardingProgress,
    } = currentOrganization;
    const {
      hasDatabasesIntegrated,
      hasRecentData,
      integratedCollector,
      integratedLogInsights,
      integratedExplain,
      integratedEmailAlerts,
    } = onboardingProgress;
    window.analytics.group(currentOrganization.id, {
      // Standard traits
      name,
      plan: planInfo.subscriptionPlanId,
      createdAt: new Date(createdAt * 1000).toISOString(),
      // Custom traits
      organizationSlug,
      hasDatabasesIntegrated,
      hasRecentData,
      integratedCollector,
      integratedLogInsights,
      integratedExplain,
      integratedEmailAlerts,
    });
  }, [enterpriseEdition, currentOrganization]);

  useEffect(() => {
    if (enterpriseEdition || !pageCategory || !pageName) {
      return;
    }

    const currPageProps = pageProps || {};
    if (pageTab) {
      currPageProps["tab"] = pageTab;
    }

    if (window.analytics) {
      window.analytics.page(pageCategory, pageName, currPageProps);
    }

    if (window.gtag) {
      let eventLabel = pageCategory + ": " + pageName;
      if (pageTab) {
        eventLabel += " " + pageTab;
      }
      window.gtag("event", "viewed", {
        event_category: "Product Feature",
        event_label: eventLabel,
        non_interaction: true,
      });
    }
  }, [enterpriseEdition, pageCategory, pageName, pageTab, pageProps]);

  return null;
};

export default PageAnalytics;
