import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";

import {
  ConnectionsPerDatabase as ConnectionsPerDatabaseType,
  ConnectionsPerDatabaseVariables,
  ConnectionsPerDatabase_getBackendCountsByDatabase as DatabaseAndCountsType,
} from "./types/ConnectionsPerDatabase";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";
import Grid, { NumberCell } from "components/Grid";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import { makeFilter } from "utils/filter";

type RowDataType = {
  databaseId: string | null | undefined;
  databaseName: string | null | undefined;
  connectionLimit: number | null | undefined;
} & DatabaseAndCountsType;

type Props = {
  serverId: string;
  timestamp: number;
};

const ConnectionsPerDatabase: React.FunctionComponent<Props> = ({
  serverId,
  timestamp,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading, error } = useQuery<
    ConnectionsPerDatabaseType,
    ConnectionsPerDatabaseVariables
  >(QUERY, {
    variables: {
      serverId,
      timestamp,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const secondaryTitle = (
    <div className={styles.secondaryTitle}>
      <span className={styles.secondaryTitleTimestamp}>
        {moment.unix(timestamp).format("ll LTS z")}
      </span>
      <PanelTitleSearch value={searchTerm} onChange={setSearchTerm} />
    </div>
  );

  const gridData = data.getBackendCountsByDatabase.map(
    (inputData: DatabaseAndCountsType): RowDataType => {
      return {
        ...inputData,
        databaseId: (inputData.database && inputData.database.id) || null,
        databaseName:
          (inputData.database && inputData.database.datname) || null,
        connectionLimit:
          (inputData.database && inputData.database.connectionLimit) || null,
      };
    },
  );
  const filteredData = gridData.filter(makeFilter(searchTerm, "databaseName"));

  return (
    <Panel title="Connections Per Database" secondaryTitle={secondaryTitle}>
      <Grid
        className="grid-cols-[minmax(30%,1fr),minmax(10%,120px),repeat(5,minmax(5%,100px))]"
        striped
        defaultSortBy="totalClientCount"
        data={filteredData}
        columns={[
          {
            field: "databaseName",
            header: "Database",
            renderer: DatabaseNameCell,
          },
          {
            field: "waitingForLockCount",
            header: "Waiting for Lock",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: NumberCell,
          },
          {
            field: "activeCount",
            header: "Active",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: NumberCell,
          },
          {
            field: "idleInTransactionCount",
            header: "Idle in TX",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: NumberCell,
          },
          {
            field: "idleCount",
            header: "Idle",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: NumberCell,
          },
          {
            field: "totalClientCount",
            header: "Total",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: NumberCell,
          },
          {
            field: "connectionLimit",
            header: "Limit",
            style: "number",
            defaultSortOrder: "desc",
            nullValue: "n/a",
            renderer: NumberCell,
          },
        ]}
      />
    </Panel>
  );
};

const DatabaseNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { databaseBackends } = useRoutes();
  return (
    <span>
      {rowData.database && !rowData.database.hidden && (
        <Link to={databaseBackends(rowData.database.id)}>
          {rowData.database.datname}
        </Link>
      )}
      {rowData.database && rowData.database.hidden && rowData.database.datname}
      {!rowData.database && <span>unknown</span>}
    </span>
  );
};

export default ConnectionsPerDatabase;
