import { useEffect, useState } from "react";

import _ from "lodash";
import classNames from "classnames";
import { SortDirection } from "types/graphql-global-types";

import { useDeepEqualMemo } from "utils/hooks";

export type SortOrder = "asc" | "desc";

export type CellData<T, F extends keyof T> = {
  field: F;
  // TODO: using recursive conditional types, it should be possible
  // to define a stricter type for fieldData here; however, naively
  // typing this as T[F] ends up with fieldData as the _union_ of
  // possible column types, which is not usable.
  // See https://github.com/microsoft/TypeScript/pull/40002
  fieldData: any;
  rowData: T;
};

export type GridColumnClassName<T, F extends keyof T> =
  | string
  | ((args: CellData<T, F>) => string);

type StyledGridColumn<T, F extends keyof T> = {
  style?: "number" | "query";
  className?: GridColumnClassName<T, F>;
  headerClassName?: string;
};

type SortableGridColumn = {
  defaultSortOrder?: SortOrder;
};

export function toGraphQLSortDirection(order: "asc" | "desc"): SortDirection {
  switch (order) {
    case "asc":
      return SortDirection.ASC;
    case "desc":
      return SortDirection.DESC;
  }
}

export type GridColumn<T, F extends keyof T> = {
  header?: React.ReactNode;
  tip?: React.ReactNode;
  field: F;
  title?: boolean | ((args: CellData<T, F>) => string);
  // We are not typing this as React.ComponentType<CellData<T, F>> (which seems
  // like a more sensible definition) due to the issues discussed here:
  // https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/53846
  renderer?: (props: CellData<T, F>) => React.ReactNode;
  /**
   * Used to render null or undefined nodes instead of calling the renderer.
   * If not set, the renderer will be called for null or undefined values.
   */
  nullValue?: React.ReactNode;
  disableSort?: boolean;
  /**
   * Width of the column, as a single track of the
   * {@link https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns grid-template-columns}
   * CSS property (e.g., "1fr" or "minmax(10px,20%)"). Optional; can instead be
   * set through grid-template-columns styles on the Grid itself instead. Only
   * takes effect if widths are set on all columns.
   *
   * Can alternately be set to 'false' to omit this column from the grid entirely.
   */
  width?: string | false;
} & StyledGridColumn<T, F> &
  SortableGridColumn;

export type SortColumn<T, F extends keyof T> = Pick<
  GridColumn<T, F>,
  "field" | "defaultSortOrder"
>;

export function getClassName<T, F extends keyof T>(
  f: StyledGridColumn<T, F>,
  field: F,
  fieldData: T[F],
  rowData: T,
): string | undefined {
  const className =
    typeof f.className === "function"
      ? f.className({ field: field, fieldData: fieldData, rowData: rowData })
      : f.className;
  const builtInStyling =
    f.style === "number"
      ? "text-right font-mono"
      : f.style === "query"
      ? "font-mono"
      : undefined;
  return classNames(builtInStyling, className);
}

export function getHeaderClassName<T, F extends keyof T>(
  f: StyledGridColumn<T, F>,
): string | undefined {
  const builtInStyling =
    f.style === "number"
      ? "text-right"
      : // queries have no special header style
        undefined;

  return classNames(builtInStyling, f.headerClassName);
}

type GridSortStateEntry = {
  columnIdx: number;
  order: SortOrder;
};

type GridColumnSortState = GridSortStateEntry[];

export type GridSortState<T> = {
  data: T[];
  entries: GridSortStateEntry[];
  doSort: (sortColumnIndex: number) => void;
};

export type SortAction<T, F extends keyof T> =
  | {
      type: "sort";
      sortColumnIndex: number;
      entries: GridSortStateEntry[];
      columns: readonly SortColumn<T, F>[];
    }
  | {
      type: "refresh";
      data: T[];
      columns: readonly SortColumn<T, F>[];
    };

/**
 * This returns a function that conforms to the Array#sort interface for sorting
 * grid data. Values that are null-like (see sortAsUndefined) always sort last
 * according to the default sort order of the column (i.e., if a column sorts
 * descending by default, null will be the lowest value; if ascending by
 * default, null will be the highest value). If values for the given items in
 * the most-recently sorted-by column are equal, values in the
 * next-most-recently sorted-by are compared.
 */
function makeSortComparator<T, F extends keyof T>(
  entries: GridSortStateEntry[],
  columns: readonly SortColumn<T, F>[],
): (item1: T, item2: T) => number {
  return (item1, item2) => {
    for (const entry of entries) {
      const column = columns[entry.columnIdx];

      if (!column) {
        // Should not happen, but if we do hit this, ignore this missing column
        // rather than erroring out
        return 0;
      }

      const sortVal1 = sortValue(item1, column);
      const sortVal2 = sortValue(item2, column);

      const asc = entry.order === "asc";
      const nullsLast = (column.defaultSortOrder ?? "asc") == entry.order;
      if (sortVal1 === sortVal2) {
        continue;
      } else if (sortVal1 === undefined) {
        return nullsLast ? 1 : -1;
      } else if (sortVal2 === undefined) {
        return nullsLast ? -1 : 1;
      } else if (sortVal1 < sortVal2) {
        return asc ? -1 : 1;
      } else if (sortVal1 > sortVal2) {
        return asc ? 1 : -1;
      }
    }
    return 0;
  };
}

function sortAsUndefined(value: unknown) {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (typeof value === "number" && isNaN(value))
  );
}

function sortValue<T, F extends keyof T>(
  row: T,
  column: SortColumn<T, F>,
): string | number | boolean | undefined {
  const rawVal = row[column.field];
  if (sortAsUndefined(rawVal)) {
    return undefined;
  }
  if (
    typeof rawVal === "number" ||
    typeof rawVal === "string" ||
    typeof rawVal === "boolean"
  ) {
    return rawVal;
  }

  return String(rawVal);
}

function reverse(order: SortOrder): SortOrder {
  switch (order) {
    case "asc":
      return "desc";
    case "desc":
      return "asc";
  }
}

// Grid provides two distinct sorting mechanisms:
//
//  - an internal sort, where clicking on Grid headers sorts (a copy of) the
//    prop-provided data within the component
//  - an external sort, where clicking on Grid headers invokes the handleSort
//    callback, and the consumer of the component is responsible for actually
//    sorting the data
//
// External sorting is useful for situations where the data is sorted
// server-side.
function isExternalSort<T, F extends keyof T>(
  sort: SortProps<T, F>,
): sort is ExternalSortProps<T, F> {
  return "handleSort" in sort;
}

export function initialInternalSortState({
  columns,
  defaultSortBy,
}: {
  columns: readonly { field: string; defaultSortOrder?: SortOrder }[];
  defaultSortBy?: string | string[];
}): GridColumnSortState {
  if (!defaultSortBy) {
    return [];
  }

  const defaultSortByList =
    typeof defaultSortBy === "string" ? [defaultSortBy] : defaultSortBy;
  return defaultSortByList
    .map((sortColName) => {
      return columns.findIndex((col) => col.field === sortColName);
    })
    .filter((colIdx) => {
      // ignore columns that don't exist on this data set
      return colIdx >= 0;
    })
    .map((colIdx) => {
      const defaultSortCol = columns[colIdx];
      const defaultSortOrder = defaultSortCol?.defaultSortOrder ?? "asc";
      return {
        columnIdx: colIdx,
        order: defaultSortOrder,
      };
    });
}

export function initialExternalSortState({
  columns,
  sortedBy,
  sortedOrder,
}: {
  columns: readonly { field: string }[];
  sortedBy?: string;
  sortedOrder?: SortOrder;
}): GridColumnSortState {
  if (!sortedBy || !sortedOrder) {
    return [];
  }
  const colIdx = columns.findIndex((col) => col.field === sortedBy);
  return [{ columnIdx: colIdx, order: sortedOrder }];
}

function deriveSortStateEntries<T, F extends keyof T>(
  state: GridColumnSortState,
  columns: readonly SortColumn<T, F>[],
  sortColumnIndex: number,
): GridSortStateEntry[] {
  const sortColumn = columns[sortColumnIndex];
  const prevLeadSort = state.length > 0 ? state[0] : undefined;

  // If the last sort was by this column, reverse that order. Otherwise,
  // use the column's default sort order or fall back to an ascending sort.
  const newSortOrder =
    prevLeadSort?.columnIdx === sortColumnIndex
      ? reverse(prevLeadSort.order)
      : sortColumn.defaultSortOrder ?? "asc";

  // we want to include previous columns to effectively achieve a stable sort
  const otherEntries = state.filter(
    (entry) => entry.columnIdx !== sortColumnIndex,
  );

  return [{ columnIdx: sortColumnIndex, order: newSortOrder }, ...otherEntries];
}

function doSort<T, F extends keyof T>(
  data: T[],
  columns: readonly SortColumn<T, F>[],
  entries: GridSortStateEntry[],
): T[] {
  const newComparator = makeSortComparator(entries, columns);
  return data.slice().sort(newComparator);
}

type InternalSortProps<T, F extends keyof T> = {
  defaultSortBy?: F | F[];
};

type ExternalSortProps<T, F extends keyof T> = {
  sortedBy: F;
  sortedOrder: SortOrder;
  handleSort: (field: F, order: SortOrder) => void;
};

export type SortProps<T, F extends keyof T> =
  | InternalSortProps<T, F>
  | ExternalSortProps<T, F>;

type CommonSortProps<T, F extends keyof T> = {
  data: T[];
  columns: readonly SortColumn<T, F>[];
};

type UseSortedDataProps<T, F extends keyof T> = CommonSortProps<T, F> &
  SortProps<T, F>;

function useInternallySortedData<T, F extends string & keyof T>(
  props: UseSortedDataProps<T, F>,
): GridSortState<T> {
  const isExternal = isExternalSort<T, F>(props);
  const { data, columns } = props;
  const defaultSortBy = isExternal ? undefined : props.defaultSortBy;

  const [sortState, setSortState] = useState<GridColumnSortState>(() => {
    return initialInternalSortState({
      columns,
      defaultSortBy,
    });
  });
  const cachedDefaultSortBy = useDeepEqualMemo(defaultSortBy);
  const cachedSortState = useDeepEqualMemo(sortState);
  const cachedSortColumns = useDeepEqualMemo(
    columns.map((col) => {
      return _.pick(col, "field", "defaultSortOrder");
    }),
  );
  // We want to reset the sort state when anything that affects the default sort
  // changes. Most commonly, this will be the visible column list on Grids that
  // can hide some columns.
  useEffect(() => {
    setSortState(
      initialInternalSortState({
        columns: cachedSortColumns,
        defaultSortBy: cachedDefaultSortBy,
      }),
    );
  }, [isExternal, cachedSortColumns, cachedDefaultSortBy]);
  const [sortedData, setSortedData] = useState<T[]>(() => {
    // We can't exit early out of the hook since we can't have other hooks after
    // early returns, so we just avoid the initial sort for externally-sorted
    // data (or if no default sort was specified).
    if (isExternal || cachedSortState.length === 0) {
      return data;
    }
    return doSort(data, cachedSortColumns, cachedSortState);
  });
  useEffect(() => {
    if (isExternal) {
      return;
    }
    setSortedData(doSort(data, cachedSortColumns, cachedSortState));
  }, [data, cachedSortColumns, cachedSortState, isExternal]);

  function handleSort(sortColumnIndex: number): void {
    const newSortEntries = deriveSortStateEntries(
      sortState,
      columns,
      sortColumnIndex,
    );

    setSortState(newSortEntries);
  }

  return {
    data: sortedData,
    entries: sortState,
    doSort: handleSort,
  };
}

function useExternallySortedData<T, F extends string & keyof T>(
  props: UseSortedDataProps<T, F>,
): GridSortState<T> {
  const isExternal = isExternalSort<T, F>(props);
  const { data, columns } = props;
  const sortedBy = isExternal ? props.sortedBy : undefined;
  const sortedOrder = isExternal ? props.sortedOrder : undefined;
  const [sortState, setSortState] = useState<GridColumnSortState>(() => {
    return initialExternalSortState({
      columns,
      sortedBy,
      sortedOrder,
    });
  });
  function handleSort(sortColumnIndex: number): void {
    if (!isExternalSort<T, F>(props)) {
      return;
    }

    const newSortEntries = deriveSortStateEntries(
      sortState,
      columns,
      sortColumnIndex,
    );
    setSortState(newSortEntries);
    const newLeadSort = newSortEntries[0];
    const column = columns[newLeadSort.columnIdx];
    props.handleSort(column.field, newLeadSort.order);
  }
  return {
    data,
    entries: sortState,
    doSort: handleSort,
  };
}

export function useSortedData<T, F extends string & keyof T>(
  props: UseSortedDataProps<T, F>,
): GridSortState<T> {
  const isExternal = isExternalSort<T, F>(props);
  const fullProps = {
    ...props,
    isExternal,
  };
  // As above, we can't put flow control in between calls to hooks, so we
  // initialize both but only use the one that is called for.
  const internalSortState = useInternallySortedData(fullProps);
  const externalSortState = useExternallySortedData(fullProps);

  if (isExternal) {
    return externalSortState;
  } else {
    return internalSortState;
  }
}
