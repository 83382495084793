import React from "react";
import { useParams, Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

import {
  CancelPlanMutation,
  CancelPlanMutationVariables,
} from "./types/CancelPlanMutation";
import MUTATION from "./Mutation.graphql";
import { useMutation } from "@apollo/client";
import { useAsyncActionFlash } from "components/WithFlashes";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

const CancelPlan: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const { organization, organizationSubscription } = useRoutes();

  const [
    cancelPlan,
    { called: mutationCalled, loading: mutationLoading, error: mutationError },
  ] = useMutation<CancelPlanMutation, CancelPlanMutationVariables>(MUTATION);
  useAsyncActionFlash({
    called: mutationCalled,
    loading: mutationLoading,
    error: mutationError?.message,
    success:
      "Successfully canceled your subscription. You can activate your plan again by upgrading below.",
    successRedirect: organizationSubscription(organizationSlug),
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    cancelPlan({ variables: { organizationSlug } });
  };

  return (
    <Panel title="Cancel Subscription">
      <PanelSection>
        <form onSubmit={handleSubmit}>
          <p>Do you really want to cancel your pganalyze Subscription?</p>
          <div>
            <input
              type="submit"
              name="commit"
              value="Yes, Cancel"
              className="btn btn-primary"
              data-disable-with="Yes, Cancel"
            />
            <Link
              className="btn btn-link"
              style={{ marginLeft: "1em" }}
              to={organization(organizationSlug)}
            >
              No, Return to Dashboard
            </Link>
          </div>
        </form>
      </PanelSection>
    </Panel>
  );
};

export default CancelPlan;
