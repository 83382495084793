type MapWithRepeats<T> = { [k: string]: T | T[] };
type Map<T> = { [k: string]: T };
type MapWithUndefined<T> = { [k: string]: T | undefined };

export const ignoreRepeats = <T>(map: MapWithRepeats<T>): Map<T> => {
  return Object.entries(map).reduce(
    (result: Map<T>, curr: [string, T | T[]]) => {
      const [k, v] = curr;
      let val;
      if (Array.isArray(v)) {
        console.error("key", k, "occurs multiple times; using first");
        val = v[0];
      } else {
        val = v;
      }
      result[k] = val;

      return result;
    },
    {},
  );
};

// Return new map merging entries of a and b (with b superseding a),
// and skipping any entries whose key is present in b but whose value
// is undefined.
export const mergeDelete = <T>(a: Map<T>, b: MapWithUndefined<T>): Map<T> => {
  const merged = { ...a, ...b };
  Object.entries(b).forEach(([k, v]) => {
    if (v === undefined) {
      delete merged[k];
    }
  });
  // We explicitly remove all keys with undefined values, so this is safe.
  // There may be a better way to express this in the type system.
  return merged as Map<T>;
};
