import React from "react";

import {
  formatNumber,
  formatBytes,
  formatMs,
  formatTimestampLong,
} from "utils/format";

import { Plan } from "types/explain";
import moment from "moment";
import ExplainFingerprint from "components/ExplainFingerprint";

type Props = {
  plan: Plan;
  runtime?: number;
  seenAt?: number;
  fingerprint: string;
  totCost: number;
  ioBlocks: number;
  blockSize: number;
  ioMs: number;
};

const ExplainStatsAndOptions: React.FunctionComponent<Props> = ({
  runtime,
  seenAt,
  fingerprint,
  totCost,
  ioBlocks,
  blockSize,
  ioMs,
}) => {
  return (
    <div>
      <dl className="grid grid-cols-2 gap-2 text-lg">
        <div>
          <dt className="text-[#606060] font-medium">Runtime</dt>
          <dd>{runtime != null ? formatMs(runtime) : "-"}</dd>
        </div>
        <div>
          <dt className="text-[#606060] font-medium">I/O Read Time</dt>
          <dd>{ioMs != null ? formatMs(ioMs) : "-"}</dd>
        </div>
        <div>
          <dt className="text-[#606060] font-medium">Read From Disk</dt>
          <dd>{ioBlocks != null ? formatBytes(ioBlocks * blockSize) : "-"}</dd>
        </div>
        <div>
          <dt className="text-[#606060] font-medium">Total Est. Cost</dt>
          <dd>{totCost != null ? formatNumber(totCost) : "N/A"}</dd>
        </div>
      </dl>
      <div className="flex items-center text-[#606060]">
        {seenAt != null && <>{formatTimestampLong(moment.unix(seenAt))} · </>}
        <span className="mx-1">Plan Fingerprint:</span>
        <ExplainFingerprint fingerprint={fingerprint} />
      </div>
    </div>
  );
};

export default ExplainStatsAndOptions;
