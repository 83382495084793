import React from "react";
import { useQuery } from "@apollo/client";
import maxBy from "lodash/maxBy";
import moment from "moment-timezone";

import { formatBytes, formatDuration, formatTimestampLong } from "utils/format";
import { useDateRange } from "components/WithDateRange";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";

import Graph from "./Graph";
import FollowerIssueList from "./FollowerIssueList";

import {
  ReplicationDetails as ReplicationDetailsType,
  ReplicationDetailsVariables,
  ReplicationDetails_getReplicationFollowers,
  ReplicationDetails_getPostgresServerStats,
} from "./types/ReplicationDetails";
import QUERY from "./Query.graphql";

// TODO:
// - Graph for follower lag (on the follower page)
//
// Idea: Show "bytes replayed / sec" by translating the replayLsn into a byte
// position and calculating the diff between steps
//
// The same could be applied on the primary to show the amount of WAL that is
// being generated

const SYNC_STATE_TO_TITLE = {
  async: "Asynchronous",
  potential: "Asynchronous (potential synchronous)",
  sync: "Synchronous",
  quorum: "Quorum",
};

const ReplicationDetails: React.FunctionComponent<{ serverId: string }> = ({
  serverId,
}) => {
  const [{ from, to }] = useDateRange();

  const { data, loading, error } = useQuery<
    ReplicationDetailsType,
    ReplicationDetailsVariables
  >(QUERY, { variables: { serverId, startTs: from.unix(), endTs: to.unix() } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data.getPostgresServerStats.length === 0) {
    return (
      <Panel title="Error">
        <PanelSection>
          <strong>Error:</strong> No replication statistics received in the
          selected time frame.
        </PanelSection>
      </Panel>
    );
  }

  const systemInfo = data.getSystemDetails.info;
  const isAurora = systemInfo?.data?.isAuroraPostgres;

  const followers = data.getReplicationFollowers.filter(
    (f) => f.lastStats?.collectedAt > from.unix(),
  );
  const latestStats = maxBy(
    data.getPostgresServerStats,
    (s: ReplicationDetails_getPostgresServerStats): number => s.collectedAt,
  );
  const {
    isPrimary,
    primaryWalLsn,
    standbyIsStreaming,
    standbyReceiveLsn,
    standbyReplayLsn,
    standbyApplyByteLag,
    standbyReplayTimestamp,
    standbyReplayTimestampAge,
  } = latestStats;
  const serverType = labelServerType(isPrimary, isAurora);

  return (
    <>
      <Panel
        title={`${serverType} Statistics`}
        secondaryTitle={moment(latestStats.collectedAt * 1000).format(
          "ll LTS z",
        )}
      >
        <PanelTable horizontal borders>
          <tbody>
            <tr>
              <th>Server Type</th>
              <td>{serverType}</td>
              {isPrimary ? (
                <>
                  <th key="th">WAL LSN</th>
                  <td key="td">{primaryWalLsn}</td>
                </>
              ) : (
                <>
                  <th key="th">Replay LSN</th>
                  <td key="td">{standbyReplayLsn}</td>
                </>
              )}
            </tr>
            {!isPrimary ? (
              <>
                <tr>
                  <th>Is Streaming?</th>
                  <td>{(standbyIsStreaming && "Yes") || "No"}</td>
                  <th>Receive LSN</th>
                  <td>{standbyReceiveLsn}</td>
                </tr>
                <tr>
                  <th>Byte Lag</th>
                  <td>{formatBytes(standbyApplyByteLag)}</td>
                  <th>Replay Timestamp</th>
                  <td>
                    {isAurora ? (
                      <>
                        {formatDuration(standbyReplayTimestampAge, false)}{" "}
                        behind
                      </>
                    ) : (
                      <>
                        {formatTimestampLong(
                          moment.unix(standbyReplayTimestamp),
                        )}{" "}
                        · {formatDuration(standbyReplayTimestampAge, false)}{" "}
                        behind
                      </>
                    )}
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </PanelTable>
        {/* Aurora primary (writer) always has 0 follower (even if there are readers), do not show the hint */}
        {isPrimary && followers.length === 0 && !isAurora && (
          <PanelSection>
            This server has no streaming replication followers.
            <br />
            <br />
            <strong>Hint:</strong> Followers using WAL shipping through a
            secondary channel (e.g. an object store) are currently not linked
            together by pganalyze, and won't show on this page.
          </PanelSection>
        )}
      </Panel>
      {followers.map(
        (f: ReplicationDetails_getReplicationFollowers): React.ReactNode => (
          <ReplicationDetailsFollower
            serverId={serverId}
            follower={f}
            key={f.id}
          />
        ),
      )}
    </>
  );
};

const ReplicationDetailsFollower: React.FunctionComponent<{
  serverId: string;
  follower: ReplicationDetails_getReplicationFollowers;
}> = ({ serverId, follower }) => {
  return (
    <Panel
      title={`${SYNC_STATE_TO_TITLE[follower.syncState]} Follower at ${
        follower.clientAddr
      }`}
      secondaryTitle={moment
        .unix(follower.lastStats.collectedAt)
        .format("lll z")}
      key={follower.id}
    >
      <PanelTable horizontal borders>
        <tbody>
          <tr>
            <th>State</th>
            <td>{follower.lastStats ? follower.lastStats.state : "unknown"}</td>
            <th>Sent LSN</th>
            <td>
              {follower.lastStats ? follower.lastStats.sentLsn : "unknown"}
            </td>
          </tr>
          <tr>
            <th>Remote Byte Lag / Local Byte Lag</th>
            <td>
              {follower.lastStats && follower.lastStats.remoteByteLag !== null
                ? formatBytes(follower.lastStats.remoteByteLag)
                : "unknown"}{" "}
              /{" "}
              {follower.lastStats && follower.lastStats.localByteLag !== null
                ? formatBytes(follower.lastStats.localByteLag)
                : "unknown"}
            </td>
            <th>Write / Flush / Replay LSN</th>
            <td>
              {follower.lastStats ? (
                <>
                  {follower.lastStats.writeLsn} · {follower.lastStats.flushLsn}{" "}
                  · {follower.lastStats.replayLsn}
                </>
              ) : (
                "unknown"
              )}
            </td>
          </tr>
        </tbody>
      </PanelTable>
      <Graph serverId={serverId} replicationFollowerId={follower.id} />
      <FollowerIssueList serverId={serverId} referentId={follower.id} />
    </Panel>
  );
};

function labelServerType(isPrimary: boolean, isAurora: boolean): string {
  if (isPrimary) {
    return isAurora ? "Primary (Writer)" : "Primary";
  } else {
    return isAurora ? "Replica (Reader)" : "Follower";
  }
}

export default ReplicationDetails;
