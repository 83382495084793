import React from "react";

import { useRoutes } from "utils/routes";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { useCurrentOrganization } from "components/WithCurrentOrganization";
type Props = {
  feature: string;
  additionalText?: React.ReactNode;
  panelOnly?: boolean;
  pluralFeature?: boolean;
};

const UpgradeRequired: React.FunctionComponent<Props> = ({
  feature,
  additionalText,
  panelOnly,
  pluralFeature,
}) => {
  const { organizationSubscriptionComparePlans } = useRoutes();
  const { slug: organizationSlug } = useCurrentOrganization();

  const panel = (
    <Panel title="Upgrade Required">
      <PanelSection>
        {additionalText && <p>{additionalText}</p>}
        <strong>{feature}</strong> {(pluralFeature && "are") || "is"} not
        included in your current plan.
      </PanelSection>
      <PanelSection>
        <a
          href={organizationSubscriptionComparePlans(organizationSlug)}
          className="btn btn-success"
        >
          Review Plans
        </a>
      </PanelSection>
    </Panel>
  );

  if (panelOnly) {
    return panel;
  }

  return <PageContent title={feature}>{panel}</PageContent>;
};

export default UpgradeRequired;
