import type { Node, Plan } from "../types/explain";
import { Diffable } from "./diff";

export function reduce<T>(
  node: Node,
  callback: (state: T, node: Node, depth: number) => T,
  initialState: T,
): T {
  function visit(state: T, node: Node, depth: number): T {
    let newState = callback(state, node, depth);
    if (node.Plans) {
      newState = node.Plans.reduce((childState, child) => {
        return visit(childState, child, depth + 1);
      }, newState);
    }
    return newState;
  }

  return visit(initialState, node, 0);
}

export function findPlanIndexes(plan: Plan): Set<string> {
  return reduce(
    plan[0].Plan,
    (allIndexes, node) => {
      const index = node["Index Name"];
      if (index) {
        allIndexes.add(index);
      }
      return allIndexes;
    },
    new Set<string>(),
  );
}

type DiffablePlanLine = {
  line: string;
  footnote: React.ReactNode;
  node: Node;
};

export type DiffablePlan = Diffable<DiffablePlanLine>;

export function toDiffablePlan(plan: Plan, allIndexes: string[]): DiffablePlan {
  const root = plan[0].Plan;

  const result = reduce(
    root,
    (lines, node, depth) => {
      const prefix = " ".repeat(depth);
      const index = node["Index Name"];
      const footnote = index ? allIndexes.indexOf(index) + 1 : undefined;
      return lines.concat([
        {
          line: `${prefix}-> ${node["Node Type"]}`,
          footnote,
          node,
        },
      ]);
    },
    [] as DiffablePlanLine[],
  );

  return result.map((content, idx) => {
    return {
      n: idx,
      content,
    };
  });
}
