import React from "react";

import classNames from "classnames";

const INDEX_SELECTION_ICON_BASE =
  "inline-block min-h-[20px] min-w-[20px] text-center text-[10px] leading-5 border";

export const IndexIcon: React.FunctionComponent<{
  label: string;
  selected?: boolean;
  existing?: boolean;
  className?: string;
}> = ({ label, className, existing, selected }) => {
  const indexVariantClass =
    existing && selected
      ? "bg-[#544D6D] hover:bg-[#30284D] text-[#FFFFFF] border-transparent"
      : existing
      ? "bg-[#EB1515] hover:bg-[#C9302C] text-[#FFFFFF] border-transparent"
      : selected
      ? "bg-[#337AB7] hover:bg-[#1C7699] text-[#FFFFFF] border-transparent"
      : "bg-[#FFFFFF] text-[#544D6D] border-[#544D6D] hover:text-[#23527C] hover:border-[#23527C]";

  return (
    <div
      className={classNames(
        INDEX_SELECTION_ICON_BASE,
        "text-white rounded-sm",
        indexVariantClass,
        className,
      )}
    >
      {label}
    </div>
  );
};

export const SeqScanIcon: React.FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  const seqScanClassName = "bg-[#544D6D] hover:bg-[#30284D] text-[#FFFFFF]";
  return (
    <div
      className={classNames(
        INDEX_SELECTION_ICON_BASE,
        "text-white rounded-sm border-transparent",
        seqScanClassName,
        className,
      )}
    >
      Seq
    </div>
  );
};

export const ScanIcon: React.FunctionComponent<{
  label: string;
  className?: string;
}> = ({ label, className }) => {
  return (
    <div
      className={classNames(
        INDEX_SELECTION_ICON_BASE,
        "bg-[#544D6D] hover:bg-[#30284D] text-[#FFFFFF] rounded-full border-transparent",
        className,
      )}
    >
      {label}
    </div>
  );
};
