import * as React from "react";

export function csrfToken(): string {
  const metaTag = window.document.head.querySelector("meta[name=csrf-token]");
  if (metaTag) {
    return metaTag.getAttribute("content") ?? "";
  } else {
    return "";
  }
}

export function csrfTag(): React.ReactNode {
  return <input type="hidden" name="authenticity_token" value={csrfToken()} />;
}
