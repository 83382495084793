import React from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

import { IndexType, getLocator } from "../util";
import { IndexIcon, SeqScanIcon } from "../Icons";
import Badge from "components/Badge";

const IndexReference: React.FunctionComponent<{
  index: IndexType | undefined;
  className?: string;
  terse?: boolean;
}> = ({ className, index, terse }) => {
  const loc = useLocation();
  if (!index) {
    const path = loc.pathname + loc.search + "#SEQ";
    return (
      <Link
        to={path}
        title="Sequential Scan"
        className={classNames("inline-flex items-start gap-1", className)}
      >
        <SeqScanIcon />
        {!terse && <span className="font-mono">Seq Scan</span>}
      </Link>
    );
  }
  const { label, existing, selected, structure } = index;
  const fullDescription = getIndexDescription(index);
  const urlId = getLocator(index);
  const path = loc.pathname + loc.search + `#${urlId}`;

  return (
    <Link
      to={path}
      title={fullDescription}
      className={classNames("inline-flex items-start gap-1", className)}
    >
      <IndexIcon label={label} existing={existing} selected={selected} />
      {!terse && (
        <>
          <span className="font-mono overflow-hidden text-ellipsis">
            {structure}
          </span>
          {index.constraint && (
            <Badge className="mt-0.5">{index.constraint}</Badge>
          )}
        </>
      )}
    </Link>
  );
};

function getIndexDescription(index: IndexType): string {
  const { name, existing, selected, structure } = index;
  if (existing) {
    return `Existing index to ${
      selected ? "keep" : "drop"
    }: ${name} ${structure}`;
  } else {
    if (selected) {
      return `Selected index to create: ${structure}`;
    } else {
      return `Other considered index (not selected): ${structure}`;
    }
  }
}

export default IndexReference;
