import React from "react";

import classNames from "classnames";

import styles from "./style.module.scss";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  bare?: boolean;
};

const Button: React.FunctionComponent<Props> = ({
  className,
  bare,
  children,
  ...rest
}) => {
  const baseStyle = !bare && styles.outlineBtn;
  return (
    <button className={classNames(baseStyle, className)} {...rest}>
      {children}
    </button>
  );
};

export default Button;
