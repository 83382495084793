import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";
import PageContent from "components/PageContent";
import Form from "components/Form";
import FormSubmit from "components/FormSubmit";
import Loading from "components/Loading";
import RoleForm from "components/RoleForm";

import { RoleEdit as RoleEditType, RoleEditVariables } from "./types/RoleEdit";
import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

const RoleEdit: React.FunctionComponent = () => {
  const { slug: organizationSlug, id: organizationRoleId } = useParams();
  const { organizationRole } = useRoutes();
  const { data, loading, error } = useQuery<RoleEditType, RoleEditVariables>(
    QUERY,
    {
      variables: { organizationRoleId },
    },
  );
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { id, name, permissions } = data.getOrganizationRoleDetails;

  return (
    <PageContent
      title={`Role: "${name}"`}
      pageCategory="organization-roles"
      pageName="edit"
    >
      <Panel title="Edit Role">
        <Form
          action={organizationRole(organizationSlug, id)}
          method="patch"
          wide={true}
        >
          <RoleForm
            roleName={name}
            permissions={permissions}
            organizationSlug={organizationSlug}
          />
          <FormSubmit title="Save" />
        </Form>
      </Panel>
    </PageContent>
  );
};

export default RoleEdit;
