import React from "react";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";

import { Data } from "components/Graph/util";
import { useDateRange } from "components/WithDateRange";

import { Network, NetworkVariables } from "./types/Network";
import QUERY from "./Query.graphql";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";

type Props = {
  serverId: string;
};

const Memory: React.FunctionComponent<Props> = ({ serverId }) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<Network, NetworkVariables>(QUERY, {
    variables: {
      serverId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });

  const noData = !loading && !error && data.getSystemStats == null;

  return (
    <Panel title="Network Throughput">
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: "bytes/sec",
            },
          }}
          series={[
            {
              key: "networkReceive",
              label: "Receive Throughput",
              tipLabel: "Rx",
            },
            {
              key: "networkTransmit",
              label: "Transmit Throughput",
              tipLabel: "Tx",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );
};

export default Memory;
