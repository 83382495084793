import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import PanelSection from "components/PanelSection";

import {
  QueryDataMissingHint as QueryDataMissingHintType,
  QueryDataMissingHintVariables,
} from "./types/QueryDataMissingHint";
import QUERY from "./Query.graphql";

interface Props {
  databaseId: string;
}

const QueryDataMissingHint: React.FunctionComponent<Props> = ({
  databaseId,
}) => {
  const { data, loading, error } = useQuery<
    QueryDataMissingHintType,
    QueryDataMissingHintVariables
  >(QUERY, { variables: { databaseId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data.getServerDetails.hasPgssEnabled === false) {
    return (
      <div>
        <PanelSection>
          <strong>
            No query data available: pg_stat_statements is not enabled.
          </strong>
        </PanelSection>
        <PanelSection>
          <p>
            The database server is missing <code>pg_stat_statements</code> in
            the <code>shared_preload_libraries</code> configuration setting.
          </p>
          <p>
            After changing this setting the server needs to be restarted for the
            change to take effect. The pganalyze interface will start showing
            query data within 20 minutes.
          </p>
        </PanelSection>
      </div>
    );
  }

  return (
    <PanelSection>
      <strong>No query data available:</strong> Try searching a different
      timeframe or verify the pganalyze setup.
    </PanelSection>
  );
};

export default QueryDataMissingHint;
