import React, { useContext, useState } from "react";

const ExplainDisplayOptionsContext = React.createContext(undefined);
const ExplainSetDisplayOptionsContext = React.createContext(undefined);

type ExplainDisplayOptions = {
  showActualTime: boolean;
  showIOTime: boolean;
  showCost: boolean;
  showActualRows: boolean;
};

const WithExplainDisplayOptions = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [displayOptions, setDisplayOptions] = useState<ExplainDisplayOptions>({
    showActualTime: true,
    showIOTime: true,
    showCost: true,
    showActualRows: true,
  });
  return (
    <ExplainSetDisplayOptionsContext.Provider value={setDisplayOptions}>
      <ExplainDisplayOptionsContext.Provider value={displayOptions}>
        {children}
      </ExplainDisplayOptionsContext.Provider>
    </ExplainSetDisplayOptionsContext.Provider>
  );
};

export function useExplainDisplayOptions(): ExplainDisplayOptions {
  return useContext(ExplainDisplayOptionsContext);
}

export function useSetExplainDisplayOptions(): React.Dispatch<
  React.SetStateAction<ExplainDisplayOptions>
> {
  return useContext(ExplainSetDisplayOptionsContext);
}

export default WithExplainDisplayOptions;
