import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import hljs from "highlight.js/lib/core";
import sqlLangDef from "highlight.js/lib/languages/sql";

type Props = {
  sql: string;
  inline?: boolean;
  nowrap?: boolean;
  className?: string;
};

// Keywords that should not be highlighted
const unhighlightedKeywords = [
  "oid",
  "date",
  "year",
  "month",
  "unique",
  "desc",
  "asc",
  "interval",
  "null",
  "not",
  "upsert",
  "name",
  "position",
  "is",
  "id",
  "cleanup",
  "query",
  "extract",
  "coalesce",
];

const SQL = ({ sql, ...rest }: Props) => {
  // This component does not properly update its DOM manipulation when
  // the 'sql' prop changes. Fixing this properly is tricky and we're
  // moving toward SQLNew, so for now just force it to re-render when
  // that happens.
  return <SQLInternal key={sql} sql={sql} {...rest} />;
};

const SQLInternal = ({ sql, inline, nowrap, className }: Props) => {
  const preRef = useRef<HTMLPreElement | null>(null);

  useEffect(() => {
    if (preRef.current) {
      const currentRef = preRef.current;
      hljs.registerLanguage("sql", sqlLangDef);
      // Adding alias rule manually so that it can be used for highlighting
      const ALIAS = {
        className: "alias",
        begin: /\$[a-z][a-z0-9_]*/,
      };
      const lang = hljs.getLanguage("sql");
      lang?.contains.push(ALIAS);
      hljs.highlightElement(currentRef);

      currentRef
        .querySelectorAll(".hljs-keyword")
        .forEach((elem: HTMLElement) => {
          const keyword = elem.innerText && elem.innerText.toLowerCase();
          if (unhighlightedKeywords.indexOf(keyword) !== -1) {
            elem.className = "";
          } else {
            elem.className = "uppercase break-normal text-[#005d00]";
          }
        });

      currentRef.querySelectorAll(".hljs-string").forEach((e: HTMLElement) => {
        e.className = "text-[#555]";
      });
      currentRef.querySelectorAll(".hljs-number").forEach((e: HTMLElement) => {
        e.className = "text-[#555]";
      });
      currentRef
        .querySelectorAll(".hljs-aggregate")
        .forEach((e: HTMLElement) => {
          e.className = "text-inherit";
        });
      currentRef.querySelectorAll(".hljs-comment").forEach((e: HTMLElement) => {
        e.className = "text-[#888]";
      });
      currentRef.querySelectorAll(".hljs-literal").forEach((e: HTMLElement) => {
        e.className = "";
      });
      currentRef.querySelectorAll(".hljs-alias").forEach((e: HTMLElement) => {
        e.className = "text-[#A16006]";
      });
    }
  }, []);

  if (inline) {
    sql = sql.replace(/\s+/g, " ");
  } else {
    sql = sql
      .replace(/[^\S\n]+/g, " ")
      .replace(/\n\s/g, "\n")
      .replace(/\n{1,}/g, "\n");
  }

  return (
    <pre
      className={classNames(
        "border-none m-0 p-0 bg-none bg-transparent",
        !nowrap && "whitespace-pre-wrap",
        inline && "inline",
        className,
      )}
      ref={preRef}
    >
      {sql}
    </pre>
  );
};

export default SQL;
