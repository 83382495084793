export type XminHeldBackBy =
  | "backend"
  | "replication_slot"
  | "replication_slot_catalog"
  | "prepared_xact"
  | "standby";

export function labelXminHeldBackBy(heldBackBy: XminHeldBackBy): string {
  switch (heldBackBy) {
    case "backend":
      return "Long-running transactions";
    case "replication_slot":
      return "Lagging or stale physical replication slots";
    case "replication_slot_catalog":
      return "Lagging or stale logical replication slots";
    case "prepared_xact":
      return "Abandoned prepared transactions";
    case "standby":
      return "Long-running queries on standbys";
  }
}

export function labelXminHeldBackByShort(heldBackBy: XminHeldBackBy): string {
  switch (heldBackBy) {
    case "backend":
      return "Long-running Xact";
    case "replication_slot":
      return "Stale Physical Repl";
    case "replication_slot_catalog":
      return "Stale Logical Repl";
    case "prepared_xact":
      return "Abandoned Prep Xact";
    case "standby":
      return "Standby Queries";
  }
}
