import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { useFeature } from "components/OrganizationFeatures";
import { Link } from "react-router-dom";

interface Props {
  databaseId: string;
  tagKey: string;
  tagValue: string;
  githubRepository: string;
  githubBranch: string;
}

const TagValue: React.FunctionComponent<Props> = ({
  databaseId,
  tagKey,
  tagValue,
  githubRepository,
  githubBranch,
}) => {
  const hasSearchFeature = useFeature("querySearch");

  if (tagKey === "line" && githubRepository && githubBranch) {
    const parts = tagValue.split(":", 3);
    if (parts.length !== 3) {
      return <span>{tagValue}</span>;
    }

    const href =
      githubRepository + "/blob/" + githubBranch + parts[0] + "#L" + parts[1];
    return (
      <span>
        <FontAwesomeIcon icon={faGithub} />{" "}
        <a href={href} target="_blank">
          {parts[0]}:{parts[1]}
        </a>
        :{parts[2]}
      </span>
    );
  }

  return hasSearchFeature ? (
    <Link
      to={`/databases/${databaseId}/queries/search#qtk=${tagKey},qtv=${tagValue}`}
    >
      {tagValue}
    </Link>
  ) : (
    <span>{tagValue}</span>
  );
};

export default TagValue;
