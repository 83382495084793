import React from "react";

import {
  faBullhorn,
  faCheck,
  faClipboardListCheck,
  faExclamationCircle,
  faHourglassHalf,
  faHouseDay,
  faStopwatch,
  faTelescope,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./style.module.scss";

export const IconResolved: React.FunctionComponent = () => (
  <FontAwesomeIcon className={styles.issueDetailIcon} icon={faCheck} />
);
export const IconAcknowledged: React.FunctionComponent = () => (
  <FontAwesomeIcon
    className={styles.issueDetailIcon}
    icon={faClipboardListCheck}
  />
);
export const IconNotified: React.FunctionComponent = () => (
  <FontAwesomeIcon className={styles.issueDetailIcon} icon={faBullhorn} />
);
export const IconDetected: React.FunctionComponent = () => (
  <FontAwesomeIcon className={styles.issueDetailIcon} icon={faTelescope} />
);
export const IconOpen: React.FunctionComponent = () => (
  <FontAwesomeIcon
    className={styles.issueDetailIcon}
    icon={faExclamationCircle}
  />
);
export const IconEscalated = IconOpen;
export const IconDowngraded = IconOpen;

export const IconFreqDaily: React.FunctionComponent = () => (
  <FontAwesomeIcon className={styles.issueDetailIcon} icon={faHouseDay} />
);
export const IconFreq30Minutes: React.FunctionComponent = () => (
  <FontAwesomeIcon className={styles.issueDetailIcon} icon={faHourglassHalf} />
);
export const IconFreqRealtime: React.FunctionComponent = () => (
  <FontAwesomeIcon className={styles.issueDetailIcon} icon={faStopwatch} />
);
