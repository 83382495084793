type UserPrefsV1 = {
  version: 1;
  queryList: {
    showRead: boolean;
    showWrite: boolean;
    showOther: boolean;
    doCompare: boolean;
  };
  serverSelect: {
    recentServerIds: { [organizationId: string]: string[] };
  };
  databaseSelect: {
    recentDbIds: { [serverHumanId: string]: string[] };
  };
  flash: {
    dismissed: {
      id: string;
      ts: number;
    }[];
  };
  indexAdvisor: {
    hideAcknowledged: boolean;
  };
};

// Discriminated union of all versions
export type StoredUserPreferences = UserPrefsV1;

// Always the latest value
export type LatestUserPreferences = UserPrefsV1;

// The exposed type hides the version; that's internal to encoding/decoding
export type UserPreferences = Omit<LatestUserPreferences, "version">;

// Take whatever's stored and convert to latest version. We
// can age out old versions if we're confident they are no
// longer in use.
export const convertPreferences = (
  prefs: StoredUserPreferences,
): UserPreferences => {
  const { version, ...rest } = prefs;
  switch (version) {
    case 1:
      return rest;
  }
};
