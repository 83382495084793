import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import OnboardingFlow from "components/OnboardingFlow";
import Loading from "components/Loading";

import {
  ServerSetupDetails,
  ServerSetupDetailsVariables,
} from "./types/ServerSetupDetails";

import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";
import PageContent from "components/PageContent";

const POLL_INTERVAL_MS = 5000;
const MAX_POLL_TIME_MS = 60 * 60 * 1000;

const ServerSetup: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const { server: serverRoute } = useRoutes();
  const { data, error, loading, stopPolling } = useQuery<
    ServerSetupDetails,
    ServerSetupDetailsVariables
  >(QUERY, {
    variables: {
      serverId,
    },
    pollInterval: POLL_INTERVAL_MS,
  });

  useEffect(() => {
    const id = setTimeout(stopPolling, MAX_POLL_TIME_MS);
    return () => clearTimeout(id);
  }, [stopPolling]);

  if (error || loading) {
    return <Loading error={!!error} />;
  }

  const server = data.getServerDetails;
  const org = server.organization;
  const step = server.integratedExplain
    ? "done"
    : server.integratedLogInsights
    ? "explain_setup"
    : server.lastSnapshotAt != null
    ? "log_insights_setup"
    : server.lastTestSnapshotAt != null
    ? "collector_waiting"
    : "collector_setup";

  if (step === "done") {
    return <Navigate to={serverRoute(serverId)} replace />;
  }

  return (
    <PageContent
      title={<span>Get Started</span>}
      windowTitle={`Setup: ${server.name}`}
      pageCategory="server"
      pageName="setup"
      pageProps={{ step }}
    >
      <OnboardingFlow
        organizationSlug={org.slug}
        serverId={serverId}
        currentStep={step}
      />
    </PageContent>
  );
};

export default ServerSetup;
