import React, { useEffect } from "react";
import { useDateRange } from "components/WithDateRange";
import { useCurrentServer } from "components/WithCurrentOrganization";
import moment from "moment-timezone";

const SyncMetricsHorizon: React.FunctionComponent = () => {
  const [range, setRange] = useDateRange();
  const rangeHorizon = range.horizon;
  const { retentionInDays } = useCurrentServer(true) || {
    retentionInDays: null,
  };
  useEffect(() => {
    if (!retentionInDays) {
      return;
    }
    const newHorizon = moment.duration(retentionInDays, "days");
    if (!rangeHorizon || newHorizon.valueOf() !== rangeHorizon.valueOf()) {
      setRange({ horizon: newHorizon });
    }
  }, [retentionInDays, setRange, rangeHorizon]);
  return null;
};

export default SyncMetricsHorizon;
