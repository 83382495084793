import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchange } from "@fortawesome/pro-regular-svg-icons";
import { DocumentNode, QueryHookOptions, useQuery } from "@apollo/client";

import { useTimeout } from "utils/hooks";

import Loading from "components/Loading";

import styles from "./style.module.scss";
import { SpinnerIcon } from "components/Icons";

type PollerProps<Q, V> = {
  query: DocumentNode;
  queryOpts: QueryHookOptions<Q, V>;
  timeout?: number;
  onResult: (result: Q) => void;
  pollingMessage: React.ReactNode;
  timeoutMessage: React.ReactNode;
};

export default function Poller<Q, V>({
  query,
  queryOpts,
  onResult,
  timeout,
  pollingMessage,
  timeoutMessage,
}: PollerProps<Q, V>) {
  const [pollingPaused, setPollingPaused] = useState(false);

  return pollingPaused ? (
    <PollingPaused
      message={timeoutMessage}
      onResume={() => setPollingPaused(false)}
    />
  ) : (
    <Polling
      query={query}
      queryOpts={queryOpts}
      message={pollingMessage}
      onResult={onResult}
      timeout={timeout}
      onPollingTimeout={() => setPollingPaused(true)}
    />
  );
}

const DEFAULT_POLL_INTERVAL_MS = 5000; // 5 seconds
const POLL_TIMEOUT_MS = 10 * 60 * 1000; // 10 minutes

type PollingProps<Q, V> = Pick<
  PollerProps<Q, V>,
  "query" | "queryOpts" | "onResult" | "timeout"
> & {
  onPollingTimeout: () => void;
  message: React.ReactNode;
};

function Polling<Q, V>({
  query,
  queryOpts,
  onResult,
  message,
  timeout = POLL_TIMEOUT_MS,
  onPollingTimeout,
}: PollingProps<Q, V>) {
  useTimeout(onPollingTimeout, timeout, []);

  const { data, loading, error } = useQuery<Q, V>(query, {
    pollInterval: DEFAULT_POLL_INTERVAL_MS,
    ...queryOpts,
  });
  const loaded = !loading && !error;

  useEffect(() => {
    if (loaded && !!data) {
      onResult(data);
    }
  }, [loaded, data, onResult]);

  if (!loaded) {
    return <Loading error={!!error} />;
  }

  return (
    <p>
      <SpinnerIcon />
      &nbsp; {message}
    </p>
  );
}

const PollingPaused: React.FunctionComponent<{
  onResume: () => void;
  message: React.ReactNode;
}> = ({ message, onResume }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    onResume();
  };
  return (
    <div className={styles.pollingPaused}>
      <FontAwesomeIcon icon={faExchange} /> {message}{" "}
      <a href="#" onClick={handleClick}>
        Check again
      </a>
    </div>
  );
};
