import React from "react";

import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons";

import Callout from "components/Callout";
import Panel from "components/Panel";
import LooksGood from "../../LooksGood";
import InsightDescription from "../InsightDescription";

import { useRoutes } from "utils/routes";
import { getOnboardingWaitMinutes } from "utils/check";

import {
  GetVacuumAdvisorData_getIssues as IssueType,
  GetVacuumAdvisorData_getVacuumInsightStatus as InsightStatusType,
} from "../types/GetVacuumAdvisorData";

const SingletonInsightPanel: React.FunctionComponent<{
  serverId: string;
  title: string;
  summary: React.ReactNode;
  details?: React.ReactNode;
  checkGroupAndName: string;
  issue: IssueType | null;
  serverName: string;
  serverCreatedAt: moment.Moment;
  insightStatus: InsightStatusType | null;
}> = ({
  serverId,
  title,
  summary,
  details,
  checkGroupAndName,
  issue,
  serverName,
  serverCreatedAt,
  insightStatus,
}) => {
  const { serverIssue } = useRoutes();

  const onboardingWaitMinutes = getOnboardingWaitMinutes(checkGroupAndName);
  let wait;
  if (onboardingWaitMinutes < 60) {
    wait = `about ${onboardingWaitMinutes} minutes`;
  } else {
    wait = `${Math.round(onboardingWaitMinutes / 60)} hours`;
  }

  let content: React.ReactNode;
  const newServer =
    moment().diff(serverCreatedAt, "minutes") < onboardingWaitMinutes;
  if (issue) {
    const issueId = issue.id;
    const variant = issue.severity === "info" ? "info" : "warning";
    content = (
      <Callout className="mx-[10px] mb-3" variant={variant}>
        {issue.description}
        <Link
          className="block mt-2"
          to={serverIssue(serverId, issueId, checkGroupAndName)}
        >
          View insight
        </Link>
      </Callout>
    );
  } else if (newServer && !insightStatus) {
    content = (
      <div className="p-2.5 pt-0">
        <FontAwesomeIcon className="text-[#337ab7] mr-2" icon={faLightbulbOn} />
        <span className="font-medium">{serverName}</span> was recently
        onboarded; check back in {wait}
      </div>
    );
  } else {
    content = (
      <LooksGood
        className="mx-[10px] mb-2"
        lastRunAt={insightStatus?.lastRunAt}
      />
    );
  }

  return (
    <Panel title={title}>
      <InsightDescription docsPath={`/docs/checks/${checkGroupAndName}`}>
        {summary}
      </InsightDescription>
      {content}
      {details ? <div className="border-t">{details}</div> : null}
    </Panel>
  );
};

export default SingletonInsightPanel;
