import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCopy,
  faTimes,
  faLink,
} from "@fortawesome/pro-light-svg-icons";

import { useUnmounted } from "utils/hooks";

export type Props = {
  className?: string;
  content: string | (() => string);
  label?: string;
  title?: string;
  fixedWidth?: boolean;
  variant?: "text" | "link";
};

const CopyToClipboard: React.FunctionComponent<Props> = ({
  className,
  content,
  label,
  title,
  fixedWidth,
  variant,
}) => {
  const unmounted = useUnmounted();
  const [copied, setCopied] = useState(false);
  const [copyError, setCopyError] = useState(null);
  const { clipboard } = navigator;
  if (!clipboard) {
    return null;
  }

  const handleCopy = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (clipboard) {
      const actualContent = typeof content === "function" ? content() : content;
      clipboard
        .writeText(actualContent)
        .then(() => {
          if (unmounted.current) {
            return;
          }

          setCopied(true);
        })
        .catch((err: Error) => {
          setCopyError(err.message);
        })
        .finally(() => {
          if (unmounted.current) {
            return;
          }

          setTimeout(() => {
            if (unmounted.current) {
              return;
            }

            setCopied(null);
          }, 2000);
        });
    }
  };

  let copyIcon;
  if (copyError) {
    copyIcon = (
      <FontAwesomeIcon
        icon={faTimes}
        title={copyError}
        fixedWidth={fixedWidth}
      />
    );
  } else if (copied) {
    copyIcon = (
      <FontAwesomeIcon icon={faCheck} title="Copied!" fixedWidth={fixedWidth} />
    );
  } else {
    copyIcon = (
      <FontAwesomeIcon
        icon={variant === "link" ? faLink : faCopy}
        title={title}
        fixedWidth={fixedWidth}
      />
    );
  }

  return (
    <a href="" className={className} onClick={handleCopy}>
      {copyIcon}
      {label && <> {label}</>}
    </a>
  );
};

export default CopyToClipboard;
