import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import classNames from "classnames";

import DateRangeBar from "components/DateRangeBar";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import QueryOverviewGraph from "components/QueryOverviewGraph";

import {
  Dashboard as DashboardType,
  DashboardVariables,
} from "./types/Dashboard";
import CheckUp from "./CheckUp";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import System from "./System";
import TableTreeMap from "./TableTreeMap";

const Dashboard: React.FunctionComponent = () => {
  const { databaseId } = useParams();
  const { error, loading, data } = useQuery<DashboardType, DashboardVariables>(
    QUERY,
    {
      variables: {
        databaseId,
      },
    },
  );
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const database = data.getDatabaseDetails;
  const server = data.getServerDetails;
  const organizationSlug = server.organization.slug;

  return (
    <PageContent
      title="Dashboard"
      pageControls={<DateRangeBar />}
      windowTitle={`Dashboard: ${database.datname} / ${server.name}`}
      pageCategory="database"
      pageName="show"
    >
      <div className={classNames("row", styles.dashboardRow)}>
        <div className="col-md-7">
          <h4>Query Runtime</h4>
          <QueryOverviewGraph
            databaseId={databaseId}
            className={styles.queryOverviewGraph}
          />
        </div>
        <div
          className="col-md-4 col-md-offset-1"
          id="checkup"
          style={{ paddingTop: "1.5em" }}
        >
          <CheckUp
            organizationSlug={organizationSlug}
            databaseId={databaseId}
          />
        </div>
      </div>
      <div className={classNames("row", styles.dashboardRow)}>
        <div className="col-md-7">
          <h4>Tables</h4>
          <TableTreeMap
            organizationSlug={organizationSlug}
            databaseId={databaseId}
          />
        </div>
        <System serverId={server.humanId} />
        <div className="checkup col-md-3" />
      </div>
    </PageContent>
  );
};

export default Dashboard;
