import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";

import {
  SchemaTableConstraints as SchemaTableConstraintsType,
  SchemaTableConstraintsVariables,
  SchemaTableConstraints_getSchemaTableConstraints,
} from "./types/SchemaTableConstraints";
import QUERY from "./Query.graphql";

type Props = {
  databaseId: string;
  tableId: string;
};

const SchemaTableConstraints: React.FunctionComponent<Props> = ({
  databaseId,
  tableId,
}) => {
  const { data, loading, error } = useQuery<
    SchemaTableConstraintsType,
    SchemaTableConstraintsVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const constraints = data.getSchemaTableConstraints;

  return (
    <Panel title="Constraints">
      <PanelTable borders={true}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {constraints.map(
            (
              constraint: SchemaTableConstraints_getSchemaTableConstraints,
            ): React.ReactNode => (
              <tr key={constraint.id}>
                <td>
                  <strong>{constraint.name}</strong>
                </td>
                <td className="definition">
                  <pre className="noborder">{constraint.constraintDef}</pre>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </PanelTable>
    </Panel>
  );
};

export default SchemaTableConstraints;
