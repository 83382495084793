import React from "react";

import { formatDateShort } from "utils/format";

import ShowFlash from "components/ShowFlash";
import { COLLECTOR_UPGRADE_URL, useCollectorInfo } from "utils/collector";
import { useAppConfig } from "components/WithAppConfig";

const OutdatedCollectorNotice: React.FunctionComponent<{
  serverId: string;
  hasRecentSnapshot: boolean;
  collectorVersion: string;
}> = ({ serverId, collectorVersion, hasRecentSnapshot }) => {
  const appConfig = useAppConfig();

  if (appConfig.enterpriseEdition) {
    return null;
  }

  if (!collectorVersion) {
    // Don't show a notice if the metadata to check versions is not available.
    return null;
  }

  if (!hasRecentSnapshot) {
    // Ignore version information from stale snapshots; if the latest snapshot
    // is this old, an outdated collector is not the user's biggest problem
    // (since even very old collectors should be able to report at least basic
    // info successfully).
    return null;
  }

  return (
    <OutdatedCollectorNoticeContent
      serverId={serverId}
      collectorVersion={collectorVersion}
    />
  );
};
const OutdatedCollectorNoticeContent: React.FunctionComponent<{
  serverId: string;
  collectorVersion: string;
}> = ({ serverId, collectorVersion }) => {
  const collectorInfo = useCollectorInfo(collectorVersion);

  if (!collectorInfo) {
    // Don't show a notice if the metadata to check versions is not available.
    return null;
  }

  const usedTag = collectorInfo.usedRelease.tagName;
  const usedPublishedAt = collectorInfo.usedRelease.publishedAt;
  const latestTag = collectorInfo.latestRelease.tagName;
  const latestPublishedAt = collectorInfo.latestRelease.publishedAt;

  if (["latest", "recent"].includes(collectorInfo.freshness)) {
    return null;
  }

  const level =
    collectorInfo.freshness === "very outdated" ? "alert" : "notice";

  // We include the collector version in the notice so that if a user upgrades
  // but later a new collector version is released, they see a new notice.
  const flashId = `collector-upgrade-notice-${serverId}-${collectorVersion}`;
  return (
    <ShowFlash
      flashId={flashId}
      dismissable
      level={level}
      msg={
        <>
          {level === "alert" ? (
            <>You are running an obsolete collector version for this server.</>
          ) : (
            <>
              You are not running the latest collector version for this server.
            </>
          )}{" "}
          This server is using collector version {usedTag}, released on{" "}
          {formatDateShort(usedPublishedAt)}, and the latest is {latestTag},
          released on {formatDateShort(latestPublishedAt)}. For more details,
          see the{" "}
          <a rel="noopener" target="_blank" href={COLLECTOR_UPGRADE_URL}>
            upgrade instructions
          </a>
          .
        </>
      }
    />
  );
};

export default OutdatedCollectorNotice;
