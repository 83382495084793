---
title: 'Step 4: Configure the Collector'
install_track_title: Installation Guide (Azure Database for PostgreSQL)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import PublicLastStepLogInsightsLink from '../_public_last_step_log_insights_link.mdx'

export const APIKeyInstructions = ({ apiKey }) => {
  return apiKey ? (
    <React.Fragment>
      The <code>PGA_API_KEY</code> for the current organization is{" "}
      <code>{apiKey}</code>
    </React.Fragment>
  ) : (
    <React.Fragment>
      The <code>PGA_API_KEY</code> can be found in the pganalyze Settings page
      for your organization, under the API keys tab
    </React.Fragment>
  );
};

export const CollectorDotEnv = ({ apiKey }) => {
  return (
    <CodeBlock>
      {`PGA_API_KEY=${apiKey || "your-organization-api-key"}
DB_HOST=your_db.postgres.database.azure.com
DB_NAME=your_database_name
DB_USERNAME=your_monitoring_user
DB_PASSWORD=your_monitoring_user_password
AZURE_SUBSCRIPTION_ID=your_subscription_id
AZURE_DB_SERVER_NAME=your_db`}
    </CodeBlock>
  );
};

## Configuring the collector with Docker

Create your configuration file (e.g. named `pganalyze_collector.env`) with environment variables like this:

<CollectorDotEnv apiKey={props.apiKey} />

Fill in the values step-by-step:

1. <APIKeyInstructions apiKey={props.apiKey} />
2. The `DB_HOST` is the IP address of your Azure Database for PostgreSQL instance
3. The `DB_NAME` is the database on the Azure Database for PostgreSQL instance you want to monitor
4. The `DB_USERNAME` and `DB_PASSWORD` should be the credentials of the monitoring user we created in Step 1
5. The `AZURE_SUBSCRIPTION_ID` is the subscription ID of your Azure Database server
6. (optional) The `AZURE_DB_SERVER_NAME` sets your Azure Database server name - this is automatically inferred from `DB_HOST` and typically not needed.
If you're using Azure Cosmos DB for PostgreSQL Cluster, this is required and set the cluster name.

If the VM has multiple Managed Identities assigned, specify the client ID of the specific Managed Identity created in Step 3
using the `AZURE_AD_CLIENT_ID` setting.

## Test snapshot

Then run the following:

```
docker run --env-file pganalyze_collector.env quay.io/pganalyze/collector:stable test
```

<PublicLastStepLogInsightsLink />
