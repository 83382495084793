---
title: 'Step 2: Enable auto_explain'
install_track_title: 'Automated EXPLAIN: Setup (Heroku Postgres)'
backlink_href: /docs/explain/setup
backlink_title: 'Automated EXPLAIN: Setup'
---

import { getSPLEnableAutoExplainRecommendation } from '../../../components/PgSettingsRecommendations';
import ActuallyEnableAutoExplainHeroku from './_02_actually_enable.mdx';

export const MaybeRecommendEnable = ({settings}) => {
   const recommendation = getSPLEnableAutoExplainRecommendation(settings);
   if (!recommendation.recommendChange) {
      return (
         <p>
            It looks like your <code>session_preload_libraries</code> already includes <code>auto_explain</code>.
            No changes are required.
         </p>
      )
   }
   return <ActuallyEnableAutoExplainHeroku splRecommendation={recommendation} />
}

<MaybeRecommendEnable settings={props.settings} />

<Link className="btn btn-success" to="03_review_settings">
   Proceed to Step 3: Review auto_explain settings
</Link>
