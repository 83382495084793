import React from "react";

import compact from "lodash/compact";
import times from "lodash/times";
import classNames from "classnames";

import { formatBytes, formatPercent } from "utils/format";
import Popover from "components/Popover";
import {
  DashboardSystem,
  DashboardSystemVariables,
  DashboardSystem_getSystemDetails_info,
  DashboardSystem_getSystemDetails_stats,
} from "./types/DashboardSystem";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";

type Props = {
  serverId: string;
};

const System: React.FunctionComponent<Props> = ({ serverId }) => {
  const { data, loading, error } = useQuery<
    DashboardSystem,
    DashboardSystemVariables
  >(QUERY, {
    variables: { serverId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { stats, info } = data.getSystemDetails;
  const systemType = info && info.systemType;
  const postgresVersionHuman =
    data.getCurrentPostgresVersion &&
    data.getCurrentPostgresVersion.humanVersion;
  const postgresVariantHint =
    (systemType == "amazon_rds" &&
      info &&
      info.data.isAuroraPostgres &&
      " · Amazon Aurora") ||
    "";

  return (
    <div
      className={classNames(
        "col-md-4",
        "col-md-offset-1",
        styles.serverSummary,
      )}
    >
      <h4>System</h4>
      {postgresVersionHuman && (
        <p>
          {postgresVersionHuman}
          {postgresVariantHint}
        </p>
      )}
      {systemType == "amazon_rds" && (
        <AmazonRdsSystemDetails stats={stats} info={info} />
      )}
      {systemType == "physical" && (
        <PhysicalSystemDetails stats={stats} info={info} />
      )}
    </div>
  );
};

const AmazonRdsSystemDetails: React.FunctionComponent<{
  stats: DashboardSystem_getSystemDetails_stats;
  info: DashboardSystem_getSystemDetails_info;
}> = ({ stats, info }) => {
  const { storageBytesTotal, storageBytesAvailable } = stats || {};
  const { status, region, instanceId, instanceClass, isAuroraPostgres } =
    info.data;

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Status</td>
            <td>{status}</td>
          </tr>
          <tr>
            <td>Region</td>
            <td>{region}</td>
          </tr>
          <tr>
            <td>Instance ID</td>
            <td>{instanceId}</td>
          </tr>
          <tr>
            <td>Instance Class</td>
            <td>{instanceClass}</td>
          </tr>
          {!isAuroraPostgres && storageBytesTotal && storageBytesAvailable && (
            <tr>
              <td>Disk Space</td>
              <td>
                {formatBytes(storageBytesTotal)} (
                {formatPercent(storageBytesAvailable / storageBytesTotal)} free)
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {region && instanceId && (
        <a
          href={`https://console.aws.amazon.com/rds/home?region=${region}#database:id=${instanceId}`}
          className="btn-amazon"
        >
          View in AWS Console
        </a>
      )}
    </div>
  );
};

const PhysicalSystemDetails: React.FunctionComponent<{
  stats: DashboardSystem_getSystemDetails_stats;
  info: DashboardSystem_getSystemDetails_info;
}> = ({ stats, info }) => {
  const {
    cpuHardwareSockets,
    cpuHardwareModel,
    cpuHardwareCoresPerSocket,
    cpuHardwareSpeedMhz,
    cpuHardwareCacheSize,
    schedulerLoadavg1min,
    schedulerLoadavg5min,
    schedulerLoadavg15min,
    memoryTotalBytes,
    memoryApplicationBytes,
    storageBytesTotal,
    storageBytesAvailable,
  } = stats || {};

  const {
    platform,
    platformVersion,
    operatingSystem,
    kernelVersion,
    architecture,
  } = info.data;

  let memoryFreeBytes = stats ? stats.memoryFreeBytes : null;
  if (memoryApplicationBytes && memoryTotalBytes) {
    memoryFreeBytes = memoryTotalBytes - memoryApplicationBytes;
  }

  return (
    <div>
      {(platform || platformVersion || operatingSystem || kernelVersion) && (
        <p>
          {compact([platform, platformVersion]).join(" ")}
          {(platform || platformVersion) &&
            (operatingSystem || kernelVersion) &&
            " · "}
          {compact([operatingSystem, kernelVersion]).join(" ")}
          {architecture && " · " + architecture}
        </p>
      )}
      {cpuHardwareSockets && (
        <div>
          {times(
            cpuHardwareSockets,
            (socketNum: number): React.ReactNode => (
              <Popover
                title={
                  <span>
                    Socket #{socketNum + 1}:<br />
                    <b>{cpuHardwareModel}</b>
                  </span>
                }
                content={
                  <div>
                    <b>Logical Cores:</b> {cpuHardwareCoresPerSocket}
                    <br />
                    <b>CPU Speed:</b> {cpuHardwareSpeedMhz} MHz
                    <br />
                    <b>Cache Size: </b>{" "}
                    {(cpuHardwareCacheSize &&
                      formatBytes(cpuHardwareCacheSize)) ||
                      "n/a"}
                  </div>
                }
                key={socketNum}
              >
                <img src="/images-old/loggedin/cpu_icon.png" alt="CPU icon" />
              </Popover>
            ),
          )}
        </div>
      )}
      {schedulerLoadavg1min !== null &&
        schedulerLoadavg1min !== undefined &&
        schedulerLoadavg5min !== null &&
        schedulerLoadavg5min !== undefined &&
        schedulerLoadavg15min !== null &&
        schedulerLoadavg15min !== undefined && (
          <div>
            Load Avg: {schedulerLoadavg1min.toFixed(2)}{" "}
            {schedulerLoadavg5min.toFixed(2)} {schedulerLoadavg15min.toFixed(2)}
          </div>
        )}
      {memoryTotalBytes && memoryFreeBytes && (
        <div>
          Memory: {formatBytes(memoryTotalBytes)} (
          {formatPercent(memoryFreeBytes / memoryTotalBytes)} free)
        </div>
      )}
      {storageBytesTotal && storageBytesAvailable && (
        <div>
          Disk Space: {formatBytes(storageBytesTotal)} (
          {formatPercent(storageBytesAvailable / storageBytesTotal)} free)
        </div>
      )}
    </div>
  );
};

export default System;
