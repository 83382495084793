import React from "react";

import { useQuery } from "@apollo/client";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Loading from "components/Loading";

import QUERY from "./Query.graphql";

import {
  TableAutovacuumSettingsVariables,
  TableAutovacuumSettings as TableAutovacuumSettingsType,
  TableAutovacuumSettings_getSchemaTableAutovacuumSettings as TableAutovacuumSetting,
} from "./types/TableAutovacuumSettings";
import Grid from "components/Grid";
import { ServerIcon, TableIcon } from "components/Icons";
import Popover from "components/Popover";
import { ConfigSettingDocsSnippet } from "components/DocsSnippet";
import { formatApproximateNumber } from "utils/format";

const TableAutovacuumSettings: React.FunctionComponent<{
  serverId: string;
  databaseId: string;
  tableId: string;
  tableName: string;
}> = ({ serverId, databaseId, tableId, tableName }) => {
  const { data, loading, error } = useQuery<
    TableAutovacuumSettingsType,
    TableAutovacuumSettingsVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
    },
  });

  const settings = data?.getSchemaTableAutovacuumSettings;

  return (
    <Panel title={`Autovacuum Settings on Table ${tableName}`}>
      {loading || error ? (
        <Loading error={!!error} />
      ) : (
        <TableAutovacuumSettingsGrid settings={settings} serverId={serverId} />
      )}
    </Panel>
  );
};

const TableAutovacuumSettingsGrid: React.FunctionComponent<{
  settings: TableAutovacuumSetting[];
  serverId: string;
}> = ({ settings, serverId }) => {
  if (settings.length === 0) {
    return <PanelSection>No settings found on this table</PanelSection>;
  }
  // Remove toast option for now
  const filteredSettings = settings.filter((val) => !val.toastOption);

  return (
    <Grid
      data={filteredSettings}
      className="grid-cols-[1fr_160px]"
      columns={[
        {
          field: "name",
          header: "Setting",
          renderer: function NameCell({ rowData, fieldData }) {
            return (
              <ConfigSettingDocsSnippet
                serverId={rowData["tableOverrideAvailable"] && serverId}
                configName={fieldData}
                noIcon
              />
            );
          },
        },
        {
          field: "tableValue",
          header: "Current",
          className: "text-right",
          tip: "Icon indicates if this value is a table only option or a server level config that can be overridden for individual tables",
          renderer: function ValueCell({ rowData, fieldData }) {
            const tableValue = fieldData;
            const serverValue = rowData["serverValue"];
            const overrideAvailable = rowData["tableOverrideAvailable"];
            const overrode =
              overrideAvailable && tableValue && serverValue !== tableValue;
            const value = tableValue || serverValue;
            const popoverContent = overrode ? (
              <>
                <div>Value is overridden at the table level</div>
                <div>
                  Sever value: {formatApproximateNumber(+serverValue as number)}
                </div>
              </>
            ) : overrideAvailable ? (
              "Server level config that can be overridden for individual tables"
            ) : (
              "Table only option (Storage Parameters)"
            );

            return (
              <Popover content={popoverContent}>
                <span
                  className={
                    ((overrideAvailable && overrode) || tableValue) &&
                    `bg-yellow-200`
                  }
                >
                  {value
                    ? formatApproximateNumber(+value as number)
                    : "(unset)"}
                </span>
                {overrideAvailable ? (
                  <ServerIcon className="ml-2" />
                ) : (
                  <TableIcon className="ml-2" />
                )}
              </Popover>
            );
          },
        },
      ]}
    />
  );
};

export default TableAutovacuumSettings;
