import React from "react";

import classNames from "classnames";

const Badge: React.FunctionComponent<{
  children: React.ReactNode;
  title?: string;
  className?: string;
  jumbo?: boolean;
}> = ({ children, title, className, jumbo }) => {
  const baseStyle = "bg-[#d9edf7] text-[#31708f] border-[#bce8f1]";
  const badgeStyles = jumbo
    ? "align-middle text-xs leading-3 rounded-full py-1 px-3"
    : "align-middle text-[8pt] rounded-md py-[1px] px-[3px] ml-[3px]";

  return (
    <span
      title={title}
      className={classNames(baseStyle, badgeStyles, className)}
    >
      {children}
    </span>
  );
};

export default Badge;
