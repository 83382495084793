import React from "react";
import ExplainList from "components/ExplainList";
import PageContent from "components/PageContent";

const ExplainOverview = () => {
  return (
    <PageContent
      title="EXPLAIN Plans"
      pageCategory="queries"
      pageName="explains"
    >
      <ExplainList />
    </PageContent>
  );
};

export default ExplainOverview;
