import React, { useCallback, useState, useRef, useEffect } from "react";
import classNames from "classnames";

import { usePrevious } from "utils/hooks";

import styles from "./style.module.scss";

type Props = {
  trigger:
    | React.ReactNode
    | ((args: { open: boolean; toggleOpen: () => void }) => React.ReactNode);
  children: (args: { setClosed: () => void }) => React.ReactNode;
  onClose?: () => void;
  className?: string;
};

const Dropdown: React.FunctionComponent<Props> = ({
  trigger,
  children,
  onClose,
  className,
}) => {
  const [open, setOpen] = useState(false);
  // N.B.: we handle onClose like this to avoid invoking the callback
  // when the setState call has not been processed yet
  const wasOpen = usePrevious(open);
  useEffect(() => {
    if (onClose && !open && wasOpen) {
      onClose();
    }
  }, [open, wasOpen, onClose]);
  const setClosed = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const ref = useRef<HTMLDivElement>();

  const handleToggleOpen = () => {
    setOpen((value) => !value);
  };

  useEffect(() => {
    const handleMaybeOutsideClick = (e: Event) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setClosed();
      }
    };

    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setClosed();
      }
    };

    document.addEventListener("mousedown", handleMaybeOutsideClick);
    document.addEventListener("keyup", handleEsc);

    return () => {
      document.removeEventListener("mousedown", handleMaybeOutsideClick);
      document.removeEventListener("keyup", handleEsc);
    };
  }, [setClosed]);

  return (
    <div ref={ref} className={classNames(styles.dropdown, className)}>
      {typeof trigger === "function" ? (
        trigger({ open, toggleOpen: handleToggleOpen })
      ) : (
        <button onClick={handleToggleOpen}>{trigger}</button>
      )}
      {open && (
        <div className={styles.dropdownContent}>{children({ setClosed })}</div>
      )}
    </div>
  );
};

export default Dropdown;
