import React, { useState } from "react";

import QUERY from "./Query.graphql";
import MUTATION from "./Mutation.graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CreateExplainWorkbook,
  CreateExplainWorkbookVariables,
} from "./types/CreateExplainWorkbook";
import {
  QueryForWorkbook,
  QueryForWorkbookVariables,
  QueryForWorkbook_getQueryForWorkbook_query as QueryType,
} from "./types/QueryForWorkbook";
import ModalContainer from "components/ModalContainer";
import Grid from "components/Grid";
import { useNavigate } from "react-router-dom";
import { useRoutes } from "utils/routes";

const CreateWorkbookPanel = ({
  onDismiss,
  databaseId,
  queryId,
}: {
  onDismiss: () => void;
  databaseId: string;
  queryId?: string;
}) => {
  const [name, setName] = useState(queryId ? `Query #${queryId}` : "");
  const [description, setDescription] = useState(
    queryId ? "Created from Query Details page" : "",
  );
  const [baselineQuery, setBaselineQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { databaseWorkbook } = useRoutes();

  // Mutation for creating workbook
  const [createWorkbook] = useMutation<
    CreateExplainWorkbook,
    CreateExplainWorkbookVariables
  >(MUTATION);

  // Query for checking if the query is valid
  const [validateQuery, { loading, data }] = useLazyQuery<
    QueryForWorkbook,
    QueryForWorkbookVariables
  >(QUERY);

  const handleCheckQuery = () => {
    if (baselineQuery === "") {
      setErrorMessage("Baseline query is required");
      return;
    }
    validateQuery({
      variables: { databaseId, query: baselineQuery },
      onCompleted: (data) => {
        if (!data.getQueryForWorkbook.validQuery) {
          setErrorMessage(
            "Query is not valid: " + data.getQueryForWorkbook.errorMessage,
          );
        } else {
          setErrorMessage(null);
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleCreate = () => {
    if (name === "") {
      setErrorMessage("Name is required");
      return;
    }
    if (!queryId && baselineQuery === "") {
      setErrorMessage("Baseline query is required");
      return;
    }
    createWorkbook({
      variables: {
        databaseId: databaseId,
        name: name,
        queryText: queryId ? null : baselineQuery,
        queryId: queryId,
        description: description || null,
      },
      onCompleted: (data) => {
        onDismiss();
        navigate(
          databaseWorkbook(
            databaseId,
            data.createExplainWorkbook.explainWorkbookId,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const validQuery = !!queryId || (data && data.getQueryForWorkbook.validQuery);
  const baselineQueryNote = loading
    ? "Checking query..."
    : validQuery && !queryId && data.getQueryForWorkbook.query == null
    ? "Could not match with existing query"
    : "";
  const queryGrid =
    validQuery && !queryId && data.getQueryForWorkbook.query !== null ? (
      <BaselineQueryGrid query={data.getQueryForWorkbook.query} />
    ) : null;
  const showNextButton = validQuery;

  return (
    <ModalContainer title="New workbook" onClose={onDismiss}>
      <p>
        Create variants of a query and track progress towards improving query
        time.
      </p>
      <div className="grid grid-cols-2 mt-4">
        <div className="mb-1 font-medium col-span-2">Name</div>
        <input
          className="col-span-2 bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Name of workbook"
        />
      </div>
      <div className="grid grid-cols-2 mt-4">
        <div className="mb-1 font-medium col-span-2">
          Description (Optional)
        </div>
        <input
          className="col-span-2 bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
          type="text"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          placeholder="Description of workbook (Optional)"
        />
      </div>
      {!queryId && (
        <div className="grid grid-cols-2 mt-4">
          <div className="mb-1 font-medium">Baseline Query</div>
          <div className="mb-1 justify-self-end text-[#606060]">
            {baselineQueryNote}
          </div>
          <div className="col-span-2">
            {queryGrid ? (
              queryGrid
            ) : (
              <textarea
                className="bg-white rounded border border-gray-300 box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
                onChange={(e) => setBaselineQuery(e.target.value)}
                value={baselineQuery}
                placeholder="Start by pasting the query SQL to match with existing query in pganalyze"
                disabled={showNextButton}
                rows={10}
              />
            )}
          </div>
        </div>
      )}
      <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
      <div className="text-right mt-4">
        <button className="btn !px-10" onClick={onDismiss}>
          Cancel
        </button>
        {showNextButton ? (
          <button className="btn btn-success !w-[140px]" onClick={handleCreate}>
            Next
          </button>
        ) : (
          <button
            className="btn btn-success !w-[140px]"
            onClick={handleCheckQuery}
          >
            Check Query
          </button>
        )}
      </div>
    </ModalContainer>
  );
};

export const BaselineQueryGrid = ({ query }: { query: QueryType }) => {
  return (
    <Grid
      className="grid-cols-[1fr_160px_160px]"
      data={[query]}
      columns={[
        {
          field: "truncatedQuery",
          header: "Query",
          style: "query",
          disableSort: true,
        },
        {
          field: "postgresRole",
          header: "Role",
          renderer: function RoleCell({ fieldData }) {
            return fieldData.name;
          },
          disableSort: true,
        },
        {
          field: "fingerprint",
          header: "Fingerprint",
          disableSort: true,
        },
      ]}
    />
  );
};

export default CreateWorkbookPanel;
