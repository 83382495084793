import React from "react";
import { Link, useParams } from "react-router-dom";

import moment from "moment-timezone";
import { upperFirst } from "lodash";
import classNames from "classnames";
import { useMutation, useQuery } from "@apollo/client";

import { formatTimestampShort } from "utils/format";

import {
  checkFrequency,
  checkTitle,
  CHECK_FREQUENCY_30MIN,
  CHECK_FREQUENCY_DAILY,
  CHECK_FREQUENCY_REALTIME,
} from "../../../../docs/util/checks";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import CheckStatusBadge from "components/CheckStatusBadge";
import PanelItems from "components/PanelItems";
import PanelItem from "components/PanelItem";
import Loading from "components/Loading";
import { useAsyncActionFlash } from "components/WithFlashes";

import {
  IssueDetail,
  IssueDetailVariables,
  IssueDetail_getIssue as IssueType,
} from "./types/IssueDetail";
import {
  UpdateIssueState,
  UpdateIssueStateVariables,
} from "./types/UpdateIssueState";

import styles from "./style.module.scss";

import QUERY from "./Query.graphql";
import MUTATION from "./Mutation.graphql";

import {
  IconAcknowledged,
  IconFreq30Minutes,
  IconFreqDaily,
  IconFreqRealtime,
  IconOpen,
  IconResolved,
} from "./Icons";

import IssueDescription from "components/IssueDescription";
import IssueDetailBody from "./IssueDetailBody";
import { getNameByPreset } from "components/SchemaTableIndexAdvisor/util";

const IssueDetailPage: React.FunctionComponent = () => {
  const { checkGroup, checkName, issueId } = useParams();
  const { data, loading, error } = useQuery<IssueDetail, IssueDetailVariables>(
    QUERY,
    {
      variables: { issueId },
    },
  );

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const issue = data.getIssue;

  return (
    <PageContent
      windowTitle={`Issue #${issue.id}`}
      title={`Issue #${issue.id}: ${checkTitle(checkGroup, checkName)}`}
      pageCategory="checks"
      pageName="show"
      pageTab="main"
      pageProps={{ check: `${checkGroup}/${checkName}` }}
    >
      <Panel title="Overview">
        <PanelSection>
          <PanelItems>
            <PanelItem label="Severity">
              <CheckStatusBadge status={issue.severity} />
            </PanelItem>
            <CheckFrequencyPanelItem
              checkGroup={checkGroup}
              checkName={checkName}
            />
            <PanelItem label="Last Updated">
              {formatTimestampShort(moment.unix(issue.updatedAt))}
            </PanelItem>
            <IssueStatePanelItem
              issueId={issue.id}
              state={issue.state}
              canAcknowledge={issue.permittedToModify}
            />
          </PanelItems>
          <DescriptionPanelItem issue={issue} />
          {issue.checkGroupAndName === "index_advisor/indexing_engine" && (
            <IndexingEngineConfigPanelItem issue={issue} />
          )}
        </PanelSection>
      </Panel>
      <IssueDetailBody
        issue={issue}
        checkGroup={checkGroup}
        checkName={checkName}
      />
    </PageContent>
  );
};

const DescriptionPanelItem: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  return (
    <PanelItem label="Description">
      <IssueDescription issue={issue} />
    </PanelItem>
  );
};

const IndexingEngineConfigPanelItem: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const tableRef = issue.descriptionReferences.find(
    (ref) => ref.param === "table",
  );
  if (!tableRef) {
    return null;
  }
  const linkPath = tableRef.path + "/index_advisor";
  const issueDetails = JSON.parse(issue.detailsJson);
  const currentSetting =
    getNameByPreset(issueDetails.index_advisor_settings_preset) ?? "Unknown";

  return (
    <PanelItem label="Current Index Advisor Configuration">
      {currentSetting} &mdash;{" "}
      <Link to={linkPath}>Explore other configurations</Link>
    </PanelItem>
  );
};

const CheckFrequencyPanelItem: React.FunctionComponent<{
  checkGroup: string;
  checkName: string;
}> = ({ checkGroup, checkName }) => {
  const frequency = checkFrequency(checkGroup, checkName);
  const IconCheckFrequency = CHECK_FREQUENCY_ICONS[frequency];

  return (
    <PanelItem label="Check Frequency">
      <IconCheckFrequency /> {frequency}
    </PanelItem>
  );
};

const IssueStatePanelItem: React.FunctionComponent<{
  issueId: string;
  state: string;
  canAcknowledge: boolean;
}> = ({ issueId, state, canAcknowledge }) => {
  const [updateIssueState, { called, loading, error }] = useMutation<
    UpdateIssueState,
    UpdateIssueStateVariables
  >(MUTATION);
  useAsyncActionFlash({
    called,
    loading,
    error: error?.message,
    success: "Issue state updated!",
  });

  const stateStr = upperFirst(state);
  const Icon =
    state === "resolved"
      ? IconResolved
      : state === "acknowledged"
      ? IconAcknowledged
      : IconOpen;
  const btnDisabled = loading || !canAcknowledge;
  const btnTip = canAcknowledge
    ? null
    : "You are not permitted to acknowledge or re-open issues";
  const stateAction = state === "triggered" ? "Acknowledge" : "Re-open";
  const newState = state === "triggered" ? "acknowledged" : "triggered";
  const btnKind = state === "triggered" ? "btn-success" : "btn-primary";

  const handleButtonClick = () => {
    updateIssueState({
      variables: {
        issueId,
        newState,
      },
    });
  };

  return (
    <PanelItem label="State">
      <Icon /> {stateStr}
      {state !== "resolved" && (
        <>
          {" "}
          <button
            title={btnTip}
            disabled={btnDisabled}
            className={classNames("btn", btnKind, styles.ackBtn)}
            onClick={handleButtonClick}
          >
            {stateAction}
          </button>
        </>
      )}
    </PanelItem>
  );
};

const CHECK_FREQUENCY_ICONS = {
  [CHECK_FREQUENCY_DAILY]: IconFreqDaily,
  [CHECK_FREQUENCY_30MIN]: IconFreq30Minutes,
  [CHECK_FREQUENCY_REALTIME]: IconFreqRealtime,
};

export default IssueDetailPage;
