import React, { useContext, useState } from "react";

import classNames from "classnames";

import CopyToClipboard from "components/CopyToClipboard";
import FilterSearch from "components/FilterSearch";
import Grid, { NumberCell } from "components/Grid";
import LooksGood from "components/LooksGood";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import { useRouteHashState } from "utils/hooks";
import { makeFilter } from "utils/filter";
import { formatNumber } from "utils/format";
import { IndexSelectionResultType, IndexType } from "./util";

import IndexReference from "./IndexReference";
import ScanReference from "./ScanReference";
import Details from "./Details";
import SummaryPanel from "./SummaryPanel";
import Callout from "components/Callout";
import PanelTitle from "components/PanelTitle";
import { IndexAddIcon, IndexDropIcon, IndexOkayIcon } from "components/Icons";
import { uniq } from "lodash";
import { useFeature } from "components/OrganizationFeatures";
import { quoteIdent } from "utils/ddl";

const IndexSelectionResults: React.FunctionComponent<{
  result: IndexSelectionResultType;
  databaseId: string;
  useConsolidation: boolean;
  verbose?: boolean;
}> = ({ result, databaseId, useConsolidation, verbose }) => {
  return (
    <WithIndexSelectionFocus>
      <IndexSelectionResultsContent
        result={result}
        databaseId={databaseId}
        useConsolidation={useConsolidation}
        verbose={verbose}
      />
    </WithIndexSelectionFocus>
  );
};

const IndexSelectionResultsContent: React.FunctionComponent<{
  result: IndexSelectionResultType;
  databaseId: string;
  useConsolidation: boolean;
  verbose?: boolean;
}> = ({ result, databaseId, useConsolidation, verbose }) => {
  const [focus, setFocus] = useIndexSelectionFocus();
  function handleCloseDetails() {
    setFocus(null);
  }

  const indexChanges = Object.values(result.indexes).filter((idx) => {
    const toAdd = !idx.existing && idx.selected;
    const toDrop = idx.existing && !idx.selected;
    return toAdd || toDrop;
  });

  const hasInsights = indexChanges.length > 0;

  return (
    <>
      {focus && (
        <Details
          databaseId={databaseId}
          result={result}
          locator={focus}
          onClose={handleCloseDetails}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-[1fr,min(max(400px,30%),40%)] gap-0 md:gap-4 items-start">
        {hasInsights ? (
          <IndexChangesPanel
            result={result}
            useConsolidation={useConsolidation}
          />
        ) : (
          <div>
            <NoInsightsPanel />
            <IndexesNoChange result={result} />
          </div>
        )}
        <SummaryPanel result={result} />
      </div>
      {hasInsights && (
        <Callout className="mb-[1em]">
          We recommend{" "}
          <a
            target="_blank"
            href="https://pganalyze.com/docs/index-advisor/test-insights"
          >
            testing insights
          </a>{" "}
          in pre-production or staging environments first before deploying
          changes to production. If possible, it is advisable to use a copy of
          the production database for your tests, otherwise you may not see a
          representative performance improvement or query plan change. Please be
          aware of{" "}
          <a
            target="_blank"
            href="https://pganalyze.com/docs/indexing-engine/limitations"
          >
            Indexing Engine limitations
          </a>{" "}
          when reviewing insights.
        </Callout>
      )}
      {verbose && (
        <>
          <AllScansOnTablePanel result={result} />
          <AllConsideredIndexesPanel result={result} />
        </>
      )}
    </>
  );
};

const NoInsightsPanel: React.FunctionComponent = () => {
  return (
    <Panel title="No Recommended Changes">
      <PanelSection>
        With the current configuration, the Index Advisor did not find any index
        changes to recommend. Explore different indexing priorities by changing
        the configuration.
        <a
          className="block mb-2"
          target="_blank"
          href="https://pganalyze.com/docs/indexing-engine/cp-model"
        >
          Learn more in documentation
        </a>
        <LooksGood />
      </PanelSection>
    </Panel>
  );
};

const IndexSelectionFocusContext = React.createContext<
  [string, (value: string) => void]
>(["", (_value: string) => undefined]);

const WithIndexSelectionFocus = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const value = useRouteHashState({
    decode: String,
    encode: String,
  });
  return (
    <IndexSelectionFocusContext.Provider value={value}>
      {children}
    </IndexSelectionFocusContext.Provider>
  );
};

function useIndexSelectionFocus() {
  return useContext(IndexSelectionFocusContext);
}

const AllScansOnTablePanel: React.FunctionComponent<{
  result: IndexSelectionResultType;
}> = ({ result }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const scans = Object.values(result.scans);
  const scanGridData = scans.map((scan) => {
    const estCurrCost = scan.bestExistingCost;
    const estNewCost = scan.bestSelectedCost;
    const indexChanged = estCurrCost.indexId !== estNewCost.indexId;
    return {
      ...scan,
      combinedClauses: scan.combinedExpression,
      indexChanged,
      currIndex: estCurrCost?.indexId,
      estCurrCost: estCurrCost?.cost,
      newIndex: estNewCost?.indexId,
      estNewCost: estNewCost?.cost,
    };
  });
  const filteredData = scanGridData.filter(
    makeFilter(searchTerm, "combinedClauses", "label"),
  );
  return (
    <Panel
      title={`All Scans on Table (${scanGridData.length})`}
      expandable
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <PanelSection>
        <Grid
          className="grid-cols-[60%_10%_10%_5%_10%_5%]"
          data={filteredData}
          defaultSortBy="combinedClauses"
          pageSize={10}
          columns={[
            {
              field: "combinedClauses",
              header: "Scan Expression",
              renderer: function ScanDetailsCell({ rowData }) {
                return <ScanReference className="w-full" scan={rowData} />;
              },
            },
            {
              field: "scansPerMin",
              header: "Est. Scans/min",
              style: "number",
              nullValue: "n/a",
              renderer: NumberCell.precision(2),
            },
            {
              field: "estCurrCost",
              header: "Est. Cost",
              style: "number",
              nullValue: "n/a",
              renderer: NumberCell,
            },
            {
              field: "currIndex",
              header: "",
              disableSort: true,
              title: ({ fieldData }) => {
                if (!fieldData) {
                  return "Sequential Scan";
                }

                return result.indexes[fieldData].structure;
              },
              renderer: function EstCurrCostCell({ fieldData }) {
                return (
                  <IndexReference terse index={result.indexes[fieldData]} />
                );
              },
            },
            {
              field: "estNewCost",
              header: "Est. New Cost",
              style: "number",
              nullValue: "n/a",
              renderer: function EstNewCostCell({ rowData, fieldData }) {
                if (!rowData.indexChanged) {
                  return "no change";
                }
                return formatNumber(fieldData);
              },
            },
            {
              field: "newIndex",
              header: "",
              disableSort: true,
              title: ({ rowData, fieldData }) => {
                if (!fieldData) {
                  return "Sequential Scan";
                }

                if (!rowData.indexChanged) {
                  return undefined;
                }

                return result.indexes[fieldData].structure;
              },
              renderer: function EstNewCostCell({ rowData, fieldData }) {
                if (!rowData.indexChanged) {
                  return null;
                }

                return (
                  <IndexReference
                    terse
                    className="ml-1"
                    index={result.indexes[fieldData]}
                  />
                );
              },
            },
          ]}
        />
      </PanelSection>
    </Panel>
  );
};

const IndexChangesPanel: React.FunctionComponent<{
  result: IndexSelectionResultType;
  useConsolidation: boolean;
}> = ({ result, useConsolidation }) => {
  const allowConsolidation = useFeature("indexAdvisorV3Consolidation");
  const indexChanges = Object.values(result.indexes).filter((idx) => {
    const toAdd = !idx.existing && idx.selected;
    const toDrop = idx.existing && !idx.selected;
    return toAdd || toDrop;
  });

  const gridData = indexChanges.map((idx) => {
    const action: "add" | "drop" =
      !idx.existing && idx.selected ? "add" : "drop";

    const costChange = idx.scans.reduce((totChange, scanCost) => {
      const scan = result.scans[scanCost.scanId];
      const bestExistingCost = scan.bestExistingCost;
      const bestSelectedCost = scan.bestSelectedCost;

      if (
        (action === "add" && bestSelectedCost.indexId === idx.id) ||
        (action === "drop" && bestExistingCost.indexId === idx.id)
      ) {
        return totChange + (bestSelectedCost.cost - bestExistingCost.cost);
      } else {
        return totChange;
      }
    }, 0);

    return {
      ...idx,
      action,
      costChange,
      affectedQueriesCount: getAffectedQueriesCount(result, idx),
    };
  });

  const createGridData = gridData.filter((item) => {
    return item.action === "add";
  });

  const dropGridData = gridData.filter((item) => {
    return allowConsolidation && useConsolidation && item.action === "drop";
  });

  const indexIconStyle = "inline-block relative top-0.5";

  return (
    <Panel
      title={
        <span>
          <IndexAddIcon className={indexIconStyle} /> Create Index (
          {createGridData.length})
        </span>
      }
      secondaryTitle={
        createGridData.length > 0 && (
          <CopyToClipboard
            className="text-sm"
            content={createGridData.map((idx) => idx.ddl + ";").join("\n")}
            label={`Copy ${createGridData.length} commands`}
          />
        )
      }
    >
      <Grid
        className="grid-cols-[1fr_18%_18%_15%] mb-1"
        data={createGridData}
        defaultSortBy="costChange"
        noRowsText="No indexes to add"
        cellRenderer={LargeCellRenderer}
        columns={[
          {
            field: "structure",
            header: "Index",
            renderer: function IndexChangeCell({ rowData }) {
              return (
                <IndexReference className="block max-w-full" index={rowData} />
              );
            },
          },
          {
            field: "costChange",
            header: "Scan Cost Change",
            style: "number",
            renderer: CostChangeCell,
          },
          {
            field: "writeOverhead",
            header: "Index Write Overhead",
            style: "number",
            renderer: function IndexWriteOverheadCell({ fieldData }) {
              return (
                <IndexWriteOverheadChange value={fieldData} action="add" />
              );
            },
          },
          {
            field: "affectedQueriesCount",
            header: "Affected Queries",
            style: "number",
          },
        ]}
      />
      {allowConsolidation && (
        <>
          <PanelTitle
            title={
              <span>
                <IndexDropIcon className={indexIconStyle} /> Drop Index (
                {dropGridData.length})
              </span>
            }
            inner
            secondaryTitle={
              dropGridData.length > 0 && (
                <CopyToClipboard
                  className="text-sm"
                  content={dropGridData
                    .map(
                      (idx) =>
                        `DROP INDEX CONCURRENTLY ${quoteIdent(idx.name)};`,
                    )
                    .join("\n")}
                  label={`Copy ${dropGridData.length} commands`}
                />
              )
            }
          />
          {useConsolidation ? (
            <Grid
              className="grid-cols-[1fr_18%_18%_15%] mb-1"
              data={dropGridData}
              defaultSortBy="costChange"
              noRowsText="No indexes to drop."
              cellRenderer={LargeCellRenderer}
              columns={[
                {
                  field: "structure",
                  header: "Index",
                  renderer: function IndexChangeCell({ rowData }) {
                    return (
                      <IndexReference
                        className="block max-w-full"
                        index={rowData}
                      />
                    );
                  },
                },
                {
                  field: "costChange",
                  header: "Scan Cost Change",
                  style: "number",
                  renderer: CostChangeCell,
                },
                {
                  field: "writeOverhead",
                  header: "Index Write Overhead",
                  style: "number",
                  renderer: function IndexWriteOverheadCell({ fieldData }) {
                    return (
                      <IndexWriteOverheadChange
                        value={fieldData}
                        action="drop"
                      />
                    );
                  },
                },
                {
                  field: "affectedQueriesCount",
                  header: "Affected Queries",
                  style: "number",
                },
              ]}
            />
          ) : (
            <PanelSection>
              Drop index insights not enabled. Use custom configuration settings
              to explore index consolidation/removal.
            </PanelSection>
          )}
        </>
      )}
      <IndexesNoChange result={result} embedded />
    </Panel>
  );
};

function getAffectedQueriesCount(
  result: IndexSelectionResultType,
  idx: IndexType,
): number {
  const allAffectedQueries = idx.scans.flatMap((scanCost) =>
    result.scans[scanCost.scanId].bestSelectedCost.indexId == idx.id
      ? result.scans[scanCost.scanId].queries
      : [],
  );
  const affectedQueries = uniq(allAffectedQueries.map((q) => q.id));
  return affectedQueries.length;
}

const IndexesNoChange: React.FunctionComponent<{
  result: IndexSelectionResultType;
  embedded?: boolean;
}> = ({ result, embedded }) => {
  const indexesNoChange = Object.values(result.indexes)
    .filter((idx) => idx.existing && idx.selected)
    .map((idx) => {
      return {
        ...idx,
        affectedQueriesCount: getAffectedQueriesCount(result, idx),
      };
    });

  const grid = (
    <Grid
      className="grid-cols-[1fr_18%_15%]"
      cellRenderer={LargeCellRenderer}
      data={indexesNoChange}
      columns={[
        {
          field: "structure",
          header: "Index",
          renderer: function IndexChangeCell({ rowData }) {
            return (
              <IndexReference className="block max-w-full" index={rowData} />
            );
          },
        },
        {
          field: "writeOverhead",
          header: "Index Write Overhead",
          style: "number",
          // this is not an increase or a decrease; just format the number
          // without a sign or color-coding
          renderer: NumberCell.precision(2),
        },
        {
          field: "affectedQueriesCount",
          header: "Affected Queries",
          style: "number",
        },
      ]}
    />
  );

  const title = (
    <span>
      <IndexOkayIcon className="inline-block relative top-0.5" /> Keep Existing
      Indexes ({indexesNoChange.length})
    </span>
  );

  if (embedded) {
    return (
      <>
        <PanelTitle title={title} inner />
        {grid}
      </>
    );
  } else {
    return <Panel title={title}>{grid}</Panel>;
  }
};

const CostChangeCell: React.FunctionComponent<{ fieldData: number }> = ({
  fieldData,
}) => {
  if (fieldData === 0) {
    return <>-</>;
  }

  const absCost = Math.abs(fieldData);
  let style: string;
  let sign: string;
  if (fieldData > 0) {
    style = "text-[#c22426]";
    sign = "+";
  } else if (fieldData < 0) {
    style = "text-[#43962a]";
    sign = "-";
  }

  return (
    <span className={style}>
      {sign}
      {formatNumber(absCost)}
    </span>
  );
};

const IndexWriteOverheadChange: React.FunctionComponent<{
  action: "add" | "drop";
  value: number;
}> = ({ action, value }) => {
  let style: string;
  let sign: string;
  if (action === "add") {
    style = "text-[#c22426]";
    sign = "+";
  } else if (action === "drop") {
    style = "text-[#43962a]";
    sign = "-";
  }

  return (
    <span className={style}>
      {sign}
      {formatNumber(value, 2)}
    </span>
  );
};

// These grids have taller cells than normal; give them some top margin to avoid
// having them feel cramped.
const LargeCellRenderer = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return <div className={classNames(className, "mt-1.5")}>{children}</div>;
};

const AllConsideredIndexesPanel: React.FunctionComponent<{
  result: IndexSelectionResultType;
}> = ({ result }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const allIndexes = Object.values(result.indexes);

  const filteredData = allIndexes.filter(makeFilter(searchTerm, "structure"));

  const noRowsText =
    allIndexes.length === 0
      ? "No indexes exist or were considered"
      : "No indexes found";

  return (
    <Panel
      title={`All Considered Indexes (${allIndexes.length})`}
      expandable
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <PanelSection>
        <Grid
          data={filteredData}
          noRowsText={noRowsText}
          className="grid grid-cols-[1fr_15%]"
          defaultSortBy="structure"
          pageSize={10}
          columns={[
            {
              field: "structure",
              header: "Index",
              renderer: ({ rowData }) => {
                return (
                  <IndexReference className="max-w-full" index={rowData} />
                );
              },
            },
            {
              field: "writeOverhead",
              header: "Write Overhead",
              style: "number",
              nullValue: "n/a",
              renderer: NumberCell.precision(2),
            },
          ]}
        />
      </PanelSection>
    </Panel>
  );
};

export default IndexSelectionResults;
