import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";

import { IndexSelectionResultType, getWeightedCostImprovement } from "../util";
import {
  formatApproximateNumber,
  formatNumber,
  formatTimestampLong,
} from "utils/format";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { TrendDownArrow, TrendUpArrow } from "components/Icons";
import classNames from "classnames";
import { calculateImpact } from "components/IndexAdvisor/util";
import ImpactRenderer from "components/ImpactRenderer";
import Tip from "components/Tip";

const SummaryPanel: React.FunctionComponent<{
  result: IndexSelectionResultType;
}> = ({ result }) => {
  const statistics = result.output.statistics;

  const hasInsights = Object.values(result.indexes).some((idx) => {
    const toAdd = !idx.existing && idx.selected;
    const toDrop = idx.existing && !idx.selected;
    return toAdd || toDrop;
  });

  if (["Infeasible", "Unknown"].includes(statistics.status)) {
    return (
      <Panel title="Estimated Outcome">
        <PanelSection>
          <h2 className="mt-0 text-lg text-[#606060]">
            <FontAwesomeIcon icon={faWarning} /> Error: Could not find a valid
            index selection
          </h2>
          This usually occurs when a rule is too restrictive. To find a valid
          selection, modify the settings to be more tolerant.
        </PanelSection>
      </Panel>
    );
  }

  const allIndexes = Object.values(result.indexes);
  const oldIndexes = allIndexes.filter((idx) => idx.existing);
  const newIndexes = allIndexes.filter((idx) => idx.selected);
  const allScans = Object.values(result.scans);

  const oldIndexCount = oldIndexes.length;
  const newIndexCount = newIndexes.length;

  const oldCoveredScanCount = allScans.filter((scan) =>
    scan.executionCosts.some((cost) => {
      return cost.indexId != null && result.indexes[cost.indexId].existing;
    }),
  ).length;
  const newCoveredScanCount = allScans.filter((scan) =>
    scan.executionCosts.some((cost) => {
      return cost.indexId != null && result.indexes[cost.indexId].selected;
    }),
  ).length;
  const totalScanCount = allScans.length;

  const oldIwo = oldIndexes.reduce((tot, idx) => tot + idx.writeOverhead, 0);
  const newIwo = newIndexes.reduce((tot, idx) => tot + idx.writeOverhead, 0);

  const oldUo = statistics.existing_update_overhead;
  const newUo = statistics.update_overhead;

  const oldScanCosts = allScans.map((scan) => scan.bestExistingCost.cost);
  const newScanCosts = allScans.map((scan) => scan.bestSelectedCost.cost);

  const oldTotScanCost = oldScanCosts.reduce(
    (totCost, scanCost) => totCost + scanCost,
    0,
  );
  const newTotScanCost = newScanCosts.reduce(
    (totCost, scanCost) => totCost + scanCost,
    0,
  );

  const oldMaxScanCost = Math.max.apply(null, oldScanCosts);
  const newMaxScanCost = Math.max.apply(null, newScanCosts);

  const oldWeightedCosts = allScans.map(
    (scan) => scan.bestExistingCost.cost * scan.scansPerMin,
  );
  const newWeightedCosts = allScans.map(
    (scan) => scan.bestSelectedCost.cost * scan.scansPerMin,
  );

  const oldTotalWeightedCost = oldWeightedCosts.reduce(
    (totWeightedCost, scanWeightedCost) => totWeightedCost + scanWeightedCost,
  );
  const newTotalWeightedCost = newWeightedCosts.reduce(
    (totWeightedCost, scanWeightedCost) => totWeightedCost + scanWeightedCost,
  );

  const oldMaxWeightedCost = Math.max.apply(null, oldWeightedCosts);
  const newMaxWeightedCost = Math.max.apply(null, newWeightedCosts);

  const weightedCostImprovement = getWeightedCostImprovement(result);
  const impact = calculateImpact(weightedCostImprovement);

  return (
    <Panel
      title={hasInsights ? "Estimated Outcome" : "Summary"}
      secondaryTitle={
        <a
          target="_blank"
          className="text-sm"
          href="https://pganalyze.com/docs/index-advisor/reason-about-insights"
        >
          Learn more
        </a>
      }
    >
      <div className="overflow-hidden">
        {hasInsights && (
          <table className="leading-loose min-w-full mb-6">
            <tbody>
              <tr className="border-b">
                <th className="pl-2.5 font-medium text-[#606060]">Impact</th>
                <td className="pr-2.5 flex justify-end mt-1">
                  <ImpactRenderer impact={impact} />
                </td>
              </tr>
              <tr className="border-b">
                <th className="pl-2.5 font-medium text-[#606060]">
                  Weighted Cost Improvement{" "}
                  <Tip
                    content={
                      <>
                        This is a weighted average of the estimated cost
                        improvement of all scans improved by these indexes,
                        weighting by average scans / minute.
                      </>
                    }
                  />
                </th>
                <td className="pr-2.5 text-right">
                  {formatApproximateNumber(weightedCostImprovement)}×
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <table className="leading-loose min-w-full">
          <tbody>
            <tr className="border-b">
              <td></td>
              <th className="font-medium text-right text-[#606060]">Current</th>
              {hasInsights && (
                <th className="font-medium pr-2.5 text-right text-[#606060]">
                  Proposed
                </th>
              )}
              <td></td>
            </tr>
            <tr className="border-b">
              <th className="pl-2.5 font-medium text-[#606060]">
                Scan Coverage
              </th>
              <td className="text-right">
                {oldCoveredScanCount}/{totalScanCount}
              </td>
              {hasInsights && (
                <td className="px-2.5 text-right">
                  {newCoveredScanCount}/{totalScanCount}
                </td>
              )}
              <td></td>
            </tr>
            <tr className="border-b">
              <th className="pl-2.5 font-medium text-[#606060]">
                Indexes Used
              </th>
              <td className="text-right">{formatNumber(oldIndexCount)}</td>
              {hasInsights && (
                <td className="px-2.5 text-right">
                  {formatNumber(newIndexCount)}
                </td>
              )}
              <td className="pr-2.5">
                {hasInsights && (
                  <TrendArrow delta={newIndexCount - oldIndexCount} />
                )}
              </td>
            </tr>
            <tr className="border-b">
              <th className="pl-2.5 font-medium text-[#606060]">
                Index Write Overhead{" "}
                <Tip
                  content={
                    <>
                      Adding these indexes is expected to increase the cost of
                      writes to this table by this many bytes for every byte
                      written to the table.
                    </>
                  }
                />
              </th>
              <td className="text-right">{formatNumber(oldIwo, 2)}</td>
              {hasInsights && (
                <td className="px-2.5 text-right">{formatNumber(newIwo, 2)}</td>
              )}
              <td className="pr-2.5">
                {hasInsights && <TrendArrow delta={newIwo - oldIwo} />}
              </td>
            </tr>
            <tr className="border-b">
              <th className="pl-2.5 font-medium text-[#606060]">
                Update Overhead{" "}
                <Tip
                  content={
                    <>
                      Indexing frequently-changing columns can prevent a
                      Postgres optimization called HOT updates. This number
                      attempts to quantify that cost.
                    </>
                  }
                />
              </th>
              <td className="text-right">{formatNumber(oldUo, 2)}</td>
              {hasInsights && (
                <td className="px-2.5 text-right">{formatNumber(newUo, 2)}</td>
              )}
              <td className="pr-2.5">
                {hasInsights && <TrendArrow delta={newUo - oldUo} />}
              </td>
            </tr>
            <tr className="border-b">
              <th
                className="pl-2.5 font-medium text-[#606060]"
                colSpan={hasInsights ? 4 : 3}
              >
                Scan Cost
              </th>
            </tr>
            <tr className="border-b">
              <th className="pl-5 font-medium text-[#606060]">Total</th>
              <td className="text-right">{formatNumber(oldTotScanCost)}</td>
              {hasInsights && (
                <td className="px-2.5 text-right">
                  {formatNumber(newTotScanCost)}
                </td>
              )}
              <td className="pr-2.5">
                {hasInsights && (
                  <TrendArrow delta={newTotScanCost - oldTotScanCost} />
                )}
              </td>
            </tr>
            <tr className="border-b">
              <th className="pl-5 font-medium text-[#606060]">Max</th>
              <td className="text-right">{formatNumber(oldMaxScanCost)}</td>
              {hasInsights && (
                <td className="px-2.5 text-right">
                  {formatNumber(newMaxScanCost)}
                </td>
              )}
              <td className="pr-2.5">
                {hasInsights && (
                  <TrendArrow delta={newMaxScanCost - oldMaxScanCost} />
                )}
              </td>
            </tr>
            <tr className="border-b">
              <th
                className="pl-2.5 font-medium text-[#606060]"
                colSpan={hasInsights ? 4 : 3}
              >
                Weighted Cost
              </th>
            </tr>
            <tr className="border-b">
              <th className="pl-5 font-medium text-[#606060]">Total</th>
              <td className="text-right">
                {formatNumber(oldTotalWeightedCost)}
              </td>
              {hasInsights && (
                <td className="px-2.5 text-right">
                  {formatNumber(newTotalWeightedCost)}
                </td>
              )}
              <td className="pr-2.5">
                {hasInsights && (
                  <TrendArrow
                    delta={newTotalWeightedCost - oldTotalWeightedCost}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th className="pl-5 font-medium text-[#606060]">Max</th>
              <td className="text-right">{formatNumber(oldMaxWeightedCost)}</td>
              {hasInsights && (
                <td className="px-2.5 text-right">
                  {formatNumber(newMaxWeightedCost)}
                </td>
              )}
              <td className="pr-2.5">
                {hasInsights && (
                  <TrendArrow delta={newMaxWeightedCost - oldMaxWeightedCost} />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PanelSection>
        <span>
          Results from {formatTimestampLong(result.runAt)}, total runtime:{" "}
          {formatNumber(statistics.total_time, 2)} s, model build time:{" "}
          {formatNumber(statistics.model_build_time, 2)} s, CP-SAT solver time:{" "}
          {formatNumber(statistics.model_solve_time, 2)} s
        </span>
      </PanelSection>
    </Panel>
  );
};

const TrendArrow: React.FunctionComponent<{
  delta: number;
  className?: string;
}> = ({ delta, className }) => {
  // We assume that all downward trends are good for now
  if (delta < 0) {
    return (
      <TrendDownArrow className={classNames("text-[#43962A]", className)} />
    );
  } else if (delta > 0) {
    return <TrendUpArrow className={classNames("text-[#C22426]", className)} />;
  } else {
    return null;
  }
};

export default SummaryPanel;
