import {
  useCurrentComparePlan,
  useCurrentPlan,
} from "components/WithExplainPlan";
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Plan, Node } from "types/explain";

type PlanCompareKind = "main" | "comparison";

type PlanContext = {
  selectedNodeId: number | string;
  setSelectedNodeId: (newId: number | string, kind?: PlanCompareKind) => void;
  selectedNode: Node | undefined;
};

const SelectedNodeContext = React.createContext<PlanContext | undefined>(
  undefined,
);

const WithNodeSelection = ({ children }: { children: React.ReactNode }) => {
  const plan = useCurrentPlan();
  const comparePlan = useCurrentComparePlan();
  const navigate = useNavigate();
  const { hash, search } = useLocation();
  const hashNodeMatch =
    hash.length > 0 && hash.match(/^#(compare-)?node-(.+)$/);
  const currHashNode = hashNodeMatch && hashNodeMatch[2];
  const isComparisonNode = hashNodeMatch && hashNodeMatch[1] === "compare-";

  const [selectedNodeId, setSelectedNodeId] = useState(currHashNode);
  useEffect(() => {
    if (currHashNode && currHashNode != selectedNodeId) {
      setSelectedNodeId(currHashNode);
    }
  }, [currHashNode, selectedNodeId]);
  const hashAwareSetter = (
    nodeId: string,
    kind: PlanCompareKind = "main",
  ): void => {
    const hash =
      kind === "main" ? `#node-${nodeId}` : `#compare-node-${nodeId}`;
    navigate({ hash, search });
  };
  const nodePlan = isComparisonNode ? comparePlan : plan;
  const currentValue = {
    selectedNodeId,
    setSelectedNodeId: hashAwareSetter,
    selectedNode: findNode(nodePlan?.plan.plan, selectedNodeId),
  };
  return (
    <SelectedNodeContext.Provider value={currentValue}>
      {children}
    </SelectedNodeContext.Provider>
  );
};

export function useSelectedNode() {
  const { selectedNode, setSelectedNodeId } = useContext(SelectedNodeContext);

  return [selectedNode, setSelectedNodeId] as const;
}

const findNode: (plan: Plan | undefined, nodeId: string) => Node | undefined = (
  plan,
  nodeId,
) => {
  if (!plan) {
    return undefined;
  }

  let result: Node;

  function doFindNode(node: Node) {
    if (matchesId(node, nodeId)) {
      result = node;
      return;
    }
    if (node.Plans) {
      for (let i = 0; !result && i < node.Plans.length; i++) {
        doFindNode(node.Plans[i]);
      }
    }
  }

  for (let i = 0; !result && i < plan.length; i++) {
    doFindNode(plan[i].Plan);
  }
  return result;
};

function matchesId(node: Node, nodeId: string): boolean {
  return (
    String(node.extra.id) === nodeId || node["Subplan Name"] == `CTE ${nodeId}`
  );
}

export default WithNodeSelection;
