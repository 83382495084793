import React from "react";
import { useQuery } from "@apollo/client";

import { Link } from "react-router-dom";

import Loading from "components/Loading";
import ShowFlash from "components/ShowFlash";
import QueryStatsTable, {
  QueryStatsTableSortBy,
} from "components/QueryStatsTable";
import { useRoutes } from "utils/routes";

import { SortDirection } from "types/graphql-global-types";
import QueryDataMissingHint from "../QueryDataMissingHint";

import {
  QueryTable as QueryTableType,
  QueryTableVariables,
} from "./types/QueryTable";
import QUERY from "./Query.graphql";

interface SortOptsType {
  sortBy: QueryStatsTableSortBy;
  sortDirection: SortDirection;
}

interface Props {
  databaseId: string;
  startTs: number;
  endTs: number;
  statementTypes: Array<string>;
  searchTerm: null | string;
  compare: boolean;
}

const FETCH_BATCH_SIZE = 100;

const QueryTable: React.FunctionComponent<Props> = ({
  databaseId,
  startTs,
  endTs,
  statementTypes,
  searchTerm,
  compare,
}) => {
  const [sortOpts, setSortOpts] = React.useState<SortOptsType>({
    sortBy: "pctOfTotal",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    QueryTableType,
    QueryTableVariables
  >(QUERY, {
    variables: {
      databaseId,
      startTs,
      endTs,
      statementTypes,
      offset: 0,
      limit: FETCH_BATCH_SIZE,
      filter: searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      compare,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const pgssTrackNone =
    data.getPostgresSettingDetails?.resetValuePrettyNoDelimiter == "none";

  if (data.getQueryStats.length === 0) {
    return (
      <>
        {pgssTrackNone && (
          <PgssTrackNoneFlash serverId={data.getServerDetails.humanId} />
        )}
        <QueryDataMissingHint databaseId={databaseId} />
      </>
    );
  }

  const fetchMoreData = (loadedCount: number) => {
    return new Promise<boolean>((resolve) => {
      const offset = loadedCount;
      fetchMore({
        variables: { offset, limit: FETCH_BATCH_SIZE },
        updateQuery: (prev, { fetchMoreResult }): QueryTableType => {
          const fetched = fetchMoreResult.getQueryStats;
          resolve(fetched.length > 0);
          if (!prev || !prev.getQueryStats) {
            return fetchMoreResult;
          }
          return {
            ...prev,
            getQueryStats: [...prev.getQueryStats, ...fetched],
          };
        },
      });
    });
  };

  return (
    <>
      {pgssTrackNone && (
        <PgssTrackNoneFlash serverId={data.getServerDetails.humanId} />
      )}
      <QueryStatsTable
        databaseId={databaseId}
        serverId={data.getServerDetails.humanId}
        queries={data.getQueryStats}
        fetchMoreData={fetchMoreData}
        sortOpts={sortOpts}
        setSortOpts={setSortOpts}
        searchTerm={searchTerm}
        compare={compare}
      />
    </>
  );
};

const PgssTrackNoneFlash: React.FunctionComponent<{ serverId: string }> = ({
  serverId,
}) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <ShowFlash
      level="alert"
      msg={
        <>
          The Postgres setting{" "}
          <Link to={serverConfigSetting(serverId, "pg_stat_statements.track")}>
            pg_stat_statements.track
          </Link>{" "}
          is set to "none" for this server. Query performance statistics are not
          tracked in <code>pg_stat_statements</code>, and so not available in
          pganalyze.
        </>
      }
    />
  );
};

export default QueryTable;
