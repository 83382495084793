import React from "react";

import { useLocation } from "react-router-dom";

import Button from "components/Button";
import { PagerDutyLogo } from "components/Icons";
import { useAppConfig } from "components/WithAppConfig";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";
import { csrfTag } from "utils/csrf";

const ConnectToPagerDuty: React.FunctionComponent<{
  organizationSlug: string;
}> = ({ organizationSlug }) => {
  const config = useAppConfig();
  const { pathname } = useLocation();

  const { organizationIntegrationsPagerduty } = useRoutes();
  const pdAppId = config?.pagerDutyAppId;

  if (!pdAppId) {
    return (
      <button disabled className="btn btn-secondary disabled">
        <PagerDutyLogo /> Not Available
      </button>
    );
  }

  return (
    <form
      action={organizationIntegrationsPagerduty(organizationSlug)}
      method="post"
    >
      {csrfTag()}
      <input type="hidden" name="return_to" value={pathname} />
      <Button type="submit" className={styles.connectBtn}>
        <PagerDutyLogo /> Connect to PagerDuty
      </Button>
    </form>
  );
};

export default ConnectToPagerDuty;
