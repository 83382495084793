import React, { useMemo, useEffect } from "react";

import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import Loading from "components/Loading";
import { useRelatedRoute, useRoutes } from "utils/routes";
import { useUserPreferences } from "utils/hooks";
import { mru } from "utils/array";

import {
  DatabaseSelect as DatabaseSelectType,
  DatabaseSelectVariables,
  DatabaseSelect_getDatabases as DatabaseType,
} from "./types/DatabaseSelect";
import QUERY from "./Query.graphql";
import styles from "../style.module.scss";
import { useCurrentServer } from "components/WithCurrentOrganization";
import { CaretIcon } from "components/Icons";
import MenuList from "../MenuList";

const DatabaseSelect: React.FunctionComponent = () => {
  const currentServer = useCurrentServer();
  const { databaseId: currentDatabaseId } = useParams();
  const currentServerId = currentServer.humanId;
  const [dbSelect, setDbSelect] = useUserPreferences("databaseSelect");

  const recentDbIds = useMemo(() => {
    return (dbSelect.recentDbIds || {})[currentServerId] || [];
  }, [dbSelect.recentDbIds, currentServerId]);

  const { server: serverRoot, database: databaseRoot } = useRoutes();
  const { relatedDatabaseRoute, relatedServerRoute } = useRelatedRoute();

  useEffect(() => {
    if (currentDatabaseId && recentDbIds[0] != currentDatabaseId) {
      setDbSelect({
        ...dbSelect,
        recentDbIds: {
          ...dbSelect.recentDbIds,
          [currentServerId]: mru(currentDatabaseId, recentDbIds, 5),
        },
      });
    }
  }, [setDbSelect, dbSelect, currentServerId, recentDbIds, currentDatabaseId]);

  const { data, loading, error } = useQuery<
    DatabaseSelectType,
    DatabaseSelectVariables
  >(QUERY, {
    variables: {
      serverId: currentServer.humanId,
    },
  });
  if (loading || error) {
    return <HeaderLoading error={!!error} />;
  }

  const databases = data.getDatabases;
  const visibleDbs = databases.filter((d) => !d.hidden);

  const sortedDbs = visibleDbs.slice().sort((a, b) => {
    if (recentDbIds.includes(a.id) && recentDbIds.includes(b.id)) {
      return recentDbIds.indexOf(a.id) - recentDbIds.indexOf(b.id);
    }
    if (recentDbIds.includes(a.id)) {
      return -1;
    }
    if (recentDbIds.includes(b.id)) {
      return 1;
    }
    return a.datname.localeCompare(b.datname);
  });

  const currentDatabase = databases.find((d) => d.id === currentDatabaseId);
  const canOpen =
    (visibleDbs.length > 1 || (visibleDbs.length === 1 && !currentDatabase)) &&
    (relatedDatabaseRoute(visibleDbs[0].id) != databaseRoot(visibleDbs[0].id) ||
      relatedServerRoute(currentServerId) == serverRoot(currentServerId));

  const trigger = ({
    open,
    toggleOpen,
  }: {
    open: boolean;
    toggleOpen: () => void;
  }) => (
    <button onClick={toggleOpen}>
      <small>Database</small>
      <div className={styles.selectMenuCurrent}>
        {currentDatabase ? (
          <DatabaseItemContent database={currentDatabase} />
        ) : (
          <>
            <span>(no database)</span>
          </>
        )}
        {currentDatabase && (
          <Link
            to={relatedServerRoute(currentServerId)}
            className={styles.closeLink}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Link>
        )}
        <CaretIcon direction={open ? "up" : "down"} headerNav />
      </div>
    </button>
  );

  return (
    <div className={styles.selectMenu} data-select-menu="database">
      {canOpen ? (
        <MenuList
          trigger={trigger}
          prompt="Search databases..."
          noMatch="no databases match"
          items={sortedDbs}
          filterKeys={["datname"]}
          renderer={DatabaseItem}
        />
      ) : (
        <div>
          <div className={styles.noOptions}>
            <small>Database</small>
            <div className={styles.selectMenuCurrent}>
              {currentDatabase ? (
                <DatabaseItemContent database={currentDatabase} />
              ) : (
                <FontAwesomeIcon
                  icon={faBan}
                  className="text-[#ccc] mt-[1px]"
                />
              )}
              {currentDatabase && (
                <Link
                  to={relatedServerRoute(currentServerId)}
                  className={styles.closeLink}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const DatabaseItem: React.FunctionComponent<{
  item: DatabaseType;
}> = ({ item: database }) => {
  const { relatedDatabaseRoute } = useRelatedRoute();
  return (
    <Link
      className={styles.selectMenuListItem}
      to={relatedDatabaseRoute(database.id)}
    >
      <DatabaseItemContent database={database} />
    </Link>
  );
};

const DatabaseItemContent: React.FunctionComponent<{
  database: DatabaseType;
}> = ({ database }) => {
  return <span>{database.datname || "Database #" + database.id}</span>;
};

const HeaderLoading: React.FunctionComponent<{ error: boolean }> = ({
  error,
}) => {
  return <Loading className={styles.loading} small error={error} />;
};

export default DatabaseSelect;
