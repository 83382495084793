import React from "react";
import { Link } from "react-router-dom";

import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";
import { BookIcon } from "components/Icons";

type Props = {
  serverId: string;
  usesLocalCollector: boolean;
  enableLogs: boolean;
  systemType: string;
};

const IntegrationHint: React.FunctionComponent<Props> = ({
  serverId,
  usesLocalCollector,
  enableLogs,
  systemType,
}) => {
  const { serverEdit, serverSetupLogInsights } = useRoutes();

  if (usesLocalCollector) {
    if (
      !["amazon_rds", "azure_database", "google_cloudsql"].includes(systemType)
    ) {
      return (
        <PanelSection>
          <p>
            <strong>Log Insights not supported for this configuration.</strong>
          </p>
          <p>
            You are running Enterprise Edition with the local collector built-in
            with the pganalyze Docker container.
          </p>
          <p>
            This configuration only supports collecting log data from Amazon
            RDS, Microsoft Azure, or Google Cloud SQL. For other platforms
            please see the{" "}
            <a
              href="https://pganalyze.com/docs/enterprise/log-insights"
              target="_blank"
            >
              <BookIcon /> pganalyze documentation
            </a>
            .
          </p>
        </PanelSection>
      );
    }

    if (enableLogs) {
      const provider = getSystemName(systemType);
      return (
        <PanelSection>
          <p>
            <strong>No Log Insights data retrieved from {provider}.</strong>
          </p>
          <p>
            If you see this for a long time you might have a permission or
            configuration error. Check the pganalyze Docker container logs for
            any errors.
          </p>
        </PanelSection>
      );
    } else {
      return (
        <PanelSection>
          <p>
            <strong>You have turned off Log Insights for this server.</strong>
          </p>
          <p>
            <Link to={serverEdit(serverId)}>
              Go to server settings to enable Log Insights
            </Link>
          </p>
        </PanelSection>
      );
    }
  }

  const setupPath = serverSetupLogInsights(serverId);

  return (
    <PanelSection>
      <p>
        <strong>You have not integrated Log Insights yet.</strong>
      </p>
      <p>
        <Link to={setupPath}>
          {systemType == "amazon_rds" && (
            <>
              <BookIcon /> Follow the setup instructions for Amazon RDS &amp;
              Aurora
            </>
          )}
          {systemType == "azure_database" && (
            <>
              <BookIcon /> Follow the setup instructions for Azure Database for
              PostgreSQL
            </>
          )}
          {systemType == "google_cloudsql" && (
            <>
              <BookIcon /> Follow the setup instructions for Google Cloud SQL
            </>
          )}
          {systemType == "heroku" && (
            <>
              <BookIcon /> Follow the setup instructions for Heroku Postgres
            </>
          )}
          {systemType == "self_managed" && (
            <>
              <BookIcon /> Follow the setup instructions for Self-Managed
              Servers
            </>
          )}
          {systemType == "crunchy_bridge" && (
            <>
              <BookIcon /> Follow the setup instructions for Crunchy Bridge for
              PostgreSQL
            </>
          )}
          {systemType == "aiven" && (
            <>
              <BookIcon /> Follow the setup instructions for Aiven for
              PostgreSQL
            </>
          )}
          {!systemType && (
            <a
              href="https://pganalyze.com/docs/log-insights/setup/"
              target="_blank"
            >
              <BookIcon /> Follow the setup instructions
            </a>
          )}
        </Link>
      </p>
    </PanelSection>
  );
};

function getSystemName(systemType: string) {
  switch (systemType) {
    case "amazon_rds":
      return "Amazon RDS";
    case "azure_database":
      return "Microsoft Azure";
    case "google_cloudsql":
      return "Google Cloud SQL";
    default:
      return "your provider";
  }
}

export default IntegrationHint;
