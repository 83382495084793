---
title: 'Step 4: Install the Collector'
install_track_title: Installation Guide (Aiven)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import InstallChoice from '../../components/InstallChoice'
import styles from '../../style.module.scss'

import imgLogoUbuntu from '../../images/logo_ubuntu.png'
import imgLogoRedhat from '../../images/logo_redhat.svg'
import imgLogoDocker from '../../images/logo_docker.svg'
import imgLogoAL2 from '../../images/aws-ec2.svg'

export const SelectCollectorPlatform = () => {
  return (
    <InstallChoice
      docsRoot={false}
      items={[
        {
          link: "04_install_the_collector_package",
          img: imgLogoUbuntu,
          providerName: "Ubuntu & Debian",
        },
        {
          link: "04_install_the_collector_package",
          img: imgLogoRedhat,
          providerName: "RHEL, CentOS & Fedora",
        },
        {
          link: "04_install_the_collector_package",
          img: imgLogoAL2,
          providerName: "Amazon Linux 2",
        },
        {
          link: "04_install_the_collector_docker",
          img: imgLogoDocker,
          providerName: "Docker",
        },
      ]}
    />
  )
}

We recommend running the collector using a VM instance or container platform
with the same Service Cloud Provider and in the same region that you are using
for your Aiven database. The instructions assume you've already provisioned an
instance to run the collector. We recommend at least 1GB of RAM (you may need
more to handle large schemas or complex query workloads).

To continue, select the environment where you plan to run the collector.

<SelectCollectorPlatform />
