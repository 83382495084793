import React from "react";

import Panel from "components/Panel";
import ServerPostgresSettings, {
  PostgresSettingType,
} from "components/ServerPostgresSettings";

const PostgresSettingsPanel: React.FunctionComponent<{
  serverId: string;
  title: string;
  settings: PostgresSettingType[];
}> = ({ serverId, title, settings }) => {
  return (
    <Panel title={title}>
      <ServerPostgresSettings serverId={serverId} settings={settings} />
    </Panel>
  );
};

export default PostgresSettingsPanel;
