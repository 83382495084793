import React from "react";
import { useQuery } from "@apollo/client";

import { formatNumber } from "utils/format";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import Loading from "components/Loading";

import QUERY from "./Query.graphql";
import {
  UsageStats as UsageStatsType,
  UsageStatsVariables,
} from "./types/UsageStats";

import styles from "./style.module.scss";
import pluralize from "pluralize";
import Tip from "components/Tip";

type Props = {
  organizationSlug: string;
};

const UsageStats: React.FunctionComponent<Props> = ({ organizationSlug }) => {
  const { loading, error, data } = useQuery<
    UsageStatsType,
    UsageStatsVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const {
    userCount,
    userLimit,
    serverCountForBilling,
    primaryWithRecentDataCount,
    replicaWithRecentDataCount,
    replicaBillingMultiplier,
    serverLimit,
  } = data.getOrganizationDetails;

  return (
    <Panel title="Usage Statistics">
      <PanelTable horizontal={true} borders={true}>
        <tbody>
          <tr>
            <th>Billable servers</th>
            <td>
              <span
                className={
                  (serverCountForBilling == serverLimit && styles.orange) ||
                  (serverLimit &&
                    serverCountForBilling > serverLimit &&
                    styles.red) ||
                  ""
                }
              >
                {formatNumber(serverCountForBilling)}
              </span>
              {serverLimit && <span> / {formatNumber(serverLimit)}</span>}
              {replicaBillingMultiplier != 1.0 ? (
                <Tip
                  content={`${pluralize(
                    "replica",
                    replicaWithRecentDataCount,
                    true,
                  )} were counted as ${pluralize(
                    "billable server",
                    Math.ceil(
                      replicaWithRecentDataCount * replicaBillingMultiplier,
                    ),
                    true,
                  )}. Each replica is counted as ${replicaBillingMultiplier} servers, with the total rounded up.`}
                />
              ) : null}
            </td>
            <th>Users</th>
            <td>
              <span
                className={
                  (userCount == userLimit && styles.orange) ||
                  (userLimit && userCount > userLimit && styles.red) ||
                  ""
                }
              >
                {formatNumber(userCount)}
              </span>
              <span>
                {" "}
                / {(userLimit && formatNumber(userLimit)) || "Unlimited"}
              </span>
            </td>
          </tr>
          <tr>
            <th>Total active servers</th>
            <td>
              {pluralize("primary", primaryWithRecentDataCount, true)},{" "}
              {pluralize("replica", replicaWithRecentDataCount, true)}
            </td>
            <th></th>
            <td></td>
          </tr>
        </tbody>
      </PanelTable>
    </Panel>
  );
};

export default UsageStats;
