import React from "react";

import { AnnotatedPlan, Node, RawNode, RawPlan } from "types/explain";

import CopyToClipboard from "components/CopyToClipboard";

type Props = {
  annotatedPlan: AnnotatedPlan;
  textPlan: string;
  format: "text" | "json";
};

const ExplainViewSource: React.FunctionComponent<Props> = ({
  annotatedPlan,
  textPlan,
  format,
}) => {
  let formattedSource: string;
  if (format === "json") {
    const rawPlan = stripAnnotations(annotatedPlan);
    formattedSource = JSON.stringify(rawPlan, null, 2);
  } else {
    formattedSource = textPlan;
  }

  return (
    <div>
      <div className="flex justify-end">
        <CopyToClipboard
          className="btn btn-small btn-link -mt-[7px]"
          content={formattedSource}
          label="Copy to clipboard"
        />
      </div>
      <pre className="whitespace-pre-wrap">{formattedSource}</pre>
    </div>
  );
};

const stripAnnotations: (plan: AnnotatedPlan) => RawPlan = (plan) => {
  const enrichedPlan = plan.plan;
  const stripNode = (node: Node): RawNode => {
    const { extra: _ignored, Plans: children, ...rest } = node;
    const newNode: RawNode = rest;
    if (children) {
      newNode.Plans = children.map((child: Node) => stripNode(child));
    }
    return newNode;
  };
  return enrichedPlan.map((root) => ({
    ...root,
    Plan: stripNode(root.Plan),
  }));
};

export default ExplainViewSource;
