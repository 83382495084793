import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { SortDirection } from "types/graphql-global-types";

import { formatBytes, formatNumber } from "utils/format";
import Loading from "components/Loading";

import {
  SchemaIndexList,
  SchemaIndexListVariables,
  SchemaIndexList_getSchemaIndices,
  SchemaIndexList_getSchemaIndices_issues,
} from "./types/SchemaIndexList";
import { useRoutes } from "utils/routes";
import QUERY from "./Query.graphql";
import { useFeature } from "components/OrganizationFeatures";
import LazyLoader from "components/LazyLoader";
import Grid from "components/Grid";
import { toSortDir, toSortOrder } from "utils/graphql";
import { GridColumn, SortOrder } from "components/Grid/util";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  searchTerm: string | null;
  databaseId: string;
}

const FETCH_BATCH_SIZE = 50;

const Table: React.FunctionComponent<Props> = ({ searchTerm, databaseId }) => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  const [sortOpts, setSortOpts] = useState<SortOptsType>({
    sortBy: "sizeBytes",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaIndexList,
    SchemaIndexListVariables
  >(QUERY, {
    variables: {
      offset: 0,
      limit: FETCH_BATCH_SIZE,
      filter: searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const schemaIndices = data.getSchemaIndices;

  const fetchMoreData = (loadedCount: number) => {
    return new Promise<boolean>((resolve) => {
      const offset = loadedCount;
      fetchMore({
        variables: { offset, limit: FETCH_BATCH_SIZE },
        updateQuery: (prev, { fetchMoreResult }): SchemaIndexList => {
          const fetched = fetchMoreResult.getSchemaIndices;
          resolve(fetched.length > 0);
          if (!prev || !prev.getSchemaIndices) {
            return fetchMoreResult;
          }
          return {
            ...prev,
            getSchemaIndices: [...prev.getSchemaIndices, ...fetched],
          };
        },
      });
    });
  };

  const sortedOrder = toSortOrder(sortOpts.sortDirection);
  const sortedBy = sortOpts.sortBy as keyof SchemaIndexList_getSchemaIndices;

  function handleSort(
    sortBy: keyof SchemaIndexList_getSchemaIndices,
    sortOrder: SortOrder,
  ) {
    setSortOpts({
      sortBy,
      sortDirection: toSortDir(sortOrder),
    });
  }

  const columns: GridColumn<
    SchemaIndexList_getSchemaIndices,
    keyof SchemaIndexList_getSchemaIndices
  >[] = [
    {
      field: "name",
      header: "Index Name",
      renderer: function NameCell({ rowData }) {
        return (
          <NameAndIssuesCell databaseId={databaseId} schemaIndex={rowData} />
        );
      },
    },
    {
      field: "tableName",
      header: "Table Name",
    },
    {
      field: "sizeBytes",
      header: "Index Size",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function IndexSizeCell({ fieldData }) {
        return formatBytes(fieldData);
      },
    },
    {
      field: "scansPerMin",
      header: "Scans / Min",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function ScansPerMinCell({ fieldData }) {
        return formatNumber(fieldData, 2);
      },
    },
  ];

  if (hasIndexAdvisor) {
    columns.push({
      field: "writeOverhead",
      header: "Write Overhead",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function WriteOverheadCell({ fieldData }) {
        return formatNumber(fieldData, 2);
      },
    });
  }

  const gridClassname = hasIndexAdvisor
    ? "grid-cols-[1fr_1fr_12%_12%_12%]"
    : "grid-cols-[1fr_1fr_12%_12%]";

  return (
    <LazyLoader loadedCount={schemaIndices.length} loadMore={fetchMoreData}>
      <Grid
        className={gridClassname}
        striped
        data={schemaIndices}
        columns={columns}
        sortedBy={sortedBy}
        sortedOrder={sortedOrder}
        handleSort={handleSort}
      />
    </LazyLoader>
  );
};

const NameAndIssuesCell: React.FunctionComponent<{
  databaseId: string;
  schemaIndex: SchemaIndexList_getSchemaIndices;
}> = ({ databaseId, schemaIndex }) => {
  const { databaseIndex } = useRoutes();
  return (
    <span>
      <Link to={databaseIndex(databaseId, schemaIndex.id)}>
        {schemaIndex.name}
      </Link>
      {schemaIndex.issues.map(
        (i: SchemaIndexList_getSchemaIndices_issues): React.ReactNode => (
          <Link
            to={databaseIndex(databaseId, schemaIndex.id)}
            className={`state-${i.severity}`}
            title={`${i.displayName || ""} ${i.description}`}
            key={i.id}
          >
            <i className="icon-exclamation-sign" />
          </Link>
        ),
      )}
    </span>
  );
};

export default Table;
