import React from "react";
import { translateY } from "utils/svg";

type Props = {
  size?: number;
  steps: number;
  completedSteps: number;
  pendingStroke?: string;
  completedStroke?: string;
  className?: string;
};

const RadialStepCounter: React.FunctionComponent<Props> = ({
  size = 32,
  steps,
  completedSteps,
  pendingStroke = "#302a57",
  completedStroke = "#93bf3b",
  className,
}) => {
  const pathWidth = 14;
  const pathRadius = 34;
  const segmentAngle = (Math.PI * 2) / steps;

  const stepItems = Array(steps)
    .fill(undefined)
    .map((_, i) => {
      const startAngle = segmentAngle * i;
      const endAngle = segmentAngle * (i + 1);

      const startX = Math.cos(startAngle) * pathRadius;
      const startY = Math.sin(startAngle) * pathRadius;
      const endX = Math.cos(endAngle) * pathRadius;
      const endY = Math.sin(endAngle) * pathRadius;
      const path = `M${startX},${startY} A${pathRadius},${pathRadius} 0 0,1 ${endX},${endY}`;

      const spokeEndX = Math.cos(startAngle) * (pathRadius + 10);
      const spokeEndY = Math.sin(startAngle) * (pathRadius + 10);
      return {
        path,
        spoke: { x1: 0, y1: 0, x2: spokeEndX, y2: spokeEndY },
      };
    });
  const done = completedSteps === steps;

  return (
    <svg
      className={className}
      viewBox="-60 -60 120 120"
      width={size}
      height={size}
    >
      <mask id="segmentMask">
        <rect x="-60" y="-60" width="120" height="120" fill="white" />
        {stepItems.map(({ spoke }, i) => (
          <line key={i} stroke="black" strokeWidth={6} {...spoke} />
        ))}
      </mask>
      <g mask="url(#segmentMask)" transform="rotate(180)">
        {stepItems.map(({ path }, i) => (
          <path
            key={i}
            d={path}
            stroke={i < completedSteps ? completedStroke : pendingStroke}
            fill="none"
            strokeWidth={pathWidth}
          />
        ))}
      </g>
      <g transform={translateY(4)}>
        <text
          fontSize={30}
          fontWeight={500}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {done ? "✔" : steps - completedSteps}
        </text>
      </g>
    </svg>
  );
};

export default RadialStepCounter;
