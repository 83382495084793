import React from "react";
import { useParams } from "react-router-dom";

import SchemaNav from "components/SchemaNav";
import PageContent from "components/PageContent";
import Panel from "components/Panel";

import Table from "./Table";
import TableTreeMap from "./TableTreeMap";

const SchemaTableList: React.FunctionComponent = () => {
  const { databaseId } = useParams();
  return (
    <PageContent
      title="Schema Statistics"
      pageCategory="schema-tables"
      pageName="index"
      featureNav={<SchemaNav databaseId={databaseId} />}
    >
      <Panel title="Largest Tables By Data Size">
        <TableTreeMap databaseId={databaseId} />
      </Panel>
      <Table databaseId={databaseId} />
    </PageContent>
  );
};

export default SchemaTableList;
