import React from "react";
import {
  useExplainDisplayOptions,
  useSetExplainDisplayOptions,
} from "../WithExplainDisplayOptions";

const ExplainDisplayControls = () => {
  const opts = useExplainDisplayOptions();
  const setOpts = useSetExplainDisplayOptions();

  const isJustOneOptChecked =
    Object.values(opts)
      .map((checked) => (checked ? 1 : 0))
      .reduce((tot, v) => tot + v, 0) === 1;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setOpts({
      ...opts,
      [e.currentTarget.name]: e.currentTarget.checked,
    });
  }
  return (
    <div>
      <h4 className="text-[#606060] ">Node Fields</h4>
      <form className="flex flex-col">
        <label>
          <input
            name="showActualTime"
            onChange={handleChange}
            type="checkbox"
            checked={opts.showActualTime}
            disabled={opts.showActualTime && isJustOneOptChecked}
          />{" "}
          Actual Time
        </label>
        <label>
          <input
            name="showIOTime"
            onChange={handleChange}
            type="checkbox"
            checked={opts.showIOTime}
            disabled={opts.showIOTime && isJustOneOptChecked}
          />{" "}
          I/O Time
        </label>
        <label>
          <input
            name="showCost"
            onChange={handleChange}
            type="checkbox"
            checked={opts.showCost}
            disabled={opts.showCost && isJustOneOptChecked}
          />{" "}
          Est. Cost
        </label>
        <label>
          <input
            name="showActualRows"
            onChange={handleChange}
            type="checkbox"
            checked={opts.showActualRows}
            disabled={opts.showActualRows && isJustOneOptChecked}
          />{" "}
          Actual Rows
        </label>
      </form>
    </div>
  );
};

export default ExplainDisplayControls;
