---
title: 'Step 0: Choose Setup Method'
install_track_title: Installation Guide (Self-Managed)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

export const SetupOptions = () => {
  return (
    <div>
      <Link className="btn btn-success" to="01_guided_setup">
        Continue with guided setup
      </Link>
      <span style={{fontSize: '14px', marginLeft: '6px'}}>
        <span> or proceed with </span>
        <Link to="01_create_monitoring_user">
          manual setup
        </Link>
      </span>
    </div>
  )
}

To monitor a new Postgres server with pganalyze, you must make some configuration changes
to your database and install and run the pganalyze collector. There are two
ways of doing this:

 * **Guided setup (recommended)**:<br/>Run a setup program which will prompt you for input and confirmation
and make necessary changes on your behalf
 * **Manual setup**:<br/>Step through our instructions, making changes yourself

The guided setup program is our recommended way to set up on systems that meet the requirements:

 * pganalyze-collector will be installed on the same system as your Postgres server
 * Server runs Ubuntu 20.04 or newer, or Debian 11 or newer
 * Postgres version 10 or newer

Otherwise, you'll need to follow the manual setup instructions.

<SetupOptions />
