import React, { useContext, useState } from "react";

const CustomSettingsContext = React.createContext<
  [string | undefined, React.Dispatch<React.SetStateAction<string>>]
>([undefined, () => ""]);

export function useCustomSettings() {
  return useContext(CustomSettingsContext);
}

const WithCustomSettings = ({ children }: { children: React.ReactNode }) => {
  const settings = useState(undefined);
  return (
    <CustomSettingsContext.Provider value={settings}>
      {children}
    </CustomSettingsContext.Provider>
  );
};

export default WithCustomSettings;
