import React, { useState } from "react";

import classNames from "classnames";

import PanelTitle from "components/PanelTitle";

import styles from "./style.module.scss";
import Button from "components/Button";
import { ExpandToggle } from "components/Icons";

export type Props = {
  id?: string;
  children: React.ReactNode;
  title: React.ReactNode;
  secondaryTitle?: React.ReactNode;
  secondaryTitleClassName?: string;
  className?: string;
} & ExpandableProps;

type ExpandableProps =
  | {
      expandable?: false;
      defaultExpanded?: never;
    }
  | {
      expandable: true;
      defaultExpanded?: boolean;
    };

const Panel: React.FunctionComponent<Props> = ({
  id,
  children,
  title,
  secondaryTitle,
  secondaryTitleClassName,
  className,
  expandable,
  defaultExpanded,
}) => {
  const [expanded, setExpanded] = useState(
    expandable ? defaultExpanded ?? false : true,
  );
  function handleTogglePanel() {
    setExpanded(!expanded);
  }
  function handleExpandPanel() {
    if (!expanded) {
      setExpanded(true);
    }
  }

  const actualTitle = expandable ? (
    <Button bare onClick={handleTogglePanel}>
      <ExpandToggle className="w-3 mr-2" expanded={expanded} />
      {title}
    </Button>
  ) : (
    title
  );

  return (
    <div id={id} className={classNames(styles.panel, className)}>
      <PanelTitle
        title={actualTitle}
        secondaryTitle={secondaryTitle}
        secondaryTitleClassName={secondaryTitleClassName}
        collapsed={!expanded}
        onFocus={handleExpandPanel}
      />
      {expanded && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default Panel;
