import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import sortBy from "lodash/sortBy";

import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import PanelSection from "components/PanelSection";
import SQL from "components/SQL";

import {
  BackendDetailsQuerySamples,
  BackendDetailsQuerySamplesVariables,
  BackendDetailsQuerySamples_getBackendDetails_snapshots,
} from "./types/BackendDetailsQuerySamples";
import QUERY from "./Query.graphql";

type Props = {
  databaseId: string;
  backendLocator: string;
};

const QuerySamples: React.FunctionComponent<Props> = ({
  databaseId,
  backendLocator,
}) => {
  const { data, loading, error } = useQuery<
    BackendDetailsQuerySamples,
    BackendDetailsQuerySamplesVariables
  >(QUERY, {
    variables: {
      databaseId,
      backendLocator,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const queries = sortBy(
    data.getBackendDetails.snapshots.filter(
      (s: BackendDetailsQuerySamples_getBackendDetails_snapshots) =>
        !!s.queryId,
    ),
    (q: BackendDetailsQuerySamples_getBackendDetails_snapshots): number =>
      -q.queryStart,
  );

  return (
    <Panel title="Query Samples">
      <PanelTable borders={true}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Query</th>
            <th>First Seen At</th>
          </tr>
        </thead>
        <tbody>
          {queries.map((q) => (
            <QuerySample key={q.id} databaseId={databaseId} query={q} />
          ))}
        </tbody>
      </PanelTable>
      <PanelSection>
        <strong>Hint:</strong> This is only a sample of the queries that have
        run in this connection - most queries faster than 10 seconds will not
        show here. Start and end times are rounded to the next 10 second
        interval.
      </PanelSection>
    </Panel>
  );
};

const QuerySample: React.FunctionComponent<{
  databaseId: string;
  query: BackendDetailsQuerySamples_getBackendDetails_snapshots;
}> = ({ databaseId, query }) => {
  const { databaseQuery } = useRoutes();
  return (
    <tr key={query.id}>
      <td>
        <Link to={databaseQuery(databaseId, query.queryId)}>
          #{query.queryId}
        </Link>
      </td>
      <td>
        <SQL inline sql={query.queryTextShort} />
      </td>
      <td>{moment.unix(query.queryStart).format("ll LTS z")}</td>
    </tr>
  );
};

export default QuerySamples;
