export const PERMISSION_FULL = "PERMISSION_FULL";
export const PERMISSION_RESTRICTED = "PERMISSION_RESTRICTED";
export const PERMISSION_NONE = "PERMISSION_NONE";

export type PermissionKind =
  | "PERMISSION_FULL"
  | "PERMISSION_RESTRICTED"
  | "PERMISSION_NONE";

export type PermissionType = {
  id: string;
  restricted: boolean;
  view: boolean;
  modify: boolean;
  billing: boolean;
  api: boolean;
  manage: boolean;
};

export const getPermissionKind = (
  permitted: boolean,
  restricted: boolean,
): PermissionKind => {
  if (permitted) {
    if (restricted) {
      return PERMISSION_RESTRICTED;
    } else {
      return PERMISSION_FULL;
    }
  } else {
    return PERMISSION_NONE;
  }
};

export const groupPermissions = (
  permissions: PermissionType[],
  field: Exclude<keyof PermissionType, "id" | "restricted">,
): PermissionKind => {
  return permissions.reduce((result: PermissionKind, permission) => {
    if (permission[field] && result !== PERMISSION_FULL) {
      if (permission.restricted) {
        return PERMISSION_RESTRICTED;
      } else {
        return PERMISSION_FULL;
      }
    } else {
      return result;
    }
  }, PERMISSION_NONE);
};
