import React from "react";

import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatTimeAgo } from "utils/format";
import moment from "moment-timezone";

const LooksGood: React.FunctionComponent<{
  className?: string;
  lastRunAt?: number;
}> = ({ className, lastRunAt }) => {
  return (
    <div className={className}>
      <FontAwesomeIcon icon={faCheckCircle} className="text-[#44962A] mr-2" />
      <strong className="text-[#44962A]">Looks good</strong>
      {lastRunAt && ` (last checked ${formatTimeAgo(moment.unix(lastRunAt))})`}
    </div>
  );
};

export default LooksGood;
