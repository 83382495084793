import React from "react";
import { useParams } from "react-router-dom";

import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import { useAppConfig } from "components/WithAppConfig";
import { useRoutes } from "utils/routes";
import { useCurrentOrganization } from "components/WithCurrentOrganization";

const SettingsNav: React.FunctionComponent = () => {
  const { enterpriseEdition } = useAppConfig();
  const { slug: organizationSlug } = useCurrentOrganization();
  const { serverId } = useParams();

  const {
    serverEdit,
    organizationServersSettings,
    organizationMembers,
    organizationRoles,
    organizationApi,
    organizationSubscription,
    organizationEdit,
    organizationIntegrations,
  } = useRoutes();
  return (
    <PageSecondaryNavigation>
      <PageNavLink
        to={
          serverId
            ? serverEdit(serverId)
            : organizationServersSettings(organizationSlug)
        }
      >
        Server Settings
      </PageNavLink>
      <PageNavLink to={organizationMembers(organizationSlug)}>
        Members
      </PageNavLink>
      <PageNavLink to={organizationRoles(organizationSlug)}>
        Member Roles
      </PageNavLink>
      <PageNavLink to={organizationApi(organizationSlug)}>API Keys</PageNavLink>
      {!enterpriseEdition && (
        <PageNavLink
          to={organizationSubscription(organizationSlug)}
          end={false}
        >
          Billing
        </PageNavLink>
      )}
      <PageNavLink to={organizationIntegrations(organizationSlug)}>
        Integrations
      </PageNavLink>
      <PageNavLink to={organizationEdit(organizationSlug)}>
        Other Settings
      </PageNavLink>
    </PageSecondaryNavigation>
  );
};

export default SettingsNav;
