import React, { useState } from "react";

import {
  formatMs,
  formatMsTrend,
  formatMsTrendDetailRange,
  formatNumber,
  formatNumberTrend,
  formatNumberTrendDetailRange,
} from "utils/format";

import Badge from "components/Badge";
import Loading from "components/Loading";

import {
  QueryDetailsContext as QueryDetailsContextType,
  QueryDetailsContextVariables,
} from "./types/QueryDetailsContext";
import QUERY from "./Query.graphql";
import { useQuery } from "@apollo/client";
import { useDateRange } from "components/WithDateRange";

import styles from "./style.module.scss";

const QueryDetailsContext: React.FunctionComponent<{
  databaseId: string;
  queryId: string;
}> = ({ databaseId, queryId }) => {
  const comparePeriod = [7, "days"];
  const [compare, setCompare] = useState(false);
  const [{ from, to }] = useDateRange();
  const { data, loading, error } = useQuery<
    QueryDetailsContextType,
    QueryDetailsContextVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryId,
      startTs: from.unix(),
      endTs: to.unix(),
      compare,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { avgTime, avgTimeDelta, callsPerMinute, callsPerMinuteDelta } =
    data.getQueryDetailSummaryStats;
  let avgTimeTrend: React.ReactNode = "";
  const startTs = from.unix();
  const endTs = to.unix();
  const prevStartTs = from
    .clone()
    .subtract(...comparePeriod)
    .unix();
  const prevEndTs = to
    .clone()
    .subtract(...comparePeriod)
    .unix();
  if (
    compare &&
    avgTimeDelta !== null &&
    avgTimeDelta !== 0 &&
    avgTime !== null
  ) {
    const trendContent = formatMsTrend(avgTime, avgTime - avgTimeDelta);
    const trendTooltip = formatMsTrendDetailRange(
      avgTime,
      avgTime - avgTimeDelta,
      startTs,
      endTs,
      prevStartTs,
      prevEndTs,
    );

    avgTimeTrend = <Badge title={trendTooltip}>{trendContent}</Badge>;
  }
  let callsPerMinuteTrend: React.ReactNode = "";
  if (
    compare &&
    callsPerMinuteDelta !== null &&
    callsPerMinuteDelta !== 0 &&
    callsPerMinute !== null
  ) {
    const trendContent = formatNumberTrend(
      callsPerMinute,
      callsPerMinute - callsPerMinuteDelta,
      2,
    );
    const trendTooltip = formatNumberTrendDetailRange(
      callsPerMinute,
      callsPerMinute - callsPerMinuteDelta,
      startTs,
      endTs,
      prevStartTs,
      prevEndTs,
      2,
    );

    callsPerMinuteTrend = <Badge title={trendTooltip}>{trendContent}</Badge>;
  }

  return (
    <div className={styles.pageContext}>
      <div className={styles.pageContextStats}>
        <dl>
          <dt>Avg Time</dt>
          <dd>
            <div className={styles.statsNumber}>{formatMs(avgTime)}</div>
            {avgTimeTrend}
          </dd>
        </dl>
        <dl>
          <dt>Calls Per Minute</dt>
          <dd>
            <div className={styles.statsNumber}>
              {formatNumber(callsPerMinute, 2)} / min
            </div>
            {callsPerMinuteTrend}
          </dd>
        </dl>
      </div>
      <div className={styles.compareToggle}>
        <input
          type="checkbox"
          checked={compare}
          id="include_comparison"
          onChange={(evt) => setCompare(evt.target.checked)}
        />
        <label htmlFor="include_comparison"> Compare to 7 days ago</label>
      </div>
    </div>
  );
};

export default QueryDetailsContext;
