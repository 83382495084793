import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import sortBy from "lodash/sortBy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock as faLockSolid,
  faClock,
} from "@fortawesome/pro-solid-svg-icons";

import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import SQL from "components/SQL";
import Grid, { CellData } from "components/Grid";
import Button from "components/Button";
import Badge from "components/Badge";
import { useFeature } from "components/OrganizationFeatures";

import {
  BackendListActivity,
  BackendListActivityVariables,
  BackendListActivity_getBackends as BackendAtTimeType,
  BackendListActivity_getBackends_snapshot as BackendSnapshot,
  BackendListActivity_getBackends_backend as Backend,
} from "./types/BackendListActivity";
import QUERY from "./Query.graphql";

import classNames from "classnames";
import { ExpandToggle } from "components/Icons";

type Props = {
  databaseId: string;
  timestamp: number;
  silentRefresh: boolean;
  setPaused?: (value: boolean) => void;
};

const Activity: React.FunctionComponent<Props> = ({
  databaseId,
  timestamp,
  silentRefresh,
  setPaused,
}) => {
  const { data, previousData, loading, error } = useQuery<
    BackendListActivity,
    BackendListActivityVariables
  >(QUERY, {
    variables: {
      databaseId,
      timestamp,
    },
  });

  const backendData = data?.getBackends ?? previousData?.getBackends;
  if ((loading && (!backendData || !silentRefresh)) || error) {
    return <Loading error={!!error} />;
  }

  const backends = sortBy(
    backendData,
    (b: BackendAtTimeType): number =>
      b.snapshot.queryStart ||
      (b.snapshot.state == "idle" && parseInt(b.backend.identity, 10)) ||
      (b.snapshot.state == "unknown" &&
        parseInt(b.backend.identity, 10) * 10) ||
      -parseInt(b.backend.identity, 10),
  );

  return (
    <WithPidFocus>
      <WithPidHover>
        <Grid
          className="grid-cols-[65px_120px_120px_120px_1fr_200px]"
          data={backends}
          cellRenderer={CellRenderer}
          columns={[
            {
              field: "backend",
              header: "PID",
              style: "number",
              renderer: function PidCell({ fieldData }) {
                return (
                  <PidCellWithRef backend={fieldData} databaseId={databaseId} />
                );
              },
              className: "!overflow-visible",
            },
            {
              field: "backend",
              header: "Role",
              renderer: function RoleCell({ fieldData }) {
                if (!fieldData.postgresRole) {
                  return "-";
                }
                return (
                  <div
                    className="text-ellipsis overflow-hidden"
                    title={fieldData.postgresRole.name}
                  >
                    {fieldData.postgresRole.name}
                  </div>
                );
              },
            },
            {
              field: "snapshot",
              header: "State",
              renderer: function StateCell({ fieldData }) {
                if (fieldData.waitingForLock) {
                  return fieldData.state + " (waiting)";
                } else {
                  return fieldData.state;
                }
              },
            },
            {
              field: "snapshot",
              header: "Query Age",
              tip: "The query age as of when the connection data was collected.",
              renderer: ({ fieldData }) => fieldData.queryAge,
            },
            {
              field: "snapshot",
              header: "Last Query",
              renderer: function LastQueryCell({ fieldData }) {
                return (
                  <QueryDetails snapshot={fieldData} setPaused={setPaused} />
                );
              },
            },
            {
              field: "snapshot",
              header: "Wait Event",
              renderer: function WaitEventCell({ fieldData }) {
                return fieldData.waitEvent
                  ? `${fieldData.waitEventType} / ${fieldData.waitEvent}`
                  : "-";
              },
              className: "whitespace-normal",
            },
          ]}
        />
      </WithPidHover>
    </WithPidFocus>
  );
};

const CellRenderer: React.ComponentType<
  CellData<BackendAtTimeType, "backend" | "snapshot"> & {
    row: number;
    column: number;
    title: string;
    className: string;
    children: React.ReactNode;
  }
> = ({ rowData, title, className, children }) => {
  const blocking = rowData.snapshot.uniqBlockingPids.length > 0;
  const blocked = rowData.snapshot.uniqBlockedByPids.length > 0;
  const pid = rowData.backend.pid;
  const hoveredPid = useHoveredPid();
  const hasLockMonitoringFeature = useFeature("lockMonitoring");

  let bgColor;
  let rowClassNames;

  if (hasLockMonitoringFeature) {
    if (blocked || rowData.snapshot.waitingForLock) {
      // blocked by something - yellow
      bgColor = "bg-[#fefce8]";
    } else if (blocking) {
      // only blocking ("root" query) - red
      bgColor = "bg-[#fef2f2]";
    }
    rowClassNames = classNames(bgColor, pid == hoveredPid && "font-bold");
  } else {
    if (rowData.snapshot.waitingForLock) {
      bgColor = "bg-[#fefce8]";
    }
    rowClassNames = bgColor;
  }

  return (
    <div
      title={title}
      className={classNames(className, "!mt-0", rowClassNames)}
    >
      {children}
    </div>
  );
};

const PidCellWithRef: React.FunctionComponent<{
  backend: Backend;
  databaseId: string;
}> = ({ backend, databaseId }) => {
  const { databaseBackend } = useRoutes();

  // handle focus in pid cell as scrollIntoView doesn't work with overflow-hidden
  // this cell uses overflow-visible instead (shouldn't overflow so safe to do so)
  const pidRef = useRef<HTMLAnchorElement>();
  const focus = useFocusedPid();
  const setFocus = useSetFocusedPid();

  useEffect(() => {
    if (backend.pid === focus && pidRef.current) {
      pidRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setFocus(null);
    }
  }, [backend.pid, focus, setFocus]);

  return (
    <Link
      to={databaseBackend(databaseId, backend.identity)}
      ref={pidRef}
      className="scroll-mt-[70px]"
    >
      {backend.pid}
    </Link>
  );
};

const QueryDetails: React.FunctionComponent<{
  snapshot: BackendSnapshot;
  setPaused?: (value: boolean) => void;
}> = ({ snapshot, setPaused }) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggleExpanded = () => {
    setPaused && setPaused(true);
    setExpanded((currExp) => !currExp);
  };
  const hasLockMonitoringFeature = useFeature("lockMonitoring");

  const query = snapshot.queryTextShort ? (
    <SQL inline sql={snapshot.queryTextShort} />
  ) : (
    <>-</>
  );

  const uniqueBlockedByCount = snapshot.uniqBlockedByPids.length;
  const uniqueBlockingCount = snapshot.uniqBlockingPids.length;
  const blocked = uniqueBlockedByCount > 0;
  const blocking = uniqueBlockingCount > 0;
  let toggleButton;
  let lockBadge;

  if (hasLockMonitoringFeature) {
    if (blocked || blocking) {
      toggleButton = (
        <Button bare onClick={handleToggleExpanded} className="w-3 mr-2">
          <ExpandToggle expanded={expanded} />
        </Button>
      );
    }

    if (blocked) {
      // yellow
      lockBadge = (
        <Badge className="!bg-[#fde047] !text-[#713f12]">
          <FontAwesomeIcon icon={faClock} fixedWidth /> {uniqueBlockedByCount}
        </Badge>
      );
    } else if (blocking) {
      // red
      lockBadge = (
        <Badge className="!bg-[#fca5a5] !text-[#7f1d1d]">
          <FontAwesomeIcon icon={faLockSolid} fixedWidth />{" "}
          {uniqueBlockingCount}
        </Badge>
      );
    }
  }

  const blockedByInfo = blocked && (
    <>
      <div className="font-medium">Waiting For</div>
      <div className="text-ellipsis overflow-hidden">
        <PidList pidList={snapshot.uniqBlockedByPids} />
      </div>
    </>
  );

  const blockingInfo = blocking && (
    <>
      <div className="font-medium">Blocking</div>
      <div className="text-ellipsis overflow-hidden">
        <PidList pidList={snapshot.uniqBlockingPids} />
      </div>
    </>
  );

  return (
    <div>
      {toggleButton}
      {lockBadge && <>{lockBadge} </>}
      {query}
      {expanded && (
        <div className="ml-5">
          <div className="grid grid-cols-[100px_1fr] gap-1 mt-3">
            {blockedByInfo}
            {blockingInfo}
          </div>
        </div>
      )}
    </div>
  );
};

const PidList: React.FunctionComponent<{
  pidList: number[];
}> = ({ pidList }) => {
  const setHoveredPid = useSetHoveredPid();
  const setFocusedPid = useSetFocusedPid();

  const handlePidHover = (pid: number, enter: boolean) => {
    if (enter) {
      setHoveredPid(pid);
    } else {
      setHoveredPid(null);
    }
  };
  const handlePidFocus = (pid: number) => {
    setFocusedPid(pid);
  };

  return (
    <>
      {pidList.map((pid, i) => [
        i > 0 && ", ",
        <span
          key={i}
          onMouseEnter={() => handlePidHover(pid, true)}
          onMouseLeave={() => handlePidHover(pid, false)}
          onClick={() => handlePidFocus(pid)}
          className="underline hover:cursor-pointer hover:text-[#3272af]"
        >
          {pid}
        </span>,
      ])}
    </>
  );
};

// Context to pass hovered and focused pid information
const HoveredPidContext = React.createContext<number | null>(null);
const SetHoveredPidContext = React.createContext<(s: number | null) => void>(
  () => {
    /* do nothing */
  },
);

export const WithPidHover = ({ children }: { children: React.ReactNode }) => {
  const [hoveredPid, setHoveredPid] = useState<number | null>(null);
  return (
    <SetHoveredPidContext.Provider value={setHoveredPid}>
      <HoveredPidContext.Provider value={hoveredPid}>
        {children}
      </HoveredPidContext.Provider>
    </SetHoveredPidContext.Provider>
  );
};

function useHoveredPid(): number | null {
  return useContext(HoveredPidContext);
}

function useSetHoveredPid(): (val: number | null) => void {
  return useContext(SetHoveredPidContext);
}

const FocusedPidContext = React.createContext<number | null>(null);
const SetFocusedPidContext = React.createContext<(s: number | null) => void>(
  () => {
    /* do nothing */
  },
);

export const WithPidFocus = ({ children }: { children: React.ReactNode }) => {
  const [focusedPid, setFocusedPid] = useState<number | null>(null);
  return (
    <SetFocusedPidContext.Provider value={setFocusedPid}>
      <FocusedPidContext.Provider value={focusedPid}>
        {children}
      </FocusedPidContext.Provider>
    </SetFocusedPidContext.Provider>
  );
};

function useFocusedPid(): number | null {
  return useContext(FocusedPidContext);
}

function useSetFocusedPid(): (val: number | null) => void {
  return useContext(SetFocusedPidContext);
}
export default Activity;
