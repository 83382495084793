import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLineColumns,
  faQuestion,
  faAbacus,
  faSortAlphaDown,
  faSortAmountDownAlt,
  faGlobeStand,
  faFilter,
  faLayerPlus,
  faLayerGroup,
  faSendBack,
  faBringFront,
  faFunction,
  faIntersection,
  faUnion,
  faLockAlt,
  faObjectGroup,
  faRedo,
  faSdCard,
  faLocation,
  faArrowSquareUp,
  faArrowToTop,
  faArrowUp,
  faPollH,
  faEdit,
  faCalculatorAlt,
  faExpandArrowsAlt,
  faLevelDown,
  faColumns,
  faCogs,
  faContainerStorage,
  faCabinetFiling,
  faCompressWide,
  faFilm,
  faGripVertical,
  faInventory,
  faPuzzlePiece,
  faGameBoard,
  faGameBoardAlt,
  faBallotCheck,
} from "@fortawesome/pro-light-svg-icons";

const NodeTypeIcon: React.FunctionComponent<{ nodeType: string }> = ({
  nodeType,
}) => {
  switch (nodeType) {
    case "Seq Scan":
      return <FontAwesomeIcon icon={faLineColumns} />;
    case "Aggregate":
      return <FontAwesomeIcon icon={faAbacus} />;
    case "Sort":
      return <FontAwesomeIcon icon={faSortAlphaDown} />;
    case "Incremental Sort":
      return <FontAwesomeIcon icon={faSortAmountDownAlt} />;
    case "Limit":
      return <FontAwesomeIcon icon={faFilter} />;
    case "Foreign Scan":
      return <FontAwesomeIcon icon={faGlobeStand} />;
    case "Append":
      return <FontAwesomeIcon icon={faLayerPlus} />;
    case "Merge Append":
      return <FontAwesomeIcon icon={faLayerGroup} />;
    case "Gather Merge":
      return <FontAwesomeIcon icon={faSendBack} />;
    case "Gather":
      return <FontAwesomeIcon icon={faBringFront} />;
    case "Function Scan":
      return <FontAwesomeIcon icon={faFunction} />;
    case "BitmapAnd":
      return <FontAwesomeIcon icon={faIntersection} />;
    case "BitmapOr":
      return <FontAwesomeIcon icon={faUnion} />;
    case "Lock Rows":
      return <FontAwesomeIcon icon={faLockAlt} />;
    case "Group":
      return <FontAwesomeIcon icon={faObjectGroup} />;
    case "Nested Loop":
      return <FontAwesomeIcon icon={faRedo} />;
    case "Materialize":
      return <FontAwesomeIcon icon={faSdCard} />;
    case "Index Only Scan":
      return <FontAwesomeIcon icon={faLocation} />;
    case "Index Scan":
      return <FontAwesomeIcon icon={faArrowSquareUp} />;
    case "Bitmap Heap Scan":
      return <FontAwesomeIcon icon={faArrowToTop} />;
    case "Bitmap Index Scan":
      return <FontAwesomeIcon icon={faArrowUp} />;
    case "Result":
      return <FontAwesomeIcon icon={faPollH} />;
    case "ModifyTable":
      return <FontAwesomeIcon icon={faEdit} />;
    case "SetOp":
      return <FontAwesomeIcon icon={faCalculatorAlt} />;
    case "Project Set":
      return <FontAwesomeIcon icon={faExpandArrowsAlt} />;
    case "Recursive Union":
      return <FontAwesomeIcon icon={faLevelDown} />;
    case "Tid Scan":
      return <FontAwesomeIcon icon={faColumns} />;
    case "Custom Scan":
      return <FontAwesomeIcon icon={faCogs} />;
    case "Values Scan":
      return <FontAwesomeIcon icon={faContainerStorage} />;
    case "Work Table Scan":
      return <FontAwesomeIcon icon={faCabinetFiling} />;
    case "Window Aggregate":
      return <FontAwesomeIcon icon={faCompressWide} />;
    case "Subquery Scan":
      return <FontAwesomeIcon icon={faFilm} />;
    case "CTE Scan":
      return <FontAwesomeIcon icon={faGripVertical} />;
    case "Table Sample Scan":
      return <FontAwesomeIcon icon={faInventory} />;
    case "Hash":
      return <FontAwesomeIcon icon={faPuzzlePiece} />;
    case "Hash Join":
      return <FontAwesomeIcon icon={faGameBoard} />;
    case "Merge Join":
      return <FontAwesomeIcon icon={faGameBoardAlt} />;
    case "Unique":
      return <FontAwesomeIcon icon={faBallotCheck} />;
    default:
      return <FontAwesomeIcon icon={faQuestion} />;
  }
};

export default NodeTypeIcon;
