import React from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";
import { formatBytes, formatNumber } from "utils/format";

import Grid from "components/Grid";
import ImpactRenderer, { Impact } from "components/ImpactRenderer";

import styles from "./style.module.scss";

import { GetIndexAdvisorIssues_getIssues as IssueType } from "../types/GetIndexAdvisorIssues";
import { getTableName } from "..";
import { makeFilter } from "utils/filter";
import Popover from "components/Popover";

const UnusedIndexList: React.FunctionComponent<{
  searchTerm: string;
  issues: IssueType[];
  databaseId: string;
}> = ({ searchTerm, issues, databaseId }) => {
  const { databaseIssue } = useRoutes();

  const gridData = issues
    .map((issue) => {
      const tableId = JSON.parse(issue.groupingKey).table;
      const tableName = getTableName(issue);

      const details = JSON.parse(issue.detailsJson);

      return {
        id: issue.id,
        tableId,
        tableName,
        indexName: details.first_idx_name,
        alsoUnusedCount: details.also_unused_count ?? 0,
        indexSize: details.size,
        tableWriteOverhead: details.table_write_overhead,
        tableWritesPerMin: details.table_writes_per_min,
        writeOverhead: details.write_overhead,
        lastUsed: details.last_used,
      };
    })
    .filter(makeFilter(searchTerm, "tableName", "indexName"));

  return (
    <Grid
      className={styles.unusedIndexGrid}
      striped
      data={gridData}
      defaultSortBy="writeOverhead"
      columns={[
        {
          header: "Impact",
          field: "writeOverhead",
          className: "flex justify-center",
          defaultSortOrder: "desc",
          renderer: function Impact({ rowData, fieldData }) {
            const tableOverhead = rowData.tableWriteOverhead;
            const impact = calculateImpact(fieldData);
            return (
              <Popover
                content={
                  <>
                    <div className={styles.writeOverhead}>
                      <strong>Less Index Write Overhead</strong>
                    </div>
                    <div>
                      Overhead on table:{" "}
                      {tableOverhead == null
                        ? "n/a"
                        : formatNumber(tableOverhead, 2)}
                    </div>
                    <div>
                      From these indexes:{" "}
                      {fieldData == null ? "n/a" : formatNumber(fieldData, 2)}
                    </div>
                  </>
                }
              >
                <Link
                  to={databaseIssue(
                    databaseId,
                    rowData.id,
                    "schema/index_unused",
                  )}
                >
                  <ImpactRenderer impact={impact} />
                </Link>
              </Popover>
            );
          },
        },
        { header: "Table", field: "tableName" },
        {
          header: "Index",
          field: "indexName",
          renderer: function IndexDef({ rowData, fieldData }) {
            const alsoUnused = rowData.alsoUnusedCount;
            return (
              <Link
                to={databaseIssue(
                  databaseId,
                  rowData.id,
                  "schema/index_unused",
                )}
              >
                {fieldData ?? "[unknown index]"}
                {alsoUnused > 0 && ` (+${alsoUnused} more)`}
              </Link>
            );
          },
        },
        {
          header: "Index Size",
          field: "indexSize",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: ({ fieldData }) => formatBytes(fieldData),
          style: "number",
        },
        {
          header: "Last Used",
          field: "lastUsed",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          style: "number",
        },
        {
          header: "Table Writes / Min",
          field: "tableWritesPerMin",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: ({ fieldData }) => formatNumber(fieldData, 3),
          style: "number",
        },
        {
          header: "Index Write Overhead",
          field: "writeOverhead",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: function IndexWriteOverhead({ fieldData }) {
            return (
              <span className={styles.writeOverhead}>
                -{formatNumber(fieldData, 2)}
              </span>
            );
          },
          style: "number",
        },
      ]}
    />
  );
};

function calculateImpact(indexWriteOverhead: number | undefined): Impact {
  return indexWriteOverhead > 0.5
    ? 5
    : indexWriteOverhead > 0.4
    ? 4
    : indexWriteOverhead > 0.3
    ? 3
    : indexWriteOverhead > 0.2
    ? 2
    : 1;
}

export default UnusedIndexList;
