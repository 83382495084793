import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import sumBy from "lodash/sumBy";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import maxBy from "lodash/maxBy";
import invert from "lodash/invert";
import moment from "moment-timezone";

import QUERY from "./Query.graphql";

import waitEventTypeMapping from "../../../../collector/protobuf/mappings/wait_event_type.json";
import waitEventMapping from "../../../../collector/protobuf/mappings/wait_event.json";
import { Data, InteractionPoint } from "components/Graph/util";
import { BarSeries } from "components/Graph/Series";
import { waitEventGraphColors } from "utils/palette";
import Loading from "components/Loading";
import { useDateRange } from "components/WithDateRange";

import {
  WaitEventGraph as WaitEventGraphType,
  WaitEventGraphVariables,
  WaitEventGraph_getWaitEventCounts_counts as WaitEventCountType,
} from "./types/WaitEventGraph";
import DateRangeGraph from "components/Graph/DateRangeGraph";

type GroupedWaitEventCountType = {
  collectedAt: number;
  group1: number;
  group2: number;
  group3: number;
  group4: number;
  group5: number;
  group6: number;
  cpu: number;
  other: number;
  total: number;
};

type Props = {
  databaseId: string;
  onClick: (collectedAt: Date) => void;
};

const WaitEventGraph: React.FunctionComponent<Props> = ({
  databaseId,
  onClick,
}) => {
  const [{ from, to }] = useDateRange();

  const { data, loading, error } = useQuery<
    WaitEventGraphType,
    WaitEventGraphVariables
  >(QUERY, {
    variables: {
      databaseId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return <WaitEventGraphDisplay data={data} onClick={onClick} />;
};

const groups = [
  "cpu",
  "group1",
  "group2",
  "group3",
  "group4",
  "group5",
  "group6",
  "other",
];

type DisplayProps = Pick<Props, "onClick"> & {
  data: WaitEventGraphType;
};

const WaitEventGraphDisplay: React.FunctionComponent<DisplayProps> = ({
  data,
  onClick,
}) => {
  const memoizedState = useMemo(() => {
    let maxTotalConnections = 0;
    const counts = data.getWaitEventCounts.counts;
    const groupedWithKeys = groupBy(
      counts,
      (d: WaitEventCountType): [number, number] => [
        d.waitEventType,
        d.waitEvent,
      ],
    );
    const grouped = sortBy<WaitEventCountType[]>(
      Object.values(groupedWithKeys),
      (g: any): number =>
        -maxBy(g, (d: WaitEventCountType): number => d.count).count,
    );
    const g1EvtType =
      (grouped.length >= 1 && grouped[0][0].waitEventType) || -1;
    const g1Evt = (grouped.length >= 1 && grouped[0][0].waitEvent) || -1;
    const g2EvtType =
      (grouped.length >= 2 && grouped[1][0].waitEventType) || -1;
    const g2Evt = (grouped.length >= 2 && grouped[1][0].waitEvent) || -1;
    const g3EvtType =
      (grouped.length >= 3 && grouped[2][0].waitEventType) || -1;
    const g3Evt = (grouped.length >= 3 && grouped[2][0].waitEvent) || -1;
    const g4EvtType =
      (grouped.length >= 4 && grouped[3][0].waitEventType) || -1;
    const g4Evt = (grouped.length >= 4 && grouped[3][0].waitEvent) || -1;
    const g5EvtType =
      (grouped.length >= 5 && grouped[4][0].waitEventType) || -1;
    const g5Evt = (grouped.length >= 5 && grouped[4][0].waitEvent) || -1;
    const g6EvtType =
      (grouped.length >= 6 && grouped[5][0].waitEventType) || -1;
    const g6Evt = (grouped.length >= 6 && grouped[5][0].waitEvent) || -1;
    const g7EvtType = 0;
    const g7Evt = 0;

    const groupedByTime = groupBy(
      counts,
      (d: WaitEventCountType): number => d.collectedAt,
    );
    const groupedCounts = Object.values(groupedByTime).map(
      (counts: Array<WaitEventCountType>): GroupedWaitEventCountType => {
        const collectedAt = counts[0].collectedAt;
        const total = sumBy(counts, (c: WaitEventCountType): number => c.count);
        maxTotalConnections = Math.max(maxTotalConnections, total);
        return {
          collectedAt: Math.floor(collectedAt),
          group1: sumBy(
            counts,
            (c: WaitEventCountType): number =>
              (c.waitEventType == g1EvtType &&
                c.waitEvent == g1Evt &&
                c.count) ||
              0,
          ),
          group2: sumBy(
            counts,
            (c: WaitEventCountType): number =>
              (c.waitEventType == g2EvtType &&
                c.waitEvent == g2Evt &&
                c.count) ||
              0,
          ),
          group3: sumBy(
            counts,
            (c: WaitEventCountType): number =>
              (c.waitEventType == g3EvtType &&
                c.waitEvent == g3Evt &&
                c.count) ||
              0,
          ),
          group4: sumBy(
            counts,
            (c: WaitEventCountType): number =>
              (c.waitEventType == g4EvtType &&
                c.waitEvent == g4Evt &&
                c.count) ||
              0,
          ),
          group5: sumBy(
            counts,
            (c: WaitEventCountType): number =>
              (c.waitEventType == g5EvtType &&
                c.waitEvent == g5Evt &&
                c.count) ||
              0,
          ),
          group6: sumBy(
            counts,
            (c: WaitEventCountType): number =>
              (c.waitEventType == g6EvtType &&
                c.waitEvent == g6Evt &&
                c.count) ||
              0,
          ),
          cpu: sumBy(
            counts,
            (c: WaitEventCountType): number =>
              (c.waitEventType == g7EvtType &&
                c.waitEvent == g7Evt &&
                c.count) ||
              0,
          ),
          other: sumBy(
            counts,
            (c: WaitEventCountType): number =>
              (!(c.waitEventType == g1EvtType && c.waitEvent == g1Evt) &&
                !(c.waitEventType == g2EvtType && c.waitEvent == g2Evt) &&
                !(c.waitEventType == g3EvtType && c.waitEvent == g3Evt) &&
                !(c.waitEventType == g4EvtType && c.waitEvent == g4Evt) &&
                !(c.waitEventType == g5EvtType && c.waitEvent == g5Evt) &&
                !(c.waitEventType == g6EvtType && c.waitEvent == g6Evt) &&
                !(c.waitEventType == g7EvtType && c.waitEvent == g7Evt) &&
                c.count) ||
              0,
          ),
          total: total,
        };
      },
    );

    const waitEventTypeNames = invert(waitEventTypeMapping);
    const waitEventNames = invert(waitEventMapping);

    const labelGroup = (
      groupEvtType: number,
      groupEvt: number,
    ): string | undefined => {
      if (groupEvtType === -1 || groupEvt === -1) {
        return undefined;
      }
      return `${waitEventTypeNames[groupEvtType]} / ${waitEventNames[groupEvt]}`;
    };

    const groupLabels = {
      group1: labelGroup(g1EvtType, g1Evt),
      group2: labelGroup(g2EvtType, g2Evt),
      group3: labelGroup(g3EvtType, g3Evt),
      group4: labelGroup(g4EvtType, g4Evt),
      group5: labelGroup(g5EvtType, g5Evt),
      group6: labelGroup(g6EvtType, g6Evt),
      cpu: "CPU",
      other: "Other",
    };

    const newGroupedCounts = groupedCounts.reduce<Data>(
      (d: Data, curr: GroupedWaitEventCountType) => {
        return groups.reduce((state, currKey) => {
          state[currKey].push([curr.collectedAt, curr[currKey]]);
          return state;
        }, d);
      },
      groups.reduce((emptyState, currKey) => {
        emptyState[currKey] = [];
        return emptyState;
      }, {}),
    );

    return {
      newGroupedCounts,
      groupedCounts,
      maxTotalConnections,
      groupLabels,
    };
  }, [data]);

  const handleNewChartClick = (d: InteractionPoint): void => {
    const ts = d.bottom.domain;
    onClick(moment(ts).toDate());
  };

  const { newGroupedCounts: newData, groupLabels } = memoizedState;
  return (
    newData && (
      <DateRangeGraph
        data={newData}
        series={groups
          .filter((g) => groupLabels[g] !== undefined)
          .map((g) => {
            return {
              label: groupLabels[g],
              type: BarSeries,
              key: g,
              color: fillFor(g),
            };
          })}
        onClick={handleNewChartClick}
      />
    )
  );
};

const fillFor = (state: string) => {
  return {
    group1: waitEventGraphColors.countGroup1,
    group2: waitEventGraphColors.countGroup2,
    group3: waitEventGraphColors.countGroup3,
    group4: waitEventGraphColors.countGroup4,
    group5: waitEventGraphColors.countGroup5,
    group6: waitEventGraphColors.countGroup6,
    cpu: waitEventGraphColors.countCPU,
    other: waitEventGraphColors.countOther,
  }[state];
};

export default WaitEventGraph;
