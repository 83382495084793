export type BitString = {
  // b101 (= B'101' in query)
  bsval: string;
};
export type QueryParameterValue = number | string | boolean | BitString | null;
// AliasParamMapType is the same shape as backend's Types::AliasParamMapType.parameters
// { "$alias1": { value: "foo", type: "text" }, "$alias2": ... }
export type AliasParamMapType = {
  [key: string]: {
    value: QueryParameterValue;
    type: ValueType;
  };
};
// JsonParametersType is the same shape as backend's Types::QuerySampleType.json_parameters
// Unlike AliasParamMapType, this doesn't have type information
// { 1: "foo", 2: 123 }
export type JsonParametersType = {
  [key: string]: QueryParameterValue;
};
// ParamSetType is used to store param set data from input fields
// Used when create/edit parameter sets
// {
//   "$alias1": { value: "value for $alias1", type: null (auto detect) },
//   "$alias2": { value: "value for $alias2", type: "double precision" },
//   "$alias3": { value: null, type: "jsonb", isNull: true },
// }
export type ParamSetValueType = {
  value: string | null;
  type: ValueType | null;
  isNull?: boolean | null;
};
export type ParamSetType = {
  [key: string]: ParamSetValueType;
};
// key = setId, value = paramSet (above)
export type ParameterSetsType = {
  [key: string]: ParamSetType;
};
export type ValueType =
  | "bigint"
  | "double precision"
  | "text"
  | "boolean"
  | "json"
  | "jsonb"
  | "bytea"
  | "bit";
export const valueTypeValues: ValueType[] = [
  "bigint",
  "double precision",
  "text",
  "boolean",
  "json",
  "jsonb",
  "bytea",
  "bit",
];

// jsonParametersToString and aliasParamMapToString both create comma separated
// list of parameters. aliasParamMapToString potentially have type casting if available.
export function jsonParametersToString(params: JsonParametersType) {
  return Object.entries(params)
    .map(([key, value]) => {
      return `${key} = ${stringifyValue(value)}`;
    })
    .join(", ");
}

export function aliasParamMapToString(params: AliasParamMapType) {
  return Object.entries(params)
    .map(([key, param]) => {
      return `${key} = ${stringifyValue(param.value, param.type)}`;
    })
    .join(", ");
}

// stringifyValue takes the param values from the backend (QueryParameterValue type),
// then stringify them to show them in the UI.
export function stringifyValue(value: QueryParameterValue, type?: string) {
  const maxLength = 50;
  const suffix = type ? `::${type}` : "";

  if (value === null) {
    return `NULL${suffix}`;
  } else if (typeof value === "number" || typeof value === "boolean") {
    return `${value}${suffix}`;
  } else if (typeof value === "string") {
    if (value.length > maxLength) {
      return `'${value.slice(0, maxLength)}...'${suffix}`;
    } else {
      return `'${value}'${suffix}`;
    }
  } else {
    // BitString (do not add suffix)
    return `${(value as BitString).bsval[0]}'${(value as BitString).bsval.slice(
      1,
    )}'`;
  }
}

// convertParamValue converts a string value (from input field) to a auto detected (or type forced) value
// which will be used as values to pass to the backend.
export function convertParamValue(param: ParamSetValueType) {
  if (param.isNull) {
    return null;
  }
  if (param.type == null) {
    return autoConvertParamValue(param.value);
  }

  // Allow empty inputs only with text and bytea
  if (
    param.value == null &&
    (param.type === "text" || param.type === "bytea")
  ) {
    return "";
  } else if (param.type === "bit") {
    const match = param.value.match(/^b'([01]+)'$/i);
    if (match && match[1]) {
      return { bsval: `b${match[1]}` };
    }
  }
  return param.value;
}

export function validateParamSet(
  paramSet: ParamSetType,
  expectedSize: number,
): string | null {
  if (Object.keys(paramSet).length < expectedSize) {
    return "Please enter values, types, or NULL flags for all parameters.";
  }
  const invalidAliases: string[] = [];
  const badTypeAliases: string[] = [];
  const badBitTypes: string[] = [];
  Object.entries(paramSet).forEach(([alias, set]) => {
    if (set.value == null && !set.isNull && set.type == null) {
      invalidAliases.push(alias);
    } else if (
      set.value == null &&
      !set.isNull &&
      !["text", "bytea"].includes(set.type)
    ) {
      // null (empty) value is only available for text and bytea types
      badTypeAliases.push(alias);
    } else if (set.type === "bit") {
      const match = set.value.match(/^b'([01]+)'$/i);
      if (!match) {
        badBitTypes.push(alias);
      }
    }
  });
  if (invalidAliases.length > 0) {
    return `Neither value, NULL flag or type are selected. Please specify at least one of them. (${invalidAliases.join(
      ", ",
    )})`;
  }
  if (badTypeAliases.length > 0) {
    return `The value is empty. The empty value is only allowed with the type text and bytea. For jsonb, use {} or []. (${badTypeAliases.join(
      ", ",
    )})`;
  }
  if (badBitTypes.length > 0) {
    return `Bit type is selected but the value format is inappropriate. Please use the format like B'101'. (${badBitTypes.join(
      ", ",
    )})`;
  }

  return null;
}

// autoConvertParamValue will convert param values from input fields (create/update params)
// to either number, boolean, or string.
export function autoConvertParamValue(value: string) {
  const numValue = +value;
  let convertedValue: any = value;
  if (!isNaN(numValue)) {
    convertedValue = numValue;
  } else if (value === "true") {
    convertedValue = true;
  } else if (value === "false") {
    convertedValue = false;
  }
  return convertedValue;
}

// convertToParamSetType converts the aliasParamMap from the backend to the ParamSet type
// which can be used for input fields of create/edit parameter sets.
export function convertToParamSetType(parameters: AliasParamMapType) {
  const paramSet: ParamSetType = {};
  Object.entries(parameters).forEach(([alias, param]) => {
    let value = param.value;
    const type = param.type;
    let isNull = false;
    if (value === null) {
      isNull = true;
    }
    if (type === "bit") {
      // value is stored as { bsval: b101 }
      const match = (value as BitString).bsval.match(/^b([01]+)$/);
      if (match && match[1]) {
        value = `b'${match[1]}'`;
      }
    }
    paramSet[alias] = {
      value: isNull ? null : `${value}`,
      type: type,
      isNull: isNull,
    };
  });
  return paramSet;
}

export function formatQueryTextWithParamValues(
  queryTextWithAlias: string,
  paramSet: ParamSetType,
) {
  // This logic should be similar to the backend logic in app/models/explain_query.rb
  return queryTextWithAlias.replace(/\$[a-zA-Z0-9_]+/g, (match) => {
    const set = paramSet[match];
    if (set === undefined) {
      // When the set is still undefined, do not replace
      return match;
    }
    const type = set?.type;
    let value = set?.value;
    const isNull = set?.isNull;
    if (type) {
      if (type === "bit") {
        // Do not add a type casting or surrounding single quotes for bit string
        // (assuming that the value is already something like b'101')
        return value;
      } else if (isNull) {
        return `NULL::${type}`;
      } else if (value == null) {
        if (type === "text" || type === "bytea") {
          // text and bytea, treat null as an empty string
          return `''::${type}`;
        }
        // When the value is still undefined, or value once became null (e.g. NULL flag selected but unselected)
        // do not replace
        return match;
      } else {
        // Escape single quotes by doubling them
        value = value.replace(/'/g, "''");
        return `'${value}'::${type}`;
      }
    } else {
      if (isNull) {
        return "NULL";
      }
      if (value == null) {
        // When the value is still undefined, or value once became null (e.g. NULL flag selected but unselected)
        // do not replace
        return match;
      }
      value = autoConvertParamValue(value);
      if (typeof value === "string") {
        // Escape single quotes by doubling them
        value = value.replace(/'/g, "''");
        return `'${value}'`;
      } else {
        return value;
      }
    }
  });
}
