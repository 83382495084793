import { useMatch } from "react-router-dom";

export const useRouteTopLevelReference = (): {
  organizationSlug?: string;
  serverId?: string;
  databaseId?: string;
} => {
  const databaseMatch = useMatch(`/databases/:databaseId/*`);
  const serverMatch = useMatch(`/servers/:serverId/*`);
  const organizationMatch = useMatch(`/organizations/:slug/*`);
  if (databaseMatch) {
    return { databaseId: databaseMatch.params["databaseId"] };
  }
  if (serverMatch) {
    return { serverId: serverMatch.params["serverId"] };
  }
  if (organizationMatch) {
    const organizationSlug =
      (organizationMatch.params["slug"] !== "new" &&
        organizationMatch.params["slug"]) ||
      undefined;
    return { organizationSlug };
  }
  return {};
};
