import React, { useRef, useLayoutEffect } from "react";

import { axisBottom, axisLeft, axisRight } from "d3-axis";
import { select } from "d3-selection";
import { extent, ScaleLinear } from "d3";
import { translateX } from "utils/svg";

import { Scale } from "./util";
import {
  formatBytes,
  formatCountShorthand,
  formatInterval,
  formatNumber,
  scaleInterval,
  scaleIntervalTo,
} from "utils/format";

export type TickBuiltInFormat =
  | "count"
  | "bytes/sec"
  | "duration ms"
  | "pct"
  | "pct from ratio";
export type TickFormatFn = (value: number) => string;

export type TickFormat = TickBuiltInFormat | TickFormatFn;

type Props = {
  placement?: "left" | "bottom" | "right";
  scale: Scale;
  width: number;
  height: number;
  tickFormat?: TickFormat;
};

const Axis: React.FunctionComponent<Props> = ({
  placement = "bottom",
  scale,
  width,
  tickFormat,
}) => {
  const ref = useRef<SVGGElement>(null);
  useLayoutEffect(() => {
    const axisFn = {
      bottom: axisBottom,
      left: axisLeft,
      right: axisRight,
    }[placement];

    const host = select(ref.current);
    const axisGenerator = axisFn(scale);
    if (tickFormat) {
      const tickFormatFn = getTickFormatFn(
        tickFormat,
        scale as ScaleLinear<number, number>,
      );
      if (tickFormatFn) {
        axisGenerator.tickFormat(tickFormatFn);
      }
    }
    const [start, end] = extent(scale.range());
    const pxPerTick = placement === "bottom" ? 80 : 30;
    const tickCount =
      start === undefined || end === undefined
        ? 0
        : Math.ceil((end - start) / pxPerTick);
    axisGenerator.ticks(tickCount);

    host.select("g").remove();
    const group = host.append("g");
    if (placement === "left") {
      // the axis is drawn to the left of the origin, but the placement
      // for a left axis should be along the right edge of the axis space
      group.attr("transform", translateX(width));
    }
    group.call(axisGenerator);
  }, [scale, placement, width, tickFormat]);

  return <g ref={ref} />;
};

export function getTickFormatFn(
  format: TickFormat | undefined,
  scale: ScaleLinear<number, number>,
): TickFormatFn | undefined {
  if (format == null) {
    return undefined;
  }
  if (typeof format === "function") {
    // We wrap the format function to avoid passing it the tick index argument
    // that d3 passes. This may be useful for d3, but it's more useful for us to
    // directly accept existing format functions that may take optional
    // arguments with different semantics (e.g., precision).
    return (value: number) => format(value);
  }

  switch (format) {
    case "count":
      return getCountTickFormatFn(scale);
    case "duration ms":
      return getMsDurationFormatFn(scale);
    case "bytes/sec":
      return getBytesPerSecondFormatFn();
    case "pct":
      return getPercentFormatFn();
    case "pct from ratio":
      return getPercentFromRatioFmtFn();
  }
}

function derivePrecision(value: number): number {
  if (value === 0) {
    return 2;
  } else if (value < 1_000) {
    return 2;
  } else if (value < 10_000) {
    return 1;
  } else {
    return 0;
  }
}

// For axis label formatters, we have about seven characters to work with before
// things get crowded or spill off the screen. Format values adaptively to keep
// as much detail as possible but to make them fit.
function getCountTickFormatFn(
  scale: ScaleLinear<number, number>,
): TickFormatFn {
  const max = scale.domain()[1];

  if (max < 1_000_000) {
    return (value) => {
      // these are counts, so we don't want a decimal point if we hve no suffix
      return formatNumber(value, 0);
    };
  }

  return (value) => {
    return formatCountShorthand(value, 1);
  };
}

function getBytesPerSecondFormatFn(): TickFormatFn {
  return (value: number) => formatBytes(value, { decimalPlaces: 0 }) + "/s";
}

function getMsDurationFormatFn(
  scale: ScaleLinear<number, number>,
): TickFormatFn {
  const max = scale.domain()[1];
  // find a unit that's suitable for formatting every value in this domain: we
  // assume that since we have a fairly small number of ticks linearly
  // distributed between the max and no less than zero, we can derive the unit
  // from the max, and it should work reasonably for all our tick values.
  const [scaledMax, unit] = scaleInterval(max);
  const precision = derivePrecision(scaledMax);

  return (value: number) => {
    const valueInUnit = scaleIntervalTo(value, unit);
    return formatInterval(valueInUnit, unit, precision);
  };
}

/**
 * Returns the value, interpreted as a percentage between 0 and 100, formatted
 * as a percentage.
 */
function getPercentFormatFn(): TickFormatFn {
  return (value) => value.toFixed(1) + " %";
}

/**
 * Returns the value, interpreted as a ratio between 0 and 1, formatted as a
 * percentage.
 */
function getPercentFromRatioFmtFn(): TickFormatFn {
  const formatPct = getPercentFormatFn();
  return (value) => formatPct(value * 100);
}

export default Axis;
