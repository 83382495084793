import React from "react";

import styles from "./style.module.scss";
import { isTestEnv } from "utils/env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

type Props = {
  authorizePath: string;
  googleClientId: string;
  buttonText?: string;
  errorHandler: (a: Array<string>) => void;
  formValidateHandler?: () => boolean;
  formDataHandler?: () => FormData;
  formSuccessHandler?: (location: string) => void;
  simple?: boolean;
  className?: string;
};

type AuthorizeResponseType = {
  code?: string;
  error?: string;
};

type AuthCallbackResultType = {
  errors: Array<string>;
  location: string;
};

class GoogleSignIn extends React.Component<Props> {
  componentDidMount() {
    if (isTestEnv) {
      return;
    }

    window.googlePlatformReadyCb = () => {
      window.gapi.load("auth2");
    };

    var js,
      gs = document.getElementsByTagName("script")[0];
    if (document.getElementById("google-platform")) {
      return;
    }
    js = document.createElement("script");
    js.id = "google-platform";
    js.src =
      "https://apis.google.com/js/client:platform.js?onload=googlePlatformReadyCb";
    if (gs.parentNode) {
      gs.parentNode.insertBefore(js, gs);
    }
  }

  render() {
    if (this.props.simple) {
      return (
        <a
          href="#"
          onClick={(evt) => {
            evt.preventDefault();
            this.handleClick();
          }}
          className={this.props.className}
        >
          {this.props.buttonText || "Sign In with Google"}
        </a>
      );
    }

    return (
      <div>
        <button
          onClick={(evt) => {
            evt.preventDefault();
            this.handleClick();
          }}
          className={styles.button}
        >
          <FontAwesomeIcon
            icon={faGoogle}
            className="text-[16px] mr-[8px] mt-[1px]"
          />
          <span>{this.props.buttonText || "Sign In with Google"}</span>
        </button>
      </div>
    );
  }

  handleClick() {
    if (this.props.formValidateHandler && !this.props.formValidateHandler()) {
      return;
    }

    if (isTestEnv) {
      this.handleAuthorizeResponse({ code: "dummy" });
      return;
    }

    window.gapi.auth2.authorize(
      {
        client_id: this.props.googleClientId,
        scope: "email profile",
        response_type: "code",
        prompt: "select_account",
      },
      (authorizeResponse: AuthorizeResponseType) => {
        this.handleAuthorizeResponse(authorizeResponse);
      },
    );
  }

  handleAuthorizeResponse({ code, error }: AuthorizeResponseType) {
    if (error == "popup_closed_by_user") {
      // Do nothing
    } else if (error) {
      this.props.errorHandler(["Internal error: " + error]);
    } else if (code) {
      var formData =
        (this.props.formDataHandler && this.props.formDataHandler()) ||
        new FormData();
      formData.append("code", code);
      fetch("/users/auth/google/callback", {
        method: "POST",
        body: formData,
        headers: { "X-Requested-With": "XMLHttpRequest" },
        credentials: "same-origin",
      })
        .then((response: Response): Promise<AuthCallbackResultType> => {
          return response.json();
        })
        .then((body: AuthCallbackResultType) => {
          if (body.errors) {
            this.props.errorHandler(body.errors);
          } else if (this.props.formSuccessHandler) {
            this.props.formSuccessHandler(body.location);
          } else {
            window.location.href = body.location;
          }
        });
    }
  }
}

export default GoogleSignIn;
