import React from "react";
import { useParams } from "react-router-dom";

import SchemaNav from "components/SchemaNav";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";

import Table from "./Table";

const SchemaViewList: React.FunctionComponent = () => {
  const { databaseId } = useParams();
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);

  const titleSearch = (
    <PanelTitleSearch value={searchTerm} onChange={setSearchTerm} />
  );

  return (
    <PageContent
      title="Schema Statistics"
      pageCategory="schema-views"
      pageName="index"
      featureNav={<SchemaNav databaseId={databaseId} />}
    >
      <Panel title="All Views" secondaryTitle={titleSearch}>
        <Table databaseId={databaseId} searchTerm={searchTerm} />
      </Panel>
    </PageContent>
  );
};

export default SchemaViewList;
