import React from "react";
import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";
import { QueryDetails_getQueryDetails_normalizedQueryScanTokens as QueryScanToken } from "components/QueryDetails/types/QueryDetails";
import classNames from "classnames";

function isPrimaryKeyword(t: QueryScanToken) {
  if (
    t.token == "SELECT" ||
    t.token == "FROM" ||
    t.token == "WHERE" ||
    t.token == "UPDATE" ||
    t.token == "DELETE_P" ||
    t.token == "RETURNING" ||
    t.token == "INSERT" ||
    t.token == "VALUES" ||
    t.token == "UNION" ||
    t.token == "GROUP_P" ||
    t.token == "ORDER"
  ) {
    return true;
  }
  return false;
}

function isSQLComment(t: QueryScanToken) {
  return t.token == "SQL_COMMENT";
}

function isComment(t: QueryScanToken) {
  return t.token == "C_COMMENT" || isSQLComment(t);
}

function isKeyword(t: QueryScanToken) {
  // TODO: "name" and "position" are both keywords and valid unquoted identifiers - how to we differentiate?
  if (isComment(t)) {
    return false;
  } else if (
    t.token != "IDENT" &&
    t.token != "NAME_P" &&
    t.token != "POSITION" &&
    t.token != "PARAM" &&
    !t.token.startsWith("ASCII_")
  ) {
    return true;
  }
  return false;
}

const OPEN_PAREN = "ASCII_40"; // "("
const CLOSE_PAREN = "ASCII_41"; // ")"

const SQLNew: React.FunctionComponent<{
  databaseId: string;
  queryText: string;
  scanTokens: QueryScanToken[] | null;
}> = ({ databaseId, queryText, scanTokens }) => {
  let prevToken: QueryScanToken | null;
  let currentDepth = 0;
  const depthIsIndented: { [key: number]: boolean } = {};
  const depthFirstKeywordLength: { [key: number]: number } = {};

  const { databaseTable } = useRoutes();

  const renderToken = function (
    scanToken: QueryScanToken,
    nextScanToken: QueryScanToken,
  ) {
    const { startPos, endPos, token, schemaTableId } = scanToken;
    let out = "";

    // We may have received a truncated query text from ExpandableSQL, so stop
    // early in those cases. Note that we still assume the query text before
    // the truncation point is identical with the text used to generate the
    // scan tokens, and no "smart" truncation has been performed without
    // adjusting the tokens accordingly.
    if (startPos > queryText.length) {
      return null;
    }

    // Add newline and indent as appropriate based on statement depth and parens
    if (depthIsIndented[currentDepth] && token == CLOSE_PAREN) {
      out += "\n";
      for (let i = 1; i <= currentDepth - 1; i++) {
        if (depthIsIndented[i]) {
          out += "  ";
        }
      }
    } else if (
      (depthIsIndented[currentDepth] && prevToken.token == OPEN_PAREN) ||
      (prevToken && (isComment(scanToken) || isPrimaryKeyword(scanToken)))
    ) {
      out += "\n";
      for (let i = 1; i <= currentDepth; i++) {
        if (depthIsIndented[i]) {
          out += "  ";
        }
      }
    }

    // Indent each new line within a statement to align keywords to form a
    // river of whitespace, inspired by https://www.sqlstyle.guide/#white-space
    if (isPrimaryKeyword(scanToken)) {
      if (depthFirstKeywordLength[currentDepth]) {
        const extraSpaces =
          depthFirstKeywordLength[currentDepth] - (endPos - startPos);
        for (let i = 0; i < extraSpaces; i++) {
          out += " ";
        }
      } else {
        // Use the length of the first keyword to determine the statement
        // intendation. Ideally we would determine the length of the longest
        // keyword within this depth, and align based on that, but that is
        // deferred to a future iteration of this code.
        depthFirstKeywordLength[currentDepth] = endPos - startPos;
      }
    }

    out += queryText.substring(startPos, endPos);

    // Set nesting depth values for the next iteration to correctly indent and
    // add newlines based on parens and primary keyword presence.
    if (token == OPEN_PAREN) {
      currentDepth++;
      if (nextScanToken && isPrimaryKeyword(nextScanToken)) {
        depthIsIndented[currentDepth] = true;
        depthFirstKeywordLength[currentDepth] = undefined;
      } else {
        depthIsIndented[currentDepth] = false;
        depthFirstKeywordLength[currentDepth] =
          depthFirstKeywordLength[currentDepth - 1];
      }
    } else if (token == CLOSE_PAREN) {
      currentDepth--;
    }

    // Add a single space between tokens if the source text has any whitespace
    // characters between the tokens (thus the endPos of the current token and
    // startPos of the next token are not identical). Note that we
    // intentionally discard newlines in the source text except for newlines
    // ending single-line comments.
    if (isSQLComment(scanToken)) {
      out += "\n";
    } else if (
      nextScanToken &&
      scanToken.endPos < nextScanToken.startPos &&
      queryText.substring(scanToken.endPos, nextScanToken.startPos).match(/\s/)
    ) {
      out += " ";
    }

    prevToken = scanToken;

    let className = "text-[#555]";
    if (isComment(scanToken)) {
      className = "text-[#888]";
    } else if (isKeyword(scanToken)) {
      className = "text-[#005d00]";
    }

    return (
      <span
        className={classNames(className, "break-words break-all")}
        key={startPos}
      >
        {schemaTableId ? (
          <Link to={databaseTable(databaseId, schemaTableId)}>
            {/* Indicate when partitioned table names were partially ignored in the fingerprint through stars */}
            {out.replace(/\d{2,}/g, "**")}
          </Link>
        ) : (
          out
        )}
      </span>
    );
  };

  return (
    <div className="font-query break-all whitespace-pre-wrap text-[13px]">
      {scanTokens
        ? scanTokens.map((t, idx) => renderToken(t, scanTokens[idx + 1]))
        : queryText}
    </div>
  );
};

export default SQLNew;
