import React from "react";

import Graph, { Props } from ".";
import { useDateRange } from "components/WithDateRange";
import { InteractionPoint } from "./util";
import moment from "moment-timezone";

const DateRangeGraph: React.FunctionComponent<Props> = (props) => {
  const [, setRange] = useDateRange();
  const handleRangeSelected = (
    start: InteractionPoint,
    end: InteractionPoint,
  ) => {
    setRange({
      from: moment.unix(start.bottom.domain / 1000),
      to: moment.unix(end.bottom.domain / 1000),
    });
  };
  const newProps = { ...props, onRangeSelected: handleRangeSelected };

  return <Graph {...newProps} />;
};

export default DateRangeGraph;
