import React, { useMemo, useCallback } from "react";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { ignoreRepeats, mergeDelete } from "./map";

export type RouteState = {
  search: { [k: string]: string };
  hash: string;
};

/**
 * A present but undefined search or hash value clears any existing value
 * (otherwise the other existing value is maintained if only search or
 * hash is updated). Similarly, a present but undefined search values
 * key clears existing values (otherwise they are maintained).
 */
export type RouteStateUpdate = {
  search?: { [k: string]: string | undefined } | undefined;
  hash?: string | undefined;
  replace?: boolean;
};

type SyncableRouteState = [RouteState, (update: RouteStateUpdate) => void];

export const RouteStateContext = React.createContext<SyncableRouteState>([
  { search: {}, hash: "" },
  () => {},
]);

export const WithRouteState = ({ children }: { children: React.ReactNode }) => {
  const loc = useLocation();
  const navigate = useNavigate();

  // N.B.: for now, we always maintain the hash, if any
  const rawHash = loc.hash;
  const hashVal = decodeURIComponent(rawHash.slice(1));
  const rawSearch = loc.search;
  const searchVals = useMemo(() => {
    return ignoreRepeats(queryString.parse(rawSearch));
  }, [rawSearch]);
  // maintains a set of parameters to be synced to the query string
  // when mounting, initialize state from route
  // when setter called, update route according to stored state

  const routeUpdater = useCallback(
    (update: RouteStateUpdate) => {
      const newRoute: { hash?: string; search?: string } = {};
      if ("hash" in update) {
        newRoute.hash = update.hash && encodeURIComponent(update.hash);
      }
      if ("search" in update) {
        newRoute.search =
          update.search &&
          queryString.stringify(mergeDelete(searchVals, update.search));
      } else {
        newRoute.search = rawSearch;
      }

      if (update.replace) {
        navigate(newRoute, { replace: true });
      } else {
        navigate(newRoute);
      }
    },
    [rawSearch, searchVals, navigate],
  );

  const routeSyncedState = useMemo(() => {
    return [
      {
        search: searchVals,
        hash: hashVal,
      },
      routeUpdater,
    ];
  }, [hashVal, searchVals, routeUpdater]) as SyncableRouteState;
  return (
    <RouteStateContext.Provider value={routeSyncedState}>
      {children}
    </RouteStateContext.Provider>
  );
};
