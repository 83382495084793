import React, { useState } from "react";

import { formatBytes, formatMs } from "utils/format";

import DocsSnippet from "components/DocsSnippet";

import { Node } from "types/explain";

import styles from "./style.module.scss";

type Props = {
  node: Node;
  blockSize: number;
};

const IOAndBuffers: React.FunctionComponent<Props> = ({ node, blockSize }) => {
  const formatBlocksAsBytes = getBlocksAsBytesFormatter(blockSize);

  const [source, setSource] = useState<"cumulative" | "self">("cumulative");
  const handleIOSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setSource("cumulative");
    } else {
      setSource("self");
    }
  };

  const blocksCost = (prop: keyof Node) =>
    getCostSummary(node, prop, source, formatBlocksAsBytes);
  const timeCost = (prop: keyof Node) =>
    getCostSummary(node, prop, source, formatMs);

  return (
    <div>
      {"Plans" in node && (
        <label>
          <input
            type="checkbox"
            checked={source === "cumulative"}
            onChange={handleIOSourceChange}
          />
          <span className="pl-1 font-medium">
            Include cumulative costs of node children
          </span>
        </label>
      )}
      <table className={styles.table}>
        <thead>
          <tr>
            <th />
            <th>
              Shared{" "}
              <DocsSnippet
                title="Shared"
                content="data from regular tables and indexes"
              />
            </th>
            <th>
              Local{" "}
              <DocsSnippet
                title="Local"
                content="data from temporary tables and indexes"
              />
            </th>
            <th>
              Temp{" "}
              <DocsSnippet
                title="Temp"
                content="short-term working data used during node execution"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              Hit{" "}
              <DocsSnippet
                title="Hit"
                content="blocks found already in cache"
              />
            </th>
            <td>{blocksCost("Shared Hit Blocks")}</td>
            <td>{blocksCost("Local Hit Blocks")}</td>
            <td>-</td>
          </tr>
          <tr>
            <th scope="row">
              Read{" "}
              <DocsSnippet
                title="Read"
                content="blocks read from disk (or OS cache)"
              />
            </th>
            <td>{blocksCost("Shared Read Blocks")}</td>
            <td>{blocksCost("Local Read Blocks")}</td>
            <td>{blocksCost("Temp Read Blocks")}</td>
          </tr>
          <tr>
            <th scope="row">
              Dirtied{" "}
              <DocsSnippet
                title="Dirtied"
                content="previously unmodified blocks changed by this query"
              />
            </th>
            <td>{blocksCost("Shared Dirtied Blocks")}</td>
            <td>{blocksCost("Local Dirtied Blocks")}</td>
            <td>-</td>
          </tr>
          <tr>
            <th scope="row">
              Written{" "}
              <DocsSnippet
                title="Written"
                content="previously-dirtied blocks evicted from cache by this query"
              />
            </th>
            <td>{blocksCost("Shared Written Blocks")}</td>
            <td>{blocksCost("Local Written Blocks")}</td>
            <td>{blocksCost("Temp Written Blocks")}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <div className="flex gap-12">
          <div>
            <div className="text-[#606060]">I/O Read Time</div>
            <div>{timeCost("I/O Read Time")}</div>
          </div>
          <div className={styles.writeTime}>
            <span className="text-[#606060]">I/O Write Time</span>
            <div>{timeCost("I/O Write Time")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getBlocksAsBytesFormatter = (blockSize: number) => (value: number) =>
  formatBytes(value * blockSize);

function getCostSummary(
  node: Node,
  prop: keyof Node,
  source: "self" | "cumulative",
  format: (a: any) => string,
): string {
  const value =
    source === "cumulative" || !node["Plans"]
      ? node[prop]
      : node.extra.self[prop];
  if (value === null || value === undefined) {
    return "-";
  }

  return format(value);
}

export default IOAndBuffers;
