import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { useAsyncActionFlash } from "components/WithFlashes";
import { useAppConfig } from "components/WithAppConfig";

import {
  SetupSamlIntegration as SetupSamlIntegrationType,
  SetupSamlIntegrationVariables,
} from "./types/SetupSamlIntegration";
import { OrganizationIntegrations_getOrganizationDetails_samlIntegration as SamlIntegrationType } from "../types/OrganizationIntegrations";

import MUTATION from "./Mutation.graphql";
import Tip from "components/Tip";

type OrganizationRole = { id: string; name: string };

const SetupSamlIntegration: React.FunctionComponent<{
  organizationSlug: string;
  integration: SamlIntegrationType;
  defaultRole: OrganizationRole;
  roles: OrganizationRole[];
}> = ({ organizationSlug, integration, defaultRole, roles }) => {
  const { domainName } = useAppConfig();
  const [setupSamlIntegration, { called, loading, error }] = useMutation<
    SetupSamlIntegrationType,
    SetupSamlIntegrationVariables
  >(MUTATION);
  useAsyncActionFlash({
    called,
    loading,
    error: error?.message,
    success: "Single Sign-On with SAML enabled",
  });
  const [idpMetadataUrl, setIdpMetadataUrl] = useState("");
  const [idpEntityId, setIdpEntityId] = useState("");
  const [idpSsoServiceUrl, setIdpSsoServiceUrl] = useState("");
  const [idpCert, setIdpCert] = useState("");
  const [organizationRole, setOrganizationRole] =
    useState<OrganizationRole | null>(defaultRole);

  const [useIdpMetadataUrl, setUseIdpMetadataUrl] = useState(true);
  useEffect(() => {
    if (called && !loading && !error) {
      setIdpMetadataUrl("");
      setIdpEntityId("");
      setIdpSsoServiceUrl("");
      setIdpCert("");
      setUseIdpMetadataUrl(true);
      setOrganizationRole(defaultRole);
    }
  }, [called, loading, error, defaultRole]);

  if (integration) {
    return null;
  }

  const handleEnable = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setupSamlIntegration({
      variables: {
        organizationSlug,
        config: { idpMetadataUrl, idpEntityId, idpSsoServiceUrl, idpCert },
        organizationRoleId: organizationRole?.id,
      },
    });
  };

  const handleMetadataModeChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const useUrl = evt.currentTarget.value === "url";
    if (useUrl) {
      setIdpEntityId("");
      setIdpSsoServiceUrl("");
      setIdpCert("");
    } else {
      setIdpMetadataUrl("");
    }
    setUseIdpMetadataUrl(useUrl);
  };

  const submitEnabled = useIdpMetadataUrl
    ? !!idpMetadataUrl
    : [idpEntityId, idpSsoServiceUrl, idpCert].every((field) => !!field);

  const baseUrl =
    window.location.origin + `/users/auth/saml/` + organizationSlug;
  return (
    <div>
      <p>
        Initiate the SAML setup by configuring the following settings with your
        identity provider:
      </p>
      <ul>
        <li>
          <strong>Single Sign-On URL (ACS):</strong>{" "}
          <code>{baseUrl + `/callback`}</code>
        </li>
        <li>
          <strong>Audience URI (SP Entity ID):</strong>{" "}
          <code>{domainName}</code>
        </li>
        <li>
          <strong>Name ID format:</strong> <code>EmailAddress</code>
        </li>
      </ul>
      Or (if supported by your provider) use the following SP metadata URL:
      <br />
      <code>{baseUrl + `/metadata`}</code>
      <hr />
      <p>Then, enter the Identity Provider (IdP) details:</p>
      <div className="flex mb-[10px]">
        <fieldset>
          <input
            id="idp_metadata_mode_url"
            type="radio"
            name="idp_metadata_mode"
            value="url"
            onChange={handleMetadataModeChange}
            checked={useIdpMetadataUrl}
          />
          <label
            htmlFor="idp_metadata_mode_url"
            className="font-normal mr-3 ml-1"
          >
            Use Identity Provider metadata URL
          </label>
          <input
            id="idp_metadata_mode_manual"
            type="radio"
            name="idp_metadata_mode"
            value="manual"
            onChange={handleMetadataModeChange}
            checked={!useIdpMetadataUrl}
          />
          <label
            htmlFor="idp_metadata_mode_manual"
            className="font-normal ml-1"
          >
            Enter Identity Provider metadata manually
          </label>
        </fieldset>
      </div>
      <form onSubmit={handleEnable}>
        {useIdpMetadataUrl ? (
          <div className="form-group">
            <label htmlFor="idp_metadata_url" className="control-label">
              Identity Provider Metadata URL:
            </label>
            <input
              type="text"
              value={idpMetadataUrl}
              id="idp_metadata_url"
              className="form-control max-w-[700px]"
              onChange={(evt) => {
                setIdpMetadataUrl(evt.currentTarget.value);
              }}
            />
          </div>
        ) : (
          <>
            <div className="form-group">
              <label htmlFor="idp_entity_id" className="control-label">
                Identity Provider Entity ID:
              </label>
              <input
                type="text"
                value={idpEntityId}
                id="idp_entity_id"
                className="form-control max-w-[700px]"
                onChange={(evt) => {
                  setIdpEntityId(evt.currentTarget.value);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="idp_sso_service_url" className="control-label">
                Identity Provider SSO URL:
              </label>
              <input
                type="text"
                value={idpSsoServiceUrl}
                id="idp_sso_service_url"
                className="form-control max-w-[700px]"
                onChange={(evt) => {
                  setIdpSsoServiceUrl(evt.currentTarget.value);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="idp_cert" className="control-label">
                Identity Provider Certificate:
              </label>
              <textarea
                value={idpCert}
                id="idp_cert"
                className="form-control resize-y font-mono max-w-[700px]"
                rows={7}
                onChange={(evt) => {
                  setIdpCert(evt.currentTarget.value);
                }}
              />
            </div>
          </>
        )}
        <div className="form-group">
          <label htmlFor="default_role" className="control-label">
            (Optional) Default role to be used for new members{" "}
            <Tip
              content={`"View & Modify (All Servers)" will be used as a default role unless specified`}
            />
            :
          </label>
          <select
            id="default_role"
            className="form-control max-w-[700px]"
            defaultValue={defaultRole ? defaultRole.id : ""}
            onChange={(evt) => {
              const role = roles.find((r) => r.id == evt.currentTarget.value);
              setOrganizationRole(role ?? null);
            }}
          >
            <option value="">- select a role -</option>
            {roles.map(
              (r: OrganizationRole): React.ReactNode => (
                <option key={r.id} value={r.id}>
                  {r.name}
                </option>
              ),
            )}
          </select>
        </div>
        <p>
          Once you have completed the identity provider configuration, enable
          the integration:
        </p>
        <div className="form-group">
          <input
            type="submit"
            value="Enable SAML authentication"
            disabled={!submitEnabled}
            className="btn btn-primary"
          />
        </div>
      </form>
    </div>
  );
};

export default SetupSamlIntegration;
