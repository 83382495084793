import React from "react";

import classNames from "classnames";

import Badge from "components/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";

type Props = {
  status: string;
  className?: string;
};

const CheckStatusBadge: React.FunctionComponent<Props> = ({
  status,
  className,
}) => {
  const commonClassName = "text-[12px] font-medium px-1 py-0.5";
  if (status == "critical") {
    return (
      <Badge
        className={classNames(
          commonClassName,
          "bg-[#fb7577] text-[#260303]",
          className,
        )}
      >
        <FontAwesomeIcon fixedWidth icon={faExclamationTriangle} /> Critical
      </Badge>
    );
  } else if (status == "warning") {
    return (
      <Badge
        className={classNames(
          commonClassName,
          "bg-[#ffce59] text-[#513a01]",
          className,
        )}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} /> Warning
      </Badge>
    );
  } else if (status == "okay") {
    return (
      <Badge
        className={classNames(
          commonClassName,
          "bg-[#a9cc62] text-[#223206]",
          className,
        )}
      >
        <FontAwesomeIcon icon={faCheckCircle} /> Okay
      </Badge>
    );
  } else {
    return (
      <Badge className={classNames(commonClassName, className)}>
        <FontAwesomeIcon icon={faInfoCircle} /> Info
      </Badge>
    );
  }
};

export default CheckStatusBadge;
