import React from "react";
import classNames from "classnames";

import { useCurrentUser } from "components/WithCurrentOrganization";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  className: string;
};

const PageUserNav: React.FunctionComponent<Props> = ({ className }) => {
  const { userEdit } = useRoutes();
  const { avatarUrl, demo, fullname } = useCurrentUser();

  return (
    <div className={classNames(styles.container, className)}>
      <>
        {/*
        For legacy reasons, we  render /users/edit on the server side, even if you
        navigate from the client (see renderLegacyContent in Page.tsx), so we use
        an anchor here instead of a Link.
        */}
        <a href={userEdit()} className={styles.userInfo} title={fullname}>
          <img
            src={avatarUrl}
            style={{ width: 20, height: 20 }}
            className={styles.memberImage}
          />
          <span>{fullname || "(unknown)"}</span>
        </a>
      </>
      {!demo && (
        <a
          rel="nofollow"
          data-method="delete"
          href="/users/sign_out"
          className={styles.logoutLink}
          title="Sign out"
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
        </a>
      )}
    </div>
  );
};

export default PageUserNav;
