import React from "react";
import { useQuery } from "@apollo/client";

import { formatBytes } from "utils/format";

import QUERY from "./Query.graphql";

import {
  ReplicationDetailsGraph,
  ReplicationDetailsGraphVariables,
} from "./types/ReplicationDetailsGraph";
import { useDateRange } from "components/WithDateRange";
import { Data } from "components/Graph/util";

import DateRangeGraph from "components/Graph/DateRangeGraph";
import GraphSection from "components/Graph/GraphSection";

type Props = {
  serverId: string;
  replicationFollowerId: string;
};

const Graph: React.FunctionComponent<Props> = ({
  serverId,
  replicationFollowerId,
}) => {
  const [{ from, to }] = useDateRange();

  const newStartTs = from.unix();
  const newEndTs = to.unix();
  const { data, loading, error } = useQuery<
    ReplicationDetailsGraph,
    ReplicationDetailsGraphVariables
  >(QUERY, {
    variables: {
      serverId,
      replicationFollowerId,
      startTs: newStartTs,
      endTs: newEndTs,
    },
  });

  return (
    <GraphSection loading={loading} error={error}>
      <DateRangeGraph
        data={data?.getReplicationFollowerStats as unknown as Data}
        axes={{ left: { format: formatBytes } }}
        series={[
          { key: "remoteByteLag", label: "Remote Byte Lag" },
          { key: "localByteLag", label: "Local Byte Lag" },
        ]}
      />
    </GraphSection>
  );
};

export default Graph;
