import React from "react";
import Swatch from "./Swatch";

import classNames from "classnames";

import { translate } from "utils/svg";

import styles from "./style.module.scss";
import { SeriesInfo } from "./util";

type Series = Pick<SeriesInfo, "key" | "label" | "fill" | "disabled">;

type Props = {
  width: number;
  height: number;
  series: Series[];
  onToggleDisabled?: (key: string) => void;
};

const Legend: React.FunctionComponent<Props> = ({
  width,
  height,
  series,
  onToggleDisabled,
}) => {
  const padding = {
    x: 11,
    y: 4,
  };
  const contentWidth = width - 2 * padding.x;
  const contentHeight = height - 2 * padding.y;
  const canDisable = series.filter((s) => !s.disabled).length > 1;

  return (
    <>
      <rect fill="white" width={width} height={height} />
      <g transform={translate(padding.x, padding.y)}>
        <foreignObject width={contentWidth} height={contentHeight}>
          <div className={styles.legend}>
            {series.map((s, i) => {
              const clickHandler =
                canDisable || s.disabled ? onToggleDisabled : undefined;
              const title = clickHandler
                ? s.disabled
                  ? `Show ${s.label}`
                  : `Hide ${s.label}`
                : s.label;
              return (
                <LegendItem
                  onClick={clickHandler}
                  key={i}
                  series={s}
                  title={title}
                />
              );
            })}
          </div>
        </foreignObject>
      </g>
    </>
  );
};

const LegendItem: React.FunctionComponent<{
  series: Series;
  onClick?: (key: string) => void;
  title: string;
}> = ({ series, onClick, title }) => {
  const handleClick = onClick && (() => onClick(series.key));
  return (
    <div
      onClick={handleClick}
      className={classNames(styles.legendItem, {
        [styles.disabledLegendItem]: series.disabled,
        [styles.unclickableLegendItem]: !handleClick,
      })}
      title={title}
    >
      <Swatch color={series.fill} />
      <span>{series.label}</span>
    </div>
  );
};

export default Legend;
