import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import { Navigate } from "react-router-dom";
import flowRight from "lodash/flowRight";

import withLoading from "decorators/withLoading";

import QUERY from "./Query.graphql";

type OrganizationType = {
  slug: string;
};

type Props = {
  data: {
    getCurrentUserDetails: {
      organizations: Array<OrganizationType>;
    };
  };
};

class RootRedirect extends React.Component<Props> {
  render() {
    const { organizations } = this.props.data.getCurrentUserDetails;

    if (organizations.length === 0) {
      return <Navigate to={`/organizations/new`} replace />;
    }

    // TODO: We should have a better organization-selection approach here, e.g. last recently viewed
    return <Navigate to={`/organizations/${organizations[0].slug}`} replace />;
  }
}

export default flowRight(graphql(QUERY), withLoading)(RootRedirect);
