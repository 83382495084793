import React from "react";

import { Link } from "react-router-dom";

import { Node } from "types/explain";

import { useRoutes } from "utils/routes";

const RelationInfo: React.FunctionComponent<{
  node: Node;
  databaseId: string;
  wrap?: boolean;
}> = ({ databaseId, node, wrap }) => {
  const relName = node["Relation Name"];
  if (!relName) {
    return null;
  }
  const relSchema = node["Schema"];
  const relAlias = node["Alias"];
  let title = "on ";
  if (relSchema) {
    title += `${relSchema}.`;
  }
  title += relName;
  if (relName != relAlias) {
    title += ` AS ${relAlias}`;
  }

  return (
    <div
      className={
        wrap ? undefined : "overflow-hidden whitespace-nowrap text-ellipsis"
      }
      title={wrap ? undefined : title}
    >
      on{" "}
      <LinkTable databaseId={databaseId} node={node}>
        {relSchema && (
          <>
            <span className="text-[#2f9c37]">{relSchema}</span>.
          </>
        )}
        <span className="text-[#2f9c37]">{relName}</span>
      </LinkTable>{" "}
      {relName != relAlias && (
        <>
          AS <span className="text-[#2f9c37]">{relAlias}</span>
        </>
      )}
    </div>
  );
};

const LinkTable = ({
  databaseId,
  node,
  children,
}: {
  databaseId: string;
  node: Node;
  children: React.ReactNode;
}) => {
  const { databaseTable } = useRoutes();
  const tableId = node.extra.relation_id;
  if (!tableId) {
    return children as React.ReactElement;
  }
  return <Link to={databaseTable(databaseId, tableId)}>{children}</Link>;
};

export default RelationInfo;
