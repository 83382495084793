import React from "react";
import { useQuery } from "@apollo/client";

import IssueList from "components/IssueList";
import Panel from "components/Panel";

import {
  PageIssueList as PageIssueListType,
  PageIssueListVariables,
} from "./types/PageIssueList";

import QUERY from "./Query.graphql";

type Props = {
  serverId: string;
  referentId: string;
  referentType: string;
};

const PageIssueList: React.FunctionComponent<Props> = ({
  serverId,
  referentId,
  referentType,
}) => {
  const { data, loading, error } = useQuery<
    PageIssueListType,
    PageIssueListVariables
  >(QUERY, {
    variables: {
      serverId,
      referentId,
      referentType,
    },
  });

  const relevantIssues = data?.getIssues.filter((issue) => {
    // hide indexing engine issues when on the query details page
    return (
      referentType != "Query" ||
      issue.checkGroupAndName !== "index_advisor/indexing_engine"
    );
  });

  if (loading || error || relevantIssues?.length === 0) {
    return null;
  }
  return (
    <Panel title="Check-Up">
      <IssueList serverId={serverId} issues={relevantIssues} />
    </Panel>
  );
};

export default PageIssueList;
