import React, { useEffect } from "react";

import { Flash, FlashLevel, useSetFlashes } from "components/WithFlashes";

type Props = {
  level: FlashLevel;
  msg: React.ReactNode;
} & DismissableProps;

type DismissableProps =
  | {
      dismissable?: false;
      flashId?: string;
    }
  | {
      dismissable: true;
      flashId: string;
    };

const ShowFlash: React.FunctionComponent<Props> = ({
  level,
  msg,
  dismissable,
  flashId,
}) => {
  const setFlashes = useSetFlashes();
  useEffect(() => {
    // TypeScript has some trouble with inference here due to the definition of
    // Flash, hence the "as"
    const thisFlash = { level, msg, dismissable, id: flashId } as Flash;

    setFlashes((existing) => {
      if (existing.find(flashMatches(level, msg, dismissable, flashId))) {
        return existing;
      }
      return existing.concat(thisFlash);
    });

    return () => {
      setFlashes((existing) => {
        return existing.filter((flash) => flash !== thisFlash);
      });
    };
  }, [setFlashes, level, msg, dismissable, flashId]);

  return null;
};

function flashMatches(
  level: FlashLevel,
  msg: React.ReactNode,
  dismissable: boolean | undefined,
  flashId: string | undefined,
): (flash: Flash) => boolean {
  return (flash) =>
    flash.level === level &&
    "msg" in flash &&
    flash.msg === msg &&
    flash.dismissable === dismissable &&
    flash.id === flashId;
}

export default ShowFlash;
