import React from "react";

import { csrfToken } from "utils/csrf";

const DEFAULT_QUERY = `# Welcome to the pganalyze API!
#
# Learn more: https://pganalyze.com/docs/api
#
# Press ▶ to run this query:
query {
  getCurrentUserDetails {
    id
    fullname
  }
}`;

type Props = {
  graphqlUri: string;
  enterpriseEdition: boolean;
};

const ApiExplorer: React.FunctionComponent<Props> = ({
  graphqlUri,
  enterpriseEdition,
}) => {
  const fetcher = async (graphQLParams: any) => {
    const jwtResponse = await fetch("/users/jwt", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    });

    const response = await fetch(graphqlUri, {
      method: "POST",
      // We use "same-origin" for Enterprise Edition since there may be non-pganalyze cookies that are
      // required by an internal connection reverse proxy
      credentials: enterpriseEdition ? "same-origin" : "omit",
      headers: {
        Authorization: jwtResponse.headers.get("authorization"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(graphQLParams),
    });

    const result = await response.json();
    delete result["extensions"];
    return result;
  };

  const GraphiQL = window.GraphiQL;
  return (
    <GraphiQL fetcher={fetcher} query={DEFAULT_QUERY}>
      <GraphiQL.Logo>pganalyze API Explorer</GraphiQL.Logo>
    </GraphiQL>
  );
};

export default ApiExplorer;
