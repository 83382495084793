import React from "react";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import OnboardingProgressPanels, {
  OnboardingStep,
} from "components/OnboardingProgressPanels";
import { useAppConfig } from "components/WithAppConfig";

import styles from "./style.module.scss";
import TrackedLink from "components/TrackedLink";
import { useRoutes } from "utils/routes";

type Props = {
  organizationSlug: string;
  serverId?: string;
  currentStep: OnboardingStep;
};

const OnboardingFlow: React.FunctionComponent<Props> = ({
  organizationSlug,
  serverId,
  currentStep,
}) => {
  const { organizationMembersNew } = useRoutes();
  const { enterpriseEdition } = useAppConfig();
  return (
    <>
      <Panel
        title="Before you get started"
        className={styles.introPanel}
        secondaryTitle={
          <TrackedLink
            eventCategory="Setup Link"
            eventLabel="Invite team member"
            href={organizationMembersNew(organizationSlug)}
          >
            Invite team member to help
          </TrackedLink>
        }
      >
        <PanelSection className={styles.introSection}>
          {!enterpriseEdition ? (
            <h5 className={styles.introHeading}>
              During your 14-day trial, we want you to get the best experience
              of pganalyze.
            </h5>
          ) : null}
          <p>
            First we'll walk you through setting up the pganalyze collector,
            which will help you gather query performance statistics from your
            database. Then, we'll continue with the setup of Log Insights.
            Finally, we will set up Automatic EXPLAIN Insights.
          </p>
          <h5 className={styles.introHeading}>
            Looking to use the pganalyze Index Advisor?
          </h5>
          <p>
            You can start using the Index Advisor within 24-48 hours after you
            have completed Query Performance setup.
          </p>
        </PanelSection>
      </Panel>
      <OnboardingProgressPanels
        organizationSlug={organizationSlug}
        serverId={serverId}
        currentStep={currentStep}
      />
    </>
  );
};

export default OnboardingFlow;
