import { Data, Datum } from "../Graph/util";
import sortBy from "lodash/sortBy";

import styles from "./style.module.scss";

// Stats array structure:
// 0: collected_at
// 1: phase
// 2: heap_blks_scanned
// 3: heap_blks_vacuumed
// 4: index_vacuum_count
// 5: num_dead_tuples

export type PhaseInfo = {
  startDate: number;
  endDate: number;
  phase: number;
  indexVacuumCount: number;
};

type VacuumStats = number[][];

export type VacuumData = {
  seriesData: Data;
  phases: PhaseInfo[];
};

export const adaptVacuumDetailData = (
  stats: VacuumStats,
  totHeapBlks: number,
): VacuumData => {
  const sorted = sortBy(stats, (s: number[]): number => s[0]);
  const phaseInfo = sorted.reduce<PhaseInfo[]>((phases, curr): PhaseInfo[] => {
    const prev = phases.length > 0 ? phases[phases.length - 1] : null;
    if (prev && curr[1] === prev.phase && curr[4] === prev.indexVacuumCount) {
      return phases;
    }
    return phases.concat({
      startDate: curr[0] * 1000,
      endDate: null,
      phase: curr[1],
      indexVacuumCount: curr[4],
    });
  }, []);
  const phases = phaseInfo.map((p, i) => {
    return {
      ...p,
      endDate:
        i === phaseInfo.length - 1
          ? stats[stats.length - 1][0] * 1000
          : phaseInfo[i + 1].startDate,
    };
  });
  const result = {
    phases,
    seriesData: {
      heapBlksScanned: stats.map(
        (s: number[]): Datum => [s[0] * 1000, totHeapBlks - s[2]],
      ),
      heapBlksVacuumed: stats.map((s: number[]): Datum => [s[0] * 1000, s[3]]),
      numDeadTuples: stats.map((s: number[]): Datum => [s[0] * 1000, s[5]]),
    },
  };

  return result;
};

export function phaseLabel(phase: number): string {
  switch (phase) {
    case 0: // INITIALIZING
      return "Initializing";
    case 1: // SCAN_HEAP
      return "Scanning heap";
    case 2: // VACUUM_INDEX
      return "Vacuuming indexes";
    case 3: // VACUUM_HEAP
      return "Vacuuming heap";
    case 4: // INDEX_CLEANUP
      return "Cleaning up indexes";
    case 5: // TRUNCATE
      return "Truncating heap";
    case 6: // FINAL_CLEANUP
      return "Performing cleanup";
    default:
      return "";
  }
}

export const phaseColor = (phase: number) => {
  switch (phase) {
    case 1: // SCAN_HEAP
      return "#f0f8ff";
    case 2: // VACUUM_INDEX
      return "#f0f0ff";
    case 3: // VACUUM_HEAP
      return "#d0f0e0";
    case 4: // INDEX_CLEANUP
      return "#e7f8fb";
    default:
      return "#f0f0f0";
  }
};

export const phaseStyle = (phase: number) => {
  switch (phase) {
    case 1: // SCAN_HEAP
      return styles.scanHeapPhase;
    case 2: // VACUUM_INDEX
      return styles.vacuumIndexPhase;
    case 3: // VACUUM_HEAP
      return styles.vacuumHeapPhase;
    case 4: // INDEX_CLEANUP
      return styles.vacuumIndexCleanupPhase;
    default:
      return styles.defaultPhase;
  }
};
