import React, { useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PageContent from "components/PageContent";
import PanelSection from "components/PanelSection";
import SettingsNav from "components/SettingsNav";
import { useAppConfig } from "components/WithAppConfig";
import { useRoutes } from "utils/routes";

import ActivatePlan from "./ActivatePlan";
import UpdateCreditCard from "./UpdateCreditCard";
import UpdateBillingDetails from "./UpdateBillingDetails";
import CancelPlan from "./CancelPlan";
import PlanInfo from "./PlanInfo";
import Overview from "./Overview";
import PlanComparison from "./PlanComparison";
import ServerUsagePanel from "./ServerUsagePanel";

import { Billing as BillingType, BillingVariables } from "./types/Billing";
import { BillingPlanInfoFragment_planInfo } from "./PlanInfo/types/BillingPlanInfoFragment";

import QUERY from "./Query.graphql";

const NoBillingAccess: React.FunctionComponent<{
  organizationSlug: string;
  serverLimit: number;
  serverCount: number;
  planInfo: BillingPlanInfoFragment_planInfo;
}> = ({ planInfo, organizationSlug, serverCount, serverLimit }) => {
  const { organizationMembers } = useRoutes();
  return (
    <>
      <Panel title="Subscription">
        <PanelSection>
          <PlanInfo planInfo={planInfo} allowPlanChange={false} />
          You don't have the <strong>Billing</strong> permission required to
          make changes. You can view the{" "}
          <Link to={organizationMembers(organizationSlug)}>Members</Link> list
          to see which team members have the <strong>Billing</strong>{" "}
          permission.
        </PanelSection>
      </Panel>
      <ServerUsagePanel
        organizationSlug={organizationSlug}
        usage={serverCount}
        limit={serverLimit}
        extraServersAction="omit"
        subscriptionPlanId={planInfo.subscriptionPlanId}
      />
    </>
  );
};

const WithStripeElements = ({ children }: { children: React.ReactNode }) => {
  const { stripePublishableKey } = useAppConfig();
  const [stripePromise, setStripePromise] = useState(null);

  useMemo(() => {
    if (stripePromise === null && stripePublishableKey !== null) {
      setStripePromise(loadStripe(stripePublishableKey));
    }
  }, [stripePromise, stripePublishableKey]);

  return (
    <Elements stripe={stripePromise} options={{ locale: "en" }}>
      {children}
    </Elements>
  );
};

type Props = {
  tab:
    | "overview"
    | "update_credit_card"
    | "update_billing_details"
    | "activate_plan"
    | "cancel_plan"
    | "compare";
};

const Billing: React.FunctionComponent<Props> = ({ tab }) => {
  const { slug: organizationSlug } = useParams();

  const { loading, error, data } = useQuery<BillingType, BillingVariables>(
    QUERY,
    {
      variables: {
        organizationSlug,
      },
    },
  );
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { permittedToBilling, planInfo, serverLimit, serverCountForBilling } =
    data.getOrganizationDetails;

  // Treat the lite plan as an expired trial in the plan comparison
  const expiredTrial =
    planInfo.expiredTrial ||
    (!planInfo.isTrial &&
      !planInfo.activeSubscription &&
      planInfo.supportsLitePlan);

  return (
    <PageContent
      title="Settings"
      pageCategory="subscription"
      pageName="index"
      pageTab={tab}
      featureNav={<SettingsNav />}
    >
      {permittedToBilling ? (
        <WithStripeElements>
          {tab == "overview" ? (
            <Overview planInfo={planInfo} />
          ) : tab == "update_credit_card" ? (
            <UpdateCreditCard organizationSlug={organizationSlug} />
          ) : tab == "update_billing_details" ? (
            <UpdateBillingDetails organizationSlug={organizationSlug} />
          ) : tab == "activate_plan" ? (
            <ActivatePlan organizationSlug={organizationSlug} />
          ) : tab == "cancel_plan" ? (
            <CancelPlan />
          ) : tab == "compare" ? (
            <PlanComparison
              isTrial={planInfo.isTrial}
              trialEndsAt={planInfo.trialEndsAt}
              expiredTrial={expiredTrial}
            />
          ) : null}
        </WithStripeElements>
      ) : (
        <NoBillingAccess
          planInfo={planInfo}
          organizationSlug={organizationSlug}
          serverCount={serverCountForBilling}
          serverLimit={serverLimit}
        />
      )}
    </PageContent>
  );
};

export default Billing;
