import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import Panel from "components/Panel";

import QUERY from "./Query.graphql";
import {
  AlertPolicyList as AlertPolicyListType,
  AlertPolicyListVariables,
} from "components/AlertPolicyList/types/AlertPolicyList";
import Grid from "components/Grid";

type Props = {
  organizationSlug: string;
};

const AlertPolicyList: React.FunctionComponent<Props> = ({
  organizationSlug,
}) => {
  const { organizationAlertPolicy } = useRoutes();
  const { data, loading, error } = useQuery<
    AlertPolicyListType,
    AlertPolicyListVariables
  >(QUERY, { variables: { organizationSlug } });
  if (loading || error || !data) {
    return <Loading error={!!error} />;
  }

  return (
    <Panel title="E-mail Alert Policies">
      <Grid
        className="grid grid-cols-[minmax(200px,1fr)_200px]"
        data={data.getAlertPolicies}
        striped
        defaultSortBy="assignedCount"
        columns={[
          {
            field: "policyName",
            header: "Alert Policy Name",
            renderer: function AlertPolicyCell({ rowData, fieldData }) {
              return (
                <Link
                  to={organizationAlertPolicy(organizationSlug, rowData.id)}
                >
                  {fieldData}
                </Link>
              );
            },
          },
          {
            field: "assignedCount",
            header: "Assigned Members",
            defaultSortOrder: "desc",
          },
        ]}
      />
    </Panel>
  );
};

export default AlertPolicyList;
