// Taken from Cynthia Brewer's colorbrewer (http://colorbrewer.org/)

// 9-class YlGn with a gray appended
export const colors = [
  "#004529",
  "#006837",
  "#238443",
  "#41ab5d",
  "#78c679",
  "#addd8e",
  "#d9f0a3",
  "#f7fcb9",
  "#ffffe5",
  "#d9d9d9",
];

export const isDark = (hexcolor: string): boolean => {
  const r = hexcolor.substr(1, 2);
  const g = hexcolor.substr(3, 2);
  const b = hexcolor.substr(5, 2);
  return [r, g, b].some((color) => parseInt(color, 16) < 0x7f);
};
