import React from "react";

import { formatNumber, truncate } from "utils/format";
import {
  faBook,
  faFingerprint,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";

import { QueryDetails_getQueryDetails_postgresRole as PostgresRoleType } from "../types/QueryDetails";
import QueryDetailsContext from "../QueryDetailsContext";
import { Link } from "react-router-dom";
import { useRoutes } from "utils/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QueryDetails_getQueryTagSummaryForQuery as QueryTag } from "../types/QueryDetails";
import QueryTags, { QueryTagType } from "components/QueryTags";
import Popover from "components/Popover";
import { normalizeMissingQueryText } from "../util";
import { useFeature } from "components/OrganizationFeatures";

const QueryDetailsHeader: React.FunctionComponent<{
  queryId: string;
  fingerprint: string;
  role: PostgresRoleType;
  truncatedQuery: string;
  databaseId: string;
  serverId: string;
  tags: QueryTag[];
}> = ({
  queryId,
  fingerprint,
  role,
  truncatedQuery,
  databaseId,
  serverId,
  tags,
}) => {
  const { serverRole, databaseQueryTags } = useRoutes();
  const hasSearchFeature = useFeature("querySearch");

  const tagsList: QueryTagType[] = [
    {
      id: "fingerprint",
      key: (
        <Popover
          content="A query fingerprint represents the abstracted form of a query and enables the grouping of similar queries together. This value is calculated using the pg_query library."
          popupClassName="!text-[12px]"
        >
          <FontAwesomeIcon
            icon={faFingerprint}
            className="text-[#666] text-[10px]"
          />{" "}
          fingerprint
        </Popover>
      ),
      value: fingerprint,
    },
  ];
  if (role) {
    tagsList.push({
      id: "role",
      key: (
        <>
          <FontAwesomeIcon icon={faUser} className="text-[#666] text-[10px]" />{" "}
          role
        </>
      ),
      value: (
        <Link
          to={serverRole(serverId, role.id)}
          className="text-[#337ab7] font-medium hover:text-[#337ab7] focus:text-[#337ab7]"
        >
          {role.name}
        </Link>
      ),
    });
  }
  const queryTagValue = (key: string, value: string): React.ReactNode => {
    return hasSearchFeature ? (
      <Link
        to={`/databases/${databaseId}/queries/search#qtk=${encodeURIComponent(
          key,
        )},qtv=${encodeURIComponent(value)}`}
        title={value}
      >
        {truncate(value, 50)}
      </Link>
    ) : (
      <span title={value}>{truncate(value, 50)}</span>
    );
  };
  tagsList.push(
    // Only show first 3 tags
    ...tags.slice(0, 3).map((tag) => {
      const andMoreLength = tag.values.length - 1;
      const andMore = andMoreLength > 0 && (
        <span className="text-[10px] text-[#606060]">
          <Link
            to={databaseQueryTags(databaseId, queryId)}
            className="text-[#606060] hover:text-[#303030] ml-1"
          >
            and {formatNumber(andMoreLength)} more
          </Link>
        </span>
      );
      return {
        id: tag.key,
        key: <span title={tag.key}>{truncate(tag.key, 30)}</span>,
        value: (
          <>
            {queryTagValue(tag.key, tag.values[0].value)}
            {andMore}
          </>
        ),
      } as QueryTagType;
    }),
  );
  let additionalNode;
  if (tags.length > 3) {
    additionalNode = (
      <Link to={databaseQueryTags(databaseId, queryId)}>
        View all query tags
      </Link>
    );
  } else if (tags.length === 0) {
    additionalNode = (
      <Link to={databaseQueryTags(databaseId, queryId)}>
        <FontAwesomeIcon icon={faBook} /> No query tags collected
      </Link>
    );
  }

  const titleQuery = normalizeMissingQueryText(truncatedQuery);
  return (
    <div className="md:flex md:justify-between">
      <div>
        <h2 className="text-[16px] text-[#606060] m-0 py-[9px] font-query font-medium overflow-visible leading-[26px]">
          {titleQuery}
        </h2>
        <div className="text-[12px] block mt-[-5px] mb-[10px] mx-0">
          <QueryTags tags={tagsList} additionalNode={additionalNode} />
        </div>
      </div>
      <QueryDetailsContext databaseId={databaseId} queryId={queryId} />
    </div>
  );
};

export default QueryDetailsHeader;
