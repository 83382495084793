import { Moment } from "moment";

export type QueryType = {
  id: string;
  normalizedQuery: string;
  truncatedQuery: string;
  avgTime: number;
  callsPerMinute: number;
  pctOfTotal: number;
};

export type IndexConstraint = "primary key" | "unique" | null;

export type IndexType = {
  id: string;
  pganalyzeId: string;
  existing: boolean;
  selected: boolean;
  label: string;
  name: string;
  structure: string;
  constraint: IndexConstraint;
  ddl: string;
  scans: ScanCostType[];
  scanCount: number;
  writeOverhead: number;
};

export type ScanCostType = {
  scanId: string;
  cost: number;
};

export type ExecutionCostType = {
  /** id of index for this cost, or null if a sequential scan */
  indexId: string | null;
  cost: number;
};

export type ScanType = {
  id: string;
  label: string;
  whereExpression: string | null;
  joinExpression: string | null;
  combinedExpression: string;
  scansPerMin: number;
  parameterizedScanExpected: boolean;

  executionCosts: ExecutionCostType[];
  bestExistingCost: ExecutionCostType;
  bestSelectedCost: ExecutionCostType;

  queries: QueryType[];
};

export type StatisticsType = {
  status: string;
  total_time: number;
  model_build_time: number;
  model_solve_time: number;
  coverage: {
    total: number;
    existing_indexes: number;
    possible_indexes: number;
    uncovered: number;
  };
  cost: {
    total: number;
    maximum: number;
  };
  weighted_cost: {
    total: number;
    maximum: number;
  };
  indexes_used: {
    total: number;
    existing_indexes: number;
    possible_indexes: number;
  };
  index_write_overhead: {
    total: number;
    existing_indexes: number;
    possible_indexes: number;
  };
  update_overhead: number;
  existing_update_overhead: number;
};

type InputInfoType = {
  index_selection_options: {
    method: "CP" | "Greedy";
    options: {
      goals: {
        name: string;
        tolerance: number;
      }[];
      rules: { [rule: string]: number };
    };
  };
};

type OutputInfoType = {
  statistics: StatisticsType;
  goals: {
    name: string;
    value: number;
  }[];
};

export type IndexSelectionResultType = {
  runAt: Moment;
  input?: InputInfoType;
  output: OutputInfoType;
  scans: {
    [scanId: string]: ScanType;
  };
  indexes: {
    [indexOid: string]: IndexType;
  };
};

export function getScansPreferringSelectedIndex(
  idx: IndexType,
  result: IndexSelectionResultType,
): ScanType[] {
  return idx.scans.reduce((selectedScans, scanCost) => {
    const scanInfo = result.scans[scanCost.scanId];
    const scanBestCost = scanInfo.bestSelectedCost;

    if (scanCost.cost <= scanBestCost.cost) {
      selectedScans.push(scanInfo);
    }

    return selectedScans;
  }, []);
}

export function getScansUsingIndex(
  idx: IndexType,
  result: IndexSelectionResultType,
): ScanType[] {
  return idx.scans.reduce((selectedScans, scanCost) => {
    const scanInfo = result.scans[scanCost.scanId];
    const scanCurrCost = scanInfo.bestExistingCost;

    if (scanCost.cost <= scanCurrCost.cost) {
      selectedScans.push(scanInfo);
    }

    return selectedScans;
  }, []);
}

export function getWeightedCostImprovement(
  result: IndexSelectionResultType,
): number {
  const scans = Object.values(result.scans);
  const relevantScans = scans.filter((scan) => {
    return (
      scan.bestSelectedCost.cost <= scan.bestExistingCost.cost &&
      scan.bestExistingCost.indexId !== scan.bestSelectedCost.indexId
    );
  });
  const totImprovementTimesWeight = relevantScans.reduce((tot, scan) => {
    const improvement = scan.bestExistingCost.cost / scan.bestSelectedCost.cost;
    const weight = scan.scansPerMin;
    return tot + improvement * weight;
  }, 0);
  const totWeight = relevantScans.reduce(
    (tot, scan) => tot + scan.scansPerMin,
    0,
  );
  return totImprovementTimesWeight / totWeight;
}

export function isIndexType(
  indexSelectionItem: ScanType | IndexType,
): indexSelectionItem is IndexType {
  return "ddl" in indexSelectionItem;
}

export function isScanType(
  indexSelectionItem: ScanType | IndexType,
): indexSelectionItem is ScanType {
  return "scansPerMin" in indexSelectionItem;
}

export function findByLocator(
  locator: string,
  result: IndexSelectionResultType,
): ScanType | IndexType | "seq scan" | undefined {
  if (locator === "SEQ") {
    return "seq scan";
  } else if (locator.startsWith("S-")) {
    const scanId = locator.slice(2);
    return result.scans[scanId];
  } else if (locator.startsWith("IX-")) {
    const indexName = locator.slice(3);
    return Object.values(result.indexes).find((idx) => idx.name === indexName);
  } else if (locator.startsWith("I-")) {
    const indexId = locator.slice(2);
    return result.indexes[indexId];
  } else {
    return undefined;
  }
}

export function getLocator(
  indexSelectionItem: ScanType | IndexType | "seq scan",
): string {
  if (indexSelectionItem === "seq scan") {
    return "SEQ";
  } else if (isIndexType(indexSelectionItem)) {
    return indexSelectionItem.existing
      ? `IX-${indexSelectionItem.name}`
      : `I-${indexSelectionItem.id}`;
  } else {
    return `S-${indexSelectionItem.id}`;
  }
}
