import React from "react";

import styles from "./style.module.scss";

import pdLogo from "./pd.png";
import slackLogo from "./slack.png";
import poweredByStripe from "./powered_by_stripe.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faArrowTrendDown,
  faArrowTrendUp,
  faBook,
  faCaretDown,
  faCaretUp,
  faCircle,
  faDatabase,
  faExchange,
  faInfoCircle,
  faSpinner,
  faTable,
  faWrench,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import {
  faPlusCircle,
  faSnowflake,
  faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faUndo } from "@fortawesome/pro-light-svg-icons";

export const SlackLogo: React.FunctionComponent = () => (
  <img alt="Slack logo" style={{ width: 18, height: 18 }} src={slackLogo} />
);

export const PagerDutyLogo: React.FunctionComponent = () => (
  <img
    alt="PagerDuty logo"
    className={styles.pdIcon}
    style={{ width: 18, height: 18 }}
    src={pdLogo}
  />
);

export const PoweredByStripeLogo: React.FunctionComponent<{
  style?: React.CSSProperties;
}> = ({ style }) => (
  <a href="https://stripe.com" target="_blank">
    <img
      alt="Powered by Stripe logo"
      className={styles.stripeIcon}
      src={poweredByStripe}
      style={{ ...style, height: 24 }}
    />
  </a>
);

type IconProps = Omit<React.ComponentProps<typeof FontAwesomeIcon>, "icon">;

export const InfoIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faInfoCircle}
      className={classNames(propClassName, "text-slate-600", "text-[12px]")}
      {...rest}
    />
  );
};

export const SettingsIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faWrench}
      className={classNames(propClassName, "text-slate-600", "text-[12px]")}
      {...rest}
    />
  );
};

export const TableIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faTable}
      className={classNames(propClassName, "text-slate-600", "text-[12px]")}
      {...rest}
    />
  );
};

export const ServerIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faDatabase}
      className={classNames(propClassName, "text-slate-600", "text-[12px]")}
      {...rest}
    />
  );
};

export const SpinnerIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faSpinner}
      className={classNames(propClassName, "animate-spin-slow")}
      {...rest}
    />
  );
};

export const BookIcon: React.FunctionComponent<IconProps> = (props) => {
  return <FontAwesomeIcon icon={faBook} {...props} />;
};

export const DeadRowsIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faTimesCircle}
      className={classNames(propClassName, "text-[15px]")}
      {...rest}
    />
  );
};

export const FreezingIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faSnowflake}
      className={classNames(propClassName, "text-[15px]")}
      {...rest}
    />
  );
};

export const InsertsIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faPlusCircle}
      className={classNames(propClassName, "text-[15px]")}
      {...rest}
    />
  );
};

export const UndoIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faUndo}
      className={classNames(propClassName, "text-[12px]")}
      {...rest}
    />
  );
};

type ToggleIconProps = IconProps & {
  expanded: boolean;
};

export const ExpandToggle: React.FunctionComponent<ToggleIconProps> = ({
  expanded,
  ...rest
}) => {
  return (
    <FontAwesomeIcon icon={expanded ? faAngleDown : faAngleRight} {...rest} />
  );
};

type CaretIconProps = IconProps & {
  direction: "up" | "down";
  headerNav?: boolean;
  dateRange?: boolean;
};

export const CaretIcon: React.FunctionComponent<CaretIconProps> = ({
  direction,
  headerNav,
  dateRange,
  className,
  ...rest
}) => {
  const headerNavClass =
    headerNav &&
    "absolute shrink-0 mr-[8px] right-0 top-[12px] text-[22px] text-[#666]";
  const dateRangeClass =
    dateRange && "absolute right-[10px] top-[12px] text-[22px] text-[#666]";
  return (
    <FontAwesomeIcon
      icon={direction === "up" ? faCaretUp : faCaretDown}
      className={classNames(className, headerNavClass, dateRangeClass)}
      {...rest}
    />
  );
};

type StatusDotIconProps = IconProps & {
  status: "critical" | "warning" | "okay" | "no data";
};

export const StatusDotIcon: React.FunctionComponent<StatusDotIconProps> = (
  props,
) => {
  const { className: propClassName, title: propTitle, status, ...rest } = props;
  let iconColor: string;
  let title = propTitle;
  switch (status) {
    case "critical":
      iconColor = "text-[#f00]";
      title ??= "Critical issues found";
      break;
    case "warning":
      iconColor = "text-[#d87d33]";
      break;
    case "okay":
      iconColor = "text-[#93bf3b]";
      title ??= "No critical issues, recently received statistics data";
      break;
    default:
      iconColor = "text-[#9c9ea2]";
      title ??= "No recent statistics data";
  }
  return (
    <FontAwesomeIcon
      icon={faCircle}
      className={classNames(propClassName, iconColor, "text-[10px] mr-[2px]")}
      title={title}
      {...rest}
    />
  );
};

export const ExchangeIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faExchange}
      className={classNames(propClassName, "mr-2")}
      {...rest}
    />
  );
};

export const TrendUpArrow: React.FunctionComponent<IconProps> = (props) => {
  return <FontAwesomeIcon icon={faArrowTrendUp} {...props} />;
};

export const TrendDownArrow: React.FunctionComponent<IconProps> = (props) => {
  return <FontAwesomeIcon icon={faArrowTrendDown} {...props} />;
};

type CustomIconProps = Omit<React.SVGProps<SVGSVGElement>, "fill">;
const FontAwesomeCustomIcon: React.FunctionComponent<CustomIconProps> = (
  props,
) => {
  const { width, height, children, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height ?? 16}
      width={width ?? 20}
      fill="currentColor"
      {...rest}
    >
      {/*!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.*/}
      {children}
    </svg>
  );
};

export const IndexOkayIcon: React.FunctionComponent<
  Omit<CustomIconProps, "viewBox">
> = (props) => {
  return (
    <FontAwesomeCustomIcon viewBox="0 0 640 512" {...props}>
      <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l362.8 0c-23.3 16.4-42.5 38.4-55.6 64L32 288c-17.7 0-32-14.3-32-32zM0 416c0-17.7 14.3-32 32-32l288.7 0c2.1 22.9 8.5 44.5 18.5 64L32 448c-17.7 0-32-14.3-32-32zm352-48c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm76.7-11.3c-6.2 6.2-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L480 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0z" />
    </FontAwesomeCustomIcon>
  );
};

export const IndexAddIcon: React.FunctionComponent<
  Omit<CustomIconProps, "viewBox">
> = (props) => {
  return (
    <FontAwesomeCustomIcon viewBox="0 0 640 512" {...props}>
      <path d="M0 96c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 64C14.3 64 0 78.3 0 96zM0 256c0 17.7 14.3 32 32 32l307.2 0c13.1-25.6 32.2-47.6 55.6-64L32 224c-17.7 0-32 14.3-32 32zM0 416c0 17.7 14.3 32 32 32l307.2 0c-10-19.5-16.4-41.1-18.5-64L32 384c-17.7 0-32 14.3-32 32zm352-48c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144s-144 64.5-144 144zm64 0c0-8.8 7.2-16 16-16l48 0 0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-48 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48-48 0c-8.8 0-16-7.2-16-16z" />
    </FontAwesomeCustomIcon>
  );
};

export const IndexDropIcon: React.FunctionComponent<
  Omit<CustomIconProps, "viewBox">
> = (props) => {
  return (
    <FontAwesomeCustomIcon viewBox="0 0 640 512" {...props}>
      <path d="M0 96c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 64C14.3 64 0 78.3 0 96zM0 256c0 17.7 14.3 32 32 32l307.2 0c13.1-25.6 32.2-47.6 55.6-64L32 224c-17.7 0-32 14.3-32 32zM0 416c0 17.7 14.3 32 32 32l307.2 0c-10-19.5-16.4-41.1-18.5-64L32 384c-17.7 0-32 14.3-32 32zm352-48c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144s-144 64.5-144 144zm84.7-59.3c6.2-6.2 16.4-6.2 22.6 0L496 345.4l36.7-36.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L518.6 368l36.7 36.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L496 390.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L473.4 368l-36.7-36.7c-6.2-6.2-6.2-16.4 0-22.6z" />
    </FontAwesomeCustomIcon>
  );
};
