import React from "react";
import { Link } from "react-router-dom";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";

interface Props {
  serverId: string;
}

const QueryTagBlankSlate: React.FunctionComponent<Props> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <Panel title={<span>No Query Tags Collected</span>}>
      <PanelSection>
        <p>
          <strong>Error:</strong> No query tags collected in the last 7 days.
        </p>
        <p>
          Query tags help you locate the source of a query in your application.
          They are collected from Log Insights (for query samples exceeding{" "}
          <Link
            to={serverConfigSetting(serverId, "log_min_duration_statement")}
          >
            log_min_duration_statement
          </Link>
          ), as well as <code>pg_stat_activity</code>.
        </p>
        <p>
          To define query tags you need to add comments to a SQL query to
          indicate its tags, like this:
        </p>
        <pre>{`SELECT 1 /* application:myapp, line:myfile.rb:123:context */`}</pre>
        <p>
          We recommend doing this automatically using application-side libraries
          such as{" "}
          <a href="https://github.com/basecamp/marginalia" target="_blank">
            marginalia
          </a>
          .
        </p>
      </PanelSection>
    </Panel>
  );
};

export default QueryTagBlankSlate;
