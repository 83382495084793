---
title: 'Step 2: Configure Log Routing'
install_track_title: 'Log Insights: Setup (Google Cloud SQL and AlloyDB)'
backlink_href: /docs/log-insights/setup
backlink_title: 'Log Insights: Setup'
---

import imgCreateLogSink from './create-log-sink.png'
import GCPLogRouting from '../../../components/GCPLogRouting'

<GCPLogRouting imgCreateLogSink={imgCreateLogSink} />

After you've saved the new sink, we now need to create a service account that can access the logs sent to the Pub/Sub topic.

<Link className="btn btn-success" to="03_set_up_service_account">
  Continue to Step 3: Set up Service Account
</Link>
