import React from "react";
import { useParams } from "react-router-dom";

import AlertPolicy from "components/AlertPolicy";
import PageContent from "components/PageContent";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";

import AlertSummary from "./AlertSummary";
import CheckList from "components/CheckList";

import { useRoutes } from "utils/routes";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";
import Loading from "components/Loading";

type Props = {
  tab: string;
};

const CheckUp: React.FunctionComponent<Props> = ({ tab }) => {
  const {
    slug: organizationSlug,
    serverId: propsServerId,
    databaseId,
    alertPolicyId,
  } = useParams();
  const {
    organizationChecks,
    organizationAlerts,
    serverChecks,
    serverAlerts,
    databaseChecks,
  } = useRoutes();
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(
    propsServerId,
    databaseId,
  );

  if (!serverIdLoaded) {
    return <Loading />;
  }

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink
        to={
          databaseId
            ? databaseChecks(databaseId)
            : serverId
            ? serverChecks(serverId)
            : organizationChecks(organizationSlug)
        }
        end={false}
      >
        Summary Report
      </PageNavLink>
      <PageNavLink
        to={
          serverId
            ? serverAlerts(serverId)
            : organizationAlerts(organizationSlug)
        }
      >
        Configure
      </PageNavLink>
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title="Alerts &amp; Check-Up"
      pageCategory="checks"
      pageName="index"
      pageTab={tab}
      featureNav={featureNav}
    >
      {tab === "checks" && (
        <CheckList
          organizationSlug={organizationSlug}
          serverId={serverId}
          databaseId={databaseId}
        />
      )}
      {tab === "alerts" &&
        (alertPolicyId ? (
          <AlertPolicy
            organizationSlug={organizationSlug}
            alertPolicyId={alertPolicyId}
          />
        ) : (
          <AlertSummary serverId={serverId} />
        ))}
    </PageContent>
  );
};

export default CheckUp;
