import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import TreeMap from "components/TreeMap";

import {
  SchemaIndexListTreeMap,
  SchemaIndexListTreeMapVariables,
  SchemaIndexListTreeMap_getSchemaIndices,
} from "./types/SchemaIndexListTreeMap";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

interface TreeMapDataType {
  displayName?: string;
  name: string;
  link: string;
  size: number;
}

interface Props {
  databaseId: string;
}

const IndexTreeMap: React.FunctionComponent<Props> = ({ databaseId }) => {
  const { data, loading, error } = useQuery<
    SchemaIndexListTreeMap,
    SchemaIndexListTreeMapVariables
  >(QUERY, { variables: { databaseId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const treeMapData: Array<TreeMapDataType> = data.getSchemaIndices.map(
    ({
      id,
      name,
      sizeBytes,
    }: SchemaIndexListTreeMap_getSchemaIndices): TreeMapDataType => ({
      name: name,
      link: `/databases/${databaseId}/indexes/${id}`,
      size: sizeBytes || 0,
    }),
  );

  return <TreeMap className={styles.treemap} data={treeMapData} />;
};

export default IndexTreeMap;
