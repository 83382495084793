import React, { useState } from "react";

import sortBy from "lodash/sortBy";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";
import QueryStatsTable, {
  QueryStatsTableSortBy,
} from "components/QueryStatsTable";
import { SortDirection } from "types/graphql-global-types";
import QueryDataMissingHint from "components/QueryPerformance/QueryList/QueryDataMissingHint";
import { formatNumber } from "utils/format";

import {
  SchemaTableQueries as SchemaTableQueriesType,
  SchemaTableQueriesVariables,
} from "./types/SchemaTableQueries";
import {
  SchemaTableQueriesScans as SchemaTableQueriesScansType,
  SchemaTableQueriesScansVariables,
} from "./types/SchemaTableQueriesScans";
import { useDateRange } from "components/WithDateRange";
import PanelTitleSearch from "components/PanelTitleSearch";
import QUERY from "./Query.graphql";
import QUERY_SCANS from "./Query.scans.graphql";
import { useFeature } from "components/OrganizationFeatures";
import Grid from "components/Grid";
import SchemaTableScanDetails from "components/SchemaTableScanDetails";

interface SortOptsType {
  sortBy: QueryStatsTableSortBy;
  sortDirection: SortDirection;
}

interface Props {
  databaseId: string;
  tableId: string;
  indexId?: string;
}

const SchemaTableQueries: React.FunctionComponent<Props> = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const indexAdvisor = useFeature("indexAdvisor");
  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(newTerm: string) => {
        setSearchTerm(newTerm);
      }}
    />
  );
  return (
    <>
      {indexAdvisor && (
        <Panel title={"Scans on " + (props.indexId ? "Index" : "Table")}>
          <SchemaTableScansContent {...props} />
        </Panel>
      )}
      {!props.indexId && (
        <Panel title="Queries" secondaryTitle={titleSearch}>
          <SchemaTableQueriesContent {...props} searchTerm={searchTerm} />
        </Panel>
      )}
    </>
  );
};

const FETCH_BATCH_SIZE = 100;

const SchemaTableQueriesContent: React.FunctionComponent<
  Props & { searchTerm: string }
> = ({ databaseId, tableId, searchTerm }) => {
  const [{ from, to }] = useDateRange();
  const [sortOpts, setSortOpts] = useState<SortOptsType>({
    sortBy: "pctOfTotal",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaTableQueriesType,
    SchemaTableQueriesVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
      startTs: from.unix(),
      endTs: to.unix(),
      offset: 0,
      limit: FETCH_BATCH_SIZE,
      filter: searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data.getQueryStatsForTable.length === 0) {
    return <QueryDataMissingHint databaseId={databaseId} />;
  }

  const fetchMoreData = (loadedCount: number) => {
    return new Promise<boolean>((resolve) => {
      const offset = loadedCount;
      fetchMore({
        variables: { offset, limit: FETCH_BATCH_SIZE },
        updateQuery: (
          prev: SchemaTableQueriesType,
          { fetchMoreResult }: { fetchMoreResult: SchemaTableQueriesType },
        ): SchemaTableQueriesType => {
          const fetched = fetchMoreResult.getQueryStatsForTable;
          resolve(fetched.length > 0);
          if (!prev || !prev.getQueryStatsForTable) {
            return fetchMoreResult;
          }
          return {
            ...prev,
            getQueryStatsForTable: [...prev.getQueryStatsForTable, ...fetched],
          };
        },
      });
    });
  };

  return (
    <QueryStatsTable
      databaseId={databaseId}
      serverId={data.getServerDetails.id}
      queries={data.getQueryStatsForTable}
      fetchMoreData={fetchMoreData}
      sortOpts={sortOpts}
      setSortOpts={setSortOpts}
      searchTerm={null}
      compare={false}
      showingTable
    />
  );
};

const SchemaTableScansContent: React.FunctionComponent<Props> = ({
  databaseId,
  tableId,
  indexId,
}) => {
  const [{ from, to }] = useDateRange();

  const { data, loading, error } = useQuery<
    SchemaTableQueriesScansType,
    SchemaTableQueriesScansVariables
  >(QUERY_SCANS, {
    variables: {
      databaseId,
      tableId,
      indexId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data.getSchemaTableScans.length === 0) {
    return <QueryDataMissingHint databaseId={databaseId} />;
  }

  const scanData = data.getSchemaTableScans.map((scan) => {
    const combinedSql =
      scan.whereExpression && scan.joinExpression
        ? `(${scan.whereExpression}) AND (${scan.joinExpression})`
        : scan.whereExpression || scan.joinExpression || "";

    return {
      combinedSql: combinedSql,
      whereExpression: scan.whereExpression,
      joinExpression: scan.joinExpression,
      scanMethod: scan.genericPlanScanMethod,
      totalCost: scan.genericPlanTotalCost,
      scansPerMin: scan.avgCallsPerMinute,
      referencedQueries: scan.queries.map((q) => q.id),
    };
  });
  const sortedScanData = sortBy(scanData, [(scan) => -scan.scansPerMin]);

  return (
    <Grid
      className="grid-cols-[minmax(0,_5fr)_minmax(0,_2fr)_repeat(2,_minmax(0,_1fr))]"
      data={sortedScanData}
      columns={[
        {
          field: "combinedSql",
          header: "Scan Expression",
          tip: "Combined conditions on data fetched during this scan.",
          renderer: function ScanDetailsCell({ rowData }) {
            return (
              <SchemaTableScanDetails
                databaseId={databaseId}
                whereExpression={rowData.whereExpression}
                joinExpression={rowData.joinExpression}
                referencedQueries={rowData.referencedQueries}
              />
            );
          },
        },
        {
          field: "scanMethod",
          header: "Scan Method",
          title: true,
          nullValue: "Unknown",
        },
        {
          field: "totalCost",
          header: "Cost",
          style: "number",
          nullValue: "?",
          renderer: ({ fieldData }) => formatNumber(fieldData, 2),
        },
        {
          field: "scansPerMin",
          header: "Est. Scans / Min",
          style: "number",
          renderer: ({ fieldData }) => formatNumber(fieldData, 4),
        },
      ]}
    />
  );
};

export default SchemaTableQueries;
