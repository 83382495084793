import jwt_decode from "jwt-decode";

export const jwtTokenExpired = (auth: string): boolean => {
  // Safety margin of 60 seconds, to give time for requests to be processed
  const expirySafetyMargin = 60 * 1000;

  const jwtToken = jwt_decode<{ exp: string }>(auth);
  const exp = parseInt(jwtToken["exp"]) * 1000;

  return new Date().getTime() > exp - expirySafetyMargin;
};
