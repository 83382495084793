import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import styles from "./style.module.scss";

import Tip from "components/Tip";
import Button from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import {
  CellData,
  getClassName,
  getHeaderClassName,
  GridColumn,
  SortProps,
  useSortedData,
} from "./util";
import { formatMs, formatNumber } from "utils/format";

export { type GridColumn, type CellData } from "./util";

type Props<T, F extends string & keyof T> = {
  className?: string;
  style?: React.CSSProperties;
  data: T[];
  noRowsText?: string;
  columns: readonly GridColumn<T, F>[];
  striped?: boolean;
  pageSize?: number;
  cellRenderer?: React.ComponentType<
    CellData<T, F> & {
      row: number;
      column: number;
      title?: string;
      className: string;
      children: React.ReactNode;
    }
  >;
} & SortProps<T, F>;

function Grid<T, F extends string & keyof T>({
  className,
  style,
  data,
  noRowsText,
  columns,
  striped,
  pageSize = Infinity,
  cellRenderer,
  ...sortProps
}: Props<T, F>) {
  const visibleColumns = columns.filter((col) => col.width !== false);
  const sortState = useSortedData({
    data,
    columns: visibleColumns,
    ...sortProps,
  });
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [sortState.data]);

  const handleSortInternal = (e: React.MouseEvent<HTMLButtonElement>) => {
    const colIdx = parseInt(e.currentTarget.dataset.columnindex ?? "", 10);
    const newSortCol = visibleColumns[colIdx];
    if (!newSortCol || newSortCol.disableSort) {
      return;
    }
    setPage(0);
    sortState.doSort(colIdx);
  };

  const isPaginated = data.length > pageSize;
  const pageFirstItem = page * pageSize;
  const nextPageFirstItem = (page + 1) * pageSize;
  // N.B.: we need to slice in zero-indexed mode but show labels in one-indexed
  const pageFirstItemLabel = String(pageFirstItem + 1);
  const pageLastItemLabel = String(Math.min(nextPageFirstItem, data.length));
  const visibleData = isPaginated
    ? sortState.data.slice(pageFirstItem, nextPageFirstItem)
    : sortState.data.slice();

  const pagePrevDisabled = pageFirstItem === 0;
  const pageNextDisabled = nextPageFirstItem >= data.length;

  const handleShowPrev = () => {
    setPage((p) => p - 1);
  };
  const handleShowNext = () => {
    setPage((p) => p + 1);
  };

  if (visibleData.length === 0) {
    return (
      <div className={styles.cell}>{noRowsText ?? "No data available"}</div>
    );
  }

  const hasInlineColumnWidths = visibleColumns.every(
    (col) => typeof col.width === "string",
  );
  const gridColStyles = hasInlineColumnWidths
    ? {
        gridTemplateColumns: visibleColumns.map((col) => col.width).join(" "),
      }
    : undefined;
  const combinedStyles = {
    ...gridColStyles,
    ...style,
  };

  return (
    <>
      <HeightWrapper isPaginated={isPaginated} page={page}>
        <div
          className={classNames(styles.grid, className)}
          style={combinedStyles}
        >
          {visibleColumns.map((col, i) => {
            const headerClassName = getHeaderClassName(col);
            const isSortedByThis = sortState.entries[0]?.columnIdx === i;
            const colHeaderContent = (
              <>
                {col.header ?? col.field}
                {col.tip && (
                  <span className={styles.headingTip}>
                    {" "}
                    <Tip content={col.tip} />
                  </span>
                )}
              </>
            );
            const fullHeaderClassName = classNames(
              styles.headingCell,
              "text-left",
              headerClassName,
            );
            if (col.disableSort) {
              return (
                <div key={i} className={fullHeaderClassName}>
                  {colHeaderContent}
                </div>
              );
            }
            const sortOrder = sortState.entries[0]?.order;
            return (
              <Button
                bare
                key={i}
                data-columnindex={i}
                className={fullHeaderClassName}
                onClick={handleSortInternal}
              >
                {colHeaderContent}
                {isSortedByThis &&
                  (sortOrder === "asc" ? <SortCaretUp /> : <SortCaretDown />)}
              </Button>
            );
          })}
          {visibleData.map((row, i) => {
            const isLastRow = i === visibleData.length - 1;
            return (
              <React.Fragment key={i}>
                {visibleColumns.map((col, j) => {
                  const cellProps = {
                    field: col.field,
                    fieldData: row[col.field],
                    rowData: row,
                  };
                  const cellClassName = getClassName(
                    col,
                    cellProps.field,
                    cellProps.fieldData,
                    cellProps.rowData,
                  );
                  const CellContentRenderer =
                    (col.renderer as React.ComponentType<CellData<T, F>>) ??
                    DefaultCellContentRenderer;
                  const title =
                    col.title === true
                      ? String(cellProps.fieldData)
                      : typeof col.title === "function"
                      ? col.title(cellProps)
                      : undefined;

                  const key = `${i}-${j}`;
                  const commonProps = {
                    title: title,
                    className: classNames(
                      styles.cell,
                      striped
                        ? i % 2 === 0 && styles.cellAlt
                        : !isLastRow && styles.cellBordered,
                      cellClassName,
                    ),
                    row: i,
                    column: j,
                  };
                  const content =
                    cellProps.fieldData == null &&
                    col.nullValue !== undefined ? (
                      col.nullValue
                    ) : (
                      <CellContentRenderer {...cellProps} />
                    );

                  if (cellRenderer) {
                    const CellRenderer = cellRenderer;
                    return (
                      <CellRenderer key={key} {...commonProps} {...cellProps}>
                        {content}
                      </CellRenderer>
                    );
                  } else {
                    return (
                      <div key={key} {...commonProps}>
                        {content}
                      </div>
                    );
                  }
                })}
              </React.Fragment>
            );
          })}
        </div>
      </HeightWrapper>
      {isPaginated && (
        <div className="flex pt-[8px] items-center justify-end">
          <span className="mr-[8px]">
            {pageFirstItemLabel}-{pageLastItemLabel} of {data.length}
          </span>
          <Button
            bare
            className="!text-[18px] !py-0 !px-[8px] disabled:text-[#999] disabled:cursor-not-allowed"
            disabled={pagePrevDisabled}
            onClick={handleShowPrev}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <Button
            bare
            className="!text-[18px] !py-0 !px-[8px] disabled:text-[#999] disabled:cursor-not-allowed"
            disabled={pageNextDisabled}
            onClick={handleShowNext}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </div>
      )}
    </>
  );
}

const DefaultCellContentRenderer = function <T, F extends keyof T>({
  fieldData,
}: {
  field: F;
  fieldData: any;
  rowData: T;
}) {
  return <>{fieldData == null ? "" : String(fieldData)}</>;
};

const SortCaretUp: React.FunctionComponent = () => {
  return (
    <svg
      className="inline align-middle flex-grow-0 flex-shrink-0 basis-6 h-4 w-4 fill-current"
      width="18"
      height="18"
      viewBox="0 0 24 24"
    >
      <path d="M7 14l5-5 5 5z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  );
};

const SortCaretDown: React.FunctionComponent = () => {
  return (
    <svg
      className="inline align-middle flex-grow-0 flex-shrink-0 basis-6 h-4 w-4 fill-current"
      width="18"
      height="18"
      viewBox="0 0 24 24"
    >
      <path d="M7 10l5 5 5-5z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  );
};

const HeightWrapper = ({
  children,
  isPaginated,
  page,
}: {
  isPaginated: boolean;
  page: number;
  children: React.ReactNode;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperMinHeight, setWrapperMinHeight] = useState<number | undefined>(
    undefined,
  );
  // Set the highest page's height as wrapper's min height to avoid the
  // pagination footer position move between the pages with different heights
  // (e.g. the last page of results has less data/height than others).
  useEffect(() => {
    if (wrapperRef.current) {
      const currHeight = wrapperRef.current.getBoundingClientRect().height;
      if (wrapperMinHeight === undefined || currHeight > wrapperMinHeight) {
        setWrapperMinHeight(currHeight);
      }
    }
  }, [page, wrapperMinHeight]);

  if (!isPaginated) {
    return <>{children}</>;
  }
  return (
    <div ref={wrapperRef} style={{ minHeight: wrapperMinHeight }}>
      {children}
    </div>
  );
};

export const NoDataGridContainer = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return <div className={classNames(styles.cell, className)}>{children}</div>;
};

export function sortNullsLast({
  fieldData,
}: {
  fieldData: number | null;
}): number {
  return fieldData == null ? -Infinity : fieldData;
}

function NumberCellRenderer({ fieldData }: { fieldData: number }) {
  return formatNumber(fieldData);
}

function MakePrecisionRenderer(precision: number): typeof NumberCellRenderer {
  return function PrecisionNumberCellRenderer({
    fieldData,
  }: {
    fieldData: number;
  }) {
    return formatNumber(fieldData, precision);
  };
}

export const NumberCell = Object.assign(NumberCellRenderer, {
  precision: MakePrecisionRenderer,
});

export function MsCell({ fieldData }: { fieldData: number }) {
  return formatMs(fieldData);
}

export default Grid;
