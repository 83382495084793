/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivityKind {
  acknowledged = "acknowledged",
  downgraded = "downgraded",
  escalated = "escalated",
  notified = "notified",
  notify_failed = "notify_failed",
  reopened = "reopened",
  resolved = "resolved",
  triggered = "triggered",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SubscriptionInterval {
  MONTH = "MONTH",
  YEAR = "YEAR",
}

/**
 * Attributes for configuring notifications
 */
export interface NotificationConfigAttributes {
  slack?: SlackNotificationConfigAttributes | null;
  pagerduty?: PagerDutyNotificationConfigAttributes | null;
}

/**
 * Attributes for configuring SAML
 */
export interface OrganizationSamlConfig {
  idpMetadataUrl?: string | null;
  idpCert?: string | null;
  idpEntityId?: string | null;
  idpSsoServiceUrl?: string | null;
}

/**
 * Attributes for configuring PagerDuty alerts
 */
export interface PagerDutyNotificationConfigAttributes {
  serviceId?: string | null;
  serviceName?: string | null;
  checks: string[];
}

/**
 * Attributes for configuring Slack notifications
 */
export interface SlackNotificationConfigAttributes {
  channelId?: string | null;
  channelName?: string | null;
  checks: string[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
