For rsyslogd and other syslog daemons, the issue can often be that new log files
are created with permissions that don't allow the pganalyze user access.

Assuming that the log files are owned by the Postgres user, you first
need to add the pganalyze user to the postgres group:

```
sudo usermod -a -G postgres pganalyze
```

And then make sure that new files are written with chmod 640. For rsyslog, adjust
your `/etc/rsyslog.conf` as follows:

```
# Place this at the beginning of the rsyslog.conf file:
$umask 0000
$FileCreateMode 0600

# Place this where the postgres log is being output
$FileCreateMode 0640
local0.* /var/log/postgres/postgres.log
$FileCreateMode 0600
```

For syslog-ng and other syslog implementations, the configuration changes may be
different; consult your syslog documentation.

After this, make sure the current log file has the correct permissions:

```
sudo chmod 640 /var/log/postgres/postgres.log
```
