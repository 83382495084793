import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";

import { useRoutes } from "utils/routes";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Tip from "components/Tip";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";

import FilterSearch from "components/FilterSearch";
import MissingIndexList from "./MissingIndexList";
import ChecksDisabledPanels from "./ChecksDisabledPanels";
import KPITiles from "./KPITiles";
import Status from "./Status";

import QUERY from "./Query.graphql";

import {
  GetIndexAdvisorIssues,
  GetIndexAdvisorIssuesVariables,
  GetIndexAdvisorIssues_getIssues as IssueType,
  GetIndexAdvisorIssues_getSchemaTables as SchemaTableType,
  GetIndexAdvisorIssues_getIssueCounts as IssueCountsType,
} from "./types/GetIndexAdvisorIssues";

import styles from "./style.module.scss";
import UnusedIndexList from "./UnusedIndexList";
import ShowFlash from "components/ShowFlash";
import ContactSupportLink from "components/ContactSupportLink";
import { useFeature } from "components/OrganizationFeatures";
import UpgradeRequired from "components/UpgradeRequired";
import Config from "./Config";
import { useUserPreferences } from "utils/hooks";

type IndexAdvisorOverviewTab =
  | "overview"
  | "indexing_engine"
  | "index_unused"
  | "config"
  | "status";

const IndexAdvisorOverview: React.FunctionComponent<{
  tab: IndexAdvisorOverviewTab;
}> = ({ tab }) => {
  const {
    databaseIndexAdvisor,
    databaseIndexAdvisorIndexingEngine,
    databaseIndexAdvisorIndexUnused,
    databaseIndexAdvisorConfig,
    databaseIndexAdvisorStatus,
  } = useRoutes();
  const { databaseId } = useParams();
  const hasIndexAdvisor = useFeature("indexAdvisor");
  if (!hasIndexAdvisor) {
    return <UpgradeRequired feature="Index Advisor" />;
  }

  return (
    <PageContent
      title="Index Advisor"
      pageCategory="index-advisor"
      pageName="index"
      featureNav={
        <PageSecondaryNavigation>
          <PageNavLink to={databaseIndexAdvisor(databaseId)}>
            Overview
          </PageNavLink>
          <PageNavLink to={databaseIndexAdvisorIndexingEngine(databaseId)}>
            Missing Indexes
          </PageNavLink>
          <PageNavLink to={databaseIndexAdvisorIndexUnused(databaseId)}>
            Unused Indexes
          </PageNavLink>
          <PageNavLink to={databaseIndexAdvisorConfig(databaseId)}>
            Configure
          </PageNavLink>
          <PageNavLink to={databaseIndexAdvisorStatus(databaseId)}>
            Status
          </PageNavLink>
        </PageSecondaryNavigation>
      }
      pageTab={tab}
    >
      <IndexAdvisorOverviewContent tab={tab} databaseId={databaseId} />
    </PageContent>
  );
};

const IndexAdvisorOverviewContent: React.FunctionComponent<{
  tab: IndexAdvisorOverviewTab;
  databaseId: string;
}> = ({ tab, databaseId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [iaPref, setIAPref] = useUserPreferences("indexAdvisor");
  const { data, loading, error } = useQuery<
    GetIndexAdvisorIssues,
    GetIndexAdvisorIssuesVariables
  >(QUERY, {
    variables: {
      databaseId,
      state: iaPref.hideAcknowledged ? "triggered" : null,
    },
  });

  const totalIndexSize = data?.getDatabaseDetails?.totalIndexSize;

  const totDataSize = getTotalDataSize(data?.getSchemaTables);
  const totalDataSize = totDataSize?.[0];
  const totalDataSize7dAgo = totDataSize?.[1];

  const currentIssues = getCurrentIssues(data?.getIssues, tab);

  const insightCount = currentIssues?.length;
  const insightsPanelTitle =
    loading ||
    error ||
    !data.getDatabaseDetails.hasInitialIndexAdvisorAnalysis ||
    insightCount > 0 ? (
      `Insights for Database (${insightCount ?? "…"})`
    ) : (
      <span>
        <FontAwesomeIcon
          className={styles.tableIssuesOkay}
          icon={faCheckCircle}
        />{" "}
        Insights for Database
      </span>
    );

  const allChecksDisabled =
    data &&
    !data.indexingEngineConfig.enabled &&
    !data.indexUnusedConfig.enabled;

  const notices = !loading && (
    <IndexAdvisorNotices
      hasColumnStats={data?.getDatabaseDetails?.hasColumnStats}
    />
  );
  if (tab == "config") {
    return (
      <>
        {notices}
        <Config databaseId={databaseId} />
      </>
    );
  }
  if (tab == "status") {
    return (
      <>
        {notices}
        <Status databaseId={databaseId} />
      </>
    );
  }

  const secondaryTitle = (
    <>
      {(tab === "index_unused" || tab === "indexing_engine") && (
        <div className="flex">
          <input
            type="checkbox"
            checked={iaPref.hideAcknowledged}
            id="hide_acknowledged"
            onChange={(evt) =>
              setIAPref({ hideAcknowledged: evt.target.checked })
            }
          />
          <label htmlFor="hide_acknowledged">Hide Acknowledged Insights</label>
        </div>
      )}
      {tab !== "overview" && (
        <FilterSearch
          initialValue={searchTerm}
          onChange={setSearchTerm}
          debounceMs={20}
        />
      )}
    </>
  );

  return (
    <>
      {notices}
      <KPITiles
        loading={loading || !!error}
        totalIndexWriteOverhead={data?.getDatabaseDetails?.indexWriteOverhead}
        totalDataSize={totalDataSize}
        totalDataSize7dAgo={totalDataSize7dAgo}
        totalIndexSize={totalIndexSize}
      />
      {data && (
        <ChecksDisabledPanels
          databaseId={databaseId}
          missingIndexCheckDisabled={!data.indexingEngineConfig.enabled}
          indexUnusedCheckDisabled={!data.indexUnusedConfig.enabled}
        />
      )}
      {!allChecksDisabled && (
        <Panel title={insightsPanelTitle} secondaryTitle={secondaryTitle}>
          {loading || error ? (
            <Loading error={!!error} />
          ) : insightCount > 0 ? (
            <IssuesContent
              tab={tab}
              searchTerm={searchTerm}
              databaseId={databaseId}
              issues={currentIssues}
              issueCounts={data.getIssueCounts}
              tables={data.getSchemaTables}
            />
          ) : data.getDatabaseDetails.hasInitialIndexAdvisorAnalysis ? (
            <PanelSection>
              Looking good! We have no ongoing insights to show at this time.
            </PanelSection>
          ) : (
            <PanelSection>
              Index Advisor is not ready yet, pending initial query analysis. If
              this takes more than 48 hours after you first start seeing stats
              reported by the server, please{" "}
              <ContactSupportLink>contact support</ContactSupportLink>.
            </PanelSection>
          )}
        </Panel>
      )}
    </>
  );
};

const IndexAdvisorNotices: React.FunctionComponent<{
  hasColumnStats: boolean;
}> = ({ hasColumnStats }) => {
  return (
    <>
      {!hasColumnStats && (
        <ShowFlash
          level="notice"
          msg={
            <>
              Your database appears to be missing column stats monitoring helper
              functions, which can lead to inaccurate Index Advisor
              recommendations. Please review the relevant{" "}
              <a
                target="_blank"
                href="https://pganalyze.com/docs/install/troubleshooting/column_stats_helper"
              >
                troubleshooting documentation
              </a>
              .
            </>
          }
        />
      )}
    </>
  );
};

const IssuesContent: React.FunctionComponent<{
  tab: IndexAdvisorOverviewTab;
  searchTerm: string;
  databaseId: string;
  issues: IssueType[];
  issueCounts: IssueCountsType[];
  tables: SchemaTableType[];
}> = ({ tab, searchTerm, issues, issueCounts, tables, databaseId }) => {
  switch (tab) {
    case "overview":
      return (
        <IssuesOverview
          databaseId={databaseId}
          issues={issues}
          issueCounts={issueCounts}
        />
      );
    case "indexing_engine":
      return (
        <MissingIndexList
          databaseId={databaseId}
          tables={tables}
          searchTerm={searchTerm}
          issues={issues}
        />
      );
    case "index_unused":
      return (
        <UnusedIndexList
          databaseId={databaseId}
          searchTerm={searchTerm}
          issues={issues}
        />
      );
    default:
      return null;
  }
};

function getCurrentIssues(
  allIssues: IssueType[] | undefined,
  tab: IndexAdvisorOverviewTab,
): IssueType[] | undefined {
  if (!allIssues) {
    return undefined;
  }

  switch (tab) {
    case "overview":
      return allIssues;
    case "indexing_engine":
      return allIssues.filter(
        (issue) => issue.checkGroupAndName === `index_advisor/indexing_engine`,
      );
    case "index_unused":
      return allIssues.filter(
        (issue) => issue.checkGroupAndName === "schema/index_unused",
      );
    default:
      return [];
  }
}

function getTotalDataSize(tables: SchemaTableType[]): [number, number] | null {
  if (!tables) {
    return null;
  }
  return tables.reduce<[number, number]>(
    ([size, size7dAgo], table) => {
      return [
        size + (table.lastStats?.dataSizeBytes ?? 0),
        size7dAgo + (table.stats7dAgo?.dataSizeBytes ?? 0),
      ];
    },
    [0, 0],
  );
}

const IssuesOverview: React.FunctionComponent<{
  issues: IssueType[];
  issueCounts: IssueCountsType[];
  databaseId: string;
}> = ({ issues, issueCounts, databaseId }) => {
  const {
    databaseIndexAdvisorIndexUnused,
    databaseIndexAdvisorIndexingEngine,
  } = useRoutes();
  const missingIdxIssues = issues.filter(
    (idx) => idx.checkGroupAndName === "index_advisor/indexing_engine",
  );
  const missingIndexCounts = issueCounts.find(
    (c) => c.checkGroupAndName === "index_advisor/indexing_engine",
  );
  const missingIndexNew = missingIndexCounts?.newCount ?? "?";
  const missingIndexResolved = missingIndexCounts?.resolvedCount ?? "?";

  const unusedIdxIssues = issues.filter(
    (idx) => idx.checkGroupAndName === "schema/index_unused",
  );
  const unusedIndexCounts = issueCounts.find(
    (c) => c.checkGroupAndName === "schema/index_unused",
  );
  const unusedIndexNew = unusedIndexCounts?.newCount ?? "?";
  const unusedIndexResolved = unusedIndexCounts?.resolvedCount ?? "?";

  return (
    <div className={styles.issuesOverviewGrid}>
      {/* headings */}
      <div
        className={classNames(
          styles.headingCell,
          styles.issuesOverviewGridInsightHeader,
        )}
      >
        Insight
      </div>
      <div className={classNames(styles.headingCell, "text-right")}>
        New Last 7 Days
      </div>
      <div className={classNames(styles.headingCell, "text-right")}>
        Resolved Last 7 Days
      </div>
      {/* Missing Indexes */}
      <div className={classNames(styles.issueCount, "text-4xl m-5")}>
        <Link to={databaseIndexAdvisorIndexingEngine(databaseId)}>
          {missingIdxIssues.length}
        </Link>
      </div>
      <div className="my-5">
        <div>
          <Link to={databaseIndexAdvisorIndexingEngine(databaseId)}>
            Missing Indexes
          </Link>{" "}
          <Tip content="Adding these indexes can help improve database performance." />
        </div>
        <div className={styles.issueFootnote}>
          Based on query activity in last 7 days
        </div>
      </div>
      <div className="my-5 mx-2 font-mono text-right">{missingIndexNew}</div>
      <div className="my-5 mx-2 font-mono text-right">
        {missingIndexResolved}
      </div>
      {/* Unused Indexes */}
      <div className={classNames(styles.issueCount, "text-4xl m-5")}>
        <Link to={databaseIndexAdvisorIndexUnused(databaseId)}>
          {unusedIdxIssues.length}
        </Link>
      </div>
      <div className="my-5">
        <div>
          <Link to={databaseIndexAdvisorIndexUnused(databaseId)}>
            Unused Indexes
          </Link>{" "}
          <Tip content="Dropping these indexes can help improve database performance, since they must be updated for writes to their corresponding tables." />
        </div>
        <div className={styles.issueFootnote}>
          Not recently used (default 35 days)
        </div>
      </div>
      <div className="my-5 mx-2 font-mono text-right">{unusedIndexNew}</div>
      <div className="my-5 mx-2 font-mono text-right">
        {unusedIndexResolved}
      </div>
    </div>
  );
};

export function getTableName(issue: IssueType): string {
  return (
    issue.descriptionReferences.find((ref) => ref.param == "table")?.name ??
    "<unknown table>"
  );
}

export default IndexAdvisorOverview;
