import React, { useContext, useState } from "react";

import { AnnotatedPlan } from "types/explain";

const PlanContext = React.createContext<ComparablePlanType | undefined>(
  undefined,
);
const SetPlanContext =
  React.createContext<React.Dispatch<React.SetStateAction<ComparablePlanType>>>(
    undefined,
  );

const ComparePlanContext = React.createContext<ComparablePlanType | undefined>(
  undefined,
);
const SetComparePlanContext =
  React.createContext<React.Dispatch<React.SetStateAction<ComparablePlanType>>>(
    undefined,
  );

export type ComparablePlanType = {
  id: string;
  label: string;
  fingerprint: string;
  seenAt: number;
  plan: AnnotatedPlan;
  runtime: number | undefined;
  totCost: number | undefined;
  ioBytes: number | undefined;
  ioMs: number | undefined;
};

const WithExplainPlan = ({
  children,
  initialPlan,
}: {
  children: React.ReactNode;
  initialPlan?: ComparablePlanType;
}) => {
  const [plan, setPlan] = useState(initialPlan);
  const [comparePlan, setComparePlan] = useState<
    ComparablePlanType | undefined
  >(undefined);

  return (
    <SetPlanContext.Provider value={setPlan}>
      <SetComparePlanContext.Provider value={setComparePlan}>
        <PlanContext.Provider value={plan}>
          <ComparePlanContext.Provider value={comparePlan}>
            {children}
          </ComparePlanContext.Provider>
        </PlanContext.Provider>
      </SetComparePlanContext.Provider>
    </SetPlanContext.Provider>
  );
};

export function useCurrentPlan() {
  return useContext(PlanContext);
}

export function useSetCurrentPlan() {
  return useContext(SetPlanContext);
}

export function useCurrentComparePlan() {
  return useContext(ComparePlanContext);
}

export function useSetCurrentComparePlan() {
  return useContext(SetComparePlanContext);
}

export default WithExplainPlan;
