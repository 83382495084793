import React, { useMemo } from "react";

import { ApolloClient, ApolloProvider, HttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";

import { apolloAuthLink } from "utils/apolloAuthLink";

type Props = {
  children: React.ReactElement;
  graphqlUri: string;
};

const App: React.FunctionComponent<Props> = ({ children, graphqlUri }) => {
  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: graphqlUri,
      credentials: "same-origin",
    });
    return new ApolloClient({
      link: apolloAuthLink.concat(httpLink),
      cache: new InMemoryCache({
        typePolicies: {
          GraphqlQuery: {
            queryType: true,
          },
          Bloat: {
            keyFields: false,
          },
          CheckUpIssueGroup: {
            keyFields: false,
          },
          DatabasePartialList: {
            keyFields: false,
          },
          IssueNotification: {
            keyFields: false,
          },
          IssueReference: {
            keyFields: false,
          },
          LogAnalysisTempFileCreatedBucket: {
            keyFields: false,
          },
          QueryIndexCheckIndexedColumn: {
            keyFields: false,
          },
          QueryIndexCheckTable: {
            keyFields: false,
          },
          QueryTag: {
            keyFields: false,
          },
          SchemaTableAutovacuumSetting: {
            keyFields: false,
          },
          SchemaTableInlineStats: {
            keyFields: false,
          },
          SchemaTableInlineBloatStats: {
            keyFields: false,
          },
          SchemaTableBloatStats: {
            keyFields: false,
          },
          SchemaTableStats: {
            keyFields: false,
          },
          SchemaColumnStats: {
            keyFields: false,
          },
          VacuumRunLogDetails: {
            keyFields: false,
          },
          SchemaTableVacuumInfo: {
            keyFields: false,
          },
          WaitEventCount: {
            keyFields: false,
          },
          CollectorInfo: {
            keyFields: false,
          },
          SlackNotificationConfig: {
            keyFields: false,
          },
          PagerDutyNotificationConfig: {
            keyFields: false,
          },
          DatabaseIndexWriteOverhead: {
            keyFields: false,
          },
          VacuumCount: {
            keyFields: false,
          },
          QueryForWorkbook: {
            keyFields: false,
          },
        },
      }),
    });
  }, [graphqlUri]);

  if (!client) {
    return <>Loading...</>;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default App;
