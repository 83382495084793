---
title: 'Step 3: Create pg_stat_statements Helpers'
install_track_title: Installation Guide (Aiven)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import { NoPgMonitorPgStatStatementsHelpers } from "../../components/MonitoringUserSetupInstructions";

<NoPgMonitorPgStatStatementsHelpers adminUsername='avnadmin' systemType='aiven' />

---

Next we continue by installing the pganalyze collector:

<Link className="btn btn-success" to="04_install_the_collector">
  Proceed to Step 4: Install the Collector
</Link>
