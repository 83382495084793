import React, { useState } from "react";

import pluralize from "pluralize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { useMutation, useQuery } from "@apollo/client";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Link, Navigate, useParams } from "react-router-dom";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { useAsyncActionFlash } from "components/WithFlashes";
import Loading from "components/Loading";
import UsageBar from "components/UsageBar";
import { PoweredByStripeLogo } from "components/Icons";
import { formatCost } from "utils/format";
import { Currency } from "utils/currency";
import { useRoutes } from "utils/routes";

import {
  ActivatePlan as ActivatePlanType,
  ActivatePlanVariables,
} from "./types/ActivatePlan";
import {
  ActivatePlanMutation as ActivatePlanMutationType,
  ActivatePlanMutationVariables,
} from "./types/ActivatePlanMutation";
import MUTATION from "./Mutation.graphql";
import QUERY from "./Query.graphql";

import { CreditCardForm } from "../UpdateCreditCard";
import CreditCardInfo from "../CreditCardInfo";
import { BillingDetailsForm } from "../UpdateBillingDetails";
import BillingDetailsInfo from "../BillingDetailsInfo";
import Tip from "components/Tip";

const ActivatePlan: React.FunctionComponent<{
  organizationSlug: string;
}> = ({ organizationSlug }) => {
  const { loading, error, data } = useQuery<
    ActivatePlanType,
    ActivatePlanVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <ActivatePlanContents organizationSlug={organizationSlug} data={data} />
  );
};

const ActivatePlanContents: React.FunctionComponent<{
  organizationSlug: string;
  data: ActivatePlanType;
}> = ({ organizationSlug, data }) => {
  const billingInfo = data.getOrganizationBilling;

  const { planId } = useParams();
  const plan = data.getOrganizationBilling.planChoices
    .filter((plan) => plan.isSelfServe)
    .find((plan) => plan.id == planId);

  const { organizationSubscriptionActivate } = useRoutes();
  const elements = useElements();
  const stripe = useStripe();
  const [stripeLoading, setStripeLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [totalServersEntered, setTotalServersEntered] = useState(
    planId === data.getOrganizationDetails.planInfo.subscriptionPlanId
      ? String(data.getOrganizationDetails.serverLimit)
      : // N.B.: if no plan was found, we'll never actually display this, but we
        // still need to initialize the state field here
        String(plan?.serversIncluded ?? "0"),
  );
  const [showCreditCardForm, setShowCreditCardForm] = useState(
    !billingInfo.subscriptionCardType,
  );
  const [showBillingDetailsForm, setShowBillingDetailsForm] = useState(
    !billingInfo.billingAddressLine1,
  );
  const [cardHolderName, setCardHolderName] = useState("");
  const [billingCompanyName, setBillingCompanyName] = useState(
    billingInfo.billingCompanyName ?? "",
  );
  const [billingAddressLine1, setBillingAddressLine1] = useState(
    billingInfo.billingAddressLine1 ?? "",
  );
  const [billingAddressLine2, setBillingAddressLine2] = useState(
    billingInfo.billingAddressLine2 ?? "",
  );
  const [billingAddressZipCode, setBillingAddressZipCode] = useState(
    billingInfo.billingAddressZipCode ?? "",
  );
  const [billingAddressCity, setBillingAddressCity] = useState(
    billingInfo.billingAddressCity ?? "",
  );
  const [billingAddressState, setBillingAddressState] = useState(
    billingInfo.billingAddressState ?? "",
  );
  const [billingAddressCountry, setBillingAddressCountry] = useState(
    billingInfo.billingAddressCountry ?? "",
  );
  const [billingVatId, setBillingVatId] = useState(
    billingInfo.billingVatId ?? "",
  );
  const [billingEmail, setBillingEmail] = useState(
    billingInfo.billingEmail ?? "",
  );
  const [flashMessage, setFlashMessage] = useState("");
  const { organizationSubscription } = useRoutes();

  const [
    activatePlan,
    { called: mutationCalled, loading: mutationLoading, error: mutationError },
  ] = useMutation<ActivatePlanMutationType, ActivatePlanMutationVariables>(
    MUTATION,
  );

  useAsyncActionFlash({
    called: mutationCalled,
    loading: mutationLoading,
    error: mutationError?.message,
    success: flashMessage,
    successRedirect: organizationSubscription(organizationSlug),
  });

  if (!plan) {
    return <Navigate to={organizationSubscription(organizationSlug)} />;
  }

  const billingCurrency = data.getOrganizationBilling
    .billingCurrency as Currency;

  const activateTitle = plan.isCurrent
    ? `Adjusting ${plan.name} Plan`
    : plan.isDowngrade
    ? `Downgrading To ${plan.name} Plan`
    : `Upgrading To ${plan.name} Plan`;

  const totalServersParsed = parseInt(totalServersEntered, 10);
  const newTotalServerCount = isNaN(totalServersParsed)
    ? plan.serversIncluded
    : totalServersParsed;

  const planSupportsExtraServers =
    plan.billingCurrencyServerOveragePrice != null;
  const extraServers = Math.max(newTotalServerCount - plan.serversIncluded, 0);
  const extraServerCost = extraServers * plan.billingCurrencyServerOveragePrice;
  const extraServerCostLabel = planSupportsExtraServers ? (
    formatCost(extraServerCost, billingCurrency)
  ) : (
    <>&mdash;</>
  );
  const totalCost = plan.billingCurrencyMonthlyPrice + extraServerCost;
  const totalCostLabel = formatCost(totalCost, billingCurrency);

  const hasServerOverage =
    Math.max(newTotalServerCount, plan.serversIncluded) <
    plan.serverCountForBilling;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!window.stripeTestPaymentMethod && (!stripe || !elements)) {
      return;
    }

    // We need to remember the correct flash message before the mutation,
    // because the mutation result will refresh the plan choices in the
    // Apollo cache, causing downgrade/upgrade info to be inaccurate.
    setFlashMessage(
      plan.isCurrent
        ? `Successfully adjusted the ${plan.name} plan.`
        : plan.isDowngrade
        ? `Successfully changed to the ${plan.name} plan.`
        : `Upgraded to the ${plan.name} plan - thanks for supporting us!`,
    );

    const mutationVariables = {
      organizationSlug,
      newTotalServerCount,
      newPlanId: plan.id,
      billingCompanyName,
      billingAddressLine1,
      billingAddressLine2,
      billingAddressZipCode,
      billingAddressCity,
      billingAddressState,
      billingAddressCountry,
      billingVatId,
      billingEmail,
    };

    if (!showCreditCardForm) {
      activatePlan({
        variables: mutationVariables,
      });
      return;
    }

    setStripeError(null);
    setStripeLoading(true);

    const billingDetails = {
      name: cardHolderName,
      address: {
        line1: billingAddressLine1,
        line2: billingAddressLine2,
        postal_code: billingAddressZipCode,
        city: billingAddressCity,
        state: billingAddressState,
      },
    };
    // Stripe complains when passing empty country value, so we need to skip it altogether if not present
    if (billingAddressCountry !== "") {
      billingDetails["address"]["country"] = billingAddressCountry;
    }

    if (window.stripeTestPaymentMethod) {
      activatePlan({
        variables: {
          ...mutationVariables,
          paymentMethodId: window.stripeTestPaymentMethod,
        },
      });
      return;
    }

    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
        billing_details: billingDetails,
      })
      .then(({ paymentMethod, error }) => {
        setStripeLoading(false);
        if (error) {
          setStripeError(error.message);
        } else {
          activatePlan({
            variables: {
              ...mutationVariables,
              paymentMethodId: paymentMethod.id,
            },
          });
        }
      });
  };

  const planAction = plan.isCurrent
    ? "Adjust"
    : plan.isDowngrade
    ? "Downgrade"
    : "Upgrade";
  const planActionInProgress = plan.isCurrent
    ? "Adjusting"
    : plan.isDowngrade
    ? "Downgrading"
    : "Upgrading";
  // Hack: we want to reflow the form into a single column on larger screens,
  // but the submit button should come last in the first column in both cases.
  // There's no good way to do that without the still-draft masonry layout (see
  // https://drafts.csswg.org/css-grid-3/Overview.bs ), so instead we add this
  // button in two places and hide it at opposite breakpoints.
  const activateSubmit = (
    <>
      <p className="mt-8">
        I hereby accept the{" "}
        <a href="https://pganalyze.com/terms">Terms of Service</a>.
      </p>
      <input
        type="submit"
        name="commit"
        value={
          stripeLoading || mutationLoading
            ? planActionInProgress + " account..."
            : planAction + " account"
        }
        disabled={stripeLoading || mutationLoading}
        className="btn btn-success"
      ></input>
    </>
  );

  const serverUsageSection = (
    <div>
      <div>
        <ServerUsageIcon hasOverage={hasServerOverage} />{" "}
        <span className="font-semibold">{plan.serverCountForBilling}</span>{" "}
        {pluralize("billable server", plan.serverCountForBilling, false)}{" "}
        currently reporting statistics
        {plan.replicaBillingMultiplier != 1.0 ? (
          <>
            {" "}
            <Tip
              content={`${pluralize(
                "replica",
                data.getOrganizationDetails.replicaWithRecentDataCount,
                true,
              )} were counted as ${pluralize(
                "billable server",
                Math.ceil(
                  data.getOrganizationDetails.replicaWithRecentDataCount *
                    plan.replicaBillingMultiplier,
                ),
                true,
              )}. Each replica is counted as ${
                plan.replicaBillingMultiplier
              } billable servers, with the total rounded up.`}
            />
          </>
        ) : null}
      </div>
      <UsageBar
        usage={plan.serverCountForBilling}
        limit={Math.max(newTotalServerCount, plan.serversIncluded)}
      />
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-[3fr_2fr] md:gap-3">
        <div>
          <Panel title={activateTitle}>
            <PanelSection>
              <div className="grid grid-cols-[1fr_max-content]">
                <div>
                  <ul>
                    {plan.marketingLines.map((line, i) => (
                      <li key={i}>{line}</li>
                    ))}
                  </ul>
                </div>
                <div className="text-right text-lg text-[#19152c] font-semibold">
                  {plan.formattedMonthlyPrice}/month
                </div>
              </div>
            </PanelSection>
          </Panel>
          <Panel
            title="Billable Servers"
            secondaryTitle={
              <a
                href="https://pganalyze.com/docs/accounts/billing"
                target="_blank"
              >
                What is a billable server?
              </a>
            }
          >
            {planSupportsExtraServers ? (
              <PanelSection>
                <div className="grid grid-cols-[1fr_3fr] gap-2 mb-2.5 items-end">
                  <div>
                    <label htmlFor="total-servers">Billable servers</label>
                    {plan.replicaBillingMultiplier != 1.0 ? (
                      <>
                        {" "}
                        <Tip
                          content={`Replicas should be counted as ${plan.replicaBillingMultiplier} billable servers, with the total rounded up.`}
                        />
                      </>
                    ) : null}
                    <input
                      id="total-servers"
                      className="form-control max-w-[8rem]"
                      type="text"
                      value={totalServersEntered}
                      onChange={(e) => {
                        const newVal = e.target.value;
                        if (!/^[0-9]{0,3}$/.test(newVal)) {
                          return;
                        }
                        setTotalServersEntered(newVal);
                      }}
                    />
                  </div>
                  {serverUsageSection}
                </div>
                <div className="text-sm text-[#19152c]">
                  {plan.serversIncluded} billable servers included in plan,{" "}
                  {plan.formattedServerOveragePrice} per additional billable
                  server / month
                </div>
              </PanelSection>
            ) : (
              <PanelSection>
                {serverUsageSection}
                {hasServerOverage && (
                  <>
                    Please upgrade to{" "}
                    <Link
                      to={organizationSubscriptionActivate(
                        organizationSlug,
                        "scale_v4",
                      )}
                    >
                      Scale
                    </Link>{" "}
                    to monitor multiple servers
                  </>
                )}
              </PanelSection>
            )}
          </Panel>
          <Panel title="Summary">
            <PanelSection>
              <div className="text-base text-[#19152c] leading-7 grid grid-cols-[1fr_max-content]">
                {planSupportsExtraServers ? (
                  <div>
                    {plan.name} plan (first {plan.serversIncluded} billable
                    servers)
                  </div>
                ) : (
                  <div>{plan.name} plan</div>
                )}
                <div className="text-right">{plan.formattedMonthlyPrice}</div>
                {planSupportsExtraServers && (
                  <>
                    <div>
                      {extraServers} additional{" "}
                      {pluralize("billable server", extraServers)} at{" "}
                      {plan.formattedServerOveragePrice} per billable server /
                      month
                    </div>
                    <div className="text-right">{extraServerCostLabel}</div>
                  </>
                )}
              </div>
            </PanelSection>
            <PanelSection>
              <div className="text-lg text-[#19152c] font-semibold grid grid-cols-2">
                <div>
                  Total for{" "}
                  {planSupportsExtraServers &&
                  newTotalServerCount < plan.serversIncluded ? (
                    <>
                      up to {plan.serversIncluded}{" "}
                      {pluralize("billable server", plan.serversIncluded)}
                    </>
                  ) : (
                    <>
                      {Math.max(newTotalServerCount, plan.serversIncluded)}{" "}
                      {pluralize(
                        "billable server",
                        Math.max(newTotalServerCount, plan.serversIncluded),
                      )}
                    </>
                  )}
                </div>
                <div className="text-right">{totalCostLabel}/month</div>
              </div>
            </PanelSection>
          </Panel>
          <div className="hidden md:block">{activateSubmit}</div>
        </div>
        <div>
          <Panel title="Billing Information">
            <PanelSection>
              {stripeError && (
                <p className="payment-errors alert-danger inline-block">
                  {stripeError}
                </p>
              )}
              {showCreditCardForm ? (
                <>
                  <div className="flex justify-between mb-1">
                    <h3 className="twh text-base">Credit card</h3>
                    <PoweredByStripeLogo />
                  </div>
                  <CreditCardForm
                    cardHolderName={cardHolderName}
                    setCardHolderName={setCardHolderName}
                  />
                </>
              ) : (
                <CreditCardInfo
                  organizationSlug={organizationSlug}
                  subscriptionCardType={
                    data.getOrganizationBilling.subscriptionCardType
                  }
                  subscriptionCardLast4={
                    data.getOrganizationBilling.subscriptionCardLast4
                  }
                  subscriptionCardExpMonth={
                    data.getOrganizationBilling.subscriptionCardExpMonth
                  }
                  subscriptionCardExpYear={
                    data.getOrganizationBilling.subscriptionCardExpYear
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCreditCardForm(true);
                  }}
                />
              )}
              <div className="mt-4">
                {showBillingDetailsForm ? (
                  <>
                    <h3 className="twh text-base">Billing details</h3>
                    <BillingDetailsForm
                      billingCompanyName={billingCompanyName}
                      setBillingCompanyName={setBillingCompanyName}
                      billingAddressLine1={billingAddressLine1}
                      setBillingAddressLine1={setBillingAddressLine1}
                      billingAddressLine2={billingAddressLine2}
                      setBillingAddressLine2={setBillingAddressLine2}
                      billingAddressZipCode={billingAddressZipCode}
                      setBillingAddressZipCode={setBillingAddressZipCode}
                      billingAddressCity={billingAddressCity}
                      setBillingAddressCity={setBillingAddressCity}
                      billingAddressState={billingAddressState}
                      setBillingAddressState={setBillingAddressState}
                      billingAddressCountry={billingAddressCountry}
                      setBillingAddressCountry={setBillingAddressCountry}
                      billingVatId={billingVatId}
                      setBillingVatId={setBillingVatId}
                      billingEmail={billingEmail}
                      setBillingEmail={setBillingEmail}
                    />
                  </>
                ) : (
                  <BillingDetailsInfo
                    organizationSlug={organizationSlug}
                    billingAddressWithName={
                      data.getOrganizationBilling.billingAddressWithName
                    }
                    billingVatId={billingVatId}
                    billingEmail={billingEmail}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowBillingDetailsForm(true);
                    }}
                  />
                )}
              </div>
            </PanelSection>
          </Panel>
        </div>
      </div>
      <div className="block md:hidden">{activateSubmit}</div>
    </form>
  );
};

const ServerUsageIcon: React.FunctionComponent<{ hasOverage: boolean }> = ({
  hasOverage,
}) => {
  return (
    <FontAwesomeIcon
      className={hasOverage ? "text-[#C22426]" : "text-[#43962A]"}
      icon={hasOverage ? faExclamationTriangle : faCheckCircle}
    />
  );
};

export default ActivatePlan;
