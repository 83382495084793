import React from "react";

import classNames from "classnames";

import Grid from "components/Grid";
import Popover from "components/Popover";
import { ConfigSettingDocsSnippet } from "components/DocsSnippet";

export type PostgresSettingType = {
  group?: string | null;
  source?: string | null;
  name: string;
  resetValue: string | null;
  resetValuePretty: string | null;
  bootValuePretty?: string | null;
};

const ServerPostgresSettings: React.FunctionComponent<{
  serverId: string;
  settings: PostgresSettingType[];
  className?: string;
}> = ({ serverId, settings, className }) => {
  return (
    <Grid
      data={settings}
      className={classNames("grid-cols-[1fr_160px]", className)}
      columns={[
        {
          field: "name",
          header: "Setting",
          renderer: function NameCell({ fieldData }) {
            return (
              <ConfigSettingDocsSnippet
                serverId={serverId}
                configName={fieldData}
                noIcon
              />
            );
          },
        },
        {
          field: "resetValuePretty",
          header: "Current Value",
          className: "text-right",
          tip: "A highlight indicates a value that was changed from the default",
          renderer: function CurrentValueCell({ rowData, fieldData }) {
            const defaultValue = rowData.bootValuePretty;
            const isCustomized =
              defaultValue !== undefined && fieldData !== defaultValue;

            const popoverContent = isCustomized && (
              <>
                <div>Value was changed from default</div>
                <div>Default value: {defaultValue}</div>
              </>
            );

            return (
              <Popover content={popoverContent}>
                <span className={classNames(isCustomized && "bg-yellow-200")}>
                  {fieldData}
                </span>
              </Popover>
            );
          },
        },
      ]}
    />
  );
};

export default ServerPostgresSettings;
