import React, { useState } from "react";

import Panel from "components/Panel";
import { useQuery } from "@apollo/client";

import {
  GetIndexAdvisorConfigForDatabase,
  GetIndexAdvisorConfigForDatabaseVariables,
  GetIndexAdvisorConfigForDatabase_getIndexAdvisorConfigForDatabase as IndexAdvisorConfig,
} from "./types/GetIndexAdvisorConfigForDatabase";

import QUERY from "./Query.graphql";
import TABLES_QUERY from "./Query.tables.graphql";
import Loading from "components/Loading";
import Grid from "components/Grid";
import Button from "components/Button";
import { SpinnerIcon } from "components/Icons";
import {
  GetCheckConfig,
  GetCheckConfigVariables,
  GetCheckConfig_getCheckConfig as GetCheckConfigType,
} from "./types/GetCheckConfig";
import { CHECK_OPTIONS } from "components/CheckConfig";
import { useRoutes } from "utils/routes";
import { Link } from "react-router-dom";
import { useCurrentServer } from "components/WithCurrentOrganization";
import Details from "./Details";

const checkGroupAndName = "index_advisor/indexing_engine";

const Config: React.FunctionComponent<{
  databaseId: string;
}> = ({ databaseId }) => {
  const { data, loading, error } = useQuery<
    GetCheckConfig,
    GetCheckConfigVariables
  >(QUERY, {
    variables: {
      databaseId,
      checkGroupAndName: checkGroupAndName,
    },
  });
  return (
    <Configurations
      loading={loading}
      error={error}
      databaseId={databaseId}
      checkData={data?.getCheckConfig}
    />
  );
};

type ConfigData = {
  name: string;
  help: string;
};

const ignoredKey = "ignored_table_size_threshold_mb";
const writeOptimizedKey = "write_heavy_threshold";
const readOptimizedKey = "read_heavy_threshold";

const Configurations: React.FunctionComponent<{
  loading: boolean;
  error: Error;
  databaseId: string;
  checkData: GetCheckConfigType;
}> = ({ loading, error, databaseId, checkData }) => {
  const { serverCheckConfigure } = useRoutes();
  const server = useCurrentServer(true);
  const [focus, setFocus] = useState<string>();
  function handleCloseDetails() {
    setFocus(null);
  }

  const {
    data: tData,
    loading: tLoading,
    error: tError,
  } = useQuery<
    GetIndexAdvisorConfigForDatabase,
    GetIndexAdvisorConfigForDatabaseVariables
  >(TABLES_QUERY, {
    variables: {
      databaseId,
    },
  });

  if (loading || error) {
    return (
      <Panel title="Configurations">
        <Loading error={!!error} />
      </Panel>
    );
  }
  const checkSettings = JSON.parse(checkData.settingsJson);
  const [checkGroup, checkName] = checkGroupAndName.split("/", 2);
  const checkDescription = CHECK_OPTIONS[checkGroup][checkName];
  const ignored = checkDescription.find((v) => v.key === ignoredKey);
  const writeOptimized = checkDescription.find(
    (v) => v.key === writeOptimizedKey,
  );
  const readOptimized = checkDescription.find(
    (v) => v.key === readOptimizedKey,
  );
  function addThresholdValueToHelp(help: string, threshold: string) {
    return help.replace("this threshold", `the threshold (${threshold})`);
  }

  const gridData: ConfigData[] = [
    {
      name: "Custom",
      help: "Tables using a custom configuration",
    },
    {
      name: "Ignored",
      help: addThresholdValueToHelp(
        ignored.help,
        `${checkSettings[ignoredKey]} MB`,
      ),
    },
    {
      name: "Write-optimized",
      help: addThresholdValueToHelp(
        writeOptimized.help,
        checkSettings[writeOptimizedKey],
      ),
    },
    {
      name: "Read-optimized",
      help: addThresholdValueToHelp(
        readOptimized.help,
        checkSettings[readOptimizedKey],
      ),
    },
    {
      name: "Balanced",
      help: "All tables that do not match to above conditions will use the Balanced configuration",
    },
  ];

  return (
    <>
      {focus != null && (
        <Details
          databaseId={databaseId}
          data={tData.getIndexAdvisorConfigForDatabase}
          settingName={focus}
          onClose={handleCloseDetails}
        />
      )}
      <Panel
        title="Configurations"
        secondaryTitle={
          <Link to={serverCheckConfigure(server.humanId, checkGroupAndName)}>
            Configure thresholds
          </Link>
        }
      >
        <Grid
          className="grid-cols-[1fr_200px]"
          data={gridData}
          columns={[
            {
              field: "name",
              header: "Configuration",
              renderer: ConfigDetailsCell,
              disableSort: true,
            },
            {
              field: "name",
              header: "Applied To",
              renderer: function AppliedCell({ fieldData }) {
                return (
                  <AppliedDetailCell
                    loading={tLoading}
                    error={tError}
                    data={tData?.getIndexAdvisorConfigForDatabase}
                    settingsName={fieldData}
                    setFocus={setFocus}
                  />
                );
              },
              disableSort: true,
            },
          ]}
        />
      </Panel>
    </>
  );
};

const ConfigDetailsCell: React.FunctionComponent<{
  rowData: ConfigData;
}> = ({ rowData }) => {
  return (
    <div>
      <div className="text-[16px] text-[#606060] my-1">{rowData.name}</div>
      <div className="whitespace-normal">{rowData.help}</div>
    </div>
  );
};

const AppliedDetailCell: React.FunctionComponent<{
  loading: boolean;
  error: Error;
  data: IndexAdvisorConfig[];
  settingsName: string;
  setFocus: React.Dispatch<React.SetStateAction<string>>;
}> = ({ loading, error, data, settingsName, setFocus }) => {
  if (loading) {
    return <SpinnerIcon />;
  }
  if (error) {
    return <>unable to fetch data</>;
  }
  if (!data) {
    return <>no data</>;
  }
  const tableCount = data.filter(
    (val) => val.settingsName === settingsName,
  ).length;

  return (
    <Button
      bare
      onClick={() => setFocus(settingsName)}
      className="!text-[#337ab7]"
    >
      {tableCount} Tables
    </Button>
  );
};

export default Config;
