import React from "react";

import classNames from "classnames";

import { useUserPreferences } from "utils/hooks";
import { useFlashes, Flash as FlashType } from "components/WithFlashes";

import styles from "./style.module.scss";
import Button from "components/Button";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

const PageFlash: React.FunctionComponent = () => {
  const flashes = useFlashes();

  if (flashes.length === 0) {
    return null;
  }

  return (
    <div>
      {flashes.map((flash: FlashType, idx: number) => (
        <Flash key={idx} flash={flash} />
      ))}
    </div>
  );
};

const Flash: React.FunctionComponent<{ flash: FlashType }> = ({ flash }) => {
  const [flashPrefs, setFlashPrefs] = useUserPreferences("flash");
  const dismissed = flashPrefs.dismissed ?? [];
  function handleDismiss() {
    // to avoid growing this array indefinitely, we assume that older dismissed
    // banners are irrelevant past a certain threshold
    const cutoff = moment().subtract(6, "weeks").toDate().getTime();
    const newDismissed = dismissed
      .filter((d) => {
        d.ts > cutoff;
      })
      .concat({
        id: flash.id,
        ts: Date.now(),
      });
    setFlashPrefs({
      dismissed: newDismissed,
    });
  }
  const isDismissed =
    flash.dismissable && dismissed.some((item) => item.id === flash.id);
  if (isDismissed) {
    return null;
  }

  return (
    <div className={classNames(styles[flash.level])}>
      {flash.dismissable && (
        <Button
          bare
          className="float-right -mr-1 -mt-2 ml-1"
          onClick={handleDismiss}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      )}
      <strong>{flash.level}!</strong>
      {"escapedHtmlMsg" in flash ? (
        // eslint-disable-next-line react/no-danger
        <span dangerouslySetInnerHTML={{ __html: flash.escapedHtmlMsg }} />
      ) : (
        flash.msg
      )}
    </div>
  );
};

export default PageFlash;
