import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import UpgradeRequired from "components/UpgradeRequired";
import { Feature, useFeature } from "components/OrganizationFeatures";
import { useCurrentOrganization } from "components/WithCurrentOrganization";
import { useRoutes } from "utils/routes";

type RequireFeatureType =
  | "schemaStats"
  | "logs"
  | "checkUp"
  | "activitySnapshotsConnections"
  | "activitySnapshotsVacuumAdvisor";

type FeatureDisplayType = {
  feature: Feature;
  name: string;
  plural: boolean;
};

type Props = {
  requireFeature?: RequireFeatureType;
};

const FEATURE_LIST: {
  [key in RequireFeatureType]: FeatureDisplayType;
} = {
  schemaStats: {
    feature: "schemaStats",
    name: "Schema Statistics",
    plural: true,
  },
  logs: { feature: "logs", name: "Log Insights", plural: false },
  checkUp: { feature: "checkUp", name: "Alerts & Check-Up", plural: false },
  activitySnapshotsConnections: {
    feature: "activitySnapshots",
    name: "Connection Tracing",
    plural: false,
  },
  activitySnapshotsVacuumAdvisor: {
    feature: "activitySnapshots",
    name: "VACUUM Advisor",
    plural: false,
  },
};

const WithSubscriptionCheck: React.FunctionComponent<Props> = ({
  requireFeature,
}) => {
  const { slug, planInfo } = useCurrentOrganization();
  const { organizationSubscription } = useRoutes();
  const feature = FEATURE_LIST[requireFeature];

  const hasFeature = useFeature(feature?.feature);
  if (!hasFeature) {
    return (
      <UpgradeRequired feature={feature.name} pluralFeature={feature.plural} />
    );
  }

  if (!planInfo.activeSubscription && !planInfo.isTrial) {
    return <Navigate to={organizationSubscription(slug)} replace />;
  }

  return <Outlet />;
};

export default WithSubscriptionCheck;
